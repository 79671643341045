import React from 'react'
import { CreativeStatusProps } from './CreativeStatus.types'
import { AssetApprovalStatus } from '@marketing-milk/interfaces'

export function CreativeStatus({ creative, className }: CreativeStatusProps) {
  const text = creative.reviewType === 'internal' ? 'Staff' : 'Client'
  function getSchematics(): [string, string] {
    switch (creative.reviewStatus) {
      case AssetApprovalStatus.Pending:
        return ['info', `Pending ${text} Review`]
      case AssetApprovalStatus.Rejected:
        return ['danger', `Rejected By ${text}`]
      case AssetApprovalStatus.Approved:
        return ['success', `Approved By ${text}`]
      case AssetApprovalStatus.Ready:
        return ['success', 'Ready']
      case AssetApprovalStatus.Published:
        return ['success', 'Published']
      default:
        return ['dark', 'Draft']
    }
  }
  const [btnColor, btnText] = getSchematics()
  return (
    <div className={`${className ?? ''} badge badge-${btnColor} text-white p-2`}>{btnText}</div>
  )
}
