import { ResizeObserver } from '@juggle/resize-observer'
import {
  AdCreative,
  CampaignGoalType,
  CampaignSchedule,
  Maybe,
  PublishCampaignDTO,
} from '@marketing-milk/interfaces'
import { Button, Tooltip } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { getCreativeFromPair, inc } from 'app/util'
import usePrevious from 'components/Stepper/components/StepperBody/StepAnimation'
import { any, equals, set } from 'lodash/fp'
import { AdCreativeStep } from 'pages/business/campaign-builder/steps/ad-creative'
import React, { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import useMeasure from 'react-use-measure'
import {
  filterCreativesByGoal,
  getAdPairs,
  getCreativeGroups,
  getIntervalFromDto,
} from '../../../helpers/campaignForm.helpers'
import { CascadingCampaignInfo } from './components/CascadingCampaignInfo'
import { CascadingCampaignOptions } from './components/CascadingCampaignOptions'

type Props = {
  creatives?: AdCreative[]
  campaignDto: PublishCampaignDTO
  setCampaignDto: React.Dispatch<React.SetStateAction<PublishCampaignDTO | undefined>>
  schedule?: CampaignSchedule
  updateSchedule: (numOfCreativeGroups: number) => void
}

export type AdPair = Maybe<[AdCreative, AdCreative]>

export const CampaignAdStep = ({
  campaignDto,
  setCampaignDto,
  creatives,
  updateSchedule,
  schedule,
}: Props) => {
  const [adPairs, setAdPairs] = useState<AdPair[]>([[]])
  const [currentAdPairIndex, setCurrentAdPairIndex] = useState(0)
  const previousCampaignDto = usePrevious(campaignDto)
  const [optionsVisible, setOptionsVisible] = useState(true)

  // This allows us to animate the height of the Cascading Info Box
  const [headingRef, { height: headingHeight }] = useMeasure({ polyfill: ResizeObserver })
  const animateContainer = useSpring({ to: { height: headingHeight } })

  // Set Default Ad Pairs
  useEffect(() => {
    if (!creatives) return
    setAdPairs(getAdPairs(campaignDto.creativeGroups, creatives))
  }, [creatives])

  // If the Campaign Goal is changed, reset creative selection
  useEffect(() => {
    // if first time choosing campaignDTO return
    if (!previousCampaignDto) return

    // If previous chosen, or current chosen goal is not lead gen, do not reset creatives.
    const { goal: previousGoal } = previousCampaignDto.campaign
    const { goal: currentGoal } = campaignDto.campaign
    if (any(equals(CampaignGoalType.LeadGeneration), [previousGoal, currentGoal])) return

    // We reset the current adPairs to make certain that no creatives
    // with  Lead Cards attached are used in a non-lead gen campaign
    setAdPairs([[]])
  }, [campaignDto.campaign.goal])

  useEffect(() => {
    setCampaignDto(set('creativeGroups', getCreativeGroups(adPairs)))
  }, [adPairs])

  useEffect(() => {
    if (adPairs.length) {
      updateSchedule(adPairs.length)
    }
  }, [campaignDto.campaign, adPairs])

  return (
    <div className="w-full flex items-stretch justify-center flex-col relative">
      <div className="flex items-center justify-center space-x-2">
        <h1 className="py-2 font-lg font-bold">Select A/B Ad Pairs</h1>

        {campaignDto.campaign.goal === CampaignGoalType.LeadGeneration &&
          (adPairs.length === 1 ? (
            <Tooltip title="If your current Ad Pair isn't performing well, the next variation in the list will replace it">
              <Button
                variant="contained"
                data-testid="toggle-cascading-button"
                onClick={() => {
                  // append a new, unselected adset
                  setAdPairs(prev => [...prev, []])
                  setCurrentAdPairIndex(inc)
                  setCampaignDto(
                    set(['campaign', 'maxCostPer'], campaignDto.campaign.maxCostPer ?? 10)
                  )
                }}
              >
                Set Cascading Campaign
              </Button>
            </Tooltip>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                // remove all adsets outside of the first
                setAdPairs(prev => [prev[0]])
                setCurrentAdPairIndex(0)
                setCampaignDto(set(['campaign', 'maxCostPer'], undefined))
              }}
            >
              Reset To Normal Campaign
            </Button>
          ))}
      </div>

      {adPairs.length > 1 && (
        <animated.div
          style={animateContainer}
          className="sticky z-10 top-0 bg-purple-50 w-full border-r"
        >
          <div ref={headingRef}>
            <div className={optionsVisible ? 'p-4' : ''}>
              <CascadingCampaignInfo
                setCampaignDto={setCampaignDto}
                dto={campaignDto}
                adPairLength={adPairs.length}
                campaignInterval={getIntervalFromDto(campaignDto)}
                onClose={() => setOptionsVisible(v => !v)}
                isVisible={optionsVisible}
                schedule={schedule}
              />
            </div>

            <CascadingCampaignOptions
              adPairIndex={currentAdPairIndex}
              setAdPairIndex={setCurrentAdPairIndex}
              adPairs={adPairs}
              setAdPairs={setAdPairs}
              campaignDto={campaignDto}
            />
          </div>
        </animated.div>
      )}

      <div className="mx-auto min-w-full">
        <div className="max-w-5xl mx-auto">
          {campaignDto.campaign.goal === CampaignGoalType.LeadGeneration && (
            <Alert severity="info">
              Your campaign is optimized for lead generation, Only Ad Creatives with lead cards will
              be displayed.
            </Alert>
          )}
        </div>
        <AdCreativeStep
          campaignDto={campaignDto}
          creatives={filterCreativesByGoal(creatives, campaignDto?.campaign.goal)}
          creativeA={getCreativeFromPair('A', currentAdPairIndex, adPairs)}
          creativeB={getCreativeFromPair('B', currentAdPairIndex, adPairs)}
          onChangeCreatives={(creativeA, creativeB) => {
            setAdPairs(set(currentAdPairIndex, [creativeA, creativeB]))
          }}
        />
      </div>
    </div>
  )
}
