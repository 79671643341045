import { FbGeneratedTargetValue } from '@marketing-milk/interfaces/dist/facebook/audience/TargetingSpec/TargetingSpec'
import { without } from 'app/util'
import { isEmpty } from 'lodash'
import { DemographicMultiOptionItem, FlexibleSpec } from '../Demographics.types'

/**
 * @param flexibleSpec the flexible_spec within facebook targeting spec
 * @param option the option used in the multiselect component
 * @param index the index of the specific demopgrahicSpec within the flexible targeting spec array
 */
export const addDemographicOption = (
  option: DemographicMultiOptionItem,
  index: number,
  demographicSpecs: FlexibleSpec[]
) => {
  if (isEmpty(demographicSpecs) || !demographicSpecs) return [addNewSpec(option, {})]
  return demographicSpecs.map((spec, i) => {
    if (i !== index) return spec
    return addNewSpec(option, spec)
  })
}

export const addNewSpec = (option: DemographicMultiOptionItem, spec: FlexibleSpec) => {
  const specKey = option.type || 'interests'
  const oldSpec = spec[specKey] || []
  return {
    ...spec,
    [`${specKey}`]: [...oldSpec, { id: String(option.value), name: option.label }],
  }
}

export const removeSpec = (option: DemographicMultiOptionItem, spec: FlexibleSpec) => {
  const specKey = option.type || 'interests'
  const oldSpec = spec[specKey] || []
  return {
    ...spec,
    [`${specKey}`]: without({ id: String(option.value), name: option.label }, oldSpec),
  }
}

/**
 * @param flexibleSpec the flexible_spec within facebook targeting spec
 * @param option the option used in the multiselect component
 * @param index the index of the specific demopgrahicSpec within the flexible targeting spec array
 */
export const removeDemographicOption = (
  option: DemographicMultiOptionItem,
  index: number,
  demographicSpecs?: FlexibleSpec[]
) => {
  if (!demographicSpecs) return []
  return demographicSpecs.map((spec, i) => {
    if (i !== index) return spec
    return removeSpec(option, spec)
  })
}

export const transformSpecToOptions = (spec: FlexibleSpec) =>
  Object.entries(spec).reduce((options, [category, values]) => {
    if (!values) return options // if no values exist, return current options
    return [...options, ...values.map(transformSpecValueToOption(category as keyof FlexibleSpec))]
  }, [] as DemographicMultiOptionItem[])

export const transformSpecArrayToOptions = (spec: FlexibleSpec[], index?: number) => {
  if (!index) return spec.flatMap(transformSpecToOptions)
  return transformSpecToOptions(spec[index])
}

/** Gets the Spec located at the target index in the flexible_spec array */
export const getSpecAtIndex = (index: number, spec?: FlexibleSpec[]) => {
  if (!spec || !spec[index]) return {}
  return spec[index]
}

const transformSpecValueToOption =
  (category: keyof FlexibleSpec) => (specValue: FbGeneratedTargetValue) => ({
    label: specValue.name,
    value: specValue.id,
    type: category,
  })
