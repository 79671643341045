import React from 'react'

interface Props {
  children: React.ReactNode
}
export const FullPageSidebar = (props: Props) => {
  return (
    <div className="flex flex-col absolute z-20 bg-white w-screen sm:relative sm:w-1/4 h-full pb-22 2xl:border-r-2 border-r overflow-y-auto">
      {props.children}
    </div>
  )
}
