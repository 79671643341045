import React, { useContext } from 'react'
import { Paper } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { FacebookLeadgenQuestionType } from '@marketing-milk/interfaces'
import { leadCardPreviewContext } from '../../context/LeadCardPreviewContext'
import { predefinedQuestionWire } from '../../../wizard/sections/form-questions/const'

export function LeadCardPrefillQuestions() {
  const { leadCard } = useContext(leadCardPreviewContext)
  const prefillQuestions = leadCard.questions
    .filter(_ => _.type !== FacebookLeadgenQuestionType.CUSTOM)
    .map(_ => {
      // @ts-ignore the question wire is made from the custom format
      const wire = predefinedQuestionWire[_.type]
      if (!wire) {
        throw new Error(`Predefined lead card question wire for ${_.type} does not exist`)
      }
      return wire
    })

  return (
    <Paper className="p-3">
      <Typography variant="h6" className="text-bold">
        Contact Information
      </Typography>
      <p className="mb-2">{leadCard.questionsDesc}</p>
      {prefillQuestions?.map((question, i) => (
        <div className="form-group bg-da mb-3" key={i}>
          <TextField label={question.label} value="Enter your answer." fullWidth />
        </div>
      ))}
    </Paper>
  )
}
