import Alert from '@material-ui/lab/Alert'
import { FormSlider } from './sections/FormSlider'
import React, { useContext, useState } from 'react'
import { FormMeta } from './sections/form-meta/FormMeta'
import { useGetBusiness } from '@marketing-milk/frontend'
import { SaveChanges } from './save-changes/SaveChanges'
import { leadCardContext } from './context/LeadCardContext'
import { LeadCardWizardProps } from './LeadCardWizard.types'
import { LeadCardPreview } from '../lead-card-preview/LeadCardPreview'
import { LeadCardContextProvider } from './context/LeadCardContextProvider'
import { ServerError } from 'components/server-request/server-error/ServerError'
import { FormPageContent, FormPagePreview } from 'components/layout/PreviewForm'

export function LeadCardWizard(props: LeadCardWizardProps) {
  return (
    <LeadCardContextProvider defaultLeadCard={props.defaultLeadCard} editable={!!props.onSave}>
      <LeadCardWizardComponent {...props} />
    </LeadCardContextProvider>
  )
}

function LeadCardWizardComponent({ onSave }: LeadCardWizardProps) {
  const [error, setError] = useState<Error>()
  const leadCard = useContext(leadCardContext)
  const business = useGetBusiness()

  return (
    <div className=" sm:space-x-0 sm:flex sm:p-4 space-x-4">
      {error && <ServerError error={error} onClose={() => setError(undefined)} />}

      <FormPageContent>
        <div className="p-2 sm:p-4">
          <FormMeta />
          <hr />
          <FormSlider />
          <hr className="my-3" />
          <SaveChanges onSave={onSave} />
        </div>
      </FormPageContent>

      <FormPagePreview>
        {!leadCard.editable && (
          <Alert elevation={2} variant="standard" severity="info" className="mb-4">
            You can't make changes to this lead card
          </Alert>
        )}
        <LeadCardPreview
          business={business}
          leadCard={leadCard}
          section={leadCard.previewSection}
          setSection={leadCard.setPreviewSection}
        />
      </FormPagePreview>
    </div>
  )
}
