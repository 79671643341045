import React from 'react'
import { range } from 'lodash'
import Select from 'react-select'
import { SelectItem } from 'components'
import { Mark } from '@material-ui/core'
import { StepHeader } from '../StepHeader'
import { Slider } from '@marketing-milk/frontend'
import {
  CustomAudience,
  CountryOption,
  CustomAudienceSubType,
  FacebookTargetingSpec,
} from '@marketing-milk/interfaces'

const filterSourceAudiences = (
  customAudience: CustomAudience,
  index: number,
  customAudiences: CustomAudience[]
) => {
  return !customAudiences.find(ca => {
    // @ts-expect-error - The type for "origin" is wrong. It's should be "string | {id: string, name: string, type: string}[]"
    const lookalikeIds: string[] = ca.lookalikeSpec?.origin.map(({ id }) => id) ?? []
    return lookalikeIds.includes(customAudience.facebookID)
  })
}

const filterLookalikeAudiences = (customAudience: CustomAudience) =>
  customAudience.subtype !== CustomAudienceSubType.LOOKALIKE

const formatCustomAudienceOptions = (customAudience: CustomAudience) => ({
  label: customAudience.name,
  value: String(customAudience.facebookID),
})

export interface AISettingsProps {
  customAudiences: CustomAudience[]
  selectedAudienceID: string
  onSelectAudience: (customAudienceID: string) => void
  ratio: number
  onUpdateRatio: (ratio: number) => void
  countryOptions: CountryOption[]
  selectedCountryCodes: string[]
  onUpdateCountryCodes: (codes: string[]) => void
  onRemoveCountry: (index: number) => void
  targetSpec: FacebookTargetingSpec
}

export function AISettings(props: AISettingsProps) {
  const {
    customAudiences,
    selectedAudienceID,
    onSelectAudience,
    onUpdateRatio,
    ratio,
    countryOptions,
    onUpdateCountryCodes,
    selectedCountryCodes,
    onRemoveCountry,
    targetSpec,
  } = props

  const FACEBOOK_MIN_SIMILARITY = 0
  const FACEBOOK_MAX_SIMILARITY = 10
  const sliderMarks: Mark[] = range(FACEBOOK_MIN_SIMILARITY, FACEBOOK_MAX_SIMILARITY + 1).map(
    mark => ({
      label: mark + ' %',
      value: mark,
    })
  )
  // First, we filter out any audience that has been used before as a "source" for another audience.
  // Then, we filter out Lookalike audiences (i.e. AI and Special Category) because a Lookalike audience
  // may not be used as the base for another Lookalike audience.
  const audienceOptions = customAudiences
    .filter(filterSourceAudiences)
    .filter(filterLookalikeAudiences)
    .map(formatCustomAudienceOptions)
  const audienceValue: any = selectedAudienceID
    ? audienceOptions.filter(x => String(x.value) === selectedAudienceID)
    : null

  const commonCountries: string[] = ['US', 'CA', 'MX']
  //@ts-ignore TODO: remove ts-ignore
  const countrySort = (a, b) =>
    (commonCountries.includes(a.value!) ? -1 : 0) - (commonCountries.includes(b.value!) ? -1 : 0)
  const options = countryOptions.map(_ => {
    if (_ && _.name !== null) {
      return {
        label: _.name ?? '',
        value: _.country_code,
      }
    } else return {}
  })

  function selectCategory(option: { label: string; value: string }) {
    onSelectAudience(option.value)
  }

  function addCountry(option: { label: string; value: string }) {
    const updatedCountryCodes = selectedCountryCodes.concat(option.value)
    onUpdateCountryCodes(updatedCountryCodes)
  }

  function generateSelectedCountries() {
    return (
      selectedCountryCodes &&
      selectedCountryCodes.map((code, i) => {
        const country = countryOptions.find(x => x.country_code === code)
        return (
          <SelectItem
            key={i}
            title={country ? country.name : code}
            onClick={() => onRemoveCountry(i)}
          />
        )
      })
    )
  }

  return (
    <>
      <div className="container">
        <StepHeader
          title="Artificial Intelligence (AI) Audience"
          description="Select an option below to start building your Facebook audience."
          targetingSpec={targetSpec}
        />
        <div className="row mt-5">
          <div className="col-12">
            <p className="text-bold">Select Your Artificial Intelligence Audience Source</p>
            <Select
              placeholder="Select an existing audience or data source..."
              options={audienceOptions}
              value={audienceValue}
              onChange={selectCategory as any}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <p className="text-bold">Select Artificial Intelligence Audience Country</p>
            <Select
              placeholder="Search for regions or countries..."
              options={
                selectedCountryCodes.length > 0
                  ? options.filter(x => !selectedCountryCodes.includes(x.value!)).sort(countrySort)
                  : options.sort(countrySort)
              }
              value={null}
              onChange={addCountry as any}
            />
            <br />
            <br />
            {generateSelectedCountries()}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <p className="text-bold">Select Size & Similarity</p>
            <Slider
              className="mt-2"
              marks={sliderMarks}
              defaultValue={ratio}
              min={FACEBOOK_MIN_SIMILARITY}
              max={FACEBOOK_MAX_SIMILARITY}
              onChangeValue={onUpdateRatio}
              minLabel="%"
              maxLabel="%"
              hideMinMaxLabels={true}
            />
            <small className="mt-5">
              Audience size ranges from 0% to 10% of the combined population of your selected
              locations. A 0% lookalike consists of the people most similar to your lookalike
              source. Increasing the percentage creates a bigger, broader audience.
            </small>
          </div>
        </div>
      </div>
    </>
  )
}
