import { InputAdornment, TextField } from '@material-ui/core'
import { DescriptiveFieldLayout } from 'components/FormFields/DescriptiveFieldLayout'
import { AttachMoney } from '@material-ui/icons'

type Props = {
  budget?: number
  setBudget: (Budget: number) => void
}

export const SprintBudgetField = ({ budget, setBudget }: Props) => (
  <DescriptiveFieldLayout
    label="Sprint Budget"
    description="Across All Campaigns, what is the maximum budget the business can spend on ads during this sprint"
  >
    <TextField
      type="number"
      required
      helperText="required"
      label="Total Ad Spend Budget"
      placeholder="total budget for all campaigns"
      defaultValue={budget}
      variant="standard"
      onChange={({ target }) => {
        setBudget(Number(target.value))
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoney />
          </InputAdornment>
        ),
      }}
    />
  </DescriptiveFieldLayout>
)
