import React from 'react'
import { Gender } from '@marketing-milk/interfaces'
import { getGender } from '@marketing-milk/frontend'

export interface UserGenderProps {
  genders: Gender[] | undefined
  updateGenders: (genders: Gender[]) => void
}

export function UserGender({ genders, updateGenders }: UserGenderProps) {
  return (
    <div className="mt-4">
      <p className="text-bold">Gender</p>
      <hr />
      <div className="gender-selector d-block w-100 p-3 border border-radius">
        <div
          className={`d-inline-block text-center br-default w-33 cursor-pointer ${
            genders?.length === 2 && 'text-brand'
          }`}
          onClick={() => updateGenders([Gender.Male, Gender.Female])}
        >
          {getGender([Gender.All])[1]}
        </div>
        <div
          className={`d-inline-block text-center br-default w-33 cursor-pointer ${
            genders?.length === 1 && genders[0] === Gender.Male && 'text-brand'
          }`}
          onClick={() => updateGenders([Gender.Male])}
        >
          {getGender([Gender.Male])[1]}
        </div>
        <div
          className={`d-inline-block text-center w-33 cursor-pointer ${
            genders?.length === 1 && genders[0] === Gender.Female && 'text-brand'
          }`}
          onClick={() => updateGenders([Gender.Female])}
        >
          {getGender([Gender.Female])[1]}
        </div>
      </div>
    </div>
  )
}
