import React from 'react'
import { AddTracking } from './AddTracking'
import { TrackingParam } from './TrackingParam'
import Typography from '@material-ui/core/Typography'
import { LeadCardCreateDTO } from '@marketing-milk/interfaces'

export interface FormTrackingProps {
  editable: boolean
  trackingParams?: LeadCardCreateDTO['settings']['trackingParams']
  onSaveParam(oldID: string, id: string, value: string): void
  onDeleteParam(id: string): void
}

export function FormTracking({
  editable,
  trackingParams,
  onSaveParam,
  onDeleteParam,
}: FormTrackingProps) {
  return (
    <div className="form-group mt-3">
      <div>
        <Typography variant="h5">Tracking</Typography>
        <Typography>
          You can include tracking parameters to help you identify where your leads come from. These
          fields won't appear in the form users see when they submit their info, but you'll be able
          to see it when you access your leads via API, Download, or configured CRM.
        </Typography>
      </div>
      <hr className="my-3" />
      <div>
        {trackingParams &&
          Object.entries(trackingParams).map(([id, value], i) => (
            <TrackingParam
              key={i}
              id={id}
              value={value}
              onSaveParam={onSaveParam}
              onDelete={onDeleteParam}
              editable={editable}
            />
          ))}
        <AddTracking onSaveParam={onSaveParam} editable={editable} />
      </div>
    </div>
  )
}
