import {
  adCreativeService,
  useGetAllBusinesses,
  useGetBusinessID as useGetCurrentBusinessID,
} from '@marketing-milk/frontend'
import { Business } from '@marketing-milk/interfaces'
import { ServerError } from 'components/server-request/server-error/ServerError'
import { useState } from 'react'
import Select, { SingleValue } from 'react-select'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ShareCreativeModalProps } from './ShareCreativeModal.types'

export function ShareCreativeModal({ creative, isVisible, onToggle }: ShareCreativeModalProps) {
  const currentBusinessID = useGetCurrentBusinessID()
  const businesses = useGetAllBusinesses()
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>()

  if (!isVisible) {
    return null
  }

  const handleBusinessSelect = (val: SingleValue<Business>) => {
    setSelectedBusiness(val)
  }

  async function handleShareCreative() {
    setLoading(true)
    try {
      if (!selectedBusiness) return
      await adCreativeService.shareCreative(
        Number(currentBusinessID),
        creative.id,
        selectedBusiness.id
      )
      toast.success('Creative has been shared')
      onToggle()
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  async function handleModalClose() {
    // Unset selections to prevent undefined behavior when clicking off modal and re-entering
    setSelectedBusiness(null)
    onToggle()
  }

  if (error) {
    return <ServerError error={error} />
  }

  return (
    <Modal isOpen size="lg" toggle={handleModalClose}>
      <ModalHeader>Share Your Creative</ModalHeader>
      <ModalBody data-testid="share-creative-modal">
        <form role="form">
          <label htmlFor="creative-name" className="font-bold">
            Creative Name
          </label>
          <input
            id="creative-name"
            name="creative-name"
            className="border rounded-sm w-full p-2 mb-4 bg-gray"
            value={creative.name}
            disabled
          />
          <label htmlFor="business-dropdown" className="font-bold">
            Target Business
          </label>
          <Select<Business>
            inputId="business-dropdown"
            name="businesses"
            options={businesses}
            onChange={handleBusinessSelect}
            getOptionLabel={(b: Business) => b.businessName}
            getOptionValue={(b: Business) => b.id.toString()}
            className="mb-4"
          />
        </form>
      </ModalBody>
      <ModalFooter className="text-right">
        <button className="btn btn-light ml-0 mr-auto" onClick={handleModalClose}>
          Cancel
        </button>
        <button
          className="btn btn-success"
          disabled={loading || !selectedBusiness}
          onClick={handleShareCreative}
        >
          {!loading ? (
            'Share Creative'
          ) : (
            <>
              <i className="fa fa-spin fa-spinner mr-2" /> Sharing Creative
            </>
          )}
        </button>
      </ModalFooter>
    </Modal>
  )
}
