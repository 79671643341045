import {
  HoverTip,
  ProfilePicture,
  RenderWhenVisible,
  useGetAllBusinesses,
} from '@marketing-milk/frontend'
import { Business } from '@marketing-milk/interfaces'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { SearchInput } from 'components'
import { FullPageHeader } from 'components/layout/FullPageLayout/Header/FullPageHeader'
import { UserLayout } from 'components/layout/UserLayout'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ErrorToolTip } from '../campaigns/Campaigns'
import ErrorIcon from '@material-ui/icons/Error'

const BusinessListShell = (props: { onSearch: (searchTerm: string) => void; children: any }) => (
  <UserLayout>
    <FullPageHeader breadcrumbs={[{ title: 'My Businesses' }]}>
      <SearchInput onSearch={props.onSearch} placeholder="Search My Businesses" className="w-1/3" />
    </FullPageHeader>

    <div className="flex-grow">{props.children}</div>
  </UserLayout>
)

const useStyles = makeStyles(theme => ({
  tableRow: {
    height: '6em',
  },
}))

export function BusinessList() {
  const classes = useStyles()
  const businesses = useGetAllBusinesses()
  const history = useHistory()
  const [filter, setFilter] = useState('')
  const [filteredRows, setFilteredRows] = useState<Business[]>([])

  useEffect(() => {
    setFilteredRows(
      filter
        ? businesses.filter(r => r.businessName.toLowerCase().includes(filter.toLowerCase()))
        : businesses
    )
  }, [businesses, filter])

  const getTableHeaders = () => ['', 'Business', 'Tools']

  return (
    <BusinessListShell
      onSearch={searchTerm => {
        setFilter(searchTerm)
      }}
    >
      <div data-testid="business-table" className="bg-white">
        <Table aria-label="business list table">
          <TableHead>
            <TableRow>
              {getTableHeaders().map((header, i) => (
                <TableCell key={i}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((biz, i) => {
              return (
                <TableRow>
                  <TableCell>
                    <ProfilePicture
                      initials={biz.businessName.slice(0, 1)}
                      image={biz.fbPageImgURL}
                    />
                  </TableCell>
                  <TableCell>
                    {biz.fbPageID ? (
                      <Link
                        to={`/${biz.id}/campaigns`}
                        className="text-blue-400"
                        data-testid="business-display-name"
                      >
                        {biz.businessName}
                      </Link>
                    ) : (
                      <ErrorToolTip title="You cannot use Ad Builder without a Facebook Page configured">
                        <div className="text-red-800" data-testid="business-display-name-error">
                          {biz.businessName}
                          &nbsp;
                          <ErrorIcon />
                        </div>
                      </ErrorToolTip>
                    )}
                  </TableCell>
                  <TableCell>
                    <RenderWhenVisible>
                      {biz.fbPageID && (
                        <div data-testid="business-nav-links" className="d-flex">
                          <HoverTip name={'builderLink-' + i} description={<span>Campaigns</span>}>
                            <i
                              className={'menu-icon fad fa-tools mr-3 cursor-pointer'}
                              onClick={() => history.push(`${biz.id}/campaigns`)}
                            />
                          </HoverTip>
                          <HoverTip
                            name={'audiencesLink-' + i}
                            description={<span>Audiences</span>}
                          >
                            <i
                              className={'menu-icon fad fa-book-user mr-3 cursor-pointer'}
                              onClick={() => history.push(`${biz.id}/audiences`)}
                            />
                          </HoverTip>
                          <HoverTip
                            name={'chubLink-' + i}
                            description={<span>Creative Builder</span>}
                          >
                            <i
                              className={'menu-icon fad fa-images mr-3 cursor-pointer'}
                              onClick={() => history.push(`${biz.id}/creatives`)}
                            />
                          </HoverTip>
                          <HoverTip
                            name={'leadCardLink-' + i}
                            description={<span>Lead Cards</span>}
                          >
                            <i
                              className={'menu-icon fad fa-address-card mr-3 cursor-pointer'}
                              onClick={() => history.push(`${biz.id}/lead-cards`)}
                            />
                          </HoverTip>
                          <HoverTip
                            name={'localCompetitionFinder-' + i}
                            description={<span>Local Competition</span>}
                          >
                            <i
                              className={'menu-icon fad fa-radar mr-3 cursor-pointer'}
                              onClick={() =>
                                history.push(`${biz.id}/competition?competitorPlaceID=&radius=1`)
                              }
                            />
                          </HoverTip>
                        </div>
                      )}
                    </RenderWhenVisible>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </BusinessListShell>
  )
}
