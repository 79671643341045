import React, { useState } from 'react'
import { capitalCase } from 'change-case'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useHistory } from 'react-router-dom'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import LoadingButton from '@mui/lab/LoadingButton'
import InputLabel from '@material-ui/core/InputLabel'
import DialogTitle from '@material-ui/core/DialogTitle'
import { LocationSearch } from '@marketing-milk/frontend'
import { useGetBusinessID } from '@marketing-milk/frontend'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { PlaceType1 } from '@googlemaps/google-maps-services-js'
import { patchCompetitionDetails } from './use-local-competition'
import DialogContentText from '@material-ui/core/DialogContentText'
import { LocationCustomTargetSpec } from '@marketing-milk/interfaces'

export declare type BusinessTypeOption = {
  label: string
  value: PlaceType1
}

const businessTypes: BusinessTypeOption[] = (Object.keys(PlaceType1) as Array<string>).map(
  type => ({
    label: capitalCase(type),
    //@ts-ignore
    value: PlaceType1[type as typeof PlaceType1],
  })
)

export function CompetitionDetailsForm() {
  const businessID = useGetBusinessID()
  const [businessType, setBusinessType] = useState<string>()
  const [location, setLocation] = useState<Required<LocationCustomTargetSpec> | undefined>()
  const [customType, setCustomType] = useState('')
  const [displayCustomType, setDisplayCustomType] = useState(false)
  const history = useHistory()
  const [detailsOpen, setDetailsOpen] = useState(true)
  const [loading, setLoading] = useState(false)

  const saveDetails = () => {
    if (!location || (!businessType && !customType)) return
    setLoading(true)
    try {
      patchCompetitionDetails(businessID, {
        //@ts-ignore
        businessType: customType ? customType : businessType!,
        address: location?.address_string ?? '',
        latitude: Number(location?.latitude) ?? 0,
        longitude: Number(location?.longitude) ?? 0,
      })
      history.go(0)
    } catch (e) {
      setLoading(false)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      PaperProps={{ style: { minWidth: 600, minHeight: 450 } }}
      open={detailsOpen}
      onClose={() => setDetailsOpen(false)}
    >
      <DialogTitle>Business Not Configured Correctly</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add the address of your business and select the type of business to see competitors in
          your area.
        </DialogContentText>
        <InputLabel id="business-address-label">Business Address</InputLabel>
        <LocationSearch
          actionType="include"
          addLocation={(location: Required<LocationCustomTargetSpec>) => setLocation(location)}
          setActionType={() => {}}
          setMapData={() => {}}
          onlySearch
          location={location}
        />

        {!displayCustomType ? (
          <>
            <InputLabel shrink className="mt-3" id="business-type-select-label">
              Business Type
            </InputLabel>
            <Select
              labelId="business-type-select-label"
              id="business-type-select"
              value={businessType}
              fullWidth
              disabled={!!customType}
              placeholder="Select Business Type"
              label="Business Type"
              inputProps={{
                'aria-label': 'business type select',
                'data-testid': 'business-type-select',
              }}
              onChange={event => setBusinessType(event.target.value as PlaceType1)}
            >
              {businessTypes.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : (
          <TextField
            className="mt-3"
            label="Custom Business Type"
            value={customType}
            placeholder="e.g. Marketing Company"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={e => setCustomType(e.target.value)}
          />
        )}
        <div className="w-100 my-3">
          <Button
            variant="text"
            className="mx-auto text-center"
            onClick={() => setDisplayCustomType(!displayCustomType)}
          >
            Or {!displayCustomType ? 'enter a custom' : 'select a'} business type
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => history.push(`/${businessID}/campaigns`)}>Cancel</Button>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          disabled={(!businessType && !customType) || !location}
          onClick={() => saveDetails()}
        >
          Save Details
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
