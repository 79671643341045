import { User } from '@marketing-milk/interfaces'

export interface NavLinkI {
  url: string
  icon: string
  label: string
  visible?: boolean
}

export function getNavLinks(busID: string | number = 0, user?: User): NavLinkI[] {
  return [
    {
      label: 'Campaigns',
      icon: 'megaphone',
      url: `${busID}/campaigns`,
    },
    {
      label: 'Audiences',
      icon: 'book-user',
      url: `${busID}/audiences`,
    },
    {
      label: 'Creatives',
      icon: 'images',
      url: `${busID}/creatives`,
    },
    {
      label: 'Lead Cards',
      icon: 'address-card',
      url: `${busID}/lead-cards`,
    },
    {
      label: 'Sprints',
      icon: 'calendar',
      url: `${busID}/sprints`,
    },
    {
      label: 'Local Competition',
      icon: 'radar',
      url: `${busID}/competition`,
    },
  ]
}
