import React from 'react'

interface FormPageContentProps {
  className?: string
  children: React.ReactNode
}
export const FormPageContent = (props: FormPageContentProps) => (
  <form className={`sm:w-1/3 p-2 bg-white rounded-md shadow-lg ${props.className}`}>
    {props.children}
  </form>
)
