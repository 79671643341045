import { toast } from 'react-toastify'
import { useState } from 'react'
import { DeleteCreativeModalProps } from './DeleteCreativeModal.types'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { adCreativeService, useGetBusinessID } from '@marketing-milk/frontend'
import { ServerError } from 'components/server-request/server-error/ServerError'
import { useCreatives } from 'pages/business/creatives/useCreatives'
import { ConfirmationModal } from 'components/ConfirmationModal'

export function DeleteCreativeModal({ creative, isVisible, onToggle }: DeleteCreativeModalProps) {
  const businessID = useGetBusinessID()
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const { refetch } = useCreatives(businessID)

  if (!isVisible) {
    return null
  }

  async function onConfirmDelete() {
    setLoading(true)
    try {
      await adCreativeService.deleteByID(businessID, creative.id)
      refetch()
      onToggle()
      toast.success('Creative has been deleted')
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  if (error) {
    return <ServerError error={error} />
  }

  return (
    <Modal isOpen toggle={onToggle}>
      <ModalBody>
        <div className="text-center mt-2 space-y-4">
          <h4>Are You Sure?</h4>
          <p>
            You're about to <span className="text-danger">delete {creative.name}</span>. Ads using
            this creative will also be deleted in Marketing Milk and in Facebook. This cannot be
            undone.
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="text-right">
        <button className="btn btn-light ml-0 mr-auto" onClick={onToggle}>
          Cancel
        </button>
        <button className="btn btn-danger" disabled={loading} onClick={onConfirmDelete}>
          {!loading ? (
            'Delete Creative'
          ) : (
            <>
              <i className="fa fa-spin fa-spinner mr-2" /> Deleting Creative
            </>
          )}
        </button>
      </ModalFooter>
    </Modal>
  )
}
