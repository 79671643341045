import { NestAPI, useGetBusiness, useGetBusinessID } from '@marketing-milk/frontend'
import { DraftCampaign, DraftCampaignDTO, Sprint, SprintDTO } from '@marketing-milk/interfaces'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export type NestControllers = 'sprints' | 'draft-campaigns'
export type NestEndpoint<T extends NestControllers> = `businesses/${number}/${T}`
export type GetEndpointData<T extends NestControllers> = T extends 'sprints'
  ? [Sprint, SprintDTO]
  : T extends 'draft-campaigns'
  ? [DraftCampaign, DraftCampaignDTO]
  : never

export const useNest = <T extends NestControllers>(controller: T) => {
  const { businessID } = useParams<{ businessID?: string }>()

  type Entity = GetEndpointData<T>[0]
  type DTO = GetEndpointData<T>[1]

  const endpoint = `businesses/${businessID}/${controller}`

  const {
    data: list,
    refetch,
    isLoading,
    isFetched,
    isError,
  } = useQuery([controller, businessID], async () => await getAll(), { retry: false })

  const getAll = async () => {
    const response = await NestAPI.get<Entity[]>(`${endpoint}`)
    return response.data
  }

  const getSingle = async (id?: number) => {
    if (!id) {
      return undefined
    }
    const response = await NestAPI.get<Entity>(`${endpoint}/${id}`)
    if (response.data === undefined) toast.error(`Cannot find given entity in ${controller}`)
    return response.data
  }

  const create = (dto: DTO) =>
    NestAPI.post<Entity>(`${endpoint}`, dto)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        toast.error(`Create action failed: ${err}`)
        return undefined
      })

  const update = async (id: number, dto: DTO) => {
    await NestAPI.patch(`${endpoint}/${id}`, dto).catch(err =>
      toast.error(`Update action failed: ${err}`)
    )
    refetch()
  }

  const deleteItem = async (sprintID: number) => {
    await NestAPI.delete(`${endpoint}/${sprintID}`)
      .then(_ => toast.success('Item Deleted'))
      .catch(err => toast.error(`Delete action failed: ${err}`))
    refetch()
  }

  return { list, getSingle, create, update, deleteItem, refetch, isLoading, isFetched, isError }
}
