import React from 'react'
import Moment from 'moment'
import { ItemDetailsCard } from 'components'
import { HoverTip } from '@marketing-milk/frontend'
import { SavedAudience } from '@marketing-milk/interfaces'
import { AudienceTools } from '../audience-tools/AudienceTools'
import { AudienceStatusIndicator } from '../../status-indicator'
import { generateAudienceSize } from 'pages/business/audiences/list-audiences/const'
import { audienceTypes } from 'pages/business/audiences/list-audiences/ListAudiences.types'

export interface DefaultAudienceCardsProps {
  defaultAudiences: SavedAudience[] | undefined
  onSetDefault: (audience: SavedAudience, isDefault: boolean) => void
  onDelete: (audience: SavedAudience) => void
  onView: (audience: SavedAudience) => void
  onEdit: (audience: SavedAudience) => void
  onRecalc: (audience: SavedAudience) => void
}

export function DefaultAudienceCards(props: DefaultAudienceCardsProps) {
  const { defaultAudiences, onSetDefault, onDelete, onView, onEdit, onRecalc } = props

  const writeBottomRow = (audience: SavedAudience, index: number) => (
    <div className="row">
      <div className="col-12 col-md-6 text-center py-2 br-default">
        <HoverTip
          name={`campaign-count-${index}`}
          description={<span>Used in {audience.numberOfTimesUsed} Marketing Milk campaigns</span>}
        >
          <i className="fad fa-ad text-inherit mr-2" /> {audience.numberOfTimesUsed}
        </HoverTip>
      </div>
      <div className="col-12 col-md-6 text-center py-2  border-right-lg border-right-sm-0 border-right">
        {generateAudienceSize(audience, onRecalc, true)}
      </div>
    </div>
  )

  if (!defaultAudiences || defaultAudiences?.length === 0) return null
  return (
    <div className="overflow-x-hidden w-full overflow-y-auto">
      <div className="flex">
        <h3 className="text-xl font-medium p-2 pl-0">Default Audiences</h3>
      </div>
      <div className="flex">
        {defaultAudiences?.map((audience, index) => (
          <ItemDetailsCard
            key={index}
            header={
              <div>
                <div className="float-left">
                  <i className="fad fa-star text-brand text-inherit" />
                </div>
                <div className="float-right">
                  <AudienceTools
                    onEdit={onEdit}
                    onView={onView}
                    onDelete={onDelete}
                    onSetDefault={onSetDefault}
                    audience={audience}
                    dotPattern="h"
                  />
                </div>
              </div>
            }
            body={
              <div className="row">
                <div className="col-12 text-center">
                  <h6
                    className="overflow-ellipsis mt-2 mb-0 cursor-pointer text-bold"
                    onClick={() => onView(audience)}
                  >
                    {audience.name}
                  </h6>
                  <div className="mb-3 audience-type">
                    {audience.type ? (
                      <span>
                        <i
                          className={`fad fa-${
                            audienceTypes[audience.type].icon
                          } text-inherit mr-2`}
                        />
                        {audienceTypes[audience.type].title}
                      </span>
                    ) : (
                      'Facebook Imported'
                    )}
                  </div>
                  <AudienceStatusIndicator status={audience.status} className="mx-auto mb-3" />
                  <small className="text-italics text-subtext">
                    Last Updated: {Moment.unix(audience.updated_at).fromNow()}
                  </small>
                </div>
              </div>
            }
            footer={writeBottomRow(audience, index)}
          />
        ))}
      </div>
    </div>
  )
}
