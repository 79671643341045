import React from 'react'
import { InputAdornment, TextField } from '@mui/material'
import MonitizationOn from '@mui/icons-material/MonetizationOn'
import { budgetIsValid } from '../../../helpers/campaignForm.helpers'

type Props = {
  budget?: number
  setBudget: (Budget: number) => void
}

export const CampaignBudgetField = ({ budget, setBudget }: Props) => (
  <div className="w-full grid sm:grid-cols-2 py-4 border-b">
    <p className="text-lg font-medium text-gray-600">Campaign Budget</p>
    <TextField
      type="number"
      required
      error={!budgetIsValid(budget)}
      helperText="must be over $50 and under $10,000"
      label="Campaign Budget"
      placeholder="Campaign Budget"
      defaultValue={budget}
      variant="standard"
      onChange={({ target }) => {
        setBudget(Number(target.value))
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MonitizationOn />
          </InputAdornment>
        ),
      }}
    />
  </div>
)
