import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { FacebookLeadgenSharingOptions } from '@marketing-milk/interfaces'

export interface FormSharingProps {
  editable: boolean
  sharing: FacebookLeadgenSharingOptions
  onChangeSharing(sharing: FacebookLeadgenSharingOptions): void
}

export function FormSharing({ editable, sharing, onChangeSharing }: FormSharingProps) {
  const sharingExplanation =
    sharing === 'restricted'
      ? 'Only people who are delivered your ad directly can submit this form.'
      : 'Your ad can be shared and anyone can submit this form.'

  return (
    <div className="form-group">
      <InputLabel disabled={!editable} shrink className="text-bold">
        Sharing
      </InputLabel>
      <ToggleButtonGroup
        value={sharing}
        onChange={(event, sharing) => onChangeSharing(sharing as FacebookLeadgenSharingOptions)}
        exclusive
      >
        <ToggleButton disabled={!editable} size="small" value="restricted">
          Restricted
        </ToggleButton>
        <ToggleButton disabled={!editable} size="small" value="open">
          Open
        </ToggleButton>
      </ToggleButtonGroup>
      <Typography variant="body1" className="mt-2">
        {sharingExplanation}
      </Typography>
    </div>
  )
}
