import { InputAdornment, TextField } from '@mui/material'
import MonetizationOn from '@mui/icons-material/MonetizationOn'

type Props = {
  campaignIndex: number
  budget: number
  setBudget: (budget: number) => void
}

export const CondensedCampaignBudgetField = (props: Props) => (
  <TextField
    variant="outlined"
    name={`campaign-${props.campaignIndex}`}
    value={props.budget || ''}
    type="number"
    label={'Budget'}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <MonetizationOn />
        </InputAdornment>
      ),
    }}
    onChange={({ target }) => props.setBudget(Number(target.value))}
    required
  />
)
