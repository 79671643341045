import { CampaignGoalType, CampaignGoalName } from '@marketing-milk/interfaces'

export interface CampaignGoalOptimizationI {
  icon: string
  name: string
  type: CampaignGoalType
  description: string
  lastUsed: boolean
}

export const campaignGoalOptimizations: CampaignGoalOptimizationI[] = [
  {
    icon: 'store',
    name: CampaignGoalName.InStoreSales,
    type: CampaignGoalType.InStoreSales,
    description: 'Drive more people to your brick-and-mortar business location.',
    lastUsed: false,
  },
  {
    icon: 'cart-arrow-down',
    name: CampaignGoalName.ECommerceSales,
    type: CampaignGoalType.ECommerceSales,
    description: 'Target individuals known for buying online to increase sales.',
    lastUsed: false,
  },
  {
    icon: 'user-plus',
    name: CampaignGoalName.LeadGeneration,
    type: CampaignGoalType.LeadGeneration,
    description: 'Generate more qualified leads to increase sign-ups and service sales',
    lastUsed: true,
  },
  {
    icon: 'desktop',
    name: CampaignGoalName.WebsiteSessions,
    type: CampaignGoalType.WebsiteSessions,
    description: 'Drive more traffic to your website and further down your funnel',
    lastUsed: false,
  },
]
