import React, { useEffect, useState } from 'react'
import { addMonths, compareAsc, fromUnixTime, getUnixTime, isAfter, isBefore } from 'date-fns'
import { CampaignGoal } from 'pages/business/campaign-builder/steps/details-and-optimization/campaign-goal'
import {
  PublisherPlatforms,
  usePublisherPlatforms,
} from 'pages/business/campaign-builder/steps/publisher-platforms'
import { filter, pipe, set } from 'lodash/fp'
import { PublishCampaignDTO } from '@marketing-milk/interfaces'
import {
  campaignHasStarted,
  combineTime,
  getGoalFromOptimization,
} from '../../helpers/campaignForm.helpers'
import { CampaignNameField, CampaignBudgetField, CampaignDurationField } from '../fields'
import { first } from 'lodash'
import { rejectNull } from '../../../../../app/util'
import { useGetBusiness } from '@marketing-milk/frontend'
import { utcToZonedTime } from 'date-fns-tz'

type Props = {
  isEdit: boolean
  campaignDto?: PublishCampaignDTO
  setCampaignDto: React.Dispatch<React.SetStateAction<PublishCampaignDTO | undefined>>
  errors?: string[]
}

export const CampaignSettingsStep = ({ campaignDto, setCampaignDto, isEdit }: Props) => {
  const { handlePlatformModeChange, handlePlatformSelection, selectedPublisherPlatforms } =
    usePublisherPlatforms(campaignDto?.publisherPlatforms)

  const defaultDate = new Date(new Date().setHours(12, 0, 0, 0))
  const defaultStartDate = campaignDto?.campaign?.startTime
    ? fromUnixTime(campaignDto.campaign.startTime)
    : defaultDate
  const defaultEndDate = campaignDto?.campaign?.endTime
    ? fromUnixTime(campaignDto.campaign.endTime)
    : addMonths(defaultDate, 3)

  useEffect(() => {
    setCampaignDto(set('publisherPlatforms', selectedPublisherPlatforms))
  }, [selectedPublisherPlatforms, setCampaignDto])

  const business = useGetBusiness()

  const [startDay, setStartDay] = useState(defaultStartDate)
  const [endDay, setEndDay] = useState(defaultEndDate)

  const [startTime, setStartTime] = useState(
    isEdit
      ? utcToZonedTime(defaultStartDate, business.timezone)
      : new Date(defaultStartDate.setHours(12, 0, 0, 0))
  )
  const [endTime, setEndTime] = useState(
    isEdit
      ? utcToZonedTime(defaultEndDate, business.timezone)
      : new Date(defaultEndDate.setHours(12, 0, 0, 0))
  )

  const updateDate = (updateType: 'startTime' | 'endTime', newDate?: Date, newTime?: Date) => {
    if (newDate && updateType === 'startTime') {
      setStartDay(newDate)
    } else if (newDate) {
      setEndDay(newDate)
    }
    setCampaignDto(
      set(
        ['campaign', updateType],
        getUnixTime(
          updateType === 'startTime'
            ? combineTime(newDate ?? startDay, newTime ?? startTime, business.timezone)
            : combineTime(newDate ?? endDay, newTime ?? endTime, business.timezone)
        )
      )
    )
  }

  return (
    <div className="w-full flex flex-col max-w-7xl mx-auto p-4 space-y-4">
      <h1 className="py-2 font-lg font-bold mx-auto">
        {campaignDto?.campaign?.name || 'Campaign '} Settings
      </h1>
      <h2 className="font-md font-semibold text-gray-500 mb-2">General Settings</h2>

      {/* Campaign Name */}
      <CampaignNameField
        name={campaignDto?.campaign?.name || ''}
        setName={name => {
          setCampaignDto(set(['campaign', 'name'], name))
        }}
      />

      {/* Campaign Budget */}
      <CampaignBudgetField
        budget={campaignDto?.campaign?.budget || 250}
        setBudget={budget => {
          setCampaignDto(set(['campaign', 'budget'], budget))
        }}
      />

      {/* Campaign Duration */}
      <CampaignDurationField
        duration={[startDay, endDay]}
        setDuration={dateRange => {
          const [start, end] = dateRange
          // only allow the end date to be extended if that campaign is active
          if (
            isEdit &&
            !!campaignDto?.campaign &&
            campaignHasStarted(campaignDto.campaign.startTime)
          ) {
            const startDate = fromUnixTime(campaignDto.campaign.startTime)
            const orderByDateAsc = (dates: Date[]) => dates.sort(compareAsc)

            const newEndDate =
              pipe(
                rejectNull,
                filter<Date>(chosenDate => isAfter(chosenDate, startDate)),
                orderByDateAsc, // sorts so that first element is latest date that isnt the start date
                first
              )(dateRange) ?? defaultEndDate

            // mutate the date range directly to prevent MUI Datepicker from autosetting start date
            dateRange[0] = startDate
            dateRange[1] = newEndDate
            updateDate('endTime', newEndDate)
          } else {
            if (isBefore(end || defaultStartDate, start || defaultEndDate)) return
            if (start) updateDate('startTime', start)
            if (end) updateDate('endTime', end)
          }
        }}
        disableStart={isEdit && campaignHasStarted(campaignDto?.campaign?.startTime)}
        startTime={startTime}
        setStartTime={start => {
          if (start) {
            setStartTime(start)
            updateDate('startTime', undefined, start)
          }
        }}
        endTime={endTime}
        setEndTime={end => {
          if (end) {
            setEndTime(end)
            updateDate('endTime', undefined, end)
          }
        }}
      />

      {/* Campaign Goal */}
      <h2 className="font-md font-semibold text-gray-500">Campaign Goal</h2>
      <div>
        <CampaignGoal
          goal={getGoalFromOptimization(campaignDto?.campaign?.goal)}
          onSelectGoal={goal => {
            setCampaignDto(set(['campaign', 'goal'], goal.type))
          }}
          pixelEventOrCCID={
            campaignDto?.campaign?.customConversionID ?? campaignDto?.campaign?.pixelType
          }
          onCustomizeGoalConfig={ccOrPixelType =>
            setCampaignDto(
              set(
                [
                  'campaign',
                  typeof ccOrPixelType === 'number' ? 'customConversionID' : 'pixelType',
                ],
                ccOrPixelType
              )
            )
          }
        />
      </div>

      {/* Campaign Publisher Platforms Input */}
      <h2 className="font-md font-semibold text-gray-500">Publisher Platforms</h2>
      <div>
        <PublisherPlatforms
          handleModeChange={handlePlatformModeChange}
          handlePlatformSelection={handlePlatformSelection}
          selectedPlatforms={selectedPublisherPlatforms}
        />
      </div>
    </div>
  )
}
