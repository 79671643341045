import { useState } from 'react'
import { useBaseAudience } from './use-base-audience'
import { FbMenuSelection, IgMenuSelection } from './steps'
import { FBPageName, SavedAudience } from '@marketing-milk/interfaces'
import { useInstagramAccounts, usePageData } from '@marketing-milk/frontend'

export interface EngagementAudienceData {
  instagramRetention: number
  instagramSelectedCategory: IgMenuSelection
  instagramAccountID: string
  pageRetention: number
  pageSelectedCategory: FbMenuSelection
}

export function useEngagementAudience(businessID: string, audience?: SavedAudience) {
  const baseAudience = useBaseAudience(businessID, audience)
  const facebookPageData: FBPageName | undefined = usePageData()
  const igAccounts = useInstagramAccounts()
  const [audienceData, setAudienceData] = useState<EngagementAudienceData>({
    instagramRetention: 145,
    instagramSelectedCategory: 'ig_business_profile_all',
    instagramAccountID: '',
    pageRetention: 145,
    pageSelectedCategory: 'page_visited',
  })

  const selectedAccount = igAccounts?.accounts?.find(x => x.id === audienceData.instagramAccountID)

  function onUpdateIgRetention(retention: number) {
    setAudienceData({
      ...audienceData,
      instagramRetention: retention,
    })
  }

  function onUpdateIgAccountID(accountID: string) {
    setAudienceData({
      ...audienceData,
      instagramAccountID: accountID,
    })
  }

  function onUpdateIgCategory(category: IgMenuSelection) {
    setAudienceData({
      ...audienceData,
      instagramSelectedCategory: category,
    })
  }

  function onPageRetentionUpdate(retention: number) {
    setAudienceData({
      ...audienceData,
      pageRetention: retention,
    })
  }

  function onUpdatePageCategory(category: FbMenuSelection) {
    setAudienceData({
      ...audienceData,
      pageSelectedCategory: category,
    })
  }

  return {
    ...baseAudience,
    audienceData,
    facebookPageData,
    igAccounts,
    selectedAccount,
    onUpdateIgRetention,
    onUpdateIgAccountID,
    onUpdateIgCategory,
    onPageRetentionUpdate,
    onUpdatePageCategory,
  }
}
