import React, { useState } from 'react'
import { leadCardPreviewContext } from './LeadCardPreviewContext'
import { LeadCardPreviewContextProviderProps } from './LeadCardPreviewContext.types'

export function LeadCardPreviewContextProvider(props: LeadCardPreviewContextProviderProps) {
  const { leadCard, business } = props
  const [previewSection, setPreviewSection] = useState(0)
  // overrides internal state
  const section = props.section ?? previewSection
  // overrides internal state setter
  const setSection = props.setSection ?? setPreviewSection
  return (
    <leadCardPreviewContext.Provider value={{ leadCard, business, section, setSection }}>
      {props.children}
    </leadCardPreviewContext.Provider>
  )
}
