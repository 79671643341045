import Link from '@material-ui/core/Link'
import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'

export interface TextBulletsProps {
  bullets: string[]
}

export function TextBullets({ bullets }: TextBulletsProps) {
  const [isExpanded, setExpanded] = useState(false)

  useEffect(() => {
    if (bullets.length < 3 && isExpanded) {
      setExpanded(false)
    }
  }, [bullets])

  const displayedBullets = isExpanded ? [...bullets] : bullets.slice(0, 2)
  return (
    <>
      <ul style={{ fontSize: 14 }}>
        {displayedBullets.map((bullet, i) => (
          <li key={`bullet_${i}`}>
            <Typography variant="body1">{bullet}</Typography>
          </li>
        ))}
        {!isExpanded && bullets.length > 2 && (
          <Link href="#" color="primary" onClick={() => setExpanded(true)}>
            Show more...
          </Link>
        )}
      </ul>
    </>
  )
}
