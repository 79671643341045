import React from 'react'
import { TextField } from '@material-ui/core'
import { nameIsValid } from '../../../helpers/campaignForm.helpers'

type Props = {
  name: string
  setName: (name: string) => void
}

export const CampaignNameField = ({ name, setName }: Props) => (
  <div className="w-full grid grid-cols-1 sm:grid-cols-2 py-4 border-b">
    <p className="text-lg font-medium text-gray-600">Campaign Name</p>
    <TextField
      id="campaignNameField"
      required
      autoFocus
      error={!nameIsValid(name)}
      helperText="name is required, must be under 256 characters"
      label="Campaign Name"
      placeholder="Campaign Name"
      defaultValue={name}
      onChange={({ target }) => {
        setName(target.value)
      }}
    />
  </div>
)
