import { Sprint } from '@marketing-milk/interfaces'
import { Button } from '@mui/material'
import { FullPageSidebar } from 'components/layout/FullPageLayout/Sidebar/FullPageSidebar'
import { SidebarSection } from 'components/layout/FullPageLayout/Sidebar/SidebarSection'
import { format, formatDistance, fromUnixTime, isAfter, isBefore } from 'date-fns'
import { getStartEndTimeText } from '../../sprint.helpers'

interface SprintsSidebarProps {
  sprints?: Sprint[]
  selectedSprintId?: number
  setSelectedSprintId: SetState<number | undefined>
}

export const SprintsSidebar = (props: SprintsSidebarProps) => {
  return (
    <FullPageSidebar>
      {props.sprints?.map(sprint => (
        <SidebarSection
          key={sprint.id}
          onClick={() => props.setSelectedSprintId(sprint.id)}
          title={sprint.name}
          className={`${
            props.selectedSprintId === sprint.id && 'bg-blue-200 border border-blue-800'
          } cursor-pointer hover:bg-gray-200`}
        >
          {getStartEndTimeText(sprint)}
        </SidebarSection>
      ))}
    </FullPageSidebar>
  )
}
