import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { UserLayout } from 'components/layout/UserLayout'
import { CreativeWizard } from './creative-wizard/CreativeWizard'
import { useFetchCreativeByID, useGetBusinessID, useGetParam } from '@marketing-milk/frontend'
import { AdBlockerDetected, AdBlockerService } from '@marketing-milk/frontend'
import { Paper, Button } from '@material-ui/core'
import AdBlockImg from 'public/img/misc/ad-block.png'

import { FullPageContent } from 'components/layout/FullPageLayout/Content/FullPageContent'
import { FullPageHeader } from 'components/layout/FullPageLayout/Header/FullPageHeader'
import { capitalize } from 'lodash'

export function EditAdCreative() {
  const businessID = useGetBusinessID()
  const [hasAdBlock, setHasAdBlock] = useState(true)
  const history = useHistory()

  useEffect(() => {
    checkAdBlock()
  }, [businessID])

  async function checkAdBlock() {
    const isDetected = await AdBlockerService.checkAdBlocker()
    setHasAdBlock(isDetected)
  }

  if (hasAdBlock) {
    return (
      <UserLayout>
        <Paper className="w-100 text-center p-5">
          <img
            className="img-fluid mb-5 mx-auto"
            style={{ maxWidth: 300 }}
            src={AdBlockImg}
            alt="ad blocker detected graphic"
          />
          <div className="d-block mx-auto" style={{ maxWidth: 700 }}>
            <AdBlockerDetected />
          </div>
          <Button variant="contained" size="large" color="primary" onClick={() => history.go(0)}>
            Refresh Page
          </Button>
        </Paper>
      </UserLayout>
    )
  }

  return <CreativeWizardComponent />
}

export function CreativeWizardComponent() {
  const businessID = useGetBusinessID()
  const creativeID = useGetParam('creativeID')
  const history = useHistory()
  const adCreative = useFetchCreativeByID(businessID, creativeID)
  const location = useLocation()
  const baseRoute = location.pathname.includes('sprints') ? 'sprints' : 'creatives'

  useEffect(() => {
    if (adCreative) {
      history.push(`/${businessID}/${baseRoute}`)
    }
  }, [businessID])

  return (
    <UserLayout>
      <FullPageHeader
        breadcrumbs={[
          { title: capitalize(baseRoute), route: `/${businessID}/${baseRoute}` },
          { title: adCreative?.name || '...loading' },
        ]}
      />

      <FullPageContent className="flex flex-col overflow-x-hidden overflow-y-auto">
        {adCreative && <CreativeWizard defaultCreative={adCreative} />}
      </FullPageContent>
    </UserLayout>
  )
}
