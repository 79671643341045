import React from 'react'
import { toast } from 'react-toastify'
import { getBaseWizardSteps } from '../'
import { AudienceWizardProps } from './AudienceWizard.types'
import { AudienceSummary } from '../steps'
import { useBaseAudience } from '../use-base-audience'
import { audienceBuilderService, useGetBusinessID } from '@marketing-milk/frontend'
import { SavedAudienceDTO, SavedAudienceStandardTargetingSpec } from '@marketing-milk/interfaces'

import { handleSubmitAudienceError } from './audience-error-handling'
import { AudienceWizard } from '../../audience-wizard'

export function HumanMadeAudienceWizard({ onPublishWizard, audience }: AudienceWizardProps) {
  const businessID = useGetBusinessID()
  const baseAudience = useBaseAudience(businessID, audience)

  async function submitAudience() {
    const dto: SavedAudienceDTO = {
      name: baseAudience.audienceName,
      isDefault: baseAudience.isDefault,
      targetingSpec: baseAudience.targetSpec as SavedAudienceStandardTargetingSpec,
    }

    try {
      if (baseAudience.isEditing && audience) {
        await audienceBuilderService.updateMMAudience(businessID, audience.id, dto)
        toast.success("You're audience has been updated!")
      } else {
        await audienceBuilderService.publishHumanMadeAudience(businessID, dto)
        toast.success("You're audience has been created and published!")
      }
      onPublishWizard(true)
    } catch (err) {
      handleSubmitAudienceError(err)
    }
  }

  return (
    <AudienceWizard
      steps={[
        ...getBaseWizardSteps(baseAudience),
        {
          title: 'Summary',
          completed: !!baseAudience.audienceName,
          canContinue: !!baseAudience.audienceName,
          onNext: submitAudience,
          children: (
            <AudienceSummary
              includedUserTraits={baseAudience.getSelectedUserTraits()}
              excludedUserTraits={baseAudience.getExcludedUserTraits()}
              targetingSpec={baseAudience.targetSpec}
              name={baseAudience.audienceName}
              description={baseAudience.audienceDescription}
              onUpdateName={baseAudience.setAudienceName}
              onUpdateDescription={baseAudience.setAudienceDescription}
              onSetDefault={baseAudience.setIsDefault}
              isDefault={baseAudience.isDefault}
            />
          ),
        },
      ]}
    />
  )
}
