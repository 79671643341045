import { Button } from '@material-ui/core'
import { Help } from '@material-ui/icons'
import { Popover } from '@mui/material'
import { useRef, useState } from 'react'

export const SprintHelpIcon = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  return (
    <div ref={ref}>
      <Button onClick={() => setOpen(true)}>
        <Help />
      </Button>

      <Popover
        anchorEl={ref.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <p className="p-4 text-md text-gray-800 max-w-sm">
          Sprints are a group of multiple creatives and campaigns that run in conjuction for a given
          period(normally 3 months)
        </p>
      </Popover>
    </div>
  )
}
