import './DesktopPreview.scss'
import React, { useState } from 'react'
import { PreviewProps } from '../Preview.types'
import IFrameResizer from 'iframe-resizer-react'
import { Loading } from '@marketing-milk/frontend'
import FacebookBG from 'public/img/misc/facebook-background.jpg'
import FacebookNav from 'public/img/misc/facebook-nav.jpg'

export function DesktopPreview({ iframeSource }: PreviewProps) {
  const [isLoaded, setLoaded] = useState(false)
  return (
    <>
      <div className="laptop">
        <div
          className="row w-100 ml-0"
          style={{
            backgroundImage: `url(${FacebookBG})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="row w-100 ml-0">
            <img className="" src={FacebookNav} alt="" />
          </div>
          <div className="col-12">
            <div
              className="mx-auto bg-white mt-1 position-relative"
              style={{
                top: -89,
                border: '1px solid #CFD1DF',
                borderRadius: 4,
                height: 500,
                width: 505,
                padding: 2,
                transform: 'scale(0.6, 0.6)',
              }}
            >
              {!isLoaded && <Loading loadingMessage="Generating Preview" />}
              <IFrameResizer
                src={iframeSource}
                scrolling={true}
                style={{
                  height: 1,
                  minHeight: '100%',
                  width: 1,
                  minWidth: '100%',
                  border: 'none',
                  visibility: isLoaded ? 'visible' : 'hidden',
                }}
                heightCalculationMethod="taggedElement"
                resizeFrom="child"
                onLoad={() => setLoaded(true)}
              />
            </div>
          </div>
        </div>
        <div className="btm" />
        <div className="shadow" />
      </div>
    </>
  )
}
