import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { DeleteAudienceModalProps } from './DeleteAudienceModal.types'
import { audienceBuilderService, Modal } from '@marketing-milk/frontend'
import { ServerError } from '../../server-request/server-error/ServerError'

export function DeleteAudienceModal({ audience, onDelete }: DeleteAudienceModalProps) {
  const [error, setError] = useState<Error>()
  const [isLoading, setLoading] = useState(false)

  function onCancel() {
    onDelete()
  }

  async function onConfirmDelete() {
    try {
      setLoading(true)
      if (!audience) return
      await audienceBuilderService.updateAudience(audience.businessID, audience.id, {
        deleted: true,
      })
      toast.success(`${audience.name} was deleted.`)
      onDelete()
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  if (!audience) {
    return null
  }

  const [buttonIcon, buttonText] = !isLoading ? ['trash-alt', 'Delete'] : ['spinner fa-spin']

  return (
    <>
      <Modal isOpen>
        <div className="row bb-default p-2 pb-3">
          <div className="col-12 text-right">
            <span className="float-left text-bold">Delete Audience</span>
            <i className="fa fa-times cursor-pointer" onClick={onCancel} />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <div className="text-center p-3">
              <h5>Are You Sure?</h5>
              <p>Once you delete this audience you wont be able to undo it.</p>
              <p className="my-2 text-bold fit-content border border-radius mx-auto px-5 py-1">
                <i className="fad fa-user-minus text-inherit mr-2" /> {audience.name}
              </p>
              <br />
              <button className="btn btn-info mr-3" onClick={onCancel}>
                <i className="fas fa-window-close text-inherit mr-1 text-white" /> Cancel
              </button>
              <button className="btn btn-danger" disabled={isLoading} onClick={onConfirmDelete}>
                <i className={`fas fa-${buttonIcon} text-inherit mr-1 text-white`} /> {buttonText}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {error && <ServerError error={error} />}
    </>
  )
}
