import React from 'react'

export interface SelectItemProps {
  title: string
  onClick: () => void
}

export function SelectItem(props: SelectItemProps) {
  const { title, onClick } = props

  return (
    <>
      <div className="fit-content bg-brand text-white px-3 py-2 border-radius float-left mr-3 mb-3">
        <span className="text-bold">
          <i
            className="fas fa-times text-inherit text-white mr-3 cursor-pointer"
            onClick={onClick}
          />
          {title}
        </span>
      </div>
    </>
  )
}
