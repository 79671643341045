import { useGetBusiness } from '@marketing-milk/frontend'
import { SprintDTO } from '@marketing-milk/interfaces'
import { TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  addMonths,
  compareAsc,
  format,
  fromUnixTime,
  getUnixTime,
  isAfter,
  isBefore,
} from 'date-fns'
import { filter, first, pipe, set } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { DescriptiveFieldLayout } from '../../../../../../components/FormFields/DescriptiveFieldLayout'
import { MonthlyBudgetField } from '../Fields/MonthlySprintBudget'
import { SprintDurationField } from '../Fields/SprintDurationField'
import { SprintNameField } from '../Fields/SprintNameField'
import { SprintBudgetField } from '../Fields/TotalSprintBudget'
import { utcToZonedTime } from 'date-fns-tz'
import { campaignHasStarted, combineTime } from '../../../../campaigns/helpers/campaignForm.helpers'
import { rejectNull } from '../../../../../../app/util/util'

type Props = {
  isEdit: boolean
  sprintDto: SprintDTO
  setSprintDto: SetState<SprintDTO>
  amountOfCampaigns?: number
  setAmountOfCampaigns?: SetState<number>
  children?: React.ReactNode
}

export const SprintSettingsStep = ({
  sprintDto,
  setSprintDto,
  amountOfCampaigns = 0,
  setAmountOfCampaigns,
  isEdit,
}: Props) => {
  const onUpdate = <T extends keyof SprintDTO>(property: T, value: SprintDTO[T]) =>
    setSprintDto(set([property], value))

  const defaultDate = new Date(new Date().setHours(12, 0, 0, 0))
  const defaultStartDate = sprintDto.startTime ? fromUnixTime(sprintDto.startTime) : defaultDate
  const defaultEndDate = sprintDto.endTime
    ? fromUnixTime(sprintDto.endTime)
    : addMonths(defaultDate, 3)

  useEffect(() => {
    setSprintDto(set(['name'], getSprintName()))
  }, [sprintDto.startTime, sprintDto.endTime])

  const business = useGetBusiness()

  const [startDay, setStartDay] = useState(defaultStartDate)
  const [endDay, setEndDay] = useState(defaultEndDate)

  const [startTime, setStartTime] = useState(
    isEdit
      ? utcToZonedTime(defaultStartDate, business.timezone)
      : new Date(defaultStartDate.setHours(12, 0, 0, 0))
  )
  const [endTime, setEndTime] = useState(
    isEdit
      ? utcToZonedTime(defaultEndDate, business.timezone)
      : new Date(defaultEndDate.setHours(12, 0, 0, 0))
  )

  const updateDate = (updateType: 'startTime' | 'endTime', newDate?: Date, newTime?: Date) => {
    if (newDate && updateType === 'startTime') {
      setStartDay(newDate)
    } else if (newDate) {
      setEndDay(newDate)
    }
    setSprintDto(
      set(
        [updateType],
        getUnixTime(
          updateType === 'startTime'
            ? combineTime(newDate ?? startDay, newTime ?? startTime, business.timezone)
            : combineTime(newDate ?? endDay, newTime ?? endTime, business.timezone)
        )
      )
    )
  }

  const getSprintName = () =>
    `${format(fromUnixTime(sprintDto.startTime), 'MMM')}-${format(
      fromUnixTime(sprintDto.endTime),
      'MMM'
    )}`

  return (
    <div className="w-full flex flex-col max-w-7xl mx-auto p-4 space-y-4">
      <h1 className="py-2 font-lg font-bold mx-auto">Sprint Settings</h1>
      <p className="font-medium mx-auto font-md text-gray-600 mb-4">
        These are global settings that will effect all creatives and campaigns in this project
      </p>

      <div className="max-w-fit mx-auto mb-8">
        <Alert severity="info" variant="standard" className="flex items-center">
          All Information in this form can be modified after it has been published
        </Alert>
      </div>

      {setAmountOfCampaigns ? (
        <DescriptiveFieldLayout
          label="Amount of Campaigns"
          description="These campaigns will be generated in marketing milk"
        >
          <TextField
            type="number"
            helperText="Must have at least 1 Campaign"
            error={amountOfCampaigns < 1}
            label="Amount Of Campaigns"
            value={amountOfCampaigns ?? '0'}
            onChange={({ target }) => setAmountOfCampaigns(Math.floor(Number(target.value)))}
            inputProps={{
              min: '1',
              step: '1',
            }}
          />
        </DescriptiveFieldLayout>
      ) : (
        <></>
      )}

      <SprintDurationField
        duration={[startDay, endDay]}
        setDuration={dateRange => {
          const [start, end] = dateRange
          // only allow the end date to be extended if that campaign is active
          if (isEdit && campaignHasStarted(sprintDto.startTime)) {
            const startDate = fromUnixTime(sprintDto.startTime)
            const orderByDateAsc = (dates: Date[]) => dates.sort(compareAsc)

            const newEndDate =
              pipe(
                rejectNull,
                filter<Date>(chosenDate => isAfter(chosenDate, startDate)),
                orderByDateAsc, // sorts so that first element is latest date that isnt the start date
                first
              )(dateRange) ?? defaultEndDate

            // mutate the date range directly to prevent MUI Datepicker from autosetting start date
            dateRange[0] = startDate
            dateRange[1] = newEndDate
            updateDate('endTime', newEndDate)
          } else {
            if (isBefore(end || defaultStartDate, start || defaultEndDate)) return
            if (start) updateDate('startTime', start)
            if (end) updateDate('endTime', end)
          }
        }}
        disableStart={isEdit && campaignHasStarted(sprintDto.startTime)}
        startTime={startTime}
        setStartTime={start => {
          if (start) {
            setStartTime(start)
            updateDate('startTime', undefined, start)
          }
        }}
        endTime={endTime}
        setEndTime={end => {
          if (end) {
            setEndTime(end)
            updateDate('endTime', undefined, end)
          }
        }}
      />

      <SprintNameField name={sprintDto.name} setName={name => onUpdate('name', name)} />

      <SprintBudgetField
        budget={sprintDto.budget}
        setBudget={budget => onUpdate('budget', budget)}
      />
      <MonthlyBudgetField sprintDTO={sprintDto} amountOfCampaigns={amountOfCampaigns ?? 0} />
    </div>
  )
}
