import { SavedAudienceType } from '@marketing-milk/interfaces'
import AiAudience from 'public/img/custom-icons/AI_Audience.png'
import CustomerList from 'public/img/custom-icons/Customer_List.png'
import EngagedUsers from 'public/img/custom-icons/Engaged_Users.png'
import HumanMade from 'public/img/custom-icons/Human_Made_Audience.png'
import LeadCards from 'public/img/custom-icons/Lead_Cards.png'
import WebsiteTraffic from 'public/img/custom-icons/Website_Traffic.png'

export interface AudiencePath {
  type: SavedAudienceType
  icon: string
  title: string
  description: string
}

export const audiencePaths: AudiencePath[] = [
  {
    type: SavedAudienceType.AI,
    icon: AiAudience,
    title: 'AI Audience',
    description:
      'Create an AI audience based on people who like your Page, website traffic or existing Custom Audiences.',
  },
  {
    type: SavedAudienceType.HumanMade,
    icon: HumanMade,
    title: 'Human-Made Audience',
    description:
      'Choose your demographics, interests, and behaviors, then save them to reuse in future ads.',
  },
  {
    type: SavedAudienceType.WebsiteTraffic,
    icon: WebsiteTraffic,
    title: 'Website Traffic',
    description:
      'Need more web traffic? Target users who are visiting, or have recently visited your website.',
  },
  {
    type: SavedAudienceType.EngagedUser,
    icon: EngagedUsers,
    title: 'Engaged Users',
    description:
      'Connect with the people who have already shown an interest in your business or product.',
  },
  {
    type: SavedAudienceType.MMGenerated,
    icon: CustomerList,
    title: 'Marketing Milk Generated',
    description:
      "Create an audience from customer data you've previously uploaded to Marketing Milk.",
  },
  {
    type: SavedAudienceType.CustomerFile,
    icon: CustomerList,
    title: 'Customer File',
    description: 'Create an audience from customer data in CSV format.',
  },
  {
    type: SavedAudienceType.LeadCard,
    icon: LeadCards,
    title: 'Lead Cards',
    description:
      'Get more leads by creating an audience based off users who have interacted with your lead cards.',
  },
]
