import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'

export interface TrackingParamProps {
  id: string
  value: string
  editable: boolean
  onDelete: (id: string) => void
  onSaveParam: (oldID: string, id: string, value: string) => void
}

export function TrackingParam({ id, value, onDelete, onSaveParam, editable }: TrackingParamProps) {
  const [paramName, setParamName] = useState(id ?? undefined)
  const [paramValue, setParamValue] = useState(value ?? undefined)
  const changesMade = id !== paramName || value !== paramValue

  return (
    <Paper className="d-flex p-3 my-3" data-testid="tracking-param-card">
      <TextField
        id="parameter-name"
        label="Parameter Name"
        fullWidth
        placeholder="e.g. Language"
        value={paramName}
        onChange={e => setParamName(e.target.value)}
        disabled={!editable}
        InputLabelProps={{ shrink: true }}
        inputProps={{ 'data-testid': 'tracking-param-name-input' }}
      />
      <TextField
        id="parameter-value"
        label="Parameter Value"
        fullWidth
        className="mx-3"
        placeholder="e.g. English"
        value={paramValue}
        onChange={e => setParamValue(e.target.value)}
        disabled={!editable}
        InputLabelProps={{ shrink: true }}
        inputProps={{ 'data-testid': 'tracking-param-value-input' }}
      />
      {editable && changesMade && (
        <IconButton
          disabled={!paramName || !paramValue}
          className="mr-2"
          onClick={() => onSaveParam(id, paramName, paramValue)}
          aria-label="save"
          data-testid="save-param-button"
        >
          <SaveIcon />
        </IconButton>
      )}
      {editable && (
        <IconButton
          onClick={() => onDelete(paramName)}
          aria-label="delete"
          data-testid="delete-param-button"
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Paper>
  )
}
