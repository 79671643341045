import React from 'react'
import { StepProps } from '../../Step'
import { Button } from '@mui/material'
import { navigateToStepIndex } from 'components/Stepper/helpers/stepper.helpers'
import { exists, onlyPopulatedValues } from 'app/util'

type Props = {
  stepProps: StepProps[]
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  currentStepIndex: number
  submitButtonText?: string
  hideSubmit?: boolean
}
export const StepperFooter = ({ stepProps, ...props }: Props) => {
  const onLastStep = props.currentStepIndex === stepProps.length - 1
  const onFirstStep = props.currentStepIndex === 0
  const selectedStep = stepProps[props.currentStepIndex]

  return (
    <footer className="relative flex-grow-0 transition-all flex-none flex justify-between items-center w-full px-4 py-2 bg-white border-t">
      {onFirstStep ? (
        <div />
      ) : (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            props.setCurrentStepIndex(
              navigateToStepIndex(props.currentStepIndex - 1, props.currentStepIndex, stepProps)
            )
          }}
        >
          Previous
        </Button>
      )}

      {exists(onlyPopulatedValues(selectedStep?.errors || [])) && (
        <p className="text-red-400 font-semibold max-w-5xl pt-2">
          [ERROR]: {selectedStep?.errors?.join(', ')}
        </p>
      )}

      {/* Render next/submit button if not intentionally hidden */}
      {!(props.hideSubmit && onLastStep) && (
        <Button
          color="primary"
          variant="contained"
          disabled={selectedStep?.disabled}
          onClick={() => {
            // If the 'onNext' prop was given, run that function
            // NOTE:  This is how the 'complete' is meant to be used.
            selectedStep?.onNext && selectedStep.onNext()

            // If On the Last Step, Don't do anything
            if (onLastStep) return

            if (selectedStep)
              props.setCurrentStepIndex(
                navigateToStepIndex(props.currentStepIndex + 1, props.currentStepIndex, stepProps)
              )
          }}
        >
          {onLastStep ? props.submitButtonText || 'Publish' : 'Next'}
        </Button>
      )}
    </footer>
  )
}
