import { EnvRenderer, useGetAllBusinesses, useGetBusinessID } from '@marketing-milk/frontend'
import { AdCreative, Environment } from '@marketing-milk/interfaces'
import { Button, Drawer } from '@mui/material'
import { addOrRemove } from 'app/util'
import { Step } from 'components/Stepper'
import { Stepper } from 'components/Stepper/Stepper'
import { BulkCreativeSelect } from 'pages/business/creatives/components/bulk-operations/BulkSelect'
import { CreativeCard } from 'pages/business/creatives/CreativeCard/CreativeCard'
import { useCreatives } from 'pages/business/creatives/useCreatives'
import { useState } from 'react'
import ReactSelect from 'react-select'

export const BulkShareCreatives = () => {
  const [selectedCreatives, setSelectedCreatives] = useState<AdCreative[]>([])
  const [selectedBusinessIds, setSelectedBusinessIds] = useState<number[]>([])
  const businessId = useGetBusinessID()
  const { shareManyCreatives } = useCreatives(businessId)
  const businesses = useGetAllBusinesses()
  const [open, setOpen] = useState(false)

  const onSelectCreative = (creative: AdCreative) =>
    setSelectedCreatives(prev => addOrRemove(creative, prev))

  const onComplete = () => {
    shareManyCreatives(selectedBusinessIds, selectedCreatives)
    setOpen(false)
  }

  return (
    <div>
      <EnvRenderer
        renderEnvironment={[
          Environment.Beta,
          Environment.Development,
          Environment.CI,
          Environment.Test,
          Environment.Staging,
        ]}
      >
        <Button variant="contained" onClick={() => setOpen(true)}>
          Bulk Share Creatives (Prod Hidden)
        </Button>
      </EnvRenderer>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <div style={{ width: '80vw' }}>
          <Stepper>
            <Step title="Import Creatives">
              <div className="w-full flex flex-col max-w-7xl mx-auto p-4 space-y-4">
                <h1 className="py-2 font-lg font-bold mx-auto">Select Creatives To Share</h1>
              </div>
              <div>
                <BulkCreativeSelect
                  onSelectCreative={onSelectCreative}
                  selectedCreatives={selectedCreatives}
                />
              </div>
            </Step>
            <Step title="Select Businesses">
              <div className="w-full flex flex-col max-w-7xl mx-auto p-4 space-y-4">
                <h1 className="py-2 font-lg font-bold mx-auto">
                  Select The Businesses To Share With
                </h1>
                <ReactSelect
                  isMulti
                  isClearable
                  options={businesses.map(business => ({
                    label: business.businessName,
                    value: business.id,
                  }))}
                  onChange={selections =>
                    setSelectedBusinessIds(selections.map(({ value }) => value))
                  }
                />
              </div>
            </Step>
            <Step title="Confirm" onNext={onComplete}>
              <div className="w-full flex flex-col max-w-7xl mx-auto p-4 space-y-4">
                <h1 className="py-2 font-lg font-bold mx-auto">
                  These Creatives Will Be Duplicated To Each Business Selected
                </h1>
              </div>
              <div className="grid justify-items-center w-full sm:grid-cols-1 md:grid-cols-3 3xl:grid-cols-5 4xl:grid-cols-5 sm:gap-5">
                {selectedCreatives.map(creative => (
                  <CreativeCard tools={false} creative={creative} onSelect={() => {}} />
                ))}
              </div>
            </Step>
          </Stepper>
        </div>
      </Drawer>
    </div>
  )
}
