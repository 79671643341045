import { NestAPI, useGetBusiness } from '@marketing-milk/frontend'
import { AdCreative, Campaign, DraftCampaign, SprintDTO } from '@marketing-milk/interfaces'
import { useNest } from 'hooks/api/useNest'
import { prop } from 'lodash/fp'
import { useCreatives } from 'pages/business/creatives/useCreatives'
import { MultipleChoiceQuestion } from 'pages/business/lead-cards/wizard/sections/form-questions/MultipleChoiceQuestion'
import { useQuery } from 'react-query'

export const useSprint = (id?: number) => {
  const business = useGetBusiness()
  const endpoint = `businesses/${business.id}/sprints`

  const { duplicateManyCreatives } = useCreatives(`${business.id}`)
  const { update, deleteItem, getSingle, list: sprints } = useNest('sprints')

  const { data: sprint, isLoading: sprintLoading } = useQuery(
    ['sprints', business.id, id],
    async () => await getSingle(id)
  )

  const {
    data: publishedCampaigns,
    isLoading: publishedCampaignsLoading,
    refetch: refetchPublishedCampaigns,
  } = useQuery<Campaign[]>(
    ['sprints', 'published-campaigns', id, business.id],
    async () => await NestAPI.get(`${endpoint}/${id}/published-campaigns`).then(prop('data'))
  )

  const {
    data: draftCampaigns,
    isLoading: draftCampaignsLoading,
    refetch: refetchDraftCampagins,
  } = useQuery<DraftCampaign[]>(
    ['sprints', 'draft-campaigns', id, business.id],
    async () => await NestAPI.get(`${endpoint}/${id}/draft-campaigns`).then(prop('data'))
  )

  const {
    data: sprintCreatives,
    isLoading: sprintCreativesLoading,
    refetch: refetchCreatives,
  } = useQuery<AdCreative[]>(
    ['sprints', 'sprint-creatives', id, business.id],
    async () => await NestAPI.get(`${endpoint}/${id}/creatives`).then(prop('data'))
  )

  const updateSprint = (dto: SprintDTO) => (id ? update(id, dto) : undefined)

  const deleteSprint = () => id && deleteItem(id)

  const importCreativesToSprint = async (creatives: AdCreative[]) => {
    await duplicateManyCreatives(creatives, {
      name: `${sprint?.name} | Campaign ? - Ad ?`,
      sprintId: sprint?.id,
    })
    refetchCreatives()
  }

  return {
    sprint,
    sprintCreatives,
    sprintCreativesLoading,
    publishedCampaigns,
    publishedCampaignsLoading,
    draftCampaigns,
    draftCampaignsLoading,
    updateSprint,
    deleteSprint,
    sprintLoading,
    refetchCreatives,
    refetchDraftCampagins,
    refetchPublishedCampaigns,
    sprints,
    importCreativesToSprint,
  }
}
