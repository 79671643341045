import { CampaignSchedule, PublishCampaignDTO } from '@marketing-milk/interfaces'
import { getCostPerText } from '../../../../helpers/campaignForm.helpers'
import { set } from 'lodash/fp'
import { Input } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { animated, useSpring, useTransition } from 'react-spring'

interface Props {
  onClose: () => void
  adPairLength: number
  campaignInterval: Interval
  dto: PublishCampaignDTO
  setCampaignDto: SetState<PublishCampaignDTO | undefined>
  isVisible: boolean
  schedule?: CampaignSchedule
}

export const CascadingCampaignInfo = (props: Props) => {
  const containerAnimation = useSpring({
    height: props.isVisible ? 255 : 0,
    opacity: props.isVisible ? 1 : 0,
  })

  const intervalAnimation = useTransition(props.schedule?.interval ?? '-', {
    from: { opacity: 0, transform: 'scale(3, 3)', color: 'red' },
    enter: { opacity: 1, transform: 'scale(1, 1)', color: 'black' },
  })

  return (
    <div className="relative">
      <div className="relative bg-transparent max-w-3xl mx-auto z-10">
        <button
          onClick={props.onClose}
          data-testid="cc-info-toggle"
          className={
            'absolute m-2 right-2 center mx-auto max-w-3xl border rounded-full bg-white w-10 h-10 flex font-bold items-center justify-center'
          }
        >
          <span className="sr-only">{props.isVisible ? 'minimize' : 'expand'}</span>
          {props.isVisible ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </button>
      </div>
      <animated.div
        data-testId="cc-info-container"
        style={containerAnimation}
        className={`relative mx-auto max-w-3xl rounded-xl border-2 bg-white w-full flex flex-col ${
          props.isVisible ? '' : 'overflow-hidden'
        }`}
      >
        {/* Title / Warning */}
        <div className="p-4 flex justify-center text-yellow-600 items-center space-x-4">
          <WarningAmberIcon fontSize="large" />
          <p className="font-lg font-semibold" data-testid="cc-info-title">
            This is now a Cascading Campaign
          </p>
        </div>

        {/* Description */}
        <div className="flex justify-center">
          <p className="sm:max-w-sm text-center">
            A Cascading Campaign automatically switches to a new Ad Pair if the current ads are not
            performing well.
          </p>
        </div>

        <ul className="flex grow-1 flex-1 flex-grow items-center justify-center divide-x bg-gray-100 p-2">
          {/* Interval Info  */}
          <li className="w-full flex flex-col">
            <div className="flex-grow-1 grow">
              <p className="text-center text-lg">Performance Checked Every</p>
              <aside className="text-center text-sm text-gray-500">
                ({props.schedule?.intervalTotal ?? '-'} {props.schedule?.intervalType ?? 'week'}(s)
                running ÷ {props.adPairLength} variations)
              </aside>
            </div>
            {intervalAnimation((style, intervalNum) => (
              <animated.p
                style={style}
                className="w-full text-2xl mt-2 flex-none font-semibold text-center"
              >
                {intervalNum} {props.schedule?.intervalType ?? 'week'}(s)
              </animated.p>
            ))}
          </li>

          {/* Cost Per Input */}
          <li className="w-full flex flex-col ">
            <div className="flex-1 pt-1">
              <p className="text-center text-lg">Switch To Next Ad When</p>
              <p className="text-sm text-center text-gray-500">
                {getCostPerText(props.dto.campaign.goal)}
              </p>
            </div>
            <p className="flex items-center justify-center mt-2">
              <p className="text-2xl text-black font-semibold">is over</p>
              <Input
                defaultValue={props.dto.campaign.maxCostPer ?? 10}
                type="number"
                startAdornment={'$'}
                onChange={({ target }) =>
                  props.setCampaignDto(set(['campaign', 'maxCostPer'], Number(target.value)))
                }
                className="ml-2 w-16"
              />
            </p>
          </li>
        </ul>
      </animated.div>
    </div>
  )
}
