import { useState } from 'react'
import { useBaseAudience } from './use-base-audience'
import { usePixelData } from '@marketing-milk/frontend'
import { FacebookPixel, InclusionExclusion, SavedAudience } from '@marketing-milk/interfaces'

export interface WebsiteAudienceData {
  inclusion: InclusionExclusion
  exclusion?: InclusionExclusion
}

export function useWebsiteAudience(businessID: string, audience?: SavedAudience) {
  const baseAudience = useBaseAudience(businessID, audience)
  const pixelData: FacebookPixel | undefined = usePixelData()
  const [addExclusions, setAddExclusions] = useState<boolean>(false)
  const [audienceData, setAudienceData] = useState<WebsiteAudienceData>({
    inclusion: {
      menuSelection: 'ALL_WEBSITE_VISITORS',
      pixelRetention: 145,
    },
    exclusion: {
      menuSelection: 'ALL_WEBSITE_VISITORS',
      pixelRetention: 145,
    },
  })

  function onUpdateInclusion(changes: Partial<InclusionExclusion>) {
    setAudienceData({
      ...audienceData,
      inclusion: {
        ...audienceData.inclusion,
        ...changes,
      },
    })
  }

  function onUpdateExclusion(changes: Partial<InclusionExclusion>) {
    audienceData.exclusion &&
      setAudienceData({
        ...audienceData,
        exclusion: {
          ...audienceData.exclusion,
          ...changes,
        },
      })
  }

  return {
    ...baseAudience,
    pixelData,
    audienceData,
    addExclusions,
    setAddExclusions,
    setAudienceData,
    onUpdateExclusion,
    onUpdateInclusion,
  }
}
