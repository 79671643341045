import { sumBy } from 'lodash'
import React, { useState } from 'react'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@material-ui/core/Typography'
import { FormatService } from '@marketing-milk/frontend'
import FormControlLabel from '@mui/material/FormControlLabel'
import { PublishCampaignDTO } from '@marketing-milk/interfaces'

export interface OrderSummaryProps {
  campaignDTOs: PublishCampaignDTO[]
  onPublish: () => void
}

export function OrderSummary({ campaignDTOs, onPublish }: OrderSummaryProps) {
  const [checked, setChecked] = useState(false)
  const total = sumBy(
    campaignDTOs.map(dto => dto.campaign),
    'budget'
  )
  const buttonText = campaignDTOs.length > 1 ? 'Publish Campaigns' : 'Publish Campaign'

  const purchaseItem = (title: string, price: number, subtitle?: string) => (
    <div className="d-flex py-3">
      <div style={{ alignSelf: 'flex-start', flex: 1 }}>
        <Typography variant="h6">{title}</Typography>
        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
      </div>
      <div className="text-right" style={{ alignSelf: 'flex-end' }}>
        <Typography data-testid="purchase-item" variant="h6">
          {FormatService.getValue(price, { type: 'currency' })}
        </Typography>
      </div>
    </div>
  )

  return (
    <div data-testid="order-summary" className="grid grid-cols-1">
      <div>
        {campaignDTOs.map(dto =>
          purchaseItem(dto.campaign.name, dto.campaign.budget, 'Facebook Campaign')
        )}
        <hr style={{ border: '1px solid #BDC2C7' }} className="my-4" />
        <div className="d-flex mb-2">
          <Typography
            variant="h5"
            className="text-bold"
            style={{ alignSelf: 'flex-start', flex: 1 }}
          >
            Total
          </Typography>
          <Typography
            data-testid="purchase-total"
            variant="h5"
            className="text-bold text-right"
            style={{ alignSelf: 'flex-end' }}
          >
            {FormatService.getValue(total, { type: 'currency' })}
          </Typography>
        </div>
        <div className="py-3">
          <FormControlLabel
            control={
              <Checkbox
                className="p-3"
                checked={checked}
                data-testid="privacy-check"
                onChange={e => setChecked(e.target.checked)}
                inputProps={{ 'aria-label': 'publish-campaign' }}
              />
            }
            label={
              <Typography variant="body1" className="MuiTypography-colorTextSecondary">
                I have read and agree to the{' '}
                <Link
                  href="https://www.marketingmilk.com/privacy-policy/"
                  target="_blank"
                  underline="always"
                >
                  Marketing Milk Privacy Policy
                </Link>
                .
              </Typography>
            }
            labelPlacement="end"
          />
        </div>
        <Button
          fullWidth
          disabled={!checked}
          color="primary"
          onClick={onPublish}
          variant="contained"
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
