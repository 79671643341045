import { useState } from 'react'
import { useBaseAudience } from './use-base-audience'
import {
  FBPageName,
  LeadCard,
  LeadFormAudienceMenuOption,
  SavedAudience,
} from '@marketing-milk/interfaces'
import { usePageData } from '@marketing-milk/frontend'
import { useGetLeadCardsForBusiness } from '../../../../../hooks'

export interface LeadCardAudienceData {
  formRetention: number
  selectedCategory: LeadFormAudienceMenuOption
  selectedLeadForm: string
}

export function useLeadCardAudience(businessID: string, audience?: SavedAudience) {
  const baseAudience = useBaseAudience(businessID, audience)
  const leadCards: LeadCard[] | undefined = useGetLeadCardsForBusiness(Number(businessID))
  const facebookPageData: FBPageName | undefined = usePageData()
  const [audienceData, setAudienceData] = useState<LeadCardAudienceData>({
    formRetention: 90,
    selectedCategory: 'lead_generation_opened',
    selectedLeadForm: '',
  })

  const selectedForm = leadCards?.find(x => x.fbID === audienceData.selectedLeadForm)

  function onRetentionUpdate(retention: number) {
    setAudienceData({
      ...audienceData,
      formRetention: retention,
    })
  }

  function onSelectCategory(category: LeadFormAudienceMenuOption) {
    setAudienceData({
      ...audienceData,
      selectedCategory: category,
    })
  }

  function onSelectForm(form: string) {
    setAudienceData({
      ...audienceData,
      selectedLeadForm: form,
    })
  }

  return {
    ...baseAudience,
    audienceData,
    facebookPageData,
    selectedForm,
    leadCards,
    setAudienceData,
    onRetentionUpdate,
    onSelectCategory,
    onSelectForm,
  }
}
