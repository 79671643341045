import { useState } from 'react'
import { Switch } from '@material-ui/core'
import { CircularProgress } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ shadows, palette }) => ({
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: palette.background.default,
    boxShadow: shadows[1],
  },
  active: {
    backgroundColor: palette.secondary.main,
  },
}))

type Props = {
  checkedDefault: boolean
  disabled: boolean
  onPauseOrResume: (checked: boolean) => Promise<boolean>
}

export const PauseAdObject = ({ checkedDefault, disabled, onPauseOrResume }: Props) => {
  const classes = useStyles()
  const [checked, setChecked] = useState(checkedDefault)
  const [loading, setLoading] = useState(false)

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!loading) {
      const { checked } = event.target
      setChecked(checked)
      setLoading(true)
      const apiSuccess = await onPauseOrResume(checked)
      setLoading(false)
      if (!apiSuccess) {
        setChecked(!checked)
      }
    }
  }

  const Icon = () => (
    <div className={`${classes.circle} ${checked && !loading ? classes.active : ''}`}>
      {loading && <CircularProgress size={14} color="primary" thickness={6} />}
    </div>
  )

  return (
    <Switch
      checkedIcon={<Icon />}
      icon={<Icon />}
      checked={checked}
      disabled={disabled}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  )
}
