import React from 'react'
import { animated } from 'react-spring'
import { ChevronRight } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'

type BreadCrumb = {
  title: string
  route?: string
}

type Props = {
  breadcrumbs: BreadCrumb[]
  className?: string
  children?: React.ReactNode[]
}
export const Breadcrumbs = React.memo((props: Props) => {
  const history = useHistory()

  const baseTitleClasses = 'text-sm font-medium sm:w-full text-gray-800 p-2 text-2xl font-semibold '

  return (
    <ol className="flex items-center">
      {props.breadcrumbs.map((breadcrumb, i) => (
        <animated.li key={i}>
          <div className="flex items-center space-x-1">
            {breadcrumb.title !== props.breadcrumbs[0].title && <ChevronRight />}

            {!breadcrumb.route && (
              <div className={`${baseTitleClasses}`}>
                <h2 className="text-xl sm:text-4xl">{breadcrumb.title}</h2>
              </div>
            )}

            {breadcrumb.route && (
              <button
                onClick={() => breadcrumb.route && history.replace(breadcrumb.route)}
                className={`${baseTitleClasses} text-blue-500  hover:text-blue-700 hover:border hover:border-b`}
              >
                <h2 className="text-xl sm:text-4xl">{breadcrumb.title}</h2>
              </button>
            )}
          </div>
        </animated.li>
      ))}
    </ol>
  )
})
