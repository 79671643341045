import React from 'react'
import { Paper } from '@material-ui/core'
import Switch from '@mui/material/Switch'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import { LeadCardPrivacySettings } from '@marketing-milk/interfaces'

export interface CustomConsentBlockProps {
  index: number
  editable: boolean
  customDisclaimer: LeadCardPrivacySettings['customDisclaimer']
  onDeleteConsentBlock(index: number): void
  onChangeConsent(index: number, key: 'isRequired' | 'text', value: any): void
}

export function CustomConsentBlock({
  index,
  editable,
  customDisclaimer,
  onChangeConsent,
  onDeleteConsentBlock,
}: CustomConsentBlockProps) {
  const consentQuestion = customDisclaimer!.checkboxes[index]
  return (
    <Paper className="mb-3 p-2 border">
      <div className="d-flex w-100 my-1">
        <div className="w-100 mr-3 center-vertical">
          <TextField
            error={!consentQuestion.text}
            helperText={!consentQuestion.text && '* Field cannot be empty'}
            className="ml-2"
            fullWidth
            disabled={!editable}
            value={consentQuestion.text}
            onChange={e => onChangeConsent(index, 'text', e.target.value)}
            placeholder="I agree to the terms & conditions"
            inputProps={{
              'aria-label': `lead card custom consent${index + 1}`,
              'data-testid': `custom-consent-input-${index + 1}`,
            }}
          />
        </div>
        <div className="center-vertical">
          <div className="ml-4" style={{ minWidth: 120 }}>
            <FormControlLabel
              label="Required"
              color="primary"
              className="m-0"
              labelPlacement="start"
              control={
                <Switch
                  color="primary"
                  checked={consentQuestion.isRequired}
                  disabled={!editable}
                  onChange={(e, checked) => onChangeConsent(index, 'isRequired', checked)}
                />
              }
            />
          </div>
        </div>
        <div className="center-vertical">
          <IconButton
            data-testid="delete-icon"
            disabled={!editable}
            onClick={() => onDeleteConsentBlock(index)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </Paper>
  )
}
