import * as Yup from 'yup'

import { AudienceSpendLimitsModalProps, SpendLimits } from './AudienceTable.types'
import { FieldArray, Form, Formik, FormikValues } from 'formik'
import { FormHelperText, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import React, { useRef } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { SavedAudience } from '@marketing-milk/interfaces'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const useModalStyles = makeStyles(theme => ({
  audienceName: {
    marginBottom: theme.spacing(2),
  },
  lifetimeMinInput: {
    width: '80%',
  },
  lifetimeMaxInput: {
    width: '80%',
  },
}))

const AudienceSpendLimitsSchema = Yup.object().shape({
  spendLimits: Yup.array().of(
    Yup.object().shape({
      lifetimeMin: Yup.number().min(1, 'Too low').max(99, 'Too high'),
      lifetimeMax: Yup.number().min(2, 'Too low').max(100, 'Too high'),
    })
  ),
})

const getInitialValues = (spendLimits: SpendLimits, audiences: SavedAudience[]) => {
  return {
    spendLimits: audiences.map(a => {
      return spendLimits[a.id]
        ? {
            lifetimeMin: spendLimits[a.id].lifetimeMin,
            lifetimeMax: spendLimits[a.id].lifetimeMax,
          }
        : { lifetimeMin: undefined, lifetimeMax: undefined }
    }),
  }
}

const ErrorMsg = (props: { msg: string }) => {
  return (
    <FormHelperText error aria-label="error text">
      {props.msg}
    </FormHelperText>
  )
}

export const AudienceSpendLimitsModal = ({
  isOpen,
  onClose,
  audiences,
  spendLimits,
  setSpendLimits,
}: AudienceSpendLimitsModalProps) => {
  const classes = useModalStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const formRef = useRef<FormikValues>()
  const submit = () => {
    if (formRef.current?.handleSubmit) {
      formRef.current?.handleSubmit()
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} scroll="paper" fullWidth={true} fullScreen={fullScreen}>
      <DialogTitle id="audience-spend-limits-modal-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Set Min and Max Spend Limits for your Audiences</Typography>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Formik
          initialValues={getInitialValues(spendLimits, audiences)}
          // @ts-ignore - TS is getting confused
          innerRef={formRef}
          validationSchema={AudienceSpendLimitsSchema}
          onSubmit={values => {
            const newSpendLimits = values.spendLimits.reduce((accum, current, i) => {
              accum[audiences[i]?.id] = current
              return accum
            }, {} as SpendLimits)

            setSpendLimits(newSpendLimits)
            onClose()
          }}
        >
          {({ values, errors, handleChange }) => {
            return (
              <Form id="audience-spend-limits-form">
                <FieldArray name="spendLimits">
                  {({ insert, remove, push }) => (
                    <List>
                      {values.spendLimits.map(({ lifetimeMin, lifetimeMax }, i) => (
                        <ListItem key={i} aria-label={`blah ${i}`}>
                          <Box display="flex" flexDirection="column">
                            <ListItemText
                              className={classes.audienceName}
                              primary={audiences[i].name}
                            />
                            <Box display="flex">
                              <div>
                                <TextField
                                  name={`spendLimits.${i}.lifetimeMin`}
                                  type="number"
                                  label="Min"
                                  inputProps={{
                                    'aria-label': `lifetime minimum - ${audiences[i].name}`,
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">%</InputAdornment>
                                    ),
                                  }}
                                  variant="outlined"
                                  className={classes.lifetimeMinInput}
                                  value={lifetimeMin ?? ''}
                                  onChange={handleChange}
                                  size="small"
                                />
                                {/* @ts-ignore */}
                                {errors.spendLimits?.[i]?.lifetimeMin && (
                                  // @ts-ignore
                                  <ErrorMsg msg={errors.spendLimits?.[i]?.lifetimeMin} />
                                )}
                              </div>
                              <div>
                                <TextField
                                  name={`spendLimits.${i}.lifetimeMax`}
                                  type="number"
                                  label="Max"
                                  inputProps={{
                                    'aria-label': `lifetime maximum - ${audiences[i].name}`,
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">%</InputAdornment>
                                    ),
                                  }}
                                  variant="outlined"
                                  className={classes.lifetimeMaxInput}
                                  value={lifetimeMax ?? ''}
                                  onChange={handleChange}
                                  size="small"
                                />
                                {/* @ts-ignore */}
                                {errors.spendLimits?.[i]?.lifetimeMax && (
                                  /* @ts-ignore */
                                  <ErrorMsg msg={errors.spendLimits?.[i]?.lifetimeMax} />
                                )}
                                {lifetimeMin && lifetimeMax && lifetimeMin >= lifetimeMax && (
                                  <ErrorMsg msg="Max must be greater than min" />
                                )}
                              </div>
                            </Box>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </FieldArray>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        {/* @ts-ignore - TS is getting confused */}
        <Button
          ref={formRef}
          type="submit"
          form="audience-spend-limits-form"
          color="primary"
          onClick={submit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
