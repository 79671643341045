import { useGetBusiness } from '@marketing-milk/frontend'
import { LocalizationProvider, DateTimePicker } from '@mui/lab'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { TextField, TextFieldProps } from '@mui/material'

type Props = {
  readyToPublish?: boolean
  duration: [Date, Date]
  setStartDate: (startDate: Date | null) => void
  setEndDate: (endDate: Date | null) => void
}

export const CondensedCampaignDurationField = (props: Props) => {
  const [startDate, endDate] = props.duration
  const business = useGetBusiness()
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label="Start Date"
        value={startDate}
        onChange={(date: Date | null) => {
          props.setStartDate(date)
        }}
        renderInput={(params: TextFieldProps) => (
          <TextField {...params} helperText={`Timezone: ${business.timezone}`} />
        )}
      />

      <DateTimePicker
        label="End Date"
        value={endDate}
        onChange={(date: Date | null) => {
          props.setEndDate(date)
        }}
        renderInput={(params: TextFieldProps) => (
          <TextField {...params} helperText={`Timezone: ${business.timezone}`} />
        )}
      />
    </LocalizationProvider>
  )
}
