import {
  LeadCard,
  FBPageName,
  FacebookTargetingSpec,
  LeadFormAudienceMenuOption,
} from '@marketing-milk/interfaces'
import React from 'react'
import Select from 'react-select'
import { StepHeader } from '../StepHeader'
import { Slider } from '@marketing-milk/frontend'
import FacebookLeadAdLogo from 'public/img/logos/facebook-lead-ad-logo.png'

export interface LeadCardProps {
  leadCards: LeadCard[] | undefined
  facebookPageData: FBPageName | undefined
  formRetention: number
  onRetentionUpdate: (retention: number) => void
  selectedCategory: LeadFormAudienceMenuOption
  onSelectCategory: (category: LeadFormAudienceMenuOption) => void
  selectedLeadForm: string
  onSelectForm: (form: string) => void
  targetSpec: FacebookTargetingSpec
}

export const leadMenuOptions: Record<LeadFormAudienceMenuOption, string> = {
  lead_generation_submitted: 'People who opened and submitted this form',
  lead_generation_dropoff: "People who opened but didn't submit this form",
  lead_generation_opened: 'Anyone who opened this form',
}

export function LeadCardStep(props: LeadCardProps) {
  const {
    leadCards,
    facebookPageData,
    formRetention,
    selectedCategory,
    onSelectCategory,
    onRetentionUpdate,
    selectedLeadForm,
    onSelectForm,
    targetSpec,
  } = props

  const leadCardOptions = leadCards?.map(l => ({
    label: l.formName,
    value: l.fbID,
  }))

  const leadValue = selectedLeadForm
    ? leadCardOptions?.filter((x: any) => x.value === selectedLeadForm)
    : null

  function selectForm(option: { label: string; value: string }) {
    onSelectForm(option.value)
  }

  const categoryOptions = Object.keys(leadMenuOptions).map(x => ({
    //@ts-ignore
    label: leadMenuOptions[x],
    value: x,
  }))

  const value = selectedCategory
    ? categoryOptions.filter((x: any) => x.value === selectedCategory)
    : null

  function selectCategory(option: { label: string; value: LeadFormAudienceMenuOption }) {
    onSelectCategory(option.value)
  }

  function generatePageName() {
    return (
      <div
        className={`${
          facebookPageData ? 'bg-drive text-drive' : 'bg-danger text-danger'
        } text-center faded fit-content p-3 border-radius mx-auto`}
      >
        {facebookPageData
          ? `${facebookPageData.name} - ${facebookPageData.id}`
          : "We couldn't find a page for your business"}
      </div>
    )
  }

  return (
    <>
      <div className="container">
        <StepHeader
          title="Choose a Facebook Page Lead Card"
          description="Choose the Facebook Lead Card you would like to use for this audience."
          imgSrc={FacebookLeadAdLogo}
          targetingSpec={targetSpec}
        />
        <div className="row mt-5">
          <div className="col-12">{generatePageName()}</div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div
              className="card box-shadow border border-radius p-4 d-block mx-auto"
              style={{ maxWidth: '600px' }}
            >
              <p className="text-bold text-center">Using this form...</p>
              <Select
                placeholder="Choose a lead card..."
                options={leadCardOptions}
                //@ts-ignore
                value={leadValue}
                onChange={selectForm as any}
              />
              <br />
              <p className="text-bold text-center">Target the following...</p>
              <Select
                placeholder="Choose a group of people"
                options={categoryOptions}
                //@ts-ignore
                value={value}
                onChange={selectCategory as any}
              />
              <p className="text-bold text-center mt-4">In the past...</p>
              <Slider
                defaultValue={formRetention}
                min={1}
                max={90}
                onChangeValue={onRetentionUpdate}
                minLabel="Day"
                maxLabel="Days"
              />
              <h4 className="text-center">{formRetention} Days</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
