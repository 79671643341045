import { LeadCard } from '@marketing-milk/interfaces'
import { useCreateFetchHook, leadCardService } from '@marketing-milk/frontend'

export function useGetLeadCardsForBusiness(
  businessID: number,
  refresh = 0
): LeadCard[] | undefined {
  return useCreateFetchHook<LeadCard[]>(
    () => leadCardService.getAll(businessID),
    businessID + refresh
  )
}
