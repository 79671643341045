import { Info } from '@mui/icons-material'
import { Alert as MuiAlert, Tooltip } from '@mui/material'
import React from 'react'

interface AlertProps {
  type: 'info' | 'error' | 'success' | 'warning'
  children: React.ReactNode
  tooltipText?: string
}
export const MMAlert = (props: AlertProps) => (
  <div className="w-full mx-auto">
    <MuiAlert severity={props.type} variant="standard" className="flex items-center">
      <p className="w-full">
        {props.children}
        {props.tooltipText && (
          <Tooltip title={props.tooltipText}>
            <Info />
          </Tooltip>
        )}
      </p>
    </MuiAlert>
  </div>
)
