import { useGetBusinessID } from '@marketing-milk/frontend'
import {
  AdCreative,
  CampaignGoalType,
  DraftCampaign,
  SavedAudience,
} from '@marketing-milk/interfaces'
import { Check } from '@mui/icons-material'
import { Button } from '@mui/material'
import { CampaignDetails } from 'components'
import { DataTable } from 'components/DataTable'
import { flattenDeep, sortBy } from 'lodash/fp'
import {
  ErrorItem,
  ErrorsIndicator,
} from 'pages/business/campaigns/components/status-indicator/ErrorsIndicator'
import {
  getAdPairs,
  getAudiencesById,
  getValidCreatives,
} from 'pages/business/campaigns/helpers/campaignForm.helpers'
import { useHistory } from 'react-router-dom'
import { rejectNull, rejectUndefined } from '../../../../../app/util'
import { hasNoCreatives, isMissingAudiences } from '../../sprint.helpers'

interface Props {
  draftCampaigns?: DraftCampaign[]
  audiences?: SavedAudience[]
  sprintCreatives?: AdCreative[]
  openModal: (type: 'creatives' | 'audiences' | 'settings', draft: DraftCampaign) => void
}
export const DraftCampaignsTable = ({
  draftCampaigns,
  audiences,
  sprintCreatives,
  ...props
}: Props) => {
  const businessId = useGetBusinessID()
  const history = useHistory()

  return (
    <DataTable<DraftCampaign>
      hideColumnFilter
      hideSearch
      hidePagination
      recordsList={sortBy('id', draftCampaigns)}
      expansionPanel={{
        shouldExpand: () => true,
        render: draft => (
          <div className="flex items-center justify-center p-4 w-full">
            <CampaignDetails
              campaignDto={draft.config}
              selectedAudiences={getAudiencesById(draft.config.audienceIDs, audiences)}
              selectedCreatives={getAdPairs(draft.config.creativeGroups, sprintCreatives || [])}
            />
          </div>
        ),
      }}
      tableSchema={{
        Name: {
          render: draft => draft.config.campaign.name,
        },
        'Edit Settings': {
          render: draft => (
            <Button onClick={() => props.openModal('settings', draft)} variant="outlined">
              Settings
            </Button>
          ),
        },
        'Edit Adsets': {
          render: draft => (
            <Button onClick={() => props.openModal('audiences', draft)} variant="outlined">
              Edit Adsets
            </Button>
          ),
        },
        'Edit Ads': {
          render: draft => (
            <Button onClick={() => props.openModal('creatives', draft)} variant="outlined">
              Edit Ads
            </Button>
          ),
        },
        Status: {
          render: draft => {
            const audienceErrors: ErrorItem[] = isMissingAudiences(draft.config)
              ? [
                  {
                    title: 'Missing Audiences',
                    message: 'You must attach audiences to this campaign',
                    details:
                      'If you have default campaigns configured, they will be automatically assigned to the campaigns you create in a sprint',
                    actionButton: (
                      <Button
                        variant={'contained'}
                        onClick={() => props.openModal('audiences', draft)}
                      >
                        Attach Audiences
                      </Button>
                    ),
                  },
                ]
              : []

            const draftCreatives = rejectNull(
              rejectUndefined(
                flattenDeep(getAdPairs(draft.config.creativeGroups, sprintCreatives ?? []))
              )
            )
            const validCreatives = getValidCreatives(
              draft.config.creativeGroups,
              sprintCreatives ?? []
            )
            const invalidCreatives = draftCreatives?.filter(
              dc => !validCreatives.some(vc => vc.id === dc.id)
            )

            const invalidCreativeErrors = invalidCreatives.map(creative => ({
              title: `${creative?.name}`,
              message: 'Missing required properties',
              details:
                'Every creative must have media, name, website url, display url, description, and primary text.',
              actionButton: (
                <Button
                  variant={'contained'}
                  onClick={() => history.push(`/${businessId}/sprints/${creative?.id || ''}`)}
                >
                  Go to Creative
                </Button>
              ),
            }))

            const noCreativeError = {
              title: 'Missing Creatives',
              message: 'You must have creatives attached to your campaign',
              details:
                'Each campaign must have either an A and a B creative or a single dynamic creative.',
              actionButton: (
                <Button variant={'contained'} onClick={() => props.openModal('creatives', draft)}>
                  Add Creatives
                </Button>
              ),
            }

            const creativeErrors: ErrorItem[] = [
              ...invalidCreativeErrors,
              ...(hasNoCreatives(draft.config) ? [noCreativeError] : []),
            ]

            const goalError = !Object.values(CampaignGoalType).includes(draft.config.campaign.goal)

            const campaignErrors: ErrorItem[] = [
              ...(goalError
                ? [
                    {
                      title: 'Misconfigured Goal',
                      message:
                        'This campaign has a misconfigured Goal. You must configure this business goal inside of the admin panel.',
                      actionButton: (
                        <Button
                          variant={'contained'}
                          onClick={() => props.openModal('settings', draft)}
                        >
                          Edit Campaign Settings
                        </Button>
                      ),
                    },
                  ]
                : []),
            ]

            const errors = [...campaignErrors, ...audienceErrors, ...creativeErrors]
            if (errors.length > 0)
              return (
                <ErrorsIndicator
                  errors={errors}
                  drawerTitle={`${draft.config.campaign.name} has errors`}
                  drawerDescription="If you attempted to upload this to facebook, it would not work correctly."
                />
              )

            return (
              <p className="bg-green-200 flex items-center justify-center text-green-900 px-4 py-2 rounded-full uppercase max-w-max font-medium">
                <Check className="mr-2" />
                Ready
              </p>
            )
          },
        },
      }}
    />
  )
}
