import { LeadCard, LeadCardCreateDTO } from '@marketing-milk/interfaces'

export function leadCardDTOWire(leadCard: LeadCard): LeadCardCreateDTO {
  return {
    formName: leadCard.formName,
    formType: leadCard.formType,
    intro: {
      imageID: leadCard.imageUsed?.id,
      imageURL: leadCard.imageURL,
      greeting: {
        headline: leadCard.headline,
        bodyStyle: leadCard.bodyStyle,
        bodyItems: leadCard.bodyItems,
      },
    },
    questionsDesc: leadCard.questionsDesc,
    questions: leadCard.questions,
    privacy: leadCard.privacy,
    thankYouPage: leadCard.thankYouPage,
    settings: leadCard.settings,
    // @ts-ignore - john
    pagePhoto: leadCard.pagePhoto,
  }
}
