import { Loading, NestAPI, useGetBusinessID } from '@marketing-milk/frontend'
import { AdCreative, PublishCampaignDTO } from '@marketing-milk/interfaces'
import { Button, Drawer, IconButton, Tooltip } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { withStyles } from '@material-ui/styles'
import { CircularProgress, Skeleton } from '@mui/material'
import { campaignService } from 'app/services'
import { MMAlert } from 'components/Alert'
import { FullPageContent } from 'components/layout/FullPageLayout/Content/FullPageContent'
import { FullPageHeader } from 'components/layout/FullPageLayout/Header/FullPageHeader'
import { UserLayout } from 'components/layout/UserLayout'
import { useFetchAllCampaigns } from 'hooks'
import { useFetchAllAudiences } from 'hooks/audiences/useFetchAllAudiences'
import { isEmpty, range } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { ConfirmationModal } from '../../../components/ConfirmationModal'
import { useCampaignGoals } from '../../../hooks/campaigns/use-campaign-goals-by-business'
import { CampaignsContent } from './components/campaign-page/CampaignContent'
import { CampaignSidebar } from './components/campaign-page/CampaignSidebar'
import { CampaignForm } from './forms/CampaignForm'
import { canUploadCampaigns, getCampaignErrorMessage } from './helpers/campaign.helpers'
import { isValidCampaignDto } from './helpers/campaignForm.helpers'

export const ErrorToolTip = withStyles(theme => ({
  tooltip: {
    fontSize: 14,
    background: 'darkred',
    padding: '10px',
  },
}))(Tooltip)

export const Campaigns = () => {
  const businessID = useGetBusinessID()
  const { data: campaignGoals, enabledGoals } = useCampaignGoals(Number(businessID))
  const { audiences = [] } = useFetchAllAudiences(businessID)
  const [createOpen, setCreateOpen] = useState(false)
  const [creatingCampaign, setCreatingCampaign] = useState(false)
  const [deletingCampaign, setDeletingCampaign] = useState(false)
  const [campaignIDBeingDeleted, setCampaignIDBeingDeleted] = useState<number>()
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const bodyRef = useRef<HTMLDivElement>(null)
  const {
    campaigns,
    deleteCampaign,
    refetch,
    count,
    isLoading,
    error,
    fetchNextPage,
    current,
    isFetchingNextPage,
    setSortDirection,
    setSortProp,
    sortDirection,
    setSearchTerm,
  } = useFetchAllCampaigns(Number(businessID))
  const [creatives, setCreatives] = useState<AdCreative[]>([])

  const onCreateCampaign = async (campaignDto?: PublishCampaignDTO) => {
    setCreatingCampaign(true)
    if (!campaignDto) return toast.error('No DTO found')
    if (!isValidCampaignDto(campaignDto, creatives))
      return toast.error('Campaign Settings, Audiences, or Creatives are Invalid')

    toast.success('We are submitting your campaign to facebook....')

    campaignService
      .publishCampaign(Number(businessID), campaignDto)
      .then(() => {
        refetch()
        setCreatingCampaign(false)
        toast.success('Campaign has been created')
      })
      .catch(err => {
        toast.error(`There was an error: ${err}`)
        setCreatingCampaign(false)
      })

    setCreateOpen(false)
  }

  const onRefreshCampaigns = async () => {
    setIsRefreshing(true)
    toast.info(
      'Your campaigns, adsets, and ads are being synchronized with Facebook. This may take a few minutes.'
    )
    try {
      await campaignService.refreshCampaignsAndTheirChildren(Number(businessID))
      toast.success('Your campaigns, adsets, and ads have been synchronized successfully')
      refetch()
    } catch (e) {
      toast.error('There was an error while synchronizing Campaigns')
    }
    setIsRefreshing(false)
  }

  const onDeleteCampaign = async (campaignId: number) => {
    setCampaignIDBeingDeleted(campaignId)
    setDeletingCampaign(true)
  }

  useEffect(() => {
    if (!bodyRef.current) return
  }, [])

  useEffect(() => {
    NestAPI.get(`businesses/${businessID}/ad-creatives/all`, { params: { complete: true } }).then(
      ({ data }: { data: AdCreative[] }) => {
        setCreatives(data)
      }
    )
  }, [businessID])

  const canUpload = canUploadCampaigns(audiences, campaignGoals)
  const campaignUploadErrorMessages = getCampaignErrorMessage(audiences, campaignGoals)

  return (
    <UserLayout>
      <FullPageHeader
        breadcrumbs={[{ title: 'Campaigns' }]}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      >
        <div className="flex justify-between items-center w-full">
          <ErrorToolTip title={campaignUploadErrorMessages ?? ''}>
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={!canUpload}
                onClick={() => setCreateOpen(true)}
              >
                Create A Campaign
              </Button>

              <IconButton className="mx-2" onClick={onRefreshCampaigns} disabled={isRefreshing}>
                {!isRefreshing ? <RefreshIcon /> : <CircularProgress size={20} />}
              </IconButton>
            </div>
          </ErrorToolTip>
          {campaigns && (
            <div className="p-4 flex items-center justify-center font-medium">
              {!isLoading ? `Showing ${current} of ${count} Campaigns` : <CircularProgress />}
            </div>
          )}
        </div>
      </FullPageHeader>

      <Drawer open={createOpen} onClose={() => setCreateOpen(false)}>
        {enabledGoals && (
          <CampaignForm
            creatives={creatives}
            goals={enabledGoals}
            audiences={audiences}
            onSubmit={onCreateCampaign}
          />
        )}
      </Drawer>

      <FullPageContent className="h-full w-full flex max-w-full">
        {sidebarOpen && (
          <CampaignSidebar
            setSearchTerm={setSearchTerm}
            setSortDirection={setSortDirection}
            setSortProp={setSortProp}
            sortDirection={sortDirection}
          />
        )}

        <div
          className="w-full h-full overflow-y-auto p-2"
          ref={bodyRef}
          onScroll={() => {
            if (isFetchingNextPage) return
            if (current === count) return
            if (!bodyRef.current) return
            const { scrollHeight, scrollTop, clientHeight } = bodyRef.current

            const currentPosition = scrollTop + clientHeight
            const positionToReach = scrollHeight - 100
            if (currentPosition > positionToReach) fetchNextPage()
          }}
        >
          <div className="flex-grow">
            {creatingCampaign && (
              <Loading loadingMessage="Creating New Campaign. This may take a few moments." />
            )}
            {error && (
              <div className="m-4 border rounded-md">
                <MMAlert type="error">
                  <p className="w-full">{error[0]}</p>
                </MMAlert>
              </div>
            )}
            {isLoading &&
              range(0, 20).map(i => (
                <div className="w-full flex items-center justify-center pt-0.5">
                  <Skeleton variant="rectangular" height={90} width={'100%'} />
                </div>
              ))}

            {!isLoading && (
              <CampaignsContent
                campaigns={campaigns}
                refetch={refetch}
                audiences={audiences}
                creatives={creatives}
                onDeleteCampaign={onDeleteCampaign}
              />
            )}

            <ConfirmationModal
              openState={[deletingCampaign, setDeletingCampaign]}
              onConfirm={() => {
                if (campaignIDBeingDeleted) deleteCampaign(campaignIDBeingDeleted)
              }}
              warningText="Are you sure you would like to delete this campaign? It will be removed from Facebook and all reporting data will be lost."
              confirmButtonText="Delete"
              confirmButtonType="delete"
            ></ConfirmationModal>

            <div className="w-full flex items-center justify-center pt-0.5">
              {/* We add this because on larger screens, only 8 campaigns might not fill the entire dom.  Without the dom being filled, the scroll handler will never be called.  After 15 campaigns are loaded, we know that any screen will be filled */}
              {!isEmpty(campaigns) && (campaigns || []).length < 15 && (
                <div className="p-4">
                  <Button onClick={() => fetchNextPage()} variant="contained" color="secondary">
                    Load More Campaigns
                  </Button>
                </div>
              )}
              {isFetchingNextPage && <CircularProgress size={50} />}
            </div>
          </div>
        </div>
      </FullPageContent>
    </UserLayout>
  )
}
