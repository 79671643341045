import { ReactNode } from 'react'
import { CreativeMediaType, FBCallToAction } from '@marketing-milk/interfaces'

export interface CreativeMedia {
  mediaID?: number
  mediaType?: CreativeMediaType
}

export interface DynamicContextProps {
  children: ReactNode
}

export interface DynamicContextState {
  headlines: string[] // 5 Max
  descriptions: string[] // 5 Max
  primaryTexts: string[] // 5 Max
  callToActions: FBCallToAction[] // 5 Max
  medias: CreativeMedia[] // 10 Max
  websiteURL: string
  displayURL: string
}

export const defaultDynamicContextState: DynamicContextState = {
  headlines: ['Headline 1', 'Headline 2', 'Headline 3'],
  descriptions: ['Description 1', 'Description 2', 'Description 3'],
  medias: [{ mediaID: undefined, mediaType: undefined }],
  primaryTexts: ['Primary 1', 'Primary 2', 'Primary 3'],
  callToActions: [FBCallToAction.ADD_TO_CART, FBCallToAction.SUBSCRIBE, FBCallToAction.SIGN_UP],
  websiteURL: '',
  displayURL: '',
}

export interface DynamicContextFunctionality {
  addCallToAction(): void
  setCallToAction(index: number, value: FBCallToAction): void
  delCallToAction(index: number): void
  addDescription(): void
  setDescription(index: number, value: string): void
  delDescription(index: number): void
  addHeadline(): void
  setHeadline(index: number, value: string): void
  delHeadline(index: number): void
  addMedia(): void
  setMedia(index: number, mediaID?: number, mediaType?: CreativeMediaType): void
  delMedia(index: number): void
  addPrimaryText(): void
  setPrimaryText(index: number, value: string): void
  delPrimaryText(index: number): void
  setWebsiteURL(websiteURL: string): void
  setDisplayURL(displayURL: string): void
  canPublishCreative(): boolean
}

export const defaultDynamicContextFunctionality: DynamicContextFunctionality = {
  addCallToAction() {},
  delCallToAction(index: number) {},
  setCallToAction(index: number, value: FBCallToAction) {},
  addDescription() {},
  setDescription(index: number, value: string) {},
  delDescription(index: number) {},
  addHeadline() {},
  setHeadline(index: number, value: string) {},
  delHeadline(index: number) {},
  addMedia() {},
  delMedia(index: number) {},
  setMedia(index: number, mediaID: number, mediaType: CreativeMediaType) {},
  addPrimaryText() {},
  setPrimaryText(index: number, value: string) {},
  delPrimaryText(index: number) {},
  setWebsiteURL(websiteURL: string) {},
  setDisplayURL(displayURL: string) {},
  canPublishCreative: () => false,
}

export type DynamicContext = DynamicContextState & DynamicContextFunctionality

export const defaultDynamicContext: DynamicContext = {
  ...defaultDynamicContextState,
  ...defaultDynamicContextFunctionality,
}
