import React from 'react'
import MM from 'public/img/logos/milk-gradient.png'

export function Footer() {
  const today = new Date().getFullYear()

  return (
    <div className="footer bg-white py-1 d-flex flex-lg-column" id="kt_footer">
      <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2 copyright">{today.toString()}</span>{' '}
          &copy;{' '}
          <a
            href="https://www.marketingmilk.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary"
          >
            Marketing Milk
          </a>
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <a
            href="https://dashboard.marketingmilk.com"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pr-3 pl-0"
          >
            <img src={MM} alt="marketing-milk marketing milk logo" style={{ maxHeight: '30px' }} />
          </a>
        </div>
      </div>
    </div>
  )
}
