import * as Sentry from '@sentry/browser'
import { useContext, useEffect } from 'react'
import { sessionContext } from '@marketing-milk/frontend'

export function useSetSentryScope() {
  const { user } = useContext(sessionContext)
  useEffect(() => {
    Sentry.configureScope(scope => {
      if (user) {
        scope.setUser({ id: user.id.toString(), email: user.email })
        return
      }
      scope.clear()
    })
  }, [user])
}
