import React, { useContext } from 'react'
import { sessionContext, useGetBusinessIDIfExists } from '@marketing-milk/frontend'
import { getNavLinks } from './getNavLinks'
import { NavLink } from './NavLink'
import { useTransition, animated } from 'react-spring'

interface NavProps {
  navOpen: boolean
}

export const MobileNav = (props: NavProps) => {
  const { user } = useContext(sessionContext)
  const businessID = useGetBusinessIDIfExists()
  const links = getNavLinks(businessID, user)

  const slideOver = useTransition(props.navOpen, {
    enter: { transform: 'translateX(0%)', opacity: 1 },
    from: { transform: 'translateX(-100%)', opacity: 0 },
    leave: { transform: 'translateX(-100%)', opacity: 1 },
  })

  return slideOver(
    (style, open) =>
      open && (
        <animated.div style={style}>
          <div
            className="pt-20 h-full w-screen"
            style={{
              background: '#1e1e2d',
            }}
          >
            <div className="grid grid-cols-3">
              <NavLink
                route={{
                  label: 'Businesses',
                  icon: 'briefcase',
                  url: 'dashboard',
                }}
              />
              {businessID && links.map((route, i) => <NavLink route={route} index={i} />)}
            </div>
          </div>
        </animated.div>
      )
  )
}
