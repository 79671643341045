import { capitalCase, sentenceCase } from 'change-case'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import {
  AdCreativeType,
  CampaignConfig,
  PublishCampaignDTO,
  SprintDTO,
} from '@marketing-milk/interfaces'
import ReactSelect from 'react-select'
import { Button, TextField } from '@material-ui/core'
import { Add, Close } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { rejectUndefined, removeAtIndex } from 'app/util'
import { SelectCreativeType } from 'components/FormFields/Select/SelectCreativeType'
import { update, set, prop, uniq, unset, flatten, flow } from 'lodash/fp'
import {
  creativeNameErrorMessage,
  creativeNameValidator,
  creativeScaffoldErrorMessage,
  creativeScaffoldValidator,
  getUniqueIndex,
} from 'pages/business/sprints/sprint.helpers'
import { useState } from 'react'
import { CreativeScaffold, SprintFormData } from '../CreateSprintForm'
import { CreativeTypeField } from '../Fields/CreativeTypeField'
import { getGoalColorClasses } from 'pages/business/campaigns/helpers/campaignForm.helpers'
import { ObjectSchema } from 'yup'

interface Props {
  campaignConfigs: CampaignConfig[]
  sprintDto: SprintDTO
  sprintCreatives: { [campaignIndex: number]: CreativeScaffold[][] }
  setSprintCreatives: SetState<{ [campaignIndex: number]: CreativeScaffold[][] }>
}
export const SprintScaffoldCreativesStep = ({
  sprintDto,
  campaignConfigs,
  sprintCreatives,
  setSprintCreatives,
}: Props) => {
  const [allCreativesSingle, setAllCreativesSingle] = useState(false)

  const removeCreative = (campaignIndex: number, pairIndex: number, creativeIndex: number) => {
    setSprintCreatives(update([campaignIndex, pairIndex], removeAtIndex(creativeIndex)))
  }

  // TODO: Support Cascading
  const addCreative = (campaignIndex: number) => {
    setSprintCreatives(
      update([campaignIndex, 0], creatives => [
        ...creatives,
        {
          name: `${campaignConfigs[campaignIndex].name} - Ad 1${
            creatives.length === 0 ? 'A' : 'B'
          }`,
          type: 'single_image',
          campaignId: campaignIndex,
          pairIndex: 0,
        },
      ])
    )
  }

  return (
    <div className="w-full flex flex-col mx-auto p-4 space-y-4">
      <h1 className="py-2 font-lg font-bold mx-auto">Scaffold Creatives</h1>
      <p className="font-medium mx-auto max-w-2xl font-md text-gray-600">
        Scaffold out the new creatives you will use in this sprint
      </p>

      <div className="max-w-fit mx-auto mb-8">
        <Alert severity="info" variant="standard" className="flex items-center">
          After publishing, you will be able to add images and copy to each of these creatives
        </Alert>
      </div>

      <div className="grid grid-cols-2 gap-4 w-full">
        {campaignConfigs.map((campaign, campaignIndex) => (
          <div className="w-full h-full border rounded-md shadow-xl">
            <div className="py-4 space-x-4 bg-gray-200 flex justify-center items-center">
              <p className="font-lg font-semibold text-gray-800">{campaign.name}</p>
              <p className={`${getGoalColorClasses(campaign.goal)} py-2 px-4 rounded-full text-sm`}>
                {capitalCase(campaign?.goal)}
              </p>
            </div>

            <div className=" space-y-4 px-4 w-full h-full py-2">
              {sprintCreatives[campaignIndex]?.map((creativeGroup, pairIndex) => (
                <>
                  <div className=" w-full h-full">
                    {creativeGroup.length > 2 && (
                      <p className="bg-white max-w-max">Pair {pairIndex + 1}</p>
                    )}
                    <div className={`${creativeGroup.length > 2 ? 'border' : ''} pl-2 h-full py-1`}>
                      {creativeScaffoldValidator(creativeGroup) && (
                        <div className="w-full px-4 mx-auto">
                          <Alert severity="error" variant="standard" className="flex items-center">
                            {creativeScaffoldErrorMessage(creativeGroup)}
                          </Alert>
                        </div>
                      )}
                      {creativeGroup.map((creative, creativeIndex) => (
                        <>
                          <ScaffoldCreativeForm
                            creative={creative}
                            campaignIndex={campaignIndex}
                            pairIndex={pairIndex}
                            creativeIndex={creativeIndex}
                            setSprintCreatives={setSprintCreatives}
                          />
                        </>
                      ))}
                      {creativeGroup.length !== 2 &&
                        !creativeGroup.map(({ type }) => type).includes(AdCreativeType.dynamic) && (
                          <div className="p-4">
                            <Button
                              variant="outlined"
                              className="w-full p-3"
                              onClick={() => addCreative(campaignIndex)}
                            >
                              <Add />
                              <p className="ml-2">Add Creative To Campaign</p>
                            </Button>
                          </div>
                        )}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

type ScaffoldCreativeFormProps = {
  creative: CreativeScaffold
  campaignIndex: number
  pairIndex: number
  creativeIndex: number
  setSprintCreatives: SetState<{ [campaignIndex: number]: CreativeScaffold[][] }>
}
const ScaffoldCreativeForm = ({
  creative,
  creativeIndex,
  campaignIndex,
  pairIndex,
  setSprintCreatives,
}: ScaffoldCreativeFormProps) => {
  return (
    <div data-testid={`creative-${creativeIndex}`} className="w-full">
      <div className="flex py-2 w-full" key={creative.name + creativeIndex}>
        <div className="flex items-center space-x-1 flex-grow w-full">
          <TextField
            fullWidth
            variant="outlined"
            name={`creative-${creativeIndex}`}
            defaultValue={creative.name}
            autoFocus
            label={`Creative ${creativeIndex + 1} Name`}
            onChange={({ target }) => {
              setSprintCreatives(
                set([campaignIndex, pairIndex, creativeIndex, 'name'], target.value)
              )
            }}
            required={true}
          />
        </div>

        <div className="w-1/3">
          <FormControl>
            <InputLabel htmlFor={`creative-${campaignIndex + pairIndex + creativeIndex}-type`}>
              Creative Type
            </InputLabel>
            <Select
              placeholder="Creative Type"
              labelId={`creative-${campaignIndex + pairIndex + creativeIndex}-type`}
              variant="outlined"
              name={`creative-${campaignIndex + pairIndex + creativeIndex}-type`}
              value={creative.type}
              inputProps={{
                label: 'Creative Type',
                id: `creative-${campaignIndex + pairIndex + creativeIndex}-type`,
              }}
              onChange={({ target }) =>
                setSprintCreatives(
                  set([campaignIndex, pairIndex, creativeIndex, 'type'], target.value)
                )
              }
              label={'Creative Type'}
              required={true}
            >
              {Object.values(AdCreativeType).map(type => (
                <MenuItem value={type} style={{ width: '100%' }}>
                  {capitalCase(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="ml-2 flex items-center justify-center">
          <button
            className="border border-red-900 rounded-full p-2 text-red-700 hover:bg-red-100"
            onClick={() =>
              setSprintCreatives(update([campaignIndex, pairIndex], removeAtIndex(creativeIndex)))
            }
          >
            <Close />
          </button>
        </div>
      </div>
    </div>
  )
}
