import 'pages'
import React, { useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import { Router } from 'components/router/router'
import { ThemeProvider } from '@material-ui/core'
import { ThemeProvider as NewMuiProvider } from '@mui/material'
import 'react-toastify/dist/ReactToastify.min.css'
import { useSentry } from './hooks/sentry/use-sentry'
import { useAppTheme } from '@marketing-milk/frontend'
import { SessionContextProvider, BusinessContextProvider } from '@marketing-milk/frontend'
import { sessionContext } from '@marketing-milk/frontend'
import './index.css'
import { QueryClientProvider, QueryClient } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'
import Auth0ProviderWithHistory from 'Auth0ProviderWithHistory'
import { Permissions } from '@marketing-milk/interfaces'

const reactQueryClient = new QueryClient()

function AppComponent() {
  const { user } = useContext(sessionContext)

  // Theme needs to stay static 'light' theme because if user has dark mode
  // enabled from Client, only material components will have dark mode styles.
  const theme = useAppTheme('light')
  useSentry()
  return (
    <ThemeProvider theme={theme}>
      {user && !user.permissions.includes(Permissions.AccessAdBuilderApp) ? (
        <Unauthorized />
      ) : (
        <>
          <NewMuiProvider theme={theme}>
            <ToastContainer style={{ zIndex: 5000 }} />
          </NewMuiProvider>
          <Router />
        </>
      )}
    </ThemeProvider>
  )
}

function App() {
  return (
    <QueryClientProvider client={reactQueryClient}>
      <SessionContextProvider>
        <BusinessContextProvider>
          <AppComponent />
        </BusinessContextProvider>
      </SessionContextProvider>
    </QueryClientProvider>
  )
}

const Unauthorized = () => {
  const { isAuthenticated, logout: auth0Logout } = useAuth0()
  const { logout } = useContext(sessionContext)

  function logoutUser() {
    const redirectURL = 'https://www.marketingmilk.com/'
    logout()

    // If the user logged in via Auth0, we need to log them out of Auth0
    // in addition to clearing their Marketing Milk session
    if (isAuthenticated) {
      auth0Logout({ returnTo: redirectURL })
    }

    return <Redirect to={redirectURL} />
  }

  return (
    <div className="loading m-grid__item m-grid__item--fluid m-grid  m-error-1">
      <div className="m-error_container">
        <span className="m-error_number">
          <h1 className="text-admin">Hi,</h1>
        </span>
        <div className="m-error_desc">
          You are not authorized to view this page. To login with another account, click{' '}
          <a onClick={logoutUser}>here</a>
        </div>
      </div>
    </div>
  )
}

export default App
