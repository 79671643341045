export interface CardRibbonProps {
  title: string
  color?: StateColors
}

export declare type StateColors = 'success' | 'danger' | 'info'

export const StateColorClasses: Record<StateColors, { className: string }> = {
  success: {
    className: 'bg-success',
  },
  danger: {
    className: 'bg-danger',
  },
  info: {
    className: 'bg-info',
  },
}
