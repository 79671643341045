import { createContext } from 'react'
import { MMPeriodSelectorContextI } from './MMPeriodSelectorContext.types'
import {
  DateRange,
  defaultStartDate,
  PeriodSelectorPreset,
  PeriodTypes,
} from '../MMPeriodSelector.types'
import moment from 'moment'
import { defaultFormat } from '../util/util'

export const defaultMMPeriodSelectorContext: MMPeriodSelectorContextI = {
  dateRange: { startDate: '', endDate: '' },
  choosingStart: true,
  periodType: 'month',
  showPeriodSelector: false,
  allowedPeriodTypes: ['month', 'quarter'],
  rangeRestriction: {
    startDate: defaultStartDate,
    endDate: moment().format(defaultFormat),
  },
  setShowPeriodSelector: (set: boolean) => {},
  selectPreset: (preset: PeriodSelectorPreset) => {},
  onUpdateDateRange: (periodType: PeriodTypes, dateRange: DateRange) => {},
  setContext: () => {},
}

//global context
export const MMPeriodSelectorContext = createContext<MMPeriodSelectorContextI>(
  defaultMMPeriodSelectorContext
)
