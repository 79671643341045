import { useGetBusinessID } from '@marketing-milk/frontend'
import { AdCreative } from '@marketing-milk/interfaces'
import { Button, Drawer } from '@mui/material'
import { addOrRemove } from 'app/util'
import { Step } from 'components/Stepper'
import { Stepper } from 'components/Stepper/Stepper'
import { useSprint } from 'hooks/api/useSprint'
import { BulkCreativeSelect } from 'pages/business/creatives/components/bulk-operations/BulkSelect'
import { CreativeCard } from 'pages/business/creatives/CreativeCard/CreativeCard'
import { useCreatives } from 'pages/business/creatives/useCreatives'
import { useState } from 'react'

type Props = {
  sprintId: number
}
export const BulkImportCreatives = (props: Props) => {
  const [selectedCreatives, setSelectedCreatives] = useState<AdCreative[]>([])
  const businessId = useGetBusinessID()
  const { importCreativesToSprint } = useSprint(props.sprintId)
  const [open, setOpen] = useState(false)

  const onSelectCreative = (creative: AdCreative) =>
    setSelectedCreatives(prev => addOrRemove(creative, prev))

  const onComplete = () => {
    importCreativesToSprint(selectedCreatives)
    setOpen(false)
  }

  return (
    <div>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Import Creatives
      </Button>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <div style={{ width: '80vw' }}>
          <Stepper submitButtonText="Add To Sprint">
            <Step title="Import Creatives">
              <div className="w-full flex flex-col max-w-7xl mx-auto p-4 space-y-4">
                <h1 className="py-2 font-lg font-bold mx-auto">Select Creatives To Import</h1>
              </div>
              <div>
                <BulkCreativeSelect
                  onSelectCreative={onSelectCreative}
                  selectedCreatives={selectedCreatives}
                />
              </div>
            </Step>
            <Step title="Confirm" onNext={onComplete}>
              <div className="w-full flex flex-col max-w-7xl mx-auto p-4 space-y-4">
                <h1 className="py-2 font-lg font-bold mx-auto">
                  These Creatives Will Be Duplicated and Attached To This Sprint
                </h1>
              </div>
              <div className="grid justify-items-center w-full sm:grid-cols-1 md:grid-cols-3 3xl:grid-cols-5 4xl:grid-cols-5 sm:gap-5">
                {selectedCreatives.map(creative => (
                  <CreativeCard tools={false} creative={creative} onSelect={() => {}} />
                ))}
              </div>
            </Step>
          </Stepper>
        </div>
      </Drawer>
    </div>
  )
}
