import React from 'react'
import { Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import { CustomQuestion } from './CustomQuestion'
import Accordion from '@material-ui/core/Accordion'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ShortAnswerQuestionProps } from './ShortAnswerQuestion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { LeadCardContextFunctionality } from '../../context/LeadCardContext.types'

export type MultipleChoiceAnswer = { key: string; value: string }

export type MultipleChoiceQuestionProps = ShortAnswerQuestionProps &
  Pick<
    LeadCardContextFunctionality,
    | 'updateMultiChoiceOption'
    | 'addMultiChoiceOption'
    | 'deleteMultiChoiceOption'
    | 'updateMultiChoiceOptionKey'
  >

export function MultipleChoiceQuestion({
  index,
  question,
  deleteQuestion,
  updateCustomQuestion,
  updateCustomQuestionKey,
  editable,
  updateMultiChoiceOption,
  updateMultiChoiceOptionKey,
  addMultiChoiceOption,
  deleteMultiChoiceOption,
}: MultipleChoiceQuestionProps) {
  return (
    <>
      <Paper className="p-2 border mb-3">
        <CustomQuestion
          data-testid="multi-choice-question"
          id="multi-choice-question"
          value={question.label}
          onChange={newLabel => updateCustomQuestion(question, newLabel)}
          onDelete={() => deleteQuestion(index)}
          onChangeKeyValue={newKey => updateCustomQuestionKey(index, newKey)}
          keyValue={question.key}
          placeholder="e.g. What's your favorite color?"
          editable={editable}
        />
        <div>
          <Accordion defaultExpanded elevation={0} className="border">
            <AccordionSummary
              className="py-0"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="answers-content"
              id="answers-header"
            >
              <Typography>Answers ({question.options?.length})</Typography>
            </AccordionSummary>
            <AccordionDetails className="pt-0">
              <div className="w-100">
                {question.options!.map((_, i) => (
                  <CustomQuestion
                    key={i}
                    id={`multi-choice-question-answer-${i}`}
                    value={_.value}
                    onChange={newLabel => updateMultiChoiceOption(index, i, question, newLabel)}
                    onDelete={() => deleteMultiChoiceOption(index, i, question)}
                    onChangeKeyValue={(newKey: string) =>
                      updateMultiChoiceOptionKey(index, i, question, newKey)
                    }
                    keyValue={_.key}
                    placeholder="e.g. Red, Blue, Green, etc."
                    editable={editable}
                    canDelete={question.options!.length > 2}
                  />
                ))}
                <br />
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  disabled={!editable}
                  type="button"
                  onClick={() => addMultiChoiceOption(index, question)}
                >
                  <AddIcon className="mr-2" />
                  Add Answer Choice
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </Paper>
    </>
  )
}
