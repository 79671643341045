import React from 'react'
import { toast } from 'react-toastify'
import { getBaseWizardSteps } from '../BaseWizardSteps'
import { AudienceWizardProps } from './AudienceWizard.types'
import { CustomerDataUploader, AudienceSummary } from '../steps'
import { useCustomerFileAudience } from '../use-customer-file-audience'
import { useGetBusinessID, audienceBuilderService } from '@marketing-milk/frontend'
import {
  CustomerFileAudienceDTO,
  CustomerFileCSVData,
  SavedAudienceStandardTargetingSpec,
} from '@marketing-milk/interfaces'

import { handleSubmitAudienceError } from './audience-error-handling'
import { AudienceWizard } from '../../audience-wizard'
import { ReceiptSection } from '../../../../campaign-builder/steps/receipt/section'

export function CustomerFileAudienceWizard({ onPublishWizard, audience }: AudienceWizardProps) {
  const businessID = useGetBusinessID()
  const customerFileAudience = useCustomerFileAudience(businessID, audience)

  const validateCSVData = (data: CustomerFileCSVData) =>
    audienceBuilderService.validateCustomerData(businessID, data)

  const submitAudience = async () => {
    const customerFileAudienceDTO: CustomerFileAudienceDTO = {
      name: customerFileAudience.audienceName,
      description: customerFileAudience.audienceDescription,
      isDefault: customerFileAudience.isDefault,
      targetingSpec: customerFileAudience.targetSpec as SavedAudienceStandardTargetingSpec,
      csvData: customerFileAudience.customerData!,
    }

    try {
      await audienceBuilderService.publishCustomerFileAudience(businessID, customerFileAudienceDTO)
      toast.success("You're audience has been created!")
      onPublishWizard(true)
    } catch (err) {
      handleSubmitAudienceError(err)
    }
  }

  return (
    <AudienceWizard
      steps={[
        {
          title: 'Customer Data',
          completed: !!customerFileAudience.customerData,
          canContinue: !!customerFileAudience.customerData,
          children: (
            <CustomerDataUploader
              handleValidData={customerFileAudience.setCustomerData}
              validateData={validateCSVData}
              targetSpec={customerFileAudience.targetSpec}
            />
          ),
        },
        ...getBaseWizardSteps(customerFileAudience),
        {
          title: 'Summary',
          completed: !!customerFileAudience.audienceName,
          canContinue: !!customerFileAudience.audienceName,
          onNext: submitAudience,
          children: (
            <AudienceSummary
              includedUserTraits={customerFileAudience.getSelectedUserTraits()}
              excludedUserTraits={customerFileAudience.getExcludedUserTraits()}
              targetingSpec={customerFileAudience.targetSpec}
              name={customerFileAudience.audienceName}
              description={customerFileAudience.audienceDescription}
              onUpdateName={customerFileAudience.setAudienceName}
              onUpdateDescription={customerFileAudience.setAudienceDescription}
              onSetDefault={customerFileAudience.setIsDefault}
              isDefault={customerFileAudience.isDefault}
              customReceiptSection={
                <>
                  <ReceiptSection
                    title="Customer File Custom Receipt Section"
                    children={<div>placeholder</div>}
                  />
                </>
              }
            />
          ),
        },
      ]}
    />
  )
}
