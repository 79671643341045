// THIS FILE IS NOT FINISHED

/**
 * Platform Placements are an item that are still in need of work.
 * The array of PlacementBoxPanels below is an incomplete version of a map between what facebook shows, and what we will eventually show
 */

export type PublisherPlatformsMode = 'automatic' | 'manual'
export const isPublisherPlatformsMode = (val: any): val is PublisherPlatformsMode =>
  ['automatic', 'manual'].includes(val)

export type PlacementBoxPanel = {
  title: string
  description: string
  checkboxes: {
    name: positions
    label: string
    checked: boolean
    img: string
    position: any
    dependents?: positions[]
    platforms: platforms[]
  }[]
}

type platforms = 'facebook' | 'instagram' | 'messenger' | 'audience_network'
type platform_positions = keyof typeof placementTargetingPositions

export const placementBoxes: any[] = [
  {
    title: 'Feeds',
    description: 'Get high visibility for your business with ads and feeds',
    checkboxes: [
      {
        name: 'feed',
        label: 'Facebook News Feed',
        checked: false,
        img: 'adset-facebook-news-feed.jpg',
        position: 'facebook_positions',
        platforms: ['facebook'],
      },
      {
        name: 'stream',
        label: 'Instagram Feed',
        checked: false,
        img: 'adset-instagram-news-feed.jpg',
        position: 'instagram_positions',
        platforms: ['instagram'],
      },
      {
        name: 'marketplace',
        label: 'Facebook Marketplace',
        checked: false,
        img: 'adset-facebook-marketplace.jpg',
        position: 'facebook_positions',
        dependents: ['feed'],
        platforms: ['facebook'],
      },
      {
        name: 'video_feeds',
        label: 'Facebook Video Feeds',
        checked: false,
        img: 'adset-facebook-video-feed.jpg',
        platforms: ['facebook'],
        position: 'facebook_positions',
      },
      // {name: '', label: 'Facebook Right Column', checked: false, img: 'adset-facebook-right-column.jpg'},
      { name: '', label: 'Instagram Explore', checked: false, img: 'adset-instagram-explore.png' },
      {
        name: '',
        label: 'Messenger Inbox',
        checked: false,
        img: 'adset-messenger-sponsored-messages.png',
      },
    ],
  },
  {
    title: 'Stories and Reels',
    description: 'Tell a rich, visual story with immersive, fullscreen vertical ads',
    checkboxes: [
      { name: '', label: 'Instagram Stories', checked: false, img: 'adset-instagram-stories.png' },
      { name: '', label: 'Facebook Stories', checked: false, img: 'adset-facebook-stories.png' },
      { name: '', label: 'Messenger Stories', checked: false, img: 'adset-messenger-stories.png' },
      { name: '', label: 'Instagram Reels', checked: false, img: 'adset-instagram-reels.png' },
    ],
  },
  {
    title: 'In-Stream',
    description: "Quickly capture people's attention while they're watching videos",
    checkboxes: [
      {
        name: '',
        label: 'Facebook In-Stream Videos',
        checked: false,
        img: 'adset-facebook-instream-videos.png',
      },
      { name: '', label: 'Instagram IGTV', checked: false, img: 'adset-instagram-tv.png' },
    ],
  },
  {
    title: 'Search',
    description: 'Get visibility for your business as people search on Facebook',
    checkboxes: [
      {
        name: '',
        label: 'Facebook Search Results',
        checked: false,
        img: 'adset-facebook-search.png',
      },
    ],
  },
  {
    title: 'Messages',
    description: 'Send offers or updates to people who are already connected to your business',
    checkboxes: [
      {
        name: '',
        label: 'Messenger Sponsored Messages',
        checked: false,
        img: 'adset-messenger-sponsored-messages.png',
      },
    ],
  },
  {
    title: 'In-Article',
    description: 'Engage with people reading content from publishers',
    checkboxes: [
      {
        name: '',
        label: 'Facebook Instant Articles',
        checked: false,
        img: 'adset-facebook-instant-articles.jpg',
      },
    ],
  },
  {
    title: 'Apps and Sites',
    description: 'Expand your reach with ads in external apps and websites',
    checkboxes: [
      // prettier-ignore
      {name: '', label: 'Audience Network Native, Banner and Interstitial', checked: false, img: 'adset-audience-network-native-banner-interstital.png'},
      {
        name: '',
        label: 'Audience Network Rewarded Videos',
        checked: false,
        img: 'adset-network-rewarded-videos.png',
      },
      {
        name: '',
        label: 'Audience Network In-Stream Videos',
        checked: false,
        img: 'adset-network-rewarded-videos.png',
      },
    ],
  },
]

export const placementTargetingPositions = {
  publisher_platforms: ['facebook', 'instagram', 'messenger', 'audience_network'] as const,
  // prettier-ignore
  facebook_positions: ['feed', 'right_hand_column', 'instant_article', 'video_feeds', 'marketplace', 'story', 'search'] as const,
  instagram_positions: ['stream', 'story', 'explore'] as const,
  audience_network_positions: ['classic', 'instream_video', 'rewarded_video'] as const,
  messenger_positions: ['messenger_home', 'sponsored_messages', 'story'] as const,
}

type positions =
  | 'feed'
  | 'right_hand_column'
  | 'instant_article'
  | 'video_feeds'
  | 'marketplace'
  | 'story'
  | 'search'
  | 'feed'
  | 'right_hand_column'
  | 'instant_article'
  | 'video_feeds'
  | 'marketplace'
  | 'story'
  | 'search'
  | 'stream'
  | 'story'
  | 'explore'
  | 'classic'
  | 'instream_video'
  | 'rewarded_video'
  | 'messenger_home'
  | 'sponsored_messages'
  | 'story'
