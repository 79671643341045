import React, { useState } from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import { ErrorsDrawer } from './ErrorsDrawer'
import { useScreenSize } from 'hooks'

export type ErrorItem = {
  message: string
  title?: string
  details?: string
  actionButton?: React.ReactNode
}

type Props = {
  errors: ErrorItem[]
  drawerTitle?: string
  drawerDescription?: string
}

export const ErrorsIndicator = ({ errors, ...props }: Props) => {
  const { isMobile } = useScreenSize()
  const [open, setOpen] = useState(false)
  return (
    <>
      <ErrorsDrawer
        drawerTitle={props.drawerTitle}
        drawerDescription={props.drawerDescription}
        errors={errors}
        isOpen={open}
        onClose={() => setOpen(false)}
      />

      <button
        onClick={() => setOpen(true)}
        className="bg-red-200 font-medium rounded-full shadow-xl text-red-800 transform transition hover:scale-110 px-2 py-2 flex items-center justify-center"
      >
        <ErrorIcon className="sm:mr-1" />
        {!isMobile && 'View Errors'}
      </button>
    </>
  )
}
