import React from 'react'
import { useHistory } from 'react-router-dom'
import { LeadCardWizard } from './wizard/LeadCardWizard'
import { UserLayout } from 'components/layout/UserLayout'
import { LeadCardCreateDTO } from '@marketing-milk/interfaces'
import { useGetBusinessID, leadCardService } from '@marketing-milk/frontend'
import { FullPageHeader } from 'components/layout/FullPageLayout/Header/FullPageHeader'
import { FullPageContent } from 'components/layout/FullPageLayout/Content/FullPageContent'

export function CreateLeadCard() {
  const history = useHistory()
  const businessID = useGetBusinessID()

  async function onCreate(leadCardDTO: LeadCardCreateDTO) {
    const newLeadCard = await leadCardService.create(Number(businessID), { ...leadCardDTO })
    history.push(`/${businessID}/lead-cards/${newLeadCard.id}`)
  }

  return (
    <UserLayout>
      <FullPageHeader
        breadcrumbs={[
          { title: 'Lead Cards', route: `/${businessID}/lead-cards` },
          { title: 'Create New Lead Card' },
        ]}
      ></FullPageHeader>

      <FullPageContent className="flex flex-col sm:p-4">
        <LeadCardWizard onSave={onCreate} />
      </FullPageContent>
    </UserLayout>
  )
}
