import List from '@material-ui/core/List'
import { useInfiniteScroll } from 'pages/business/creatives/useInifiniteScroll'
import { fuzzySearch } from 'components/DataTable/helpers/search.helpers'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import ImageIcon from '@material-ui/icons/Image'
import { OndemandVideo, Edit, Image } from '@material-ui/icons'
import ListItem from '@material-ui/core/ListItem'
import React, { useContext, useState, useRef, useMemo, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { CreativeModalProps } from './CreativeModal.types'
import ListItemText from '@material-ui/core/ListItemText'
import { AdImage, AdVideo } from '@marketing-milk/interfaces'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { creativeContext } from '../../context/CreativeContext'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ServerError } from 'components/server-request/server-error/ServerError'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import {
  ImageMedia,
  Media,
  VideoMedia,
  MediaModal,
  Dropzone,
  Loading,
} from '@marketing-milk/frontend'
import { adImageService, adVideoService, useGetBusinessID } from '@marketing-milk/frontend'
import { Drawer, Tab, Tabs } from '@mui/material'
import { SearchInput } from 'components'
import { Upload } from '@mui/icons-material'
import { format, fromUnixTime } from 'date-fns'
import { omit, sortBy } from 'lodash/fp'
import { isAdImage, isAdVideo } from 'pages/business/campaigns/helpers/campaign.helpers'
import useMeasure from 'react-use-measure'
import { useTransition, animated } from 'react-spring'
import { BlankPageLayout } from 'components/BlankPageLayout'
import { keyBy, reverse } from 'lodash'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectedMedia: {
      '& .MuiList-root': {
        padding: 0,
      },
      '& .MuiListItem-root': {
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiListItemText-root': {
        paddingLeft: 5,
      },
      '& .MuiListItemText-primary': {
        paddingBottom: 4,
      },
    },
  })
)

export function CreativeModal({ mediaID, mediaType, onChange, readOnly }: CreativeModalProps) {
  const classes = useStyles()
  const businessID = useGetBusinessID()
  const [error, setError] = useState<Error>()
  const [isOpen, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const { images, videos, addImage, addVideo } = useContext(creativeContext)
  const imageTypes = ['.png', '.jpg', '.jpeg']
  const videoTypes = ['.mp4', '.mov', '.gif']

  function onToggle() {
    if (!readOnly) {
      setModal(_ => !_)
    }
  }

  function onSelectMedia(media: Media) {
    onChange(media.id, media.type)
    setModal(false)
  }

  async function onUploadMedia(file: File) {
    setLoading(true)
    try {
      const fileExt = file.name.split('.').pop()!.toLowerCase()
      const isImage = imageTypes.includes('.' + fileExt)
      const isVideo = videoTypes.includes('.' + fileExt)

      if (!isImage && !isVideo) {
        throw new Error(`File type ${fileExt} is not supported on Ad Images yet`)
      }

      const promise = isImage ? adImageService.createImage : adVideoService.createVideo
      const newMedia = await promise(Number(businessID), file)

      if (isImage) {
        addImage(newMedia as AdImage)
        onChange(newMedia.id, 'image')
      } else {
        addVideo(newMedia as AdVideo)
        onChange(newMedia.id, 'video')
      }
      onToggle()
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  const activeMedia: any =
    mediaType === 'image' ? images.find(_ => _.id === mediaID) : videos.find(_ => _.id === mediaID)

  const imageMedia: ImageMedia[] = images.map(_ => ({
    id: _.id,
    type: 'image',
    fileURL: _.url,
    fileName: _.name,
    createdAt: _.createdAt,
    updatedAt: _.updatedAt,
  }))

  const videoMedia: VideoMedia[] = videos.map(_ => ({
    id: _.id,
    type: 'video',
    duration: _.length,
    fileURL: _.permalinkUrl,
    fileName: _.title!,
    thumbnailURL: _.thumbnail!,
    createdAt: _.createdAt,
    updatedAt: _.updatedAt,
  }))
  return (
    <>
      {error && <ServerError error={error} />}
      {activeMedia ? (
        <div className={classes.selectedMedia}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  variant="rounded"
                  src={activeMedia.url ?? activeMedia.thumbnail}
                  alt={activeMedia.name ?? activeMedia.title}
                  style={{ height: 50, width: 50 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={activeMedia?.name ?? activeMedia?.title}
                secondary={activeMedia?.status}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" disabled={readOnly} onClick={onToggle}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  style={{ marginLeft: 13 }}
                  aria-label="delete"
                  disabled={readOnly}
                  onClick={() => onChange(undefined, undefined)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
      ) : (
        <Button variant="contained" color="secondary" disabled={readOnly} onClick={onToggle}>
          <ImageIcon className="mr-2" /> Add Media
        </Button>
      )}

      {/* <MediaUploadModal
        isOpen={isOpen}
        isUploading={loading}
        onClose={onToggle}
        mediaList={[...images, ...videos]}
        onUpload={onUploadMedia}
        onSelect={onChange}
      /> */}

      <MediaModal
        isOpen={isOpen}
        isLoading={loading}
        onToggle={onToggle}
        images={imageMedia}
        imageExtensions={imageTypes}
        videos={videoMedia}
        videoExtensions={videoTypes}
        onUpload={onUploadMedia}
        onSelect={onSelectMedia}
      />
    </>
  )
}

export default function useMedia(queries: string[], values: number[], defaultValue: number) {
  const match = () => values[queries.findIndex(q => matchMedia(q).matches)] || defaultValue

  const [value, set] = useState(match)

  useEffect(() => {
    const handler = () => set(match)
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  return value
}

type MediaModalProps = {
  isOpen: boolean
  onClose: () => void
  isUploading?: boolean
  mediaList: (AdVideo | AdImage)[]
  onUpload: (file: File) => Promise<void>
  onSelect: (id: number, type: 'video' | 'image') => void
}
const MediaUploadModal = (props: MediaModalProps) => {
  const imageTypes = ['.png', '.jpg', '.jpeg']
  const videoTypes = ['.mp4', '.mov', '.gif']

  const [tab, setTab] = useState<'video' | 'images'>('images')
  const [searchTerm, setSearchTerm] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [itemsOnPage, setItemsOnPage] = useState(10)

  const getTabIndex = (tabString: typeof tab) => (tab === 'images' ? 0 : 1)

  const isVideo = (adMedia: AdImage | AdVideo): adMedia is AdVideo => 'source' in adMedia
  const isImage = (adMedia: AdImage | AdVideo): adMedia is AdImage => 'url' in adMedia

  const getSrc = (media: AdImage | AdVideo) => (isVideo(media) ? media.source : media.url)
  const getName = (media: AdImage | AdVideo) => (isVideo(media) ? media.title : media.name)

  // Infinite Scroll Functionality
  useEffect(() => {
    const atBottom = () => {
      const height = window.innerHeight + window.scrollY
      const documentHeight = document.body.offsetHeight
      if (height >= documentHeight - 200) {
        setItemsOnPage(items => items + 10)
      }
    }

    document.addEventListener('scroll', atBottom)

    return () => {
      document.removeEventListener('scroll', atBottom)
    }
  }, [])

  const mediaListRef = useRef(null)
  const { amountDisplayed: amountOfMediaDisplayed } = useInfiniteScroll(
    props.mediaList || [],
    mediaListRef?.current
  )

  const mediaByKey = useMemo(() => keyBy(props.mediaList, 'id'), [])

  const columns = useMedia(
    ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)'],
    [3, 2, 1],
    2
  )
  const [ref, { width }] = useMeasure()

  const getItems = (mediaList: (AdVideo | AdImage)[]) =>
    reverse(fuzzySearch(sortBy('updatedAt', mediaList), searchTerm))

  const [heights, gridItems] = useMemo(() => {
    const heights = new Array(columns).fill(0) // Each column gets a height starting with zero
    const gridItems = getItems(props.mediaList).map((media, i) => {
      const src = getSrc(media)
      const getHeight = () => {
        if (isVideo(media)) return 600
        if (i % 2) return 600
        return 400
        // if (i % 3) return 800
        // return 600
      }
      const srcHeight = getHeight()

      const column = heights.indexOf(Math.min(...heights)) // Basic masonry-grid placing, puts tile into the smallest column using Math.min
      const x = (width / columns) * column // x = container width / number of columns * column index,
      const y = (heights[column] += srcHeight / 2) - srcHeight / 2 // y = it's just the height of the current column
      return {
        src,
        x,
        y,
        type: isVideo(media) ? 'video' : 'image',
        id: media.id,
        width: width / columns,
        height: srcHeight / 2,
      } as {
        src: string
        x: number
        y: number
        type: 'image' | 'video'
        id: number
        width: number
        height: number
      }
    })
    return [heights, gridItems || ([] as NonNullable<typeof gridItems>)]
  }, [columns, props.mediaList, width, amountOfMediaDisplayed])

  const transitions = useTransition(gridItems, {
    key: (item: { css: string; height: number }) => item.css,
    from: props => ({ ...props, opacity: 0 }),
    enter: props => ({ ...props, opacity: 1 }),
    // update: (props) => ({ x, y, width, height }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  })

  const getShowingText = () => {
    return ''
    // const amount = tab === 'video' ? amountOfVideosDisplayed : amountOfImagesDisplayed
    // const total = tab === 'video' ? props.videos.length : props.images.length
    // return `showing ${amount} of ${total} ${tab}s`
  }

  return (
    <Drawer open={props.isOpen} onClose={props.onClose}>
      <div style={{ width: '80vw' }}>
        <BlankPageLayout title="Select Images or Videos" stretch>
          <div className="flex justify-between items-center border-b">
            <div className="flex w-full">
              <SearchInput onSearch={setSearchTerm} />
              <Button variant="contained" color="primary" onClick={() => setIsUploading(true)}>
                <Upload className="mr-2" />
                Upload
              </Button>
            </div>

            <div className="p-2 flex justify-end">{getShowingText()}</div>
          </div>

          {isUploading && (
            <div className="w-full flex flex-col p-4 items-center justify-center">
              {props.isUploading ? (
                <Loading loadingMessage="This process may take several minutes for larger files." />
              ) : (
                <>
                  <Dropzone
                    className="m-4 py-5"
                    allowedExtensions={tab === 'images' ? imageTypes : videoTypes}
                    handleUpload={props.onUpload}
                  />
                  <Button variant="contained" onClick={() => setIsUploading(false)}>
                    Cancel
                  </Button>
                </>
              )}
            </div>
          )}

          <div ref={ref} className="relative w-full h-full">
            {
              !isUploading &&
                transitions((style, item) => (
                  <animated.button
                    style={style}
                    className="absolute z-20 transform p-4"
                    onClick={() => {
                      props.onSelect(item.id, item.type)
                      props.onClose()
                    }}
                  >
                    <div className="relative cursor-pointer hover:scale-105 hover:border bg-gray-200 hover:border-blue-200 bg-cover bg-center h-full w-full transform overflow-hidden uppercase rounded-md shadow-lg text-sm object-cover">
                      <p
                        className={`m-2 px-4 py-2 absolute w-24 text-sm text-center font-medium ${
                          item.type === 'video'
                            ? 'text-green-800 bg-green-200'
                            : 'text-blue-800 bg-blue-200'
                        } rounded-full absolute`}
                      >
                        {item.type}
                      </p>

                      {item.type === 'image' && (
                        <img src={item.src} alt="" className="w-full h-full object-cover" />
                      )}

                      {item.type === 'video' && (
                        <video
                          src={item.src}
                          autoPlay
                          preload="auto"
                          muted
                          loop
                          playsInline
                          className="w-full bg-cover object-cover bg-center"
                        />
                      )}
                    </div>
                  </animated.button>
                ))
              /* <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 grid-flow-row">
                {sortBy('updatedAt', fuzzySearch(props.media, searchTerm))
                  .slice(0, amountOfMediaDisplayed)
                  .map(media => (
                    <li className="relative border rounded-md" key={media.id}>
                      <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                        <p
                          className={`px-4 py-2 ${
                            isAdVideo(media)
                              ? 'text-green-800 bg-green-200'
                              : 'text-blue-800 bg-blue-200'
                          } rounded-full absolute`}
                        >
                          {isAdVideo(media) ? 'video' : 'image'}
                        </p>
                        {isAdImage(media) && (
                          <img
                            src={media.url}
                            alt=""
                            className="object-cover pointer-events-none group-hover:opacity-75 h-48 w-full"
                          />
                        )}

                        {isAdVideo(media) && (
                          <video
                            src={media.permalinkUrl}
                            autoPlay
                            preload="auto"
                            muted
                            loop
                            playsInline
                            className="h-48 w-full object-contain"
                          />
                        )}

                        <button type="button" className="absolute inset-0 focus:outline-none">
                          <span className="sr-only">
                            {isAdImage(media) ? media.name : media.title}
                          </span>
                        </button>
                      </div>
                      <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                        file name
                      </p>
                      <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                        {format(fromUnixTime(media.updatedAt), 'PPP')}{' '}
                      </p>
                    </li>
                  ))}
              </ul> */
            }
          </div>

          {/* {tab === 'video' && !isUploading && (
          <div
            className="w-full h-full overflow-y-scroll p-4"
            onScroll={onVideoScroll}
            ref={videosListRef}
          >
            <div className="w-full grid grid-cols-4 gap-5">
              {sortBy('updatedAt', fuzzySearch(props.videos, searchTerm))
                .slice(0, amountOfVideosDisplayed)
                .map(video => (
                  <div className="rounded-md border hover:shadow-2xl flex flex-col justify-center items-center">
                    <video
                      src={video.fileURL}
                      autoPlay
                      preload="auto"
                      muted
                      loop
                      playsInline
                      className="h-48 w-full object-contain"
                    />
                    <div className="grid grid-cols-2 mb-2">
                      <p className="font-semibold text-sm text-gray-800">name</p>
                      <p>{video.fileName}</p>

                      <p className="font-semibold text-sm text-gray-800">updated</p>
                      <p>{format(fromUnixTime(video.updatedAt), 'PPP')}</p>
                    </div>
                    <button className="h-16 bg-purple-200 w-full font-semibold">Select</button>
                  </div>
                ))}
            </div>
          </div>
        )} */}
        </BlankPageLayout>
      </div>
    </Drawer>
  )
}
