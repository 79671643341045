import React, { useContext } from 'react'
import Paper from '@material-ui/core/Paper'
import MuiAlert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import PhoneIcon from '@material-ui/icons/Phone'
import { leadCardPreviewContext } from '../../context'
import Typography from '@material-ui/core/Typography'
import { ProfilePicture } from '@marketing-milk/frontend'
import { FacebookLeadgenButtonType } from '@marketing-milk/interfaces'

export function MessageForLeads() {
  const { business, leadCard } = useContext(leadCardPreviewContext)
  return (
    <>
      <Paper className="p-3 mt-4">
        <div style={{ textAlign: 'center', alignContent: 'center' }}>
          <div style={{ margin: '0 auto', width: 50, marginTop: -30 }}>
            <ProfilePicture
              initials={business.businessName.slice(0, 1)}
              image={business.fbPageImgURL}
            />
          </div>
          <Typography variant="subtitle1" style={{ color: '#8D949E' }}>
            {business.businessName}
          </Typography>
          <Typography gutterBottom variant="h5">
            {leadCard.thankYouPage?.headline}
          </Typography>
          <Typography variant="body1">{leadCard.thankYouPage?.description}</Typography>
        </div>
        <hr className="my-2" />
        <MuiAlert elevation={0} variant="standard" severity="info">
          You've successfully submitted your responses.
        </MuiAlert>
      </Paper>
      <Button variant="contained" color="secondary" className="mt-4" style={{ width: '100%' }}>
        {leadCard.thankYouPage?.callToAction === FacebookLeadgenButtonType.CALL_BUSINESS && (
          <PhoneIcon data-testid="phone-icon" fontSize="small" className="mr-2" />
        )}
        {leadCard.thankYouPage?.buttonText}
      </Button>
    </>
  )
}
