import { Loading, useGetBusinessID } from '@marketing-milk/frontend'
import { Button } from '@material-ui/core'
import { SearchInput } from 'components'
import { fuzzySearch } from 'components/DataTable/helpers/search.helpers'
import { SelectCreativeType } from 'components/FormFields/Select/SelectCreativeType'
import { NoItemsCreated } from 'components/NoItemsCreated/NoItemsCreated'
import { useScreenSize } from 'hooks'
import { useSprint } from 'hooks/api/useSprint'
import { set, sortBy } from 'lodash/fp'
import { NewCreativeModal } from 'pages/business/creatives/components/NewCreativeModal/NewCreativeModal'
import { CreativeCard } from 'pages/business/creatives/CreativeCard/CreativeCard'
import { useCreatives } from 'pages/business/creatives/useCreatives'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  creativeFilterPredicate,
  CreativeFilters,
} from '../../../creatives/helpers/creative.helpers'
import { BulkImportCreatives } from '../bulk-import-creatives/BulkImportCreatives'

interface SprintsCreativesTabProps {
  sprintId: number
}

export const SprintsCreativesTab = (props: SprintsCreativesTabProps) => {
  const businessID = useGetBusinessID()
  const history = useHistory()

  const { isRefetchingCreatives } = useCreatives(businessID)
  const { sprint, sprintCreatives, sprintCreativesLoading } = useSprint(props.sprintId)
  const { isMobile } = useScreenSize()

  const [searchTerm, setSearchTerm] = useState('')
  const [filters, setFilters] = useState<CreativeFilters>({})

  const showLoader = useMemo(
    () => sprintCreativesLoading || isRefetchingCreatives,
    [sprintCreativesLoading, isRefetchingCreatives]
  )

  if (!sprint) return null
  return (
    <div>
      {/* header */}
      <div className="p-8 border-b flex items-center bg-white space-x-8">
        <NewCreativeModal sprintId={sprint.id}>
          <Button variant="contained" color="primary">
            {isMobile ? '+' : 'Add Creative'}
          </Button>
        </NewCreativeModal>

        <BulkImportCreatives sprintId={sprint.id} />

        <SearchInput placeholder="Search Creatives" onSearch={setSearchTerm} />

        <SelectCreativeType
          placeholder="Filter By Type"
          onSelectType={types => setFilters(set('type', types))}
        />
      </div>

      {!showLoader && sprintCreatives?.length === 0 && (
        <NoItemsCreated title="This sprint does not have any creatives yet" />
      )}

      {sprintCreativesLoading && <Loading loadingMessage="Getting Creatives For Sprint" />}
      {isRefetchingCreatives && <Loading loadingMessage="Refreshing Creatives" />}

      <div className="grid justify-items-center w-full sm:grid-cols-1 md:grid-cols-2 3xl:grid-cols-5 4xl:grid-cols-5 sm:gap-5">
        {sortBy('lastUsed', fuzzySearch(sprintCreatives || [], searchTerm))
          .filter(creativeFilterPredicate(filters))
          .map(creative => (
            <CreativeCard
              key={creative.id}
              creative={creative}
              onSelect={() => history.push(`/${businessID}/sprints/${creative.id}`)}
              pillDisplayed={'reviewStatus'}
              propertiesDisplayed={['name']}
            />
          ))}
      </div>
    </div>
  )
}
