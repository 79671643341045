import { FormatService, useGetBusinessIfExists } from '@marketing-milk/frontend'
import { Business, CampaignSchedule, PublishCampaignDTO } from '@marketing-milk/interfaces'
import Typography from '@material-ui/core/Typography'
import { format, fromUnixTime } from 'date-fns'
import { ReactNode } from 'react'
import { campaignGoalOptimizations } from '../../app/services'
import { utcToZonedTime } from 'date-fns-tz'

export interface SettingsSummaryProps {
  campaignDto: PublishCampaignDTO
  schedule?: CampaignSchedule
  rollingCostPer?: number
}

interface SettingProps {
  title: string
  subtitle?: string | number
  children?: ReactNode
}

const Setting = (props: SettingProps) => (
  <div>
    <Typography variant="h6">{props.title}</Typography>
    <Typography variant="body1">{props.subtitle}</Typography>
    {props.children}
  </div>
)

export function SettingsSummary({ campaignDto, schedule, rollingCostPer }: SettingsSummaryProps) {
  const campaignBudget = FormatService.getValue(campaignDto?.campaign.budget, { type: 'currency' })
  const campaignGoal = campaignGoalOptimizations.find(
    goal => goal.type === campaignDto?.campaign.goal
  )?.name
  const hasPublisherPlatforms =
    campaignDto?.publisherPlatforms && campaignDto?.publisherPlatforms.length > 0
  const platformsDisplay = hasPublisherPlatforms
    ? `${campaignDto?.publisherPlatforms?.map(p => ' ' + p)}`
    : 'Assigned Automatically'
  const business = useGetBusinessIfExists() ?? ({ timezone: 'UTC' } as Business)
  const dates: Date[] = schedule?.adjustmentTimestamps.sort().map(fromUnixTime) ?? []

  return (
    <>
      <div data-testid="settings-summary" className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <Setting title="Name" subtitle={campaignDto.campaign.name}></Setting>
        <Setting title="Budget" subtitle={campaignBudget}></Setting>
        <Setting title="Goal" subtitle={campaignGoal}></Setting>
        <Setting
          title="Duration"
          subtitle={`${format(
            utcToZonedTime(fromUnixTime(campaignDto.campaign.startTime), business.timezone),
            'PPpp'
          )} - ${format(
            utcToZonedTime(fromUnixTime(campaignDto?.campaign.endTime), business.timezone),
            'PPpp'
          )}`}
        >
          <Typography variant="body2">Timezone: {business.timezone}</Typography>
          {schedule && (
            <Typography className="text-bold" variant="body1">
              {schedule.intervalTotal} {schedule.intervalType}(s)
            </Typography>
          )}
        </Setting>

        <Setting title="Platforms" subtitle={platformsDisplay}></Setting>
        <Setting title="Frequency" subtitle={campaignDto.campaign.frequency + ' Times'}></Setting>
        {campaignDto.campaign.maxCostPer && schedule && (
          <>
            <Setting
              title="Cascading Info"
              subtitle={`Target Cost Per: ${
                campaignDto.campaign.maxCostPer
                  ? FormatService.getValue(campaignDto.campaign.maxCostPer, { type: 'currency' })
                  : 'N/A'
              }`}
            >
              <Typography className="text-bold">
                Rolling Cost Per:{' '}
                {rollingCostPer !== null && rollingCostPer !== undefined ? (
                  <span
                    className={`text-lg ${
                      !rollingCostPer ||
                      !campaignDto.campaign.maxCostPer ||
                      rollingCostPer > campaignDto.campaign.maxCostPer
                        ? 'text-danger'
                        : 'text-success'
                    }`}
                  >
                    {FormatService.getValue(rollingCostPer, { type: 'currency' })}
                  </span>
                ) : (
                  'Pending'
                )}
              </Typography>
            </Setting>

            <Setting
              title="Cascading Schedule"
              subtitle={`Evaluated every ${schedule.interval} ${schedule.intervalType}(s):`}
            >
              <ol>
                {dates.map(date => (
                  <li>{format(utcToZonedTime(date, business.timezone), 'PPpp')}</li>
                ))}
              </ol>
            </Setting>
          </>
        )}
      </div>
    </>
  )
}
