import React from 'react'
import { TargetSpec } from '@marketing-milk/interfaces/dist/facebook/audience/TargetingSpec/TargetingSpec'
import { FlexibleTargetSpec } from '@marketing-milk/interfaces/dist/facebook/audience/TargetingSpec/FlexibleTargeting'
import { MultiOptionItem } from 'components'
import { FacebookTargetingSpec } from '@marketing-milk/interfaces'

/**
 * On our front end, we only use the properties of the facebook target spec that contain a value generated by facebook.
 * Not only that, but we are limiting that to one of 6 specific properties
 */
export type FlexibleSpec = Partial<Pick<FlexibleTargetSpec & TargetSpec, TraitCategories>>

/**
 * IMPORTANT: The original `FacebookTargetingSpec` is deprecated and not indicitive of the actual code within demographics.
 * This is a temporary replacement, meant to be casted in order to prevent TS errors.  Whenever The deprecated `FacebookTargetingSpec`
 * is completely removed, we can remove this type.
 */
export type TargetingSpec = Omit<FacebookTargetingSpec, 'exclusions' | 'flexible_spec'> & {
  flexible_spec: FlexibleSpec[]
  exclusions: FlexibleSpec
}

export type DemographicMultiOptionItem = MultiOptionItem<keyof FlexibleSpec>

export type DemographicsProps = {
  setTargetSpec: React.Dispatch<React.SetStateAction<TargetingSpec>>
  targetSpec: TargetingSpec
  businessID: string | number
  options: DemographicMultiOptionItem
}

export const userTraitCategories = [
  'interests',
  'behaviors',
  'life_events',
  'family_statuses',
  'industries',
  'income',
] as const
export type TraitCategories = typeof userTraitCategories[number]
export const traitCategoriesDisplay: Record<TraitCategories, string> = {
  interests: 'Interests',
  behaviors: 'Behaviors',
  life_events: 'Life Events',
  family_statuses: 'Family Statuses',
  industries: 'Industries',
  income: 'Income',
}
