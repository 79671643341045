import React from 'react'
import { AdCreative, AdCreativeType } from '@marketing-milk/interfaces'
import { CarouselCreative } from './carousel-creative/CarouselCreative'
import { DynamicCreative } from './dynamic-creative/DynamicCreative'
import { SingleCreative } from './single-creative/SingleCreative'

export function getCreativeFormat(format: AdCreativeType, creative: AdCreative) {
  switch (format) {
    case AdCreativeType.carousel:
      return <CarouselCreative creative={creative} />
    case AdCreativeType.dynamic:
      return <DynamicCreative />
    default:
      return <SingleCreative />
  }
}
