import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import './public/sass/index.scss'
import '@marketing-milk/frontend/dist/frontend.css'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
