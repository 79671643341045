import React from 'react'
import { toast } from 'react-toastify'
import { AudienceWizardProps } from './AudienceWizard.types'
import { getBaseWizardSteps } from '../BaseWizardSteps'
import { useLeadCardAudience } from '../use-lead-card-audience'
import { LeadCardStep, leadMenuOptions, AudienceSummary } from '../steps'
import { audienceBuilderService, useGetBusinessID, Line } from '@marketing-milk/frontend'
import { LeadFormAudienceDTO, SavedAudienceStandardTargetingSpec } from '@marketing-milk/interfaces'

import { handleSubmitAudienceError } from './audience-error-handling'
import { AudienceWizard } from '../../audience-wizard'
import { ReceiptSection } from '../../../../campaign-builder/steps/receipt/section'

export function LeadCardAudienceWizard({ onPublishWizard, audience }: AudienceWizardProps) {
  const businessID = useGetBusinessID()
  const leadCardAudience = useLeadCardAudience(businessID, audience)

  async function submitAudience() {
    const dto: LeadFormAudienceDTO = {
      name: leadCardAudience.audienceName,
      description: undefined,
      targetingSpec: leadCardAudience.targetSpec as SavedAudienceStandardTargetingSpec,
      retentionDays: leadCardAudience.audienceData.formRetention,
      leadFormIDs: [leadCardAudience.audienceData.selectedLeadForm],
      menuSelection: leadCardAudience.audienceData.selectedCategory,
      isDefault: leadCardAudience.isDefault,
    }

    try {
      await audienceBuilderService.publishLeadCardAudience(businessID, dto)
      toast.success("You're audience has been created and published!")
      onPublishWizard(true)
    } catch (err) {
      handleSubmitAudienceError(err)
    }
  }

  return (
    <>
      <AudienceWizard
        steps={[
          {
            title: 'Lead Card Settings',
            completed:
              !!leadCardAudience.audienceData.selectedCategory &&
              !!leadCardAudience.audienceData.formRetention &&
              leadCardAudience.audienceData.selectedLeadForm !== '',
            canContinue:
              !!leadCardAudience.audienceData.selectedCategory &&
              !!leadCardAudience.audienceData.formRetention &&
              leadCardAudience.audienceData.selectedLeadForm !== '',
            children: (
              <LeadCardStep
                leadCards={leadCardAudience.leadCards}
                facebookPageData={leadCardAudience.facebookPageData}
                onRetentionUpdate={leadCardAudience.onRetentionUpdate}
                onSelectCategory={leadCardAudience.onSelectCategory}
                selectedCategory={leadCardAudience.audienceData.selectedCategory}
                formRetention={leadCardAudience.audienceData.formRetention}
                onSelectForm={leadCardAudience.onSelectForm}
                selectedLeadForm={leadCardAudience.audienceData.selectedLeadForm}
                targetSpec={leadCardAudience.targetSpec}
              />
            ),
          },
          ...getBaseWizardSteps(leadCardAudience),
          {
            title: 'Summary',
            completed: !!leadCardAudience.audienceName,
            canContinue: !!leadCardAudience.audienceName,
            onNext: submitAudience,
            children: (
              <AudienceSummary
                includedUserTraits={leadCardAudience.getSelectedUserTraits()}
                excludedUserTraits={leadCardAudience.getExcludedUserTraits()}
                targetingSpec={leadCardAudience.targetSpec}
                name={leadCardAudience.audienceName}
                description={leadCardAudience.audienceDescription}
                onUpdateName={leadCardAudience.setAudienceName}
                onUpdateDescription={leadCardAudience.setAudienceDescription}
                onSetDefault={leadCardAudience.setIsDefault}
                isDefault={leadCardAudience.isDefault}
                customReceiptSection={
                  <>
                    <ReceiptSection
                      title="Lead Card Settings"
                      children={
                        <>
                          <Line
                            left="Facebook Lead Card"
                            right={leadCardAudience.selectedForm?.formName}
                          />
                          <Line
                            left="Retention:"
                            right={`${leadCardAudience.audienceData.formRetention} Days`}
                          />
                          <Line
                            left="Category:"
                            right={leadMenuOptions[leadCardAudience.audienceData.selectedCategory]}
                          />
                        </>
                      }
                    />
                  </>
                }
              />
            ),
          },
        ]}
      />
    </>
  )
}
