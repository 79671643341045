import { AdCard } from './AdCard'
import React, { useState } from 'react'
import { getCreativeFromPair } from 'app/util'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
// TODO:  remove this type when the correct type is inside of mono repo
import { AdPair } from 'pages/business/campaigns/forms/CampaignForm'
import { FBAdPreviewFormat } from '@marketing-milk/interfaces'
import { getPreview } from 'pages/business/creatives/creative-wizard/ad-preview/views/getPreview'

export interface AdSummaryProps {
  selectedCreatives?: AdPair[]
}

export function AdSummary({ selectedCreatives }: AdSummaryProps) {
  const [activeCreativesIndex, setActiveCreativesIndex] = useState(0)
  const creativeA = getCreativeFromPair('A', activeCreativesIndex, selectedCreatives)
  const creativeB = getCreativeFromPair('B', activeCreativesIndex, selectedCreatives)
  const currentAdPair = activeCreativesIndex + 1
  const previousAdPair = () => setActiveCreativesIndex(activeCreativesIndex - 1)
  const nextAdPair = () => setActiveCreativesIndex(activeCreativesIndex + 1)

  const noCreative = (
    <div className="h-100 d-flex justify-content-center">
      <div className="center-vertical text-center">
        <BrokenImageIcon
          color="disabled"
          className="mx-auto mb-2"
          fontSize="large"
          style={{ fontSize: '40px' }}
        />
        <Typography variant="h5" className="MuiTypography-colorTextSecondary">
          No Creative Selected
        </Typography>
      </div>
    </div>
  )

  return (
    <div data-testid="ad-summary" className="w-100">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>{creativeA ? <AdCard title="Creative A" creative={creativeA} /> : noCreative}</div>
        <div>{creativeB ? <AdCard title="Creative B" creative={creativeB} /> : noCreative}</div>
      </div>
      {selectedCreatives && selectedCreatives?.length > 1 && (
        <div data-testid="ad-pair-controls" className="w-100 mt-4">
          <div className="d-flex fit-content mx-auto text-center">
            <IconButton
              data-testid="previous-ad-pair"
              size="medium"
              disabled={activeCreativesIndex === 0}
              onClick={previousAdPair}
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
            <Typography className="mx-4 center-vertical" variant="subtitle1">
              {currentAdPair} of {selectedCreatives.length}
            </Typography>
            <IconButton
              data-testid="next-ad-pair"
              size="medium"
              disabled={currentAdPair === selectedCreatives.length}
              onClick={nextAdPair}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  )
}
