// This File Contains a Console.logged output of an Adset with errors on 11/18/2021
import { range } from 'lodash/fp'
import {
  SavedAudienceType,
  CampaignAd,
  CampaignAdset,
  AdCreative, // Broken
  UserRoleScopes, // Broken
  UserRole, // Broken
  FacebookAdError, // Broken
  User, // Broken
  LocationCustomTargetSpec, // Broken
  UserRoleNotifications,
  UserRolesBehavior,
  UserRolesType,
  AdCreativeReview,
  TutorialProgress,
  AdCreativeComment,
  AdCreativeType,
  FBCallToAction,
  AssetApprovalStatus,
  PublishCampaignDTO,
  CampaignGoalType,
  exampleLeadCard,
  exampleAdImage,
  AdCreativeStatus,
  exampleFacebookTargetingSpec,
  exampleAdCreative,
  exampleUser,
  SavedAudience,
  SavedAudienceStatus,
} from '@marketing-milk/interfaces'
import { addMonths, getUnixTime } from 'date-fns'

export const makeMockDTO = (dto?: PublishCampaignDTO) => ({
  ...mockCampaignDTO,
  ...dto,
})

export const mockCampaignDTO: PublishCampaignDTO = {
  audienceIDs: [1, 2],
  creativeGroups: [[1, 2]],
  campaign: {
    budget: 250,
    startTime: getUnixTime(new Date()),
    endTime: getUnixTime(addMonths(new Date(), 1)),
    frequency: 7,
    goal: CampaignGoalType.LeadGeneration,
    name: 'Mock Campaign DTO',
  },
}

// Ad Error Not Consistent with what is returned. Id is returned, but not inside the error object
export const mockFacebookError: FacebookAdError = {
  // @ts-ignore Id is not inside of facebookAdError
  id: 7,
  title: 'Error',
  message: 'message',
  details: 'details',
}
export const mockCampaignError = { ...mockFacebookError, title: 'Campaign Error' }
export const mockAdsetError = { ...mockFacebookError, title: 'Adset Error' }
export const mockAdError = { ...mockFacebookError, title: 'Ad Error' }
export const mockAdAError = { ...mockFacebookError, title: 'Ad A Error' }
export const mockAdBError = { ...mockFacebookError, title: 'Ad B Error' }

// @ts-ignore typescript has every property marked as required, but many properties arenot being returned
export const mockScopes: UserRoleScopes = {
  test: false,
  isAdmin: true,
  isDeveloper: true,
  manageUsers: true,
  manageUserRoles: true,
  manageBusinessUsers: true,
  manageBusinessUploads: true,
  manageUploadSubmissions: true,
  manageBusinessDataSources: true,
  manageCreativeHubTemplates: true,
}

export const mockNotifications: UserRoleNotifications = {
  businessGoalReached: false,
  businessHealthAlert: false,
  businessTwoMonthGoalReached: false,
  businessStatusUpdate: false,
  businessFailingGoals: false,
  businessNewLeadReceived: false,
  rollthroughReports: false,
  creativeHubAdApproved: false,
  creativeHubAdRejected: false,
  creativeHubAdPending: false,
  businessClientUploadReminder: false,
}

export const mockRole: UserRole = {
  id: 24,
  behavior: UserRolesBehavior.Developer,
  type: UserRolesType.Employee,
  name: 'Developer',
  // Scopes Type is Not Compatible with What is Returned.  Type has every property as a required boolean.
  // Only a subset is currently displayed
  // @ts-ignore
  scopes: mockScopes,
  notifications: mockNotifications,
}

// User Type Not compatible with what is actually returned
// @ts-ignore
export const mockUser: User = {
  id: 4418,
  firstName: 'John',
  lastName: 'Munson',
  role: mockRole,
  fbConnected: true,
  type: 'Administrator',
  email: 'johncmunson91@gmail.com',
  profilePicture: 'https://graph.facebook.com/2803173383253504/picture?type=large',
  creativeHubTutorialCompleted: false,
  // @ts-ignore -> type defines podID as undefined or number
  branchID: null,
  // @ts-ignore -> type defines podID as undefined or number
  podID: null,
  tutorial: {
    chubIntroduction: true,
    chubCreateCreative: true,
    chubChooseAdFormat: true,
  },
  createdAt: '2020-08-03T19:18:47.000Z',
  updatedAt: '2021-11-18T15:49:45.000Z',
  webBuilder: false,
  // @ts-ignore -> type defines podID as undefined or Date
  privacyAddendum: null,
  theme: 0,
}

export const mockReview: AdCreativeReview = {
  id: 874,
  creativeID: 1783503,
  user: mockUser,
  type: 'internal',
  approved: true,
  createdAt: 1637254559,
  updatedAt: 1637254559,
}

export const mockTutorial: TutorialProgress = {
  chubIntroduction: true,
  chubCreateCreative: true,
  chubChooseAdFormat: true,
}

export const mockComment: AdCreativeComment = {
  id: 956,
  creativeID: 1783504,
  user: mockUser,
  message: 'asf',
  rating: 5,
  ratingCopy: 5,
  ratingGraphic: 5,
  createdAt: 1637254563,
  updatedAt: 1637254563,
}

export const mockAd: AdCreative = {
  id: 1783504,
  name: '1b1',
  format: AdCreativeType.single_image,
  status: AdCreativeStatus.ACTIVE,
  locked: true,
  singleImage: exampleAdImage,
  effectiveObjectStoryID: '155873241210106_2363269800470428',
  imageURL:
    'https://scontent-dfw5-2.xx.fbcdn.net/v/t45.1600-4/186619961_6235713361316_5173245071227661000_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=2aac32&_nc_ohc=0CCkxJyR_HoAX-K3v5_&_nc_ht=scontent-dfw5-2.xx&edm=AE73ty4EAAAA&oh=edaff50306b66ec5d607a115607ee5af&oe=619AB45C',
  callToAction: FBCallToAction.ADD_TO_CART,
  thumbnailURL:
    'https://external-dfw5-1.xx.fbcdn.net/safe_image.php?d=AQF7nEAzeBfm6rLF&w=64&h=64&url=https%3A%2F%2Fwww.facebook.com%2Fads%2Fimage%2F%3Fd%3DAQK5W8uknd4EPlJVWgjNWZZYptpXr3Mj8VNavg-m6DI-0yAA56d2TTPEBjorSoEsxBGopY3MISxJVoLVO_1Rq7FTxCefzL1xsvqKESPr0kTXxgak0ofvuN6M-aJOSWVmQaJdwYY2H8L2MkEElDzU_Y7d&cfs=1&ext=emg0&_nc_oe=6f0f4&_nc_sid=58080a&ccb=3-5&_nc_hash=AQFJoOw09UF4UNbO',
  graphicText: 'Graphic Text',
  primaryText: 'Try today!',
  linkHeadline: 'Hurry, before this offer ends',
  linkDescription: 'St. Louis is great',
  linkWebsiteURL: 'https://drivesocialnow.com/',
  linkDisplayURL: 'https://drivesocialnow.com/',
  lastUsed: 1637254719,
  createdAt: 1637254033,
  creativeOrigin: 1,
  isHidden: false,
  // @ts-ignore typescript has this as number or undefined
  deletedAt: null,
  reviewStatus: AssetApprovalStatus.Ready,
  reviewType: 'internal',
  reviews: [mockReview],
  comments: [mockComment],
}

export const mockLeadCardCreative: AdCreative = {
  ...mockAd,
  leadCardUsed: exampleLeadCard,
}

export const makeMockCreative = (name: string, changes?: Partial<AdCreative>): AdCreative => ({
  ...mockAd,
  name,
  ...changes,
})
export const mockNormalCreatives = range(1, 4).map(num =>
  makeMockCreative('Creative ' + num, { id: num })
)
export const mockLeadCardCreatives = mockNormalCreatives.map(creative => ({
  ...creative,
  name: 'Lead Card ' + creative.name,
}))
export const mockApprovedCreative = makeMockCreative('Approved Creative', {
  reviewStatus: AssetApprovalStatus.Approved,
})
export const mockDraftCreative = makeMockCreative('Approved Creative', {
  reviewStatus: AssetApprovalStatus.Draft,
})
export const mockSingleCreative = makeMockCreative('Single Image Creative', {
  format: AdCreativeType.single_image,
})
export const mockCarouselCreative = makeMockCreative('Carousel Creative', {
  format: AdCreativeType.carousel,
})
export const mockCreatives = [...mockNormalCreatives, ...mockLeadCardCreatives]
export const mockCustomLocation: LocationCustomTargetSpec = {
  name: 'FWHP+3C Marne, IA, USA',
  address_string: 'FWHP+3C Marne, IA, USA',
  distance_unit: 'mile',
  // @ts-ignore typescript has this as a string
  latitude: 41.477689453604,
  // @ts-ignore typescript has this as a string
  longitude: -95.063886794475,
  radius: 25,
  primary_city_id: 2445585,
  region_id: 3858,
  country: 'US',
}

export const mockSavedAudience: SavedAudience = {
  id: 1,
  businessID: 1,
  isDefault: false,
  name: 'New Customers',
  description: 'Lorem ipsum',
  status: SavedAudienceStatus.Active,
  type: SavedAudienceType.HumanMade,
  facebookID: '123',
  approximateCountLowerBound: 55000,
  approximateCountUpperBound: 66000,
  isPotentialApproximation: false,
  created_at: undefined,
  updated_at: 1603908720,
  timeLastUsed: 1603908740,
  targeting: exampleFacebookTargetingSpec,
  numberOfTimesUsed: 0,
  deleted: false,
  createdWithMM: true,
  history: [
    {
      action: 'CREATE',
      user: exampleUser,
      updatedAt: 1603908720,
    },
    {
      action: 'UPDATE',
      user: exampleUser,
      updatedAt: 1603908920,
    },
  ],
}

export const mockCampaignAd: CampaignAd = {
  ...exampleAdCreative,
  adsetId: 1,
  startDate: new Date().getTime(),
  endDate: new Date().getTime(),
  adGroup: 1,
}

export const mockCampaignAdset: CampaignAdset = {
  ...mockSavedAudience,
  id: 210332,
  businessID: 791,
  name: 'test 2',
  status: 'ACTIVE',
  facebookID: '6254586480916',
  // @ts-ignore Typescript defines this as null | number | undefined
  created_at: '1637262411',
  // @ts-ignore Typescript defines this as null | number | undefined
  updated_at: '1637262411',
  targeting: exampleFacebookTargetingSpec,
  audienceID: 18585,
  adGroups: [[mockCampaignAd, mockCampaignAd]],
}

export const mockAdAWithError = {
  ...mockCampaignAd,
  name: 'Ad A',
  errors: [mockAdAError],
}

export const mockAdBWithError = {
  ...mockCampaignAd,
  name: 'Ad B',
  errors: [mockAdBError],
}

export const mockAdsetWithErrors: CampaignAdset = {
  ...mockCampaignAdset,
  errors: [mockFacebookError],
}
export const mockAdsetWithAdErrors: CampaignAdset = {
  ...mockCampaignAdset,
  errors: [mockAdsetError],
  adGroups: [[mockAdAWithError, mockAdBWithError]],
}
