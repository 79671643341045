import React from 'react'
import { FormIntro } from './FormIntro'
import { FormQuestions } from './FormQuestions'

export function LeadCardCustomQuestions() {
  return (
    <>
      <FormIntro />
      <FormQuestions />
    </>
  )
}
