import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

export interface PrivacyTextProps {
  editable: boolean
  text?: string
  onChangeText(text: string): void
}
export const privacyTextMaxLength = 70

export function PrivacyText({ editable, text, onChangeText }: PrivacyTextProps) {
  return (
    <div className="form-group">
      <TextField
        error={!text && editable}
        helperText={!text && editable && 'Privacy text is a required field'}
        label="Text *"
        fullWidth
        multiline
        disabled={!editable}
        value={text}
        onChange={event => onChangeText(event.target.value as string)}
        rows={1}
        inputProps={{ maxLength: privacyTextMaxLength, 'data-testid': 'privacy-text-input' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="subtitle1">
                {text?.length ?? 0}/{privacyTextMaxLength}
              </Typography>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
        placeholder="View our privacy policy here"
      />
    </div>
  )
}
