import React from 'react'
import { toast } from 'react-toastify'
import { AudienceWizardProps } from './AudienceWizard.types'
import { AudienceSummary, FunnelList } from '../steps'
import { getBaseWizardSteps } from '../BaseWizardSteps'
import { useFunnelAudience } from '../use-funnel-audience'
import { audienceBuilderService, useGetBusinessID, Line } from '@marketing-milk/frontend'
import {
  MMGeneratedAudienceDTO,
  SavedAudienceStandardTargetingSpec,
} from '@marketing-milk/interfaces'

import { handleSubmitAudienceError } from './audience-error-handling'
import { AudienceWizard } from '../../audience-wizard'
import { ReceiptSection } from '../../../../campaign-builder/steps/receipt/section'

export function FunnelAudienceWizard({ onPublishWizard, audience }: AudienceWizardProps) {
  const businessID = useGetBusinessID()
  const funnelAudience = useFunnelAudience(businessID, audience)

  async function submitAudience() {
    if (!funnelAudience.audienceData.selectedStageID) {
      toast.error('You must select a funnel stage to populate your audience.')
      return
    }

    const dto: MMGeneratedAudienceDTO = {
      name: funnelAudience.audienceName,
      description: undefined,
      isDefault: funnelAudience.isDefault,
      targetingSpec: funnelAudience.targetSpec as SavedAudienceStandardTargetingSpec,
      selectedStageID: funnelAudience.audienceData.selectedStageID,
      autoUpdate: funnelAudience.audienceData.autoUpdate,
    }

    try {
      await audienceBuilderService.publishFunnelListAudience(businessID, dto)
      toast.success("You're audience has been created!")
      onPublishWizard(true)
    } catch (err) {
      handleSubmitAudienceError(err)
    }
  }

  return (
    <AudienceWizard
      steps={[
        {
          title: 'Funnel Stage',
          completed: funnelAudience.canProceed(),
          canContinue: funnelAudience.canProceed(),
          children: (
            <FunnelList
              funnelStageOptions={funnelAudience.funnelStageOptions}
              autoUpdate={funnelAudience.audienceData.autoUpdate}
              onChangeAutoUpdate={funnelAudience.onChangeAutoUpdate}
              onSelectStageID={funnelAudience.onSelectStageID}
              selectedStageID={funnelAudience.audienceData.selectedStageID}
              targetSpec={funnelAudience.targetSpec}
            />
          ),
        },
        ...getBaseWizardSteps(funnelAudience),
        {
          title: 'Summary',
          completed: !!funnelAudience.audienceName,
          canContinue: !!funnelAudience.audienceName,
          onNext: submitAudience,
          children: (
            <AudienceSummary
              includedUserTraits={funnelAudience.getSelectedUserTraits()}
              excludedUserTraits={funnelAudience.getExcludedUserTraits()}
              targetingSpec={funnelAudience.targetSpec}
              name={funnelAudience.audienceName}
              description={funnelAudience.audienceDescription}
              onUpdateName={funnelAudience.setAudienceName}
              onUpdateDescription={funnelAudience.setAudienceDescription}
              onSetDefault={funnelAudience.setIsDefault}
              isDefault={funnelAudience.isDefault}
              customReceiptSection={
                <>
                  <ReceiptSection
                    title="Marketing Milk List Settings"
                    children={
                      <>
                        {funnelAudience.selectedStage && (
                          <>
                            <Line
                              left="Selected Funnel Stage:"
                              right={
                                <>
                                  <i
                                    className={`${funnelAudience.selectedStage?.icon} text-inherit`}
                                  />{' '}
                                  {funnelAudience.selectedStage?.name}
                                </>
                              }
                            />
                            <Line
                              left="Auto Add Future Customers:"
                              right={funnelAudience.audienceData.autoUpdate ? 'Yes' : 'No'}
                            />
                          </>
                        )}
                      </>
                    }
                  />
                </>
              }
            />
          ),
        },
      ]}
    />
  )
}
