import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

export function useInitSentry() {
  useEffect(() => {
    Sentry.init({
      autoSessionTracking: true,
      environment: 'ad-builder',
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      enabled:
        process.env.REACT_APP_SENTRY_ENABLED === 'true' || process.env.NODE_ENV === 'production',
      tracesSampleRate: 1.0,
    })
  }, [])
}
