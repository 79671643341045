import './slider.scss'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Slider as MaterialSlider, Mark } from '@material-ui/core'

export interface SliderProps {
  defaultValue: number
  min: number
  max: number
  minLabel?: string
  maxLabel?: string
  className?: string
  onChangeValue: (value: number) => void
  marks?: boolean | Mark[]
  hideMinMaxLabels?: boolean
  onChangeCommitted?: () => void
}

const StyledSlider = withStyles({
  root: {
    color: '#76CCBE',
    height: 8,
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -3,
    marginLeft: -12,
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 14px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(MaterialSlider)

export function Slider(props: SliderProps) {
  const {
    defaultValue,
    min,
    max,
    onChangeValue,
    minLabel,
    maxLabel,
    className,
    marks,
    hideMinMaxLabels = false,
    onChangeCommitted,
  } = props

  function changeValue(event: React.ChangeEvent<{}>, value: number | number[]) {
    const newValue: number = Array.isArray(value) ? value[0] : value
    onChangeValue(newValue)
  }

  return (
    <div className={`range-slider ${className ? className : ''}`}>
      <div className="container-fluid">
        <div className="row">
          {!hideMinMaxLabels && (
            <div className="col-2 p-0">
              <div className="slider-min float-right mt-2">
                {min} {minLabel && minLabel}
              </div>
            </div>
          )}
          <div className={`col-${hideMinMaxLabels ? '12' : '8'} px-3`}>
            <StyledSlider
              marks={marks ?? false}
              min={min}
              max={max}
              value={defaultValue}
              onChange={changeValue}
              aria-labelledby="continuous-slider"
              onChangeCommitted={() => (onChangeCommitted ? onChangeCommitted() : {})}
            />
          </div>
          {!hideMinMaxLabels && (
            <div className="col-2 p-0">
              <div className="slider-max text-left mt-2">
                {max} {maxLabel && maxLabel}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
