import React, { useContext } from 'react'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { sessionContext } from '@marketing-milk/frontend'
import { dynamicContext } from '../../context/DynamicContext'
import { creativeContext } from '../../../../context/CreativeContext'
import { SelectCallToAction } from '../../../../fields/select-call-to-action/SelectCallToAction'
import { FBCallToAction } from '@marketing-milk/interfaces'
import { Alert } from '@mui/lab'

export function DynamicCallToAction() {
  const { user } = useContext(sessionContext)
  const { creative } = useContext(creativeContext)
  const canEdit = !creative!.locked
  const { callToActions, addCallToAction, delCallToAction, setCallToAction } =
    useContext(dynamicContext)

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="w-100 d-flex">
            <Typography variant="h5" className="pt-2" data-testid="cta-tab">
              Call to Action
            </Typography>
            <Button
              className="ml-auto mr-0"
              color="secondary"
              variant="contained"
              disabled={callToActions.length === 5}
              onClick={addCallToAction}
            >
              <AddIcon className="mr-2" /> Add
            </Button>
          </div>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {callToActions.map((callToAction, i) => {
            const variation = i + 1
            const isDuplicate = callToActions.filter(x => x === callToAction).length > 1
            return (
              <div className="mb-3" key={i}>
                <SelectCallToAction
                  error={isDuplicate || !callToAction}
                  helperText={
                    isDuplicate || !callToAction
                      ? 'Field must be unique and cannot be empty'
                      : undefined
                  }
                  onlyLeadOptions={!!creative.leadCardUsed}
                  callToAction={callToAction}
                  customLabel={
                    <>
                      Call to Action {variation}{' '}
                      <i
                        data-testid={`delete-icon-${variation}`}
                        className="fa fa-trash-alt text-danger ml-2 cursor-pointer"
                        onClick={() => delCallToAction(i)}
                      />
                    </>
                  }
                  customLabelID={`select-${variation}`}
                  onChange={c => setCallToAction(i, c)}
                  readOnly={!canEdit}
                />
                {/* warning for users that this creative will have issues if used in a campaign optimized for website sessions */}
                {callToAction === FBCallToAction.SEE_MORE && (
                  <Alert variant="filled" severity="warning" className="bg-warning faded">
                    Warning: Dynamic creatives with the "See More" call to action cannot be used in
                    campaigns optimized for Website Sessions "Reach".
                  </Alert>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
