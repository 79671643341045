import React, { useContext } from 'react'
import { TextBullets } from './TextBullets'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { ProfilePicture } from '@marketing-milk/frontend'
import { leadCardPreviewContext } from '../../context'

export function FormIntro() {
  const { business, leadCard } = useContext(leadCardPreviewContext)
  return (
    <Paper className="p-3 mb-3">
      <div style={{ textAlign: 'center', alignContent: 'center' }}>
        <div style={{ margin: '0 auto', width: 50, marginTop: -30 }}>
          <ProfilePicture
            initials={business.businessName.slice(0, 1)}
            image={business.fbPageImgURL}
          />
        </div>
        <Typography variant="subtitle1" style={{ color: '#8D949E' }}>
          {business.businessName}
        </Typography>
        <Typography gutterBottom variant="h5">
          {leadCard.intro.greeting?.headline}
        </Typography>
      </div>
      {leadCard.intro.greeting?.bodyStyle && (
        <>
          {leadCard.intro.greeting.bodyStyle === 'PARAGRAPH_STYLE' ? (
            <Typography
              variant="body1"
              style={{ lineHeight: 1.5, whiteSpace: 'pre-line', textAlign: 'center' }}
            >
              {leadCard.intro.greeting.bodyItems[0]}
            </Typography>
          ) : (
            <TextBullets bullets={leadCard.intro.greeting.bodyItems} />
          )}
        </>
      )}
    </Paper>
  )
}
