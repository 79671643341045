import {
  BulkCampaignUpload,
  Campaign,
  CampaignGoals,
  PublishCampaignDTO,
  CustomConversion,
  CampaignPauseActionType,
  PauseCampaignDTO,
  CampaignAdset,
  AdGroup,
  GenerateCampaignScheduleDTO,
  CampaignSchedule,
} from '@marketing-milk/interfaces'
import { NestAPI } from '@marketing-milk/frontend'
import { AxiosResponse } from 'axios'
import { AdObjectType } from './Campaign.types'

class CampaignService {
  async getCampaignsByBusiness(busID: string | number): Promise<Campaign[]> {
    const response: AxiosResponse<Campaign[]> = await NestAPI.get(`businesses/${busID}/campaigns`)
    return response.data
  }

  // TODO: add correct response type
  async getGlobalCampaignStats(busID: string | number): Promise<any> {
    const response = await NestAPI.get(`${busID}/campaigns`)
    return response.data
  }

  async patchCampaign(
    busID: number,
    campaignID: string | number,
    dto: PublishCampaignDTO
  ): Promise<boolean> {
    const response = await NestAPI.patch<boolean>(
      `businesses/${busID}/publish-campaign/${campaignID}`,
      dto
    )
    return response.data
  }

  async publishCampaign(busID: number, dto: PublishCampaignDTO): Promise<boolean> {
    const response = await NestAPI.post<boolean>(`businesses/${busID}/publish-campaign`, dto)
    return response.data
  }

  async getCampaignSchedule(
    busID: number,
    dto: GenerateCampaignScheduleDTO
  ): Promise<CampaignSchedule> {
    const response = await NestAPI.post<CampaignSchedule>(
      `businesses/${busID}/publish-campaign/schedule`,
      dto
    )
    return response.data
  }

  async duplicateCampaign(busID: number, campaignID: number, dto: PublishCampaignDTO) {
    const response = await NestAPI.post(`businesses/${busID}/duplicate-campaign/${campaignID}`, dto)
    return response.data
  }

  async hideCampaign(busID: string | number, campaign: Campaign): Promise<boolean> {
    const response = await NestAPI.post(`${busID}/campaigns/hide`, campaign)
    return response.data
  }

  async getCampaignOptimizationsByBusiness(busID: string | number): Promise<CampaignGoals> {
    const response = await NestAPI.get(`businesses/${busID}/campaigns/goals`)
    return response.data
  }

  async getBulkUploads(): Promise<BulkCampaignUpload[]> {
    const response = await NestAPI.get('business/campaign-upload/bulk')
    return response.data
  }

  async getCustomConversions(busID: number): Promise<CustomConversion[]> {
    const response = await NestAPI.get(`businesses/${busID}/custom-conversions`)
    return response.data
  }

  async pauseOrResumeAdObjectForBusiness(
    busID: number,
    adObjectID: number,
    adObjectType: AdObjectType,
    action: CampaignPauseActionType
  ) {
    const apiCallByAdObject: Record<AdObjectType, string> = {
      Campaign: `businesses/${busID}/pause-campaign/${adObjectID}`,
      Adset: `businesses/${busID}/pause-campaign/adset/${adObjectID}`,
      Ad: `businesses/${busID}/pause-campaign/ad/${adObjectID}`,
    }
    const dto: PauseCampaignDTO = {
      action,
    }
    await NestAPI.patch(apiCallByAdObject[adObjectType], dto)
  }

  /**
   * @desc Performs lives call to facebook to refresh all campaigns/adsets/ads
   */
  async refreshCampaignsAndTheirChildren(busID: number) {
    await NestAPI.get(`businesses/${busID}/ad-builder/sync/campaigns`)
    await NestAPI.get(`businesses/${busID}/ad-builder/sync/adsets`)
    await NestAPI.get(`businesses/${busID}/ad-builder/sync/ads`)
  }

  async getCampaignPerformance(
    busID: number,
    campaignID: number,
    start?: string,
    end?: string
  ): Promise<Campaign> {
    const response = await NestAPI.get(`businesses/${busID}/campaigns/${campaignID}/performance`, {
      params: {
        start,
        end,
      },
    })
    return response.data
  }

  async getAdsetsForCampaign(busID: number, campaignID: number): Promise<CampaignAdset[]> {
    const response = await NestAPI.get(`businesses/${busID}/campaigns/${campaignID}/adsets`)
    return response.data
  }

  async getAdsForAdset(busID: number, campaignID: number, adsetID: number): Promise<AdGroup[]> {
    const response = await NestAPI.get(
      `businesses/${busID}/campaigns/${campaignID}/adsets/${adsetID}/ads`
    )
    return response.data
  }
}

export const campaignService = new CampaignService()
