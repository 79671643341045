import {
  FacebookPixel,
  FacebookTargetingSpec,
  InclusionExclusion,
  InclusionExclusionURLMenuOption,
  StandardPixelEvent,
} from '@marketing-milk/interfaces'

export declare type PixelEventTypes =
  | StandardPixelEvent
  | 'ALL_WEBSITE_VISITORS'
  | 'VISITORS_BY_URL'

export interface PixelProps {
  pixelData: FacebookPixel | undefined
  inclusionSettings: InclusionExclusion
  exclusionSettings?: InclusionExclusion
  addExclusions: boolean
  onAddExclusions: (addExclusion: boolean) => void
  updateExclusion: (changes: Partial<InclusionExclusion>) => void
  updateInclusion: (changes: Partial<InclusionExclusion>) => void
  targetSpec: FacebookTargetingSpec
}

export const fbPixelCategories: Record<PixelEventTypes, string> = {
  VISITORS_BY_URL: 'Website Vistors by URL',
  ALL_WEBSITE_VISITORS: 'All Website Visitors',
  AddPaymentInfo: 'Add Payment Information',
  AddToCart: 'Add To Cart',
  AddToWishlist: 'Add To Wishlist',
  CompleteRegistration: 'Complete Registration',
  Contact: 'Contact',
  CustomizeProduct: 'Customize Product',
  Donate: 'Donate',
  FindLocation: 'Find Location',
  InitiateCheckout: 'Initiate Checkout',
  Lead: 'Lead',
  PageView: 'PageView',
  Purchase: 'Purchase',
  Schedule: 'Schedule',
  Search: 'Search',
  StartTrial: 'Start Trial',
  SubmitApplication: 'Submit Application',
  Subscribe: 'Subscribe',
  ViewContent: 'View Content',
}

export const urlCategories: Record<InclusionExclusionURLMenuOption, string> = {
  i_contains: 'Contains',
  i_not_contains: "Doesn't Contain",
  eq: 'Equals',
}
