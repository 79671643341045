import React, { useState } from 'react'
import { ServerErrorProps } from './ServerError.types'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useLogSentryError } from '../../../hooks/sentry/log-sentry-error'

export function ServerError({ error, message, code, onClose }: ServerErrorProps) {
  useLogSentryError(error)
  const [showModal, setModal] = useState(true)

  function onToggle() {
    setModal(_ => !_)
    if (showModal && onClose) {
      onClose()
    }
  }

  // verifies a message is received from BE and that it is not a 500 error
  const isCustomError = (): boolean => !!message && !!code && code / 100 < 5

  if (!showModal) {
    return null
  }

  return (
    <>
      <Modal isOpen toggle={onToggle}>
        <ModalHeader>Error</ModalHeader>
        <ModalBody>
          <div className="text-center">
            <div>
              <i className="fa fa-exclamation-triangle fa-4x" />
            </div>
            <h5>There was a problem and your request could not be completed at this time.</h5>
            {isCustomError() && <p>{message}</p>}
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
