import { useEffect, useState } from 'react'
import { useLogSentryError } from './sentry/log-sentry-error'

export type FetchResponse<T> = { data: T | undefined; error: Error | undefined; loading: boolean }

export function useCreateFetchHookAdvanced<T>(
  promise: () => Promise<T>,
  refresh = 0
): FetchResponse<T> {
  const [data, setData] = useState<T>()
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState<boolean>(false)
  useLogSentryError(error)

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      setError(undefined)
      setData(undefined)
      promise()
        .then(data => {
          setData(data)
        })
        .catch(err => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    fetchData()
  }, [refresh])

  return { data, error, loading }
}
