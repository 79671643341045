import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { NavLinkI } from './getNavLinks'

type SideNavLinkProps = {
  route: NavLinkI
  index?: number
}
export const NavLink = ({ route, index }: SideNavLinkProps) => {
  const location = useLocation()

  return (
    <li
      key={index}
      className={'flex flex-col justify-center items-center w-full hover:bg-gray-700 p-1'}
    >
      <Link
        to={`/${route.url}`}
        className={`menu-link space-y-2 p-3 flex justify-items-stretch justify-center rounded-md items-center flex-col ${
          location.pathname.includes(route.url) ? 'border-1 border-white rounded-md bg-gray-50' : ''
        }`}
      >
        <i
          style={{
            color: !location.pathname.includes(route.url) ? '#a2a3b7' : '#a044ff',
          }}
          className={`fad w-8 text-center fa-${route.icon}`}
        />
        <span
          style={{
            color: !location.pathname.includes(route.url) ? '#a2a3b7' : '#a044ff',
          }}
          className="flex-grow text-lg sm:text-sm"
        >
          {route.label}
        </span>
      </Link>
    </li>
  )
}
