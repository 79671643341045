import { Checkbox, IconButton, TableCell, TableRow } from '@material-ui/core'
import React, { useState } from 'react'

import { ExpandableRow } from './ExpandableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

type TableRowProps = {
  expansionPanel?: React.ReactNode
  shouldExpand: boolean
  rowData: React.ReactNode[]
  isSelected: boolean
  checked: boolean
  onRowSelect?: () => void
}

export function DataTableRow<T>({
  expansionPanel,
  shouldExpand,
  rowData,
  isSelected,
  onRowSelect,
  checked,
}: TableRowProps) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow hover={!!shouldExpand} selected={isSelected || open}>
        {expansionPanel && (
          <TableCell>
            {shouldExpand && (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
        )}

        {onRowSelect && (
          <TableCell>
            <Checkbox role="checkbox" onClick={onRowSelect} checked={checked} />
          </TableCell>
        )}

        {rowData.map(nodeToDisplay => (
          <TableCell scope="row">{nodeToDisplay}</TableCell>
        ))}
      </TableRow>
      {shouldExpand && <ExpandableRow open={open} expansionPanel={expansionPanel} />}
    </>
  )
}
