import { CampaignGoalType } from '@marketing-milk/interfaces'
import { capitalCase } from 'change-case'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useCampaignGoals } from 'hooks/campaigns/use-campaign-goals-by-business'
import { useGetBusinessID } from '@marketing-milk/frontend'

type Props = {
  campaignIndex: number
  readyToPublish?: boolean
  goal: CampaignGoalType
  setGoal: (campaignGoals: CampaignGoalType) => void
}

export const SelectCampaignGoalField = (props: Props) => {
  const businessID = useGetBusinessID()
  const { enabledGoals } = useCampaignGoals(Number(businessID))

  return (
    <FormControl>
      <InputLabel htmlFor={`campaign-${props.campaignIndex}-goal`}>Goal</InputLabel>
      <Select
        placeholder="Goal"
        labelId={`campaign-${props.campaignIndex}-goal`}
        variant="outlined"
        name={`campaign-${props.campaignIndex}-goal`}
        value={props.goal}
        inputProps={{
          label: 'Goal',
          id: `campaign-${props.campaignIndex}-goal`,
        }}
        // value={props.goal || 'in_store_sales'}
        // @ts-ignore
        onChange={({ target }) => props.setGoal(target.value)}
        label={'Goal'}
        required={true}
      >
        {enabledGoals.map(goal => (
          <MenuItem value={goal} style={{ width: '100%' }}>
            {capitalCase(goal)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
