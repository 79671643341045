import { GuestLayout } from '@marketing-milk/frontend'
import React from 'react'
import MMLogo from 'public/img/logos/mm-droplet-text.png'
import { useAuth0 } from '@auth0/auth0-react'

export function InvalidUser() {
  const { logout } = useAuth0()
  return (
    <GuestLayout>
      <div className="d-flex flex-column flex-root h-100">
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
          <div
            className="login-aside flex-column flex-row-auto"
            style={{ background: '#2A2F4C', color: 'white', width: '30%' }}
          >
            <div className="d-flex flex-column-auto flex-column p-15">
              <a href="#" className="text-center mb-10">
                <img alt="mm logo" src={MMLogo} />
              </a>
              <h3 className="font-weight-bolder text-center font-size-h4 font-size-h1-lg">
                Measure KPI's That Matter
              </h3>
            </div>
          </div>
          <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
            <div className="d-flex flex-column-fluid flex-center">
              <div className="login-form login-signin">
                <form className="form fv-plugins-bootstrap fv-plugins-framework" noValidate>
                  <div className="pb-13 pt-lg-0 pt-5">
                    <p className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg mb-2">
                      Oh no...
                    </p>
                    <div>
                      <p>Looks like we couldn't find a user with that email.</p>
                      <p style={{ color: 'darkred' }}>
                        Note: If you are logged in with your Facebook account you must log out on
                        <a href="https://facebook.com" target="_blank">
                          {' '}
                          facebook.com
                        </a>
                      </p>
                    </div>
                    <button
                      onClick={e => {
                        e.preventDefault()
                        logout({ returnTo: `${window.location.origin}/builder/login` })
                      }}
                      className="btn btn-primary btn-block mt-4"
                    >
                      Try Another Account
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
              <div className="text-dark-50 font-size-lg font-weight-bolder mr-10">
                <span className="mr-1">&copy; {new Date().getFullYear()}</span>
                <a href="/" target="_blank" className="text-dark-75 text-hover-primary">
                  Marketing Milk
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}
