import { useState } from 'react'
import { addOrRemove } from '../../../../../util/util'
import { PublisherPlatformsMode } from './publisher-platforms.types'
import { PublisherPlatform, publisherPlatforms } from '@marketing-milk/interfaces'

export type HandlePlatformModeChange = (mode: PublisherPlatformsMode) => void
export type HandlePlatformSelection = (platform: PublisherPlatform) => void

export function usePublisherPlatforms(platforms?: PublisherPlatform[]) {
  const [selectedPublisherPlatforms, setSelectedPublisherPlatforms] = useState(platforms)

  const handlePlatformModeChange = (mode: PublisherPlatformsMode) => {
    if (mode === 'automatic') {
      setSelectedPublisherPlatforms(undefined)
    } else {
      setSelectedPublisherPlatforms([...publisherPlatforms])
    }
  }

  const handlePlatformSelection = (platform: PublisherPlatform) => {
    // Return early if user is trying to unselect the last checkbox. At least one must be selected.
    if (selectedPublisherPlatforms?.length === 1 && selectedPublisherPlatforms[0] === platform)
      return
    setSelectedPublisherPlatforms(existing =>
      existing ? addOrRemove(existing, platform) : existing
    )
  }

  return { selectedPublisherPlatforms, handlePlatformModeChange, handlePlatformSelection }
}
