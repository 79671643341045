import { adCreativeService, sessionContext, useGetBusinessID } from '@marketing-milk/frontend'
import {
  AdCreative,
  AdCreativeCarouselDTO,
  AdCreativeImageCarouselSlideDTO,
  AdCreativeType,
  AdCreativeVideoCarouselSlideDTO,
  FBCallToAction,
} from '@marketing-milk/interfaces'
import { FormControlLabel, Switch } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { useCreatives } from 'pages/business/creatives/useCreatives'
import { useContext } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { creativeContext } from '../../context/CreativeContext'
import { SaveButton } from '../../fields/save-button/SaveButton'
import { SelectCallToAction } from '../../fields/select-call-to-action/SelectCallToAction'
import { SelectLeadCard } from '../../fields/select-lead-card/SelectLeadCard'
import { CarouselSlides } from './carousel-slides/CarouselSlides'

interface CarouselCreativeFormInput {
  name: string
  primaryText: string
  url: string
  displayLink: string
  endSlide: boolean
  callToAction: FBCallToAction
  slides: CarouselCreativeFormSlide[]
  carouselDynamicOrder: boolean
}

export interface CarouselCreativeFormSlide {
  headline?: string
  description?: string
  media?: { mediaType: 'image' | 'video'; mediaID: number }
}

interface CarouselCreativeProps {
  creative: AdCreative
}

export function CarouselCreative({ creative }: CarouselCreativeProps) {
  const businessID = useGetBusinessID()
  const { user } = useContext(sessionContext)
  const { setCreative } = useContext(creativeContext)
  const { refetch } = useCreatives(businessID)
  const methods = useForm<CarouselCreativeFormInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: creative.name,
      primaryText: creative.primaryText,
      url: creative.linkWebsiteURL,
      displayLink: creative.linkDisplayURL,
      endSlide: creative.carouselEndSlide,
      carouselDynamicOrder: creative.carouselDynamicOrder,
      callToAction: creative.callToAction ?? FBCallToAction.ADD_TO_CART,
      slides:
        creative.carouselSlides?.map(slide => ({
          headline: slide.linkHeadline,
          description: slide.linkDescription,
          media: {
            mediaType: slide.mediaType,
            mediaID:
              slide.mediaType === 'image'
                ? slide.image.id
                : slide.mediaType === 'video'
                ? slide.video.id
                : undefined,
          },
        })) ?? [],
    },
  })

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = methods
  const canEdit = user && !creative.locked

  const reloadCreative = async () => {
    const data = await adCreativeService.getByID(businessID, creative.id)

    // To be removed when carousel context is removed
    setCreative(data)
  }

  const carouselFieldsToAdCreativeCarouselDTO = (
    formFields: CarouselCreativeFormInput
  ): AdCreativeCarouselDTO => {
    return {
      format: AdCreativeType.carousel,
      name: formFields.name,
      primaryText: formFields.primaryText,
      url: formFields.url,
      displayLink: formFields.displayLink,
      callToAction: formFields.callToAction,
      endSlide: formFields.endSlide,
      dynamicOrder: formFields.carouselDynamicOrder,
      slides: formFields.slides.map(
        (slide, i): AdCreativeImageCarouselSlideDTO | AdCreativeVideoCarouselSlideDTO => {
          // This is some nonsense because we deliniate between imageID and videoID
          const media: any = {
            mediaType: slide.media?.mediaType,
          }
          slide.media?.mediaType === 'image'
            ? (media.imageID = slide.media.mediaID)
            : slide.media?.mediaType === 'video'
            ? (media.videoID = slide.media.mediaID)
            : undefined

          return {
            order: i,
            headline: slide.headline,
            description: slide.description,
            url: formFields.url,
            ...media,
          }
        }
      ),
    }
  }

  const handleSave = async () => {
    await adCreativeService.updateCarousel(
      businessID,
      creative.id,
      carouselFieldsToAdCreativeCarouselDTO(methods.getValues())
    )
    refetch()
    reloadCreative()
  }

  const saveDisabled = () => {
    const formValues = methods.getValues()

    // Currently, 0 or 2+ slides are valid because of the way FB requires
    // Carousel Creatives (on the BE, 0 slides generates a single image preview)
    const hasValidNumberOfSlides = formValues.slides?.length !== 1
    const allSlidesHaveMedia = formValues.slides?.every(slide => slide.media?.mediaID)
    const hasValidSlides = hasValidNumberOfSlides && allSlidesHaveMedia

    return !(canEdit && hasValidSlides)
  }

  const submitForApprovalDisabled = () => {
    const formValues = methods.getValues()

    // While we can save with 0 slides, we want slides to actually allow this creative to be published
    const hasValidNumberOfSlides = formValues.slides.length >= 2

    return !(isValid && hasValidNumberOfSlides)
  }

  // Manually triggering form validation after slide add/remove
  // due to some weird caching of media selection not getting picked
  // up by parent components
  const handleSlideChange = () => trigger()

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((data: CarouselCreativeFormInput) => console.log(data))}
        className="space-y-4"
      >
        <Controller
          name="name"
          control={control}
          rules={{ required: { value: true, message: 'Creative Name is required' } }}
          render={({ field }) => (
            <TextField
              id="creative-name"
              label="Creative Name"
              helperText={errors.name?.message}
              error={!!errors.name}
              disabled={!canEdit}
              className="w-full"
              required
              {...field}
            />
          )}
        />

        <Controller
          name="url"
          control={control}
          rules={{ required: { value: true, message: 'Website URL is required' } }}
          render={({ field }) => (
            <TextField
              id="website-url"
              label="Website URL"
              helperText={errors.url?.message}
              error={!!errors.url}
              disabled={!canEdit}
              className="w-full"
              required
              {...field}
            />
          )}
        />

        <Controller
          name="displayLink"
          control={control}
          rules={{ required: { value: true, message: 'Display URL is required' } }}
          render={({ field }) => (
            <TextField
              id="display-url"
              helperText={errors.displayLink?.message}
              error={!!errors?.displayLink}
              label="Display URL"
              disabled={!canEdit}
              className="w-full"
              required
              {...field}
            />
          )}
        />

        <Controller
          name="primaryText"
          control={control}
          rules={{ required: { value: true, message: 'Primary Text is required' } }}
          render={({ field }) => (
            <TextField
              id="primary-text"
              label="Primary Text"
              inputProps={{ 'aria-label': 'primary text' }}
              helperText={errors.primaryText?.message}
              error={!!errors.primaryText}
              multiline
              rows={4}
              disabled={!canEdit}
              className="w-full"
              required
              {...field}
            />
          )}
        />

        <p className="mt-4 text-xl font-semibold">Lead Card Settings</p>
        <SelectLeadCard readOnly={!canEdit} />
        <SelectCallToAction
          callToAction={methods.watch('callToAction')}
          onlyLeadOptions={!!creative.leadCardUsed}
          readOnly={!canEdit}
          {...methods.register('callToAction')}
          onChange={cta => setValue('callToAction', cta)}
        />

        <CarouselSlides
          slides={methods.getValues().slides}
          handleSlideChange={handleSlideChange}
          readOnly={!canEdit}
        />

        <Controller
          name="endSlide"
          control={control}
          defaultValue={true}
          render={({ field }) => (
            <FormControlLabel
              className="pl-2"
              control={
                <Switch
                  color="primary"
                  disabled={!canEdit}
                  size="small"
                  checked={field.value}
                  {...field}
                />
              }
              label="Add a card at the end with your Page profile picture"
            />
          )}
        />

        <Controller
          name="carouselDynamicOrder"
          control={control}
          defaultValue={true}
          render={({ field }) => (
            <FormControlLabel
              className="pl-2"
              control={
                <Switch
                  color="primary"
                  disabled={!canEdit}
                  size="small"
                  checked={field.value}
                  {...field}
                />
              }
              label="Allow Facebook to randomize slides to show best performing slide first"
            />
          )}
        />

        {!methods.getValues().slides.every(slide => slide.media?.mediaID) && (
          <p className="text-red">All Slides Require Media</p>
        )}
        {methods.getValues().slides.length === 1 && (
          <p className="text-red">2 Slides Are Required to Save and Submit For Approval</p>
        )}

        <SaveButton disabled={saveDisabled()} onSave={handleSave} />
      </form>
    </FormProvider>
  )
}
