import React from 'react'

interface BlankPageLayoutProps {
  title: React.ReactNode // Normally Just a string
  subtitle?: React.ReactNode // Normally Just a string
  className?: string
  children: React.ReactNode
  stretch?: boolean
}
export const BlankPageLayout = (props: BlankPageLayoutProps) => (
  <div className={`w-full flex flex-col mx-auto p-4 space-y-4 ${props.className}`}>
    <h1 className="font-lg font-bold mx-auto">{props.title}</h1>
    {props.subtitle && (
      <p className="font-medium mx-auto max-w-2xl font-md text-gray-600">{props.subtitle}</p>
    )}
    <div
      className={`mx-auto w-full flex flex-col items-center justify-center ${
        props.stretch ? '' : 'max-w-7xl'
      }`}
    >
      {props.children}
    </div>
  </div>
)
