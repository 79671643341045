import React, { useContext } from 'react'
import { sessionContext, useGetBusinessIDIfExists } from '@marketing-milk/frontend'
import { getNavLinks } from './getNavLinks'
import { useScreenSize } from 'hooks'
import { NavLink } from './NavLink'

export const DesktopNav = () => {
  const { user } = useContext(sessionContext)
  const { isMobile } = useScreenSize()
  const businessID = useGetBusinessIDIfExists()
  const links = getNavLinks(businessID, user)

  if (isMobile) return null
  return (
    <div
      style={{ background: '#1e1e2d' }}
      className="h-screen flex flex-col"
      data-testid="sidebar-navigation"
    >
      <a
        href="https://dashboard.marketingmilk.com"
        target="_blank"
        rel="noopener noreferrer"
        className="flex justify-center items-center w-full h-16 bg-purple-800"
      >
        <img
          src={
            'https://www.marketingmilk.com/wp-content/themes/marketing-milk/static/img/marketing-milk-logo-white.svg'
          }
          alt="marketing-milk marketing milk logo"
          style={{ maxHeight: '30px' }}
        />
      </a>

      <div className="space-y-1 mx-auto text-center">
        <NavLink
          route={{
            label: 'Businesses',
            icon: 'briefcase',
            url: 'dashboard',
          }}
        />
        {businessID && links.map((route, i) => <NavLink route={route} index={i} />)}
      </div>
    </div>
  )
}
