import React from 'react'
import { Button } from '@material-ui/core'
import { NewCreativeModal } from '../NewCreativeModal/NewCreativeModal'
import { AdCreative } from '@marketing-milk/interfaces'
import { FullPageHeader } from 'components/layout/FullPageLayout/Header/FullPageHeader'
import { useScreenSize } from 'hooks/useScreenSize'
import { BulkShareCreatives } from '../bulk-operations/BulkShareCreatives'

interface Props {
  sidebarOpen: boolean
  setSidebarOpen: SetState<boolean>
  creatives?: AdCreative[]
  paginationData: [number, number]
}

export const CreativePageHeader = (props: Props) => {
  const { isMobile } = useScreenSize()

  const [currentShowing, totalAmount] = props.paginationData

  return (
    <FullPageHeader
      breadcrumbs={[{ title: 'Creatives' }]}
      sidebarOpen={props.sidebarOpen}
      setSidebarOpen={props.setSidebarOpen}
    >
      <NewCreativeModal>
        <Button variant="contained" color="primary">
          {isMobile ? '+' : 'Add Creative'}
        </Button>
      </NewCreativeModal>

      <BulkShareCreatives />

      <p className="text-gray-800 text-md flex-1 text-right mr-24 font-medium">
        {isMobile
          ? `${currentShowing} / ${totalAmount}`
          : `Showing ${currentShowing} of ${totalAmount} creatives`}
      </p>
    </FullPageHeader>
  )
}
