import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

export interface CompletionHeadlineProps {
  editable: boolean
  headline?: string
  onChangeHeadline(headline: string): void
}

export const completionHeadlineMaxLength = 60

export function CompletionHeadline({
  editable,
  headline,
  onChangeHeadline,
}: CompletionHeadlineProps) {
  return (
    <div className="form-group">
      <TextField
        error={!headline && editable}
        helperText={!headline && editable && 'Thank you page headline is a required field'}
        label="Headline *"
        fullWidth
        disabled={!editable}
        value={headline}
        onChange={event => onChangeHeadline(event.target.value as string)}
        inputProps={{
          maxLength: completionHeadlineMaxLength,
          'data-testid': 'completion-headline-input',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="subtitle1">
                {headline?.length ?? 0}/{completionHeadlineMaxLength}
              </Typography>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
      />
    </div>
  )
}
