import { FormatService, ValueChange } from '@marketing-milk/frontend'
import { Campaign } from '@marketing-milk/interfaces'
import { differenceInDays, format, fromUnixTime } from 'date-fns'
import { TableSchema } from '../../../components/DataTable/types'
import { calculatePercentDifference } from '../campaign-builder/util'
import { StatusIndicator } from './components/status-indicator/StatusIndicator'
import { campaignHasError, getAllErrors } from './helpers/campaign.helpers'
import { campaignHasEnded, campaignHasStarted } from './helpers/campaignForm.helpers'

// prettier-ignore
export const columnNames = ['Details', 'Tools', 'Off / On', 'Campaign Name', 'Duration', 'Start Date', 'End Date', 'Delivery Status', 'Spend', 'Budget', 'KPI', 'Cost Per', 'Cost Per Industry', 'Cost Per Rank', 'KPI Rank', 'RoAS', 'Revenue', 'Type', 'details', 'Industry Avg. Cost', 'KPI Rank', 'Tools', 'Created From'] as const;
export type ColumnNames = typeof columnNames[number]
export const campaignsTableSchema: TableSchema<Campaign, ColumnNames> = {
  'Campaign Name': {
    tooltipText: 'The name of your campaign',
    render: record => record.name,
  },
  Duration: {
    tooltipText: 'How long your campaign will run',
    render: ({ startTime, endTime }) =>
      differenceInDays(fromUnixTime(startTime), fromUnixTime(endTime)) + ' days',
    hidden: true,
  },
  'Start Date': {
    tooltipText: 'The date your campaign starts running',
    render: ({ startTime }) => format(fromUnixTime(startTime), 'P'),
  },
  'End Date': {
    tooltipText: 'The date your campaign stops running',
    render: ({ endTime }) => format(fromUnixTime(endTime), 'P'),
  },
  'Delivery Status': {
    tooltipText: 'The status of how your campaign is delivering to users on Facebook',
    render: campaign => (
      <StatusIndicator
        drawerTitle="Your Campaign Has Errors"
        drawerDescription="you must fix these errors and resubmit the campaign"
        status={campaign.status}
        hasEnded={campaignHasEnded(campaign.endTime)}
        isFuture={!campaignHasStarted(campaign.startTime)}
        errors={campaignHasError(campaign) ? getAllErrors(campaign) : undefined}
      />
    ),
  },
  Revenue: {
    tooltipText: 'Revenue',
    render: campaign =>
      campaign.revenue ? FormatService.getValue(campaign.revenue, { type: 'currency' }) : 'N/A',
  },
  Spend: {
    render: campaign => FormatService.getValue(campaign.spend, { type: 'currency' }),
  },
  Budget: {
    render: campaign => FormatService.getValue(campaign.budget, { type: 'currency' }),
  },
  'Cost Per': {
    tooltipText:
      'Cost Per defined as Spend / KPI. This is compared against the Industry average, and will tell you how much better/worse your Cost Per is compared to the average of all other campaigns of the same Optimization and Industry.',
    render: ({ costPerCurrent, costPerIndustryAvg }) => (
      <span>
        {FormatService.getValue(costPerCurrent, { type: 'currency' })}
        {!!costPerIndustryAvg && (
          <ValueChange
            value={calculatePercentDifference(costPerCurrent ?? 0, costPerIndustryAvg ?? 0)}
            valueType="percent"
            className="ml-2"
            inverseColor={true}
          />
        )}
      </span>
    ),
  },
  'Industry Avg. Cost': {
    tooltipText: 'The average of all other campaigns of the same Optimization and Industry.',
    render: ({ costPerIndustryAvg }) =>
      FormatService.getValue(costPerIndustryAvg, { type: 'currency' }),
    hidden: true,
  },
  RoAS: {
    render: ({ roas }) => (roas ? `${FormatService.getValue(roas)}x` : 'N/A'),
  },
  KPI: {
    render: ({ kpi }) => FormatService.getValue(kpi),
  },
  Type: {
    tooltipText: 'type',
    render: campaign => (campaign.isCascading ? 'Cascading' : 'Regular'),
    hidden: true,
  },
}
