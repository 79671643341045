import {
  AdCreative,
  AdCreativeType,
  AdImage,
  AdVideo,
  Campaign,
  CampaignGoals,
  CampaignGoalType,
  FacebookAdError,
  FBCallToAction,
  SavedAudience,
} from '@marketing-milk/interfaces'
import { adCreativeService } from 'app/services'
import { onlyPopulatedValues } from 'app/util'
import {
  allPass,
  any,
  anyPass,
  flatten,
  flattenDeep,
  isEmpty,
  map,
  pipe,
  pluck,
  prop,
  update,
} from 'lodash/fp'

export const getAllErrors = (campaign: Campaign): FacebookAdError[] => {
  const campaignErrors = campaign.errors || []
  // const campaignErrors = campaign.errors || [];

  // If no adset, just return campaignErrors
  if (!campaign.adSets) return campaignErrors

  const allErrors = [...campaignErrors, ...getAdsetErrors(campaign), ...getAdErrors(campaign)]

  return onlyPopulatedValues(allErrors)
}

export const getAdsetErrors = (campaign?: Campaign): FacebookAdError[] => {
  if (!campaign) return []
  return pipe(
    pluck('errors'),
    flatten,
    onlyPopulatedValues,
    denoteErrorType('ADSET(audience)')
  )(campaign.adSets)
}

export const getAdErrors = (campaign?: Campaign): FacebookAdError[] => {
  if (!campaign) return []
  return pipe(
    pluck('adGroups'),
    flattenDeep,
    pluck('errors'),
    flattenDeep,
    onlyPopulatedValues,
    denoteErrorType('AD')
  )(campaign.adSets)
}

export const denoteErrorType = (type: 'ADSET(audience)' | 'AD') =>
  map(update('title', title => `${type} ERROR: ${title || ''}`))

export const campaignHasError = (campaign: Campaign) => !isEmpty(getAllErrors(campaign))

/**
 *
 * @description checks creative configuration for known issues based on campaign goal
 * @param creative to be evaluated
 * @param campaignGoal to be evaluated against
 *
 */
export const hasImproperCTA = (creative: AdCreative, campaignGoal: CampaignGoalType) => {
  return (
    creative.format === AdCreativeType.dynamic &&
    creative.dynamicConfig?.callToActions?.includes(FBCallToAction.SEE_MORE) &&
    campaignGoal === CampaignGoalType.WebsiteSessions
  )
}

export const canUploadCampaigns = (
  audiences: SavedAudience[],
  campaignGoals?: CampaignGoals
): boolean => {
  if (!campaignGoals) return false
  return hasEnabledOptimization(campaignGoals) && !isEmpty(audiences)
}

export const canUseOptimization = (optimization: CampaignGoalType, goals: CampaignGoals) =>
  goals.optimizations.filter(opt => opt.enabled).some(opt => opt.type === optimization)

export const hasEnabledOptimization = (goals: CampaignGoals) =>
  !isEmpty(goals.optimizations.filter(opt => opt.enabled))

export const creativeHasProp =
  (property: keyof AdCreative) => (creative: AdCreative, improperValue?: any) => {
    const creativeProperty = prop(property, creative)
    if (!creativeProperty) return false
    if (isEmpty(creativeProperty)) return false
    if (creativeProperty === improperValue) return false
    return true
  }

export const creativeHasName = creativeHasProp('name')
export const creativeHasGraphicText = creativeHasProp('graphicText')
export const creativeHasStatus = creativeHasProp('status')
export const creativeHasHeadline = creativeHasProp('linkHeadline')
export const creativeHasDescription = creativeHasProp('linkDescription')
export const creativeHasWebsiteURL = creativeHasProp('linkWebsiteURL')
export const creativeHasDisplayURL = creativeHasProp('linkDisplayURL')
export const creativeHasImageUrl = creativeHasProp('imageURL')
export const creativeHasCTA = creativeHasProp('callToAction')
export const creativeHasPrimaryText = creativeHasProp('primaryText')
export const creativeHasDynamicConfig = creativeHasProp('dynamicConfig')
export const creativeHasCarouselProps = anyPass([
  creativeHasProp('carouselDynamicOrder'),
  creativeHasProp('carouselEndSlide'),
  creativeHasProp('carouselSlides'),
])

// prettier-ignore
type AdCreativeData = Omit<
  AdCreative,
  | keyof DynamicCreativeProperties
  // thumbnailURL is only used for videos???
  | keyof SingleMediaAdProperties
  | keyof CarouselAdProperties
 >;

type AdDescriptionProperties = Required<Pick<AdCreative, 'callToAction' | 'primaryText'>>

// DYNAMIC
type DynamicCreativeProperties = Required<
  Pick<AdCreative, 'dynamicConfig' | 'dynamicImages' | 'dynamicVideos'>
>
export type DynamicCreative = AdCreativeData & DynamicCreativeProperties

// CAROUSEL
type CarouselAdProperties = Required<
  Pick<AdCreative, 'carouselDynamicOrder' | 'carouselEndSlide' | 'carouselSlides'>
>
export type CarouselCreative = AdCreativeData & AdDescriptionProperties & CarouselAdProperties

// SINGLE
type SingleMediaAdProperties = Required<
  Pick<AdCreative, 'graphicText' | 'linkHeadline' | 'linkDescription'>
>
export type SingleCreative = AdCreativeData & AdDescriptionProperties & SingleMediaAdProperties

// type SingleImageCreative = Pick<AdCreative, ''>
export const creativeHasSingleImage = (creative: AdCreative) => creativeHasProp('singleImage')

export const isDynamicCreative = (creative: AdCreative): creative is DynamicCreative =>
  !!creative?.dynamicConfig && !!creative?.dynamicImages && !!creative?.dynamicVideos
export const isSingleCreative = (creative: AdCreative): creative is SingleCreative =>
  !!creative?.singleImage ||
  !!creative?.singleVideo ||
  !!creative?.graphicText ||
  !!creative?.linkHeadline ||
  !!creative?.linkDescription
export const isCarouselCreative = (creative: AdCreative): creative is CarouselCreative =>
  !!creative?.carouselEndSlide || !!creative?.carouselSlides
export const isAdImage = (item: any): item is AdImage =>
  !!item && !!item?.name && !!item?.url && !!item?.status && !!item?.hash
export const isAdVideo = (item: any): item is AdVideo =>
  !!item && !!item?.embeddedHtml && !!item?.permalinkUrl && !!item?.status

// All creatives need to pass these functions in order to be valid
const allCreativeValidators = [creativeHasCTA, creativeHasPrimaryText]

// The validator arrays below are for each specific creative type(single, carousel, and dynamic)
const dynamicCreativeValidators = [...allCreativeValidators, creativeHasDynamicConfig]
const singleCreativeValidators = [
  ...allCreativeValidators,
  creativeHasDescription,
  creativeHasHeadline,
]
const carouselCreativeValidators = [...allCreativeValidators, creativeHasCarouselProps]

export const creativeHasImageOrVideo = (creative: AdCreative, improperValue?: any) =>
  !!(creative?.singleImage?.id || creative?.singleVideo?.id)

const carouselHasTwoSlides = (creative: AdCreative, improperValue: any) =>
  !!(creative.carouselSlides && creative.carouselSlides.length >= 2)

export const isValidDynamicCreative = ({ dynamicConfig }: AdCreative) =>
  !!(
    dynamicConfig?.callToActions &&
    dynamicConfig.descriptions &&
    dynamicConfig.headlines &&
    (dynamicConfig.images || dynamicConfig.videos) &&
    dynamicConfig.primaryTexts
  )

export const commonCreativeValidators = [
  creativeHasName,
  creativeHasWebsiteURL,
  creativeHasDisplayURL,
]

export const singleCreativeHasRequiredProperties = allPass([
  ...commonCreativeValidators,
  creativeHasStatus,
  creativeHasHeadline,
  creativeHasDescription,
  creativeHasImageOrVideo,
])

export const carouselCreativeHasRequiredProperties = allPass([
  ...commonCreativeValidators,
  creativeHasPrimaryText,
  carouselHasTwoSlides,
])

export const dynamicCreativeHasRequiredProperties = allPass([
  ...commonCreativeValidators,
  isValidDynamicCreative,
])

export const getCampaignErrorMessage = (
  audiences: SavedAudience[],
  campaignGoals?: CampaignGoals
) => {
  if (isEmpty(audiences)) return 'you must have an audience created before making a campaign'
  if (!campaignGoals) return 'you must configure a campaign optimization'
  if (!hasEnabledOptimization(campaignGoals))
    return 'you must have a campaign optimization configured'
  return null
}

export const isValidCreative = (creative?: AdCreative) => {
  if (!creative) return false
  return (
    (isDynamicCreative(creative) && dynamicCreativeHasRequiredProperties(creative)) ||
    (isSingleCreative(creative) && singleCreativeHasRequiredProperties(creative)) ||
    (isCarouselCreative(creative) && carouselCreativeHasRequiredProperties(creative))
  )
}

export const creativeHasValidLeadCard = (creative?: AdCreative) => !!creative?.leadCardUsed

export const getCreativeErrorText = (creative: AdCreative) => {
  const errors: string[] = []
  if (!creativeHasName(creative)) return errors.push('Missing Name')
  if (!creativeHasWebsiteURL) return errors.push('Missing Website Url')
  if (!creativeHasDisplayURL) return errors.push('Missing Display Url')
  if (isDynamicCreative(creative)) {
    // if ()
  }
}

export const hasValidSingleCreatives = any(allPass(singleCreativeValidators))
export const hasValidDynamicCreatives = any(allPass(dynamicCreativeValidators))
export const hasValidCarouselCreatives = any(allPass(carouselCreativeValidators))

export const hasAnyValidCreative = (creatives: AdCreative[]) => {
  if (hasValidSingleCreatives(creatives.filter(isSingleCreative))) return true
  if (hasValidDynamicCreatives(creatives.filter(isDynamicCreative))) return true
  if (hasValidCarouselCreatives(creatives.filter(isCarouselCreative))) return true
  return false
}

export const getCreativeErrorMessages = (creatives: AdCreative[]) => {
  if (!hasAnyValidCreative(creatives)) 'Your creatives are missing required properties'
  return 'There is something wrong with the creatives you have made'
}

// to be removed when creative builder defaults removed
export const creativeHasNonDefaultProperties = (creative: AdCreative) =>
  creativeHasName(creative) &&
  creativeHasStatus(creative, 'Status') &&
  creativeHasHeadline(creative, 'Link Headline') &&
  creativeHasDescription(creative, 'Link Description') &&
  creativeHasWebsiteURL(creative) &&
  creativeHasDisplayURL(creative)

export const hasAdCreativesReadyForUse = (adCreatives: AdCreative[]) =>
  hasAnyValidCreative(adCreatives.filter(adCreativeService.isReadyForCampaignUse))
