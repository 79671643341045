import React, { useContext } from 'react'
import { leadCardPreviewContext } from './context'

export function BackgroundPreview() {
  const { leadCard } = useContext(leadCardPreviewContext)

  // @ts-ignore - john
  if (!leadCard.intro.imageURL && !leadCard.pagePhoto) {
    return (
      <>
        <div
          data-testid="no-background-image"
          className="no-image mb-5"
          style={{
            maxWidth: '280px',
            minWidth: '252px',
            textAlign: 'center',
            margin: '0 auto',
            height: '168px',
            position: 'relative',
          }}
        >
          <div style={{ position: 'absolute', bottom: 0 }}>
            <i className="fas fa-images fa-3x" />
            <div
              className="no-image-notice"
              style={{
                background: '#000',
                borderRadius: '2px',
                color: '#ccd0d5',
                fontSize: '11px',
                opacity: 0.75,
                padding: '4px 12px',
              }}
            >
              The image creative used in your ad will show up here.
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div
      data-testid="background-image"
      className="image w-100"
      style={{
        backgroundImage: `url(${
          // @ts-ignore - john
          leadCard.pagePhoto ? leadCard.pagePhoto.source : leadCard.intro.imageURL
        })`,
        backgroundSize: 'cover',
        marginBottom: '-64px',
        height: '232px',
      }}
    />
  )
}
