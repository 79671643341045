import { capitalCase } from 'change-case'
import { IconButton } from '@material-ui/core'
import { ImageCarousel } from './ImageCarousel'
import React, { ImgHTMLAttributes } from 'react'
import { Chip, Drawer } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import PhoneIcon from '@mui/icons-material/Phone'
import PublicIcon from '@mui/icons-material/Public'
import { StarRating } from '@marketing-milk/frontend'
import { useLocalCompetitionFinder } from './use-local-competition'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { AddressType, PlaceReview } from '@googlemaps/google-maps-services-js'
import { Avatar, Typography, Card, Link, Paper, CardHeader, CardContent } from '@mui/material'

export function CompetitionDetails(props: ReturnType<typeof useLocalCompetitionFinder>) {
  const classes = useStyles()
  const images: ImgHTMLAttributes<HTMLImageElement>[] = props.selectedCompetitorDetails
    ? props
        .getPlacePhotos(props.selectedCompetitorDetails?.photos)
        .map(photo => ({ src: photo.src, height: photo.height }))
    : []
  if (!props.selectedCompetitorDetails) return <></>
  return (
    <Drawer
      BackdropProps={{ invisible: true }}
      PaperProps={{
        style: {
          maxWidth: 700,
          minWidth: 500,
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
        },
      }}
      anchor="right"
      open={props.detailsDrawerOpen}
      onClose={() => props.setSelectedCompetitorID(undefined)}
    >
      <div className="">
        <Card className={classes.mediaCard}>
          <div className="h-500 object-cover">
            <ImageCarousel autoplay images={images} />
          </div>
          <IconButton
            className={classes.closeButton}
            onClick={() => props.setSelectedCompetitorID(undefined)}
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.cardContent + ' p-3'}>
            <div className="grid grid-cols-3">
              <div className="col-span-2">
                <Typography variant="h4">
                  {props.selectedCompetitorDetails.name}
                  {props.selectedCompetitorDetails.business_status && (
                    <>
                      {' '}
                      •{' '}
                      {props.isBusinessOperational(props.selectedCompetitorDetails)
                        ? 'Open'
                        : 'Permanently Closed ☠️'}
                    </>
                  )}
                </Typography>
                {props.selectedCompetitorDetails.rating && (
                  <div className="d-flex mb-2">
                    <StarRating
                      key="place-rating"
                      size="medium"
                      value={props.selectedCompetitorDetails.rating}
                      count={5}
                      alignContent="left"
                      readOnly
                    />
                    <Typography className="ml-2" variant="body1">
                      {props.selectedCompetitorDetails.rating}
                    </Typography>
                    {props.selectedCompetitorDetails.user_ratings_total && (
                      <Typography className="ml-2" variant="body1">
                        ({props.selectedCompetitorDetails.user_ratings_total})
                      </Typography>
                    )}
                  </div>
                )}
                {props.selectedCompetitorDetails.vicinity && (
                  <Typography variant="body1">
                    {props.selectedCompetitorDetails?.formatted_address}
                  </Typography>
                )}
              </div>
              <div>
                <div className="absolute bottom-0 right-0 h-16 w-16" style={{ width: 90 }}>
                  {props.selectedCompetitorDetails?.website && (
                    <Link href={props.selectedCompetitorDetails?.website}>
                      <PublicIcon fontSize="large" />
                    </Link>
                  )}
                  {props.selectedCompetitorDetails?.formatted_phone_number && (
                    <Link
                      className="ml-3"
                      href={'tel:' + props.selectedCompetitorDetails?.formatted_phone_number}
                    >
                      <PhoneIcon fontSize="large" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div className="p-3">
          <div className="grid grid-cols-2 gap-2">
            {props.selectedCompetitorDetails?.opening_hours?.weekday_text && (
              <div>
                <Typography variant="h4">Hours</Typography>
                <ul>
                  {props.selectedCompetitorDetails?.opening_hours?.weekday_text?.map(
                    (text: string, index: number) => (
                      <li key={index}>{text}</li>
                    )
                  )}
                </ul>
              </div>
            )}
            {props.selectedCompetitorDetails.types && (
              <div>
                <Typography className="pb-3" variant="h4">
                  Categories
                </Typography>
                {props.selectedCompetitorDetails.types?.map((type: AddressType, index: number) => (
                  <Chip
                    key={index}
                    className="mr-2"
                    label={capitalCase(type)}
                    color="primary"
                    variant="outlined"
                  />
                ))}
              </div>
            )}
          </div>
          {props.selectedCompetitorDetails?.reviews && (
            <div>
              <Typography className="pb-1" variant="h4">
                Reviews
              </Typography>
              <div className="d-flex overflow-auto ml-n2">
                {props.selectedCompetitorDetails?.reviews?.map(
                  (review: PlaceReview, index: number) => (
                    <Paper elevation={3} className="m-2" key={index} style={{ minWidth: 300 }}>
                      <CardHeader
                        avatar={<Avatar src={review.profile_photo_url ?? ''}>R</Avatar>}
                        title={review.author_name}
                        subheader={review.relative_time_description}
                      />
                      <CardContent>
                        <StarRating
                          key="place-rating"
                          size="medium"
                          value={review.rating}
                          count={5}
                          alignContent="left"
                          readOnly
                        />
                        <Typography variant="body2" color="text.secondary">
                          {review.text}
                        </Typography>
                      </CardContent>
                    </Paper>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mediaCard: {
      borderRadius: '0px',
      boxShadow: 'none',
      position: 'relative',
      minWidth: 200,
      minHeight: 360,
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '64%',
        bottom: 0,
        zIndex: 1,
        background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
      },
      '& .MuiPaper-root': {
        borderRadius: '0px !important',
      },
      '& .MuiRating-iconEmpty': {
        color: '#858585 !important',
      },
    },
    cardContent: {
      position: 'absolute',
      zIndex: 2,
      bottom: 0,
      width: '100%',
      color: 'white',
    },
    closeButton: {
      position: 'absolute',
      zIndex: 2,
      top: 5,
      right: 5,
      backgroundColor: 'rgba(255,255,255,0.3) !important',
    },
  })
)
