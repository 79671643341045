import React, { useState, Children, useEffect } from 'react'
import { path } from 'lodash/fp'
import { StepProps } from './Step'
import { StepperHeader, StepContent, StepperFooter } from './components'
import { navigateToStepIndex } from './helpers/stepper.helpers'

type Props = {
  currentStep?: number
  className?: string
  hideSubmit?: boolean
  submitButtonText?: string
  children: React.FunctionComponentElement<StepProps | false>[] // Children Should have step props
}

export const Stepper = (props: Props) => {
  const [currentStepIndex, setCurrentStepIndex] = useState((props.currentStep || 1) - 1)
  const [stepPropsList, setStepPropsList] = useState<StepProps[]>([])

  useEffect(() => {
    setStepPropsList(Children.toArray(props.children).map(path(['props'])))
  }, [props.children])

  useEffect(() => {
    setStepPropsList(prevProps =>
      prevProps.map((prop, i) => {
        if (i < currentStepIndex) return { ...prop, completed: true }
        return prop
      })
    )
  }, [currentStepIndex, props.currentStep])

  return (
    <section className="w-full overflow-hidden h-screen max-h-screen flex flex-col justify-between items-stretch">
      <StepperHeader
        currentStep={currentStepIndex}
        stepPropsList={stepPropsList}
        onClickTitle={stepIndex => {
          setCurrentStepIndex(navigateToStepIndex(stepIndex, currentStepIndex, stepPropsList))
        }}
      />

      <StepContent selectedStep={currentStepIndex}>{props.children}</StepContent>

      <StepperFooter
        submitButtonText={props.submitButtonText}
        currentStepIndex={currentStepIndex}
        setCurrentStepIndex={setCurrentStepIndex}
        stepProps={stepPropsList}
        hideSubmit={props.hideSubmit}
      />
    </section>
  )
}
