import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'
import { AudienceBuilder } from '../audience-builder'
import { Loading } from 'components/messages/loading'
import { DefaultAudienceCards, TermsAlert } from './'
import { UserLayout } from 'components/layout/UserLayout'
import { SavedAudience } from '@marketing-milk/interfaces'
import { AudienceRecalculation } from './audience-recalculation'
import { audienceBuilderService } from '@marketing-milk/frontend'
import { ServerError } from 'components/server-request/server-error/ServerError'
import { AudiencesTable } from 'components/common-tables/audiences-table/AudiencesTable'
import { CustomAudienceTermsAndConditions, SavedAudienceType } from '@marketing-milk/interfaces'
import { DeleteAudienceModal } from 'components/audience/delete-audience-modal/DeleteAudienceModal'
import { useGetBusinessID } from '@marketing-milk/frontend'
import { ViewAudienceModal } from '@marketing-milk/frontend'
import { FullPageHeader } from 'components/layout/FullPageLayout/Header/FullPageHeader'
import { FullPageContent } from 'components/layout/FullPageLayout/Content/FullPageContent'
import { useFetchAllAudiences } from 'hooks/audiences/useFetchAllAudiences'
import { NoItemsCreated } from 'components/NoItemsCreated/NoItemsCreated'

export function ListAudiences() {
  const businessID = useGetBusinessID()
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(true)
  const [terms, setTerms] = useState<CustomAudienceTermsAndConditions>()
  const { audiences, refetch } = useFetchAllAudiences(businessID)
  const [deleteModalState, verifyDelete] = useState<SavedAudience | undefined>()
  const [viewModalState, viewAudience] = useState<SavedAudience | undefined>()
  const [editAudienceState, editAudience] = useState<SavedAudience | undefined>()
  const [recalcModalState, viewRecalc] = useState<SavedAudience | undefined>()

  useEffect(() => {
    setLoading(true)
    async function checkTerms() {
      try {
        const busTerms = await audienceBuilderService.checkTermsAndConditions(businessID)
        setTerms(busTerms)
      } catch (e: any) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    checkTerms()
  }, [businessID])

  async function onSetDefault(audience: SavedAudience, isDefault: boolean) {
    try {
      await audienceBuilderService.updateAudience(audience.businessID, audience.id, {
        isDefault: isDefault,
      })
      refetch()
      toast.success(`${audience.name} default status has been updated.`)
    } catch {
      toast.error(`Sorry, we had trouble updating ${audience.name}'s default status.`)
    }
  }

  return (
    <>
      <UserLayout>
        {error && <ServerError error={error} />}
        <TermsAlert termsData={terms} />
        <FullPageHeader breadcrumbs={[{ title: 'Audiences' }]}>
          <AudienceBuilder mode="create" onAudienceCreation={refetch} />
        </FullPageHeader>

        <FullPageContent className="flex h-full w-full flex-col space-x-4 p-2">
          {audiences ? (
            <>
              {audiences.length > 0 ? (
                <div className="h-full">
                  <DefaultAudienceCards
                    onEdit={editAudience}
                    onView={viewAudience}
                    onDelete={verifyDelete}
                    onRecalc={viewRecalc}
                    onSetDefault={onSetDefault}
                    defaultAudiences={audiences.filter(audience => audience.isDefault)}
                  />

                  <h3 className="text-xl font-medium p-2">All Audiences</h3>
                  <AudiencesTable
                    onSetDefault={onSetDefault}
                    audiences={audiences}
                    deleteAudience={verifyDelete}
                    editAudience={editAudience}
                    recalcAudience={viewRecalc}
                    viewAudience={viewAudience}
                  />
                </div>
              ) : (
                <NoItemsCreated
                  title="Hmmm.. It looks like you haven't created an Audience yet, no worries!"
                  subtitle="Click the button below and create your first audience"
                >
                  <AudienceBuilder mode="create" onAudienceCreation={refetch} />
                </NoItemsCreated>
              )}
            </>
          ) : (
            <Loading />
          )}
        </FullPageContent>
      </UserLayout>

      {editAudienceState && (
        <AudienceBuilder
          mode="edit"
          audience={editAudienceState}
          onAudienceCreation={() => editAudience(undefined)}
          openModal={!!editAudienceState}
          selectedWizard={SavedAudienceType.HumanMade}
          hideToggle={true}
          onCloseModal={() => editAudience(undefined)}
        />
      )}

      {viewModalState && (
        <ViewAudienceModal
          onHideModal={() => viewAudience(undefined)}
          onRecalc={viewRecalc}
          showModal={!!viewModalState}
          audiences={[viewModalState]}
        />
      )}
      <AudienceRecalculation
        onHideModal={() => viewRecalc(undefined)}
        onCalculateAudience={refetch}
        showModal={!!recalcModalState}
        audience={recalcModalState!}
      />
      <DeleteAudienceModal
        audience={deleteModalState}
        onDelete={() => {
          verifyDelete(undefined)
          refetch()
        }}
      />
    </>
  )
}
