import { useWindowSize } from 'hooks'
import React from 'react'

interface FullPageContentProps {
  className?: string
  children?: React.ReactNode
  heightOffset?: number
}

export const FullPageContent = ({
  className,
  children,
  heightOffset = 60,
}: FullPageContentProps) => {
  const [height] = useWindowSize()

  return (
    <div
      style={{ height: height - heightOffset, maxHeight: height - heightOffset }}
      className={`w-full flex h-full flex-grow min-w-min overflow-y-auto relative ${
        className ?? ''
      } `}
    >
      {children}
    </div>
  )
}
