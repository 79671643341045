import { ReactNode } from 'react'
import { emptyLeadCard } from '../../wizard/context/LeadCardContext.const'
import {
  Business,
  exampleBusiness,
  FacebookLeadgenButtonType,
  FacebookLeadgenLocale,
  LeadCardCreateDTO,
} from '@marketing-milk/interfaces'

export interface LeadCardPreviewContext {
  leadCard: LeadCardCreateDTO
  business: Business
  section: number
  setSection(section: number): void
}
export const defaultLeadCardPreviewContext: LeadCardPreviewContext = {
  leadCard: emptyLeadCard,
  business: exampleBusiness,
  section: 0,
  setSection(section: number) {},
}

export interface LeadCardPreviewContextProviderProps {
  leadCard: LeadCardCreateDTO
  business: Business
  children: ReactNode
  section?: number
  setSection?: (section: number) => void
}

export const testLeadCardPreviewContext: LeadCardPreviewContext = {
  ...defaultLeadCardPreviewContext,
  business: {
    ...exampleBusiness,
    businessName: 'Marketing Milk',
    fbPageImgURL: 'businessImg.jpg',
  },
  leadCard: {
    formName: 'Test Form',
    formType: 'more_volume',
    intro: {
      imageID: 1,
      imageURL: 'image.jpg',
      greeting: {
        headline: 'Test Headline',
        bodyStyle: 'PARAGRAPH_STYLE',
        bodyItems: ['test paragraph text'],
      },
    },
    questionsDesc: '',
    questions: [],
    privacy: {
      link: 'https://marketingmilk.com',
      text: 'Marketing Milk Privacy',
      customDisclaimer: {
        title: 'disclaimer title',
        body: 'disclaimer body',
        checkboxes: [],
      },
    },
    thankYouPage: {
      headline: 'Thanks For Registering',
      description: 'Someone will be in contact with you.',
      callToAction: FacebookLeadgenButtonType.VIEW_WEBSITE,
      buttonText: 'Visit Website',
      link: 'https://marketingmilk.com',
      phoneNumber: '6184203646',
      countryCode: 'US',
    },
    settings: {
      locale: FacebookLeadgenLocale.EN_US,
      sharing: 'restricted',
      trackingParams: {
        testID: '1',
      },
    },
  },
}
