import Box from '@mui/material/Box'
import React, { useState } from 'react'
import { ImgHTMLAttributes } from 'react'
import IconButton from '@mui/material/IconButton'
import { autoPlay } from 'react-swipeable-views-utils'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export interface ImageCarouselProps extends SwipeableViewsProps {
  images?: ImgHTMLAttributes<HTMLImageElement>[]
  autoplay?: boolean
  navControls?: boolean
}

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

export function ImageCarousel({
  images,
  autoplay = false,
  navControls = true,
}: ImageCarouselProps) {
  const classes = useStyles()
  const maxImages = images?.length ?? 0
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1)
  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1)
  const handleStepChange = (step: number) => setActiveStep(step)

  const renderImages = images?.map((img, index) => (
    <div key={index}>
      {Math.abs(activeStep - index) <= 2 ? (
        <Box
          component="img"
          sx={{
            height: '100%',
            minHeight: 500,
            display: 'block',
            maxWidth: 700,
            overflow: 'hidden',
            width: '100%',
          }}
          src={img.src}
          alt={img.alt}
        />
      ) : null}
    </div>
  ))

  return (
    <Box sx={{ maxWidth: 700, flexGrow: 1, minHeight: 500 }}>
      {navControls && (
        <>
          {activeStep !== 0 && (
            <IconButton
              className={classes.backButton}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </IconButton>
          )}
          {activeStep !== maxImages - 1 && (
            <IconButton
              className={classes.nextButton}
              onClick={handleNext}
              disabled={activeStep === maxImages - 1}
            >
              <KeyboardArrowRight />
            </IconButton>
          )}
        </>
      )}
      {autoplay ? (
        <AutoPlaySwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {renderImages}
        </AutoPlaySwipeableViews>
      ) : (
        <SwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {renderImages}
        </SwipeableViews>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      zIndex: 1000,
      position: 'absolute',
      left: 0,
      top: '46%',
      transform: 'translateY(-50%)',
      marginLeft: '5px',
      backgroundColor: 'rgba(255,255,255,0.3) !important',
    },
    nextButton: {
      zIndex: 1000,
      position: 'absolute',
      right: 0,
      top: '46%',
      transform: 'translateY(-50%)',
      marginRight: '5px',
      backgroundColor: 'rgba(255,255,255,0.3) !important',
    },
  })
)
