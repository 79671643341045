import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { LeadCardWizard } from './wizard/LeadCardWizard'
import { UserLayout } from 'components/layout/UserLayout'
import { LeadCardCreateDTO } from '@marketing-milk/interfaces'
import { useFetchLeadCard } from 'hooks/lead-card/fetch-lead-card'
import { Loading, useGetBusinessID, useGetParam, leadCardService } from '@marketing-milk/frontend'
import { FullPageHeader } from 'components/layout/FullPageLayout/Header/FullPageHeader'
import { FullPageContent } from 'components/layout/FullPageLayout/Content/FullPageContent'

export function DuplicateLeadCard() {
  const history = useHistory()
  const businessID = useGetBusinessID()
  const leadCardID = useGetParam('leadCardID')
  const leadCard = useFetchLeadCard(Number(businessID), Number(leadCardID))

  useEffect(() => {
    if (leadCard) {
      history.push(`/${businessID}/lead-cards`)
    }
  }, [businessID])

  async function onCreate(leadCardDTO: LeadCardCreateDTO) {
    const newLeadCard = await leadCardService.create(Number(businessID), { ...leadCardDTO })
    history.push(`/${businessID}/lead-cards/${newLeadCard.id}`)
  }

  return (
    <UserLayout>
      <FullPageHeader
        breadcrumbs={[
          { title: 'Lead Cards', route: `/${businessID}/lead-cards` },
          { title: leadCard?.formName || '...loading' },
        ]}
      ></FullPageHeader>

      <div className="alert alert-dark">
        <i className="fa fa-exclamation-circle text-white mr-2 " />
        You are making a duplicate. Your changes won't be saved until you click the "Save" button.
      </div>

      <FullPageContent className="flex flex-col sm:p-4">
        {leadCard ? <LeadCardWizard defaultLeadCard={leadCard} onSave={onCreate} /> : <Loading />}
      </FullPageContent>
    </UserLayout>
  )
}
