import { TableCell, Tooltip, withStyles } from '@material-ui/core'

import React from 'react'

type Props = {
  tooltipText?: string
  children?: React.ReactNode
}

export const TableColumnToolTip = withStyles(theme => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip)

export const TableColumnTitle = (props: Props) => {
  // if tooltipText exists, render a column name surrounded by a tooltip
  if (props.tooltipText) {
    return (
      <TableColumnToolTip title={props.tooltipText}>
        <TableCell align="left">{props.children}</TableCell>
      </TableColumnToolTip>
    )
  }

  // otherwise, return a simple table cell
  return <TableCell>{props.children}</TableCell>
}
