import React from 'react'
import { Paper } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

export function ShortAnswerQuestion(props: { label: string }) {
  return (
    <Paper className="p-3 mb-3">
      <div className="form-group">
        <Typography variant="h6" className="text-bold">
          {props.label}
        </Typography>
        <TextField value="Enter your answer." fullWidth />
      </div>
    </Paper>
  )
}
