import React from 'react'
import { TextField } from '@material-ui/core'
import { DescriptiveFieldLayout } from 'components/FormFields/DescriptiveFieldLayout'

type Props = {
  name: string
  setName: (name: string) => void
}

export const SprintNameField = ({ name, setName }: Props) => (
  <DescriptiveFieldLayout
    label="Sprint Name"
    description="Your sprint name will prepend your campaign names and creative names.  If your sprint name is 'Jan-March', then your first generated campaign will be named `Jan-March - Campaign 1`"
  >
    <TextField
      fullWidth
      required
      name="sprint-name"
      error={!name.length}
      helperText="name is required"
      label="Sprint Name"
      placeholder="Sprint Name"
      value={name}
      onChange={({ target }) => {
        setName(target.value)
      }}
    />
  </DescriptiveFieldLayout>
)
