interface SprintsTabButton {
  name: string
  onClick: () => void
  selected: boolean
}
export const SprintsTabButton = ({ name, onClick, selected }: SprintsTabButton) => {
  const baseClasses = 'hover:text-purple-800 cursor-pointer'
  const selectedClasses = 'bg-white border-t-2 border-l-2 border-r-2 rounded-t-lg'

  return (
    <div
      onClick={onClick}
      role="button"
      className={`${selected ? selectedClasses : baseClasses} flex items-center p-4 text-center`}
    >
      {name}
    </div>
  )
}
