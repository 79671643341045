import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import React, { useContext, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import { sessionContext } from '@marketing-milk/frontend'
import FormHelperText from '@mui/material/FormHelperText'
import { dynamicContext } from '../../context/DynamicContext'
import { creativeContext } from '../../../../context/CreativeContext'
import { CreativeModal } from '../../../../fields/creative-modal/CreativeModal'

export function DynamicMedia() {
  const { user } = useContext(sessionContext)
  const { creative, setPreviewsToGenerate } = useContext(creativeContext)
  const { medias, addMedia, delMedia, setMedia } = useContext(dynamicContext)

  useEffect(() => {
    setPreviewsToGenerate(medias.length)
  }, [medias.length])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="w-100 d-flex">
            <Typography variant="h5" className="pt-2" data-testid="media-tab">
              Media
            </Typography>
            <Button
              className="ml-auto mr-0"
              color="secondary"
              variant="contained"
              disabled={medias.length === 5 || creative!.locked}
              onClick={addMedia}
            >
              <AddIcon className="mr-2" /> Add
            </Button>
          </div>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {medias.map((media, i) => {
            const isDuplicate = medias.filter(x => x.mediaID === media.mediaID).length > 1
            const emptyMedia = media.mediaID === undefined || media.mediaType === undefined
            return (
              <div className="mb-2" key={i}>
                <InputLabel shrink>
                  Media {i + 1}
                  {!creative!.locked && (
                    <>
                      &nbsp;&nbsp;|{' '}
                      <span className="text-danger ml-2 cursor-pointer" onClick={() => delMedia(i)}>
                        Remove
                      </span>
                    </>
                  )}
                </InputLabel>
                <CreativeModal
                  onChange={(mediaID, mediaType) => setMedia(i, mediaID, mediaType)}
                  mediaID={media.mediaID}
                  mediaType={media.mediaType}
                  readOnly={creative!.locked}
                />
                {(emptyMedia || isDuplicate) && (
                  <FormHelperText error>Field must be unique and cannot be empty</FormHelperText>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
