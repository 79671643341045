import React from 'react'
import { Paper } from '@material-ui/core'
import { categoryOptions } from './const'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import ListSubheader from '@material-ui/core/ListSubheader'
import { LeadCardQuestionPredefined, FacebookLeadgenQuestionType } from '@marketing-milk/interfaces'
import {
  LeadCardContextFunctionality,
  LeadCardContextState,
} from '../../context/LeadCardContext.types'

export type PrefillQuestionProps = {
  index: number
  question: LeadCardQuestionPredefined
  availableQuestions: FacebookLeadgenQuestionType[]
} & Pick<LeadCardContextFunctionality, 'deleteQuestion' | 'updatePrefillQuestionType'> &
  Pick<LeadCardContextState, 'editable'>

export function PrefillQuestion({
  index,
  question,
  editable,
  deleteQuestion,
  updatePrefillQuestionType,
  availableQuestions,
}: PrefillQuestionProps) {
  return (
    <Paper className="d-flex p-2 border mb-3">
      <div className="w-100 mr-3 center-vertical">
        <Select
          defaultValue=""
          disabled={!editable}
          className="ml-2"
          value={question.type}
          inputProps={{
            'aria-label': 'prefill question select',
            'data-testid': 'prefill-question-select',
          }}
          onChange={e =>
            updatePrefillQuestionType(question, e.target.value as FacebookLeadgenQuestionType)
          }
        >
          {categoryOptions.map((category, i) => [
            <ListSubheader key={i}>{category.label}</ListSubheader>,
            category.options.map((option, index) => (
              <MenuItem
                disabled={
                  !availableQuestions
                    .map(q => q)
                    .includes(option.value as FacebookLeadgenQuestionType)
                }
                key={index}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            )),
          ])}
        </Select>
      </div>
      <div>
        <IconButton disabled={!editable} onClick={() => deleteQuestion(index)}>
          <DeleteIcon data-testid="delete-icon" />
        </IconButton>
      </div>
    </Paper>
  )
}
