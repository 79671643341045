import React from 'react'
import { CampaignGoalProps } from './CampaignGoal.types'
import { ActionCard } from 'components/cards/action-card'
import { useGetBusinessID } from '@marketing-milk/frontend'
import { useCampaignGoals } from 'hooks/campaigns/use-campaign-goals-by-business'
import { campaignGoalOptimizations } from 'app/services/campaign-builder/CampaignBuilder.types'
import { Loading } from 'components/messages/loading'
import Select from 'react-select'
import { CampaignGoalType, PixelEventType } from '@marketing-milk/interfaces'
import { useCustomConversions } from '../../../../../../hooks/campaigns/useCustomConversionsByBusiness'
import { capitalCase } from 'change-case'

export function CampaignGoal({
  goal,
  onSelectGoal,
  pixelEventOrCCID,
  onCustomizeGoalConfig,
}: CampaignGoalProps) {
  const businessID = useGetBusinessID()
  const { data: goals } = useCampaignGoals(Number(businessID))
  const { data: customConversions } = useCustomConversions(Number(businessID))
  if (!goals) {
    return <Loading />
  }

  const pixelEventOptions = Object.values(PixelEventType).map(pixelEventType => ({
    label: capitalCase(pixelEventType.toLowerCase().replaceAll('_', ' ')),
    value: pixelEventType,
  }))
  const customConversionOptions =
    customConversions?.map(({ name, id, facebookCCIdentifier }) => ({
      label: `${name} (ID: ${facebookCCIdentifier})`,
      value: id,
    })) ?? []

  return (
    <div className="row my-5" data-testid="campaign-optimizations">
      <div className="w-full">
        <div className="row">
          <div className="col-12 mb-2">
            <h5>Choose Your Campaign Goal</h5>
            <p>Choose the overarching goal of your campaign</p>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 gap-5 px-2">
          {campaignGoalOptimizations.map((opt, i) => {
            const businessOpt = goals?.optimizations.find(x => x.type === opt.type)

            return (
              <div key={i} style={{ display: 'grid', gridAutoFlow: 'column', gap: '5%' }}>
                <ActionCard
                  classes={`campaign-goal-opt-cards text-center mb-2 mb-md-4 ${
                    !businessOpt?.enabled && 'cursor-default'
                  } ${opt === goal && 'selected'}`}
                  active={opt === goal}
                  onClick={() => (!businessOpt?.enabled ? {} : onSelectGoal(opt))}
                >
                  <div
                    className={`p-2 h-100 center-vertical ${
                      !businessOpt?.enabled && 'text-inactive'
                    }`}
                  >
                    <i
                      className={`fad fa-${opt.icon} fa-3x mb-0 ${
                        !businessOpt?.enabled && 'text-inactive'
                      }`}
                    />
                    <h5 className="mt-4">{opt.name}</h5>
                    <p>{opt.description}</p>
                    {!businessOpt?.enabled && (
                      <div className="text-warning bg-warning faded px-3 py-2 rounded mt-3">
                        Goal Not Configured
                      </div>
                    )}
                  </div>
                </ActionCard>
              </div>
            )
          })}
        </div>
        {!!goal &&
        [CampaignGoalType.ECommerceSales, CampaignGoalType.WebsiteSessions].includes(goal?.type) ? (
          <>
            <div className="row mt-2">
              <div className="col-12 mb-2">
                <h5>Select Web Conversion</h5>
              </div>
            </div>
            <div className="w-full grid grid-cols-2 py-4 border-b mt-2">
              <p className="text-lg font-medium text-gray-600">Conversion Event</p>
              <Select
                placeholder="Select Web Event to optimize for"
                value={
                  pixelEventOptions.find(_ => _.value === pixelEventOrCCID) ??
                  customConversionOptions.find(_ => _.value === pixelEventOrCCID)
                }
                options={[
                  {
                    label: 'Custom Conversions',
                    options: customConversionOptions,
                  },
                  {
                    label: 'Pixel Events',
                    options: pixelEventOptions,
                  },
                ]}
                onChange={e =>
                  !!e?.value && !!onCustomizeGoalConfig && onCustomizeGoalConfig(e.value)
                }
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}
