import { createStyles, makeStyles, Theme, Paper } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperContainer: {
      backgroundImage:
        "url('https://www.marketingmilk.com/wp-content/uploads/2021/07/empty-table-bg.jpg')",
      backgroundSize: 'cover',
      height: '100%',
      minHeight: '700px',
      backgroundPosition: 'center center',
    },
  })
)

interface FormPagePreviewProps {
  className?: string
  children: React.ReactNode
}

export const FormPagePreview = (props: FormPagePreviewProps) => {
  const classes = useStyles()

  return (
    <div className="sm:w-full h-full min-h-full">
      <div className="sm:px-4">
        <Paper className={`p-4 ${classes.paperContainer}`}>{props.children}</Paper>
      </div>
    </div>
  )
}
