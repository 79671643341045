import React from 'react'
import { Paper } from '@material-ui/core'
import { CustomQuestion } from './CustomQuestion'
import { LeadCardQuestionCustom } from '@marketing-milk/interfaces'
import {
  LeadCardContextFunctionality,
  LeadCardContextState,
} from '../../context/LeadCardContext.types'

export type ShortAnswerQuestionProps = { index: number; question: LeadCardQuestionCustom } & Pick<
  LeadCardContextFunctionality,
  'updateCustomQuestionKey' | 'updateCustomQuestion' | 'deleteQuestion'
> &
  Pick<LeadCardContextState, 'editable'>

export function ShortAnswerQuestion({
  index,
  question,
  deleteQuestion,
  updateCustomQuestion,
  updateCustomQuestionKey,
  editable,
}: ShortAnswerQuestionProps) {
  return (
    <Paper className="mb-3 p-2 border">
      <CustomQuestion
        id="short-answer-question"
        value={question.label}
        onChange={newLabel => {
          updateCustomQuestion(question, newLabel)
        }}
        onDelete={() => deleteQuestion(index)}
        onChangeKeyValue={newKey => updateCustomQuestionKey(index, newKey)}
        keyValue={question.key}
        placeholder="e.g. Where did you first hear about us?"
        editable={editable}
      />
    </Paper>
  )
}
