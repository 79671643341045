import React from 'react'
import { AdSummary } from './AdSummary'
import { AdsetSummary } from './AdsetSummary'
import { DetailSection } from '../detail-section'
import { SettingsSummary } from './SettingsSummary'
import { CampaignSchedule, PublishCampaignDTO, SavedAudience } from '@marketing-milk/interfaces'
// TODO:  remove this type when the correct type is inside of mono repo
import { AdPair } from 'pages/business/campaigns/forms/CampaignForm'
import { Alert } from '@material-ui/lab'

export interface CampaignDetailsProps {
  campaignDto: PublishCampaignDTO
  schedule?: CampaignSchedule
  selectedAudiences?: SavedAudience[]
  selectedCreatives?: AdPair[]
}

export function CampaignDetails({
  campaignDto,
  selectedAudiences,
  selectedCreatives,
  schedule,
}: CampaignDetailsProps) {
  return (
    <div data-testid="campaign-details" className="w-100">
      <DetailSection title="Settings" className="mb-4">
        <SettingsSummary campaignDto={campaignDto} schedule={schedule} />
      </DetailSection>
      <DetailSection title="Adsets" className="mb-4">
        {selectedAudiences && selectedAudiences.length > 0 ? (
          <AdsetSummary selectedAudiences={selectedAudiences} />
        ) : (
          <Alert severity="error">No Audience Selected</Alert>
        )}
      </DetailSection>
      <DetailSection title="Ads" className="mb-4">
        <AdSummary selectedCreatives={selectedCreatives} />
      </DetailSection>
    </div>
  )
}
