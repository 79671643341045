import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { LayoutWrapper } from './wrapper/Wrapper'
import { sessionContext } from '@marketing-milk/frontend'

export function UserLayout(props: { children: any; className?: string }) {
  const { user } = useContext(sessionContext)

  if (!user) {
    return <Redirect to="/login" />
  }

  return (
    <LayoutWrapper>
      <div
        className={`h-full flex flex-col justify-between items-stretch overflow-x-auto ${props.className}`}
      >
        {props.children}
      </div>
    </LayoutWrapper>
  )
}
