import React from 'react'
import { PrivacyText } from './PrivacyText'
import { PrivacyLink } from './PrivacyLink'
import { CustomDisclaimer } from './CustomDisclaimer'
import Typography from '@material-ui/core/Typography'
import { LeadCardPrivacySettings } from '@marketing-milk/interfaces/dist/ad-builder/LeadCardDTO'

export interface FormPrivacyProps {
  editable: boolean
  privacy: LeadCardPrivacySettings
  onChangeLink(link: string): void
  onChangeText(text: string): void
  onAddDisclaimer(): void
  onDeleteDisclaimer(): void
  onChangeTitle(title: string): void
  onChangeBody(body: string): void
  onAddConsentBlock(): void
  onDeleteConsentBlock(index: number): void
  onChangeConsent(index: number, key: 'isRequired' | 'text', value: any): void
}

export function FormPrivacy(props: FormPrivacyProps) {
  const {
    editable,
    privacy,
    onChangeLink,
    onChangeText,
    onAddDisclaimer,
    onDeleteDisclaimer,
    onChangeTitle,
    onChangeBody,
    onAddConsentBlock,
    onDeleteConsentBlock,
    onChangeConsent,
  } = props
  return (
    <div data-testid="form-privacy-step">
      <Typography className="m-0" variant="h5">
        Privacy
      </Typography>
      <p>
        Because you will be collecting customer information, you need to include a link to your
        company's privacy policy. Your link will appear with Facebook's default privacy disclaimer.
      </p>
      <hr className="my-3" />
      <PrivacyLink editable={editable} link={privacy.link} onChangeLink={onChangeLink} />
      <PrivacyText editable={editable} text={privacy.text} onChangeText={onChangeText} />
      <CustomDisclaimer
        editable={editable}
        customDisclaimer={privacy.customDisclaimer}
        onAddDisclaimer={onAddDisclaimer}
        onDeleteDisclaimer={onDeleteDisclaimer}
        onChangeTitle={onChangeTitle}
        onChangeBody={onChangeBody}
        onAddConsentBlock={onAddConsentBlock}
        onDeleteConsentBlock={onDeleteConsentBlock}
        onChangeConsent={onChangeConsent}
      />
    </div>
  )
}
