import { Input, TableFooter, TablePagination } from '@material-ui/core'
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { generateTableData, getDisplayedColumns } from './helpers/tableData.helpers'

import { ColumnFilter } from './components/ColumnFilter'
import { DataTableProps } from './types'
import { DataTableRow } from './components/DataTableRow'
import { Loading } from '@marketing-milk/frontend'
import { TableColumnTitle } from './components/TableColumnTitle'
import { fuzzySearch } from './helpers/search.helpers'
import { usePagination } from './hooks/usePagination'
import { useTableStyles } from './styles'

/**
 * Table Component designed to allow easy creation creation of complex tables.
 * see ./_TEST_/MockPeopleTable.tsx for example implementation
 * @example
    export const MockPeopleTable = (props: MockProps) => {
        const [users, setPeople] = useState<Person[]>();

        useEffect(() => {
          getPeople(10).then(setPeople);
        }, []);

        // Defining the column names as an array lets us define a tuple from them,
        // which gives us type checking when making the tableSchema
        const columnNames = ['Person Name', 'Person Age', 'Friends Ages'] as const;
        type ColumnNames = typeof columnNames[number];

        // When making the schema, you must define the object using the TableSchema Type
        //The Type takes a Record containing the information you are going to display(i.e. a list of users),
        // and it takes a schema object defining what the table will look like.  The keys are the column titles.
        // the value under each column table contains optional properties for hiding the column by default, and
        // displaying tooltip text when hovering.  The important aspect is the render function.  The render
        // function is a unary function that takes the type of the default object given(i.e. User) and returns
        // whatever will be displayed.  This can be a string, number, date, or react component
        const tableSchema: TableSchema<Person, ColumnNames> = {
          'Person Name': {
            tooltipText: 'Tooltip Text', // this column name will have tooltip text on hover
            render: person => person.name.toUpperCase(),
          },
          'Person Age': {
            render: person => person.age + ' years old',
            hidden: true // this column will be hidden by default, it can be easily displayed again by the user
          },
          'Friends Ages': { // render a list of reactcomponents
            render: person => person.friends.map(age => <p style={{color: 'green'}}>{age}</p>),
          },
        };

        // An expansion panel is simply a render function that takes the record you want to display
        // and returns a react component meant to be shown under each row
        const ExpansionPanel = (person: Person) => <p>{person.name} is {person.age} years old</p>

        return (
          <DataTable<Person>
            hideSearch // optionally hides the search bar
            hideColumnFilter // optionally hides the column filter
            recordsList={people}
            tableSchema={tableSchema}
            expansionPanel={{
              // This property has the actual expansion panel
              render: ExpansionPanel,
              // This property allows us to conditionally show the expansion panel
              // In this instance, only the person with an id of 1 will have the ability
              // to be expanded
              shouldExpand: person => person.id === 1,
            }}
            // This property is optional, pagination is handled by default
            rowsPerPage={4}
          />
        );
    };
 */
export function DataTable<T extends Record<string, any>>(props: DataTableProps<T>) {
  const { rowsPerPage = 10 } = props
  const { currentPage, setCurrentPage, getItemsOnPage, getTotalPages } =
    usePagination<T[]>(rowsPerPage)
  const [searchTerm, setSearchTerm] = useState('')
  const [schemaState, setSchemaState] = useState(props.tableSchema)
  const [tableData, setTableData] = useState<any[]>([])

  const classes = useTableStyles()

  useEffect(() => {
    setTableData([])
    if (!props.recordsList) return

    const rows = generateTableData<T>({
      recordsList: props.recordsList,
      isSelected: props.isSelected,
      selectable: props.selectable,
      expansionPanel: props.expansionPanel,
      tableSchema: schemaState,
    })

    setTableData(fuzzySearch(rows, searchTerm))
  }, [props.recordsList, schemaState, searchTerm])

  useEffect(() => {
    if (!props.recordsList) return
    const totalPages = getTotalPages(props.recordsList)
    if (currentPage > totalPages) setCurrentPage(1)
  }, [props.recordsList])

  if (!props.recordsList) return <Loading />
  return (
    <TableContainer component={Paper} className={classes.table}>
      {!props.hideSearch && (
        <Input
          type="search"
          role="search"
          className={classes.input}
          placeholder="Search Table"
          onChange={t => setSearchTerm(t.target.value)}
        />
      )}
      {/* Select Columns To Display */}
      {!props.hideColumnFilter && (
        <ColumnFilter
          tableSchema={props.tableSchema}
          setTableState={setSchemaState}
          tableState={schemaState}
        />
      )}

      <Table aria-label="collapsible table">
        {/* Column Names */}
        <TableHead>
          <TableRow>
            {props.expansionPanel && <TableColumnTitle />}
            {props.selectable && props.selectable.setSelectedRows && <TableColumnTitle />}

            {getDisplayedColumns(schemaState).map((columnTitle, i) => (
              <TableColumnTitle key={i} tooltipText={schemaState[columnTitle].tooltipText}>
                {columnTitle}
              </TableColumnTitle>
            ))}
          </TableRow>
        </TableHead>

        {/* Table Rows */}
        <TableBody className={classes.tableBody}>
          {(!props.recordsList || props.recordsList?.length === 0) && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexGrow: 1,
                minHeight: '50vh',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '300px',
              }}
            >
              {props.recordsList?.length === 0 && 'Nothing Has Been Created'}
            </div>
          )}
          {!props.hidePagination
            ? getItemsOnPage(tableData, currentPage).map((data, i) => (
                <DataTableRow {...data} key={i} />
              ))
            : tableData.map((data, i) => <DataTableRow {...data} key={i} />)}
        </TableBody>

        {/* Pagination */}
        {!props.hidePagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[{ label: 'All', value: -1 }]}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1} // have to subtract one since the usePagination hook begins at 1
                onChangePage={(_, pageNumber) => setCurrentPage(pageNumber + 1)} // must inc their page number by 1 since we begin at 1, not 0
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
