import { NestAPI } from '@marketing-milk/frontend'

import { Campaign, ResponseError } from '@marketing-milk/interfaces'
import { add, flatten, map, pipe, prop } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { toast } from 'react-toastify'

type CampaignResponse = { campaigns: Campaign[]; count: number; page: number }

export function useFetchAllCampaigns(businessId: number) {
  const [campaigns, setCampaigns] = useState<Campaign[]>()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchProp, setSearchProp] = useState('name')
  const [sortProp, setSortProp] = useState('createdTime')
  const [sortDirection, setSortDirection] = useState('DESC')

  const query = useInfiniteQuery<CampaignResponse, string>(
    ['campaigns-paginated', businessId, searchTerm, searchProp, sortProp, sortDirection],
    ({ pageParam = 1 }) =>
      NestAPI.get<CampaignResponse>(`businesses/${businessId}/campaigns`, {
        params: {
          page: pageParam,
          take: 6,
          searchTerm,
          searchProp,
          sortProp,
          sortDirection,
        },
      })
        .then(response => response.data)
        .catch(err => {
          if ('response' in err) throw err.response.data.issues.map((issue: any) => issue.message)
          throw err
        }),
    {
      getNextPageParam: lastPage => lastPage.page + 1,
    }
  )
  const { data } = query

  const flattenPaginatedResults = pipe(prop('pages'), flatten, map(prop('campaigns')), flatten)

  useEffect(() => {
    if (!data?.pages) return

    setCampaigns(flattenPaginatedResults(data))
  }, [data?.pages])

  const deleteCampaign = async (campaignId: number) => {
    try {
      const response = await NestAPI.delete(`businesses/${businessId}/campaign/${campaignId}`)
      await query.refetch()
      toast.success(response.data.data)
    } catch (e) {
      // @ts-expect-error we know it is of type ResponseError[]
      e.response.data.errors.forEach((error: ResponseError) => {
        toast.error(error.description)
      })
    }
  }

  return {
    campaigns,
    deleteCampaign,
    count: data?.pages[0].count || 0,
    current: data?.pages.map(page => page.campaigns.length).reduce(add),
    setSearchTerm,
    searchTerm,
    setSearchProp,
    searchProp,
    setSortDirection,
    sortDirection,
    setSortProp,
    sortProp,
    ...query,
  }
}
