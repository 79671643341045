import React, { useEffect, useState } from 'react'
import { TableSchema } from '../../../../../components/DataTable/types'
import { format, fromUnixTime } from 'date-fns'
import { AdGroup, Campaign, CampaignAd, CampaignAdset } from '@marketing-milk/interfaces'
import { DataTable } from 'components/DataTable'
import { Button } from '@material-ui/core'
import { getIFrameSource, SingleAdPreview } from 'components'
import DialogModal from 'components/material/dialog/Dialog'
import { StatusIndicator } from '../status-indicator'
import { OnPauseOrResumeAdObjectHandler } from '../../../../../app/services/campaign-builder/campaign/Campaign.types'
import { PauseAdObject } from '../PauseAdObject'
import { campaignService } from '../../../../../app/services'
import { FormatService, Loading, useGetBusinessID } from '@marketing-milk/frontend'
import { Tooltip } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'
import { useFetchCreativePreview } from '../../../../../hooks/creatives/fetch-creative-preview'

type Props = {
  campaign: Campaign
  adSet: CampaignAdset
  campaignHasEnded: boolean
  campaignIsFuture: boolean
  onPauseOrResume: OnPauseOrResumeAdObjectHandler
}

export const AdsTable = ({
  campaign,
  adSet,
  campaignHasEnded,
  campaignIsFuture,
  onPauseOrResume,
}: Props) => {
  const businessID = useGetBusinessID()
  const [selectedAd, setSelectedAd] = useState<CampaignAd>()
  const [adGroups, setAdGroups] = useState<AdGroup[]>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getAdsets = async () => {
      setLoading(true)
      setAdGroups(await campaignService.getAdsForAdset(Number(businessID), campaign.id, adSet.id))
      setLoading(false)
    }

    getAdsets()
  }, [businessID, campaign, adSet])

  const { retrievePreview, adCreativePreview } = useFetchCreativePreview(selectedAd?.creativeID)

  useEffect(() => {
    retrievePreview(selectedAd?.creativeID)
  }, [selectedAd?.creativeID])

  type AdTableColumnNames =
    | 'Off / On'
    | 'Ad Name'
    | 'Primary Text'
    | 'Creative Details'
    | 'Revenue'
    | 'Spend'
    | 'Cost Per'
    | 'RoAS'
    | 'KPI'
    | 'Delivery Status'
    | 'Start Date'
    | 'End Date'
  const adTableSchema: TableSchema<CampaignAd, AdTableColumnNames> = {
    'Off / On': {
      tooltipText:
        'This column indicates whether your adset is currently on or off. When switched on, it will be able to run based on its schedule and settings. When switched off, it will not run.',
      render: ad => (
        <PauseAdObject
          checkedDefault={ad.activeStatus === 'ACTIVE'}
          disabled={!ad.activeStatus}
          onPauseOrResume={checked => onPauseOrResume('Ad', ad.id, checked ? 'resume' : 'pause')}
        />
      ),
    },
    'Ad Name': {
      render: ad => ad.name || <p style={{ color: 'darkred' }}>N/A</p>,
    },
    'Start Date': {
      render: ad => (ad.startDate ? format(fromUnixTime(ad.startDate), 'P') : 'N/A'),
    },
    'End Date': {
      render: ad => (ad.endDate ? format(fromUnixTime(ad.endDate), 'P') : 'N/A'),
    },
    'Delivery Status': {
      render: data => (
        <StatusIndicator
          status={data.status}
          errors={data.errors}
          hasEnded={campaignHasEnded}
          isFuture={campaignIsFuture}
        />
      ),
    },
    Revenue: {
      render: ({ revenue }) =>
        revenue ? FormatService.getValue(revenue, { type: 'currency' }) : 'N/A',
    },
    Spend: {
      render: ({ spend }) => (spend ? FormatService.getValue(spend, { type: 'currency' }) : 'N/A'),
    },
    'Cost Per': {
      render: ({ costPerCurrent }) =>
        costPerCurrent ? FormatService.getValue(costPerCurrent, { type: 'currency' }) : 'N/A',
    },
    RoAS: {
      render: ({ roas }) => (roas ? `${FormatService.getValue(roas)}x` : 'N/A'),
    },
    KPI: {
      render: ({ kpi }) => (
        <>
          <span>{FormatService.getValue(kpi)}</span>
          <Tooltip title="Your ads may not total up to be the same as the adset-level. This is due to how ad results are currently extrapolated.">
            <HelpOutline className="text-primary ml-1" fontSize="small" />
          </Tooltip>
        </>
      ),
    },
    'Creative Details': {
      render: ad => (
        <Button color="secondary" variant="contained" onClick={() => setSelectedAd(ad)}>
          Ad Creative Details
        </Button>
      ),
    },
  }

  return (
    <>
      <DialogModal
        title={selectedAd?.name || 'Creative Preview'}
        onOpen={() => {}}
        isOpen={!!selectedAd}
        onClose={() => setSelectedAd(undefined)}
        content={
          <div>
            {adCreativePreview && (
              <SingleAdPreview iframeURL={getIFrameSource(adCreativePreview)} />
            )}
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr' }}>
              <strong>Primary Text</strong>
              <p>{selectedAd?.primaryText || 'N/A'}</p>
            </div>
            {selectedAd && selectedAd.destinationURL && (
              <div className="pt-3">
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    window.location.href = selectedAd.destinationURL ?? 'https://facebook.com'
                  }}
                >
                  View on <i className="ml-2 fab fa-facebook-square text-white" />
                </Button>
              </div>
            )}
          </div>
        }
      />
      <div className="space-y-2">
        {!loading && adGroups ? (
          adGroups.map((adGroup, i) => (
            <div key={`adset-${adSet.id}-${i}`}>
              <DataTable<any>
                tableSchema={adTableSchema}
                recordsList={adGroup}
                hideColumnFilter
                hidePagination
                hideSearch
              />
            </div>
          ))
        ) : loading ? (
          <Loading />
        ) : (
          'No Ads connected'
        )}
      </div>
    </>
  )
}
