import React from 'react'
import { LocationCustomTargetSpec } from '@marketing-milk/interfaces'
import { LocationListItem, RegionListItem, CountryListItem } from './'
import { LocationActionType, LocationRegion } from '@marketing-milk/frontend'

// NOTES:
// - user CAN set radius on pin drops and cities
// - user CANNOT set radius on countries, markets (DMAs), regions, ZIP/Postal Codes,

export interface LocationListProps {
  includedLocations: LocationCustomTargetSpec[] | undefined
  excludedLocations: LocationCustomTargetSpec[] | undefined
  includedCountries: string[] | undefined
  includedRegions: LocationRegion[] | undefined
  excludedCountries: string[] | undefined
  excludedRegions: LocationRegion[] | undefined
  updateLocationRadius(location: number, radius: number, type: LocationActionType): void
  updateLocationActionType(location: number, newType: LocationActionType): void
  updateCountryActionType(country: number, newType: LocationActionType): void
  updateRegionActionType(region: number, newType: LocationActionType): void
  delLocation(location: number, type: LocationActionType): void
  delCountry(country: number, type: LocationActionType): void
  delRegion(region: number, type: LocationActionType): void
}

export function LocationList(props: LocationListProps) {
  const allLocations =
    (props.excludedLocations?.length ?? 0) +
    (props.includedLocations?.length ?? 0) +
    (props.excludedCountries?.length ?? 0) +
    (props.includedCountries?.length ?? 0) +
    (props.excludedRegions?.length ?? 0) +
    (props.includedRegions?.length ?? 0)

  function generateList() {
    if (!allLocations) {
      return (
        <p className="text-drive bg-drive faded p-3">
          Add a locations by search or clicking the map.
        </p>
      )
    } else {
      return (
        <>
          {props.excludedCountries?.map((country, i) => (
            <CountryListItem
              key={`excluded${i}`}
              index={i}
              actionType="exclude"
              onRemoveLocation={props.delCountry}
              onUpdateActionType={props.updateCountryActionType}
              country={country}
            />
          ))}
          {props.includedCountries?.map((country, i) => (
            <CountryListItem
              key={i}
              index={i}
              actionType="include"
              onRemoveLocation={props.delCountry}
              onUpdateActionType={props.updateCountryActionType}
              country={country}
            />
          ))}
          {props.excludedRegions?.map((region, i) => (
            <RegionListItem
              key={`excluded${i}`}
              index={i}
              actionType="exclude"
              onRemoveLocation={props.delRegion}
              onUpdateActionType={props.updateRegionActionType}
              region={region}
            />
          ))}
          {props.includedRegions?.map((region, i) => (
            <RegionListItem
              key={i}
              index={i}
              actionType="include"
              onRemoveLocation={props.delRegion}
              onUpdateActionType={props.updateRegionActionType}
              region={region}
            />
          ))}
          {props.excludedLocations?.map((location, i) => (
            <LocationListItem
              key={`excluded${i}`}
              index={i}
              actionType="exclude"
              onSetRadius={props.updateLocationRadius}
              onRemoveLocation={props.delLocation}
              onUpdateActionType={props.updateLocationActionType}
              location={location}
            />
          ))}
          {props.includedLocations?.map((location, i) => (
            <LocationListItem
              key={i}
              index={i}
              actionType="include"
              onSetRadius={props.updateLocationRadius}
              onRemoveLocation={props.delLocation}
              onUpdateActionType={props.updateLocationActionType}
              location={location}
            />
          ))}
        </>
      )
    }
  }

  return (
    <div className="mt-4">
      <p className="text-bold">Selected Locations</p>
      <hr />
      <div className="locations-list">{generateList()}</div>
    </div>
  )
}
