import moment from 'moment'
import { slashDateFormat } from 'app/services/campaign-builder'

export const maxBudget = 50000
export const minBudget = 250
export const recommendedBudget = 1000

export function calculatePercentDifference(current: number, comparedTo: number) {
  return (current - comparedTo) / comparedTo
}

export function calculateTotalSpend(budget: number, spendPercentIncrease: number) {
  const increase = (spendPercentIncrease / 100) * budget
  const totalSpend = increase + budget
  return [increase, totalSpend]
}

export function calculatePercentageIncrease(value: number, percentageIncrease: number) {
  return (value / 100) * percentageIncrease
}

export function isBudgetValid(budget: number) {
  return budget >= minBudget && budget <= maxBudget
}

export function getDateAndTime(dateRange: string) {
  const dateMoment = moment(dateRange)
  const date = dateMoment.format(slashDateFormat)
  const hour = dateMoment.format('hh')
  const timeZone = dateMoment.format('a').toUpperCase()
  return {
    date,
    hour,
    timeZone,
  }
}
