import React, { useState } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import { LeadCardIntro } from '@marketing-milk/interfaces'
import { LeadFormMediaType } from '../../LeadCardWizard.types'
import Alert from '@material-ui/lab/Alert'

export interface FormMediaProps {
  editable: boolean
  intro: LeadCardIntro
  onChangeMediaType(type: LeadFormMediaType): void
  onChangeMedia(imageID: number, imageURL: string): void
}

export function FormMedia({ editable, intro, onChangeMedia, onChangeMediaType }: FormMediaProps) {
  const [showPhotoModal, setPhotoModal] = useState(false)

  function changeMediaType(event: React.MouseEvent<HTMLElement>, type: LeadFormMediaType) {
    // `${type}` is forced due to odd side effects when comparing it in its native string form
    const newType = `${type}` as LeadFormMediaType
    onChangeMediaType(newType)
    if (newType === 'uploaded') {
      setPhotoModal(true)
    }
  }

  return (
    <div className="form-group">
      <InputLabel disabled={!editable} shrink className="text-bold">
        Background *
      </InputLabel>
      <Alert variant="standard" severity="info" className="mb-2">
        The background image for this lead card will be pulled from the creative it's used with.
      </Alert>
      {/*TODO: Uncomment this code after background image upload is functional*/}
      {/*<ToggleButtonGroup onChange={changeMediaType}>*/}
      {/*  <ToggleButton disabled={!editable} size="small" selected={!intro.imageID && !intro.imageURL} value={LeadFormMediaType.Creative}>*/}
      {/*    <AddPhotoAlternateIcon fontSize="small" className="mr-2" />*/}
      {/*    Creative Image*/}
      {/*  </ToggleButton>*/}
      {/*  <ToggleButton disabled={!editable} size="small" selected={!!intro.imageID || !!intro.imageURL} value={LeadFormMediaType.Uploaded}>*/}
      {/*    <CloudUploadIcon fontSize="small" className="mr-2" />*/}
      {/*    Uploaded Image*/}
      {/*  </ToggleButton>*/}
      {/*</ToggleButtonGroup>*/}
      {/*/!* TODO: This needs to be swapped with the <MediaModal/> component from the frontend lib *!/*/}
      {/*<PagePhotoModal isOpen={showPhotoModal} onChange={onChangeMedia} onToggle={() => setPhotoModal(_ => !_)} />*/}
      {/*{!!intro.imageID && !!intro.imageURL && (*/}
      {/*  <div className="alert alert-dark mt-4">*/}
      {/*    You can view your selected image{' '}*/}
      {/*    <a href={intro.imageURL} target="_blank">*/}
      {/*      here*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  )
}
