import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { MenuItem, ListItemText, Button, Menu, Switch } from '@material-ui/core'
import { TableSchema } from '../types'
import { useTableStyles } from '../styles'

type Props<T extends Record<string, any>> = {
  tableSchema: TableSchema<T>
  tableState: Partial<TableSchema<T>>
  setTableState: Dispatch<SetStateAction<TableSchema<T>>>
}

export function ColumnFilter<T extends Record<string, any>>({
  tableState,
  setTableState,
  tableSchema,
}: Props<T>) {
  const classes = useTableStyles()
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef(null)

  const onSwitch = (columnName: string) => {
    setTableState(prevState =>
      Object.entries(prevState).reduce(
        (newState, [currColumnName, rowData]) => ({
          ...newState,
          [`${currColumnName}`]: {
            ...rowData,
            hidden: currColumnName === columnName ? !rowData.hidden : rowData.hidden,
          },
        }),
        {}
      )
    )
  }

  return (
    <>
      <Button ref={buttonRef} variant="outlined" onClick={() => setIsOpen(v => !v)}>
        Show / Hide Columns
      </Button>
      <Menu open={isOpen} anchorEl={buttonRef.current} onClose={() => setIsOpen(false)}>
        {Object.entries(tableSchema).map(([name]) => (
          <MenuItem key={name} value={name} onClick={() => onSwitch(name)}>
            <Switch role="checkbox" checked={!tableState[name]?.hidden} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
        <Button variant="contained" onClick={() => setIsOpen(false)} className={classes.exitButton}>
          Close
        </Button>
      </Menu>
    </>
  )
}
