import { useState } from 'react'
import { useBaseAudience } from './use-base-audience'
import { SavedAudience } from '@marketing-milk/interfaces'
import { removeItem, useGetCountries } from '@marketing-milk/frontend'

export interface AiAudienceData {
  customAudienceID: string
  countries: string[]
  ratio: number
}

export function useAiAudience(businessID: string, audience?: SavedAudience) {
  const countries = useGetCountries()
  const baseAudience = useBaseAudience(businessID, audience)
  const [audienceData, setAudienceData] = useState<AiAudienceData>({
    ratio: 3,
    countries: [],
    customAudienceID: '',
  })

  const selectedAudience = () =>
    baseAudience.customAudiences?.find(c => c.facebookID === audienceData.customAudienceID)

  function onSelectAudience(customAudienceID: string) {
    setAudienceData({
      ...audienceData,
      customAudienceID: customAudienceID,
    })
  }

  function onUpdateRatio(ratio: number) {
    setAudienceData({
      ...audienceData,
      ratio: ratio,
    })
  }

  function onUpdateCountries(countries: string[]) {
    setAudienceData({
      ...audienceData,
      countries: countries,
    })
  }

  function onRemoveCountry(index: number) {
    const updatedCountries = removeItem(audienceData.countries, index)
    setAudienceData({
      ...audienceData,
      countries: updatedCountries,
    })
  }

  return {
    ...baseAudience,
    audienceData,
    countries,
    selectedAudience,
    setAudienceData,
    onSelectAudience,
    onUpdateCountries,
    onUpdateRatio,
    onRemoveCountry,
  }
}
