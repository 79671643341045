import { useState } from 'react'
import { FunnelStageWithOfflineEventCount, SavedAudience } from '@marketing-milk/interfaces'
import { useBaseAudience } from './use-base-audience'
import { useGetFunnelStagesAndOptions } from '../../../../../hooks'

export interface FunnelAudienceData {
  selectedStageID: number | undefined
  autoUpdate: boolean
}

export function useFunnelAudience(businessID: string, audience?: SavedAudience) {
  const baseAudience = useBaseAudience(businessID, audience)
  const funnelStageOptions: FunnelStageWithOfflineEventCount[] | undefined =
    useGetFunnelStagesAndOptions(businessID)

  const [audienceData, setAudienceData] = useState<FunnelAudienceData>({
    selectedStageID: undefined,
    autoUpdate: false,
  })

  const selectedStage = funnelStageOptions?.find(stage => stage.id === audienceData.selectedStageID)

  function onSelectStageID(id: number) {
    setAudienceData({
      ...audienceData,
      selectedStageID: id,
    })
  }

  function onChangeAutoUpdate() {
    setAudienceData({
      ...audienceData,
      autoUpdate: !audienceData.autoUpdate,
    })
  }

  // 1. Must select a funnel stage
  // 2. The funnel stage must have offline events, OR you must check the option to add future events
  function canProceed() {
    return (
      !!audienceData.selectedStageID &&
      ((selectedStage?.offlineEventCount ?? 0) > 0 || audienceData.autoUpdate)
    )
  }

  return {
    ...baseAudience,
    audienceData,
    funnelStageOptions,
    selectedStage,
    onSelectStageID,
    onChangeAutoUpdate,
    canProceed,
  }
}
