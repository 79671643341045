import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@mui/material/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import DialogContent from '@mui/material/DialogContent'

export interface QuestionsDescriptionProps {
  editable: boolean
  description?: string
  onChangeDescription: (description: string) => void
}

export function QuestionsDescription({
  editable,
  description,
  onChangeDescription,
}: QuestionsDescriptionProps) {
  const [open, setOpen] = useState(false)
  return (
    <div className="form-group">
      <InputLabel shrink className="text-bold mb-0">
        Description *
      </InputLabel>
      <Typography variant="subtitle2">
        Let people know how the information they give you will be used or shared.{' '}
        <span className="text-hover text-brand" onClick={() => setOpen(true)}>
          See examples
        </span>
      </Typography>
      <TextField
        error={!description}
        helperText={!description && 'This field is required'}
        placeholder="We’ll use your information to send you our weekly newsletters."
        fullWidth
        disabled={!editable}
        value={description}
        onChange={e => onChangeDescription(e.target.value as string)}
        inputProps={{ 'data-testid': 'question-description-input' }}
      />
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Examples of Reasonably Prominent Notices in Lead Ads</DialogTitle>
        <DialogContent>
          <p className="pb-2">
            Facebook requires you to inform people about why you are collecting their contact
            information and how you are going to use or share it to comply with its Lead Ads Terms.
            You can create a reasonably prominent notice while creating a lead ad, and it will be
            shared with people before they submit the lead form.
          </p>

          <p className="pb-2">
            This reasonably prominent notice will appear above the contact information you are
            requesting in the form. It should include details on why you are collecting this
            information and how you are going to use or share it. To include a reasonably prominent
            notice, use the <strong>Prefill Questions</strong> field within the{' '}
            <strong>Questions</strong> section during creation.
          </p>

          <p>
            You can create your own reasonably prominent notice or modify one of these examples to
            fit your needs:
          </p>

          <div className="px-2">
            <ul>
              <li>• We’ll use your information to send you our weekly newsletters.</li>
              <li>
                • We’ll use your information to contact you about our events. We may also share your
                data with our partners who may reach out to you about their products and services.
              </li>
              <li>
                • We’ll use your information to sign you up for a property showing and will keep you
                informed about additional properties for sale in the area.
              </li>
              <li>
                • We’ll use your contact details to send you online quotes. We will also share your
                data with trusted partners who may reach out to you regarding financing options.
              </li>
            </ul>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
