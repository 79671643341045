import moment from 'moment'
import { DateRange } from '../index'
import { PeriodTypes } from '../index'
import { DatePickerOptions } from '../index'

export const defaultFormat = 'YYYY-MM-DD hh:mm A'
export const defaultDisplayFormat = 'MM/DD/YYYY'

export function getStartOfMonth(date: string): string {
  return getEdgeOfPeriod(date, 'month', 'start')
}
export function getEndOfMonth(date: string): string {
  return getEdgeOfPeriod(date, 'month', 'end')
}
export function getStartOfQuarter(date: string): string {
  return getEdgeOfPeriod(date, 'quarter', 'start')
}
export function getEndOfQuarter(date: string): string {
  return getEdgeOfPeriod(date, 'quarter', 'end')
}
export function getStartOfYear(date: string): string {
  return getEdgeOfPeriod(date, 'year', 'start')
}

export function getEndOfYear(date: string): string {
  return getEdgeOfPeriod(date, 'year', 'end')
}
export function getEdgeOfPeriod(
  date: string,
  periodType: PeriodTypes,
  edge: 'start' | 'end'
): string {
  if (edge === 'start') {
    return moment(date).startOf(periodType).format(defaultFormat)
  }
  return moment(date).endOf(periodType).format(defaultFormat)
}

export function getFullMonthRange(dateRange: DateRange): DateRange {
  return getFullPeriodRange(dateRange, 'month')
}

export function getFullQuarterRange(dateRange: DateRange): DateRange {
  return getFullPeriodRange(dateRange, 'quarter')
}

export function getFullYearRange(dateRange: DateRange): DateRange {
  return getFullPeriodRange(dateRange, 'year')
}

function getFullPeriodRange(dateRange: DateRange, periodType: PeriodTypes): DateRange {
  const { startDate, endDate } = dateRange
  const newStartDate: string = moment(startDate).startOf(periodType).format(defaultFormat)
  const newEndDate: string = moment(endDate).endOf(periodType).format(defaultFormat)
  return { startDate: newStartDate, endDate: newEndDate }
}

export function getMonthsInRange(dateRange: DateRange): string[] {
  return getPeriodsInRange(dateRange, 'month')
}

export function getQuartersInRange(dateRange: DateRange): string[] {
  return getPeriodsInRange(dateRange, 'quarter')
}

export function getYearsInRange(dateRange: DateRange): string[] {
  return getPeriodsInRange(dateRange, 'year')
}

function getPeriodsInRange(dateRange: DateRange, periodType: PeriodTypes): string[] {
  const { startDate, endDate } = dateRange
  const startMoment: moment.Moment = moment(startDate)
  const endMoment: moment.Moment = moment(endDate)
  const periods: string[] = []
  const periodFormat: string = periodType.charAt(0).toUpperCase()

  while (
    endMoment > startMoment ||
    startMoment.format(periodFormat) === endMoment.format(periodFormat)
  ) {
    periods.push(startMoment.format(defaultFormat))
    startMoment.add(1, periodType)
  }
  return periods
}

export function getOptionsByType(dateRange: DateRange, periodType: PeriodTypes): DatePickerOptions {
  const { startDate, endDate } = dateRange
  const options: DatePickerOptions = {}
  const startMoment = moment(startDate)
  const endMoment = moment(endDate)

  if (endMoment.isBefore(startMoment)) {
    throw new Error('End date must be greater than start date.')
  }

  while (
    startMoment.isBefore(endMoment) ||
    startMoment.format(defaultFormat) === endMoment.format(defaultFormat)
  ) {
    let label = ''
    if (periodType === 'month') label = startMoment.startOf(periodType).format('MMM')
    if (periodType === 'year') label = startMoment.startOf(periodType).format('YYYY')
    if (periodType === 'quarter') label = `Q${startMoment.startOf(periodType).quarter()}`
    if (periodType === 'day') label = startMoment.startOf(periodType).format('D')

    const dateOption = {
      label,
      dateRange: {
        startDate: startMoment.startOf(periodType).format(defaultFormat),
        endDate: startMoment.endOf(periodType).format(defaultFormat),
      },
    }

    if (periodType === 'day') {
      const curYear = startMoment.startOf(periodType).format('YYYY')
      const curMonth = startMoment.startOf(periodType).format('MMMM')
      const periodTypeLabel = curMonth + ', ' + curYear
      if (options[periodTypeLabel]) {
        options[periodTypeLabel].push(dateOption)
      } else {
        options[periodTypeLabel] = [dateOption]
      }
      startMoment.add(1, periodType)
    } else {
      const curYear = startMoment.startOf(periodType).format('YYYY')
      if (options[curYear]) {
        options[curYear].push(dateOption)
      } else {
        options[curYear] = [dateOption]
      }
      startMoment.add(1, periodType)
    }
  }
  return options
}
