import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ServerError } from 'components/server-request/server-error/ServerError'

type SelectOption = {
  label: string
  value: any
}

type SelectAsynccProps = {
  placeholder: string
  onSelectOption: (option: SelectOption) => void
  fetchOptions: (inputValue: string) => Promise<SelectOption[]>
}

export function SelectAsync({ placeholder, onSelectOption, fetchOptions }: SelectAsynccProps) {
  const [error, setError] = useState<Error | undefined>()
  const [inputValue, setInputValue] = useState<string>('')
  const [options, setOptions] = useState<SelectOption[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const _fetchOptions = async () => {
      try {
        if (inputValue) {
          setIsLoading(true)
          const options = await fetchOptions(inputValue)
          setIsLoading(false)
          setOptions(options)
        }
      } catch (error: any) {
        setError(error)
      }
    }

    _fetchOptions()
  }, [inputValue])

  const _onSelectOption = (label: string | null) => {
    if (label) {
      const option = options.find(option => option.label === label)
      onSelectOption(option!)
    }
  }

  return (
    <div>
      {error && <ServerError onClose={() => setError(undefined)} error={error} />}
      <Autocomplete
        freeSolo={true}
        value={inputValue}
        onChange={(event: any, option: string | null) => {
          _onSelectOption(option)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        id="controllable-states-demo"
        options={options.map(_ => _.label)}
        style={{ width: 300 }}
        renderInput={params => (
          <TextField
            {...params}
            label={placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  )
}
