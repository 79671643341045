import { adCreativeService, sessionContext, useGetBusinessID } from '@marketing-milk/frontend'
import {
  AdCreativeDTO,
  AdCreativeType,
  CreativeMediaType,
  ImageCreativeDTO,
  VideoCreativeDTO,
} from '@marketing-milk/interfaces'
import { useCreatives } from 'pages/business/creatives/useCreatives'
import { InputLabel, TextField } from '@material-ui/core'
import { isEqual } from 'lodash'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { creativeContext } from '../../context/CreativeContext'
import { CreativeModal } from '../../fields/creative-modal/CreativeModal'
import { SaveButton } from '../../fields/save-button/SaveButton'
import { SelectCallToAction } from '../../fields/select-call-to-action/SelectCallToAction'
import { SelectLeadCard } from '../../fields/select-lead-card/SelectLeadCard'
import { singleContext } from './context/SingleContext'
import { SingleContextProvider } from './context/SingleContextProvider'

interface SingleCreativeFormInput {
  name: string
  format: AdCreativeType
  graphicText: string
  primaryText: string
  headline: string
  description: string
  url: string
  displayLink: string
}

export function SingleCreativeComponent() {
  const businessID = useGetBusinessID()
  const { refetch } = useCreatives(businessID)
  const { user } = useContext(sessionContext)
  const [latestDTO, setLatestDTO] = useState<AdCreativeDTO>()
  const { creative, setCreative, name, format, setFormat } = useContext(creativeContext)
  const {
    callToAction,
    description,
    displayURL,
    graphicText,
    headline,
    mediaID,
    mediaType,
    primaryText,
    websiteURL,
    updateSingleContext,
  } = useContext(singleContext)

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm<SingleCreativeFormInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name,
      format: AdCreativeType.single_image,
      graphicText,
      primaryText,
      headline,
      description,
      url: websiteURL,
      displayLink: displayURL,
    },
  })

  const canEdit = !creative.locked

  const reloadCreative = async () => {
    const data = await adCreativeService.getByID(businessID, creative.id)
    setCreative(data)
  }

  function onMediaChange(mediaID: number, mediaType: CreativeMediaType) {
    updateSingleContext({
      mediaID,
      mediaType,
    })
    if (!!mediaType && format.includes('single')) {
      setFormat(mediaType === 'image' ? AdCreativeType.single_image : AdCreativeType.single_video)
    }
  }

  async function updateCreative() {
    const isImage = format === AdCreativeType.single_image
    const singleMedia = isImage ? 'imageID' : 'videoID'
    const singleCreativeDTO: AdCreativeDTO = {
      callToAction: callToAction,
      [singleMedia]: mediaID,
      ...getValues(),
      format: isImage ? AdCreativeType.single_image : AdCreativeType.single_video,
    }

    if (isEqual(singleCreativeDTO, latestDTO)) return

    setLatestDTO(singleCreativeDTO)

    const newSingleCreative = isImage
      ? await adCreativeService.updateSingleImage(
          businessID,
          creative!.id,
          singleCreativeDTO as ImageCreativeDTO
        )
      : await adCreativeService.updateSingleVideo(
          businessID,
          creative!.id,
          singleCreativeDTO as VideoCreativeDTO
        )

    setCreative(newSingleCreative)
    refetch()
  }

  return (
    <>
      <form onSubmit={handleSubmit(() => {})}>
        <Controller
          name="name"
          defaultValue={name}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              label="Creative Name"
              inputProps={{ 'aria-label': 'creative name' }}
              disabled={!canEdit}
              fullWidth
              required
              {...field}
            />
          )}
        />
        {errors?.name?.type === 'required' && (
          <p className="text-red text-sm">This field is required</p>
        )}

        <div className="my-3">
          <InputLabel shrink className="font-semibold">
            Media <span className="text-red mr-4">*</span>
          </InputLabel>
          <CreativeModal
            mediaID={mediaID}
            mediaType={mediaType}
            onChange={onMediaChange}
            readOnly={!canEdit}
          />
        </div>

        <div className="form-group">
          <SelectLeadCard readOnly={!canEdit} />
        </div>

        <div className="form-group">
          <Controller
            name="description"
            defaultValue={description}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label="Description"
                inputProps={{ 'aria-label': 'description' }}
                disabled={!canEdit}
                fullWidth
                {...field}
                required
              />
            )}
          />
          {errors?.description?.type === 'required' && (
            <p className="text-red text-sm">This field is required</p>
          )}
        </div>

        <div className="form-group">
          <Controller
            name="headline"
            defaultValue={headline}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label="Headline"
                inputProps={{ 'aria-label': 'headline' }}
                disabled={!canEdit}
                fullWidth
                {...field}
                required
              />
            )}
          />
          {errors?.headline?.type === 'required' && (
            <p className="text-red text-sm">This field is required</p>
          )}
        </div>

        <div className="form-group">
          <Controller
            name="graphicText"
            defaultValue={graphicText}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label="Graphic Text"
                inputProps={{ 'aria-label': 'graphic text' }}
                disabled={!canEdit}
                fullWidth
                {...field}
                required
              />
            )}
          />
          {errors?.graphicText?.type === 'required' && (
            <p className="text-red text-sm">This field is required</p>
          )}
        </div>

        <div className="form-group">
          <Controller
            name="primaryText"
            defaultValue={primaryText}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label="Primary Text"
                inputProps={{ 'aria-label': 'primary text' }}
                disabled={!canEdit}
                multiline
                fullWidth
                rows={4}
                {...field}
                required
              />
            )}
          />
          {errors?.primaryText?.type === 'required' && (
            <p className="text-red text-sm">This field is required</p>
          )}
        </div>

        <div className="form-group">
          <Controller
            name="url"
            defaultValue={websiteURL}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label="Website URL"
                inputProps={{ 'aria-label': 'website url' }}
                disabled={!canEdit}
                {...field}
                fullWidth
                required
              />
            )}
          />
          {errors?.url?.type === 'required' && (
            <p className="text-red text-sm">This field is required</p>
          )}
        </div>

        <div className="form-group">
          <Controller
            name="displayLink"
            defaultValue={displayURL}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label="Display URL"
                inputProps={{ 'aria-label': 'display url' }}
                {...field}
                disabled={!canEdit}
                fullWidth
                required
              />
            )}
          />
          {errors?.displayLink?.type === 'required' && (
            <p className="text-red text-sm">This field is required</p>
          )}
        </div>

        <div className="form-group">
          <SelectCallToAction
            onlyLeadOptions={!!creative.leadCardUsed}
            callToAction={callToAction}
            onChange={e => updateSingleContext({ callToAction: e })}
            readOnly={!canEdit}
          />
        </div>

        <div className="form-group">
          <SaveButton onSave={updateCreative} disabled={!canEdit} />
        </div>
      </form>
    </>
  )
}

export function SingleCreative() {
  return (
    <SingleContextProvider>
      <SingleCreativeComponent />
    </SingleContextProvider>
  )
}
