import React, { useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import { sessionContext } from '@marketing-milk/frontend'
import { dynamicContext } from '../../context/DynamicContext'
import { creativeContext } from '../../../../context/CreativeContext'

export function DynamicWebsite() {
  const { user } = useContext(sessionContext)
  const { creative } = useContext(creativeContext)
  const canEdit = !creative!.locked
  const { displayURL, setDisplayURL, websiteURL, setWebsiteURL } = useContext(dynamicContext)
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <TextField
              id="website-url"
              label="Website URL"
              fullWidth
              value={websiteURL}
              onChange={e => setWebsiteURL(e.target.value)}
              disabled={!canEdit}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-2">
          <div className="form-group">
            <TextField
              id="display-url"
              label="Display URL"
              fullWidth
              value={displayURL}
              onChange={e => setDisplayURL(e.target.value)}
              disabled={!canEdit}
            />
          </div>
        </div>
      </div>
    </>
  )
}
