import { FBAdPreviewFormat } from '@marketing-milk/interfaces'

export type BasicAdPreview = 'facebook' | 'instagram' | 'messenger' | 'marketplace'

export function basicAdPreviewWire(basicPreview: BasicAdPreview): FBAdPreviewFormat[] {
  switch (basicPreview) {
    case 'instagram':
      return [FBAdPreviewFormat.INSTAGRAM_STANDARD, FBAdPreviewFormat.INSTAGRAM_STORY]
    case 'messenger':
      return [
        FBAdPreviewFormat.MESSENGER_MOBILE_STORY_MEDIA,
        FBAdPreviewFormat.DESKTOP_FEED_STANDARD,
      ]
    case 'marketplace':
      return [FBAdPreviewFormat.MARKETPLACE_MOBILE, FBAdPreviewFormat.DESKTOP_FEED_STANDARD]
    default:
      return [FBAdPreviewFormat.MOBILE_FEED_STANDARD, FBAdPreviewFormat.DESKTOP_FEED_STANDARD]
  }
}

export interface AdPreviewBlock {
  format: FBAdPreviewFormat
  iframe: string
}
