import { Loading, useGetBusiness } from '@marketing-milk/frontend'
import { SprintDTO } from '@marketing-milk/interfaces'
import { Drawer } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Button } from '@mui/material'
import { ConfirmationModal } from 'components/ConfirmationModal'
import { useSprint } from 'hooks/api/useSprint'
import { useEffect, useState } from 'react'
import { getDefaultSprintDto } from '../../sprint.helpers'
import { SprintSettingsStep } from '../create-sprint-form/Steps/SprintSettingsStep'
import { EditCompleteButton } from '../shared/EditCompleteButton'
import { SprintDraftCampaigns } from '../sprint-campaigns-tab/SprintDraftCampaigns'
import { SprintsCreativesTab } from '../sprint-creatives-tab/SprintsCreativesTab'
import { SprintsPublishedCampaigns } from '../sprint-published-campaigns/SprintPublishedCampaigns'
import { SprintsTabButton } from '../sprint-tab-button/SprintsTabbutton'

type Props = {
  selectedSprintId: number
}

export const SprintContent = (props: Props) => {
  const [tab, setTab] = useState<'creatives' | 'draft-campaigns' | 'published-campaigns'>(
    'creatives'
  )
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [editing, setEditing] = useState(false)

  const { sprint, updateSprint, sprintCreatives, deleteSprint, publishedCampaigns } = useSprint(
    props.selectedSprintId
  )
  const business = useGetBusiness()

  const [sprintDto, setSprintDto] = useState<SprintDTO>(getDefaultSprintDto(business.timezone))

  useEffect(() => {
    setTab('creatives')
  }, [props.selectedSprintId])

  useEffect(() => {
    if (!sprint) return
    setSprintDto({
      name: sprint.name,
      budget: sprint.budget,
      endTime: sprint.endTime,
      startTime: sprint.startTime,
    })
  }, [sprint])

  if (!sprint)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loading loadingMessage=". . . Loading Sprint" />
      </div>
    )
  return (
    <div className="w-full h-full overflow-y-scroll p-2">
      <div className="flex p-4 space-y-2 space-x-2 items-center">
        <h1 className="font-semibold text-4xl p-2">{sprint?.name}</h1>
        <Button variant="contained" onClick={() => setEditing(true)}>
          Edit
        </Button>

        <Button
          variant="contained"
          color="error"
          data-testid="sprint-delete-button"
          onClick={() => setIsConfirmDeleteOpen(true)}
        >
          <Close />
          Delete
        </Button>
        <ConfirmationModal
          openState={[isConfirmDeleteOpen, setIsConfirmDeleteOpen]}
          onConfirm={deleteSprint}
          confirmButtonText="Delete Sprint"
          warningText="All Creatives and Published Campaigns will not be deleted, but their association with this sprint will be removed."
        />
      </div>

      <Drawer open={editing} onClose={() => setEditing(false)}>
        <div style={{ width: '80vw' }}>
          <SprintSettingsStep sprintDto={sprintDto} setSprintDto={setSprintDto} isEdit={editing} />
          <EditCompleteButton onClick={() => updateSprint(sprintDto)} />
        </div>
      </Drawer>

      <div className="p-4">
        <div className="flex">
          <SprintsTabButton
            name="Creatives"
            onClick={() => setTab('creatives')}
            selected={tab === 'creatives'}
          />

          <SprintsTabButton
            name="Draft Campaigns"
            onClick={() => setTab('draft-campaigns')}
            selected={tab === 'draft-campaigns'}
          />

          {publishedCampaigns && (
            <SprintsTabButton
              name="Published Campaigns"
              onClick={() => setTab('published-campaigns')}
              selected={tab === 'published-campaigns'}
            />
          )}
        </div>

        <div className="bg-white border-2 rounded-lg -mt-1 shadow-xl w-full min-h-screen">
          {tab === 'creatives' && sprint && <SprintsCreativesTab sprintId={sprint.id} />}

          {tab === 'draft-campaigns' && sprint && (
            <SprintDraftCampaigns sprint={sprint} creatives={sprintCreatives} />
          )}

          {tab === 'published-campaigns' && sprint && (
            <SprintsPublishedCampaigns sprintId={sprint.id} />
          )}
        </div>
      </div>
    </div>
  )
}
