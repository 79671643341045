import React from 'react'
import { AdCreative } from '@marketing-milk/interfaces'
import { Popover, Typography, Divider } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { formTypeWire } from 'pages/business/lead-cards/lead-card-preview/LeadCardPreview.types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
)

export function LeadCardIcon(props: { creative: AdCreative }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  if (!props.creative.leadCardUsed) return <></>

  return (
    <>
      <div
        data-testid="lead-card-icon"
        className="mr-2 ml-auto right-0 mt-2 bg-white absolute rounded-full d-flex center-vertical"
        style={{ width: '28px', height: '28px' }}
        // popover props
        aria-owns={open ? 'lead-card-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
          setAnchorEl(event.currentTarget)
        }
        onMouseLeave={() => setAnchorEl(null)}
      >
        <i data-testid="lc-icon" className="fad fa-address-card d-block mx-auto" />
      </div>
      <Popover
        id="lead-card-popover"
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        <Typography variant="subtitle1">Lead Card</Typography>
        <Divider />
        <Typography className="mt-2" variant="h6">
          {props.creative.leadCardUsed.formName}
        </Typography>
        <Typography variant="body1">
          {formTypeWire[props.creative.leadCardUsed.formType]}
        </Typography>
      </Popover>
    </>
  )
}
