import React from 'react'
import './business-selector.scss'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'
import { useGetAllBusinesses, useGetBusinessIDIfExists } from '@marketing-milk/frontend'

export function BusinessSelector() {
  const history = useHistory()
  const businessID = useGetBusinessIDIfExists()
  const businesses = useGetAllBusinesses()

  const options = businesses.map(_ => ({
    value: _.id,
    label: _.businessName,
  }))

  const value = businessID ? options.find(_ => _.value === Number(businessID)) : null

  function onSelect(option: { value: number; label: string }) {
    const newURL = history.location.pathname.replace(`/${businessID}/`, `/${option.value}/`)
    history.push(newURL)
  }

  if (!businessID) {
    return null
  }

  return (
    <div className="business-selector">
      <Select options={options} value={value} onChange={onSelect as any} />
    </div>
  )
}
