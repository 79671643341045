import { LeadCardPreviewContextProviderProps } from './context/LeadCardPreviewContext.types'
import { FacebookLeadgenFormType } from '@marketing-milk/interfaces'

export interface LeadCardPreviewProps
  extends Omit<LeadCardPreviewContextProviderProps, 'children'> {
  showName?: boolean
  mobilePreview?: boolean
  selectionPreview?: boolean
  hideBackground?: boolean
}

export const formTypeWire: Record<FacebookLeadgenFormType, string> = {
  more_volume: 'More Volume',
  higher_intent: 'Higher Intent',
}
