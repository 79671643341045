import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { ConsentQuestions } from './ConsentQuestions'
import Typography from '@material-ui/core/Typography'
import { CustomDisclaimerText } from './CustomDisclaimerText'
import { CustomDisclaimerTitle } from './CustomDisclaimerTitle'
import { LeadCardPrivacySettings } from '@marketing-milk/interfaces'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export interface CustomDisclaimerProps {
  editable: boolean
  customDisclaimer: LeadCardPrivacySettings['customDisclaimer']
  onAddDisclaimer(): void
  onDeleteDisclaimer(): void
  onChangeTitle(title: string): void
  onChangeBody(body: string): void
  onAddConsentBlock(): void
  onDeleteConsentBlock(index: number): void
  onChangeConsent(index: number, key: 'isRequired' | 'text', value: any): void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
  })
)

export function CustomDisclaimer({
  editable,
  customDisclaimer,
  onAddDisclaimer,
  onDeleteDisclaimer,
  onChangeTitle,
  onChangeBody,
  onChangeConsent,
  onAddConsentBlock,
  onDeleteConsentBlock,
}: CustomDisclaimerProps) {
  const classes = useStyles()

  if (!customDisclaimer) {
    return (
      <Button
        color="secondary"
        variant="contained"
        fullWidth
        disabled={!editable}
        type="button"
        onClick={onAddDisclaimer}
      >
        <AddIcon className="mr-2" />
        Add Custom Disclaimer
      </Button>
    )
  }

  return (
    <>
      <div className="d-flex">
        <div className="center-vertical">
          <Typography className="m-0" variant="h5">
            Custom Disclaimer
          </Typography>
          <p className="pb-1">
            You can add additional notices like marketing opt-ins and legal disclaimers to the
            default Facebook privacy disclaimer.
          </p>
        </div>
      </div>
      <hr className="my-3" />
      <CustomDisclaimerTitle
        editable={editable}
        customDisclaimer={customDisclaimer}
        onChangeTitle={onChangeTitle}
      />
      <CustomDisclaimerText
        editable={editable}
        customDisclaimer={customDisclaimer}
        onChangeBody={onChangeBody}
      />
      <ConsentQuestions
        editable={editable}
        customDisclaimer={customDisclaimer}
        onAddConsentBlock={onAddConsentBlock}
        onDeleteConsentBlock={onDeleteConsentBlock}
        onChangeConsent={onChangeConsent}
      />
      <div className="w-100 text-right mt-4">
        <Button
          className={classes.deleteButton}
          fullWidth
          variant="contained"
          disabled={!editable}
          type="button"
          onClick={onDeleteDisclaimer}
        >
          <DeleteIcon className="mr-2" />
          Remove Custom Disclaimer
        </Button>
      </div>
    </>
  )
}
