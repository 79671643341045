import {
  FacebookLeadgenBodyStyle,
  FacebookLeadgenButtonType,
  FacebookLeadgenFormType,
  FacebookLeadgenLocale,
  FacebookLeadgenQuestionType,
  FacebookLeadgenSharingOptions,
  LeadCard,
  LeadCardCreateDTO,
  LeadCardQuestion,
  LeadCardQuestionCustom,
  LeadCardQuestionPredefined,
} from '@marketing-milk/interfaces'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { snakeCase } from 'change-case'
import { useEffect, useState } from 'react'
import { leadCardDTOWire } from '../lead-card.wire'
import { PhoneInputProps } from 'react-phone-input-2'
import { LeadFormMediaType } from '../LeadCardWizard.types'
import { LeadCardContextState } from './LeadCardContext.types'
import { pipe, flatten, indexOf, omit, propEq, set, unset, update } from 'lodash/fp'
import { predefinedQuestionWire } from '../sections/form-questions/const'
import { checkValidUrl, updateObjectInArray } from '../../../../../app/util'
import {
  emptyLeadCard,
  newCustomDisclaimer,
  newGreeting,
  newMultiChoiceQuestion,
  newShortAnswerQuestion,
} from './LeadCardContext.const'

export const useLeadCard = (editable: boolean, defaultLeadCard?: LeadCard) => {
  const [previewSection, setPreviewSection] = useState(0)
  const [isValidPhone, setIsValidPhone] = useState(false)
  const [leadCard, setLeadCard] = useState<LeadCardCreateDTO>(
    defaultLeadCard ? leadCardDTOWire(defaultLeadCard) : emptyLeadCard
  )

  // Listeners
  useEffect(() => {
    if (
      leadCard.intro.greeting?.bodyStyle === 'PARAGRAPH_STYLE' &&
      leadCard.intro.greeting?.bodyItems.length > 1
    ) {
      setLeadCard(set(['intro', 'greeting', 'bodyItems'])(['']))
    }
  }, [leadCard.intro.greeting?.bodyStyle === 'PARAGRAPH_STYLE'])

  useEffect(() => {
    const isNotPhoneType =
      leadCard.thankYouPage?.callToAction !== FacebookLeadgenButtonType.CALL_BUSINESS
    // reset button text if CTA type changes
    setLeadCard(set(['thankYouPage', 'buttonText'])(''))
    // reset link if CTA type is changed to call business
    setLeadCard(
      set(['thankYouPage', 'link'])(!isNotPhoneType ? '' : leadCard.thankYouPage?.link ?? '')
    )
    // reset phone and country code if type is changed to download/website
    setLeadCard(
      set(['thankYouPage', 'countryCode'])(
        isNotPhoneType ? undefined : leadCard.thankYouPage?.phoneNumber
      )
    )
    setLeadCard(
      set(['thankYouPage', 'phoneNumber'])(
        isNotPhoneType ? undefined : leadCard.thankYouPage?.phoneNumber
      )
    )
  }, [leadCard.thankYouPage?.callToAction])

  function onChange(changes: Partial<LeadCardContextState>) {
    setLeadCard(_ => ({
      ..._,
      ...changes,
    }))
  }

  function canSaveLeadCard(): boolean {
    return (
      leadCard.formName !== '' && // form has name
      leadCard.questionsDesc !== '' && // form questions description
      leadCard.formType !== ('' as any) && // form has a type selected
      leadCard.questions.length > 0 && // lead card has questions
      checkValidUrl(leadCard.privacy.link) && // lead card has valid privacy link
      !!leadCard.privacy.text && // lead card has privacy text
      !!leadCard.settings.locale && // lead card has locale setting
      !!leadCard.settings.sharing &&
      checkForLabelsAndKeys() &&
      checkGreeting() &&
      checkCompletion() &&
      checkDisclaimer() &&
      checkConsentBlocks()
      // TODO: Add background image validation
    )
  }

  ///////////////////////
  // Meta Info Helpers //
  ///////////////////////

  const updateFormName = (formName: string) => setLeadCard(set(['formName'])(formName))
  const updateFormType = (formType: FacebookLeadgenFormType) =>
    setLeadCard(set(['formType'])(formType))

  ///////////////////
  // Media Helpers //
  ///////////////////

  const updateMediaType = (type: LeadFormMediaType) => {
    const [newImageID, newEncodedImage] =
      type === 'uploaded'
        ? [leadCard.intro.imageID ?? 1, leadCard.intro.imageURL ?? '']
        : [undefined, undefined]
    setLeadCard(set(['intro', 'imageID'])(newImageID))
    setLeadCard(set(['intro', 'imageURL'])(newEncodedImage))
  }

  const updateMedia = (imageID: number, imageURL: string) => {
    setLeadCard(set(['intro', 'imageID'])(imageID))
    setLeadCard(set(['intro', 'imageURL'])(imageURL))
  }

  ////////////////////////////////////
  // Custom/Prefill Question Helpers //
  ////////////////////////////////////

  const updateQuestionDescription = (description: string) =>
    setLeadCard(set(['questionsDesc'])(description))

  function deleteQuestion(index: number) {
    const newQuestions = [...leadCard.questions]
    newQuestions.splice(index, 1)
    onChange({ questions: newQuestions })
  }

  const checkForLabelsAndKeys = () => {
    // The casted type below is to tell Typescript that we're specifically using custom questions because of the prefill/custom type union
    const customQuestions = leadCard.questions.filter(
      propEq('type', FacebookLeadgenQuestionType.CUSTOM)
    ) as LeadCardQuestionCustom[]
    const customOptions = flatten(
      customQuestions.map(({ options }) => (!options ? [] : flatten(options)))
    )
    // checks if all custom questions (and multi choice options if any exist) have a label/key
    return (
      customQuestions.every(question => !!question.label && !!question.key) &&
      customOptions.every(option => !!option.value && !!option.key)
    )
  }

  const addShortAnswerQuestion = () =>
    setLeadCard(set(['questions'])([...leadCard.questions, newShortAnswerQuestion]))
  const addMultiChoiceQuestion = () =>
    setLeadCard(set(['questions'])([...leadCard.questions, newMultiChoiceQuestion]))
  const addPrefillQuestion = () =>
    setLeadCard(
      set(['questions'])([
        ...leadCard.questions,
        { type: FacebookLeadgenQuestionType[getAvailablePrefillQuestions()[0]] },
      ])
    )
  const { CUSTOM } = FacebookLeadgenQuestionType
  const getCustomQuestions = () =>
    leadCard.questions.filter(({ type }) => type === CUSTOM) as LeadCardQuestionCustom[]

  const getPrefillQuestions = () =>
    leadCard.questions.filter(({ type }) => type !== CUSTOM) as LeadCardQuestionPredefined[]

  const getCustomQuestionIndex = (customIndex: number) =>
    leadCard.questions.findIndex(_ => _ === getCustomQuestions()[customIndex])
  const getPrefillQuestionIndex = (prefillIndex: number) =>
    leadCard.questions.findIndex(_ => _ === getPrefillQuestions()[prefillIndex])

  // Multiple Choice Option Helpers
  function updateMultiChoiceOption(
    questionIndex: number,
    index: number,
    question: LeadCardQuestionCustom,
    newValue: string
  ) {
    const newOptions = [...question.options!]
    newOptions[index].value = newValue
    // convert label into autogenerated key for the question
    // NOTE: keys on custom questions are REQUIRED by facebook
    updateMultiChoiceOptionKey(questionIndex, index, question, snakeCase(newValue))
    onChangeQuestion(questionIndex, question, { options: newOptions })
  }

  const addMultiChoiceOption = (index: number, question: LeadCardQuestionCustom) =>
    onChangeQuestion(index, question, {
      options: [...(question?.options || []), { key: '', value: '' }],
    })

  function deleteMultiChoiceOption(
    questionIndex: number,
    index: number,
    question: LeadCardQuestionCustom
  ) {
    const newOptions = [...question.options!]
    newOptions.splice(index, 1)
    onChangeQuestion(questionIndex, question, { options: newOptions })
  }

  function updateMultiChoiceOptionKey(
    questionIndex: number,
    index: number,
    question: LeadCardQuestionCustom,
    newKey: string
  ) {
    const newOptions = [...question.options!]
    newOptions[index].key = newKey
    onChangeQuestion(questionIndex, question, { options: newOptions })
  }

  const updateCustomQuestionKey = (index: number, newKey: string) =>
    setLeadCard(set(['questions'])(updateObjectInArray(getCustomQuestions(), index, 'key', newKey)))

  const updateCustomQuestion = (question: LeadCardQuestion, label: string) => {
    // convert label into autogenerated key for the question
    // NOTE: keys on custom questions are REQUIRED by facebook
    setLeadCard(prevLeadCard => {
      const index = indexOf(question, prevLeadCard.questions)
      return update(
        ['questions', index],
        pipe(set('key', snakeCase(label)), set('label', label)),
        prevLeadCard
      )
    })
  }

  const getAvailablePrefillQuestions = () => {
    const disabledQuestions = getPrefillQuestions()
    if (
      disabledQuestions.find(
        question => question.type === FacebookLeadgenQuestionType.FIRST_NAME
      ) ||
      disabledQuestions.find(question => question.type === FacebookLeadgenQuestionType.LAST_NAME)
    ) {
      disabledQuestions.push({ type: FacebookLeadgenQuestionType.FULL_NAME })
    } else if (
      disabledQuestions.find(question => question.type === FacebookLeadgenQuestionType.FULL_NAME)
    ) {
      disabledQuestions.push({ type: FacebookLeadgenQuestionType.FIRST_NAME })
      disabledQuestions.push({ type: FacebookLeadgenQuestionType.LAST_NAME })
    }
    return Object.keys(predefinedQuestionWire).filter(
      x => !disabledQuestions.find(y => y.type === x)
    ) as FacebookLeadgenQuestionType[]
  }

  const updatePrefillQuestionType = (
    currentQuestion: LeadCardQuestionPredefined,
    newType: FacebookLeadgenQuestionType
  ) => {
    setLeadCard(prevLeadCard => {
      const index = indexOf(currentQuestion, prevLeadCard.questions)
      return update(['questions', index], set(['type'], newType), prevLeadCard)
    })
  }

  function sortPrefillQuestions(newQuestions: LeadCardQuestionPredefined[]) {
    if (editable) {
      onChange({
        questions: [...getCustomQuestions(), ...newQuestions],
      })
    }
  }

  function sortCustomQuestions(newQuestions: LeadCardQuestionCustom[]) {
    if (editable) {
      onChange({
        questions: [...newQuestions, ...getPrefillQuestions()],
      })
    }
  }

  function onChangeQuestion(
    index: number,
    question: LeadCardQuestionCustom,
    newQuestion: Partial<LeadCardQuestionCustom>
  ) {
    const newQuestions = [...leadCard.questions]
    newQuestions[index] = {
      ...question,
      ...newQuestion,
    }
    onChange({ questions: newQuestions })
  }

  ///////////////////////////////
  // Tracking Settings Helpers //
  ///////////////////////////////

  function upsertTrackingParam(oldID: string, id: string, value: string) {
    const paramIdExists = !!(
      leadCard.settings.trackingParams &&
      Object.keys(leadCard.settings.trackingParams).find(x => x === id)
    )
    // TODO: be defensive and validate this in the component before allowing user to save
    if (paramIdExists) {
      toast.warning("You can't have more than one tracking parameter with the same name")
    } else {
      setLeadCard(
        update(
          ['settings', 'trackingParams'],
          (trackingParams: LeadCard['settings']['trackingParams']) =>
            omit(oldID, { ...trackingParams, [id]: value })
        )
      )
    }
  }

  const deleteTrackingParam = (id: string) => setLeadCard(unset(['settings', 'trackingParams', id]))

  const updateLanguage = (language: FacebookLeadgenLocale) =>
    setLeadCard(set(['settings', 'locale'])(language))

  const updateSharing = (sharing: FacebookLeadgenSharingOptions) =>
    setLeadCard(set(['settings', 'sharing'])(sharing))

  ////////////////////////
  // Completion Helpers //
  ////////////////////////

  const checkCompletion = () => {
    return leadCard.thankYouPage
      ? !!leadCard.thankYouPage.headline &&
          !!leadCard.thankYouPage.description &&
          !!leadCard.thankYouPage.buttonText &&
          !!leadCard.thankYouPage.callToAction &&
          (leadCard.thankYouPage.callToAction !== FacebookLeadgenButtonType.CALL_BUSINESS
            ? // if CTA is visit website OR download
              checkValidUrl(leadCard.thankYouPage.link)
            : // if CTA is call business
              !!leadCard.thankYouPage.countryCode &&
              !!leadCard.thankYouPage.phoneNumber &&
              isValidPhone)
      : true
  }

  const updateCompletionHeadline = (headline: string) =>
    setLeadCard(set(['thankYouPage', 'headline'])(headline))

  const updateCompletionDescription = (description: string) =>
    setLeadCard(set(['thankYouPage', 'description'])(description))

  const updateCompletionCTA = (callToAction: FacebookLeadgenButtonType) =>
    setLeadCard(set(['thankYouPage', 'callToAction'])(callToAction))

  const updateCompletionButtonText = (buttonText: string) =>
    setLeadCard(set(['thankYouPage', 'buttonText'])(buttonText))

  const updateCompletionLink = (link: string) => setLeadCard(set(['thankYouPage', 'link'])(link))

  const updatePhoneNumber = (phoneNumber: string) =>
    setLeadCard(set(['thankYouPage', 'phoneNumber'])(phoneNumber))

  const updateCountryCode = (countryCode: string) =>
    setLeadCard(set(['thankYouPage', 'countryCode'])(countryCode))

  const updateBusinessPhone = (...info: Parameters<NonNullable<PhoneInputProps['onChange']>>) => {
    const nonFormattedPhoneNumber = info[0]
    const countryData = info[1]

    if (!isEmpty(countryData)) {
      // @ts-ignore typescript doesn't recognize the empty object check
      updateCountryCode(countryData.countryCode.toUpperCase())
      // prepend plus for Facebook
      updatePhoneNumber('+' + nonFormattedPhoneNumber)
      // check if phone number is complete
      // @ts-ignore typescript doesn't recognize the empty object check
      setIsValidPhone(nonFormattedPhoneNumber.length === countryData.format.match(/\./g).length)
    }
  }

  //////////////////////
  // Greeting Helpers //
  //////////////////////

  const checkGreeting = () => {
    // checks if greeting is present, that all required fields are also present
    return leadCard.intro.greeting
      ? !!leadCard.intro.greeting.headline &&
          !!leadCard.intro.greeting.bodyStyle &&
          // verifies at least 1 item in array isn't an empty string
          !leadCard.intro.greeting.bodyItems.every(item => !item)
      : true
  }

  const addGreeting = () => setLeadCard(set(['intro', 'greeting'])(newGreeting))

  const updateGreetingBodyStyle = (bodyStyle: FacebookLeadgenBodyStyle) =>
    setLeadCard(set(['intro', 'greeting', 'bodyStyle'])(bodyStyle))

  const updateGreetingHeadline = (headline: string) =>
    setLeadCard(set(['intro', 'greeting', 'headline'])(headline))

  const updateGreetingParagraph = (paragraph: string) =>
    setLeadCard(set(['intro', 'greeting', 'bodyItems'])([paragraph]))

  const updateGreetingBullet = (index: number, bullet: string) => {
    const newBullets = [...leadCard.intro.greeting!.bodyItems]
    if (!bullet) {
      newBullets.splice(index, 1)
    } else {
      newBullets[index] = bullet
    }
    setLeadCard(set(['intro', 'greeting', 'bodyItems'])(newBullets))
  }

  const deleteGreeting = () => setLeadCard(set(['intro', 'greeting'])(undefined))

  /////////////////////
  // Privacy Helpers //
  /////////////////////

  const updatePrivacyLink = (link: string) => setLeadCard(set(['privacy', 'link'])(link))

  const updatePrivacyText = (text: string) => setLeadCard(set(['privacy', 'text'])(text))

  // Disclaimer Helpers
  const checkDisclaimer = () => {
    // checks if disclaimer is present, that all required fields are also present
    return leadCard.privacy.customDisclaimer
      ? !!leadCard.privacy.customDisclaimer.title &&
          !!leadCard.privacy.customDisclaimer.body &&
          !!leadCard.privacy.customDisclaimer.checkboxes
      : true
  }

  const addCustomDisclaimer = () =>
    setLeadCard(set(['privacy', 'customDisclaimer'])(newCustomDisclaimer))

  const deleteCustomDisclaimer = () => setLeadCard(set(['privacy', 'customDisclaimer'])(undefined))

  const updateDisclaimerTitle = (title: string) =>
    setLeadCard(set(['privacy', 'customDisclaimer', 'title'])(title))

  const updateDisclaimerBody = (body: string) =>
    setLeadCard(set(['privacy', 'customDisclaimer', 'body'])(body))

  // Consent Block Helpers
  const checkConsentBlocks = () => {
    // checks if disclaimer is present, and all checkboxes have been filled out
    return leadCard.privacy.customDisclaimer &&
      leadCard.privacy.customDisclaimer.checkboxes.length > 0
      ? leadCard.privacy.customDisclaimer.checkboxes.every(checkbox => !!checkbox.text)
      : true
  }

  const addConsentBlock = () => {
    const newConsentQuestions = leadCard.privacy.customDisclaimer
      ? [...leadCard.privacy.customDisclaimer.checkboxes]
      : []
    newConsentQuestions.push({
      isRequired: false,
      isCheckedByDefault: false,
      text: '',
    })
    onChange({
      privacy: {
        ...leadCard.privacy,
        customDisclaimer: {
          ...leadCard.privacy.customDisclaimer!,
          checkboxes: newConsentQuestions,
        },
      },
    })
  }

  const updateConsentBlock = (index: number, key: 'isRequired' | 'text', value: any) => {
    const consentQuestion = leadCard.privacy.customDisclaimer!.checkboxes[index]
    const newConsentQuestions = [...leadCard.privacy.customDisclaimer!.checkboxes]
    newConsentQuestions[index] = {
      ...consentQuestion,
      [key]: value,
    }
    onChange({
      privacy: {
        ...leadCard.privacy,
        customDisclaimer: {
          ...leadCard.privacy.customDisclaimer!,
          checkboxes: newConsentQuestions,
        },
      },
    })
  }

  const deleteConsentBlock = (index: number) => {
    const newConsentQuestions = [...leadCard.privacy.customDisclaimer!.checkboxes]
    newConsentQuestions.splice(index, 1)
    onChange({
      privacy: {
        ...leadCard.privacy,
        customDisclaimer: {
          ...leadCard.privacy.customDisclaimer!,
          checkboxes: newConsentQuestions,
        },
      },
    })
  }

  return {
    ...leadCard,
    previewSection,
    setPreviewSection,
    isValidPhone,
    onChange,
    upsertTrackingParam,
    deleteTrackingParam,
    canSaveLeadCard,
    updateQuestionDescription,
    getAvailablePrefillQuestions,
    deleteQuestion,
    updatePrefillQuestionType,
    updateCustomQuestionKey,
    updateCustomQuestion,
    updateMultiChoiceOption,
    addMultiChoiceOption,
    deleteMultiChoiceOption,
    updateMultiChoiceOptionKey,
    addPrefillQuestion,
    addShortAnswerQuestion,
    addMultiChoiceQuestion,
    sortCustomQuestions,
    sortPrefillQuestions,
    getCustomQuestions,
    getCustomQuestionIndex,
    getPrefillQuestions,
    getPrefillQuestionIndex,
    updateLanguage,
    updateSharing,
    updateCompletionHeadline,
    updateCompletionDescription,
    updateCompletionCTA,
    updateCompletionLink,
    updateCompletionButtonText,
    updatePhoneNumber,
    updateCountryCode,
    updateBusinessPhone,
    addGreeting,
    updateGreetingBodyStyle,
    updateGreetingHeadline,
    updateGreetingParagraph,
    updateGreetingBullet,
    deleteGreeting,
    updatePrivacyLink,
    updatePrivacyText,
    addCustomDisclaimer,
    deleteCustomDisclaimer,
    updateDisclaimerTitle,
    updateDisclaimerBody,
    addConsentBlock,
    updateConsentBlock,
    deleteConsentBlock,
    updateFormName,
    updateFormType,
    updateMediaType,
    updateMedia,
  }
}
