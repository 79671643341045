import { MenuOption } from './'
import './multi-option-menu.scss'
import React, { useState, useEffect } from 'react'
import { DropdownMenu, DropdownToggle, DropdownItem, Dropdown } from 'reactstrap/lib'
import { fuzzySearch } from 'components/DataTable/helpers/search.helpers'
import { any, has, flatten, omit, propEq, equals } from 'lodash/fp'

// props for component use
export interface MultiOptionMenuProps {
  toggleTitle: string
  options: MultiOptionItem
  selectedValues?: Array<string | number | undefined>
  onSelectOption: <T>(option: MultiOptionItem<T>) => void
  onRemoveOption: <T>(option: MultiOptionItem<T>) => void
  dropDirection?: 'up' | 'down'
}

// structure of options for menu
export interface MultiOptionItem<T = string> {
  label: string
  value?: string | number
  type?: T
  children?: Array<MultiOptionItem>
}

export function MultiOptionMenu({
  options,
  onSelectOption,
  selectedValues,
  toggleTitle,
  onRemoveOption,
  dropDirection,
}: MultiOptionMenuProps) {
  const rootChildren = options?.children || []
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [items, setItems] = useState(rootChildren)
  const [animationDirection, setAnimationDirection] = useState<'right' | 'left'>('right')
  const [path, setPath] = useState<string[]>([])

  useEffect(() => {
    if (searchTerm === '') return
    setItems(searchTree(rootChildren, searchTerm))
  }, [searchTerm])

  useEffect(() => {
    if (path.length === 0) setItems(rootChildren)
    setItems(getChildrenFromPath(path))
  }, [path])

  const searchTree = (options: MultiOptionItem[], term: string) =>
    fuzzySearch(flattenChildren(options), term).filter(v => v.value)

  const flattenChildren = (nodes: MultiOptionItem[]): MultiOptionItem[] => {
    if (!any(has('children'), nodes)) return nodes

    const flattenedNodes = flatten(
      nodes.map(node => {
        if (!node?.children) return node
        return [omit(['children'], node), ...flatten(node.children)]
      })
    )

    return flattenChildren(flattenedNodes)
  }

  // Traverses down the tree until it reaches the path specified in the array
  const getChildrenFromPath = (path: string[]) =>
    path.reduce((children, label) => {
      if (!children) return []
      return children.find(propEq('label', label))?.children || []
    }, options?.children || [])

  // when user chooses item with children, set children as options for dropdown
  const goToChild = (option: MultiOptionItem) => {
    setAnimationDirection('right')
    setPath(prev => [...prev, option.label])
  }

  // traverse back up the array
  const goUpOneLevel = () => {
    setAnimationDirection('left')
    setPath(prev => prev.slice(0, -1)) // remove last item in array
  }

  // takes user back to top level options
  const goHome = () => {
    setPath([])
    setAnimationDirection('left')
  }

  // closes dropdown and resets state
  const closeDropdown = () => {
    setDropdownOpen(false)
    setAnimationDirection('right')
  }

  return (
    <Dropdown
      className="multi-option-menu w-100"
      isOpen={dropdownOpen}
      direction={dropDirection ?? 'down'}
      toggle={() => {}}
    >
      <DropdownToggle className="w-100" onClick={() => setDropdownOpen(_ => !_)}>
        {toggleTitle}
      </DropdownToggle>
      <DropdownMenu className="w-100 overflow-hidden">
        {/* Controls */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* Home Button */}
              <div
                className="fit-content bg-brand text-white px-3 py-2 border-radius float-left mr-3 cursor-pointer home-control"
                onClick={goHome}
              >
                <span className="text-bold">
                  <i className="fas fa-home text-inherit text-white" />
                </span>
              </div>

              {/* Back Button */}
              {path.length > 0 && (
                <div
                  className="fit-content bg-brand text-white px-3 py-2 border-radius float-left mr-3 cursor-pointer back-control"
                  onClick={goUpOneLevel}
                >
                  <span className="text-bold">
                    <i className="fas fa-chevron-left text-inherit mr-2 text-white" /> Back
                  </span>
                </div>
              )}

              {/* Search Input */}
              <input
                className="form-control float-right"
                style={{ maxWidth: '300px' }}
                type="text"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                placeholder="Search by term..."
              />
            </div>
          </div>
        </div>

        <DropdownItem divider />

        {/* List Items available in dropdown */}
        <ul className={`menu-options-list m-0 p-0  slide-in-${animationDirection}`}>
          {items?.map((option, index) => (
            <li key={index} className="h-100 w-100 d-block">
              <MenuOption
                option={option}
                onAddOption={() => onSelectOption(option)}
                onRemoveOption={() => onRemoveOption(option)}
                onViewChildren={() => goToChild(option)}
                selected={any(equals(option.value), selectedValues)}
              />
            </li>
          ))}
        </ul>

        <DropdownItem divider />
        <div className="d-block w-100 px-2">
          <button
            className="btn-success border-radius d-block w-100 py-3 text-center"
            onClick={closeDropdown}
          >
            Done
          </button>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}
