import React, { useState, useEffect } from 'react'
import { propEq, set } from 'lodash/fp'
import { FormatService } from '@marketing-milk/frontend'
import { PublishCampaignDTO, SavedAudience } from '@marketing-milk/interfaces'
import { StepProps } from 'components/Stepper'
import { getAudienceBoundsAverageTotal, getAudiencesById } from '../../helpers/campaignForm.helpers'
import { RenderWhenVisible } from '@marketing-milk/frontend'
import { fuzzySearch } from 'components/DataTable/helpers/search.helpers'
import { AudienceSpendLimitsModal } from 'pages/business/campaign-builder/steps/audience/audience-table/AudienceSpendLimitsModal'
import { Button } from '@mui/material'
import { AudiencesTable } from 'components/common-tables/audiences-table/AudiencesTable'

type Props = {
  campaignDto?: PublishCampaignDTO
  setCampaignDto: React.Dispatch<React.SetStateAction<PublishCampaignDTO | undefined>>
  audiences?: SavedAudience[]
} & StepProps

export const CampaignAdsetsStep = ({ audiences, campaignDto, setCampaignDto }: Props) => {
  const [selectedAudiences, setSelectedAudiences] = useState(
    getAudiencesById(campaignDto?.audienceIDs, audiences) || []
  )
  const [spendLimits, setSpendLimits] = useState(campaignDto?.spendLimitsByAudienceId || {})
  const [searchTerm, setSearchTerm] = useState('')
  const [spendLimitsModalIsOpen, setSpendLimitsModalIsOpen] = useState(false)

  // Set Audiences in DTO when an audience in table is selected
  useEffect(() => {
    setCampaignDto(
      set(
        'audienceIDs',
        selectedAudiences.map(({ id }) => id)
      )
    )
  }, [selectedAudiences])

  // Set spend limits in DTO when spend limits are selected
  useEffect(() => {
    setCampaignDto(set('spendLimitsByAudienceId', spendLimits))
  }, [spendLimits, selectedAudiences])

  // Set audiences as default if there are no audiences selected
  useEffect(() => {
    if (selectedAudiences.length !== 0) return
    if (!audiences) return
    setSelectedAudiences(audiences.filter(propEq('isDefault', true)))
  }, [audiences])

  return (
    <div className="p-4 w-full flex justify-center flex-col">
      <h1 className="py-2 font-lg font-bold mx-auto">Create Adsets</h1>
      <div className="flex-col justify-between p-4 mx-auto max-w-7xl">
        <div className="w-full flex space-y-4">
          <div className="max-w-4xl">
            <h5>Select the Audience(s) you would like to target in this campaign</h5>
            <p>
              Adsets will be created from the Audiences that you choose. The Ads you create in the
              next step will run on each of these Audiences as separate Adsets.
            </p>
          </div>
        </div>
      </div>

      <div className="flex space-x-4 p-4 pb-2 items-center">
        <input
          className="bg-white rounded-md border px-4 py-3 flex-grow"
          type="text"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Filter Audiences"
        />
        <Button
          onClick={() => setSpendLimitsModalIsOpen(v => !v)}
          variant="contained"
          color="primary"
        >
          Set Spend Limits
        </Button>
      </div>

      <h5 className="p-4 pt-0">
        Audience Size:{' '}
        {FormatService.getValue(getAudienceBoundsAverageTotal(selectedAudiences)) || 0}
      </h5>

      <AudienceSpendLimitsModal
        isOpen={spendLimitsModalIsOpen}
        onClose={() => setSpendLimitsModalIsOpen(false)}
        audiences={selectedAudiences!}
        spendLimits={spendLimits!}
        setSpendLimits={setSpendLimits!}
      />

      <div>
        <RenderWhenVisible>
          {audiences && (
            <AudiencesTable
              audiences={fuzzySearch(audiences, searchTerm)}
              checkedAudiences={selectedAudiences}
              onCheck={setSelectedAudiences}
              recalcAudience={() => {}}
              editAudience={() => {}}
              viewAudience={() => {}}
            />
          )}
        </RenderWhenVisible>
      </div>
    </div>
  )
}
