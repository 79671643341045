import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import { Dialog } from '@material-ui/core'
import List from '@material-ui/core/List'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ListItemText from '@material-ui/core/ListItemText'
import DialogContent from '@material-ui/core/DialogContent'
import { Carousel } from '../../../../components/carousel/Carousel'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'

// Primary Audience Tutorial
import step1 from './primary-audience-tutorial-steps/step1.png'
import step2 from './primary-audience-tutorial-steps/step2.png'
import step3 from './primary-audience-tutorial-steps/step3.png'
import step4 from './primary-audience-tutorial-steps/step4.png'
import step5 from './primary-audience-tutorial-steps/step5.png'
import step6 from './primary-audience-tutorial-steps/step6.png'
import step7 from './primary-audience-tutorial-steps/step7.png'
import step8 from './primary-audience-tutorial-steps/step8.png'
import step9 from './primary-audience-tutorial-steps/step9.png'
import step10 from './primary-audience-tutorial-steps/step10.png'
import step11 from './primary-audience-tutorial-steps/step11.png'
import step12 from './primary-audience-tutorial-steps/step12.png'
import step13 from './primary-audience-tutorial-steps/step13.png'
import step14 from './primary-audience-tutorial-steps/step14.png'
import step15 from './primary-audience-tutorial-steps/step15.png'

export function AudienceHelp() {
  const [primaryAudienceModalOpen, setPrimaryAudienceModalOpen] = useState<boolean>(false)
  const [helpAnchorEl, setHelpAnchorEl] = useState<null | HTMLElement>(null)
  const helpOpen = Boolean(helpAnchorEl)
  const togglePrimaryAudienceModal = () => {
    setHelpAnchorEl(null)
    setPrimaryAudienceModalOpen(_ => !_)
  }

  return (
    <>
      <IconButton
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setHelpAnchorEl(_ => (_ ? null : event.currentTarget))
        }
        component="span"
      >
        <HelpOutlineOutlinedIcon />
      </IconButton>
      <Popper style={{ width: 500 }} open={helpOpen} anchorEl={helpAnchorEl} placement="bottom-end">
        <Paper style={{ padding: '1em' }}>
          <Typography paragraph={true} variant="h5">
            Welcome to Audience Builder!
          </Typography>
          <Typography paragraph={true} variant="body1">
            This is where you will create, view, and manage your Facebook audiences.
          </Typography>
          <Typography variant="body1">
            Facebook audiences are used to target groups of people when running ad campaigns. There
            are many different ways in which you can target people, including but not limited to...
          </Typography>
          <Box display="flex" justifyContent="space-around">
            <List dense={true}>
              <ListItem className="m-0 p-0">
                <ListItemText primary="Age" />
              </ListItem>
              <ListItem className="m-0 p-0">
                <ListItemText primary="Gender" />
              </ListItem>
            </List>
            <List dense={true}>
              <ListItem className="m-0 p-0">
                <ListItemText primary="Geographic Location" />
              </ListItem>
              <ListItem className="m-0 p-0">
                <ListItemText primary="Interests and Behaviors" />
              </ListItem>
            </List>
            <List dense={true}>
              <ListItem className="m-0 p-0">
                <ListItemText primary="Website Visitors" />
              </ListItem>
              <ListItem className="m-0 p-0">
                <ListItemText primary="Facebook Likes" />
              </ListItem>
            </List>
          </Box>
          <Typography paragraph={true} variant="body1">
            Audiences can even be composed together to build up more complex audiences. This works
            on the principle of "including" and "excluding".
          </Typography>
          <Typography paragraph={true} variant="body1">
            For example, you might be interested in creating an audience that includes the group of
            people that have ever interacted with your brand. This is what we call a "primary"
            audience, and it will involve creating several different types of audiences and
            combining them together.
          </Typography>
          <Typography paragraph={true} variant="body1">
            We recommend creating a "primary" audience as one of the first things you do in Audience
            Builder.{' '}
            <Link href="javascript:void(0)" onClick={togglePrimaryAudienceModal}>
              Click here to find out how!
            </Link>
          </Typography>
        </Paper>
      </Popper>
      {/*/  Primary Audience Tutorial  /*/}
      <Dialog open={primaryAudienceModalOpen} onClose={togglePrimaryAudienceModal}>
        <DialogContent>
          <Carousel
            steps={[
              {
                title: "Let's create a Primary Audience!",
                description:
                  'To get started, click on the "Create Audience" button. You will be creating several different types of audiences and combining them together into a "primary" audience at the end.',
                imgPath: step1,
              },
              {
                title: 'Create a Website Traffic Audience',
                description: 'This audience will target anyone who has visited your website',
                imgPath: step2,
              },
              {
                title: 'Choose "All Website Visitors"',
                description:
                  'We recommend 145 days, but choose what makes sense for your business.',
                imgPath: step3,
              },
              {
                title: 'Select United States',
                description:
                  'Select the globe in the top right to be in "country" mode. Use the default value for all other fields on this step.',
                imgPath: step4,
              },
              {
                title: 'Give it a name and publish it',
                description:
                  'Click through to end of the wizard and give your website audience a sensible name, then click "Publish"',
                imgPath: step5,
              },
              {
                title: 'Next up, create an Engaged Users Audience',
                description:
                  "This audience lets you target people who have visited your business's Facebook and Instagram pages.",
                imgPath: step6,
              },
              {
                title: 'Choose a category',
                description:
                  'Make sure your selections match the image below. Note: Some businesses may not have an Instagram account.',
                imgPath: step7,
              },
              {
                title: 'Rinse and repeat',
                description:
                  'Just like the Website Audience, be sure to select the United States. Then, click through to the end and give your audience a name before publishing it.',
                imgPath: step8,
              },
              {
                title: "Now, let's create a Customer List Audience",
                description:
                  'This audience targets anyone who has generated an offline event and is in a particular funnel stage.',
                imgPath: step9,
              },
              {
                title: 'Choose a funnel stage and automatically add future customer data',
                description:
                  'Choose the funnel stage that makes the most sense for your particular business. Finish creating this audience just like you did the previous two audiences.',
                imgPath: step10,
              },
              {
                title: 'At this point...',
                description:
                  'You should have three newly created audiences in your "All Audiences" table.',
                imgPath: step11,
              },
              {
                title: "Now it's time to actually create the Primary Audience!",
                description:
                  'To do so, select "Human-Made Audience. This is the most basic type of audience, and it will act as a container for the other audiences you just created.',
                imgPath: step12,
              },
              {
                title: 'Include Audiences',
                description:
                  'Click through to the "Include" step. Find the audiences you created in the previous steps and include them.',
                imgPath: step13,
              },
              {
                title: 'Almost finished...',
                description:
                  'Select the United States on the map, then name your audience and publish it..',
                imgPath: step14,
              },
              {
                title: 'Finished!',
                description:
                  "You've successfully created a primary audience. This will be a useful audience to have in your future marketing efforts.",
                imgPath: step15,
              },
            ]}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
