import React from 'react'
import { FormSharing } from './FormSharing'
import { FormLanguage } from './FormLanguage'
import { FormTracking } from './FormTracking'
import Typography from '@material-ui/core/Typography'
import {
  FacebookLeadgenLocale,
  FacebookLeadgenSharingOptions,
  LeadCardCreateDTO,
} from '@marketing-milk/interfaces'

export interface FormSettingsProps {
  editable: boolean
  settings: LeadCardCreateDTO['settings']
  onChangeLocale(locale: FacebookLeadgenLocale): void
  onChangeSharing(sharing: FacebookLeadgenSharingOptions): void
  onSaveParam(oldID: string, id: string, value: string): void
  onDeleteParam(id: string): void
}

export function FormSettings(props: FormSettingsProps) {
  const { settings, editable, onDeleteParam, onSaveParam, onChangeLocale, onChangeSharing } = props
  return (
    <div data-testid="form-settings-step">
      <Typography className="mb-2" variant="h5">
        Settings
      </Typography>
      <p>
        Configure additional settings about your lead card like the language users will see, who can
        see it, and tracking parameters.
      </p>
      <hr className="my-3" />
      <FormLanguage editable={editable} locale={settings.locale} onChangeLocale={onChangeLocale} />
      <FormSharing
        editable={editable}
        sharing={settings.sharing}
        onChangeSharing={onChangeSharing}
      />
      <FormTracking
        editable={editable}
        trackingParams={settings.trackingParams}
        onSaveParam={onSaveParam}
        onDeleteParam={onDeleteParam}
      />
    </div>
  )
}
