import { toast } from 'react-toastify'
import React, { useContext, useState } from 'react'
import { SelectLeadCard as LeadSelect } from 'components'
import { creativeContext } from '../../context/CreativeContext'
import { adCreativeService, useGetBusinessID } from '@marketing-milk/frontend'
import { ServerError } from 'components/server-request/server-error/ServerError'

export interface SelectLeadCardProps {
  readOnly: boolean
  onSelect?: () => void
}

export function SelectLeadCard({ readOnly, onSelect }: SelectLeadCardProps) {
  const businessID = useGetBusinessID()
  const [error, setError] = useState<Error>()
  const { creative, setCreative } = useContext(creativeContext)

  async function onChange(leadCardId: number) {
    onSelect && onSelect()
    try {
      await adCreativeService.assignLeadCard(Number(businessID), creative!.id, leadCardId)
      const newCreative = await adCreativeService.getByID(Number(businessID), creative!.id)
      setCreative(newCreative)
      toast.success('Lead card has been assigned to the creative')
    } catch (e: any) {
      setError(e)
    }
  }

  return (
    <>
      {error && <ServerError error={error} onClose={() => setError(undefined)} />}
      <LeadSelect
        readOnly={readOnly || !creative}
        selectedLeadCardID={creative?.leadCardUsed?.id}
        onSelectLeadCard={onChange}
      />
    </>
  )
}
