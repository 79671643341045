import React from 'react'
import { LocationCustomTargetSpec } from '@marketing-milk/interfaces'
import {
  LocationActionDropdown,
  LocationActionType,
  RadiusSelector,
} from '@marketing-milk/frontend'

const FACEBOOK_MIN_RADIUS = 1
const FACEBOOK_MAX_RADIUS = 50

export interface LocationListItemProps {
  index: number
  onSetRadius: (location: number, radius: number, type: LocationActionType) => void
  onRemoveLocation: (location: number, type: LocationActionType) => void
  onUpdateActionType: (location: number, newType: LocationActionType) => void
  location: LocationCustomTargetSpec
  actionType: LocationActionType
}

export function LocationListItem(props: LocationListItemProps) {
  const { location, onUpdateActionType, onRemoveLocation, onSetRadius, actionType } = props

  function setRadius(radius: number) {
    onSetRadius(props.index, radius, props.actionType)
  }

  function removeLocation() {
    onRemoveLocation(props.index, actionType)
  }

  function updateActionType(type: LocationActionType) {
    onUpdateActionType(props.index, type)
  }

  return (
    <div className="location-list-item py-2">
      <LocationActionDropdown
        actionType={actionType}
        onlyIcon={true}
        onSelectAction={updateActionType}
      />
      <small className="text-bold" style={{ fontSize: '12px' }}>
        {location.address_string}
      </small>
      <RadiusSelector
        radius={location.radius}
        onSetRadius={e => setRadius(e)}
        min={FACEBOOK_MIN_RADIUS}
        max={FACEBOOK_MAX_RADIUS}
      />
      <i
        data-testid="remove-location"
        className="remove-location fas fa-times text-inherit text-danger float-right cursor-pointer mt-2"
        onClick={removeLocation}
      />
    </div>
  )
}
