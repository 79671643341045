import { singleContext } from './SingleContext'
import React, { useContext, useState } from 'react'
import { FBCallToAction } from '@marketing-milk/interfaces'
import { creativeContext } from '../../../context/CreativeContext'
import { SingleContextProps, SingleContextState } from './SingleContext.types'

export function SingleContextProvider({ children }: SingleContextProps) {
  const { creative } = useContext(creativeContext)
  const [state, setState] = useState<SingleContextState>({
    headline: creative?.linkHeadline ?? '',
    description: creative?.linkDescription ?? '',
    graphicText: creative?.graphicText ?? '',
    primaryText: creative?.primaryText ?? '',
    websiteURL: creative?.linkWebsiteURL ?? '',
    displayURL: creative?.linkDisplayURL ?? '',
    callToAction: creative?.callToAction ?? FBCallToAction.ADD_TO_CART,
    mediaID: creative?.singleImage?.id ?? creative?.singleVideo?.id,
    mediaType: creative?.singleImage ? 'image' : 'video',
  })

  function updateSingleContext(changes: Partial<SingleContextState>) {
    setState(_ => ({
      ..._,
      ...changes,
    }))
  }

  return (
    <singleContext.Provider value={{ ...state, updateSingleContext }}>
      {children}
    </singleContext.Provider>
  )
}
