import {
  AdCreative,
  CampaignSchedule,
  PublishCampaignDTO,
  SavedAudience,
} from '@marketing-milk/interfaces'
import { CampaignSummary } from 'components'
import { isEmpty, propEq, set } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import {
  getAdPairs,
  getAudiencesById,
  getDefaultAudienceIDs,
} from '../../helpers/campaignForm.helpers'

export interface CampaignReviewStepProps {
  campaignDto: PublishCampaignDTO
  setCampaignDto: SetState<PublishCampaignDTO | undefined>
  onPublish: () => void
  schedule?: CampaignSchedule
  audiences?: SavedAudience[]
  creatives?: AdCreative[]
}

export function CampaignReviewStep({
  campaignDto,
  setCampaignDto,
  onPublish,
  audiences,
  creatives,
  schedule,
}: CampaignReviewStepProps) {
  const [selectedAudiences, setSelectedAudiences] = useState<SavedAudience[]>([])
  const selectedCreatives = getAdPairs(campaignDto.creativeGroups, creatives || [])

  // Set audiences as default if there are no audiences selected
  useEffect(() => {
    if (selectedAudiences.length !== 0) return
    if (!audiences) return
    setSelectedAudiences(audiences.filter(propEq('isDefault', true)))
  }, [audiences])

  useEffect(() => {
    if (!isEmpty(campaignDto.audienceIDs) || !audiences) return
    setCampaignDto(set('audienceIDs', getDefaultAudienceIDs(audiences)))
  }, [audiences])

  useEffect(() => {
    setSelectedAudiences(getAudiencesById(campaignDto.audienceIDs, audiences))
  }, [audiences, campaignDto.audienceIDs])

  return (
    <div className="p-4 w-full flex justify-center flex-col">
      <h1 className="py-2 font-lg font-bold mx-auto">Campaign Summary</h1>
      <CampaignSummary
        campaignDTO={campaignDto}
        onPublish={onPublish}
        selectedCreatives={selectedCreatives}
        selectedAudiences={selectedAudiences}
        schedule={schedule}
      />
    </div>
  )
}
