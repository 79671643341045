import { Campaign } from '@marketing-milk/interfaces'
import { OnPauseOrResumeAdObjectHandler } from '../../../../../app/services'
import { DetailSection, SettingsSummary } from '../../../../../components'
import { campaignHasEnded, campaignHasStarted } from '../../helpers/campaignForm.helpers'
import { AdsetsTable } from '../adsets/AdsetsTable'

export interface CampaignDetailsProps {
  campaign: Campaign
  onPauseOrActivate: OnPauseOrResumeAdObjectHandler
}

export function CampaignExpandDetails({ campaign, onPauseOrActivate }: CampaignDetailsProps) {
  return (
    <div key={campaign.id} className="grid w-full p-4">
      {campaign.config && (
        <div className="col-span-full p-4">
          <DetailSection title="Settings" className="mb-4">
            <SettingsSummary
              campaignDto={campaign.config}
              schedule={campaign.adjustmentSchedule}
              rollingCostPer={campaign.rollingCostPer ?? undefined}
            />
          </DetailSection>
        </div>
      )}
      <div className="col-span-full">
        <AdsetsTable
          campaign={campaign}
          campaignHasEnded={campaignHasEnded(campaign.endTime)}
          isFutureCampaign={!campaignHasStarted(campaign.startTime)}
          onPauseOrResume={onPauseOrActivate}
        />
      </div>
    </div>
  )
}
