import {
  Campaign,
  CampaignGoalType,
  CampaignPauseActionType,
  exampleCampaignRank,
} from '@marketing-milk/interfaces'
import { addMonths, getUnixTime } from 'date-fns'
import {
  mockAdsetError,
  mockAdsetWithAdErrors,
  mockCampaignAd,
  mockCampaignError,
} from 'pages/business/campaigns/mockData'
import { campaignGoalOptimizations } from '../CampaignBuilder.types'

export const mockGlobalStats = {
  temp: true,
}

export type CampaignsByBusinessId = { [businessId: string]: Campaign[] }

export type AdObjectType = 'Campaign' | 'Adset' | 'Ad'
export type OnPauseOrResumeAdObjectHandler = (
  adObjectType: AdObjectType,
  adObjectID: number,
  action: CampaignPauseActionType
) => Promise<boolean>

export const defaultCampaign: Campaign = {
  id: 0,
  status: 'ACTIVE',
  name: 'Campaign Name',
  goal: campaignGoalOptimizations[0].type,
  startTime: 0,
  endTime: 0,
  budget: 1000,
  spend: 0,
  kpi: 0,
  costPerCurrent: 0,
  costPerIndustryAvg: 0,
  rank: exampleCampaignRank,
  isCascading: false,
  roas: 0,
  revenue: 0,
}

export const mockCampaign: Campaign = {
  id: 0,
  name: 'Campaign',
  goal: campaignGoalOptimizations[0].type,
  startTime: getUnixTime(new Date()),
  endTime: getUnixTime(addMonths(new Date(), 3)),
  budget: 1000,
  status: 'ACTIVE',
  spend: 1000,
  kpi: 48,
  costPerCurrent: 5.2,
  costPerIndustryAvg: 6.2,
  rank: exampleCampaignRank,
  isCascading: false,
  roas: 15.44,
  revenue: 40327,
  config: {
    audienceIDs: [],
    creativeGroups: [],
    campaign: {
      name: 'Campaign',
      budget: 1000,
      startTime: getUnixTime(new Date('9-10-2020')),
      endTime: getUnixTime(new Date('10-20-2020')),
      frequency: 1,
      goal: CampaignGoalType.LeadGeneration,
    },
  },
}

const makeExampleCampaign = (id: number, title: string, rois: number) => ({
  ...mockCampaign,
  id: id,
  rois: rois,
})

export const makeMockCampaign = (id: number, title: string, params: Partial<Campaign>) => ({
  ...mockCampaign,
  id: id,
  ...params,
  config: {
    ...mockCampaign.config,
    ...params.config,
  },
})

export const makeSingleMockCampaign = (params: Partial<Campaign>) =>
  makeMockCampaign(1, 'Single Example', params)

export const mockErrorCampaign = {
  ...makeExampleCampaign(3, 'Campaign w/ Error', 0),
  errors: [{ message: 'There was an error' }],
}

export const mockActiveCampaignSet = [
  makeExampleCampaign(2, 'Campaign 2 With High Roa', 12),
  makeExampleCampaign(1, 'Campaign 1 With Low Roa', 8),
]

export const mockCampaignSet: Array<Campaign> = [
  mockCampaign,
  {
    ...mockCampaign,
    // @ts-ignore
    adSets: [{ ...mockAdsetWithAdErrors, errors: [mockAdsetError] }],
    errors: [mockCampaignError],
  },
  {
    ...mockCampaign,
    id: 1,
    goal: campaignGoalOptimizations[1].type,
    status: 'ACTIVE',
    kpi: 23,
    costPerCurrent: 3.4,
  },
  {
    ...mockCampaign,
    id: 2,
    goal: campaignGoalOptimizations[2].type,
    status: 'ARCHIVED',
    kpi: 11,
    costPerCurrent: 9.3,
  },
  {
    ...mockCampaign,
    id: 3,
    goal: campaignGoalOptimizations[3].type,
    status: 'IN_PROCESS',
    kpi: 102,
    costPerIndustryAvg: 12.98,
  },
  {
    ...mockCampaign,
    id: 4,
    goal: campaignGoalOptimizations[0].type,
    status: 'ACTIVE',
    kpi: 23,
    roas: 9.85,
    spend: 2345.67,
    revenue: 8364.82,
    costPerIndustryAvg: 4.58,
  },
  {
    ...mockCampaign,
    id: 5,
    goal: campaignGoalOptimizations[2].type,
    status: 'ACTIVE',
    kpi: 13,
    spend: 568.29,
  },
  mockCampaign,
]
