import React, { ReactNode } from 'react'
import Button from '@material-ui/core/Button'
import ShuffleIcon from '@material-ui/icons/Shuffle'
import { MobilePreview } from './mobile-preview/MobilePreview'
import { FBAdPreviewFormat } from '@marketing-milk/interfaces'
import { DesktopPreview } from './desktop-preview/DesktopPreview'
import { getIFrameSource, SingleAdPreview } from 'components/ad-preview'

export function getPreview(
  format: FBAdPreviewFormat,
  rawIFrame: string,
  hideBorder = false,
  fluidWidth = false,
  children?: ReactNode,
  isDynamic?: boolean,
  onShuffle?: () => void
): ReactNode {
  const iframeSource = getIFrameSource(rawIFrame)

  if (!iframeSource) {
    return (
      <div
        className="mt-15 font-weight-bold text-danger border border-radius"
        style={{ width: fluidWidth ? '100%' : 325, padding: 5 }}
      >
        <i className="fa fa-exclamation-triangle mr-2 text-danger" /> Invalid Preview
      </div>
    )
  }

  switch (true) {
    case format.includes('MOBILE') || format.includes('INSTAGRAM'):
      return hideBorder ? (
        <MobilePreview format={format} iframeSource={iframeSource} fluidWidth={fluidWidth} />
      ) : (
        <>
          <SingleAdPreview iframeURL={iframeSource} children={children} />
          {/*if creative is dynamic render shuffle button*/}
          {isDynamic && (
            <Button
              data-testid="shuffle-button"
              color="primary"
              className="d-block mx-auto"
              onClick={onShuffle}
            >
              <ShuffleIcon fontSize="small" /> Shuffle Creative
            </Button>
          )}
        </>
      )
    case format.includes('DESKTOP'):
      return <DesktopPreview format={format} iframeSource={iframeSource} />
    default:
      return 'Not Supported'
  }
}
