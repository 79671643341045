import { capitalCase } from 'change-case'
import { SearchInput } from 'components'
import { FullPageSidebar } from 'components/layout/FullPageLayout/Sidebar/FullPageSidebar'
import { SidebarSection } from 'components/layout/FullPageLayout/Sidebar/SidebarSection'
import ReactSelect from 'react-select'
import SortIcon from '@mui/icons-material/Sort'

type Props = {
  setSearchTerm: SetState<string>
  setSortProp: SetState<string>
  setSortDirection: SetState<string>
  sortDirection: string
}

export const CampaignSidebar = (props: Props) => {
  return (
    <FullPageSidebar>
      <SidebarSection title="Search Campaigns">
        <SearchInput onSearch={props.setSearchTerm} placeholder="Search By Name" />
      </SidebarSection>
      <SidebarSection title="Sort Campaigns">
        <span className="flex">
          <div className="flex-1">
            <ReactSelect
              defaultValue={{ label: 'Created Time', value: 'createdTime' }}
              options={['createdTime', 'updatedTime', 'name', 'startTime', 'endTime', 'goal'].map(
                value => ({
                  label: capitalCase(value),
                  value,
                })
              )}
              onChange={newValue => newValue && props.setSortProp(newValue.value)}
              openMenuOnClick
            />
          </div>
          <button
            type="button"
            onClick={() =>
              props.setSortDirection(direction => (direction === 'ASC' ? 'DESC' : 'ASC'))
            }
            className="-ml-px flex-shrink-0 relative inline-flex items-center space-x-2 px-2 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <SortIcon className="text-gray-400 w-5 h-5" />

            <span>{props.sortDirection}</span>
          </button>
        </span>
      </SidebarSection>
    </FullPageSidebar>
  )
}
