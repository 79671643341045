import React, { useState } from 'react'
import { AdCreative, AdCreativeType } from '@marketing-milk/interfaces'

type Props = {
  creative: AdCreative
  property: keyof AdCreative
}
export const CreativePill = ({ creative, property }: Props) => {
  const getReviewStatusStyles = ({ reviewStatus }: AdCreative) => {
    if (reviewStatus === 'ready') return 'text-green-800 bg-green-100'
    if (reviewStatus === 'pending') return 'text-green-800 bg-green-100'
    if (reviewStatus === 'rejected') return 'text-red-800 bg-red-100'
    if (reviewStatus === 'draft') return 'text-blue-800 bg-blue-100'
    return 'text-purple-900 bg-purple-100'
  }

  const getTypeStyles = ({ format }: AdCreative) => {
    if (format === AdCreativeType.single_image) return 'text-green-800 bg-green-100'
    if (format === AdCreativeType.single_video) return 'text-blue-800 bg-blue-100'
    if (format === AdCreativeType.carousel) return 'text-yellow-800 bg-yellow-100'
    if (format === AdCreativeType.dynamic) return 'text-red-800 bg-red-100'
    return 'text-purple-900 bg-purple-100'
  }

  const getPillStyles = () => {
    if (property === 'format') return getTypeStyles(creative)
    if (property === 'reviewStatus') return getReviewStatusStyles(creative)
    return ''
  }

  return (
    <span
      className={
        'px-4 py-1 absolute m-2 text-md font-medium rounded-full w-auto flex' +
        ' ' +
        getPillStyles()
      }
    >
      {creative[property]?.toString().replace('_', ' ')}
    </span>
  )
}

type CreativeMediaProps = {
  creative: AdCreative
}
const CreativeMedia = ({ creative }: CreativeMediaProps) => {
  const [brokenLink, setBrokenLink] = useState(false)

  const tailwindHeight = 'h-64 w-full bg-gray-200'

  if (brokenLink)
    return (
      <div
        className={`font-bold text-red-500 ${tailwindHeight} w-full flex items-center justify-center `}
      >
        <i className="fa fa-exclamation-triangle mr-2 text-red-400" /> Link Broken In Marketing Milk
      </div>
    )

  if (creative.singleVideo)
    return (
      <video
        autoPlay
        preload="auto"
        muted
        loop
        onError={() => setBrokenLink(true)}
        playsInline
        src={creative.singleVideo ? creative.singleVideo?.source : ''}
        className={`${tailwindHeight} rounded-t-lg object-cover`}
      />
    )

  if (creative.imageURL)
    return (
      <img
        src={creative.imageURL}
        onError={() => setBrokenLink(true)}
        className={`${tailwindHeight} object-cover rounded-t-lg`}
      />
    )

  return (
    <div
      className={`font-bold text-yellow-500 w-full flex ${tailwindHeight} items-center justify-center `}
    >
      <i className="fa fa-exclamation-triangle mr-2 text-yellow-500" /> No Media Added
    </div>
  )
}
