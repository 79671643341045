import React from 'react'
import Box from '@material-ui/core/Box'
import { SelectItem } from 'components/select-item'
import { SelectAsync } from 'components/select/SelectAsync'
import { MultiOptionMenu } from 'components/multi-option-menu'
import { getCustomInterests } from 'app/services/audience-builder'
import { DemographicMultiOptionItem, DemographicsProps } from '../Demographics.types'
import {
  addNewSpec,
  removeSpec,
  transformSpecArrayToOptions,
  transformSpecToOptions,
} from '../helpers/demographics.helpers'

export function ExcludedDemographics({
  targetSpec,
  setTargetSpec,
  options,
  ...props
}: DemographicsProps) {
  const fetchCustomInterestOptions = async (inputValue: string) => {
    const customInterests = await getCustomInterests(props.businessID, inputValue)
    const selectedTraits = transformSpecArrayToOptions(targetSpec.flexible_spec).map(
      option => option.value
    )
    return customInterests
      .filter(({ id }) => !selectedTraits.includes(id))
      .map(({ name, id, type }) => ({
        label: name,
        value: id,
        type,
      }))
  }

  const addExcludedDemographic = (option: DemographicMultiOptionItem) =>
    setTargetSpec(oldSpec => ({
      ...oldSpec,
      exclusions: addNewSpec(option, oldSpec.exclusions || {}),
    }))

  const removeExcludedDemographic = (option: DemographicMultiOptionItem) =>
    setTargetSpec(oldSpec => ({
      ...oldSpec,
      exclusions: removeSpec(option, targetSpec.exclusions || {}),
    }))

  return (
    <>
      <div className="row mt-5">
        <div className="col-12">
          <p className="text-bold">ANY of these traits:</p>
          <Box display="flex" alignItems="center">
            <MultiOptionMenu
              dropDirection="up"
              toggleTitle="Add Demographics, Interests, and Behaviors"
              options={options}
              selectedValues={transformSpecToOptions(targetSpec?.exclusions || {}).map(
                ({ value }) => value
              )}
              // @ts-ignore
              onSelectOption={addExcludedDemographic}
              // @ts-ignore
              onRemoveOption={removeExcludedDemographic}
            />
            <Box width="2em" />
            <SelectAsync
              placeholder="Search for custom interests..."
              onSelectOption={addExcludedDemographic}
              fetchOptions={fetchCustomInterestOptions}
            />
          </Box>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          {transformSpecToOptions(targetSpec?.exclusions || {}).map((option, index) => (
            <SelectItem
              key={index}
              title={option.label ?? ''}
              onClick={() => removeExcludedDemographic(option)}
            />
          ))}
        </div>
      </div>
    </>
  )
}
