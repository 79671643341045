import { hasDupes } from 'app/util'
import { dynamicContext } from './DynamicContext'
import React, { useContext, useState } from 'react'
import { creativeContext } from '../../../context/CreativeContext'
import { CreativeMediaType, FBCallToAction } from '@marketing-milk/interfaces'
import {
  CreativeMedia,
  defaultDynamicContextState,
  DynamicContextFunctionality,
  DynamicContextProps,
  DynamicContextState,
} from './DynamicContext.types'

export function DynamicContextProvider({ children }: DynamicContextProps) {
  const { creative } = useContext(creativeContext)

  const imageMedia: CreativeMedia[] =
    creative.dynamicConfig?.images?.map(_ => ({
      mediaID: _.id,
      mediaType: 'image',
    })) ?? []

  const videoMedia: CreativeMedia[] =
    creative.dynamicConfig?.videos?.map(_ => ({
      mediaID: _.id,
      mediaType: 'video',
    })) ?? []

  const [state, setState] = useState<DynamicContextState>({
    headlines: creative.dynamicConfig?.headlines ?? defaultDynamicContextState.headlines,
    descriptions: creative?.dynamicConfig?.descriptions ?? defaultDynamicContextState.descriptions,
    medias: [...imageMedia, ...videoMedia],
    primaryTexts: creative?.dynamicConfig?.primaryTexts ?? defaultDynamicContextState.primaryTexts,
    callToActions:
      creative?.dynamicConfig?.callToActions ?? defaultDynamicContextState.callToActions,
    websiteURL: creative?.linkWebsiteURL ?? defaultDynamicContextState.websiteURL,
    displayURL: creative?.linkDisplayURL ?? defaultDynamicContextState.displayURL,
  })

  // Saving Creative
  const canPublishCreative = () => {
    const mediaIDs = state.medias.map(media => media.mediaID)
    const partialLink = !!state.websiteURL && !!state.displayURL
    const partialHeadline =
      state.headlines.length > 0 &&
      state.headlines.every(headline => !!headline) &&
      !hasDupes(state.headlines)
    const partialDescription =
      state.descriptions.length > 0 &&
      state.descriptions.every(description => !!description) &&
      !hasDupes(state.descriptions)
    const partialPrimaryTexts =
      state.primaryTexts.length > 0 &&
      state.primaryTexts.every(primaryText => !!primaryText) &&
      !hasDupes(state.primaryTexts)
    const partialMedias =
      state.medias.length > 0 &&
      state.medias.every(media => !!media.mediaID && !!media.mediaType) &&
      !hasDupes(mediaIDs)
    const partialCallToAction = state.callToActions.length > 0 && !hasDupes(state.callToActions)
    return (
      partialLink &&
      partialHeadline &&
      partialDescription &&
      partialPrimaryTexts &&
      partialCallToAction &&
      partialMedias
    )
  }

  // Call to Action
  function addCallToAction() {
    setState(_ => ({
      ..._,
      callToActions: [..._.callToActions, FBCallToAction.ADD_TO_CART],
    }))
  }

  function setCallToAction(index: number, value: FBCallToAction) {
    setState(_ => {
      const newCallToActions = [..._.callToActions]
      newCallToActions[index] = value
      return {
        ..._,
        callToActions: newCallToActions,
      }
    })
  }

  function delCallToAction(index: number) {
    setState(_ => ({
      ..._,
      callToActions: _.callToActions.filter((c, i) => i !== index),
    }))
  }

  // Description
  function addDescription() {
    setState(_ => ({
      ..._,
      descriptions: [..._.descriptions, ''],
    }))
  }

  function setDescription(index: number, value: string) {
    setState(_ => {
      const newDescriptions = [..._.descriptions]
      newDescriptions[index] = value
      return {
        ..._,
        descriptions: newDescriptions,
      }
    })
  }

  function delDescription(index: number) {
    setState(_ => ({
      ..._,
      descriptions: _.descriptions.filter((c, i) => i !== index),
    }))
  }

  // Headline
  function addHeadline() {
    setState(_ => ({
      ..._,
      headlines: [..._.headlines, ''],
    }))
  }

  function setHeadline(index: number, value: string) {
    setState(_ => {
      const newHeadlines = [..._.headlines]
      newHeadlines[index] = value
      return {
        ..._,
        headlines: newHeadlines,
      }
    })
  }

  function delHeadline(index: number) {
    setState(_ => ({
      ..._,
      headlines: _.headlines.filter((c, i) => i !== index),
    }))
  }

  // Media
  function addMedia() {
    setState(_ => ({
      ..._,
      medias: [
        ..._.medias,
        {
          mediaID: undefined,
          mediaType: undefined,
        },
      ],
    }))
  }

  function setMedia(index: number, mediaID: number, mediaType: CreativeMediaType) {
    setState(_ => {
      const newMedias = [..._.medias]
      newMedias[index] = { mediaID, mediaType }
      return {
        ..._,
        medias: newMedias,
      }
    })
  }

  function delMedia(index: number) {
    setState(_ => ({
      ..._,
      medias: _.medias.filter((c, i) => i !== index),
    }))
  }

  // Primary Text

  function addPrimaryText() {
    setState(_ => ({
      ..._,
      primaryTexts: [..._.primaryTexts, ''],
    }))
  }

  function setPrimaryText(index: number, value: string) {
    setState(_ => {
      const newPrimaryTexts = [..._.primaryTexts]
      newPrimaryTexts[index] = value
      return {
        ..._,
        primaryTexts: newPrimaryTexts,
      }
    })
  }

  function delPrimaryText(index: number) {
    setState(_ => ({
      ..._,
      primaryTexts: _.primaryTexts.filter((c, i) => i !== index),
    }))
  }

  // Other
  function setWebsiteURL(websiteURL: string) {
    setState(_ => ({
      ..._,
      websiteURL,
    }))
  }

  function setDisplayURL(displayURL: string) {
    setState(_ => ({
      ..._,
      displayURL,
    }))
  }

  const functionality: DynamicContextFunctionality = {
    canPublishCreative,
    addCallToAction,
    setCallToAction,
    delCallToAction,
    addDescription,
    setDescription,
    delDescription,
    addHeadline,
    setHeadline,
    delHeadline,
    addMedia,
    setMedia,
    delMedia,
    addPrimaryText,
    setPrimaryText,
    delPrimaryText,
    setWebsiteURL,
    setDisplayURL,
  }

  return (
    <dynamicContext.Provider value={{ ...state, ...functionality }}>
      {children}
    </dynamicContext.Provider>
  )
}
