import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { FacebookLeadgenFormType } from '@marketing-milk/interfaces'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'

export interface FormTypeProps {
  editable: boolean
  formType?: FacebookLeadgenFormType
  onChangeFormType(formType: FacebookLeadgenFormType): void
}

export function FormType({ editable, formType, onChangeFormType }: FormTypeProps) {
  return (
    <div className="form-group">
      <InputLabel disabled={!editable} shrink className="text-bold">
        Form Type *
      </InputLabel>
      <ToggleButtonGroup
        value={formType}
        onChange={(event, type) => onChangeFormType(type)}
        exclusive
      >
        <ToggleButton disabled={!editable} size="small" value="more_volume">
          <GroupAddIcon fontSize="small" className="mr-2" />
          More Volume
        </ToggleButton>
        <ToggleButton disabled={!editable} size="small" value="higher_intent">
          <AddShoppingCartIcon fontSize="small" className="mr-2" />
          Higher Intent
        </ToggleButton>
      </ToggleButtonGroup>
      <Typography variant="body1" className="mt-2">
        {formType === 'more_volume'
          ? "Use a form that's quick to fill out and submit on a mobile device"
          : 'Add a review step that gives people a chance to confirm their info.'}
      </Typography>
    </div>
  )
}
