import React from 'react'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { LocalizationProvider as TimePickersLocalizationProvider } from '@mui/x-date-pickers'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker'
import { MMAlert } from 'components/Alert'
import { addYears, format } from 'date-fns'
import { MobileTimePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import { useGetBusiness } from '@marketing-milk/frontend'

type Props = {
  duration: [Date | null, Date | null]
  setDuration: (duration: [Date | null, Date | null]) => void
  disableStart: boolean
  startTime: Date
  setStartTime: (time: Date | null) => void
  endTime: Date
  setEndTime: (time: Date | null) => void
}

export const CampaignDurationField = ({
  duration,
  setDuration,
  disableStart,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}: Props) => {
  const business = useGetBusiness()

  return (
    <div className=" flex flex-wrap justify-between py-4 border-b">
      <p className="text-lg font-medium text-gray-600">Duration</p>
      <div className="">
        {disableStart && (
          <div className="pb-3">
            <MMAlert type={'info'}>
              Once a Campaign has started, you cannot change the start date.
            </MMAlert>
          </div>
        )}
        <div className="flex items-center w-full justify-evenly">
          <p className="p-3 border rounded-full text-gray-600">
            {duration[0] && format(new Date(duration[0]), 'PP')}
          </p>
          <p>to</p>
          <p className="p-3 border rounded-full text-gray-600">
            {duration[1] && format(new Date(duration[1]), 'PP')}
          </p>
        </div>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDateRangePicker
            startText="Campaign Start"
            endText="Campaign End"
            displayStaticWrapperAs="desktop"
            disablePast
            disableCloseOnSelect
            maxDate={addYears(new Date(), 1)}
            value={duration}
            onChange={setDuration}
            renderInput={() => <React.Fragment></React.Fragment>}
          />
        </LocalizationProvider>
        <TimePickersLocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="flex items-center w-full justify-evenly">
            <MobileTimePicker
              label="Start Time"
              value={startTime}
              onChange={setStartTime}
              renderInput={params => (
                <TextField {...params} helperText={`Timezone: ${business.timezone}`} />
              )}
            />
            <MobileTimePicker
              label="End Time"
              value={endTime}
              onChange={setEndTime}
              renderInput={params => (
                <TextField {...params} helperText={`Timezone: ${business.timezone}`} />
              )}
            />
          </div>
        </TimePickersLocalizationProvider>
      </div>
    </div>
  )
}
