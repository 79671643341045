import { FunnelStageWithOfflineEventCount } from '@marketing-milk/interfaces'
import { audienceBuilderService } from '@marketing-milk/frontend'
import { useCreateFetchHook } from '@marketing-milk/frontend'

export function useGetFunnelStagesAndOptions(
  businessID: string,
  refresh = 0
): FunnelStageWithOfflineEventCount[] | undefined {
  return useCreateFetchHook<FunnelStageWithOfflineEventCount[]>(
    () => audienceBuilderService.getFunnelListOptions(businessID),
    refresh
  )
}
