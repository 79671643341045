import { useInfiniteQuery } from 'react-query'
import { NestAPI } from '@marketing-milk/frontend'
import { AdCreative, AdCreativeType } from '@marketing-milk/interfaces'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { first, flatMap } from 'lodash'
import { add } from 'lodash/fp'
import { CreativeFilters } from './helpers/creative.helpers'

type DuplicateCreativePayload = {
  name: string
  sprintId?: number | null
}

type PaginationOptions = {
  page?: number
  take?: number
  searchProp?: string
  searchTerm?: string
  sortProp?: string
  sortDirection?: 'ASC' | 'DESC'
  filterProp?: string
  filterValues?: string
}

type CreativeResponse = { creatives: AdCreative[]; count: number; page: number }

// this creatives hooks retrieves them based upon a lot of parameters and is taken by page
export const useCreatives = (
  businessID: string,
  defaultPaginationProps?: Omit<PaginationOptions, 'filterProp' | 'filterValues' | 'page'>
) => {
  const [creatives, setCreatives] = useState<AdCreative[] | undefined>()
  const [searchTerm, setSearchTerm] = useState(defaultPaginationProps?.searchTerm ?? '')
  const [searchProp, setSearchProp] = useState(defaultPaginationProps?.searchProp ?? 'name')
  const [sortProp, setSortProp] = useState(defaultPaginationProps?.sortProp ?? 'id')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>(
    defaultPaginationProps?.sortDirection ?? 'DESC'
  )
  const [filterValues, setFilterValues] = useState<CreativeFilters | undefined>(undefined)
  const [count, setCount] = useState(0)
  const [isRefetchingCreatives, setIsRefetchingCreatives] = useState(false)
  const query = useInfiniteQuery<CreativeResponse>(
    ['creatives', businessID, sortDirection, sortProp, searchTerm, filterValues],
    async ({ pageParam = 1 }) =>
      await getCreatives({
        page: pageParam,
        take: defaultPaginationProps?.take ?? 100,
        searchTerm,
        searchProp,
        sortProp,
        sortDirection,
        // only supports BE filter for type (format on the backend)
        filterProp: filterValues?.type?.length ? 'format' : undefined,
        filterValues: filterValues?.type?.length ? filterValues.type.toString() : undefined,
      }),
    {
      // will only refresh on window focus when stale
      refetchOnWindowFocus: true,
      // stale time of 5 mins
      staleTime: 5 * 60 * 1000,
      getNextPageParam: lastPage => lastPage.page + 1,
    }
  )

  const { data, refetch } = query

  const refetchCreatives = async () => {
    setIsRefetchingCreatives(true)
    await refetch()
    setIsRefetchingCreatives(false)
  }

  useEffect(() => {
    if (!data?.pages) return
    setCount(first(flatMap(data.pages, page => page.count)) ?? 0)
    setCreatives(flatMap(data.pages, page => page.creatives))
  }, [data?.pages])

  const getCreatives = async (params: PaginationOptions): Promise<CreativeResponse> => {
    const response = await NestAPI.get<CreativeResponse>(`businesses/${businessID}/ad-creatives`, {
      params,
    })
    return response.data
  }

  const duplicateCreative = async (creativeID: number, overrides: DuplicateCreativePayload) => {
    const response = await NestAPI.post(
      `businesses/${businessID}/ad-creatives/${creativeID}/duplicate`,
      overrides
    )
    refetchCreatives()
    return response.data
  }

  const createCreative = async (name: string, type: string, overrides?: Partial<AdCreative>) => {
    const response = await NestAPI.post<AdCreative>(
      `businesses/${businessID}/ad-creatives/create`,
      { name, type, overrides }
    )
    refetchCreatives()

    return response.data
  }

  const scaffoldCreatives = async (
    scaffoldData: Array<{ name: string; type: AdCreativeType; overrides?: Partial<AdCreative> }>
  ) =>
    await Promise.all(
      scaffoldData.map(data =>
        createCreative(data.name, data.type, data.overrides)
          .then(_ => {
            toast.success(`${data.name} creative has been added`)
          })
          .catch(err => toast.error(`scaffold failed for ${data.name}. ${err}`))
      )
    )

  const shareManyCreatives = (businessIds: number[], creatives: AdCreative[]) => {
    Promise.all(
      businessIds.map(businessToShareId =>
        Promise.all(
          creatives.map(creative =>
            NestAPI.post(`businesses/${businessID}/ad-creatives/${creative.id}/share`, {
              businessID: businessToShareId,
            })
              .then(res => toast.success(`${creative.name} has been shared`))
              .catch(err =>
                toast.warn(
                  `${creative.name} error. It is possible that this worked in MM, but facebook had issues.  ${err.message}`
                )
              )
          )
        )
      )
    )

    refetchCreatives()
  }

  const duplicateManyCreatives = (creatives: AdCreative[], overrides?: DuplicateCreativePayload) =>
    Promise.all(
      creatives.map(creative =>
        NestAPI.post<AdCreative>(`businesses/${businessID}/ad-creatives/${creative.id}/duplicate`, {
          name: overrides?.name || creative.name,
          type: creative.format,
          sprintId: overrides?.sprintId,
        })
          .then(res => res.data)
          .catch(err => toast.error(`${err?.message ? err.message : err}`))
      )
    )

  return {
    creatives,
    count,
    current: data?.pages.map(page => page.creatives.length).reduce(add),
    setSearchTerm,
    searchTerm,
    setSearchProp,
    searchProp,
    setSortDirection,
    sortDirection,
    setSortProp,
    sortProp,
    filterValues,
    setFilterValues,
    duplicateCreative,
    duplicateManyCreatives,
    createCreative,
    scaffoldCreatives,
    shareManyCreatives,
    isRefetchingCreatives,
    ...query,
  }
}
