import React from 'react'
import { uniq } from 'lodash'
import Link from '@material-ui/core/Link'
import Alert from '@material-ui/lab/Alert'
import { screen } from '@testing-library/react'
import userEvent from '@testing-library/user-event'

//////////////////////////
// Global Testing Utils //
//////////////////////////

export const updateInput = (input: HTMLInputElement, additionalText: string) =>
  updateFieldValue(input, additionalText)
export const updateFieldValue = (input: HTMLInputElement, additionalText: string) => {
  userEvent.clear(input)
  userEvent.type(input, additionalText)
}
export const checkInputValue = (input: HTMLInputElement, value: string) => {
  expect(input.value).toEqual(value)
}

////////////////
// Form Steps //
////////////////

export const getFormSettingsStep = () => screen.getByTestId('form-settings-step')
export const getFormCompletionStep = () => screen.getByTestId('form-completion-step')
export const getFormQuestionsStep = () => screen.getByTestId('form-questions-step')
export const getFormPrivacyStep = () => screen.getByTestId('form-privacy-step')
export const getFormGreetingStep = () => screen.getByTestId('form-greeting-step')
export const getFormMeta = () => screen.getByTestId('form-meta')

export const clickIntroTab = () => userEvent.click(screen.getByText('Intro'))
export const clickQuestionsTab = () => userEvent.click(screen.getByText('Questions *'))
export const clickPrivacyTab = () => userEvent.click(screen.getByText('Privacy *'))
export const clickCompletionTab = () => userEvent.click(screen.getByText('Completion *'))
export const clickSettingsTab = () => userEvent.click(screen.getByText('Settings'))

//////////////
// Settings //
//////////////

// tracking params
export const getAllTrackingParams = () => screen.getAllByTestId('tracking-param-card')
export const getSaveTrackingParamButton = () => screen.getByTestId('save-param-button')
export const saveTrackingParam = () => userEvent.click(getSaveTrackingParamButton())
export const getDeleteTrackingParamButton = () => screen.getByTestId('delete-param-button')
export const deleteTrackingParam = () => userEvent.click(getDeleteTrackingParamButton())
export const clickAddTrackingParam = () => userEvent.click(screen.getByText(/add parameter/i))

export const getParamNameInput = () =>
  screen.getByTestId('tracking-param-name-input') as HTMLInputElement
export const getParamValueInput = () =>
  screen.getByTestId('tracking-param-value-input') as HTMLInputElement

export const fillOutParamCard = (name: string, value: string) => {
  updateInput(getParamNameInput(), name)
  updateInput(getParamValueInput(), value)
}
export const addTrackingParam = (name: string, value: string) => {
  clickAddTrackingParam()
  fillOutParamCard(name, value)
}
export const checkForTrackingParams = () => screen.queryByTestId('tracking-param-card')

// sharing type
export const getRestrictedSharingType = () =>
  screen.getByRole('button', {
    name: /restricted/i,
  })
export const selectRestrictedSharingType = () => userEvent.click(getRestrictedSharingType())
export const getOpenSharingType = () =>
  screen.getByRole('button', {
    name: /open/i,
  })
export const selectOpenSharingType = () => userEvent.click(getOpenSharingType())

// language type
export const getLanguageSelect = () => {
  screen.getByRole('button', {
    name: /language select/i,
  })
}
export const openLanguageSelect = () => {
  userEvent.click(
    screen.getByRole('button', {
      name: /language select/i,
    })
  )
}
export const selectNewLanguage = () => {
  openLanguageSelect()
  userEvent.click(
    screen.getByRole('option', {
      name: /it_it/i,
    })
  )
}

////////////////
// Completion //
////////////////

export const getCompletionLinkInput = () =>
  screen.getByTestId('completion-link-input') as HTMLInputElement
export const updateCompletionLinkInput = (additionalText: string) =>
  updateInput(getCompletionLinkInput(), additionalText)

export const getCompletionHeadlineInput = () =>
  screen.getByTestId('completion-headline-input') as HTMLInputElement
export const updateCompletionHeadlineInput = (additionalText: string) =>
  updateInput(getCompletionHeadlineInput(), additionalText)

export const getCompletionDescriptionInput = () =>
  screen.getByTestId('completion-description-input') as HTMLInputElement
export const updateCompletionDescriptionInput = (additionalText: string) =>
  updateInput(getCompletionDescriptionInput(), additionalText)

export const getCompletionCTATextInput = () =>
  screen.getByTestId('completion-cta-text-input') as HTMLInputElement
export const updateCompletionCTATextInput = (additionalText: string) =>
  updateInput(getCompletionCTATextInput(), additionalText)

export const getCTASelect = () => {
  screen.getByRole('button', {
    name: /completion cta select/i,
  })
}
export const openCTASelect = () => {
  userEvent.click(
    screen.getByRole('button', {
      name: /completion cta select/i,
    })
  )
}
export const selectNewCTA = () => {
  openCTASelect()
  userEvent.click(
    screen.getByRole('option', {
      name: /download/i,
    })
  )
}

//////////////
// Greeting //
//////////////

export const getGreetingHeadlineInput = () =>
  screen.getByTestId('greeting-headline-input') as HTMLInputElement
export const updateGreetingHeadlineInput = (additionalText: string) =>
  updateInput(getGreetingHeadlineInput(), additionalText)

export const getGreetingParagraphInput = () =>
  screen.getByTestId('greeting-paragraph-input') as HTMLInputElement
export const updateGreetingParagraphInput = (additionalText: string) =>
  updateInput(getGreetingParagraphInput(), additionalText)

export const getGreetingListInputs = () =>
  screen.getAllByTestId('greeting-list-input') as HTMLInputElement[]
export const updateGreetingListInput = (index: number, additionalText: string) =>
  updateInput(getGreetingListInputs()[index], additionalText)

export const getRemoveGreetingButton = () =>
  screen.getByTestId('delete-greeting-button') as HTMLButtonElement
export const clickRemoveGreeting = () => userEvent.click(getRemoveGreetingButton())

export const getParagraphLayoutType = () =>
  screen.getByRole('button', {
    name: /paragraph/i,
  })
export const selectParagraphLayoutType = () => userEvent.click(getParagraphLayoutType())
export const getListLayoutType = () =>
  screen.getByRole('button', {
    name: /list/i,
  })
export const selectListLayoutType = () => userEvent.click(getListLayoutType())

/////////////
// Privacy //
/////////////

export const getPrivacyTextInput = () =>
  screen.getByTestId('privacy-text-input') as HTMLInputElement
export const updatePrivacyTextInput = (additionalText: string) =>
  updateInput(getPrivacyTextInput(), additionalText)

export const getPrivacyLinkInput = () =>
  screen.getByTestId('privacy-link-input') as HTMLInputElement
export const updatePrivacyLinkInput = (additionalText: string) =>
  updateInput(getPrivacyLinkInput(), additionalText)

export const getDisclaimerTitleInput = () =>
  screen.getByTestId('custom-disclaimer-title-input') as HTMLInputElement
export const updateDisclaimerTitleInput = (additionalText: string) =>
  updateInput(getDisclaimerTitleInput(), additionalText)

export const getDisclaimerBodyInput = () =>
  screen.getByTestId('custom-disclaimer-body-input') as HTMLInputElement
export const updateDisclaimerBodyInput = (additionalText: string) =>
  updateInput(getDisclaimerBodyInput(), additionalText)

///////////////
// Meta Info //
///////////////

export const getFormNameInput = () => screen.getByTestId('form-name-input') as HTMLInputElement
export const updateFormNameInput = (additionalText: string) =>
  updateInput(getFormNameInput(), additionalText)

export const getMoreVolumeFormType = () =>
  screen.getByRole('button', {
    name: /more volume/i,
  })
export const selectMoreVolumeType = () => userEvent.click(getMoreVolumeFormType())
export const getHigherIntentFormType = () =>
  screen.getByRole('button', {
    name: /higher intent/i,
  })
export const selectHigherIntentType = () => userEvent.click(getHigherIntentFormType())

export const getCreativeMediaType = () =>
  screen.getByRole('button', {
    name: /creative image/i,
  })
export const selectCreativeMediaType = () => userEvent.click(getCreativeMediaType())
export const getUploadedMediaType = () =>
  screen.getByRole('button', {
    name: /uploaded image/i,
  })
export const selectUploadedMediaType = () => userEvent.click(getUploadedMediaType())

///////////////
// Questions //
///////////////

export const updateQuestionInput = (additionalText: string) =>
  updateInput(getQuestionInput(), additionalText)
export const getQuestionInput = () =>
  screen.getByTestId('custom-question-input') as HTMLInputElement
export const getDeleteIcon = () => screen.getByTestId('delete-icon')
export const getKeyModalToggle = () => screen.getByTestId('key-modal-toggle')
export const getKeyInput = () => screen.getByTestId('custom-question-key') as HTMLInputElement
export const getPrefillSelect = () =>
  screen.getByRole('button', {
    name: /prefill question select/i,
  })

export const clickDelete = () => userEvent.click(getDeleteIcon())
export const openKeyModal = () => userEvent.click(getKeyModalToggle())

export const updateKeyValue = (additionalText: string) => {
  openKeyModal()
  updateFieldValue(getKeyInput(), additionalText)
}
export const openPrefillSelect = () =>
  userEvent.click(
    screen.getByRole('button', {
      name: /prefill question select/i,
    })
  )
export const selectNewPrefillOption = () => {
  openPrefillSelect()
  userEvent.click(
    screen.getByRole('option', {
      name: /first name/i,
    })
  )
}

export const getAddQuestionButton = () => screen.getByTestId('add-question-button')
export const clickAddQuestion = () => userEvent.click(getAddQuestionButton())
export const getAddPrefillQuestionButton = () => screen.getByTestId('add-prefill-question')
export const clickPrefillQuestion = () => userEvent.click(getAddPrefillQuestionButton())
export const getAddShortAnswerQuestionButton = () => screen.getByTestId('add-short-answer-question')
export const clickShortAnswerQuestion = () => userEvent.click(getAddShortAnswerQuestionButton())
export const getAddMultiChoiceQuestionButton = () => screen.getByTestId('add-multi-choice-question')
export const clickMultiChoiceQuestion = () => userEvent.click(getAddMultiChoiceQuestionButton())

// Question Alerts
export type QuestionAlertProps = { questionCount: number }
export function QuestionsAlert({ questionCount }: QuestionAlertProps) {
  return questionCount === 0 ? (
    <Alert variant="standard" severity="info" className="mb-2">
      You haven't included any questions for this lead card yet.
    </Alert>
  ) : questionCount > 5 ? (
    <Alert variant="standard" severity="warning" className="mb-2">
      <strong>Adding too many questions may impact your form submission rate.</strong> Only ask for
      the information you need. You can use targeting to reach a more specific audience, which can
      improve the quality of your leads.{' '}
      <Link href="https://www.facebook.com/business/help/717368264947302" target="_blank">
        Learn More
      </Link>
    </Alert>
  ) : null
}

// Prefill Question Options
export interface PrefilledQuestion {
  label: string
  category: string
}

export type FBPrefillQuestion =
  | 'FULL_NAME'
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'CITY'
  | 'COMPANY_NAME'
  | 'COUNTRY'
  | 'DOB'
  | 'EMAIL'
  | 'GENDER'
  | 'JOB_TITLE'
  | 'MARITAL_STATUS'
  | 'PHONE'
  | 'POST_CODE'
  | 'PROVINCE'
  | 'RELATIONSHIP_STATUS'
  | 'STATE'
  | 'STREET_ADDRESS'
  | 'ZIP'
  | 'WORK_EMAIL'
  | 'MILITARY_STATUS'
  | 'WORK_PHONE_NUMBER'

export const predefinedQuestionWire: Record<FBPrefillQuestion, PrefilledQuestion> = {
  FIRST_NAME: {
    label: 'First Name',
    category: 'Contact Fields',
  },
  LAST_NAME: {
    label: 'Last Name',
    category: 'Contact Fields',
  },
  FULL_NAME: {
    label: 'Full Name',
    category: 'Contact Fields',
  },
  CITY: {
    label: 'City',
    category: 'Contact Fields',
  },
  COMPANY_NAME: {
    label: 'Company Name',
    category: 'Work Information',
  },
  COUNTRY: {
    label: 'Country',
    category: 'Contact Fields',
  },
  DOB: {
    label: 'Date of Birth',
    category: 'Demographic Questions',
  },
  EMAIL: {
    label: 'Email',
    category: 'Contact Fields',
  },
  GENDER: {
    label: 'Gender',
    category: 'Demographic Questions',
  },
  JOB_TITLE: {
    label: 'Job Title',
    category: 'Work Information',
  },
  MARITAL_STATUS: {
    label: 'Marital Status',
    category: 'Demographic Questions',
  },
  PHONE: {
    label: 'Phone Number',
    category: 'Contact Fields',
  },
  POST_CODE: {
    label: 'Post Code',
    category: 'Contact Fields',
  },
  PROVINCE: {
    label: 'Province',
    category: 'Contact Fields',
  },
  RELATIONSHIP_STATUS: {
    label: 'Relationship Status',
    category: 'Demographic Questions',
  },
  STATE: {
    label: 'State',
    category: 'Contact Fields',
  },
  STREET_ADDRESS: {
    label: 'Street Address',
    category: 'Contact Fields',
  },
  ZIP: {
    label: 'Zip Code',
    category: 'Contact Fields',
  },
  WORK_EMAIL: {
    label: 'Work Email',
    category: 'Work Information',
  },
  MILITARY_STATUS: {
    label: 'Military Status',
    category: 'Demographic Questions',
  },
  WORK_PHONE_NUMBER: {
    label: 'Work Phone Number',
    category: 'Work Information',
  },
}

export const categories = uniq(Object.entries(predefinedQuestionWire).map(_ => _[1].category))
export const categoryOptions = categories.map(category => {
  const nestedOptions = Object.entries(predefinedQuestionWire).filter(
    entry => entry[1].category === category
  )
  return {
    label: category,
    options: nestedOptions.map(_ => ({
      label: _[1].label,
      value: _[0],
    })),
  }
})
