import React from 'react'
import { QuestionsAlert } from './const'
import { PrefillQuestion } from './PrefillQuestion'
import Typography from '@material-ui/core/Typography'
import { AddQuestionDropdown } from './AddQuestionDropdown'
import { ShortAnswerQuestion } from './ShortAnswerQuestion'
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion'
import { SortableList } from 'components/sortable-list/SortableList'
import { LeadCardContextFunctionality } from '../../context/LeadCardContext.types'
import { QuestionsDescription } from './QuestionsDescription'

export type FormQuestionsProps = {
  editable: boolean
  questionDescription?: string
} & Pick<
  LeadCardContextFunctionality,
  | 'updateCustomQuestionKey'
  | 'updateCustomQuestion'
  | 'deleteQuestion'
  | 'updatePrefillQuestionType'
  | 'deleteMultiChoiceOption'
  | 'updateMultiChoiceOption'
  | 'addMultiChoiceOption'
  | 'updateMultiChoiceOptionKey'
  | 'addPrefillQuestion'
  | 'addShortAnswerQuestion'
  | 'addMultiChoiceQuestion'
  | 'getPrefillQuestions'
  | 'getPrefillQuestionIndex'
  | 'getCustomQuestionIndex'
  | 'getCustomQuestions'
  | 'sortCustomQuestions'
  | 'sortPrefillQuestions'
  | 'updateQuestionDescription'
  | 'getAvailablePrefillQuestions'
>

export function FormQuestions(props: FormQuestionsProps) {
  const {
    editable,
    questionDescription,
    updateCustomQuestion,
    updateCustomQuestionKey,
    deleteQuestion,
    updatePrefillQuestionType,
    deleteMultiChoiceOption,
    updateMultiChoiceOption,
    addMultiChoiceOption,
    updateMultiChoiceOptionKey,
    addPrefillQuestion,
    addShortAnswerQuestion,
    addMultiChoiceQuestion,
    getPrefillQuestions,
    getPrefillQuestionIndex,
    getCustomQuestionIndex,
    getCustomQuestions,
    sortCustomQuestions,
    sortPrefillQuestions,
    updateQuestionDescription,
    getAvailablePrefillQuestions,
  } = props

  return (
    <div data-testid="form-questions-step">
      <div className="d-flex">
        <div className="center-vertical">
          <Typography className="m-0" variant="h5">
            Questions
          </Typography>
          <p>Collect relevant information from your users by asking questions.</p>
        </div>
        <div className="center-vertical" style={{ flex: 'none' }}>
          <AddQuestionDropdown
            onAddPrefillQuestion={addPrefillQuestion}
            onAddShortAnswerQuestion={addShortAnswerQuestion}
            onAddMultipleChoiceQuestion={addMultiChoiceQuestion}
            editable={editable}
          />
        </div>
      </div>
      <hr className="my-3" />
      <QuestionsDescription
        editable={editable}
        description={questionDescription}
        onChangeDescription={updateQuestionDescription}
      />
      <hr className="my-3" />
      <QuestionsAlert questionCount={getPrefillQuestions().length + getCustomQuestions().length} />
      <SortableList values={getCustomQuestions()} onChange={sortCustomQuestions}>
        {getCustomQuestions().map((_, i) => {
          const fullIndex = getCustomQuestionIndex(i)
          return Array.isArray(_.options) ? (
            <MultipleChoiceQuestion
              question={_}
              index={fullIndex}
              editable={editable}
              updateCustomQuestionKey={updateCustomQuestionKey}
              updateCustomQuestion={updateCustomQuestion}
              deleteQuestion={deleteQuestion}
              deleteMultiChoiceOption={deleteMultiChoiceOption}
              updateMultiChoiceOption={updateMultiChoiceOption}
              addMultiChoiceOption={addMultiChoiceOption}
              updateMultiChoiceOptionKey={updateMultiChoiceOptionKey}
            />
          ) : (
            <ShortAnswerQuestion
              question={_}
              index={fullIndex}
              editable={editable}
              updateCustomQuestionKey={updateCustomQuestionKey}
              updateCustomQuestion={updateCustomQuestion}
              deleteQuestion={deleteQuestion}
            />
          )
        })}
      </SortableList>
      <SortableList values={getPrefillQuestions()} onChange={sortPrefillQuestions}>
        {getPrefillQuestions().map((_, i) => (
          <div className="cursor-pointer" key={`prefill_${i}`}>
            <PrefillQuestion
              editable={editable}
              question={_}
              updatePrefillQuestionType={updatePrefillQuestionType}
              index={getPrefillQuestionIndex(i)}
              deleteQuestion={deleteQuestion}
              availableQuestions={getAvailablePrefillQuestions()}
            />
          </div>
        ))}
      </SortableList>
    </div>
  )
}
