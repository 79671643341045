import React from 'react'
import Select from 'react-select'
import { Slider } from '@marketing-milk/frontend'
import {
  InclusionExclusion,
  InclusionExclusionURLMenuOption,
  StandardPixelEvent,
} from '@marketing-milk/interfaces'
import { PixelEventTypes } from './'

export interface RuleBlockProps {
  type: 'Include' | 'Exclude'
  ruleSettings?: InclusionExclusion
  updateRuleSettings: (changes: Partial<InclusionExclusion>) => void
  urlMenuOptions: { label: string; value: InclusionExclusionURLMenuOption }[]
  pixelMenuOptions: { label: string; value: PixelEventTypes }[]
}

export function RuleBlock(props: RuleBlockProps) {
  const { ruleSettings, updateRuleSettings, urlMenuOptions, pixelMenuOptions, type } = props

  const categoryValue: any = ruleSettings?.menuSelection
    ? pixelMenuOptions.filter((x: any) => x.value === ruleSettings.menuSelection)
    : null

  const urlValue: any = ruleSettings?.visitorsByURLOptions?.menuSelection
    ? urlMenuOptions.filter(
        (x: any) => x.value === ruleSettings?.visitorsByURLOptions?.menuSelection
      )
    : null

  function selectUrlRule(option: { label: string; value: InclusionExclusionURLMenuOption }) {
    const urlOptions = {
      url: ruleSettings?.visitorsByURLOptions?.url ?? '',
      menuSelection: option.value,
    }
    updateRuleSettings({
      visitorsByURLOptions: urlOptions,
    })
  }

  function selectCategory(option: { label: StandardPixelEvent; value: PixelEventTypes }) {
    updateRuleSettings({
      menuSelection: option.value,
    })
  }

  function updateUrl(url: string) {
    updateRuleSettings({
      visitorsByURLOptions: {
        menuSelection: ruleSettings?.visitorsByURLOptions?.menuSelection ?? 'eq',
        url: url,
      },
    })
  }

  function updateRetention(retention: number) {
    updateRuleSettings({
      pixelRetention: retention,
    })
  }

  return (
    <div
      className="card box-shadow border-radius d-inline-block mx-auto"
      style={{ maxWidth: '600px' }}
    >
      <div className={`card-header ${type === 'Exclude' ? 'bg-danger' : 'bg-success'}`}>
        <h5 className="text-center mb-0 text-white">{type}</h5>
      </div>
      <div className="card-body">
        <p className="text-bold text-center">
          Target website visitors by the following category...
        </p>
        <Select
          placeholder="Choose a pixel event or category"
          options={pixelMenuOptions}
          value={categoryValue}
          onChange={selectCategory as any}
        />
        {ruleSettings?.menuSelection === 'VISITORS_BY_URL' && (
          <>
            <p className="text-bold text-center mt-4">Where the URL they visited...</p>
            <div className="d-block w-100 mb-2">
              <Select
                className="w-100"
                options={urlMenuOptions}
                value={urlValue}
                onChange={selectUrlRule as any}
              />
            </div>
            <input
              className="w-100 border border-radius url-input"
              type="text"
              value={ruleSettings?.visitorsByURLOptions?.url}
              placeholder={
                ruleSettings?.visitorsByURLOptions?.menuSelection === 'eq'
                  ? 'example.com/example'
                  : '/example'
              }
              onChange={e => updateUrl(e.target.value)}
            />
          </>
        )}
        <p className="text-bold text-center mt-4">In the past...</p>
        <Slider
          defaultValue={ruleSettings?.pixelRetention ?? 145}
          min={1}
          max={180}
          onChangeValue={updateRetention}
          minLabel="Day"
          maxLabel="Days"
        />
        <h4 className="text-center">{ruleSettings?.pixelRetention} Days</h4>
      </div>
    </div>
  )
}
