import React, { useEffect, useState } from 'react'
import { creativeContext } from './CreativeContext'
import { BasicAdPreview } from '../ad-preview/AdPreview.type'
import { AdCreative, AdCreativeType, AdImage, AdVideo } from '@marketing-milk/interfaces'
import {
  adImageService,
  adVideoService,
  Loading,
  NestAPI,
  useGetBusinessID,
} from '@marketing-milk/frontend'
import {
  CreativeContextFunctionality,
  CreativeContextProps,
  CreativeContextState,
  defaultCreativeContextState,
} from './CreativeContext.types'
import { useQuery } from 'react-query'

export function CreativeContextProvider({ children, defaultCreative }: CreativeContextProps) {
  const businessID = useGetBusinessID()
  const [images, setImages] = useState<AdImage[]>([])
  const [videos, setVideos] = useState<AdVideo[]>([])

  useEffect(() => {
    NestAPI.get<AdImage[]>(`businesses/${businessID}/ad-images`).then(({ data }) => setImages(data))
  }, [])

  useEffect(() => {
    NestAPI.get<AdVideo[]>(`businesses/${businessID}/ad-videos`).then(({ data }) => setVideos(data))
  }, [])

  const [state, setState] = useState<CreativeContextState>({
    ...defaultCreativeContextState,
    name: defaultCreative?.name ?? '',
    creative: defaultCreative,
    format: defaultCreative?.format ?? AdCreativeType.single_image,
  })

  function upsertState(changes: Partial<CreativeContextState>): void {
    setState(_ => ({
      ..._,
      ...changes,
    }))
  }

  function setName(name: string) {
    upsertState({ name })
  }

  function setFormat(format: AdCreativeType) {
    upsertState({ format })
  }

  function setCreative(creative: AdCreative) {
    upsertState({ creative })
  }

  function setPreview(preview: BasicAdPreview) {
    upsertState({ preview })
  }

  function setPreviewsToGenerate(previewsToGenerate: number) {
    upsertState({ previewsToGenerate })
  }

  function addImage(image: AdImage) {
    setImages(_ => [..._!, image])
  }

  function addVideo(video: AdVideo) {
    setVideos(_ => [..._!, video])
  }

  const functionality: CreativeContextFunctionality = {
    images,
    videos,
    addImage,
    addVideo,
    setName,
    setFormat,
    setCreative,
    setPreview,
    setPreviewsToGenerate,
  }

  return (
    <creativeContext.Provider value={{ ...state, ...functionality }}>
      {children}
    </creativeContext.Provider>
  )
}
