import { SavedAudienceStatus } from '@marketing-milk/interfaces'

export const audienceStatusStyles: Record<
  SavedAudienceStatus,
  { text: string; textClass: string; backgroundClass: string }
> = {
  [SavedAudienceStatus.Processing]: {
    text: 'Populating',
    textClass: 'text-drive',
    backgroundClass: 'bg-drive faded',
  },
  [SavedAudienceStatus.Active]: {
    text: 'Active',
    textClass: 'text-success',
    backgroundClass: 'bg-success faded',
  },
  // @ts-ignore - the interfaces lib is out of whack at the moment
  PENDING: {
    text: 'Pending',
    textClass: 'text-drive',
    backgroundClass: 'bg-drive faded',
  },
}

export interface AudienceStatusIndicatorProps {
  status: SavedAudienceStatus
  className?: string
}
