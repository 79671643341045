import React, { useState } from 'react'
import { checkValidUrl } from 'app/util'
import Link from '@material-ui/core/Link'
import Alert from '@material-ui/lab/Alert'
import TextField from '@material-ui/core/TextField'

export interface CompletionLinkProps {
  editable: boolean
  link?: string
  onChangeLink(link: string): void
}

export function CompletionLink({ editable, link, onChangeLink }: CompletionLinkProps) {
  const [linkInfo, setLinkInfo] = useState(false)
  const validatedUrl = link && checkValidUrl(link)
  return (
    <div className="form-group">
      <TextField
        error={!validatedUrl && editable}
        helperText={
          !validatedUrl &&
          editable &&
          'Must be a valid web address. Be sure to include http:// or https://'
        }
        label="Link *"
        fullWidth
        disabled={!editable}
        value={link}
        onChange={event => onChangeLink(event.target.value as string)}
        onFocus={() => setLinkInfo(true)}
        onBlur={() => setLinkInfo(false)}
        InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
        inputProps={{ 'data-testid': 'completion-link-input' }}
        placeholder="https://mywebsite.com"
      />
      {linkInfo && (
        <Alert variant="standard" severity="info" className="mb-2">
          The link you use in completion messages can't go directly to a PDF file, JPEG file, image
          or download.{' '}
          <Link
            href="https://www.facebook.com/policies/ads/prohibited_content/non_functional_landing_page"
            target="_blank"
          >
            Learn More
          </Link>
        </Alert>
      )}
    </div>
  )
}
