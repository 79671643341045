import React from 'react'
import { ReactSortable } from 'react-sortablejs'
import { SortableListProps } from './SortableList.types'

export function SortableList({ children, values, onChange }: SortableListProps) {
  const valuesWithID = values.map((_, i) => ({
    ..._,
    // @ts-ignore - object may have an id
    id: _.id ?? i,
  }))

  return (
    <ReactSortable list={valuesWithID} setList={onChange}>
      {children}
    </ReactSortable>
  )
}
