import { makeStyles } from '@material-ui/core'

export const usePlatformPlacementStyles = makeStyles({
  platformPlacements: {
    margin: '',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  placementOptions: {
    padding: '20px',
  },
  possiblePlatforms: {
    borderTop: '1px solid #eee',
    padding: '20px',
  },
  platformCheckboxes: {
    display: 'grid',
    gridTemplateColumns: '25% 25%',
    margin: '20px 0',
  },
})
