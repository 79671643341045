import React from 'react'
import { set } from 'lodash/fp'
import ReactSelect from 'react-select'
import { CreativeFilters } from '../../helpers/creative.helpers'
import { SearchInput } from 'components'
import { FullPageSidebar } from 'components/layout/FullPageLayout/Sidebar/FullPageSidebar'
import SortIcon from '@mui/icons-material/Sort'
import { SidebarSection } from 'components/layout/FullPageLayout/Sidebar/SidebarSection'
import { SelectCreativeType } from 'components/FormFields/Select/SelectCreativeType'

interface Props {
  searchTerm: string
  setSearchTerm: SetState<string>
  sortDirection: 'ASC' | 'DESC'
  setSortDirection: SetState<'ASC' | 'DESC'>
  filters: CreativeFilters
  setFilters: SetState<CreativeFilters | undefined>
  setSort: SetState<string>
}

const options = [
  {
    label: 'Last Used',
    value: 'lastUsed',
  },
  {
    label: 'Name',
    value: 'name',
  },
]

export const CreativePageSidebar = (props: Props) => {
  return (
    <FullPageSidebar>
      {/* Search */}
      <SidebarSection title="Search Creatives">
        <SearchInput placeholder="Search Creatives" onSearch={props.setSearchTerm} />
      </SidebarSection>

      {/* Sorting */}
      <SidebarSection title="Sort Creatives">
        <span className="flex">
          <div className="flex-1">
            <ReactSelect
              defaultValue={options.find(option => option.value === 'lastUsed')}
              options={options}
              onChange={newValue => newValue && props.setSort(newValue.value)}
              openMenuOnClick
            />
          </div>
          <button
            type="button"
            onClick={() =>
              props.setSortDirection(direction => (direction === 'ASC' ? 'DESC' : 'ASC'))
            }
            className="-ml-px flex-shrink-0 relative inline-flex items-center space-x-2 px-2 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <SortIcon className="text-gray-400 w-5 h-5" />

            <span>{props.sortDirection}</span>
          </button>
        </span>
      </SidebarSection>

      {/* <SidebarSection title="Filter By Status">
        <SelectCreativeStatus
          placeholder="Filter By Status"
          onSelectStatus={statuses => props.setFilters(set('status', statuses))}
        />
      </SidebarSection> */}

      <SidebarSection title="Filter By Type">
        <SelectCreativeType
          placeholder="Filter By Type"
          onSelectType={types => props.setFilters(set('type', types))}
        />
      </SidebarSection>
    </FullPageSidebar>
  )
}

export default CreativePageSidebar
