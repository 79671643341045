import { partition } from 'lodash'
import * as Papa from 'papaparse'
import { StepHeader } from '../StepHeader'
import { Dropzone } from '@marketing-milk/frontend'
import React, { useEffect, useReducer } from 'react'
import { green, red } from '@material-ui/core/colors'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { CheckCircleOutline, Check, Close } from '@material-ui/icons'
import { Button, CircularProgress, IconButton, TextField } from '@material-ui/core'
import {
  CustomerFileCSVData,
  CustomerFileValidationError,
  FacebookTargetingSpec,
  isMatchKey,
  MatchKeys,
} from '@marketing-milk/interfaces'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Chip from '@mui/material/Chip'
import Alert from '@material-ui/lab/Alert'

// PROPS
type Props = {
  handleValidData: (csvData: CustomerFileCSVData) => void
  validateData: (csvData: CustomerFileCSVData) => Promise<CustomerFileValidationError[]>
  targetSpec: FacebookTargetingSpec
}

// STATE
type StateMode =
  | 'DragNDrop'
  | 'ParseError'
  | 'Validating'
  | 'ValidationErrors'
  | 'ValidationNetworkError'
  | 'Validated'
type State = {
  mode: StateMode
  errMsg?: string
  csvData?: CustomerFileCSVData
  validationErrors?: CustomerFileValidationError[]
  validationNetworkError?: string
}
const initialState: State = { mode: 'DragNDrop' }

// ACTIONS
type FieldChanges = {
  column: MatchKeys
  row: number
  value: string
}

type Action =
  | {
      type: 'PARSE_FAILED'
      payload: string
    }
  | {
      type: 'VALIDATE_CSV_DATA'
      payload: CustomerFileCSVData
    }
  | {
      type: 'RETRY_UPLOAD'
    }
  | {
      type: 'VALIDATION_FAILED'
      payload: CustomerFileValidationError[]
    }
  | {
      type: 'VALIDATION_NETWORK_ERROR'
      payload: string
    }
  | {
      type: 'VALIDATION_SUCCESS'
    }
  | {
      type: 'EDIT_FIELD'
      payload: FieldChanges
    }
  | {
      type: 'ACCEPT_ROW'
      payload: number
    }
  | {
      type: 'DISCARD_ROW'
      payload: number
    }

// REDUCER
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'PARSE_FAILED': {
      return { mode: 'ParseError', errMsg: action.payload }
    }
    case 'VALIDATE_CSV_DATA': {
      return { mode: 'Validating', csvData: action.payload }
    }
    case 'RETRY_UPLOAD': {
      return { mode: 'DragNDrop' }
    }
    case 'VALIDATION_FAILED': {
      if (!state.csvData) throw new Error('Missing csvData')
      return { mode: 'ValidationErrors', csvData: state.csvData, validationErrors: action.payload }
    }
    case 'VALIDATION_NETWORK_ERROR': {
      if (!state.csvData) throw new Error('Missing csvData')
      return {
        mode: 'ValidationNetworkError',
        csvData: state.csvData,
        validationNetworkError: action.payload,
      }
    }
    case 'VALIDATION_SUCCESS': {
      if (!state.csvData) throw new Error('Missing csvData')
      return { mode: 'Validated', csvData: state.csvData }
    }
    case 'EDIT_FIELD': {
      if (state.mode !== 'ValidationErrors') throw new Error('Invalid state')
      if (!state.csvData) throw new Error('Missing csvData')
      if (!state.validationErrors) throw new Error('Missing validationErrors')

      const { column, row, value } = action.payload

      const position = state.csvData.headers.indexOf(column)

      const updatedCSVData: CustomerFileCSVData = {
        headers: state.csvData.headers,
        data: [
          ...state.csvData.data.slice(0, row),
          [
            ...state.csvData.data[row].slice(0, position),
            value,
            ...state.csvData.data[row].slice(position + 1),
          ],
          ...state.csvData.data.slice(row + 1),
        ],
      }

      return {
        mode: 'ValidationErrors',
        csvData: updatedCSVData,
        validationErrors: state.validationErrors,
      }
    }
    case 'ACCEPT_ROW': {
      if (state.mode !== 'ValidationErrors') throw new Error('Invalid state')
      if (!state.csvData) throw new Error('Missing csvData')
      if (!state.validationErrors) throw new Error('Missing validationErrors')

      return {
        mode: 'ValidationErrors',
        csvData: state.csvData,
        validationErrors: state.validationErrors.filter(err => err.row !== action.payload),
      }
    }
    case 'DISCARD_ROW': {
      if (state.mode !== 'ValidationErrors') throw new Error('Invalid state')
      if (!state.csvData) throw new Error('Missing csvData')
      if (!state.validationErrors) throw new Error('Missing validationErrors')

      return {
        mode: 'ValidationErrors',
        csvData: {
          headers: state.csvData.headers,
          data: state.csvData.data.filter((row, i) => i !== action.payload),
        },
        validationErrors: state.validationErrors
          // Remove errors pertaining to the discarded row
          .filter(err => err.row !== action.payload)
          // Adjust row indices to account for the discarded row
          .map(err => ({ ...err, row: err.row > action.payload ? err.row - 1 : err.row })),
      }
    }
    default:
      throw new Error('Invalid action')
  }
}

const exampleCSVData = [
  ['email', 'phone', 'gen', 'doby', 'dobm', 'dobd', 'ln', 'fn', 'fi', 'st', 'ct', 'zip', 'country'],
  [
    'johndoe@gmail.com',
    '123-456-7890',
    'm',
    '1990',
    '05',
    '23',
    'Doe',
    'John',
    'J',
    'tx',
    'dallas',
    '75001',
    'us',
  ],
  ['janedoe@gmail.com', '', 'f', '', '', '', 'Doe', 'Jane', 'J', '', '', '', ''],
]
const csvContent = 'data:text/csv;charset=utf-8,' + exampleCSVData.map(e => e.join(',')).join('\n')
const encodedCsvUri = encodeURI(csvContent)

export const CustomerDataUploader = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (state.mode === 'Validated') {
      props.handleValidData(state.csvData!)
    }
  }, [state.mode])

  const setParseError = (errorMessage: string) => {
    dispatch({ type: 'PARSE_FAILED', payload: errorMessage })
  }

  const validateParsedCSVData = async (parsedData: CustomerFileCSVData) => {
    dispatch({ type: 'VALIDATE_CSV_DATA', payload: parsedData })
    try {
      const validationErrors = await props.validateData(parsedData)
      if (validationErrors.length) {
        dispatch({ type: 'VALIDATION_FAILED', payload: validationErrors })
      } else {
        dispatch({ type: 'VALIDATION_SUCCESS' })
      }
    } catch (err: any) {
      const errMessage = `${err.response.data.error}: ${err.response.data.message}`
      dispatch({ type: 'VALIDATION_NETWORK_ERROR', payload: errMessage })
    }
  }

  const retryUpload = () => {
    dispatch({ type: 'RETRY_UPLOAD' })
  }

  const acceptRow = (rowIndex: number) => {
    dispatch({ type: 'ACCEPT_ROW', payload: rowIndex })
  }

  const discardRow = (rowIndex: number) => {
    dispatch({ type: 'DISCARD_ROW', payload: rowIndex })
  }

  const handleCellEdit = (fieldChanges: FieldChanges) => {
    dispatch({ type: 'EDIT_FIELD', payload: fieldChanges })
  }

  const getValidationRows = () => {
    return (
      state
        .csvData!.data.map((row, rowIndex) => {
          const rowErrors = state.validationErrors!.filter(err => err.row === rowIndex)

          return rowErrors.length === 0 ? undefined : (
            <TableRow key={rowIndex}>
              <TableCell>
                <IconButton
                  onClick={() => acceptRow(rowIndex)}
                  aria-label={`accept-row-${rowIndex}`}
                >
                  <Check style={{ color: green[500] }} />
                </IconButton>
                <IconButton
                  onClick={() => discardRow(rowIndex)}
                  aria-label={`discard-row-${rowIndex}`}
                >
                  <Close style={{ color: red[500] }} />
                </IconButton>
              </TableCell>
              {row.map((cellValue, columnIndex) => {
                const header = state.csvData!.headers[columnIndex]
                const error = rowErrors.find(err => err.row === rowIndex && err.field === header)

                return (
                  <TableCell key={columnIndex}>
                    <TextField
                      inputProps={{ 'aria-label': `column-${header}-row-${rowIndex}` }}
                      value={cellValue}
                      onChange={e =>
                        handleCellEdit({
                          row: rowIndex,
                          column: header as MatchKeys,
                          value: e.target.value,
                        })
                      }
                      disabled={!error}
                      error={!!error}
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })
        // Filter out undefined values. We perform the mapping first, and the filtering second, because
        // the map is dependent on the the original array indices.
        .filter(_ => _)
    )
  }

  const uploadContent = () => {
    switch (state.mode) {
      case 'DragNDrop': {
        return (
          <div>
            <Dropzone
              allowedExtensions={['.csv']}
              inputProps={{ 'data-testid': 'dropzone-input' }}
              handleUpload={file => {
                Papa.parse(file, {
                  delimiter: ',',
                  skipEmptyLines: true,
                  complete: results => {
                    if (results.errors.length) {
                      setParseError(results.errors[0].message)
                      return
                    }

                    if (results.data.length <= 1) {
                      setParseError(
                        'The first row of your CSV data should contain headers, and all other rows should contain data.'
                      )
                      return
                    }

                    if (results.data.length <= 110) {
                      setParseError(
                        'Your CSV should contain more than 110 valid rows, otherwise the size is too small for Facebook.'
                      )
                      return
                    }

                    const headers = results.data[0] as string[]
                    const [validHeaders, invalidHeaders] = partition(headers, isMatchKey)

                    if (invalidHeaders.length) {
                      setParseError(
                        `The following headers are invalid: ${invalidHeaders.join(', ')}`
                      )
                      return
                    }

                    const data = results.data.slice(1)
                    const [validData, invalidData] = partition(
                      data,
                      row => Array.isArray(row) && row.length === validHeaders.length
                    ) as string[][][]

                    if (invalidData.length) {
                      setParseError(`The following row is invalid: ${invalidData[0].join(', ')}`)
                      return
                    }

                    const parsedCSVData = { headers: validHeaders, data: validData }

                    validateParsedCSVData(parsedCSVData)
                  },
                  error: error => setParseError(error.message),
                })
              }}
            />
          </div>
        )
      }
      case 'ParseError': {
        return (
          <div>
            <Alert variant="standard" severity="error">
              {state.errMsg}
            </Alert>
            <br />
            <Button variant="contained" color="secondary" onClick={() => retryUpload()}>
              Retry Upload
            </Button>
          </div>
        )
      }
      case 'Validating': {
        return (
          <Typography variant="h6">
            Validating... <CircularProgress />
          </Typography>
        )
      }
      case 'ValidationErrors': {
        const tableRows = getValidationRows()

        return tableRows.length === 0 ? (
          <div>
            <Typography variant="h6">
              Great! You have finished addressing all validation errors.
            </Typography>
            <br />
            <Button variant="contained" onClick={() => validateParsedCSVData(state.csvData!)}>
              Resubmit
            </Button>
          </div>
        ) : (
          <div>
            <Typography variant="h6">
              There were errors when validating your CSV data. The problematic rows are shown below.
              You may mark a row as "fixed" by clicking the green checkmark, or you can discard a
              row from your dataset by clicking the red X.
            </Typography>
            <br />
            <TableContainer component={Paper}>
              <Table aria-label="csv-validation-errors">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {state.csvData!.headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
              </Table>
            </TableContainer>
          </div>
        )
      }
      case 'ValidationNetworkError': {
        return (
          <div>
            <Typography variant="h6">
              There was a network error when trying to validate your data. Please try again in a
              little bit.
            </Typography>
            <div style={{ color: 'red' }}>{state.validationNetworkError}</div>
            <br />
            <Button variant="contained" onClick={() => retryUpload()}>
              Retry Upload
            </Button>
          </div>
        )
      }
      case 'Validated': {
        return (
          <Typography variant="h6">
            Your file is formatted correctly and contains valid data{' '}
            <CheckCircleOutline style={{ color: green[500] }} />
          </Typography>
        )
      }
      default:
        throw new Error('Invalid state')
    }
  }

  return (
    <div>
      <StepHeader
        title="Customer Data Upload"
        description="Upload a list of customers from your business. We'll use this list to target users on Facebook that are similar to the people in this list."
        targetingSpec={props.targetSpec}
      />
      {uploadContent()}
      <div className="w-100 d-block mt-5">
        <Typography className="mb-2" variant="h6">
          About Customer Data Upload:
        </Typography>
        <Typography className="mb-2" variant="body1">
          Your customer list is a CSV file that contains information used to build your audience.
          Identifiers in your customer list are used to match with Facebook users. The more
          identifiers you provide, the better the match rate. Before the list is sent to Facebook
          for your audience to be created, we use a cryptographic security method known as hashing,
          which turns the identifiers into randomized code and cannot be reversed.
        </Typography>
        <Button download className="mt-2" color="primary" variant="contained" href={encodedCsvUri}>
          <FileDownloadIcon className="mr-2" /> Download File Template
        </Button>
        <Typography className="mb-2 mt-5" variant="h6">
          Include as many of the following fields as possible:{' '}
        </Typography>
        {Object.keys(MatchKeys)
          .filter(key => key !== 'extern_id' && key !== 'lead_id')
          .map(key => (
            <Chip className="mr-3" label={key} variant="outlined" />
          ))}
      </div>
    </div>
  )
}
