import React from 'react'
import 'react-phone-input-2/lib/style.css'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'

export interface CompletionPhoneProps {
  editable: boolean
  phoneNumber?: string
  countryCode?: string
  isValidPhoneNumber: boolean
  onChangeBusinessPhone: (...info: Parameters<NonNullable<PhoneInputProps['onChange']>>) => void
}

export const allowedCountries = ['us', 'mx', 'ca', 'dk']

export function CompletionPhone({
  editable,
  phoneNumber,
  countryCode,
  onChangeBusinessPhone,
  isValidPhoneNumber,
}: CompletionPhoneProps) {
  return (
    <div className="form-group">
      <InputLabel
        error={!phoneNumber && isValidPhoneNumber}
        disabled={!editable}
        shrink
        className="text-bold"
      >
        Business Phone Number *
      </InputLabel>
      <PhoneInput
        autoFormat
        disabled={!editable}
        value={phoneNumber}
        country={countryCode}
        countryCodeEditable={false}
        disableDropdown={false}
        showDropdown
        enableLongNumbers={false}
        onlyCountries={allowedCountries}
        onChange={onChangeBusinessPhone}
        inputProps={{
          name: 'business_phone_number',
          required: true,
        }}
        isValid={!!phoneNumber}
      />
      {!phoneNumber && !isValidPhoneNumber && (
        <FormHelperText error={true}>
          Thank you page phone number is required. Please add a valid phone number.
        </FormHelperText>
      )}
    </div>
  )
}
