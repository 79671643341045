import React, { useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { sessionContext, useGetBusinessIDIfExists } from '@marketing-milk/frontend'
import { BusinessSelector } from '../business-selector/BusinessSelector'
import { SimpleDropdown, ProfilePicture } from '@marketing-milk/frontend'
import { Permissions } from '@marketing-milk/interfaces'

export function TopNav() {
  const businessID = useGetBusinessIDIfExists()
  const { user, logout } = useContext(sessionContext)
  const { isAuthenticated, logout: auth0Logout } = useAuth0()

  function getDropdownDisplay() {
    const initials = user
      ? user?.firstName?.substring(0, 1) + user?.lastName?.substring(0, 1)
      : 'n/a'
    return (
      <>
        <span className="text-muted font-size-base d-none d-md-inline mr-1">Hi,</span>
        <span className="text-dark-50 font-size-base d-none d-md-inline mr-3">
          {user?.firstName}
        </span>
        <ProfilePicture initials={initials} image={user ? user.profilePicture : ''} />
      </>
    )
  }

  function logoutUser() {
    const redirectURL = 'https://www.marketingmilk.com/'
    logout()
    // If the user logged in via Auth0, we need to log them out of Auth0
    // in addition to clearing their Marketing Milk session
    if (isAuthenticated) auth0Logout({ returnTo: redirectURL })
  }

  return (
    <div id="kt_subheader" className="subheader py-2 subheader-solid border-b">
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex z-40 space-x-2 flex justify-center items-center">
            <BusinessSelector />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="topbar-item">
            <SimpleDropdown button={getDropdownDisplay()}>
              <ul className="navi navi-hover">
                {user && user.permissions.includes(Permissions.AccessAdminApp) && (
                  <li className="navi-item text-left">
                    <a
                      href={
                        businessID
                          ? `${process.env.REACT_APP_ADMIN}/business/beta/accounts/edit/${businessID}`
                          : `${process.env.REACT_APP_ADMIN}/business/beta/accounts/list`
                      }
                      className="navi-link"
                    >
                      <span className="navi-text hover-brand">
                        <i className="fad fa-cogs text-inherit mr-2 text-brand" /> Admin Panel
                      </span>
                    </a>
                  </li>
                )}
                {user && user.permissions.includes(Permissions.AccessDashboardApp) && (
                  <li className="navi-item text-left">
                    <a
                      href={
                        businessID
                          ? `${process.env.REACT_APP_CLIENT}/${businessID}/dashboard`
                          : `${process.env.REACT_APP_CLIENT}/main`
                      }
                      className="navi-link"
                    >
                      <span className="navi-text hover-brand">
                        <i className="fad fa-tachometer text-inherit mr-2 text-brand" /> Dashboard
                      </span>
                    </a>
                  </li>
                )}
                <li className="navi-item text-left">
                  <span onClick={logoutUser} className="navi-link">
                    <span className="navi-text hover-brand">
                      <i className="fad fa-sign-out text-inherit mr-2 text-brand" /> Logout
                    </span>
                  </span>
                </li>
              </ul>
            </SimpleDropdown>
          </div>
        </div>
      </div>
    </div>
  )
}
