import React from 'react'
import { CompletionLink } from './CompletionLink'
import { CompletionPhone } from './CompletionPhone'
import { CallToActionText } from './CallToActionText'
import Typography from '@material-ui/core/Typography'
import { CallToActionButton } from './CallToActionButton'
import { CompletionHeadline } from './CompletionHeadline'
import { CompletionDescription } from './CompletionDescription'
import { LeadCardThankYou } from '@marketing-milk/interfaces'
import { FacebookLeadgenButtonType } from '@marketing-milk/interfaces'
import { PhoneInputProps } from 'react-phone-input-2'

export interface FormCompletionProps {
  editable: boolean
  isValidPhoneNumber: boolean
  thankYouPage?: LeadCardThankYou
  onChangeHeadline(headline: string): void
  onChangeDescription(description: string): void
  onChangeCTA(callToAction: FacebookLeadgenButtonType): void
  onChangeButtonText(buttonText: string): void
  onChangeLink(link: string): void
  onChangeBusinessPhone: (...info: Parameters<NonNullable<PhoneInputProps['onChange']>>) => void
}

export function FormCompletion(props: FormCompletionProps) {
  const {
    editable,
    thankYouPage,
    onChangeHeadline,
    onChangeDescription,
    onChangeCTA,
    onChangeButtonText,
    onChangeLink,
    isValidPhoneNumber,
    onChangeBusinessPhone,
  } = props
  return (
    <div data-testid="form-completion-step">
      <Typography className="mb-2" variant="h5">
        Completion
      </Typography>
      <p>Let people who submit their information know what to expect next.</p>
      <hr className="my-3" />
      <CompletionHeadline
        editable={editable}
        headline={thankYouPage?.headline}
        onChangeHeadline={onChangeHeadline}
      />
      <CompletionDescription
        editable={editable}
        description={thankYouPage?.description}
        onChangeDescription={onChangeDescription}
      />
      <CallToActionButton
        editable={editable}
        callToAction={thankYouPage?.callToAction}
        onChangeCTA={onChangeCTA}
      />
      <CallToActionText
        editable={editable}
        buttonText={thankYouPage?.buttonText}
        onChangeButtonText={onChangeButtonText}
      />
      {thankYouPage?.callToAction !== FacebookLeadgenButtonType.CALL_BUSINESS ? (
        <CompletionLink editable={editable} link={thankYouPage?.link} onChangeLink={onChangeLink} />
      ) : (
        <CompletionPhone
          onChangeBusinessPhone={onChangeBusinessPhone}
          isValidPhoneNumber={isValidPhoneNumber}
          phoneNumber={thankYouPage.phoneNumber}
          countryCode={thankYouPage?.countryCode}
          editable={editable}
        />
      )}
    </div>
  )
}
