import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'

export function useLogSentryError(error?: Error) {
  useEffect(() => {
    if (error) {
      Sentry.captureException(error)
    }
  }, [error])
}
