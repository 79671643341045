import { useState } from 'react'
import { useBaseAudience } from './use-base-audience'
import { SavedAudience, CustomerFileCSVData } from '@marketing-milk/interfaces'

export function useCustomerFileAudience(businessID: string, audience?: SavedAudience) {
  const baseAudience = useBaseAudience(businessID, audience)
  const [customerData, setCustomerData] = useState<CustomerFileCSVData>()

  return {
    ...baseAudience,
    customerData,
    setCustomerData,
  }
}
