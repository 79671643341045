import Typography from '@material-ui/core/Typography'
import { FormatService } from '@marketing-milk/frontend'
import { SavedAudience } from '@marketing-milk/interfaces'
import { getAudienceBoundsAverageTotal } from 'pages/business/campaigns/helpers/campaignForm.helpers'
import { AudienceChip } from './AudienceChip'

export interface AdsetSummaryProps {
  selectedAudiences?: SavedAudience[]
}

export function AdsetSummary({ selectedAudiences }: AdsetSummaryProps) {
  const audienceAvg = FormatService.getValue(getAudienceBoundsAverageTotal(selectedAudiences)) || 0
  return (
    <div data-testid="adset-summary" className="grid grid-cols-1">
      <div>
        <div className="pb-0 flex-wrap">
          {selectedAudiences?.map(audience => (
            <AudienceChip audience={audience} />
          ))}
        </div>
        <div className="d-flex pt-4">
          <Typography variant="body1" style={{ alignSelf: 'flex-start', flex: 1 }}>
            {selectedAudiences?.length} Adsets
          </Typography>
          <Typography
            variant="body1"
            className="text-bold text-right"
            style={{ alignSelf: 'flex-end' }}
          >
            Total Avg. Size: {audienceAvg}
          </Typography>
        </div>
      </div>
    </div>
  )
}
