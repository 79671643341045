import { Loading } from '@marketing-milk/frontend'
import { Button, Drawer } from '@material-ui/core'
import { CalendarToday } from '@material-ui/icons'
import { FullPageContent } from 'components/layout/FullPageLayout/Content/FullPageContent'
import { FullPageHeader } from 'components/layout/FullPageLayout/Header/FullPageHeader'
import { UserLayout } from 'components/layout/UserLayout'
import { NoItemsCreated } from 'components/NoItemsCreated/NoItemsCreated'
import { useNest } from 'hooks/api/useNest'
import { useEffect, useState } from 'react'
import { CreateSprintForm } from './components/create-sprint-form/CreateSprintForm'
import { SprintHelpIcon } from './components/sprint-help-icon/SprintHelpIcon'
import { SprintContent } from './components/sprints-content/SprintContent'
import { SprintsSidebar } from './components/sprints-sidebar/SprintsSidebar'

export function Sprints() {
  const [selectedSprintId, setSelectedSprintId] = useState<number>()
  const [createSprintsModalOpen, setCreateSprintModalOpen] = useState(false)

  const { list: sprints, create: createSprint, isLoading: sprintsLoading } = useNest('sprints')

  // Sort Displayed Sprints
  useEffect(() => {
    if (!sprints) return
    const sorted = sprints?.sort((a, b) => b.createdTime - a.createdTime)
    if (!sorted) return
    setSelectedSprintId(sorted[0]?.id)
  }, [sprints])

  return (
    <UserLayout>
      <FullPageHeader breadcrumbs={[{ title: 'Sprints' }]}>
        <Button color="primary" onClick={() => setCreateSprintModalOpen(true)} variant="contained">
          Start New Sprint
        </Button>

        <Drawer open={createSprintsModalOpen} onClose={() => setCreateSprintModalOpen(false)}>
          <CreateSprintForm
            onSubmit={createSprint}
            onClose={() => setCreateSprintModalOpen(false)}
          />
        </Drawer>
        <SprintHelpIcon />
      </FullPageHeader>

      <FullPageContent className="overflow-x-hidden">
        {sprints && sprints?.length !== 0 && (
          <SprintsSidebar
            sprints={sprints}
            selectedSprintId={selectedSprintId}
            setSelectedSprintId={setSelectedSprintId}
          />
        )}

        {sprints?.length === 0 && (
          <div className="mx-auto">
            <NoItemsCreated
              title="Hmmm.. It looks like you haven't made any sprints yet, no worries!"
              subtitle="Click the button below and create your first Sprint"
            >
              <button
                type="button"
                className="max-w-3xl my-8 block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setCreateSprintModalOpen(true)}
              >
                <CalendarToday fontSize="large" />
                <span className="mt-2 block text-lg font-medium text-gray-900">Make Sprint</span>
              </button>
            </NoItemsCreated>
          </div>
        )}

        {sprintsLoading && (
          <div className="w-full justify-center p-4">
            <Loading />
          </div>
        )}

        {selectedSprintId && <SprintContent selectedSprintId={selectedSprintId} />}
      </FullPageContent>
    </UserLayout>
  )
}
