import React, { useState, useEffect } from 'react'
import { Loading, RenderWhenVisible } from '@marketing-milk/frontend'
import { AdCreative } from '@marketing-milk/interfaces'
import { equals } from 'lodash/fp'
import { CreativeCard } from 'pages/business/creatives/CreativeCard/CreativeCard'
import { fuzzySearch } from 'components/DataTable/helpers/search.helpers'
import { sortByUpdatedAt } from 'pages/business/creatives/helpers/creative.helpers'
import { Alert } from '@material-ui/lab'

export type Props = {
  creatives?: AdCreative[]
  creativeA?: AdCreative
  creativeB?: AdCreative
  selectCreative: (creative: AdCreative) => void
}

export const AdCreativeList = ({ creatives, creativeA, creativeB, selectCreative }: Props) => {
  const [searchTerm, setSearchTerm] = useState('')

  const [debouncing, setDebouncing] = useState(true)

  useEffect(() => {
    setTimeout(() => setDebouncing(false), 500)
  }, [])

  const getButtonText = (creative: AdCreative) => {
    if (equals(creative, creativeA)) return 'Creative A'
    if (equals(creative, creativeB)) return 'Creative B'
    return undefined
  }

  return (
    <>
      <div className="flex w-full space-x-4 p-4 pb-2 items-center justify-center">
        <input
          className="bg-white w-full rounded-md border px-4 py-3 max-w-6xl"
          type="text"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Filter Ads"
        />
      </div>

      {creatives && creatives.length === 0 && (
        <div className="max-w-5xl mx-auto">
          <Alert severity="warning">
            No creatives? Make sure that your ads have all of the required info added. Also, check
            and see whether you have set the correct optimization in your campaign. If this is a
            Lead Gen campaign, you will only see creatives that have a lead card attached.
          </Alert>
        </div>
      )}

      {creatives && !debouncing ? (
        <div className="grid w-full justify-items-center sm:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 sm:gap-10">
          {fuzzySearch(sortByUpdatedAt(creatives), searchTerm).map((creative, i) => (
            <RenderWhenVisible>
              <CreativeCard
                key={i}
                tools={false}
                creative={creative}
                onSelect={selectCreative}
                selectedText={getButtonText(creative)}
                pillDisplayed={'format'}
              />
            </RenderWhenVisible>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}
