import { useGetBusinessID } from '@marketing-milk/frontend'
import { AdCreative, PublishCampaignDTO, Sprint, SprintDTO } from '@marketing-milk/interfaces'
import { rejectUndefined } from 'app/util'
import { useDraftCampaign } from 'hooks/api/useDraftCampaign'
import { useNest } from 'hooks/api/useNest'
import { useFetchAllAudiences } from 'hooks/audiences/useFetchAllAudiences'
import { fromPairs, update } from 'lodash/fp'
import { useCreatives } from 'pages/business/creatives/useCreatives'
import { useState } from 'react'
import { indexedEntries } from '../../sprint.helpers'
import { CreativeScaffold } from './CreateSprintForm'

export const useSprintUpload = (
  sprintDto: SprintDTO,
  campaignConfigs: PublishCampaignDTO['campaign'][],
  creativeDict: { [campaignIndex: number]: CreativeScaffold[][] }
) => {
  const [isSprintUploading, setIsSprintUploading] = useState(false)
  const [isUploadCompleted, setIsUploadCompleted] = useState(false)

  const businessId = useGetBusinessID()
  const { defaultAudiences } = useFetchAllAudiences(businessId)
  const { createCreative } = useCreatives(businessId)
  const { create: createSprint, refetch: refetchSprints } = useNest('sprints')
  const { createDraftCampaign } = useDraftCampaign()

  const [resultFunctions, sprintUploadResults] = useUploadResultState([
    'sprint',
    'creatives',
    'draftCampaigns',
  ])

  const generateFullSprint = async () => {
    setIsSprintUploading(true)

    // Create Sprint
    const sprint = await createSprint(sprintDto)
      .then(onSprintSuccess)
      .catch(onSprintError(sprintDto.name))

    if (!sprint) {
      onSprintError('Backend did not return Sprint')('')
      setIsSprintUploading(false)
      return
    }

    // Upload Creatives and map them to a campaign DTO object
    const campaignDtos = await Promise.all(
      indexedEntries(creativeDict).map(buildSprintCampaignDto(sprint.id))
    )

    // Create Draft Campaigns
    await Promise.all(
      campaignDtos.map(config =>
        createDraftCampaign({ config, sprintID: sprint.id })
          .then(onDraftCampaignSuccess(config))
          .catch(onDraftCampaignError(config))
      )
    )

    setIsUploadCompleted(true)
    setIsSprintUploading(false)
  }

  const uploadCreativeGroup = (sprintId: number) => async (creativeGroup: CreativeScaffold[]) => {
    const creativeIds = creativeGroup.map(({ name, type }) =>
      // @ts-ignore
      createCreative(name, type, { sprintId }).then(onCreativeSuccess).catch(onCreativeError(name))
    )

    return rejectUndefined(await Promise.all(creativeIds))
  }

  const buildSprintCampaignDto =
    (sprintId: number) =>
    async ([campaignIndex, creativeGroups]: [number, CreativeScaffold[][]]) => ({
      creativeGroups: await Promise.all(creativeGroups.map(uploadCreativeGroup(sprintId))),
      campaign: campaignConfigs[Number(campaignIndex)],
      audienceIDs: defaultAudiences.map(audience => audience.id),
      // @ts-ignore
      sprintId,
    })

  // Error State Functions
  const onDraftCampaignError = (config: PublishCampaignDTO) => (err: any) => {
    resultFunctions.draftCampaigns.setError(
      config.campaign.name,
      `${config.campaign.name} failed to upload`,
      `${err}`
    )
    return undefined
  }
  const onCreativeError = (name: string) => (err: any) => {
    resultFunctions.creatives.setError(name, `'${name}' failed to upload`, `${err}`)
    return undefined
  }
  const onSprintError = (name: string) => (err: any) => {
    resultFunctions.sprint.setError(name, `'${name}' failed to upload`, `${err}`)
    return undefined
  }

  // Success State Functions
  const onDraftCampaignSuccess = (config: PublishCampaignDTO) => (_: any) =>
    resultFunctions.draftCampaigns.setSuccess(
      config.campaign.name,
      'created in MM and attached to sprint'
    )
  const onCreativeSuccess = (creative: AdCreative) => {
    resultFunctions.creatives.setSuccess(creative.name, 'created in MM and attached to sprint')
    return creative.id
  }
  const onSprintSuccess = (sprint?: Sprint) => {
    if (!sprint) return sprint
    resultFunctions.sprint.setSuccess(sprint.name, 'Has been created')
    return sprint
  }

  return {
    isSprintUploading,
    sprintUploadResults,
    isUploadCompleted,
    generateFullSprint,
    refetchSprints,
  }
}

export type UploadInfo = {
  name: string
  resultType: 'error' | 'success'
  message: string
  description?: string
}

export const useUploadResultState = <T extends string>(uploadTypes: T[]) => {
  const [uploadResults, setUploadResults] = useState<Record<T, UploadInfo[]>>(
    // @ts-ignore
    fromPairs(uploadTypes.map(type => [type, []]))
  )

  // Error State Functions
  const setErrorFn =
    (type: T) => (name: string, message: string, description?: string) => (error: any) => {
      const info: UploadInfo = {
        message,
        name,
        description: `${description} | ${error}`,
        resultType: 'error',
      }
      setUploadResults(update(type, prev => [...prev, info]))
    }
  const setSuccessFn = (type: T) => (name: string, message: string, description?: string) => {
    const info: UploadInfo = {
      message,
      name,
      description,
      resultType: 'success',
    }

    setUploadResults(update(type, prev => [...prev, info]))
  }

  const setterFunctions = fromPairs(
    uploadTypes.map(type => {
      return [type, { setSuccess: setSuccessFn(type), setError: setErrorFn(type) }]
    })
  ) as Record<
    T,
    { setSuccess: ReturnType<typeof setSuccessFn>; setError: ReturnType<typeof setErrorFn> }
  >

  return [setterFunctions, uploadResults] as [typeof setterFunctions, typeof uploadResults]
}
