import React from 'react'
import { Typography } from '@material-ui/core'
import { Place } from '@googlemaps/google-maps-services-js'
import { useLocalCompetitionFinder } from './use-local-competition'
import { SidebarSection, FullPageSidebar } from '@marketing-milk/frontend'

export function CompetitionList(props: ReturnType<typeof useLocalCompetitionFinder>) {
  return (
    <FullPageSidebar>
      {props.data?.map((place: Place, index: number) => (
        <SidebarSection
          key={index}
          className="cursor-pointer hover:bg-gray-200"
          onClick={() => props.setSelectedCompetitorID(place.place_id)}
          title={`${place.name} ${!props.isBusinessOperational(place) ? '☠️' : ''}` || '...loading'}
        >
          {props.getDistanceFromBusiness(props.businessCoordinates, place)}
        </SidebarSection>
      ))}
      <Typography className="text-center mt-2" variant="body2">
        Displaying {props.data?.length} Competitors
      </Typography>
    </FullPageSidebar>
  )
}
