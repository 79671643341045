import { Loading, useGetBusiness } from '@marketing-milk/frontend'
import {
  AdCreative,
  Campaign,
  CampaignPauseActionType,
  PublishCampaignDTO,
  SavedAudience,
} from '@marketing-milk/interfaces'
import { Drawer } from '@mui/material'
import { AdObjectType, campaignService } from 'app/services'
import axios from 'axios'
import { DataTable, DataTableProps } from 'components/DataTable'
import { NoItemsCreated } from 'components/NoItemsCreated/NoItemsCreated'
import { useCampaignGoals } from 'hooks/campaigns/use-campaign-goals-by-business'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { campaignsTableSchema } from '../../campaignsTableSchema'
import { CampaignForm } from '../../forms/CampaignForm'
import { getAdErrors, getAdsetErrors } from '../../helpers/campaign.helpers'
import { singleCampaignDtoIsValid } from '../../helpers/campaignForm.helpers'
import { CampaignTools } from '../campaign-tools/CampaignTools'
import { PauseAdObject } from '../PauseAdObject'
import MMLogoSrc from 'public/img/logos/milk-gradient.png'
import FBLogoSrc from 'public/img/logos/facebook-logo.jpg'
import { CampaignExpandDetails } from './CampaignExpandDetails'

export type ActionType = 'create' | 'retry' | 'duplicate' | 'extend' | 'edit' | 'delete'

type Props = {
  campaigns?: Campaign[]
  audiences?: SavedAudience[]
  creatives?: AdCreative[]
  refetch: () => void
  onDeleteCampaign?: (campaignId: number) => void
}
export const CampaignsContent = ({
  campaigns,
  refetch,
  audiences,
  creatives,
  onDeleteCampaign,
}: Props) => {
  const business = useGetBusiness()
  const { enabledGoals } = useCampaignGoals(business.id)
  const [editType, setEditType] = useState<ActionType>()
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>()
  const [uploading, setUploading] = useState(false)

  const actionHandler = (selectedCampaign: Campaign) => (type: ActionType) => {
    if (type === 'delete' && onDeleteCampaign) {
      onDeleteCampaign(selectedCampaign.id)
    } else {
      openDrawer(selectedCampaign, type)
    }
  }

  const openDrawer = (campaign: Campaign, type: ActionType) => {
    setSelectedCampaign(campaign)
    setEditType(type)
  }

  const closeDrawer = () => {
    setSelectedCampaign(undefined)
    setEditType(undefined)
  }

  const onSubmit = async (campaignDto?: PublishCampaignDTO) => {
    if (!campaignDto) return
    if (!selectedCampaign) return
    if (!singleCampaignDtoIsValid(campaignDto, () => {})) return
    setUploading(true)

    closeDrawer()

    try {
      if (editType === 'edit' || editType === 'retry') {
        toast.info('We are adding your edits to the campaign on facebook')
        await campaignService.patchCampaign(business.id, selectedCampaign.id, campaignDto)
      }
      if (editType === 'duplicate') {
        toast.info('We are duplicating your campaign')
        await campaignService.duplicateCampaign(business.id, selectedCampaign.id, campaignDto)
      }

      await refetch()
      toast.success('Success!')
    } catch {
      await refetch()
      toast.error('Failure!  Your campaign was published with errors')
    } finally {
      setUploading(false)
    }
  }

  const onPauseOrActivate = async (
    adObjectType: AdObjectType,
    adObjectID: number,
    action: CampaignPauseActionType
  ): Promise<boolean> => {
    try {
      await campaignService.pauseOrResumeAdObjectForBusiness(
        business.id,
        adObjectID,
        adObjectType,
        action
      )
      await refetch()
      toast.success(`Your ${adObjectType} has been successfully ${action}d`)
      return true
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.data.message) {
        toast.error(`Your ${adObjectType} failed to be ${action}d: ${err.response.data.message}`)
      } else {
        toast.error(`Your ${adObjectType} failed to be ${action}d`)
      }
      await refetch()
      return false
    }
  }

  return (
    <>
      <Drawer open={!!selectedCampaign || editType === 'create'} onClose={closeDrawer}>
        <CampaignForm
          // @ts-ignore
          dto={selectedCampaign?.config}
          goals={enabledGoals}
          campaignErrors={{
            campaign: selectedCampaign?.errors?.map(s => s.message),
            adsets: getAdsetErrors(selectedCampaign)?.map(s => s.message),
            ads: getAdErrors(selectedCampaign)?.map(s => s.message),
          }}
          audiences={audiences}
          creatives={creatives}
          onSubmit={onSubmit}
        />
      </Drawer>

      {uploading && <Loading loadingMessage="We are adding your changes to facebook" />}

      {isEmpty(campaigns) && (
        <div className="w-full flex justify-center">
          <NoItemsCreated title="No Campaigns To Show" />
        </div>
      )}

      {campaigns && campaigns.length > 0 && !uploading && (
        <CampaignsTable
          hidePagination
          hideColumnFilter
          hideSearch
          tableSchema={{
            'Off / On': {
              tooltipText:
                'This column indicates whether your campaign is currently on or off. When switched on, it will be able to run based on its schedule and settings. When switched off, it will not run.',
              render: campaign => (
                <PauseAdObject
                  key={campaign.id}
                  checkedDefault={campaign.activeStatus === 'ACTIVE'}
                  disabled={!campaign.activeStatus}
                  onPauseOrResume={checked =>
                    onPauseOrActivate('Campaign', campaign.id, checked ? 'resume' : 'pause')
                  }
                />
              ),
            },
            ...campaignsTableSchema,
            'Created From': {
              render: campaign =>
                campaign.config ? (
                  <img
                    src={MMLogoSrc}
                    alt="marketing-milk marketing milk logo"
                    style={{ maxHeight: '30px' }}
                  />
                ) : (
                  <img src={FBLogoSrc} alt="facebook logo" style={{ maxHeight: '30px' }} />
                ),
            },
            Actions: {
              render: campaign => (
                <CampaignTools campaign={campaign} action={actionHandler(campaign)} />
              ),
            },
          }}
          expansionPanel={{
            shouldExpand: () => true,
            render: campaign => (
              <CampaignExpandDetails campaign={campaign} onPauseOrActivate={onPauseOrActivate} />
            ),
          }}
          recordsList={campaigns}
        />
      )}
    </>
  )
}

export const CampaignsTable = ({ recordsList: campaigns, ...props }: DataTableProps<Campaign>) => (
  <DataTable<Campaign> recordsList={campaigns} {...props} />
)
