interface Props {
  onClick: () => void
}
export const EditCompleteButton = ({ onClick }: Props) => {
  return (
    <button
      className="p-8 m-8 bg-purple-200 w-1/3 border border-dashed border-gray-300 hover:bg-gray-100 flex items-center justify-center font-bold text-lg mx-auto"
      onClick={onClick}
    >
      Update Information
    </button>
  )
}
