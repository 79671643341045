import React, { useContext } from 'react'
import { ShortAnswerQuestion } from './ShortAnswerQuestion'
import { MultiChoiceQuestion } from './MultiChoiceQuestion'
import { leadCardPreviewContext } from '../../context/LeadCardPreviewContext'
import { FacebookLeadgenQuestionType, LeadCardQuestionCustom } from '@marketing-milk/interfaces'

export function FormQuestions() {
  const { leadCard } = useContext(leadCardPreviewContext)
  const customQuestions = leadCard.questions.filter(
    _ => _.type === FacebookLeadgenQuestionType.CUSTOM
  ) as LeadCardQuestionCustom[]

  if (customQuestions.length === 0) {
    return null
  }

  return (
    <>
      {customQuestions.map((question, i) => {
        return question.options?.length ? (
          <MultiChoiceQuestion key={i} label={question.label} options={question.options} />
        ) : (
          <ShortAnswerQuestion key={i} label={question.label} />
        )
      })}
      <br />
      <br />
    </>
  )
}
