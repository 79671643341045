import {
  AdCreative,
  AdCreativeStatus,
  AdCreativeType,
  CampaignConfig,
  CampaignGoalType,
  CreativeGroup,
  PublishCampaignDTO,
  SavedAudience,
  Seconds,
} from '@marketing-milk/interfaces'
import { CampaignGoalOptimizationI, campaignGoalOptimizations } from 'app/services'
import { exists, rejectUndefined } from 'app/util'
import { addMonths, fromUnixTime, getUnixTime, isBefore } from 'date-fns'
import {
  add,
  all,
  any,
  concat,
  filter,
  flattenDeep,
  isEmpty,
  isEqual,
  map,
  mean,
  pipe,
  prop,
  propEq,
  reduce,
  update,
} from 'lodash/fp'
import { SpendLimits } from 'pages/business/campaign-builder/steps/audience'
import { toast } from 'react-toastify'
import { AdPair } from '../forms/steps'
import { isValidCreative } from './campaign.helpers'
import { zonedTimeToUtc } from 'date-fns-tz'

export const getGoalFromOptimization = (
  type?: PublishCampaignDTO['campaign']['goal']
): CampaignGoalOptimizationI | undefined =>
  campaignGoalOptimizations.find(optimization => type === optimization.type)

export const getAudiencesById = (ids: number[] = [], audiences: SavedAudience[] = []) =>
  audiences.filter(({ id }) => ids.includes(id))

export const getDefaultAudiences = (audiences: SavedAudience[]) =>
  audiences.filter(audience => audience.isDefault)

export const getDefaultAudienceIDs = (audiences: SavedAudience[]) =>
  getDefaultAudiences(audiences).map(audience => audience.id)

export const sanitizeSpendLimits = (
  spendLimits: PublishCampaignDTO['spendLimitsByAudienceId']
): SpendLimits => (spendLimits ? spendLimits : {})

export const getAverageAudienceSize = ({
  approximateCountLowerBound,
  approximateCountUpperBound,
}: SavedAudience) => mean([approximateCountLowerBound, approximateCountUpperBound])

export const getAudienceBoundsAverageTotal = pipe(map(getAverageAudienceSize), reduce(add, 0))

export const calculateFinalBudget = (base: number, upsellPercent?: number) => {
  if (!upsellPercent) return base
  return base + base * (upsellPercent / 100)
}

export const nameIsValid = (name?: string) => (name ? exists(name) && name.length < 256 : false)
export const goalIsValid = (goal?: CampaignConfig['goal']) => (goal ? exists(goal) : false)
export const addtlGoalCampaignConfigIsValid = (campaignConf: CampaignConfig) =>
  // valid if not a web campaign
  ![CampaignGoalType.ECommerceSales, CampaignGoalType.WebsiteSessions].includes(
    campaignConf.goal
  ) ||
  // of valid if one of the fields are set
  !!campaignConf.customConversionID ||
  !!campaignConf.pixelType
export const audiencesAreValid = (audienceIds?: number[]) =>
  audienceIds ? exists(audienceIds) : false
export const budgetIsValid = (budget?: number) => !!(budget ? budget > 49 && budget < 10000 : false)
export const campaignHasStarted = (startTime?: number): boolean =>
  !!startTime && startTime <= Math.floor(new Date().getTime() / 1000)
export const campaignHasEnded = (endTime: number): boolean =>
  endTime <= Math.floor(new Date().getTime() / 1000)

export const nameErrorText = (name?: string) => {
  if (!name) return 'name is required'
  if (name.length > 255) return 'name must be under 256 characters'
  return ''
}

export const budgetErrorText = (budget?: number) => {
  if (!budget) return 'no budget selected'
  if (budget < 49) return 'budget must exceed $50'
  if (budget > 10000) return 'budget must be under $10,000'
  return ''
}

export const dateErrorText = ([start, end]: [Seconds | null, Seconds | null]) => {
  if (!start) return 'start date is required'
  if (!end) return 'end date is required'
  if (isBefore(end, start)) return 'end date cannot be before start date'
  return ''
}

export const campaignSettingsValidators = (dto?: PublishCampaignDTO) => {
  const nameValid = dto?.campaign?.name ? nameIsValid(dto?.campaign?.name) : false
  const budgetValid = dto?.campaign?.budget ? budgetIsValid(dto?.campaign?.budget) : false
  const goalValid = dto?.campaign?.goal
    ? goalIsValid(dto?.campaign?.goal) && addtlGoalCampaignConfigIsValid(dto.campaign)
    : false
  return [nameValid, budgetValid, goalValid]
}

export const getValidCreatives = (creativeGroups: CreativeGroup[], creatives?: AdCreative[]) =>
  pipe(
    creatives => getAdPairs(creativeGroups, creatives),
    flattenDeep,
    filter(isValidCreative),
    rejectUndefined
  )(creatives || [])

export const anyCreativesValid = (creatives: AdCreative[]) =>
  any(isEqual(true), creatives.map(isValidCreative))

export const allCreativesValid = (creatives: AdCreative[]) =>
  all(isEqual(true), creatives.map(isValidCreative))

export const campaignDtoHasValidSettings = (dto: PublishCampaignDTO) => {
  if (!nameIsValid(dto?.campaign?.name)) return false
  if (!budgetIsValid(dto?.campaign?.budget)) return false
  if (!goalIsValid(dto?.campaign?.goal)) return false
  if (!addtlGoalCampaignConfigIsValid(dto.campaign)) return false
  return true
}

export const campaignDtoHasValidCreatives = (dto: PublishCampaignDTO, creatives: AdCreative[]) => {
  if (!dto.campaign) return false
  const selectedCreatives = getAdPairs(dto.creativeGroups, creatives)
  for (const selectedCreativePair of selectedCreatives) {
    const creativePair = rejectUndefined(selectedCreativePair)
    // No Ad Pair can be empty
    if (!creativePair.length) return false
    // No Ad Pair can contain a dynamic and another creative
    if (
      creativePair.some(creative => creative.format === AdCreativeType.dynamic) &&
      creativePair.length > 1
    ) {
      return false
    }
    // Every creative selected must be properly configured
    if (!creativePair.every(isValidCreative)) return false
  }
  return true
}

export const campaignDtoHasValidAudiences = (dto: PublishCampaignDTO) => {
  if (!dto) return false
  if (!dto.audienceIDs) return false
  if (dto.audienceIDs.length === 0) return false
  return true
}

export const isValidCampaignDto = (dto: PublishCampaignDTO, creatives: AdCreative[]) => {
  if (!campaignDtoHasValidSettings(dto)) return false
  if (!campaignDtoHasValidCreatives(dto, creatives)) return false
  if (!campaignDtoHasValidAudiences(dto)) return false
  return true
}

export type CampaignFormErrors = {
  campaign?: string[]
  adsets?: string[]
  ads?: string[]
}
export const singleCampaignDtoIsValid = (
  { campaign, ...dto }: PublishCampaignDTO,
  setErrors?: React.Dispatch<React.SetStateAction<CampaignFormErrors>>
) => {
  if (!nameIsValid(campaign.name)) {
    const errorText = nameErrorText(campaign.name)
    toast.error(errorText)
    setErrors && setErrors(update('campaign', concat(errorText)))
    return false
  }

  if (!goalIsValid(campaign.goal)) {
    toast.error(`Please choose a campaign goal for ${campaign.name}`)
    if (setErrors) {
      setErrors(update('campaign', concat('goal is empty')))
      setErrors({ campaign: ['goal is empty'] })
    }
    return false
  }

  if (!budgetIsValid(campaign.budget)) {
    const budgetText = budgetErrorText(campaign.budget)
    toast.error(`[${campaign.name} Error]: ${budgetText}`)
    setErrors && setErrors(update('campaign', concat(`[${campaign.name}]: ${budgetText}`)))
  }

  const dateError = dateErrorText([campaign.startTime, campaign.endTime])

  if (exists(dateError)) {
    toast.error(dateError)
    setErrors && setErrors({ campaign: [`[${campaign.name}]: ${dateError}`] })
  }

  if (isEmpty(dto.audienceIDs)) {
    toast.error(`Please Select an Audience for ${campaign.name}`)
    setErrors && setErrors({ adsets: [`No audience selected for ${campaign.name}`] })
    return false
  }

  if (any(isEmpty, dto.creativeGroups) || isEmpty(dto.creativeGroups)) {
    toast.error(`Please Select an Creatives for ${campaign.name}`)
    setErrors && setErrors({ ads: ['All Variations must have at least one creative selected'] })
    return false
  }

  return true
}

export const findCreativeById = (id?: number, creatives?: AdCreative[]) =>
  creatives?.find(propEq('id', id))

export const filterCreativesByGoal = (creatives?: AdCreative[], goal?: `${CampaignGoalType}`) =>
  creatives?.filter(c => (goal === 'lead_generation' ? c.leadCardUsed : !c.leadCardUsed))

export const getSelectableCreatives = (creatives: AdCreative[]) =>
  creatives
    .filter(creative => creative.status !== AdCreativeStatus.DELETED) // not deleted
    .filter(creative => creative.status !== AdCreativeStatus.WITH_ISSUES) // has no issues
    .filter(isValidCreative)

export const getCostPerText = (goal: `${CampaignGoalType}`) => {
  if (goal === 'lead_generation') return 'cost per lead genenerated'
  if (goal === 'ecommerce_sales') return 'cost per website purchase'
  if (goal === 'website_sessions') return 'cost per visit'
  if (goal === 'in_store_sales') return 'cost per sale'
  return ''
}

export const getGoalColorClasses = (goal: `${CampaignGoalType}`) => {
  if (goal === 'lead_generation') return 'text-blue-800 bg-blue-200'
  if (goal === 'ecommerce_sales') return 'text-green-800 bg-green-200'
  if (goal === 'website_sessions') return 'text-yellow-800 bg-yellow-200'
  if (goal === 'in_store_sales') return 'text-orange-800 bg-orange-200'
  return 'text-orange-800 bg-orange-200'
}

export const getIntervalFromDto = (dto: PublishCampaignDTO) => ({
  start: fromUnixTime(dto.campaign.startTime),
  end: fromUnixTime(dto.campaign.endTime),
})

export const getDefaultDTO = (
  dto: PublishCampaignDTO | undefined,
  timezone = 'America/Chicago'
): NonNullable<PublishCampaignDTO> => {
  if (dto) return dto
  return {
    campaign: {
      budget: 250,
      startTime: getUnixTime(zonedTimeToUtc(new Date().setHours(12, 0, 0, 0), timezone)),
      endTime: getUnixTime(
        addMonths(zonedTimeToUtc(new Date().setHours(12, 0, 0, 0), timezone), 3)
      ),
      frequency: 7, // Frequency should always be 7
      goal: CampaignGoalType.LeadGeneration,
      name: 'New Campaign',
    },
    audienceIDs: [],
    creativeGroups: [[]],
  }
}

export const getAdPairs = (
  creativeGroups: PublishCampaignDTO['creativeGroups'],
  creatives: AdCreative[]
) =>
  creativeGroups.map(group =>
    group.map(creativeId => creatives?.find(({ id }) => creativeId === id))
  )

export const getCreativeGroups = (adPairs: AdPair[]) => adPairs.map(pair => pair?.map(prop('id')))

export const combineTime = (newDate: Date, newTime: Date, timezone: string): Date => {
  const zonedTime = new Date(
    `${newDate.toDateString()} ${newTime.toTimeString().substring(0, 8)}` // trim off TZ meta data
  )
  const utcDate = zonedTimeToUtc(zonedTime, timezone)
  return utcDate
}
