import React from 'react'
import TextField from '@material-ui/core/TextField'

export interface FormNameProps {
  editable: boolean
  formName?: string
  onChangeFormName(name: string): void
}

export function FormName({ editable, formName, onChangeFormName }: FormNameProps) {
  return (
    <div className="form-group">
      <TextField
        error={!formName}
        helperText={!formName && 'Form name is a required field'}
        id="form-name"
        label="Form Name *"
        placeholder="e.g. Free Class Offer"
        fullWidth
        disabled={!editable}
        value={formName}
        onChange={e => onChangeFormName(e.target.value as string)}
        InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
        inputProps={{ 'data-testid': 'form-name-input' }}
      />
    </div>
  )
}
