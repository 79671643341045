import { useEffect, useState } from 'react'
import { MultiOptionItem } from 'components/multi-option-menu'
import { FacebookTargetingSpec } from '@marketing-milk/interfaces'
import { userTraitCategories } from 'pages/business/audiences/audience-builder/hooks/steps/demographics'

export function useGetUserTraits(
  targetSpec: FacebookTargetingSpec,
  type: 'include' | 'exclude',
  refresh = 0
): MultiOptionItem[] {
  const [traits, setTraits] = useState<Array<MultiOptionItem>>([])

  useEffect(() => {
    getTraits()
  }, [refresh])

  function getTraits() {
    const userTraits: MultiOptionItem[] = []
    if (type === 'include' && targetSpec.flexible_spec && targetSpec.flexible_spec.length > 0) {
      userTraitCategories.forEach(
        category =>
          //@ts-ignore
          targetSpec.flexible_spec[0][category] &&
          //@ts-ignore
          targetSpec.flexible_spec[0][category].length > 0 &&
          //@ts-ignore
          targetSpec.flexible_spec[0][category].map(x =>
            userTraits.push({ label: x.name!, value: x.id!, type: category })
          )
      )
    }

    if (type === 'exclude' && targetSpec.exclusions) {
      userTraitCategories.forEach(
        category =>
          //@ts-ignore
          targetSpec.exclusions[category] &&
          //@ts-ignore
          targetSpec.exclusions[category].length > 0 &&
          //@ts-ignore
          targetSpec.exclusions[category].map(x =>
            userTraits.push({ label: x.name!, value: x.id!, type: category })
          )
      )
    }
    setTraits(userTraits)
  }

  return traits
}
