import React, { useContext } from 'react'
import { SinglePreview } from './previews/SinglePreview'
import { DynamicPreview } from './previews/DynamicPreview'
import { AdCreativeType } from '@marketing-milk/interfaces'
import { creativeContext } from '../context/CreativeContext'

export function AdPreview() {
  const { creative } = useContext(creativeContext)
  return creative?.format === AdCreativeType.dynamic ? <DynamicPreview /> : <SinglePreview />
}
