import { useGetBusinessID } from '@marketing-milk/frontend'
import { AdCreativeType } from '@marketing-milk/interfaces'
import { TextField } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Button, Popover } from '@mui/material'
import { ServerError } from 'components/server-request/server-error/ServerError'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useCreatives } from '../../useCreatives'

type Props = {
  sprintId?: number
  children?: React.ReactElement
}
export const NewCreativeModal = ({ children, sprintId }: Props) => {
  const history = useHistory()
  const businessID = useGetBusinessID()
  const [name, setName] = useState<string>()
  const [error, setError] = useState<Error>()
  const [type, setType] = useState<AdCreativeType>()
  const [associatedSprintId, setAssociatedSprintId] = useState<number>()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const buttonRef = useRef<HTMLElement>(null)
  const portalRef = useRef<HTMLDivElement>(null)
  const { createCreative } = useCreatives(businessID)

  const onSubmit = async () => {
    try {
      setLoading(true)
      if (!name || !type) return

      const newCreative = await createCreative(name, type, {
        // @ts-ignore
        sprintId: sprintId,
      })

      history.push(`/${businessID}/${sprintId ? 'sprints' : 'creatives'}/${newCreative.id}`)
    } catch (e: any) {
      setError(e)
    }

    setLoading(false)
    setIsOpen(false)
  }

  return (
    <>
      <span className="cursor-pointer" ref={buttonRef} onClick={() => setIsOpen(o => !o)}>
        {children}
      </span>

      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={() => setIsOpen(false)}
        ref={portalRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div className="border-b">
          <div className="p-4 space-y-4">
            <TextField
              id="creative-name-input"
              label="Creative Name"
              inputProps={{ 'aria-label': 'creative name' }}
              placeholder="e.g. Free Trial Creative"
              fullWidth
              autoFocus
              value={name}
              onChange={e => setName(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />

            <div>
              <InputLabel shrink>Creative Type</InputLabel>
              <ToggleButtonGroup exclusive value={type} onChange={(e, value) => setType(value)}>
                <ToggleButton
                  value={AdCreativeType.single_image}
                  aria-label="single image"
                  data-testid="single-creative-type"
                >
                  Single Image / Video
                </ToggleButton>
                <ToggleButton
                  value={AdCreativeType.dynamic}
                  aria-label="dynamic"
                  data-testid="dynamic-creative-type"
                >
                  Dynamic
                </ToggleButton>
                <ToggleButton
                  value={AdCreativeType.carousel}
                  aria-label="carousel"
                  data-testid="carousel-creative-type"
                >
                  Carousel
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>

        <div className="flex p-2 w-full  justify-between items-center">
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>

          <Button variant="contained" color="primary" disabled={!name || !type} onClick={onSubmit}>
            <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'save'} mr-2 text-white`} />
            {isLoading ? 'Creating' : 'Save'}
          </Button>
        </div>
      </Popover>

      {error && <ServerError error={error} onClose={() => setError(undefined)} />}
    </>
  )
}
