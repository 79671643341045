import { AdCreative, AdCreativeType, AssetApprovalStatus } from '@marketing-milk/interfaces'
import { isBefore } from 'date-fns'
import { sortBy, reverse } from 'lodash/fp'
import { isValidCreative } from 'pages/business/campaigns/helpers/campaign.helpers'
import * as Mocks from '../../campaigns/mockData'
export const mockCreatives = [
  Mocks.mockCarouselCreative,
  Mocks.mockCarouselCreative,
  Mocks.mockDraftCreative,
  Mocks.mockDraftCreative,
  Mocks.mockApprovedCreative,
  Mocks.mockApprovedCreative,
]

export type CreativeFilters = Partial<{
  ready: boolean
  draft: boolean
  rejected: boolean
  pending: boolean
  published: boolean
  image: boolean
  video: boolean
  carousel: boolean
  dynamic: boolean
  type?: `${AdCreativeType}`[]
  status?: `${AssetApprovalStatus}`[]
}>

export const creativeFilterPredicate = (filters: CreativeFilters) => (creative: AdCreative) => {
  const type =
    filters.type && filters.type.length > 0 ? filters.type.includes(creative.format) : true
  const status =
    filters.status && filters.status.length > 0
      ? filters.status.includes(creative.reviewStatus)
      : true
  const image = filters.image ? creative.format !== AdCreativeType.single_image : true
  const carousel = filters.carousel ? creative.format !== AdCreativeType.carousel : true
  const dynamic = filters.dynamic ? creative.format !== AdCreativeType.dynamic : true
  const video = filters.video ? creative.format !== AdCreativeType.single_video : true
  const approved = filters.ready ? creative.reviewStatus !== AssetApprovalStatus.Ready : true
  const draft = filters.draft ? creative.reviewStatus !== AssetApprovalStatus.Draft : true
  const rejected = filters.rejected ? creative.reviewStatus !== AssetApprovalStatus.Rejected : true
  const pending = filters.pending ? creative.reviewStatus !== AssetApprovalStatus.Pending : true
  const published = filters.published
    ? creative.reviewStatus !== AssetApprovalStatus.Published
    : true
  return (
    image &&
    carousel &&
    dynamic &&
    video &&
    approved &&
    draft &&
    rejected &&
    pending &&
    published &&
    type &&
    status
  )
}

export const sortByUpdatedAt = (creatives: AdCreative[]) =>
  creatives.sort((A, B) => {
    if (!A.lastUsed || !B.lastUsed) return 1
    if (isBefore(new Date(A.lastUsed), new Date(B.lastUsed))) return 1
    return -1
  })

export const creativeSorter = (sortType: string, creatives: AdCreative[]) => {
  if (sortType === 'Last Used') return sortByUpdatedAt(creatives)
  if (sortType === 'Name') return reverse(sortBy('name', creatives))
  return creatives
}

export const sortSwitch = <T>(items: T[], direction: 'ASC' | 'DESC') => {
  if (direction === 'DESC') return items
  return reverse(items)
}

export const isReadyForApprovalSubmission = (creative: AdCreative) =>
  isValidCreative(creative) &&
  (creative.reviewStatus === AssetApprovalStatus.Draft ||
    creative.reviewStatus === AssetApprovalStatus.Rejected) &&
  !creative.isHidden
