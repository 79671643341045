import { getDistance } from 'geolib'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { useState, useEffect } from 'react'
import { NestAPI } from '@marketing-milk/frontend'
import { CompetitionFinderDetails } from '@marketing-milk/interfaces'
import { Place, PlacePhoto } from '@googlemaps/google-maps-services-js'
import {
  useGetBusiness,
  useQueryStringParams,
  useQueryStringManager,
} from '@marketing-milk/frontend'

export type competitionIconType = 'business' | 'competitor' | 'closed-competitor'

export interface CompetitionParams {
  radius: number
  competitorPlaceID: string
}

export async function getCompetitionData(
  businessID: string | number,
  radius: number
): Promise<Place[]> {
  const response: AxiosResponse<Place[]> = await NestAPI.get(
    `businesses/${businessID}/competition?radius=${radius}`
  )
  return response.data
}

export function useLocalCompetition(businessID: string | number, radius: number) {
  return useQuery(['places', businessID, radius], () => getCompetitionData(businessID, radius), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

export async function getCompetitorData(
  businessID: string | number,
  placeID: string
): Promise<Place> {
  const response: AxiosResponse<Place> = await NestAPI.get(
    `businesses/${businessID}/competition/${placeID}`
  )
  return response.data
}

export function useCompetitorData(businessID: string | number, placeID: string) {
  return useQuery(['placeID', businessID, placeID], () => getCompetitorData(businessID, placeID), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

export async function patchCompetitionDetails(
  businessID: string | number,
  details: CompetitionFinderDetails
) {
  await NestAPI.patch(`businesses/manage/${businessID}/competition-details`, details)
}

export function useLocalCompetitionFinder(canToggle: boolean) {
  const MIN_GOOGLE_RADIUS = 1
  const MAX_GOOGLE_RADIUS = 30
  const DEFAULT_COORDINATES = {
    lat: 38.6287618,
    lng: -90.1939502,
  }
  const business = useGetBusiness()
  const businessCoordinates = {
    lat: Number(business.latitude) ?? DEFAULT_COORDINATES.lat,
    lng: Number(business.longitude) ?? DEFAULT_COORDINATES.lng,
  }
  const queryParams = useQueryStringParams<CompetitionParams>()
  const [searchRadius, setSearchRadius] = useState(queryParams?.radius ?? 1)
  const { data, isLoading } = useLocalCompetition(business.id, searchRadius)
  const [selectedCompetitorID, setSelectedCompetitorID] = useState<string | undefined>(
    queryParams?.competitorPlaceID ?? undefined
  )
  const queryStringManager = useQueryStringManager<CompetitionParams>({
    radius: searchRadius,
    competitorPlaceID: selectedCompetitorID ?? '',
  })
  const [showSearchRadius, setShowSearchRadius] = useState(false)
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false)

  const hasBusinessData = business.latitude && business.longitude && business.googleType
  const competitorData = useCompetitorData(business.id, selectedCompetitorID ?? '0')
  const selectedCompetitorDetails = competitorData?.data

  // turn on/off local competition finder map pins and legend/tools
  const [featureActive, setFeatureActive] = useState(true)

  // if competitor selected open drawer, if not then close drawer
  useEffect(() => {
    setDetailsDrawerOpen(!!selectedCompetitorID)
  }, [selectedCompetitorID])

  // Utils
  const getDistanceFromBusiness = (
    businessCoordinates: { lat: number; lng: number },
    place: Place
  ) => {
    // distance in meters divided by meters in a mile
    const distance =
      getDistance(businessCoordinates, {
        lat: place.geometry?.location.lat ?? 0,
        lng: place.geometry?.location.lng ?? 0,
      }) / 1609.34

    return distance.toFixed(2) + ' miles away'
  }

  const isBusinessOperational = (place: Place) => place.business_status === 'OPERATIONAL'

  function getPlacePhotos(photos?: PlacePhoto[]) {
    if (!photos || !photos.length || photos.length === 0) return []
    return photos.map(photo => ({
      height: photo.height,
      src: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=${photo.width}&photo_reference=${photo.photo_reference}&key=AIzaSyDj3R90v1czXSHvP_5Hx5CepflyWCyvfeI`,
    }))
  }

  const getMapIcon = (type: competitionIconType) => {
    // Icon generated using https://apidocs.geoapify.com/playground/icon API
    const color = type === 'business' ? '%23659ee8' : type === 'competitor' ? 'red' : '%23998585'
    const icon = type === 'business' ? 'home' : type === 'competitor' ? 'building' : 'times'
    const size = type === 'business' ? 'x-large' : 'small'
    return `https://api.geoapify.com/v1/icon/?type=material&color=${color}&size=${size}&icon=${icon}&iconType=awesome&apiKey=bb56b88c13cf464c9912cd555d12cc5d`
  }

  return {
    data,
    isLoading,
    setSearchRadius,
    setSelectedCompetitorID,
    setDetailsDrawerOpen,
    showSearchRadius,
    setShowSearchRadius,
    searchRadius,
    business,
    detailsDrawerOpen,
    hasBusinessData,
    businessCoordinates,
    MIN_GOOGLE_RADIUS,
    MAX_GOOGLE_RADIUS,
    DEFAULT_COORDINATES,
    getDistanceFromBusiness,
    selectedCompetitorDetails,
    getPlacePhotos,
    isBusinessOperational,
    getMapIcon,
    queryStringManager,
    featureActive,
    setFeatureActive,
    canToggle,
  }
}
