import Popover from '@material-ui/core/Popover'
import InfoIcon from '@mui/icons-material/Info'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@material-ui/core'
import { useFetchApproximateAudienceSize } from 'hooks/audiences/fetch-approximate-audience-size'
import { FacebookTargetingSpec } from '@marketing-milk/interfaces'
import { FormatService, useGetBusinessID } from '@marketing-milk/frontend'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
      maxWidth: '200px',
    },
  })
)

export interface SizeEstimateProps {
  targetingSpec: FacebookTargetingSpec
}

export function SizeEstimate({ targetingSpec }: SizeEstimateProps) {
  const classes = useStyles()
  const businessID = useGetBusinessID()
  const [approxSizeRefresh, setApproxSizeRefresh] = useState(0)
  const approximateAudienceSize = useFetchApproximateAudienceSize(
    businessID,
    approxSizeRefresh,
    targetingSpec
  )
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    setApproxSizeRefresh(_ => _ + 1)
  }, [JSON.stringify(targetingSpec)])
  return (
    <>
      <div className="">
        <Typography variant="h5" className="mb-2">
          Estimated Audience Size
          <span
            className="ml-2"
            // popover props
            aria-owns={open ? 'audience-size-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
              setAnchorEl(event.currentTarget)
            }
            onMouseLeave={() => setAnchorEl(null)}
          >
            <InfoIcon className="text-warning mt-n1" fontSize="medium" />
          </span>
        </Typography>
        <Popover
          id="audience-size-popover"
          className={classes.popover}
          classes={{ paper: classes.paper }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setAnchorEl(null)}
          disableRestoreFocus
        >
          <Typography>Audience size may differ when including custom audiences.</Typography>
        </Popover>
      </div>
      <div>
        {approximateAudienceSize.loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="body1">
            {approximateAudienceSize?.data
              ? `${FormatService.getValue(
                  Number(approximateAudienceSize.data.lowerBound)
                )} - ${FormatService.getValue(Number(approximateAudienceSize.data.upperBound))}`
              : approximateAudienceSize?.error?.message
              ? 'Select a location to calculate an audience size estimate'
              : 'could not calculate approximate size...'}
          </Typography>
        )}
      </div>
    </>
  )
}
