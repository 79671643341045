import { Footer } from '../footer/Footer'
import React, { useState } from 'react'
import { TopNav } from '../nav/top-nav'
import { useScreenSize, useWindowSize } from 'hooks'
import { DesktopNav, MobileNav } from '../nav/SideNav'
import { MobileHeader } from '../nav/MobileHeader'

export const LayoutWrapper: React.FC = ({ children }) => {
  const { isDesktop, isMobile } = useScreenSize()
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [height, width] = useWindowSize()

  return (
    <div style={{ height, width }} className="overflow-hidden">
      {isMobile && <MobileHeader navOpen={mobileNavOpen} setNavOpen={setMobileNavOpen} />}
      <div className="flex h-full w-full">
        {isDesktop ? <DesktopNav /> : <MobileNav navOpen={mobileNavOpen} />}
        <div className="flex flex-col h-full w-full">
          <TopNav />
          <div className="flex-grow w-full overflow-hidden">{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  )
}
