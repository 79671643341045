import React, { useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { UserLayout } from 'components/layout/UserLayout'
import { CreativeCard } from '../creatives/CreativeCard/CreativeCard'
import { CreativePageHeader } from '../creatives/components/CreativePageHeader/CreativePageHeader'
import CreativePageSidebar from '../creatives/components/CreativePageSidebar/CreativePageSidebar'
import { FullPageContent } from 'components/layout/FullPageLayout/Content/FullPageContent'
import { useGetBusinessID } from '@marketing-milk/frontend'
import { useHistory } from 'react-router-dom'
import { useCreatives } from './useCreatives'
import { NoItemsCreated } from 'components/NoItemsCreated/NoItemsCreated'
import { CalendarToday } from '@material-ui/icons'
import { isEmpty } from 'lodash'

export const Creatives = () => {
  const businessID = useGetBusinessID()
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const {
    creatives,
    count,
    current,
    isFetchingNextPage,
    setSortProp,
    setSortDirection,
    sortDirection,
    setSearchTerm,
    searchTerm,
    fetchNextPage,
    filterValues,
    setFilterValues,
  } = useCreatives(businessID, { take: 50, sortProp: 'lastUsed' })
  const creativeListRef = useRef<HTMLDivElement>(null)
  const history = useHistory()

  return (
    <UserLayout>
      <div className="flex flex-col w-full h-full max-h-full justify-between items-stretch">
        {/* Header */}
        <CreativePageHeader
          creatives={creatives}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          paginationData={creatives ? [creatives.length, count] : [0, 0]}
        />

        <FullPageContent>
          {/* Filters / Actions */}
          {sidebarOpen && (
            <CreativePageSidebar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              filters={filterValues || {}}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              setFilters={setFilterValues}
              setSort={setSortProp}
            />
          )}

          {/* Body */}
          <div
            className="w-full h-full overflow-y-scroll"
            onScroll={() => {
              if (isFetchingNextPage) return
              if (current === count) return
              if (!creativeListRef.current) return
              const { scrollHeight, scrollTop, clientHeight } = creativeListRef.current
              const currentPosition = scrollTop + clientHeight
              const positionToReach = scrollHeight - 100
              if (currentPosition > positionToReach) fetchNextPage()
            }}
            ref={creativeListRef}
          >
            <div className="grid justify-items-center w-full sm:grid-cols-2 md:grid-cols-3 3xl:grid-cols-5 4xl:grid-cols-5 sm:gap-5">
              {(creatives ?? []).map(creative => (
                <CreativeCard
                  key={creative.id}
                  creative={creative}
                  onSelect={() => history.push(`/${businessID}/creatives/${creative.id}`)}
                  pillDisplayed={'format'}
                  propertiesDisplayed={['name']}
                />
              ))}
            </div>

            {isFetchingNextPage ? (
              <div className="w-full relative flex justify-center pb-80">
                <CircularProgress color="primary" size={100} />
              </div>
            ) : (
              isEmpty(creatives) && (
                <div className="w-full flex justify-center">
                  <NoItemsCreated
                    title="No Creatives are available on MM for this business"
                    subtitle="Click the button below to make a creative"
                  >
                    <button
                      type="button"
                      className="max-w-3xl my-8 block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={console.log}
                    >
                      <CalendarToday fontSize="large" />

                      <span className="mt-2 block text-lg font-medium text-gray-900">
                        Make Creative
                      </span>
                    </button>
                  </NoItemsCreated>
                </div>
              )
            )}
          </div>
        </FullPageContent>
      </div>
    </UserLayout>
  )
}

export default Creatives
