import { useQuery } from 'react-query'
import { CampaignGoals, CampaignGoalType } from '@marketing-milk/interfaces'
import { useCreateFetchHook } from '@marketing-milk/frontend'
import { campaignService } from 'app/services/campaign-builder/campaign'
import { twentyFourHoursInMs } from '../../constants'
import { useMemo, useState } from 'react'

export function useGetCampaignGoalsByBusiness(
  businessID: string,
  refresh = 0
): CampaignGoals | undefined {
  return useCreateFetchHook<CampaignGoals>(
    () => campaignService.getCampaignOptimizationsByBusiness(businessID),
    refresh
  )
}

// Disable all refetching behavior. Always pull from cache, since this data
// changes so rarely. Will only get fresh data on a page refresh.
const queryOpts = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: twentyFourHoursInMs,
}

export function useCampaignGoals(businessID: number) {
  const queryData = useQuery(
    ['campaignGoals', businessID],
    () => campaignService.getCampaignOptimizationsByBusiness(businessID),
    queryOpts
  )

  const enabledGoals = useMemo(() => {
    if (!queryData?.data) return []
    return queryData.data.optimizations.filter(goal => goal.enabled).map(goal => goal.type)
  }, [queryData.data])

  // const queryData = useQuery(
  //   ['campaignGoals', businessID],
  //   async () => {
  //     const goals = await {
  //       optimizations: [
  //         {
  //           type: CampaignGoalType.InStoreSales,
  //           enabled: true,
  //           kpi: { kpiName: 'foo', costPer: 5 },
  //         },
  //         {
  //           type: CampaignGoalType.LeadGeneration,
  //           enabled: true,
  //           kpi: { kpiName: 'foo', costPer: 5 },
  //         },
  //       ],
  //       cpm: 5,
  //     }
  //     setEnabledGoals([CampaignGoalType.ECommerceSales, CampaignGoalType.LeadGeneration])
  //     return goals
  //   },
  //   queryOpts
  // )

  return { ...queryData, enabledGoals }
}
