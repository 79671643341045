import React from 'react'
import { MultiOptionItem } from '../'
import { DropdownItem } from 'reactstrap/lib'

export interface MenuOptionProps {
  option: MultiOptionItem
  selected: boolean
  onViewChildren: () => void
  onAddOption: () => void
  onRemoveOption: () => void
}

export function MenuOption(props: MenuOptionProps) {
  const { option, selected, onAddOption, onRemoveOption, onViewChildren } = props

  const handleClick = () => {
    if (option.children) return onViewChildren()
    if (selected) return onRemoveOption()
    return onAddOption()
  }

  return (
    <DropdownItem className="d-block" onClick={handleClick}>
      {option.label}
      {selected && (
        <>
          <i className="fad fa-check-circle text-success text-inherit ml-2" />
          <i className="fas fa-times text-danger text-inherit float-right" />
        </>
      )}
      {option.children && <i className="fa fa-chevron-right text-brand text-inherit float-right" />}
    </DropdownItem>
  )
}
