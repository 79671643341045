import React, { useState } from 'react'
import { checkValidUrl } from 'app/util'
import Link from '@material-ui/core/Link'
import Alert from '@material-ui/lab/Alert'
import TextField from '@material-ui/core/TextField'

export interface PrivacyLinkProps {
  editable: boolean
  link: string
  onChangeLink(link: string): void
}

export function PrivacyLink({ editable, link, onChangeLink }: PrivacyLinkProps) {
  const [linkInfo, setLinkInfo] = useState(false)
  const validatedUrl = checkValidUrl(link)
  return (
    <div className="form-group">
      <TextField
        error={!validatedUrl}
        helperText={
          !validatedUrl && 'Must be a valid web address. Be sure to include http:// or https://'
        }
        label="Link *"
        fullWidth
        disabled={!editable}
        value={link}
        onChange={event => onChangeLink(event.target.value as string)}
        onFocus={() => setLinkInfo(true)}
        onBlur={() => setLinkInfo(false)}
        InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
        inputProps={{ 'data-testid': 'privacy-link-input' }}
        placeholder="https://mywebsite.com/privacy"
      />
      {linkInfo && (
        <Alert variant="standard" severity="info" className="mb-2">
          Your privacy policy link can't go directly to a PDF file, JPEG file, image or download.{' '}
          <Link
            href="https://www.facebook.com/policies/ads/prohibited_content/non_functional_landing_page"
            target="_blank"
          >
            Learn More
          </Link>
        </Alert>
      )}
    </div>
  )
}
