import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { routerContext } from 'components/router/context/RouterContext'
import { RouterContextProps } from 'components/router/context/RouterContextTypes'

export function RouterContextProvider({ children }: RouterContextProps) {
  const location = useLocation()
  const [routes, setRoutes] = useState<string[]>([])

  useEffect(() => {
    setRoutes(_ => [..._, location.pathname + location.search])
  }, [location.pathname])

  return <routerContext.Provider value={routes}>{children}</routerContext.Provider>
}
