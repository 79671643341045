import { RenderWhenVisible, useGetBusinessID } from '@marketing-milk/frontend'
import { AdCreative } from '@marketing-milk/interfaces'
import { prop } from 'lodash/fp'
import { isValidCreative } from 'pages/business/campaigns/helpers/campaign.helpers'
import { CreativeCard } from '../../CreativeCard/CreativeCard'
import { useCreatives } from '../../useCreatives'

type Props = {
  selectedCreatives: AdCreative[]
  onSelectCreative: (creative: AdCreative) => void
}
export const BulkCreativeSelect = (props: Props) => {
  const businessId = useGetBusinessID()
  const { creatives } = useCreatives(businessId)

  const isSelected = (creative: AdCreative) =>
    props.selectedCreatives.map(prop('id')).includes(creative.id)

  return (
    <div className="grid justify-items-center w-full sm:grid-cols-1 md:grid-cols-3 3xl:grid-cols-5 4xl:grid-cols-5 sm:gap-5">
      {creatives?.filter(isValidCreative).map(creative => (
        <RenderWhenVisible>
          <CreativeCard
            tools={false}
            creative={creative}
            key={creative.id}
            onSelect={props.onSelectCreative}
            selectedText={isSelected(creative) ? 'Selected' : undefined}
          />
        </RenderWhenVisible>
      ))}
    </div>
  )
}
