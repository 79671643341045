import React from 'react'
import { toast } from 'react-toastify'
import { AudienceWizardProps } from './AudienceWizard.types'
import { AISettings, AudienceSummary } from '../steps'
import { getBaseWizardSteps } from '../BaseWizardSteps'
import { useAiAudience } from '../use-ai-audience'
import { AIAudienceDTO, SavedAudienceStandardTargetingSpec } from '@marketing-milk/interfaces'
import {
  audienceBuilderService,
  useGetBusinessID,
  Line,
  FormatService,
} from '@marketing-milk/frontend'

import { handleSubmitAudienceError } from './audience-error-handling'
import { AudienceWizard } from '../../audience-wizard'
import { ReceiptSection } from '../../../../campaign-builder/steps/receipt/section'

export function AiAudienceWizard({ onPublishWizard, audience }: AudienceWizardProps) {
  const businessID = useGetBusinessID()
  const aiAudience = useAiAudience(businessID, audience)

  async function submitAudience() {
    const dto: AIAudienceDTO = {
      name: aiAudience.audienceName,
      description: undefined,
      targetingSpec: aiAudience.targetSpec as SavedAudienceStandardTargetingSpec,
      customAudienceID: aiAudience.audienceData.customAudienceID,
      countries: aiAudience.audienceData.countries,
      ratio: aiAudience.audienceData.ratio,
      isDefault: aiAudience.isDefault,
    }

    try {
      await audienceBuilderService.publishAIAudience(businessID, dto)
      toast.success("You're audience has been created and published!")
      onPublishWizard(true)
    } catch (err) {
      handleSubmitAudienceError(err)
    }
  }

  return (
    <AudienceWizard
      steps={[
        {
          title: 'AI Settings',
          completed:
            !!aiAudience.audienceData.ratio &&
            aiAudience.audienceData.customAudienceID !== '' &&
            aiAudience.audienceData.countries.length > 0,
          canContinue:
            !!aiAudience.audienceData.ratio &&
            aiAudience.audienceData.customAudienceID !== '' &&
            aiAudience.audienceData.countries.length > 0,
          children: (
            <AISettings
              onRemoveCountry={aiAudience.onRemoveCountry}
              countryOptions={aiAudience.countries ?? []}
              onUpdateCountryCodes={aiAudience.onUpdateCountries}
              selectedCountryCodes={aiAudience.audienceData.countries}
              customAudiences={aiAudience.customAudiences ?? []}
              onSelectAudience={aiAudience.onSelectAudience}
              onUpdateRatio={aiAudience.onUpdateRatio}
              ratio={aiAudience.audienceData.ratio}
              selectedAudienceID={aiAudience.audienceData.customAudienceID}
              targetSpec={aiAudience.targetSpec}
            />
          ),
        },
        ...getBaseWizardSteps(aiAudience),
        {
          title: 'Summary',
          completed: !!aiAudience.audienceName,
          canContinue: !!aiAudience.audienceName,
          onNext: submitAudience,
          children: (
            <AudienceSummary
              includedUserTraits={aiAudience.getSelectedUserTraits()}
              excludedUserTraits={aiAudience.getExcludedUserTraits()}
              targetingSpec={aiAudience.targetSpec}
              name={aiAudience.audienceName}
              description={aiAudience.audienceDescription}
              onUpdateName={aiAudience.setAudienceName}
              onUpdateDescription={aiAudience.setAudienceDescription}
              onSetDefault={aiAudience.setIsDefault}
              isDefault={aiAudience.isDefault}
              customReceiptSection={
                <>
                  <ReceiptSection
                    title="AI Settings"
                    children={
                      <>
                        <Line
                          left="Base Audience:"
                          right={`${aiAudience.selectedAudience()?.name} (${FormatService.getValue(
                            aiAudience.selectedAudience()?.approximateCountLowerBound
                          )} - ${FormatService.getValue(
                            aiAudience.selectedAudience()?.approximateCountUpperBound
                          )})`}
                        />
                        <Line
                          left="Similarity Ratio:"
                          right={`${aiAudience.audienceData.ratio}%`}
                        />
                        <Line
                          left="Target Countries:"
                          right={aiAudience.audienceData.countries.map(
                            code =>
                              aiAudience.countries?.find(x => x.country_code === code)?.name + ', '
                          )}
                        />
                      </>
                    }
                  />
                </>
              }
            />
          ),
        },
      ]}
    />
  )
}
