import { Loading, useGetBusinessID } from '@marketing-milk/frontend'
import { AdCreative } from '@marketing-milk/interfaces'
import { Button } from '@material-ui/core'
import { Image } from '@mui/icons-material'
import { SearchInput } from 'components'
import { BlankPageLayout } from 'components/BlankPageLayout'
import { fuzzySearch } from 'components/DataTable/helpers/search.helpers'
import { SelectCreativeStatus } from 'components/FormFields/Select/SelectCreativeStatus'
import { SelectCreativeType } from 'components/FormFields/Select/SelectCreativeType'
import { NoItemsCreated } from 'components/NoItemsCreated/NoItemsCreated'
import { useScreenSize } from 'hooks'
import { useSprint } from 'hooks/api/useSprint'
import { useFetchAllAudiences } from 'hooks/audiences/useFetchAllAudiences'
import { set, sortBy } from 'lodash/fp'
import { campaignsTableSchema } from 'pages/business/campaigns/campaignsTableSchema'
import { CampaignsContent } from 'pages/business/campaigns/components/campaign-page/CampaignContent'
import { NewCreativeModal } from 'pages/business/creatives/components/NewCreativeModal/NewCreativeModal'
import { CreativeCard } from 'pages/business/creatives/CreativeCard/CreativeCard'
import { useCreatives } from 'pages/business/creatives/useCreatives'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  creativeFilterPredicate,
  CreativeFilters,
} from '../../../creatives/helpers/creative.helpers'

interface SprintsCreativesTabProps {
  sprintId: number
}

export const SprintsPublishedCampaigns = (props: SprintsCreativesTabProps) => {
  const businessID = useGetBusinessID()
  const { publishedCampaigns, refetchPublishedCampaigns, sprintCreatives } = useSprint(
    props.sprintId
  )
  const { audiences } = useFetchAllAudiences(businessID)

  useEffect(() => {
    refetchPublishedCampaigns()
  }, [])

  return (
    <BlankPageLayout title="Published Campaigns">
      {publishedCampaigns && publishedCampaigns.length > 0 ? (
        <CampaignsContent
          campaigns={publishedCampaigns}
          audiences={audiences}
          creatives={sprintCreatives}
          refetch={refetchPublishedCampaigns}
        />
      ) : (
        <NoItemsCreated
          title="No Published Campaigns"
          subtitle="You must complete your draft campaigns and publish them to facebook.  You can do this in the draft campaigns tab."
        ></NoItemsCreated>
      )}
    </BlankPageLayout>
  )
}
