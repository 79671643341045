import { Loading, useGetBusinessID } from '@marketing-milk/frontend'
import {
  AdCreativeType,
  CampaignGoalType,
  PublishCampaignDTO,
  SprintDTO,
} from '@marketing-milk/interfaces'
import { CircularProgress } from '@material-ui/core'
import { capitalCase } from 'change-case'
import { MMAlert } from 'components/Alert'
import { BlankPageLayout } from 'components/BlankPageLayout'
import { Step } from 'components/Stepper/Step'
import { Stepper } from 'components/Stepper/Stepper'
import { useCampaignGoals } from 'hooks/campaigns/use-campaign-goals-by-business'
import { flattenDeep, fromPairs, range } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getDefaultSprintDto, makeSprintCampaignConfig } from '../../sprint.helpers'
import { SprintSetupCampaignsStep } from './Steps/SprintSetupCampaignsStep'
import { SprintScaffoldCreativesStep } from './Steps/SprintScaffoldCreativesStep'
import { SprintSettingsStep } from './Steps/SprintSettingsStep'
import { useSprintUpload } from './useSprintUpload'

interface Props {
  onSubmit: (formData: SprintFormData) => void
  onClose: () => void
}

export const CreateSprintForm = (props: Props) => {
  const businessId = useGetBusinessID()
  const [amountOfCampaigns, setAmountOfCampaigns] = useState(3)
  const [sprintDto, setSprintDto] = useState<SprintDTO>(getDefaultSprintDto())
  const [campaignConfigs, setCampaignConfigs] = useState<PublishCampaignDTO['campaign'][]>([])
  const [creativeDict, setCreativeDict] = useState<CreativeDict>({
    0: [[]],
  })
  const { enabledGoals } = useCampaignGoals(Number(businessId))
  const {
    generateFullSprint,
    isSprintUploading,
    sprintUploadResults,
    isUploadCompleted,
    refetchSprints,
  } = useSprintUpload(sprintDto, campaignConfigs, creativeDict)

  useEffect(() => {
    setCampaignConfigs(
      range(0, amountOfCampaigns).map(n =>
        makeSprintCampaignConfig(n, enabledGoals[0], sprintDto, amountOfCampaigns)
      )
    )
  }, [amountOfCampaigns, sprintDto, enabledGoals])

  useEffect(() => {
    const makeCreative = (
      baseName: string,
      campaignIndex: number,
      pairIndex: number,
      creativeIndex: number
    ): CreativeScaffold => ({
      name: `${baseName} ${pairIndex + 1}${creativeIndex === 0 ? 'A' : 'B'}`,
      type: AdCreativeType.single_image,
      campaignIndex,
      creativeGroup: pairIndex,
    })

    const creativeDictionary = fromPairs(
      campaignConfigs.map((config, i): [number, CreativeScaffold[][]] => {
        const baseName = `${config.name} - Ad`
        return [i, [[makeCreative(baseName, i, 0, 0), makeCreative(baseName, i, 0, 1)]]]
      })
    )

    setCreativeDict(creativeDictionary)
  }, [campaignConfigs])

  return (
    <div style={{ width: '90vw' }}>
      {(isSprintUploading || isUploadCompleted) && (
        <BlankPageLayout
          title={
            isSprintUploading ? (
              <Loading loadingMessage="We are creating your sprint" />
            ) : (
              'Upload Results'
            )
          }
          subtitle={
            <>
              {isSprintUploading &&
                'This may take a few moments. Do not close this modal or refresh the page'}
            </>
          }
        >
          {Object.entries(sprintUploadResults).map(([type, results]) => (
            <div className="p-4 space-y-4 border-b min-w-full">
              <div className="flex space-x-4 items-center">
                <p className="font-medium text-gray-800 text-xl pb-2">{capitalCase(type)}</p>
                {!isUploadCompleted && (
                  <>
                    {type === 'sprint' && results.length === 0 && (
                      <CircularProgress color="primary" size={30} />
                    )}
                    {type === 'draftCampaigns' && results.length !== amountOfCampaigns && (
                      <CircularProgress color="primary" size={30} />
                    )}
                    {type === 'creatives' &&
                      results.length !== flattenDeep(Object.values(creativeDict)).length && (
                        <CircularProgress color="primary" size={30} />
                      )}
                  </>
                )}
              </div>
              {isUploadCompleted && results.length === 0 && (
                <MMAlert type={'info'}>
                  <p className="w-full">No {type} Added</p>
                </MMAlert>
              )}
              {results.map(result => (
                <MMAlert tooltipText={result.description} type={result.resultType}>
                  <div className="grid grid-cols-2">
                    <p>{result.name} </p>
                    <p>{result.message} </p>
                  </div>
                </MMAlert>
              ))}
            </div>
          ))}

          <button
            className="p-8 border border-dashed border-gray-300 hover:bg-gray-100 flex items-center justify-center font-bold text-lg w-full my-8"
            onClick={() => {
              refetchSprints()
              toast.warn('it could take a few moments for the new sprint to display in the U.I.')
              props.onClose()
            }}
          >
            Close Modal
          </button>
        </BlankPageLayout>
      )}

      {!isSprintUploading && !isUploadCompleted && (
        <Stepper submitButtonText="Create Sprint">
          <Step
            title={'Sprint Settings'}
            disabled={sprintDto.name.length === 0 || amountOfCampaigns < 1}
          >
            <SprintSettingsStep
              sprintDto={sprintDto}
              setSprintDto={setSprintDto}
              amountOfCampaigns={amountOfCampaigns}
              setAmountOfCampaigns={setAmountOfCampaigns}
              isEdit={false}
            />
          </Step>
          <Step title={'Scaffold Campaigns'}>
            <SprintSetupCampaignsStep
              sprintDto={sprintDto}
              campaignConfigs={campaignConfigs}
              setCampaignConfigs={setCampaignConfigs}
            />
          </Step>
          <Step title={'Scaffold Creatives'} onNext={generateFullSprint}>
            <SprintScaffoldCreativesStep
              sprintDto={sprintDto}
              campaignConfigs={campaignConfigs}
              sprintCreatives={creativeDict}
              setSprintCreatives={setCreativeDict}
            />
          </Step>
        </Stepper>
      )}
    </div>
  )
}

export type CreativeDict = {
  [campaignIndex: number]: CreativeScaffold[][]
}

export type CreativeScaffold = {
  name: string
  type: AdCreativeType
  campaignIndex: number
  creativeGroup: number
}

export type SprintFormData = SprintDTO & {
  campaignDtos: (Omit<PublishCampaignDTO, 'creativeGroups'> & {
    creativeGroups: Array<CreativeScaffold[]>
  })[]
}

export const makeSprintCampaignDto = (
  sprintFormData: SprintFormData,
  goal: CampaignGoalType,
  index: number
): SprintFormData['campaignDtos'][0] => ({
  campaign: {
    budget: Math.floor(sprintFormData.budget / sprintFormData.campaignDtos.length || 1),
    startTime: sprintFormData.startTime,
    endTime: sprintFormData.endTime,
    frequency: 3,
    goal: goal,
    name: `${sprintFormData.name} - Campaign ${index + 1}`,
  },
  audienceIDs: [],
  creativeGroups: [[]],
  publisherPlatforms: [],
  spendLimitsByAudienceId: [],
})
