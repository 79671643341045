import { SizeEstimate } from '../SizeEstimate'
import { Line } from '@marketing-milk/frontend'
import { NameInput } from 'components/name-input'
import React, { ReactNode, useState } from 'react'
import Accordion from '@material-ui/core/Accordion'
import Typography from '@material-ui/core/Typography'
import { LineAccordion } from '@marketing-milk/frontend'
import { MultiOptionItem } from 'components/multi-option-menu'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { ReceiptSection } from 'pages/business/campaign-builder/steps/receipt/section'
import {
  FacebookTargetingSpec,
  LocationCustomTargetSpec,
  LocationType,
} from '@marketing-milk/interfaces'
import {
  LocationListItem,
  targetDisplayOptions,
  userTraitCategories,
  traitCategoriesDisplay,
} from '../'
import {
  useFetchAllCustomAudiencesForBusiness,
  useGetBusinessID,
  getGender,
} from '@marketing-milk/frontend'

export interface AudienceSummaryProps {
  name: string
  isDefault: boolean
  description: string | undefined
  onUpdateName: (name: string) => void
  onSetDefault: (isDefault: boolean) => void
  onUpdateDescription: (description: string) => void
  targetingSpec: FacebookTargetingSpec
  includedUserTraits: MultiOptionItem[]
  excludedUserTraits: MultiOptionItem[]
  customReceiptSection?: ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    accordion: {
      backgroundColor: '#f7f7f7',
    },
    accordionDetails: {
      backgroundColor: '#ffffff',
    },
  })
)

export function AudienceSummary(props: AudienceSummaryProps) {
  const {
    onUpdateName,
    isDefault,
    onSetDefault,
    name,
    targetingSpec,
    includedUserTraits,
    excludedUserTraits,
    customReceiptSection,
  } = props
  const {
    geo_locations,
    custom_audiences,
    excluded_custom_audiences,
    excluded_geo_locations,
    genders,
    age_max,
    age_min,
  } = targetingSpec
  const businessID = useGetBusinessID()
  const customAudiences = useFetchAllCustomAudiencesForBusiness(businessID)
  const classes = useStyles()
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  function onCheck(checked: boolean) {
    onSetDefault(checked)
  }

  function generateUserTraits(traitGroup: MultiOptionItem[]) {
    return userTraitCategories.map((category, i) => {
      const categoryTraits = traitGroup.filter(x => x.type === category)
      return (
        <LineAccordion
          key={i}
          //@ts-ignore
          left={`${traitCategoriesDisplay[category]} (${categoryTraits.length})`}
        >
          {categoryTraits.map((trait, index: number) => (
            <Line key={index} left={trait.type} right={trait.label} />
          ))}
        </LineAccordion>
      )
    })
  }

  return (
    <div className="container-fluid audience-name-step">
      <div className="container mb-4">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h2 className="mb-2">Audience Summary</h2>
              <p className="px-2 px-md-4">Name your audience, review your settings, and publish.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-4">
        <div className="row">
          <div className="col-6">
            <div className="col-12 p-0 mt-3">
              <h5 className="text-bold mb-1">Audience Name</h5>
            </div>
            <NameInput
              placeholder="Add Audience Name..."
              defaultValue={name ?? undefined}
              onValueChange={onUpdateName}
              maxChar={30}
            />
            <div className="mt-2">
              <input
                className="mr-2"
                name="isDefault"
                type="checkbox"
                checked={isDefault}
                onChange={e => onCheck(e.target.checked)}
              />
              Make this audience a default audience?
            </div>
          </div>
          <div className="col-6">
            <SizeEstimate targetingSpec={targetingSpec} />
          </div>
        </div>
      </div>

      <div className="container mb-4">
        <hr />
        <div className="row mt-1">
          <div className="col-12">
            <h5>Review Details</h5>

            <div className={classes.root}>
              {customReceiptSection && (
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<i className="fas fa-chevron-down" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>Custom Settings</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className="container-fluid">{customReceiptSection}</div>
                  </AccordionDetails>
                </Accordion>
              )}

              <Accordion
                className={classes.accordion}
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<i className="fas fa-chevron-down" />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className={classes.heading}>User Type</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <div className="container-fluid">
                    <ReceiptSection
                      title="User Type"
                      children={
                        <>
                          <Line left="Gender:" right={genders ? getGender(genders)[1] : 'All'} />
                          <Line left="Age Range:" right={`Between ${age_min} and ${age_max}`} />
                          <Line
                            left="Location Target:"
                            right={
                              geo_locations &&
                              geo_locations.location_types &&
                              (geo_locations.location_types.length === 2
                                ? 'People Living in or recently in this location'
                                : targetDisplayOptions[
                                    geo_locations.location_types[0] as LocationType
                                  ].label)
                            }
                          />
                        </>
                      }
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className={classes.accordion}
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<i className="fas fa-chevron-down" />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography className={classes.heading}>Locations</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <div className="container-fluid">
                    <>
                      {geo_locations &&
                        geo_locations.custom_locations &&
                        geo_locations.custom_locations.map(
                          (location: LocationCustomTargetSpec, index: number) => (
                            <LocationListItem
                              index={index}
                              onSetRadius={() => {}}
                              onRemoveLocation={() => {}}
                              onUpdateActionType={() => {}}
                              location={location}
                              actionType="include"
                            />
                          )
                        )}
                      {excluded_geo_locations &&
                        excluded_geo_locations.custom_locations &&
                        excluded_geo_locations.custom_locations.map(
                          (location: LocationCustomTargetSpec, index: number) => (
                            <LocationListItem
                              index={index}
                              onSetRadius={() => {}}
                              onRemoveLocation={() => {}}
                              onUpdateActionType={() => {}}
                              location={location}
                              actionType="include"
                            />
                          )
                        )}
                    </>
                  </div>
                </AccordionDetails>
              </Accordion>

              {includedUserTraits && includedUserTraits.length > 0 && (
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'panel4'}
                  onChange={handleChange('panel4')}
                >
                  <AccordionSummary
                    expandIcon={<i className="fas fa-chevron-down" />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography className={classes.heading}>Included User Traits</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className="container-fluid">
                      <ReceiptSection
                        title="Included User Traits"
                        children={generateUserTraits(includedUserTraits)}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}

              {excludedUserTraits && excludedUserTraits.length > 0 && (
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'panel5'}
                  onChange={handleChange('panel5')}
                >
                  <AccordionSummary
                    expandIcon={<i className="fas fa-chevron-down" />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography className={classes.heading}>Excluded User Traits</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className="container-fluid">
                      <ReceiptSection
                        title="Excluded User Traits"
                        children={generateUserTraits(excludedUserTraits)}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}

              {custom_audiences && custom_audiences.length > 0 && (
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'panel6'}
                  onChange={handleChange('panel6')}
                >
                  <AccordionSummary
                    expandIcon={<i className="fas fa-chevron-down" />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography className={classes.heading}>Included Audiences</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className="container-fluid">
                      <ReceiptSection
                        title="Included Audiences"
                        children={
                          <>
                            {custom_audiences.map((audience, index: number) => (
                              <Line
                                key={index}
                                left={index + 1}
                                right={
                                  customAudiences?.find(c => c.facebookID === audience.id)?.name
                                }
                              />
                            ))}
                          </>
                        }
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}

              {excluded_custom_audiences && excluded_custom_audiences.length > 0 && (
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'panel6'}
                  onChange={handleChange('panel6')}
                >
                  <AccordionSummary
                    expandIcon={<i className="fas fa-chevron-down" />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography className={classes.heading}>Excluded Audiences</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className="container-fluid">
                      <ReceiptSection
                        title="Excluded Audiences"
                        children={
                          <>
                            {excluded_custom_audiences.map((audience, index: number) => (
                              <Line
                                key={index}
                                left={index + 1}
                                right={
                                  customAudiences?.find(c => c.facebookID === audience.id)?.name
                                }
                              />
                            ))}
                          </>
                        }
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
