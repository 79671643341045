import { chunk } from 'lodash'
import './single-preview.scss'
import { getPreview } from '../views/getPreview'
import { AdCreativeType } from '@marketing-milk/interfaces'
import React, { useContext, useEffect, useState } from 'react'
import { creativeContext } from '../../context/CreativeContext'
import { AdPreviewBlock, basicAdPreviewWire } from '../AdPreview.type'
import { adCreativeService, useGetBusinessID } from '@marketing-milk/frontend'
import { ServerError } from 'components/server-request/server-error/ServerError'

export function SinglePreview() {
  const businessID = useGetBusinessID()
  const [error, setError] = useState<Error>()
  const [previews, setPreviews] = useState<AdPreviewBlock[]>([])
  const { creative, preview } = useContext(creativeContext)

  useEffect(() => {
    async function generatePreviews(): Promise<AdPreviewBlock[]> {
      const basicPreviewToCoolPreviews = basicAdPreviewWire(preview)
      const mobilePreview = await adCreativeService.getAdPreview(
        businessID,
        creative.id,
        basicPreviewToCoolPreviews[0]
      )
      const desktopPreview = await adCreativeService.getAdPreview(
        businessID,
        creative.id,
        basicPreviewToCoolPreviews[1]
      )
      return [
        {
          format: basicPreviewToCoolPreviews[0],
          iframe: mobilePreview,
        },
        {
          format: basicPreviewToCoolPreviews[1],
          iframe: desktopPreview,
        },
      ]
    }

    async function initGeneration() {
      try {
        setPreviews([])
        const previewsToGenerate = creative!.format !== AdCreativeType.dynamic ? 1 : 3
        const previews = await Promise.all(
          [...Array(previewsToGenerate)].map(_ => generatePreviews())
        )
        setPreviews(previews.flat())
      } catch (e: any) {
        setError(e)
      }
    }

    if (creative) {
      initGeneration()
    }
  }, [creative, preview])

  return (
    <>
      <div className="container-fluid single-preview" style={{ marginTop: 40, marginLeft: 20 }}>
        {chunk(previews, 2).map((previews: AdPreviewBlock[], index: number) => (
          <div className="row mb-4" key={index}>
            {previews.map((_: AdPreviewBlock, index: number) => (
              <div className="mr-5" key={index}>
                {getPreview(_.format, _.iframe)}
              </div>
            ))}
          </div>
        ))}
      </div>
      {error && <ServerError error={error} onClose={() => setError(undefined)} />}
    </>
  )
}
