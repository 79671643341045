import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import Typography from '@material-ui/core/Typography'
import { audiencePaths } from '../../pages/business/audiences/audience-builder'
import { FormatService } from '@marketing-milk/frontend'
import { SavedAudience } from '@marketing-milk/interfaces'

export const AudienceChip = ({ audience }: { audience: SavedAudience }) => {
  const audienceType = audiencePaths.find(path => path.type === audience.type)
  const audienceRange = `${FormatService.getValue(
    audience.approximateCountLowerBound
  )} - ${FormatService.getValue(audience.approximateCountUpperBound)}`
  return (
    <Chip
      data-testid="audience-chip"
      className="mb-3 mr-3 bg-brand text-white"
      avatar={
        <Avatar
          data-testid="audience-icon"
          style={{ backgroundColor: 'white' }}
          src={audienceType?.icon}
          alt={audienceType?.title + ' icon'}
        />
      }
      label={
        <Typography variant="subtitle1" className="text-bold ml-2">
          <span className="pr-2">{audience.name}</span> |{' '}
          <span data-testid="adset-range" className="pl-2">
            {audienceRange}
          </span>
        </Typography>
      }
    />
  )
}
