import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { leadCardDTOWire } from './wizard/lead-card.wire'
import CardContent from '@material-ui/core/CardContent'
import { UserLayout } from 'components/layout/UserLayout'
import { useGetLeadCardsForBusiness } from 'hooks/facebook'
import CardActionArea from '@material-ui/core/CardActionArea'
import { LeadCardPreview } from './lead-card-preview/LeadCardPreview'
import { synchronizeDataService, useGetBusiness } from '@marketing-milk/frontend'
import { toast } from 'react-toastify'
import { formTypeWire } from './lead-card-preview/LeadCardPreview.types'
import { FullPageHeader } from 'components/layout/FullPageLayout/Header/FullPageHeader'
import { SearchInput } from 'components'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { FullPageContent } from 'components/layout/FullPageLayout/Content/FullPageContent'
import { useScreenSize } from 'hooks'
import { isEmpty } from 'lodash'
import { NoItemsCreated } from '../../../components/NoItemsCreated/NoItemsCreated'
import { CalendarToday } from '@mui/icons-material'

export function ListLeadCards() {
  const history = useHistory()
  const business = useGetBusiness()
  const [filter, setFilter] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const leadCards = useGetLeadCardsForBusiness(business.id, refresh)
  const filteredLeadCards = (leadCards ?? []).filter(_ => _.formName.toLowerCase().includes(filter))
  const { isMobile } = useScreenSize()

  useEffect(() => {
    setLoading(false)
  }, [leadCards])

  const useStyles = makeStyles({
    root: {
      maxWidth: 355,
      minWidth: 200,
      width: 'auto',
      height: '100%',
      marginRight: 15,
      marginBottom: 15,
      padding: 0,
    },
  })
  const classes = useStyles()

  async function syncLeadsWithFB() {
    try {
      await synchronizeDataService.syncLeadgenForms(business.id)
    } catch (e) {
      toast.error('Leadgen Forms failed to Synchronize with Facebook.')
    }
    setRefresh(refresh + 1)
  }

  return (
    <UserLayout>
      <FullPageHeader breadcrumbs={[{ title: 'Lead Cards' }]}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(`/${business.id}/lead-cards/create`)}
        >
          {isMobile ? '+' : 'Create Lead Card'}
        </Button>

        <IconButton onClick={syncLeadsWithFB}>
          <RefreshIcon />
        </IconButton>

        <SearchInput placeholder="Search Lead Cards" onSearch={setFilter} />
      </FullPageHeader>

      <FullPageContent className="flex h-full w-full flex-col space-x-4 p-2">
        {!isEmpty(filteredLeadCards) && (
          <div className="grid w-full justify-items-center sm:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-6 sm:gap-10 p-4 space-y-4 sm:space-y-0">
            {filteredLeadCards.map(leadCard => (
              <div className="h-full">
                <Card className={classes.root}>
                  <CardHeader
                    title={leadCard.formName}
                    subheader={formTypeWire[leadCard.formType]}
                    action={
                      <IconButton
                        aria-label="copy lead card"
                        onClick={() =>
                          history.push(`/${business.id}/lead-cards/duplicate/${leadCard.id}`)
                        }
                      >
                        <FileCopyIcon />
                      </IconButton>
                    }
                  />
                  <CardActionArea
                    onClick={() => history.push(`/${business.id}/lead-cards/${leadCard.id}`)}
                  >
                    <CardContent>
                      <LeadCardPreview
                        leadCard={leadCardDTOWire(leadCard)}
                        business={business}
                        mobilePreview={false}
                        selectionPreview={false}
                        showName={false}
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            ))}
          </div>
        )}

        {!isLoading && isEmpty(filteredLeadCards) && (
          <NoItemsCreated
            title="No Lead Cards are available on MM for this business"
            subtitle="Click the button below and make your first lead card."
          >
            <button
              type="button"
              className="max-w-3xl my-8 block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => history.push(`/${business.id}/lead-cards/create`)}
            >
              <CalendarToday fontSize="large" />

              <span className="mt-2 block text-lg font-medium text-gray-900">Make Lead Card</span>
            </button>
          </NoItemsCreated>
        )}
      </FullPageContent>
    </UserLayout>
  )
}
