import React, { useState } from 'react'
import { AdCreative, FBAdPreviewFormat } from '@marketing-milk/interfaces'
import { CardRibbon } from 'components/cards/action-card/components/card-ribbon'
import { getPreview } from 'pages/business/creatives/creative-wizard/ad-preview/views/getPreview'
import { Drawer, Tooltip } from '@material-ui/core'
import { sentenceCase } from 'change-case'
import { CreativePill } from './CreativePill'
import { CreativeMedia } from './CreativeMedia'
import { CreativeTools } from '../components/creative-tools/CreativeTools'
import { LeadCardIcon } from './LeadCardIcon'
import { useFetchCreativePreview } from '../../../../hooks/creatives/fetch-creative-preview'

type StringKeys<T> = { [P in keyof T]: NonNullable<T[P]> extends String ? P : never }[keyof T]

type Props = {
  creative: AdCreative
  selectedText?: string
  pillDisplayed?: keyof Pick<AdCreative, 'format' | 'status' | 'reviewStatus'>
  propertiesDisplayed?: StringKeys<AdCreative>[]
  tools?: boolean
  onSelect: (adCreative: AdCreative) => void
}
export const CreativeCard = ({
  creative,
  propertiesDisplayed = ['name'],
  tools = true,
  ...props
}: Props) => {
  const buttonClasses =
    'font-semibold text-lg text-center p-3 w-full transition-all duration-200 bg-gray-300 hover:bg-blue-800'
  const [viewPreview, setViewPreview] = useState(false)

  const { adCreativePreview, retrievePreview } = useFetchCreativePreview(creative.id)

  const className = `sm:m-5 sm:mt-8 transition-all z-0 overflow-hidden duration-200 cursor-pointer w-80 min-h-96 rounded-2xl relative flex flex-col mx-2 my-8 shadow-xl hover:shadow-2xl hover:border-blue-200 ${
    props.selectedText
      ? 'border-2 border-purple-600 hover:border-purple-600'
      : 'border hover:border-purple-400'
  }`

  return (
    <section className={className} data-testid={creative.name}>
      <div className="flex items-center justify-end">
        {tools && <CreativeTools creative={creative} dotPattern="h" />}
      </div>

      <div onClick={() => props.onSelect(creative)}>
        {props.selectedText && <CardRibbon title={props.selectedText} />}

        <CreativePill creative={creative} property="format" />

        <LeadCardIcon creative={creative} />

        <CreativeMedia creative={creative} />

        <div className="divide-y flex-1">
          {propertiesDisplayed.map(
            property =>
              property && (
                <div className={'py-3 px-2 sm:py-3 sm:px-6 grid grid-cols-1'}>
                  <dt className={'text-sm font-medium'}>
                    <span className={!creative[property] ? 'text-red-800' : 'text-gray-500'}>
                      {sentenceCase(property)} {!creative[property] && '(incomplete)'}
                    </span>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 truncate">
                    {creative[property]}
                  </dd>
                </div>
              )
          )}
        </div>
      </div>

      <div className="rounded-b-2xl flex justify-center w-full divide-x">
        {creative.destinationURL && (
          <Tooltip title={creative.destinationURL}>
            <a
              href={creative.destinationURL}
              target="_blank"
              className={`${buttonClasses} border-r border-gray-800`}
            >
              Open Link
            </a>
          </Tooltip>
        )}
        <a
          href="#"
          className={buttonClasses}
          onClick={e => {
            e.stopPropagation()
            setViewPreview(true)
            retrievePreview()
          }}
        >
          Preview Ad
        </a>
      </div>

      <Drawer open={viewPreview} onClose={() => setViewPreview(false)}>
        <div className="flex flex-col align-middle p-4 h-screen overflow-hidden w-full justify-center space-y-4 ">
          <div className="flex justify-between align-middle items-center">
            <p className="text-center font-extrabold text-xl">Ad Preview</p>
            <i className="fa fa-times flex cursor-pointer" onClick={() => setViewPreview(false)} />
          </div>
          {adCreativePreview && getPreview(FBAdPreviewFormat.MOBILE_FULLWIDTH, adCreativePreview)}
        </div>
      </Drawer>
    </section>
  )
}
