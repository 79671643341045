import React from 'react'
import { range } from 'lodash'
import Select from 'react-select'

export interface AgeRangeProps {
  age_min: number
  age_max: number
  updateAgeRange: (minAge?: number, maxAge?: number) => void
}

export function AgeRange(props: AgeRangeProps) {
  const { age_min, age_max, updateAgeRange } = props

  const ageRange = range(18, 66)
  const ageOptions = ageRange.map(age => ({
    value: age,
    label: age === 65 ? '65+' : String(age), // add plus sign to age 65
  }))

  function setMinAge(selected?: number) {
    if (selected) updateAgeRange(selected, age_max)
  }

  function setMaxAge(selected?: number) {
    if (selected) updateAgeRange(age_min, selected)
  }

  return (
    <>
      <p className="text-bold">Age Range</p>
      <hr />
      <div className="row">
        <form className="col-6" role="form">
          <label htmlFor="min-age">
            <small>Min</small>
          </label>
          <Select
            name="min-age"
            inputId="min-age"
            defaultValue={ageOptions.find(age => age.value === age_min)}
            options={ageOptions.filter(x => x.value <= age_max)}
            onChange={val => setMinAge(val?.value)}
          />
        </form>
        <form className="col-6" role="form">
          <label htmlFor="max-age">
            <small>Max</small>
          </label>
          <Select
            name="max-age"
            inputId="max-age"
            defaultValue={ageOptions.find(age => age.value === age_max)}
            options={ageOptions.filter(x => x.value >= age_min)}
            onChange={val => setMaxAge(val?.value)}
          />
        </form>
      </div>
    </>
  )
}
