import '../ad-preview.scss'
import { SingleAdPreviewProps } from './'
import React, { useEffect, useState } from 'react'
import { Loading } from 'components/messages/loading'

export function SingleAdPreview(props: SingleAdPreviewProps) {
  const [loading, setLoading] = useState<boolean>(true)
  const { children } = props

  // when iframe url changes reset loading state
  useEffect(() => {
    setLoading(true)
  }, [props.iframeURL])

  return (
    <div className="ad-preview">
      <div className="phone-container mx-auto">
        <div className="phone">
          <iframe
            className={`${loading ? 'd-none' : ''}`}
            style={{ zIndex: 50 }}
            src={props.iframeURL}
            scrolling="yes"
            onLoad={() => setLoading(false)}
          />
          {children && children}
          {loading && <Loading />}
        </div>
      </div>
    </div>
  )
}
