import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'

export interface AddQuestionDropdownProps {
  onAddPrefillQuestion: () => void
  onAddShortAnswerQuestion: () => void
  onAddMultipleChoiceQuestion: () => void
  editable: boolean
}

export function AddQuestionDropdown({
  editable,
  onAddPrefillQuestion,
  onAddShortAnswerQuestion,
  onAddMultipleChoiceQuestion,
}: AddQuestionDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        disabled={!editable}
        data-testid="add-question-button"
        aria-controls="question-type-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
        color="primary"
      >
        Add Question
      </Button>
      <Menu
        id="question-type-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'question-type-button',
        }}
      >
        <MenuItem data-testid="add-prefill-question" onClick={onAddPrefillQuestion}>
          <ListItemText
            primary="Prefill Question"
            secondary="Ask for user information. This will be prefilled from their Facebook account"
          />
        </MenuItem>
        <MenuItem data-testid="add-short-answer-question" onClick={onAddShortAnswerQuestion}>
          <ListItemText
            primary="Custom Question"
            secondary="Ask for responses to questions that matter to your business"
          />
        </MenuItem>
        <MenuItem data-testid="add-multi-choice-question" onClick={onAddMultipleChoiceQuestion}>
          <ListItemText
            primary="Multiple Choice Question"
            secondary="Ask a question and provide the user with custom response options"
          />
        </MenuItem>
      </Menu>
    </>
  )
}
