import { adCreativeService, sessionContext, useGetBusinessID } from '@marketing-milk/frontend'
import { AdCreativeType, AssetApprovalStatus, DynamicCreativeDTO } from '@marketing-milk/interfaces'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ShuffleIcon from '@material-ui/icons/Shuffle'
import { TabPanel } from 'components'
import { useCreatives } from 'pages/business/creatives/useCreatives'
import React, { ReactNode, useContext } from 'react'
import { creativeContext } from '../../context/CreativeContext'
import { SaveButton } from '../../fields/save-button/SaveButton'
import { SelectLeadCard } from '../../fields/select-lead-card/SelectLeadCard'
import { dynamicContext } from './context/DynamicContext'
import { DynamicContextProvider } from './context/DynamicContextProvider'
import { DynamicCallToAction } from './fields/dynamic-call-to-action/DynamicCallToAction'
import { DynamicDescription } from './fields/dynamic-description/DynamicDescription'
import { DynamicHeadline } from './fields/dynamic-headline/DynamicHeadline'
import { DynamicMedia } from './fields/dynamic-media/DynamicMedia'
import { DynamicPrimaryText } from './fields/dynamic-primary-text/DynamicPrimaryText'
import { DynamicWebsite } from './fields/dynamic-website/DynamicWebsite'

export function DynamicCreativeComponent() {
  const businessID = useGetBusinessID()
  const { refetch } = useCreatives(businessID)
  const { user } = useContext(sessionContext)
  const { name, setName, creative, setCreative } = useContext(creativeContext)
  const canEdit = !creative!.locked
  const {
    headlines,
    descriptions,
    primaryTexts,
    medias,
    websiteURL,
    displayURL,
    callToActions,
    canPublishCreative,
  } = useContext(dynamicContext)

  const disabledReason =
    creative.reviewStatus === AssetApprovalStatus.Published
      ? 'You cannot edit a published creative. If you would like to use this creative again, please duplicate it.'
      : 'Ensure all required fields are filled out'

  const reloadCreative = async () => {
    const data = await adCreativeService.getByID(businessID, creative!.id)
    setCreative(data)
  }

  function getDraftStatus(): boolean {
    const partialHeadline = headlines.length === 0 || !!headlines.find(_ => _ === '')
    const partialDescription = descriptions.length === 0 || !!descriptions.find(_ => _ === '')
    const partialPrimaryTexts = primaryTexts.length === 0 || !!primaryTexts.find(_ => _ === '')
    const partialMedias = medias.length === 0 || medias.some(_ => !_.mediaID || !_.mediaType)
    const partialCallToAction = callToActions.length === 0
    const partialLink = websiteURL === '' || displayURL === ''
    return (
      partialHeadline ||
      partialDescription ||
      partialPrimaryTexts ||
      partialMedias ||
      partialCallToAction ||
      partialLink
    )
  }

  function isDisabled(): boolean {
    return !canPublishCreative() || creative.reviewStatus === AssetApprovalStatus.Published
  }

  async function onSave() {
    const dynamicDTO: DynamicCreativeDTO = {
      name,
      format: AdCreativeType.dynamic,
      imageIDs: medias.filter(_ => _.mediaType === 'image').map(_ => _.mediaID!),
      videoIDs: medias.filter(_ => _.mediaType === 'video').map(_ => _.mediaID!),
      primaryTexts,
      headlines,
      descriptions,
      callToActions,
      url: websiteURL,
      displayLink: displayURL,
    }

    const newCreative = await adCreativeService.updateDynamic(
      businessID,
      creative!.id!,
      dynamicDTO,
      true
    )
    refetch()
    setCreative(newCreative)
  }

  const sections: Array<{ title: string; section: ReactNode }> = [
    {
      title: 'Media',
      section: <DynamicMedia />,
    },
    {
      title: 'Headline',
      section: <DynamicHeadline />,
    },
    {
      title: 'Status',
      section: <DynamicPrimaryText />,
    },
    {
      title: 'Description',
      section: <DynamicDescription />,
    },
    {
      title: 'Call to Action',
      section: <DynamicCallToAction />,
    },
  ]

  return (
    <>
      <TextField
        id="creative-name"
        label="Creative Name"
        className="mb-4"
        fullWidth
        value={name}
        onChange={e => setName(e.target.value)}
        disabled={!canEdit}
      />
      <div className="form-group">
        <SelectLeadCard readOnly={!canEdit} />
      </div>
      <DynamicWebsite />
      <TabPanel sections={sections} />
      <hr className="mt-4" />
      <div className="form-group">
        <SaveButton disabled={!canEdit} onSave={onSave} />
        <Button
          variant="contained"
          className="mt-3"
          fullWidth
          color="primary"
          disabled={isDisabled()}
          onClick={onSave}
          type="button"
        >
          <ShuffleIcon className="mr-2" /> Shuffle
        </Button>
      </div>
    </>
  )
}

export function DynamicCreative() {
  return (
    <DynamicContextProvider>
      <DynamicCreativeComponent />
    </DynamicContextProvider>
  )
}
