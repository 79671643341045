import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { TrackingParam } from './TrackingParam'

export interface AddTrackingProps {
  editable: boolean
  onSaveParam: (oldID: string, id: string, value: string) => void
}

export function AddTracking(props: AddTrackingProps) {
  const [showTrackingParam, setShowTrackingParam] = useState(false)

  return (
    <>
      {showTrackingParam && (
        <TrackingParam
          id={''}
          value={''}
          editable={true}
          onDelete={() => setShowTrackingParam(false)}
          onSaveParam={(oldID, id, value) => {
            props.onSaveParam(oldID, id, value)
            setShowTrackingParam(false)
          }}
        />
      )}
      <Button
        className="mt-2"
        variant="contained"
        color="secondary"
        disabled={!props.editable}
        onClick={() => setShowTrackingParam(true)}
        type="button"
        data-testid="add-tracking-param"
      >
        Add Parameter
      </Button>
    </>
  )
}
