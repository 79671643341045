import React from 'react'
import { ErrorItem, ErrorsIndicator } from './ErrorsIndicator'
import { EffectiveStatus } from '@marketing-milk/interfaces'

type Props = {
  status?: EffectiveStatus | null
  hasEnded?: boolean
  isFuture?: boolean
  className?: string
  errors?: ErrorItem[]
  drawerTitle?: string
  drawerDescription?: string
}

interface EffectiveStatusDisplayProps {
  className: 'success' | 'drive' | 'brand' | 'danger' | 'red'
  text: string
  endedText?: string
  futureText?: string
}

const effectiveStatusRecord: Record<EffectiveStatus, EffectiveStatusDisplayProps> = {
  ACTIVE: { className: 'success', text: 'Active', endedText: 'Completed', futureText: 'Scheduled' },
  PAUSED: { className: 'drive', text: 'Paused', endedText: 'Off' },
  DELETED: { className: 'brand', text: 'Deleted' },
  ARCHIVED: { className: 'brand', text: 'Archived' },
  IN_PROCESS: { className: 'drive', text: 'Processing' },
  WITH_ISSUES: { className: 'danger', text: 'Errors' },
  CAMPAIGN_PAUSED: { className: 'drive', text: 'Campaign Paused' },
  PENDING_REVIEW: { className: 'drive', text: 'In Review' },
  DISAPPROVED: { className: 'danger', text: 'Rejected' },
  PREAPPROVED: { className: 'drive', text: 'Preapproved' },
  PENDING_BILLING_INFO: { className: 'danger', text: 'Billing Errors' },
  ADSET_PAUSED: { className: 'drive', text: 'Adset Paused' },
}

export const StatusIndicator = ({ status, hasEnded, isFuture, ...props }: Props) => {
  const getStyles = () => {
    if (props.errors) return 'red'
    if (!status) return 'red'
    return effectiveStatusRecord[status].className
  }

  const displayStatus = (
    status?: EffectiveStatus | null,
    campaignHasEnded?: boolean,
    isFuture?: boolean
  ): string => {
    // statuses that may change based upon whether or not the campaign has finished
    if (!status) return 'Incomplete'
    const effectiveStatusProps = effectiveStatusRecord[status]
    const endedText = effectiveStatusProps.endedText
    const futureText = effectiveStatusProps.futureText
    return campaignHasEnded && endedText
      ? endedText
      : isFuture && futureText
      ? futureText
      : effectiveStatusProps.text
  }

  return (
    <div className={`text-center ${props.className || ''}`}>
      {props.errors && props.errors.length ? (
        <ErrorsIndicator
          errors={props.errors}
          drawerDescription={props.drawerDescription}
          drawerTitle={props.drawerTitle}
        />
      ) : (
        <span
          style={{ filter: 'brightness(0.4)' }}
          className={`text-${getStyles()} bg-${getStyles()} faded min-w-min rounded-full shadow-xl max-w-max transform px-4 py-2 flex items-center justify-center`}
        >
          {displayStatus(status, hasEnded, isFuture)}
        </span>
      )}
    </div>
  )
}
