import { useGetBusiness } from '@marketing-milk/frontend'
import {
  AdCreative,
  CampaignGoalType,
  CampaignSchedule,
  PublishCampaignDTO,
  SavedAudience,
} from '@marketing-milk/interfaces'
import { Step } from 'components/Stepper'
import { Stepper } from 'components/Stepper/Stepper'
import { all, any, equals, set } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { campaignService } from '../../../../app/services'
import { rejectNil } from '../../../../app/util'
import {
  CampaignAdStep,
  CampaignAdsetsStep,
  CampaignReviewStep,
  CampaignSettingsStep,
} from '../forms/steps'
import * as H from '../helpers/campaignForm.helpers'
import { CampaignFormErrors, singleCampaignDtoIsValid } from '../helpers/campaignForm.helpers'

type Props = {
  audiences?: SavedAudience[]
  creatives?: AdCreative[]
  dto?: PublishCampaignDTO
  goals: CampaignGoalType[]
  onSubmit: (dto: PublishCampaignDTO) => void
  campaignErrors?: CampaignFormErrors
}

// TODO: remove these types when the correct type is inside of mono repo
export declare type Maybe<T extends any[]> = T extends Array<infer U> ? Array<U | undefined> : never
export declare type CreativeGroup = Maybe<[number, number]>
export type AdPair = Maybe<[AdCreative, AdCreative]>

export const CampaignForm = ({ dto, creatives, audiences, goals, ...props }: Props) => {
  const [errors, setErrors] = useState<CampaignFormErrors>({
    campaign: props.campaignErrors?.campaign,
    adsets: props.campaignErrors?.adsets,
    ads: props.campaignErrors?.ads,
  })
  const [schedule, setCampaignSchedule] = useState<CampaignSchedule>()

  const business = useGetBusiness()

  const [campaignDto, setCampaignDto] = useState<PublishCampaignDTO | undefined>(
    H.getDefaultDTO(dto, business.timezone)
  )

  useEffect(() => {
    if (!dto) return
    setCampaignDto(dto)
  }, [])

  useEffect(() => {
    if (!props.campaignErrors) return
    setErrors(props.campaignErrors)
  }, [dto])

  const handleSubmit = async () => {
    if (!campaignDto) return
    // Set errors and stop submission if the dto is not valid
    if (!singleCampaignDtoIsValid(campaignDto, setErrors)) return

    props.onSubmit(campaignDto)
  }

  const fetchCampaignSchedule = async (numOfCreativeGroups: number) => {
    if (campaignDto && numOfCreativeGroups > 1) {
      const generatedSchedule = await campaignService.getCampaignSchedule(business.id, {
        startTime: campaignDto.campaign.startTime,
        endTime: campaignDto.campaign.endTime,
        numOfAdGroups: numOfCreativeGroups,
      })
      setCampaignSchedule(generatedSchedule)
    }
  }

  useEffect(() => {
    if (!dto) return
    fetchCampaignSchedule(dto.creativeGroups.length)
  }, [dto?.campaign, dto?.creativeGroups])

  return (
    <div style={{ width: '80vw' }}>
      <Stepper hideSubmit>
        {/* Select Settings */}
        <Step
          title={`${campaignDto?.campaign?.name || 'Campaign'} Settings`}
          completed={all(equals(true), H.campaignSettingsValidators(campaignDto))}
          disabled={any(equals(false), H.campaignSettingsValidators(campaignDto))}
          errors={errors.campaign}
          onNext={() => setErrors(set('campaign', undefined))}
        >
          <CampaignSettingsStep
            isEdit={!!dto}
            campaignDto={campaignDto}
            setCampaignDto={setCampaignDto}
            errors={errors.campaign}
          />
        </Step>

        {/* Select Audiences */}
        <Step
          title="Adsets"
          completed={H.audiencesAreValid(campaignDto?.audienceIDs)}
          disabled={!H.audiencesAreValid(campaignDto?.audienceIDs)}
          errors={errors.adsets}
          onNext={() => setErrors(set('adsets', undefined))}
        >
          <CampaignAdsetsStep
            title="Adsets"
            campaignDto={campaignDto}
            setCampaignDto={setCampaignDto}
            audiences={audiences}
            errors={errors.adsets}
          />
        </Step>

        {/* Select Ads */}
        <Step
          title="Ads"
          completed={campaignDto && !!rejectNil(campaignDto?.creativeGroups[0])?.length}
          disabled={campaignDto && !rejectNil(campaignDto?.creativeGroups[0])?.length}
          errors={errors.ads}
          onNext={() => setErrors(set('ads', undefined))}
        >
          {campaignDto && (
            <CampaignAdStep
              campaignDto={campaignDto}
              setCampaignDto={setCampaignDto}
              creatives={creatives}
              schedule={schedule}
              updateSchedule={fetchCampaignSchedule}
            />
          )}
        </Step>

        {/* Review Campaign */}
        <Step title="Review">
          {campaignDto && (
            <CampaignReviewStep
              campaignDto={campaignDto}
              setCampaignDto={setCampaignDto}
              onPublish={handleSubmit}
              audiences={audiences}
              creatives={creatives}
              schedule={schedule}
            />
          )}
        </Step>
      </Stepper>
    </div>
  )
}
