import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { FacebookLeadgenButtonType } from '@marketing-milk/interfaces'

export interface CallToActionButtonProps {
  editable: boolean
  callToAction?: FacebookLeadgenButtonType
  onChangeCTA(callToAction: FacebookLeadgenButtonType): void
}

export const CALL_TO_ACTION_OPTIONS = [
  {
    label: 'View Website',
    value: FacebookLeadgenButtonType.VIEW_WEBSITE,
  },
  {
    label: 'Download',
    value: FacebookLeadgenButtonType.DOWNLOAD,
  },
  {
    label: 'Call Business',
    value: FacebookLeadgenButtonType.CALL_BUSINESS,
  },
]

export function CallToActionButton({
  editable,
  callToAction,
  onChangeCTA,
}: CallToActionButtonProps) {
  return (
    <div className="form-group">
      <InputLabel disabled={!editable} className="text-bold" shrink>
        Call to Action *
      </InputLabel>
      <Select
        fullWidth
        defaultValue=""
        disabled={!editable}
        value={callToAction}
        inputProps={{
          'aria-label': 'completion cta select',
          'data-testid': 'completion-cta-select',
        }}
        onChange={event => onChangeCTA(event.target.value as FacebookLeadgenButtonType)}
      >
        {CALL_TO_ACTION_OPTIONS.map((x, i) => (
          <MenuItem key={i} value={x.value}>
            {x.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}
