import React from 'react'
import { useLeadCard } from './useLeadCard'
import { leadCardContext } from './LeadCardContext'
import { LeadCardContextProps } from './LeadCardContext.types'

export function LeadCardContextProvider({
  children,
  defaultLeadCard,
  editable = false,
}: LeadCardContextProps) {
  const leadCardFunctionality = useLeadCard(editable, defaultLeadCard)

  return (
    <leadCardContext.Provider
      value={{
        editable,
        ...leadCardFunctionality,
      }}
    >
      {children}
    </leadCardContext.Provider>
  )
}
