import { SavedAudienceType } from '@marketing-milk/interfaces'

export const audienceTypes: Record<SavedAudienceType, { title: string; icon: string }> = {
  AI: {
    title: 'Artificial Intelligence',
    icon: 'mind-share',
  },
  HUMAN_MADE: {
    title: 'Human Made',
    icon: 'user-astronaut',
  },
  WEBSITE_TRAFFIC: {
    title: 'Website Traffic',
    icon: 'globe',
  },
  ENGAGED_USERS: {
    title: 'Engaged Users',
    icon: 'heart-circle',
  },
  LEAD_CARD: {
    title: 'Lead Card',
    icon: 'id-card',
  },
  MM_GENERATED: {
    title: 'Customer List',
    icon: 'cloud',
  },
  CUSTOMER_FILE: {
    title: 'Customer File',
    icon: 'cloud',
  },
  FACEBOOK_IMPORTED: {
    title: 'Customer File',
    icon: 'cloud',
  },
}
