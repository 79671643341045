import React from 'react'
import { Step, StepProps } from 'components/Stepper'
import { Stepper } from 'components/Stepper/Stepper'

export interface AudienceWizardProps {
  steps: StepProps[]
}

export function AudienceWizard(props: AudienceWizardProps) {
  const { steps } = props

  return (
    <Stepper>
      {steps.map((step, i) => (
        <Step className="p-4" key={i} {...step}>
          {step.children}
        </Step>
      ))}
    </Stepper>
  )
}
