import React from 'react'

interface Props {
  title: string
  children: React.ReactNode
  className?: string
  onClick?: () => void
}
export const SidebarSection = (props: Props) => {
  return (
    <div className={props.className} onClick={props.onClick} role={props.onClick && 'button'}>
      <p className="text-lg font-semibold pl-4 pt-4">{props.title}</p>
      <div className="border-b p-4">{props.children}</div>
    </div>
  )
}
