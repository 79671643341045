import React, { useState } from 'react'

export interface NameInputProps {
  placeholder: string
  onValueChange: (value: string) => void
  defaultValue?: string
  maxChar: number
  className?: string
}

export function NameInput(props: NameInputProps) {
  const { placeholder, defaultValue, maxChar, onValueChange, className } = props
  const [value, setValue] = useState<string>(defaultValue ?? '')

  function handleChange(title: string) {
    if (title.match(/^[a-zA-Z0-9.\-() ]*$/g) && title.length <= maxChar) {
      setValue(title)
      onValueChange(title)
    }
  }

  const maxCharacterColor =
    value.length === 0 ? '' : value.length === maxChar ? 'text-warning' : 'text-success'
  const specialCharactersColor = value.length === 0 ? '' : 'text-success'

  return (
    <>
      <div className={`col-12 p-0 text-center name-input mb-2 ${className ? className : ''}`}>
        <input
          type="text"
          value={value}
          className="mx-auto border"
          style={{ minWidth: '100%' }}
          placeholder={placeholder}
          role="name-input"
          onChange={e => handleChange(e.target.value)}
        />
      </div>
      <div className="col-12 p-0 col-md-4 mt-2 mt-md-0 mt-dm-9 text-left">
        <small className={`d-block ${maxCharacterColor}`}>
          <i className={`fas fa-xs fa-check-circle ${maxCharacterColor}`} /> Max {maxChar}{' '}
          characters
        </small>
        <small className={`d-block ${specialCharactersColor}`}>
          <i className={`fas fa-xs fa-check-circle ${specialCharactersColor}`} /> No special or
          unique characters
        </small>
      </div>
    </>
  )
}
