import MMDroplet from 'public/img/logos/milk-gradient.png'
import React from 'react'

interface NoItemsCreatedProps {
  children?: React.ReactNode
  title: string
  subtitle?: string
}
export const NoItemsCreated = (props: NoItemsCreatedProps) => (
  <div className="bg-white border border-radius p-5 d-block  w-full flex items-center self-center justify-center max-w-4xl m-4">
    <div className="text-center space-y-4 flex flex-col items-center justify-center max-w-4xl">
      <img
        src={MMDroplet}
        className="mx-auto mb-4 object-cover"
        alt="Marketing Milk Droplet Logo"
        style={{ maxHeight: '80px' }}
      />
      <h3 className="font-medium text-gray-800 text-2xl">{props.title}</h3>
      {props.subtitle && <p className="text-sm">{props.subtitle}</p>}
      {props.children}
    </div>
  </div>
)
