import React from 'react'
import { Button, Tab, Tabs, Tooltip } from '@mui/material'
import { inc, dec, onlyPopulatedValues } from 'app/util'
import {
  // prettier-ignore
  isEmpty,
  last,
  unset,
} from 'lodash/fp'
import CancelIcon from '@mui/icons-material/Cancel'
import { CampaignSchedule, PublishCampaignDTO } from '@marketing-milk/interfaces'
// TODO:  remove this type when the correct type is inside of mono repo
import { AdPair } from '../../../CampaignForm'
import { toast } from 'react-toastify'
import { getCostPerText } from '../../../../helpers/campaignForm.helpers'

type Props = {
  adPairs: AdPair[]
  setAdPairs: SetState<AdPair[]>
  adPairIndex: number
  setAdPairIndex: SetState<number>
  campaignDto: PublishCampaignDTO
  schedule?: CampaignSchedule
}

export const CascadingCampaignOptions = ({
  adPairs,
  setAdPairs,
  adPairIndex,
  setAdPairIndex,
  campaignDto,
  schedule,
}: Props) => {
  return (
    <div className="flex items-center justify-center w-full">
      <Tabs value={adPairIndex} centered scrollButtons="auto">
        {adPairs.map((_, index) => (
          <div className="px-2 relative">
            {index > 1 && (
              <button
                data-testid="cc-remove-variation-button"
                className="absolute z-20 right-0 bg-transparent"
                onClick={() => {
                  setAdPairs(pairs => onlyPopulatedValues(unset(adPairIndex, pairs)))
                  setAdPairIndex(dec)
                }}
              >
                <CancelIcon color="error" />
              </button>
            )}
            <Tab
              label={`${index > 0 ? ' Variation' : 'Main A/B Pair'} ${
                index > 0 ? '#' + index : ''
              }  `}
              onClick={() => setAdPairIndex(index)}
              value={index}
            />
          </div>
        ))}
      </Tabs>

      <div className="ml-5">
        <Tooltip title="If your current Ad Pair isn't performing well, the backup pair will replace them">
          <Button
            disabled={!!isEmpty(last(adPairs || []))}
            variant="contained"
            onClick={() => {
              if (schedule) {
                toast.info(
                  `Performance will be checked every ${schedule.interval} ${
                    schedule.intervalType
                  }(s) and switch if ${getCostPerText(campaignDto.campaign.goal)} is less than $${
                    campaignDto.campaign.maxCostPer ?? 10
                  }`
                )
              }
              setAdPairs(prev => [...prev, []])
              setAdPairIndex(inc)
            }}
          >
            Add Variation
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}
