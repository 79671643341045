import { useState, useEffect } from 'react'
import { LeadCard } from '@marketing-milk/interfaces'
import { leadCardService } from '@marketing-milk/frontend'

export function useFetchLeadCard(businessID: number, leadCardID: number): LeadCard | undefined {
  const [leadCard, setLeadCard] = useState<LeadCard>()

  useEffect(() => {
    async function fetchLeadCard() {
      setLeadCard(undefined)
      const leadCardData = await leadCardService.getByID(businessID, leadCardID)
      setLeadCard(leadCardData)
    }
    fetchLeadCard()
  }, [businessID, leadCardID])

  return leadCard
}
