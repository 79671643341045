import React from 'react'
import { ActionCard } from 'components/cards/action-card/ActionCard'
import Select, { SingleValue } from 'react-select'
import { FacebookTargetingSpec, FunnelStageWithOfflineEventCount } from '@marketing-milk/interfaces'
import { StepHeader } from '../StepHeader'
import { isEmpty } from 'lodash'

export interface FunnelListProps {
  selectedStageID: number | undefined
  onSelectStageID: (id: number) => void
  autoUpdate: boolean
  onChangeAutoUpdate: () => void
  funnelStageOptions: FunnelStageWithOfflineEventCount[] | undefined
  targetSpec: FacebookTargetingSpec
}

export function FunnelList(props: FunnelListProps) {
  const {
    selectedStageID,
    autoUpdate,
    onChangeAutoUpdate,
    onSelectStageID,
    funnelStageOptions,
    targetSpec,
  } = props

  const funnelOptions = funnelStageOptions
    ? funnelStageOptions.map(x => ({
        label: `${x.name} (${x.offlineEventCount})`,
        value: x.id,
      }))
    : []

  const funnelValue = selectedStageID ? funnelOptions.find(x => x.value === selectedStageID) : null

  function selectFunnelOption(option: SingleValue<{ label: string; value: number }>) {
    if (!option) return
    onSelectStageID(option.value)
  }

  return (
    <>
      <div className="col-12 p-2 p-md-5 center-vertical">
        <div className="container">
          <StepHeader
            title="Prepare Your Customer List"
            description="Choose the Funnel Stage below that best matches the type of customer you’re
                  wanting to model. NOTE: The more customers there are the higher your chances are
                  of finding more."
            targetingSpec={targetSpec}
          />
          <div className="row mt-4">
            <div className="col-12">
              <Select
                placeholder="Choose your funnel stage..."
                value={funnelValue}
                options={funnelOptions}
                onChange={selectFunnelOption}
                isLoading={isEmpty(funnelOptions)}
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <div className="text-center">
                <h2 className="mb-2">Automatically Add Future Customer Data (Optional)</h2>
                <p className="px-2 px-md-4">
                  Automatically add customers matching your selected funnel stage once they’ve been
                  imported into Marketing Milk using the Data Uploader tool.
                </p>

                <ActionCard
                  classes={`fit-content mt-5 p-1 mx-auto d-block ${autoUpdate && 'selected'}`}
                  onClick={() => onChangeAutoUpdate()}
                >
                  <span>Yes, Add My Future Customers</span>
                  <i
                    className={`fas fa-${
                      autoUpdate ? 'check-circle text-success' : 'circle text-inactive'
                    } text-inherit pl-3`}
                  />
                </ActionCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
