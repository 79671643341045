import { useState } from 'react'
import { Tool, ToolMenu } from '@marketing-milk/frontend'
import { CreativeToolsProps } from './CreativeTools.types'
import { ShareCreativeModal } from './share-creative-modal/ShareCreativeModal'
import { DeleteCreativeModal } from './delete-creative-modal/DeleteCreativeModal'
import { DuplicateCreativeModal } from './duplicate-creative-modal/DuplicateCreativeModal'
import { AssetApprovalStatus } from '@marketing-milk/interfaces'
import { ConfirmationModal } from 'components/ConfirmationModal'

export function CreativeTools({ creative, dotPattern }: CreativeToolsProps) {
  const [showShareModal, setShareModal] = useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [showDuplicateModal, setDuplicateModal] = useState(false)
  const tools: Tool[] = [
    {
      text: 'Share',
      icon: 'share',
      action: onToggleShare,
      type: 'link',
    },
    {
      text: 'Duplicate',
      icon: 'clone',
      action: onToggleDuplicate,
      type: 'link',
    },
  ]

  if (creative.reviewStatus !== AssetApprovalStatus.Published) {
    tools.push({
      text: 'Delete',
      icon: 'trash-alt',
      action: () => setDeleteModal(true),
      type: 'link',
    })
  }

  function onToggleDelete() {
    setDeleteModal(_ => !_)
  }

  function onToggleShare() {
    setShareModal(_ => !_)
  }

  function onToggleDuplicate() {
    setDuplicateModal(_ => !_)
  }

  return (
    <>
      <ToolMenu dotPattern={dotPattern} tools={tools} />
      <DeleteCreativeModal
        creative={creative}
        isVisible={showDeleteModal}
        onToggle={onToggleDelete}
      />
      <ShareCreativeModal creative={creative} isVisible={showShareModal} onToggle={onToggleShare} />
      <DuplicateCreativeModal
        creative={creative}
        isVisible={showDuplicateModal}
        onToggle={onToggleDuplicate}
      />
    </>
  )
}
