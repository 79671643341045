import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { FacebookLeadgenLocale } from '@marketing-milk/interfaces'

export interface FormLanguageProps {
  editable: boolean
  locale: FacebookLeadgenLocale
  onChangeLocale(locale: FacebookLeadgenLocale): void
}

export const languageOptions = Object.values(FacebookLeadgenLocale).map(_ => ({
  label: _.toUpperCase(),
  value: _,
}))

export function FormLanguage({ editable, locale, onChangeLocale }: FormLanguageProps) {
  return (
    <div className="form-group">
      <InputLabel disabled={!editable} shrink id="language-select-label" className="text-bold">
        Language
      </InputLabel>
      <Select
        labelId="language-select-label"
        id="completion-cta-select"
        fullWidth
        displayEmpty
        inputProps={{ 'aria-label': 'language select', 'data-testid': 'language-select' }}
        value={languageOptions.find(_ => _.value === locale)?.value}
        onChange={event => onChangeLocale(event.target.value as FacebookLeadgenLocale)}
        disabled={!editable}
      >
        {languageOptions.map((x, i) => (
          <MenuItem key={i} value={x.value}>
            {x.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}
