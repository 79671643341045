import {
  LeadCardCreateDTO,
  FacebookLeadgenButtonType,
  FacebookLeadgenLocale,
  LeadCardQuestionCustom,
  FacebookLeadgenQuestionType,
} from '@marketing-milk/interfaces'

export const emptyLeadCard: LeadCardCreateDTO = {
  formName: '',
  formType: 'more_volume', // Facebook's default
  intro: {
    imageID: undefined,
    imageURL: undefined,
    greeting: {
      headline: '',
      bodyStyle: 'PARAGRAPH_STYLE',
      bodyItems: [''],
    },
  },
  questionsDesc: '',
  questions: [
    // TODO: Implement these default prefills
    // {type: FacebookLeadgenQuestionType.FIRST_NAME},
    // {type: FacebookLeadgenQuestionType.LAST_NAME},
    // {type: FacebookLeadgenQuestionType.EMAIL},
  ],
  privacy: {
    link: '',
    text: '',
    customDisclaimer: undefined,
  },
  thankYouPage: {
    headline: "Thanks, you're all set.",
    description: 'You can visit our website or exit the form now.',
    callToAction: FacebookLeadgenButtonType.VIEW_WEBSITE,
    buttonText: 'View Website',
    link: '',
  },
  settings: {
    locale: FacebookLeadgenLocale.EN_US,
    sharing: 'restricted',
    trackingParams: undefined,
  },
}

export const newCustomDisclaimer: LeadCardCreateDTO['privacy']['customDisclaimer'] = {
  title: '',
  body: '',
  checkboxes: [],
}

export const newGreeting: LeadCardCreateDTO['intro']['greeting'] = {
  headline: '',
  bodyStyle: 'PARAGRAPH_STYLE',
  bodyItems: [''],
}

export const newShortAnswerQuestion: LeadCardQuestionCustom = {
  type: FacebookLeadgenQuestionType.CUSTOM,
  format: 'short-answer',
  key: '',
  label: '',
}

export const newMultiChoiceQuestion: LeadCardQuestionCustom = {
  type: FacebookLeadgenQuestionType.CUSTOM,
  format: 'multiple-choice',
  options: [
    { key: '', value: '' },
    { key: '', value: '' },
  ],
  key: '',
  label: '',
}

export const newMultiChoiceQuestionOption: { key: string; value: string } = { key: '', value: '' }
