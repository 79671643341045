import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import InputAdornment from '@material-ui/core/InputAdornment'
import DialogContentText from '@material-ui/core/DialogContentText'

export interface ChangeKeyModalProps {
  value?: string
  onChange(newKey: string): void
  editable: boolean
}

export function ChangeKeyModal({ value, onChange, editable }: ChangeKeyModalProps) {
  const [isOpen, setOpen] = useState(false)
  const onToggle = () => setOpen(_ => !_)

  return (
    <>
      <IconButton data-testid="key-modal-toggle" onClick={onToggle}>
        <VpnKeyIcon />
      </IconButton>
      <Dialog data-testid="key-modal" open={isOpen} onClose={onToggle}>
        <DialogTitle>Change Field Key</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Change how your field names appear when you export files.
          </DialogContentText>
          <TextField
            autoFocus
            error={!value}
            helperText="* You must have a key when using a custom question."
            disabled={!editable}
            label="Key Identifier"
            fullWidth
            value={value}
            onChange={e => onChange(e.target.value)}
            inputProps={{
              'aria-label': 'custom question key',
              'data-testid': 'custom-question-key',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyIcon />
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button data-testid="key-modal-close" onClick={onToggle}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
