import React from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import { Drawer } from '@material-ui/core'
import { ErrorItem } from './ErrorsIndicator'

type Props = {
  errors: ErrorItem[]
  drawerTitle?: string
  drawerDescription?: string
  isOpen: boolean
  onClose: () => void
  actionButton?: React.ReactNode
}

export const ErrorsDrawer = ({ errors, isOpen, onClose, ...props }: Props) => (
  <Drawer open={isOpen} onClose={onClose}>
    <div className="min-w-max w-full">
      <div style={{ display: 'flex', borderBottom: '1px solid grey', padding: '20px 30px' }}>
        <ErrorIcon style={{ height: '60px', width: '60px', color: 'maroon', marginRight: '5px' }} />
        <div>
          <p style={{ margin: 0, paddingBottom: '2px', color: 'black', fontSize: '22px' }}>
            {props.drawerTitle || 'Errors were found'}
          </p>
          <p style={{ margin: 0, padding: 0, color: 'darkgrey', fontSize: '14px' }}>
            {props.drawerDescription ||
              'Facebook rejected this item for the reasons shown below.  please take corrective actions.'}
          </p>
        </div>
      </div>
      <div className="divide-y gap-5">
        {errors.map((error, i) => (
          <div className="p-4 flex space-x-16" key={i}>
            <p className="text-red-500 flex-grow font-medium text-md w-1/2">
              {error?.title || 'Error'}
            </p>
            <div className="max-w-md">
              <p className="font-medium">{error.message}</p>
              {error?.details && <p className="text-xs text-gray-800 pt-1">{error?.details}</p>}
              <div className="mt-2">{error.actionButton && error.actionButton}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </Drawer>
)
