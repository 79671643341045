import { useQuery } from 'react-query'
import { savedAudienceService } from '@marketing-milk/frontend'
import { SavedAudience } from '@marketing-milk/interfaces'
import { useEffect, useState } from 'react'
import { getDefaultAudiences } from 'pages/business/campaigns/helpers/campaignForm.helpers'

export function useFetchAllAudiences(businessID: string) {
  const [defaultAudiences, setDefaultAudiences] = useState<SavedAudience[]>([])

  const {
    data: audiences,
    isLoading,
    isError,
    refetch,
  } = useQuery(['audiences', businessID], () => savedAudienceService.getAll(businessID), {
    refetchOnMount: false,
  })

  useEffect(() => {
    if (!audiences) return
    setDefaultAudiences(getDefaultAudiences(audiences))
  }, [audiences])

  return { audiences, isLoading, isError, refetch, defaultAudiences }
}
