import React from 'react'
import './status-indicator.scss'
import { AudienceStatusIndicatorProps, audienceStatusStyles } from './'

export function AudienceStatusIndicator(props: AudienceStatusIndicatorProps) {
  const { status, className } = props

  const style = audienceStatusStyles[status]

  return (
    <>
      <div
        className={`status-indicator audience-status-indicator text-center ${className} ${style.backgroundClass}`}
      >
        <span className={`${style.textClass}`}>{style.text}</span>
      </div>
    </>
  )
}
