import { useState } from 'react'
import {
  CustomAudienceTargetSpec,
  DynamicTargetSpec,
  FacebookTargetingSpec,
  FlexibleTargetSpec,
  Gender,
} from '@marketing-milk/interfaces'
import { useGetUserTraits } from '../../../../../hooks'
import { MultiOptionItem } from '../../../../../components'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { removeItem, useLocationTargetingSpec } from '@marketing-milk/frontend'

export function useFacebookTargetingSpec(initialTargetingSpec?: FacebookTargetingSpec) {
  const [refresh, setRefresh] = useState(0)
  const locations = useLocationTargetingSpec({
    editable: true,
    geoLocations: initialTargetingSpec?.geo_locations,
    excludedGeoLocations: initialTargetingSpec?.excluded_geo_locations,
  })
  const [targetSpec, setTargetSpec] = useState<FacebookTargetingSpec>(
    initialTargetingSpec ?? {
      age_min: 18,
      age_max: 65,
      genders: [1, 2],
      geo_locations: locations.locationTargetSpec.geo_locations,
      excluded_geo_locations: locations.locationTargetSpec.excluded_geo_locations,
      custom_audiences: [],
      excluded_custom_audiences: [],
      flexible_spec: [
        {
          interests: [],
          behaviors: [],
          life_events: [],
          family_statuses: [],
          industries: [],
          income: [],
        },
      ],
      exclusions: {
        interests: [],
        behaviors: [],
        life_events: [],
        family_statuses: [],
        industries: [],
        income: [],
      },
      publisher_platforms: [],
    }
  )

  // This is needed to make sure updates are applied to the Facebook Targeting Spec (targetSpec) above
  useDeepCompareEffect(() => {
    setTargetSpec(prevState => ({
      ...prevState,
      geo_locations: locations.locationTargetSpec.geo_locations,
      excluded_geo_locations: locations.locationTargetSpec.excluded_geo_locations,
    }))
  }, [
    locations.locationTargetSpec.geo_locations,
    locations.locationTargetSpec.excluded_geo_locations,
  ])

  // Get user traits
  const includedTraits = useGetUserTraits(targetSpec, 'include', refresh)
  const excludedTraits = useGetUserTraits(targetSpec, 'exclude', refresh)

  // Misc. Utils
  function setTarget(targetSpec: Partial<FacebookTargetingSpec>) {
    setTargetSpec(_ => ({
      ..._,
      ...targetSpec,
    }))
  }

  function updateAgeRange(minAge?: number, maxAge?: number) {
    setTarget({
      age_min: minAge ? minAge : targetSpec.age_min,
      age_max: maxAge ? maxAge : targetSpec.age_max,
    })
  }

  function updateGenders(genders: Gender[]) {
    setTarget({ genders })
  }

  // User Traits
  function addUserTraits(option: MultiOptionItem) {
    setTargetSpec(_ => {
      // option type translates to keys of our FlexibleTargetSpec (demographics, interests, behaviors)
      const targetSpecKey: keyof FlexibleTargetSpec = option.type as keyof FlexibleTargetSpec

      // DynamicTargetSpec is the generic object contained in arrays for the keys above
      const dynamicSpecItem: DynamicTargetSpec = {
        id: String(option.value),
        name: option.label,
      }

      return {
        ..._,
        //@ts-ignore
        flexible_spec: [
          {
            //@ts-ignore
            ..._.flexible_spec[0],
            //@ts-ignore
            [targetSpecKey]: (_.flexible_spec[0][targetSpecKey] || []).concat(dynamicSpecItem) ?? [
              dynamicSpecItem,
            ],
          },
        ],
      }
    })
    setRefresh(_ => _ + 1)
  }

  function removeUserTraits(option: MultiOptionItem) {
    setTargetSpec(_ => {
      // option type translates to keys of our FlexibleTargetSpec (demographics, interests, behaviors)
      const targetSpecKey: keyof FlexibleTargetSpec = option.type as keyof FlexibleTargetSpec

      //@ts-ignore
      if (_.flexible_spec[0][targetSpecKey]) {
        //@ts-ignore
        const index = _.flexible_spec[0][targetSpecKey].findIndex(x => x.id === option.value)
        //@ts-ignore
        const newTargetArray = removeItem(_.flexible_spec[0][targetSpecKey], index)

        return {
          ..._,
          flexible_spec: [
            {
              //@ts-ignore
              ..._.flexible_spec[0],
              //@ts-ignore
              [targetSpecKey]: newTargetArray,
            },
          ],
        }
      } else {
        return {
          ..._,
        }
      }
    })
    setRefresh(_ => _ + 1)
  }

  function getSelectedUserTraits(): MultiOptionItem[] {
    return includedTraits
  }

  function addExcludedUserTraits(option: MultiOptionItem) {
    setTargetSpec(_ => {
      // option type translates to keys of our FlexibleTargetingSpec (demographics, interests, behaviors)
      const targetSpecKey: keyof FlexibleTargetSpec = option.type as keyof FlexibleTargetSpec

      // DynamicTargetSpec is the generic object contained in arrays for the keys above
      const dynamicSpecItem: DynamicTargetSpec = {
        id: String(option.value),
        name: option.label,
      }

      return {
        ..._,
        exclusions: {
          ..._.exclusions,
          //@ts-ignore
          [targetSpecKey]: _.exclusions[targetSpecKey].concat(dynamicSpecItem) ?? [dynamicSpecItem],
        },
      }
    })
    setRefresh(_ => _ + 1)
  }

  function removeExcludedUserTraits(option: MultiOptionItem) {
    setTargetSpec(_ => {
      // option type translates to keys of our FlexibleTargetingSpec (demographics, interests, behaviors)
      const targetSpecKey: keyof FlexibleTargetSpec = option.type as keyof FlexibleTargetSpec

      //@ts-ignore
      if (_.exclusions[targetSpecKey]) {
        //@ts-ignore
        const index = _.exclusions[targetSpecKey].findIndex(x => x.id === option.value)
        //@ts-ignore
        const newTargetArray = removeItem(_.exclusions[targetSpecKey], index)

        return {
          ..._,
          exclusions: {
            ..._.exclusions,
            [targetSpecKey]: newTargetArray,
          },
        }
      } else {
        return {
          ..._,
        }
      }
    })
    setRefresh(_ => _ + 1)
  }

  function getExcludedUserTraits(): MultiOptionItem[] {
    return excludedTraits
  }

  // Included/Excluded Audiences
  function getIncludedCustomAudiences(): string[] {
    const audiences: string[] = []
    if (targetSpec.custom_audiences && targetSpec.custom_audiences.length > 0) {
      targetSpec.custom_audiences.map(x => audiences.push(x.id))
    }
    return audiences
  }

  function getExcludedCustomAudiences(): string[] {
    const excludedAudiences: string[] = []
    if (targetSpec.excluded_custom_audiences && targetSpec.excluded_custom_audiences.length > 0) {
      targetSpec.excluded_custom_audiences.map(x => excludedAudiences.push(x.id))
    }
    return excludedAudiences
  }

  function addIncludedAudience(facebookAudienceID: string) {
    setTargetSpec(_ => {
      //@ts-ignore
      const newAudience: CustomAudienceTargetSpec = { id: facebookAudienceID }
      return {
        ..._,
        custom_audiences: _.custom_audiences?.concat(newAudience) ?? [newAudience],
      }
    })
  }

  function addExcludedAudience(facebookAudienceID: string) {
    setTargetSpec(_ => {
      //@ts-ignore
      const newExcludedAudience: CustomAudienceTargetSpec = { id: facebookAudienceID }
      return {
        ..._,
        excluded_custom_audiences: _.excluded_custom_audiences?.concat(newExcludedAudience) ?? [
          newExcludedAudience,
        ],
      }
    })
  }

  function removeIncludedAudience(facebookAudienceID: string) {
    setTargetSpec(_ => {
      if (_.custom_audiences) {
        //@ts-ignore
        const index = _.custom_audiences?.findIndex(x => x.id === facebookAudienceID)
        const newAudiences = removeItem(_.custom_audiences, index)

        return {
          ..._,
          custom_audiences: newAudiences,
        }
      } else {
        return {
          ..._,
        }
      }
    })
  }

  function removeExcludedAudience(facebookAudienceID: string) {
    setTargetSpec(_ => {
      if (_.excluded_custom_audiences) {
        const index = _.excluded_custom_audiences?.findIndex(x => x.id === facebookAudienceID)
        const newAudiences = removeItem(_.excluded_custom_audiences, index)

        return {
          ..._,
          excluded_custom_audiences: newAudiences,
        }
      } else {
        return {
          ..._,
        }
      }
    })
  }

  // User Interests
  function addInterest(interest: DynamicTargetSpec) {
    setTargetSpec(_ => ({
      ..._,
      interests: _.interests?.concat(interest) ?? [interest],
    }))
  }

  function addExcludedInterest(interest: DynamicTargetSpec) {
    setTargetSpec(_ => ({
      ..._,
      exclusions: {
        interests: _.interests?.concat(interest) ?? [interest],
      },
    }))
  }

  function removeInterest(interest: DynamicTargetSpec) {
    setTargetSpec(_ => {
      if (_.interests) {
        const index = _.interests?.findIndex(x => x.id === interest.id)
        const newInterests = removeItem(_.interests, index)

        return {
          ..._,
          interests: newInterests,
        }
      } else {
        return {
          ..._,
        }
      }
    })
  }

  function removeExcludedInterest(interest: DynamicTargetSpec) {
    setTargetSpec(_ => {
      if (_.exclusions && _.exclusions.interests) {
        const index = _.exclusions.interests?.findIndex(x => x.id === interest.id)
        const newInterests = removeItem(_.exclusions.interests, index)

        return {
          ..._,
          exclusions: {
            interests: newInterests,
          },
        }
      } else {
        return {
          ..._,
        }
      }
    })
  }

  return {
    ...locations,
    targetSpec,
    updateAgeRange,
    updateGenders,
    addUserTraits,
    removeUserTraits,
    getSelectedUserTraits,
    addExcludedUserTraits,
    removeExcludedUserTraits,
    getExcludedUserTraits,
    getIncludedCustomAudiences,
    getExcludedCustomAudiences,
    addIncludedAudience,
    removeIncludedAudience,
    addExcludedAudience,
    removeExcludedAudience,
    removeInterest,
    addInterest,
    removeExcludedInterest,
    addExcludedInterest,
    setTargetSpec: setTarget,
    setTargetState: setTargetSpec,
  }
}
