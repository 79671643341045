import { AxiosResponse } from 'axios'
import { NestAPI } from '@marketing-milk/frontend'
import { ExtraUserDemographicOption } from '@marketing-milk/interfaces'
import {
  DemographicMultiOptionItem,
  userTraitCategories,
} from '../../../pages/business/audiences/audience-builder'

export async function getAllUserDemographics(
  busID: string | number
): Promise<DemographicMultiOptionItem> {
  const response: AxiosResponse<DemographicMultiOptionItem> = await NestAPI.get(
    `/businesses/${busID}/targeting-metadata/all-user-demographics`
  )
  return response.data
}

export async function getCustomInterests(
  busID: string | number,
  searchPhrase: string
): Promise<ExtraUserDemographicOption[]> {
  const response: AxiosResponse<ExtraUserDemographicOption[]> = await NestAPI.get(
    `/businesses/${busID}/targeting-metadata/extra-interests?keyword=${searchPhrase}`
  )
  // @ts-ignore - Typescript is too strict here. You should be able to pass whatever you want to Array.includes()
  return response.data.filter(_ => userTraitCategories.includes(_.type))
}
