import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import { LeadCardIntro } from '@marketing-milk/interfaces'

export interface FormBulletsProps {
  editable: boolean
  greeting: LeadCardIntro['greeting']
  onChangeBullet(index: number, bullet: string): void
}

export const greetingBulletMaxLength = 80

export function FormBullets({ editable, greeting, onChangeBullet }: FormBulletsProps) {
  // check if a greeting exists and all items are empty
  const bulletsInvalid = !!greeting && greeting?.bodyItems?.every(item => !item)

  if (!greeting) {
    return null
  }

  return (
    <>
      {bulletsInvalid && editable && (
        <FormHelperText error={true}>
          One or more bullets are required when using a greeting
        </FormHelperText>
      )}
      {Array.from(new Array(5)).map((n, i) => (
        <TextField
          label={`Bullet ${i + 1}`}
          fullWidth
          disabled={!editable}
          key={i}
          className="mt-2"
          value={greeting?.bodyItems && greeting?.bodyItems[i]}
          onChange={e => onChangeBullet(i, e.target.value)}
          inputProps={{ maxLength: greetingBulletMaxLength, 'data-testid': 'greeting-list-input' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="subtitle1">
                  {(greeting?.bodyItems && greeting?.bodyItems[i]?.length) ?? 0}/
                  {greetingBulletMaxLength}
                </Typography>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
          placeholder="Include additional details"
        />
      ))}
    </>
  )
}
