import React from 'react'
import { SavedAudience } from '@marketing-milk/interfaces'
import { HoverTip, displayAudienceSize } from '@marketing-milk/frontend'

export function generateAudienceSize(
  audience: SavedAudience,
  recalc: (audience: SavedAudience) => void,
  showIcon: boolean
) {
  // We send audience: SavedAudience in from a cast which means that some of the properties aren't actually the type they say they are.
  // Because of this, I'm casting a "number" to a number to make sure the commas get inserted by the format service.
  const size = displayAudienceSize(
    Number(audience.approximateCountLowerBound),
    Number(audience.approximateCountUpperBound)
  )
  return (
    <>
      {audience.approximateCountUpperBound < 1000 && !audience.isPotentialApproximation ? (
        <>
          <i
            style={{ cursor: 'pointer' }}
            className="fa fa-exclamation-triangle text-inherit text-warning mr-2 audience-recalc"
            onClick={() => recalc(audience)}
          />
        </>
      ) : null}
      {audience.isPotentialApproximation ? (
        <HoverTip
          name={`audience-recalc-${audience.id}`}
          description={
            <span>
              This estimation does not include the Custom Audiences attached to the Audience
            </span>
          }
        >
          <i className="fa fa-exclamation-triangle text-inherit mr-2" />
          {size}
        </HoverTip>
      ) : (
        <>
          {showIcon && <i className="fad fa-users text-inherit mr-2" />} {size}
        </>
      )}
    </>
  )
}
