import { Loading, useGetBusinessID } from '@marketing-milk/frontend'
import {
  AdCreative,
  DraftCampaign,
  PublishCampaignDTO,
  SavedAudience,
} from '@marketing-milk/interfaces'
import { Info } from '@mui/icons-material'
import { Alert, Checkbox, Drawer, Slider, Switch, Tab, Tabs, Tooltip } from '@mui/material'
import { campaignService } from 'app/services'
import { addOrRemove, getCreativeFromPair } from 'app/util'
import { CampaignDetails } from 'components'
import { BlankPageLayout } from 'components/BlankPageLayout'
import { Step } from 'components/Stepper/Step'
import { Stepper } from 'components/Stepper/Stepper'
import { useDraftCampaign } from 'hooks/api/useDraftCampaign'
import { useSprint } from 'hooks/api/useSprint'
import { any, isEqual } from 'lodash/fp'
import { getAdPairs } from 'pages/business/campaigns/helpers/campaignForm.helpers'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getStartEndTimeText } from '../../sprint.helpers'

interface Props {
  draftCampaigns: DraftCampaign[]
  audiences?: SavedAudience[]
  creatives?: AdCreative[]
  isOpen: boolean
  sprintId: number
  onClose: () => void
}

export const PublishDraftCampaignsModal = ({ draftCampaigns, ...props }: Props) => {
  const history = useHistory()
  const businessId = useGetBusinessID()
  const [draftsToUpload, setDraftsToUpload] = useState<DraftCampaign[]>([])
  const [currentViewedDraft, setCurrentViewedDraft] = useState<DraftCampaign>()
  const [uploading, setUploading] = useState(false)
  const [uploadComplete, setUploadComplete] = useState(false)
  const [campaignUploadResults, setCampaignUploadResults] = useState<UploadInfo[]>([])
  const { deleteDraftCampaign } = useDraftCampaign()
  const { refetchPublishedCampaigns } = useSprint(props.sprintId)

  useEffect(() => {
    const [first] = draftsToUpload
    if (!first) return
    setCurrentViewedDraft(first)
  }, [draftsToUpload[0]])

  const isSelected = useCallback(
    (draft: DraftCampaign) => any(isEqual(draft), draftsToUpload),
    [draftsToUpload]
  )

  const clickDraft = (draft: DraftCampaign) =>
    setDraftsToUpload(prevDrafts => addOrRemove(draft, prevDrafts))

  const onComplete = async () => {
    setUploading(true)

    await Promise.all(
      draftsToUpload.map(draft => {
        campaignService
          // @ts-ignore
          .publishCampaign(Number(businessId), { ...draft.config, sprintID: props.sprintId })
          .then(res => {
            if (res) {
              setCampaignUploadResults(results => [
                ...results,
                {
                  message: ' uploaded to facebook',
                  name: draft.config.campaign.name,
                  resultType: 'success',
                },
              ])
              deleteDraftCampaign(draft.id)
              return
            }

            setCampaignUploadResults(results => [
              ...results,
              {
                message: 'uploaded to facebook with errors',
                name: draft.config.campaign.name,
                resultType: 'error',
                description: 'error will display on the published campaigns tab',
              },
            ])
          })
          .catch(err => {
            setCampaignUploadResults(results => [
              ...results,
              {
                message: 'failed to upload',
                name: draft.config.campaign.name,
                resultType: 'error',
                description: `${err}`,
              },
            ])
          })
      })
    )

    setUploading(false)
    setUploadComplete(true)
    refetchPublishedCampaigns()
  }

  return (
    <Drawer open={props.isOpen} onClose={props.onClose}>
      <div style={{ width: '90vw' }}>
        {!(uploadComplete || uploading) && (
          <Stepper submitButtonText="Publish Campaigns">
            <Step
              title={'Choose Campaigns'}
              completed={draftsToUpload.length > 0}
              canContinue={draftsToUpload.length > 0}
              disabled={draftsToUpload.length === 0}
            >
              <BlankPageLayout
                className="max-w-4xl"
                title="Choose Campaign To Publish"
                subtitle="Only the selected campaigns will be published to facebook"
              >
                <div className="w-full grid grid-cols-2 gap-2">
                  {draftCampaigns.map(draftCampaign => (
                    <div
                      className={
                        'flex justify-between items-center w-full p-4 border border-dashed rounded-md'
                      }
                    >
                      <Checkbox
                        color="primary"
                        onClick={() => {
                          clickDraft(draftCampaign)
                        }}
                        defaultChecked={isSelected(draftCampaign)}
                        value={isSelected(draftCampaign)}
                      />
                      <div>
                        <div className="font-semibold">{draftCampaign.config.campaign.name}</div>
                        <div className="">{getStartEndTimeText(draftCampaign.config.campaign)}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </BlankPageLayout>
            </Step>
            <Step title={'Review & Publish'} onNext={onComplete}>
              <BlankPageLayout
                title="Review Campaigns"
                subtitle="These campaigns will be publisehd to facebook.  Make sure they are correct!"
              >
                <div className="w-full flex flex-col p-4 space-y-4">
                  <div className="space-x-4">
                    <Tabs
                      value={currentViewedDraft ? currentViewedDraft?.id : draftsToUpload[0]?.id}
                    >
                      {draftsToUpload.map(draftCampaign => (
                        <Tab
                          style={{
                            paddingLeft: '10px',
                          }}
                          value={draftCampaign.id}
                          label={draftCampaign.config.campaign.name}
                          onClick={() => setCurrentViewedDraft(draftCampaign)}
                        />
                      ))}
                    </Tabs>
                  </div>

                  {currentViewedDraft ? (
                    <CampaignDetails
                      campaignDto={currentViewedDraft.config}
                      selectedCreatives={getAdPairs(
                        currentViewedDraft.config.creativeGroups,
                        props?.creatives || []
                      )}
                      selectedAudiences={props?.audiences?.filter(a =>
                        currentViewedDraft.config.audienceIDs.includes(a.id)
                      )}
                    />
                  ) : (
                    <p>Select a draft to view</p>
                  )}
                </div>
              </BlankPageLayout>
            </Step>
          </Stepper>
        )}

        {(uploadComplete || uploading) && (
          <BlankPageLayout
            title={'Publish Results Will Display Below'}
            subtitle={'This may take a few moments. Do not close this modal or refresh the page'}
          >
            <div className="p-4 space-y-4 border-b min-w-full">
              {uploading && <Loading />}
              {campaignUploadResults.map(result => (
                <div className="w-full mx-auto">
                  <Alert
                    severity={result.resultType}
                    variant="standard"
                    className="flex items-center"
                  >
                    <div className="grid grid-cols-2">
                      <p>{result.name}</p>
                      <p>
                        {result.message}{' '}
                        {result.description && (
                          <Tooltip title={`${result.description}`}>
                            <Info />
                          </Tooltip>
                        )}
                      </p>
                    </div>
                  </Alert>
                </div>
              ))}

              <button
                className="p-8 border border-dashed border-gray-300 hover:bg-gray-100 flex items-center justify-center font-bold text-lg w-full my-8"
                onClick={() => {
                  toast.warn(
                    'it could take a few moments for the new sprint to display in the U.I.'
                  )
                  props.onClose()
                }}
              >
                Close Modal
              </button>
            </div>
          </BlankPageLayout>
        )}
      </div>
    </Drawer>
  )
}

type UploadInfo = {
  name: string
  resultType: 'error' | 'success'
  message: string
  description?: string
}
