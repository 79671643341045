import { NestAPI, useGetBusiness } from '@marketing-milk/frontend'
import { DraftCampaign, DraftCampaignDTO } from '@marketing-milk/interfaces'
import { toast } from 'react-toastify'

export const useDraftCampaign = () => {
  const business = useGetBusiness()

  const endpoint = `businesses/${business.id}/draft-campaigns`

  const createDraftCampaign = (dto: DraftCampaignDTO) =>
    NestAPI.post<DraftCampaign>(`${endpoint}`, dto)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        toast.error(`Create action failed: ${err}`)
        return undefined
      })

  const updateDraftCampaign = async (id: number, dto: DraftCampaignDTO) => {
    await NestAPI.patch(`${endpoint}/${id}`, dto).catch(err =>
      toast.error(`Update action failed: ${err}`)
    )
  }

  const deleteDraftCampaign = async (id: number) => {
    await NestAPI.delete(`${endpoint}/${id}`)
  }

  return {
    updateDraftCampaign,
    createDraftCampaign,
    deleteDraftCampaign,
  }
}
