import { FormName } from './FormName'
import { FormType } from './FormType'
import React, { useContext } from 'react'
import { FormMedia } from './FormMedia'
import { leadCardContext } from '../../context/LeadCardContext'

export function FormMeta() {
  const {
    formName,
    formType,
    intro,
    editable,
    updateFormName,
    updateFormType,
    updateMediaType,
    updateMedia,
  } = useContext(leadCardContext)
  return (
    <div data-testid="form-meta">
      <FormName editable={editable} formName={formName} onChangeFormName={updateFormName} />
      <FormType editable={editable} formType={formType} onChangeFormType={updateFormType} />
      <FormMedia
        editable={editable}
        intro={intro}
        onChangeMedia={updateMedia}
        onChangeMediaType={updateMediaType}
      />
    </div>
  )
}
