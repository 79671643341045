import React from 'react'
import { ReceiptSectionProps } from './Section.types'

export function ReceiptSection({ title, children, onClickEdit }: ReceiptSectionProps) {
  return (
    <div className="row review-row mb-5">
      <div className="col-12 text-left">
        <h5 className="w-100 pb-2 bb-default">
          {title}
          {onClickEdit && (
            <span
              className="float-right text-brand change-step cursor-pointer"
              onClick={onClickEdit}
            >
              View <i className="fad fa-external-link-square-alt ml-2 text-brand" />
            </span>
          )}
        </h5>
        {children}
      </div>
    </div>
  )
}
