import { AdCreativeType } from '@marketing-milk/interfaces'
import ReactSelect from 'react-select'

export type Props = {
  onSelectType: (type: AdCreativeType) => void
  placeholder?: string
  disableMulti?: boolean
}
export const SelectCreativeType = (props: Props) => (
  <ReactSelect
    isMulti={!props.disableMulti}
    isClearable
    options={Object.values(AdCreativeType).map(status => ({
      label: status,
      value: status,
    }))}
    placeholder={props.placeholder}
    // @ts-ignore
    onChange={selections => props.onSelectType(selections.map(({ value }) => value))}
  />
)
