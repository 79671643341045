import React, { useEffect, useState } from 'react'
import { TableSchema } from '../../../../../components/DataTable/types'
import { Adset, Campaign, CampaignAd, CampaignAdset } from '@marketing-milk/interfaces'
import { DataTable } from 'components/DataTable'
import { FormatService, useGetBusinessID, ViewAudienceModal } from '@marketing-milk/frontend'
import { Button } from '@material-ui/core'
import { SingleAdPreview } from 'components'
import DialogModal from 'components/material/dialog/Dialog'
import { AdsTable } from './AdsTable'
import { StatusIndicator } from '../status-indicator'
import { PauseAdObject } from '../PauseAdObject'
import { OnPauseOrResumeAdObjectHandler } from '../../../../../app/services/campaign-builder/campaign/Campaign.types'
import { campaignService } from '../../../../../app/services'
import { Tooltip } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'

type Props = {
  campaign: Campaign
  campaignHasEnded: boolean
  isFutureCampaign: boolean
  onPauseOrResume: OnPauseOrResumeAdObjectHandler
}

export const AdsetsTable = ({
  campaign,
  campaignHasEnded,
  isFutureCampaign,
  onPauseOrResume,
}: Props) => {
  const businessID = useGetBusinessID()
  const [selectedAd, setSelectedAd] = useState<CampaignAd>()
  const [selectedAudience, setSelectedAudience] = useState<any>()
  const [adsets, setAdsets] = useState<CampaignAdset[]>()

  useEffect(() => {
    const getAdsets = async () => {
      setAdsets(await campaignService.getAdsetsForCampaign(Number(businessID), campaign.id))
    }

    getAdsets()
  }, [businessID, campaign])

  type AdsetTableColumnNames =
    | 'Off / On'
    | 'Adset Name'
    | 'Delivery Status'
    | 'Audience'
    | 'Revenue'
    | 'Spend'
    | 'Cost Per'
    | 'Rolling Cost Per'
    | 'RoAS'
    | 'KPI'
    | 'Audience Details'
  const adsetTableSchema: TableSchema<CampaignAdset, AdsetTableColumnNames> = {
    'Off / On': {
      tooltipText:
        'This column indicates whether your adset is currently on or off. When switched on, it will be able to run based on its schedule and settings. When switched off, it will not run.',
      render: adset => (
        <PauseAdObject
          checkedDefault={adset.activeStatus === 'ACTIVE'}
          disabled={!adset.activeStatus}
          onPauseOrResume={checked =>
            onPauseOrResume('Adset', adset.id, checked ? 'resume' : 'pause')
          }
        />
      ),
    },
    'Adset Name': {
      render: adset => adset.name,
    },
    'Delivery Status': {
      render: data => (
        <StatusIndicator
          status={data.status}
          errors={data.errors}
          hasEnded={campaignHasEnded}
          isFuture={isFutureCampaign}
        />
      ),
    },
    Revenue: {
      render: ({ revenue }) =>
        revenue ? FormatService.getValue(revenue, { type: 'currency' }) : 'N/A',
    },
    Spend: {
      render: ({ spend }) => (spend ? FormatService.getValue(spend, { type: 'currency' }) : 'N/A'),
    },
    'Cost Per': {
      render: ({ costPerCurrent }) =>
        costPerCurrent ? FormatService.getValue(costPerCurrent, { type: 'currency' }) : 'N/A',
    },
    'Rolling Cost Per': {
      hidden: !campaign.isCascading,
      render: ({ rollingCostPer }) =>
        rollingCostPer !== null && rollingCostPer !== undefined ? (
          <span
            className={`text-lg ${
              !rollingCostPer || !campaign.maxCostPer || rollingCostPer > campaign.maxCostPer
                ? 'text-danger'
                : 'text-success'
            }`}
          >
            {FormatService.getValue(rollingCostPer, { type: 'currency' })}
          </span>
        ) : (
          'Pending'
        ),
    },
    RoAS: {
      render: ({ roas }) => (roas ? `${FormatService.getValue(roas)}x` : 'N/A'),
    },
    KPI: {
      render: ({ kpi }) => (
        <>
          <span>{FormatService.getValue(kpi)}</span>
          <Tooltip title="Your adsets may not total up to be the same as the campaign-level. This is due to how adset results are currently extrapolated.">
            <HelpOutline className="text-primary ml-1" fontSize="small" />
          </Tooltip>
        </>
      ),
    },
    'Audience Details': {
      render: adset => {
        if (adset)
          return (
            <Button
              onClick={() => setSelectedAudience({ ...adset, id: adset.audienceID ?? adset.id })}
              variant="contained"
              color="secondary"
            >
              View Audience
            </Button>
          )
        return <p style={{ color: 'darkgrey', fontSize: '12px' }}>No Audience Data</p>
      },
    },
  }

  return (
    <div className="p-4 flex flex-col items-center justify-center w-full border-b-2 border-purple-400">
      <h1 style={{ fontSize: '28px', fontWeight: 600, marginBottom: '20px' }}>Adsets</h1>
      {selectedAudience && (
        <ViewAudienceModal
          showModal={!!selectedAudience}
          audiences={[selectedAudience]}
          onHideModal={() => setSelectedAudience(undefined)}
          onRecalc={() => ''}
        />
      )}
      <div className="w-full">
        <DataTable<CampaignAdset>
          recordsList={adsets}
          tableSchema={adsetTableSchema}
          hideColumnFilter
          hidePagination
          hideSearch
          expansionPanel={{
            shouldExpand: () => true,
            render: adSet => (
              <div
                style={{
                  padding: '30px 40px',
                  paddingBottom: '70px',
                  background: '#eee',
                  flexGrow: 1,
                }}
              >
                <h1 style={{ fontSize: '22px', fontWeight: 600, marginBottom: '20px' }}>Ads</h1>
                <AdsTable
                  campaign={campaign}
                  adSet={adSet}
                  campaignHasEnded={campaignHasEnded}
                  campaignIsFuture={isFutureCampaign}
                  onPauseOrResume={onPauseOrResume}
                />
              </div>
            ),
          }}
        />
      </div>
    </div>
  )
}
