import { PathCards } from './'
import React, { useState } from 'react'
import { AudienceHelp } from './AudienceHelp'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import DialogModal from 'components/material/dialog/Dialog'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { SavedAudience, SavedAudienceType } from '@marketing-milk/interfaces'
import {
  HumanMadeAudienceWizard,
  AiAudienceWizard,
  WebsiteAudienceWizard,
  EngagementAudienceWizard,
  FunnelAudienceWizard,
  LeadCardAudienceWizard,
  CustomerFileAudienceWizard,
} from './hooks/wizards'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    audienceBuilder: {
      width: '90vw',
      '& @media (max-width: 767px)': {
        width: '98vw',
      },
    },
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  })
)

export interface AudienceBuilderProps {
  mode: 'create' | 'edit'
  onAudienceCreation?: () => void
  selectedWizard?: SavedAudienceType
  openModal?: boolean
  onCloseModal?: () => void
  audience?: SavedAudience
  hideToggle?: boolean
}

export function AudienceBuilder(props: AudienceBuilderProps) {
  const classes = useStyles()
  const {
    mode,
    onAudienceCreation,
    selectedWizard,
    openModal,
    audience,
    onCloseModal,
    hideToggle = false,
  } = props
  const [drawerOpen, setDrawerOpen] = useState(openModal ?? false)
  const [selectedPath, setSelectedPath] = useState<SavedAudienceType | undefined>(
    selectedWizard ?? undefined
  )
  const [discardModalOpen, setDiscardModalOpen] = useState<boolean>(false)

  const closeAudienceBuilder = () => {
    setDrawerOpen(false)
    onCloseModal && onCloseModal()
    setSelectedPath(undefined)
  }

  const onCreatedAudience = () => {
    closeAudienceBuilder()
    onAudienceCreation && onAudienceCreation()
  }

  const onDiscard = () => {
    setDiscardModalOpen(false)
    closeAudienceBuilder()
  }

  return (
    <div>
      {!hideToggle && (
        <div>
          <Button variant="contained" color="primary" onClick={() => setDrawerOpen(true)}>
            <GroupAddIcon className="mr-2" />
            Create Audience
          </Button>
          <AudienceHelp />
        </div>
      )}

      <Drawer
        open={drawerOpen}
        onClose={() => (!selectedPath ? setDrawerOpen(false) : setDiscardModalOpen(true))}
      >
        <div className={`audience-builder ${classes.audienceBuilder}`}>
          <div className="builder-content">
            {!selectedPath && <PathCards onSelectedPath={setSelectedPath} />}
            {selectedPath === 'HUMAN_MADE' && (
              <HumanMadeAudienceWizard
                mode={mode}
                audience={audience ?? undefined} // Entry point for data when editing any audience
                onExitWizard={() => setDiscardModalOpen(true)}
                onPublishWizard={() => onCreatedAudience()}
              />
            )}
            {selectedPath === 'AI' && (
              <AiAudienceWizard
                mode={mode}
                onExitWizard={setSelectedPath}
                onPublishWizard={() => onCreatedAudience()}
              />
            )}
            {selectedPath === 'WEBSITE_TRAFFIC' && (
              <WebsiteAudienceWizard
                mode={mode}
                onExitWizard={() => setDiscardModalOpen(true)}
                onPublishWizard={() => onCreatedAudience()}
              />
            )}
            {selectedPath === 'ENGAGED_USERS' && (
              <EngagementAudienceWizard
                mode={mode}
                onExitWizard={() => setDiscardModalOpen(true)}
                onPublishWizard={() => onCreatedAudience()}
              />
            )}
            {selectedPath === 'MM_GENERATED' && (
              <FunnelAudienceWizard
                mode={mode}
                onExitWizard={() => setDiscardModalOpen(true)}
                onPublishWizard={() => onCreatedAudience()}
              />
            )}
            {selectedPath === 'CUSTOMER_FILE' && (
              <CustomerFileAudienceWizard
                mode={mode}
                onExitWizard={() => setDiscardModalOpen(true)}
                onPublishWizard={() => onCreatedAudience()}
              />
            )}
            {selectedPath === 'LEAD_CARD' && (
              <LeadCardAudienceWizard
                mode={mode}
                onExitWizard={() => setDiscardModalOpen(true)}
                onPublishWizard={() => onCreatedAudience()}
              />
            )}
          </div>
        </div>
      </Drawer>

      {/*// Discard Modal*/}
      <DialogModal
        title="Are You Sure?"
        isOpen={discardModalOpen}
        onOpen={() => {}}
        onClose={() => setDiscardModalOpen(false)}
        content={
          <div className="text-center p-3">
            <Typography variant="h5">Discard Your Audience Progress?</Typography>
            <Typography variant="body1">
              Once you delete your changes you won't be able to undo it.
            </Typography>
          </div>
        }
        actions={
          <>
            <Button autoFocus onClick={() => setDiscardModalOpen(false)}>
              Cancel
            </Button>
            <Button
              className={classes.deleteButton}
              variant="contained"
              color="inherit"
              onClick={() => onDiscard()}
              autoFocus
            >
              Delete
            </Button>
          </>
        }
      />
    </div>
  )
}
