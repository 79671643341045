import React from 'react'
import { toast } from 'react-toastify'
import { AudienceWizardProps } from './AudienceWizard.types'
import { getBaseWizardSteps } from '../BaseWizardSteps'
import { accountCategories, pageCategories } from '../steps'
import { useEngagementAudience } from '../use-engagement-audience'
import { AudienceSummary, FacebookPage, InstagramAccount } from '../steps'
import { audienceBuilderService, useGetBusinessID, Line } from '@marketing-milk/frontend'
import {
  EngagedUsersAudienceDTO,
  SavedAudienceStandardTargetingSpec,
} from '@marketing-milk/interfaces'

import { handleSubmitAudienceError } from './audience-error-handling'
import { AudienceWizard } from '../../audience-wizard'
import { ReceiptSection } from '../../../../campaign-builder/steps/receipt/section'

export function EngagementAudienceWizard({ onPublishWizard, audience }: AudienceWizardProps) {
  const businessID = useGetBusinessID()
  const engagementAudience = useEngagementAudience(businessID, audience)

  async function submitAudience() {
    const dto: EngagedUsersAudienceDTO = {
      name: engagementAudience.audienceName,
      description: undefined,
      targetingSpec: engagementAudience.targetSpec as SavedAudienceStandardTargetingSpec,
      pageRetention: engagementAudience.audienceData.pageRetention,
      instagramRetention: engagementAudience.audienceData.instagramRetention,
      instagramAccountID:
        engagementAudience.audienceData.instagramAccountID === ''
          ? undefined
          : engagementAudience.audienceData.instagramAccountID,
      fbMenuSelection: engagementAudience.audienceData.pageSelectedCategory,
      igMenuSelection: engagementAudience.audienceData.instagramSelectedCategory,
      isDefault: engagementAudience.isDefault,
    }

    try {
      await audienceBuilderService.publishEngagedUsersAudience(businessID, dto)
      toast.success("You're audience has been created and published!")
      onPublishWizard(true)
    } catch (err) {
      handleSubmitAudienceError(err)
    }
  }

  return (
    <AudienceWizard
      steps={[
        {
          title: 'Instagram',
          completed:
            !!engagementAudience.audienceData.instagramRetention &&
            // !!engagementAudience.audienceData.instagramAccountID &&
            !!engagementAudience.audienceData.instagramSelectedCategory,
          canContinue:
            !!engagementAudience.audienceData.instagramRetention &&
            // !!engagementAudience.audienceData.instagramAccountID &&
            !!engagementAudience.audienceData.instagramSelectedCategory,
          children: (
            <InstagramAccount
              accountID={engagementAudience.audienceData.instagramAccountID}
              retention={engagementAudience.audienceData.instagramRetention}
              onUpdateRetention={engagementAudience.onUpdateIgRetention}
              onAddCategory={engagementAudience.onUpdateIgCategory}
              onUpdateAccountID={engagementAudience.onUpdateIgAccountID}
              selectedCategory={engagementAudience.audienceData.instagramSelectedCategory}
              targetSpec={engagementAudience.targetSpec}
            />
          ),
        },
        {
          title: 'Facebook Page',
          completed:
            !!engagementAudience.audienceData.pageRetention &&
            !!engagementAudience.audienceData.pageSelectedCategory,
          canContinue:
            !!engagementAudience.audienceData.pageRetention &&
            !!engagementAudience.audienceData.pageSelectedCategory,
          children: (
            <FacebookPage
              facebookPageData={engagementAudience.facebookPageData}
              selectedCategory={engagementAudience.audienceData.pageSelectedCategory}
              onRetentionUpdate={engagementAudience.onPageRetentionUpdate}
              onSelectCategory={engagementAudience.onUpdatePageCategory}
              pageRetention={engagementAudience.audienceData.pageRetention}
              targetSpec={engagementAudience.targetSpec}
            />
          ),
        },
        ...getBaseWizardSteps(engagementAudience),
        {
          title: 'Summary',
          completed: !!engagementAudience.audienceName,
          canContinue: !!engagementAudience.audienceName,
          onNext: submitAudience,
          children: (
            <AudienceSummary
              includedUserTraits={engagementAudience.getSelectedUserTraits()}
              excludedUserTraits={engagementAudience.getExcludedUserTraits()}
              targetingSpec={engagementAudience.targetSpec}
              name={engagementAudience.audienceName}
              description={engagementAudience.audienceDescription}
              onUpdateName={engagementAudience.setAudienceName}
              onUpdateDescription={engagementAudience.setAudienceDescription}
              onSetDefault={engagementAudience.setIsDefault}
              isDefault={engagementAudience.isDefault}
              customReceiptSection={
                <>
                  <ReceiptSection
                    title="Instagram Account Settings"
                    children={
                      <>
                        <Line
                          left="Selected Account:"
                          right={`@${engagementAudience.selectedAccount?.username}`}
                        />
                        <Line
                          left="Category:"
                          right={
                            accountCategories[
                              engagementAudience.audienceData.instagramSelectedCategory
                            ]
                          }
                        />
                        <Line
                          left="Retention:"
                          right={`${engagementAudience.audienceData.instagramRetention} Days`}
                        />
                      </>
                    }
                  />
                  <ReceiptSection
                    title="Facebook Page Settings"
                    children={
                      <>
                        <Line
                          left="Facebook Page:"
                          right={`${engagementAudience.facebookPageData?.name} – ${engagementAudience.facebookPageData?.id}`}
                        />
                        <Line
                          left="Category:"
                          right={
                            pageCategories[engagementAudience.audienceData.pageSelectedCategory]
                          }
                        />
                        <Line
                          left="Retention:"
                          right={`${engagementAudience.audienceData.pageRetention} Days`}
                        />
                      </>
                    }
                  />
                </>
              }
            />
          ),
        },
      ]}
    />
  )
}
