import React from 'react'
import { useBaseAudience } from '../../'
import { StepHeader } from '../StepHeader'
import { AgeRange, UserGender, LocationTarget, LocationList } from './'
import { FullPageContent } from 'components/layout/FullPageLayout/Content/FullPageContent'
import {
  useLocalCompetitionFinder,
  CompetitionFinderMapTools,
} from 'pages/business/local-competition'
import {
  GoogleMap,
  AudienceLocationMapTools,
  LocationSearch,
  FullPageSidebar,
} from '@marketing-milk/frontend'

export function Locations(props: ReturnType<typeof useBaseAudience>) {
  const finder = useLocalCompetitionFinder(true)
  return (
    <>
      <div className="flex flex-col w-full h-full max-h-full justify-between items-stretch">
        <StepHeader
          title="User Type & Locations Targeting"
          description="Target users based on age range, gender, geographical location, and more."
          targetingSpec={props.targetSpec}
        />
        <FullPageContent heightOffset={225}>
          <FullPageSidebar>
            <div className="pr-4">
              <AgeRange
                updateAgeRange={props.updateAgeRange}
                age_min={props.targetSpec.age_min}
                age_max={props.targetSpec.age_max}
              />
              <UserGender updateGenders={props.updateGenders} genders={props.targetSpec.genders} />
              <LocationTarget
                updateLocationTargetType={props.updateLocationTargetType}
                locationTypes={props.targetSpec.geo_locations?.location_types}
              />
              <LocationList
                includedLocations={props.targetSpec.geo_locations?.custom_locations}
                excludedLocations={props.targetSpec.excluded_geo_locations?.custom_locations}
                includedCountries={props.targetSpec.geo_locations?.countries}
                includedRegions={props.targetSpec.geo_locations?.regions}
                excludedCountries={props.targetSpec.excluded_geo_locations?.countries}
                excludedRegions={props.targetSpec.excluded_geo_locations?.regions}
                updateLocationRadius={props.updateLocationRadius}
                updateLocationActionType={props.updateLocationActionType}
                updateCountryActionType={props.updateCountryActionType}
                updateRegionActionType={props.updateRegionActionType}
                delLocation={props.delLocation}
                delCountry={props.delCountry}
                delRegion={props.delRegion}
              />
            </div>
          </FullPageSidebar>
          <div className="w-full h-full overflow-y-scroll">
            <div className="w-full h-full relative">
              <GoogleMap
                center={finder.businessCoordinates}
                zoom={13}
                onClick={e => props.mapClick(e)}
                mapContainerStyle={{ width: '100%', height: '100%', minHeight: 400 }}
              >
                <LocationSearch
                  addLocation={props.editable ? props.addLocation : () => {}}
                  setMapData={() => {}}
                  // setMapData={props.editable ? props.setMapData : () => {}}
                  actionType={props.actionType}
                  setActionType={props.setActionType}
                  className="max-w-550px position-absolute"
                  style={{
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    top: '35px',
                    width: '100%',
                    maxWidth: '500px',
                    position: 'absolute',
                  }}
                />
                <CompetitionFinderMapTools {...finder} />
                <AudienceLocationMapTools {...props} />
              </GoogleMap>
            </div>
          </div>
        </FullPageContent>
      </div>
    </>
  )
}
