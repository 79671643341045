import React from 'react'
import { Collapse, makeStyles, TableCell, TableRow } from '@material-ui/core'

type Props = {
  expansionPanel: React.ReactNode
  open: boolean
}

const useRowStyles = makeStyles({
  expansionRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableCell: {
    display: 'inline-block',
    width: '100%',
  },
})

export const ExpandableRow = (props: Props) => {
  const classes = useRowStyles()

  return (
    <TableRow className={classes.expansionRow} selected>
      <TableCell style={{ padding: 0, margin: 0, width: '100%', flexGrow: 1 }} colSpan={16}>
        <Collapse in={props.open} timeout="auto" unmountOnExit className={classes.tableCell}>
          <div style={{ flexGrow: 1, width: '100%', display: 'flex' }}>{props.expansionPanel}</div>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
