import React from 'react'
import Box from '@material-ui/core/Box'
import { SelectItem } from 'components/select-item'
import { SelectAsync } from 'components/select/SelectAsync'
import { MultiOptionMenu } from 'components/multi-option-menu'
import { getCustomInterests } from 'app/services/audience-builder'
import { DemographicMultiOptionItem, DemographicsProps } from '../Demographics.types'
import {
  addDemographicOption,
  getSpecAtIndex,
  removeDemographicOption,
  transformSpecArrayToOptions,
  transformSpecToOptions,
} from '../helpers/demographics.helpers'

type Props = {
  demographicSpecIndex: number
} & DemographicsProps

export function IncludedDemographics({ targetSpec, setTargetSpec, options, ...props }: Props) {
  const fetchCustomInterestOptions = async (inputValue: string) => {
    const customInterests = await getCustomInterests(props.businessID, inputValue)
    const selectedTraits = transformSpecArrayToOptions(targetSpec.flexible_spec).map(
      option => option.value
    )
    return customInterests
      .filter(({ id }) => !selectedTraits.includes(id))
      .map(({ name, id, type }) => ({
        label: name,
        value: id,
        type: type,
      }))
  }

  const addIncludedDemographic = (option: DemographicMultiOptionItem) =>
    setTargetSpec(targetSpec => ({
      ...targetSpec,
      flexible_spec: addDemographicOption(
        option,
        props.demographicSpecIndex,
        targetSpec?.flexible_spec
      ),
    }))

  const removeIncludedDemographic = (option: DemographicMultiOptionItem) =>
    setTargetSpec(targetSpec => ({
      ...targetSpec,
      flexible_spec: removeDemographicOption(
        option,
        props.demographicSpecIndex,
        targetSpec?.flexible_spec
      ),
    }))

  const getDemographicOptions = () =>
    transformSpecToOptions(getSpecAtIndex(props.demographicSpecIndex, targetSpec?.flexible_spec))

  return (
    <>
      <div className="row mt-5">
        <div className="col-12">
          <p className="text-bold">
            {props.demographicSpecIndex > 0
              ? 'They also must have one of these traits'
              : 'One of these traits'}
          </p>
          <div>
            <Box display="flex" alignItems="center">
              <MultiOptionMenu
                toggleTitle="Add Demographics, Interests, and Behaviors"
                options={options}
                selectedValues={getDemographicOptions().map(({ value }) => value)}
                // @ts-ignore
                onSelectOption={addIncludedDemographic}
                // @ts-ignore
                onRemoveOption={removeIncludedDemographic}
              />
              <Box width="2em" />
              <SelectAsync
                placeholder="Search for custom interests..."
                onSelectOption={addIncludedDemographic}
                fetchOptions={fetchCustomInterestOptions}
              />
            </Box>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          {getDemographicOptions().map((option, index) => (
            <SelectItem
              key={index}
              title={option.label ?? ''}
              onClick={() => removeIncludedDemographic(option)}
            />
          ))}
        </div>
      </div>
    </>
  )
}
