import React from 'react'

type Props = {
  label: string
  description?: string
  children: React.ReactChild
}

export const DescriptiveFieldLayout = ({ label, description, children }: Props) => (
  <div className="w-full grid grid-cols-1 sm:grid-cols-2 py-4 border-b">
    <div>
      <p className="text-lg font-medium text-gray-600">{label}</p>
      {description && <p className="text-xs max-w-md text-gray-800">{description}</p>}
    </div>
    {children}
  </div>
)
