import { uniq } from 'lodash'
import { MobileProgress } from './'
import { Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { WizardProps, defaultWizardState } from './'
import { ProgressCard } from 'components/cards/action-card'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { Modal, useGetBusinessID } from '@marketing-milk/frontend'

const useStyles = makeStyles(theme => ({
  spinner: {
    color: 'white',
  },
}))

export function Wizard(props: WizardProps) {
  const classes = useStyles()
  const history = useHistory()
  const businessID = useGetBusinessID()
  const {
    type,
    steps,
    activeStep = 0,
    onSubmit,
    completionButtonText = 'Publish',
    showProgressBar = true,
  } = props
  const [state, setState] = useState({
    ...defaultWizardState,
    active: activeStep ?? 0,
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { active, completed } = state
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    setState(x => ({
      ...x,
      active: activeStep ?? 0,
    }))
    setShowModal(false)
  }, [activeStep])

  function goForward(): void {
    if (steps[active].procedureModal && steps[active].isComplete) setShowModal(true)
    else if (steps[active].isComplete) {
      setState(prevState => ({
        active: prevState.active + 1,
        completed: uniq([prevState.active, ...prevState.completed]),
      }))
    }
  }

  function goBackward(): void {
    setState(prevState => ({
      active: prevState.active - 1,
      completed: prevState.completed,
    }))
  }

  function goToStep(step: number): void {
    if (step === 0 || steps[step]?.isComplete) {
      setState(prevState => ({ active: step, completed: prevState.completed }))
    }
  }

  async function onSubmitWithLoading() {
    if (isSubmitting === false) {
      setIsSubmitting(true)
      try {
        await onSubmit!()
        toast.success('Success!')

        // redirect user to campaigns page after completing any campaign builder path
        history.push(`/${businessID}/campaigns`)

        setTimeout(() => {
          toast.warn('Your Browser Will Refresh in 5 seconds')
        }, 100)

        setTimeout(() => {
          document.location.reload()
        }, 5000)
      } catch (err: any) {
        setIsSubmitting(false)
        setShowModal(false)
        toast.warn(`There was an error processing your campaign: ${err.message}`)
      }
    }
  }

  return (
    <div
      className=""
      style={{
        width: '100%',
        height: '100vh',
        padding: '10% 2%',
        paddingTop: '2%',
        overflow: 'auto',
      }}
    >
      <div className="row">
        <div
          className={`col-12 order-last order-xl-first ${
            steps[active].hideProgressBar || !showProgressBar ? 'col-xl-12' : 'col-xl-10'
          }`}
          style={{}}
        >
          <div className="">
            <h3 className="mb-2">{steps[active].name}</h3>
            <hr style={{ borderTop: '2px solid rgba(100,108,154,0.31)' }} />
          </div>

          <div style={{}} className="overflowY-auto">
            {steps[active].component}
          </div>

          <div className="mb-4">
            <hr style={{ borderTop: '2px solid rgba(100,108,154,0.31)' }} />
            <div className="row">
              <div className="col">
                {active > 0 && (
                  <span className="btn btn-drive prev-step" onClick={goBackward}>
                    Previous
                  </span>
                )}
              </div>
              <p className="col-6 margin-top-1 text-center text-small">
                Changes are not saved. If you exit the wizard before publishing all progress will be
                lost.
              </p>
              <div className="col">
                {active < steps.length - 1 && (
                  <span
                    className={`btn btn-drive float-right next-step ${
                      !steps[active]?.isComplete ? 'disabled cursor-default' : ''
                    }`}
                    onClick={goForward}
                  >
                    Next
                  </span>
                )}
                {active === steps.length - 1 && (
                  <span
                    className={`btn btn-brand float-right submit-button ${
                      !steps[active]?.isComplete ? 'disabled cursor-default' : ''
                    }`}
                    onClick={() =>
                      steps[active]?.isComplete
                        ? steps[active].procedureModal
                          ? setShowModal(true)
                          : onSubmitWithLoading()
                        : null
                    }
                  >
                    {isSubmitting ? (
                      <CircularProgress className={classes.spinner} size="1em" />
                    ) : (
                      completionButtonText
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*// hide progress bar on last step of wizard*/}
        <div
          style={{ height: 'calc(100vh - 8rem)' }}
          className={`col-12 overflowY-auto steps-list order-first order-xl-last ${
            steps[active].hideProgressBar || !showProgressBar ? 'd-none' : 'col-xl-2'
          }`}
        >
          <div className="d-none d-xl-block">
            <h3 className="text-left mt-1">Progress</h3>
            {steps.map((step, i) => (
              <ProgressCard
                key={i}
                status={step.isComplete ? 'complete' : i === active ? 'active' : 'inactive'}
                title={`${i + 1}. ${step.name}`}
                onClick={() => goToStep(i)}
                classes={i === 0 || steps[i]?.isComplete ? '' : 'cursor-default'}
              >
                {step.stepPreview}
              </ProgressCard>
            ))}
          </div>
          <div className="d-block d-xl-none">
            <MobileProgress steps={steps} activeStep={active} goToStep={goToStep} />
          </div>
        </div>
      </div>
      <Modal isOpen={showModal} onToggle={() => setShowModal(!showModal)}>
        {steps[active].procedureModal}
        {active === steps.length - 1 && (
          <>
            <div className="col-12 text-center">
              <span
                className="btn btn-default lose-final-modal"
                onClick={() => setShowModal(false)}
              >
                Close
              </span>
              <span
                className={`
                  btn btn-drive continue ml-3
                  ${steps[active]?.procedureModalIsComplete ? '' : 'disabled cursor-default'}
                  ${isSubmitting && 'disabled cursor-default'}
                `}
                onClick={() =>
                  steps[active]?.procedureModalIsComplete && !isSubmitting
                    ? onSubmitWithLoading()
                    : null
                }
              >
                {isSubmitting ? <Spinner color="primary" /> : completionButtonText}
              </span>
            </div>
          </>
        )}
      </Modal>
    </div>
  )
}
