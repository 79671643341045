import { FBCallToAction } from '@marketing-milk/interfaces'
import { InputLabel } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import FormHelperText from '@mui/material/FormHelperText'
import React, { ReactNode, useEffect } from 'react'

export interface SelectCallToActionProps {
  callToAction: FBCallToAction
  onChange(callToAction: FBCallToAction): void
  readOnly: boolean
  customLabel?: ReactNode
  customLabelID?: string
  onlyLeadOptions?: boolean
  error?: boolean
  helperText?: string
}

export const BAD_CALL_TO_ACTION_FIELDS: Partial<Array<FBCallToAction>> = [
  FBCallToAction.BUY,
  FBCallToAction.CALL,
  FBCallToAction.CALL_ME,
  FBCallToAction.CONTACT,
  FBCallToAction.FIND_A_GROUP,
  FBCallToAction.FOLLOW_NEWS_STORYLINE,
  FBCallToAction.FOLLOW_PAGE,
  FBCallToAction.GET_DIRECTIONS,
  FBCallToAction.LIKE_PAGE,
  FBCallToAction.MESSAGE_PAGE,
  FBCallToAction.RECORD_NOW,
  FBCallToAction.REFER_FRIENDS,
  FBCallToAction.SAY_THANKS,
  FBCallToAction.SEND_A_GIFT,
  FBCallToAction.SHARE,
  FBCallToAction.SOTTO_SUBSCRIBE,
  FBCallToAction.START_ORDER,
  FBCallToAction.VIDEO_ANNOTATION,
  FBCallToAction.VISIT_PAGES_FEED,
  FBCallToAction.WOODHENGE_SUPPORT,
  FBCallToAction.BOOK_NOW,
]

export const LEAD_CALL_TO_ACTION_OPTIONS: Partial<Array<FBCallToAction>> = [
  FBCallToAction.SUBSCRIBE,
  FBCallToAction.APPLY_NOW,
  FBCallToAction.DOWNLOAD,
  FBCallToAction.GET_OFFER,
  FBCallToAction.GET_QUOTE,
  FBCallToAction.LEARN_MORE,
  FBCallToAction.SIGN_UP,
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

export const CALL_TO_ACTION_OPTIONS: Array<{ label: string; value: string }> = Object.entries(
  FBCallToAction
)
  .filter(([key]) => !BAD_CALL_TO_ACTION_FIELDS.includes(key as FBCallToAction))
  .map(([key, value]) => ({
    label: value.replace(/_/g, ' '), // replace all underscores with spaces
    value: key,
  }))

export function SelectCallToAction({
  callToAction,
  onChange,
  readOnly,
  customLabel,
  customLabelID,
  onlyLeadOptions,
  error,
  helperText,
}: SelectCallToActionProps) {
  const classes = useStyles()
  const selectedCTA = CALL_TO_ACTION_OPTIONS.find(_ => _.value === callToAction)
  const ctaOptions = CALL_TO_ACTION_OPTIONS
    ? onlyLeadOptions
      ? CALL_TO_ACTION_OPTIONS.filter(({ label, value }) =>
          LEAD_CALL_TO_ACTION_OPTIONS.includes(value as FBCallToAction)
        )
      : CALL_TO_ACTION_OPTIONS
    : []

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as FBCallToAction)
  }

  // Resets the CTA Dropdown to a valid Lead Card Option if we switch to onlyLeadOptions
  useEffect(() => {
    if (
      onlyLeadOptions &&
      !LEAD_CALL_TO_ACTION_OPTIONS.includes(selectedCTA?.value as FBCallToAction)
    ) {
      onChange(FBCallToAction.APPLY_NOW)
    }
  }, [onlyLeadOptions])

  return (
    <FormControl fullWidth>
      <InputLabel id={`cta-select-label-${customLabelID}`}>
        {customLabel ?? 'Call To Action'}
      </InputLabel>
      <Select
        error={error}
        labelId={`cta-select-label-${customLabelID}`}
        id="cta-select"
        fullWidth
        displayEmpty
        className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'call to action select', 'data-testid': 'cta-select' }}
        value={selectedCTA ? selectedCTA.value : null}
        onChange={handleChange}
        disabled={readOnly}
      >
        <MenuItem value="" disabled>
          Select a call to action...
        </MenuItem>
        {ctaOptions?.map((x, i) => (
          <MenuItem key={i} value={x.value}>
            {x.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}
