import axios from 'axios'
import { toast } from 'react-toastify'

export const handleSubmitAudienceError = (err: unknown) => {
  if (axios.isAxiosError(err)) {
    const message = err.response?.data.message ?? err.message
    const stringMessage = typeof message === 'string' ? message : JSON.stringify(message)
    toast.error(stringMessage)
  } else if (err instanceof Error && err.message) {
    toast.error(err.message)
  } else {
    toast.error('Error')
  }
}
