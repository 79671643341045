import React from 'react'
import TextField from '@material-ui/core/TextField'
import { LeadCardIntro } from '@marketing-milk/interfaces'

export interface FormParagraphProps {
  editable: boolean
  greeting: LeadCardIntro['greeting']
  onChangeParagraph(paragraph: string): void
}

export function FormParagraph({ editable, greeting, onChangeParagraph }: FormParagraphProps) {
  const paragraphInvalid = !!greeting && !greeting?.bodyItems[0]
  return (
    <TextField
      error={paragraphInvalid && editable}
      helperText={paragraphInvalid && editable && 'This field is required when using a greeting'}
      fullWidth
      multiline
      rows={2}
      disabled={!editable}
      value={greeting?.bodyItems[0]}
      onChange={event => onChangeParagraph(event.target.value as string)}
      placeholder="Enter text that tells people about your product or service"
      inputProps={{ 'data-testid': 'greeting-paragraph-input' }}
    />
  )
}
