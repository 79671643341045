import { AudiencesTableProps } from './AudiencesTable.types'
import { DataTable, TableSchema } from 'components/DataTable'
import { HoverTip } from '@marketing-milk/frontend'
import { SavedAudience, SavedAudienceStatus } from '@marketing-milk/interfaces'
import { Theme, makeStyles } from '@material-ui/core'

import { AudienceTools } from 'pages/business/audiences/list-audiences/audience-tools'
import Moment from 'moment'
import React from 'react'
import { StatusIndicator } from 'pages/business/campaigns/components/status-indicator'
import { audienceTypes } from 'pages/business/audiences/list-audiences'
import { generateAudienceSize } from 'pages/business/audiences/list-audiences/const'

const useStyles = makeStyles((theme: Theme) => ({
  facebookImported: {
    color: theme.palette.warning.dark,
  },
}))

export function AudiencesTable({
  audiences,
  deleteAudience,
  viewAudience,
  recalcAudience,
  editAudience,
  onCheck,
  checkedAudiences,
  onSetDefault,
}: AudiencesTableProps) {
  const classes = useStyles()

  type Columns =
    | 'Audience Name'
    | 'Type'
    | 'Size'
    | 'Last Used'
    | 'Date Created'
    | 'Status'
    | 'Tools'
  const audienceTableSchema: TableSchema<SavedAudience, Columns> = {
    'Audience Name': {
      render: audience => (
        <div className="d-block" style={{ lineHeight: '20px' }}>
          <p className="mb-0" style={{ fontSize: 12 }}>
            {audience.isDefault ? (
              <HoverTip name="default-audience" description={<span>Default Audience</span>}>
                <i className="fad fa-star text-brand text-inherit mr-2" />
              </HoverTip>
            ) : null}
            {audience.name}
          </p>
          <small>Updated {Moment.unix(audience.updated_at).fromNow()}</small>
        </div>
      ),
    },
    Type: {
      render: audience => (
        <span>
          {!audience.type ? (
            <>
              <i className={'fab fa-facebook-f text-inherit mr-2'} />
              <span className={classes.facebookImported}>Facebook Imported</span>
            </>
          ) : (
            <>
              <i className={`fad fa-${audienceTypes[audience.type].icon} text-inherit mr-2`} />
              {audienceTypes[audience.type].title}
            </>
          )}
        </span>
      ),
    },
    Size: {
      render: audience => generateAudienceSize(audience, () => recalcAudience(audience), false),
    },
    Status: {
      render: audience => (
        <StatusIndicator
          status={
            // TODO: until we revisit audience status, this will work
            audience.status === SavedAudienceStatus.Processing ? 'IN_PROCESS' : audience.status
          }
        />
      ),
    },
    'Last Used': {
      // render: audience => generateAudienceSize(audience, () => recalcAudience(audience), false),
      render: ({ timeLastUsed }) =>
        timeLastUsed ? Moment.unix(timeLastUsed).fromNow() : 'Never Used',
    },
    'Date Created': {
      render: audience =>
        audience.created_at ? Moment.unix(audience.created_at).format('LLL') : 'N/A',
    },
    Tools: {
      render: audience => (
        <AudienceTools
          onEdit={editAudience}
          onView={viewAudience}
          onDelete={deleteAudience}
          onSetDefault={onSetDefault}
          audience={audience}
          dotPattern="h"
        />
      ),
    },
  }

  return (
    <DataTable<SavedAudience>
      recordsList={audiences}
      tableSchema={audienceTableSchema}
      hideSearch
      hideColumnFilter
      selectable={{
        setSelectedRows: onCheck,
        selectedRows: checkedAudiences,
      }}
    />
  )
}
