import React, { useContext } from 'react'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { sessionContext } from '@marketing-milk/frontend'
import { dynamicContext } from '../../context/DynamicContext'
import InputAdornment from '@material-ui/core/InputAdornment'
import { creativeContext } from '../../../../context/CreativeContext'

export function DynamicPrimaryText() {
  const { user } = useContext(sessionContext)
  const { creative } = useContext(creativeContext)
  const canEdit = !creative!.locked
  const { primaryTexts, addPrimaryText, delPrimaryText, setPrimaryText } =
    useContext(dynamicContext)
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="w-100 d-flex">
            <Typography variant="h5" className="pt-2" data-testid="status-tab">
              Status
            </Typography>
            <Button
              className="ml-auto mr-0"
              color="secondary"
              variant="contained"
              disabled={primaryTexts.length === 5}
              onClick={addPrimaryText}
            >
              <AddIcon className="mr-2" /> Add
            </Button>
          </div>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {primaryTexts.map((primaryText, i) => {
            const variation = i + 1
            const isDuplicate = primaryTexts.filter(x => x === primaryText).length > 1
            return (
              <div className="mb-3" key={i}>
                <TextField
                  error={isDuplicate || !primaryText}
                  helperText={
                    (isDuplicate || !primaryText) && 'Field must be unique and cannot be empty'
                  }
                  id={`status-text-${variation}`}
                  label={`Status ${variation}`}
                  fullWidth
                  multiline
                  rows={2}
                  placeholder="Enter a status..."
                  value={primaryText}
                  onChange={e => setPrimaryText(i, e.target.value)}
                  disabled={!canEdit}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          data-testid={`delete-icon-${variation}`}
                          edge="end"
                          aria-label="delete"
                          onClick={() => delPrimaryText(i)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
