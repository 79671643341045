import { ExpansionPanel, TableSchema, DataTableProps } from '../types'
import { any, isEqual } from 'lodash/fp'

import { without } from 'app/util'

export const getDisplayedColumns = <T extends Record<string, any>>(tableSchema: TableSchema<T>) =>
  Object.entries(tableSchema)
    .filter(([_, { hidden }]) => !hidden)
    .map(([columnName]) => columnName)

const selectRow =
  <T>(selection: T) =>
  (selections: T[]) => {
    if (any(isEqual(selection), selections)) return without(selection, selections)
    return [...selections, selection]
  }

type Params<T extends Record<string, any>> = {
  recordsList: T[]
  tableSchema: TableSchema<T>
  expansionPanel?: ExpansionPanel<T>
  selectable: DataTableProps<T>['selectable']
  isSelected?: (record: T) => boolean
}
export const generateTableData = <T extends Record<string, any>>({
  recordsList,
  tableSchema,
  expansionPanel,
  isSelected,
  selectable,
}: Params<T>) =>
  recordsList.map(record => ({
    isSelected: isSelected ? isSelected(record) : false,
    expansionPanel: expansionPanel?.render(record),
    shouldExpand: expansionPanel?.shouldExpand(record) || false,
    onRowSelect:
      selectable && selectable.setSelectedRows
        ? () => selectable.setSelectedRows && selectable.setSelectedRows(selectRow(record))
        : undefined,
    checked: any(isEqual(record), selectable?.selectedRows || []),
    rowData: Object.values(tableSchema)
      .filter(({ hidden }) => !hidden)
      .map(({ render }) => render(record)),
  }))
