import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { UserLayout } from 'components/layout/UserLayout'
import { GoogleMap, ShareLink } from '@marketing-milk/frontend'
import { FullPageHeader } from 'components/layout/FullPageLayout/Header/FullPageHeader'
import { FullPageContent } from 'components/layout/FullPageLayout/Content/FullPageContent'
import { useLocalCompetitionFinder, CompetitionFinderMapTools, CompetitionList } from './'

export function LocalCompetition() {
  const finder = useLocalCompetitionFinder(false)
  const matches = useMediaQuery('(max-width:900px)')
  return (
    <UserLayout>
      <div className="flex flex-col w-full h-full max-h-full justify-between items-stretch">
        <FullPageHeader breadcrumbs={[{ title: 'Local Competition' }]}>
          <ShareLink onlyIcon={false} url={finder.queryStringManager.stringifiedURL} />
        </FullPageHeader>
        <FullPageContent>
          {finder.hasBusinessData && !matches && <CompetitionList {...finder} />}
          <div className="w-full h-full overflow-y-scroll">
            <div className="w-full h-full relative">
              <GoogleMap
                center={finder.businessCoordinates}
                zoom={12}
                mapContainerStyle={{ width: '100%', height: '100%', minHeight: 400 }}
              >
                <CompetitionFinderMapTools {...finder} />
              </GoogleMap>
            </div>
          </div>
        </FullPageContent>
      </div>
    </UserLayout>
  )
}
