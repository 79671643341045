import React from 'react'
import { capitalCase } from 'change-case'
import { usePlatformPlacementStyles } from './styles'
import { PublisherPlatformsMode } from './publisher-platforms.types'
import { PublisherPlatform, publisherPlatforms } from '@marketing-milk/interfaces'
import {
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Container,
} from '@material-ui/core'

type PublisherPlatformsProps = {
  handleModeChange: (mode: PublisherPlatformsMode) => void
  handlePlatformSelection: (platform: PublisherPlatform) => void
  selectedPlatforms?: PublisherPlatform[]
}

export const PublisherPlatforms = ({
  handleModeChange,
  handlePlatformSelection,
  selectedPlatforms,
}: PublisherPlatformsProps) => {
  const classes = usePlatformPlacementStyles()

  const _handlePlatformSelection = (e: React.ChangeEvent<HTMLInputElement>, checked: Boolean) => {
    // Return early if user is trying to unselect the last checkbox. At least one must be selected.
    if (selectedPlatforms?.length === 1 && selectedPlatforms[0] === e.target.value) return
    handlePlatformSelection(e.target.value as PublisherPlatform)
  }

  return (
    <div className={classes.platformPlacements} data-testid="publisher-platforms">
      <div className={'col ' + classes.placementOptions}>
        <FormControl component="fieldset">
          <RadioGroup
            name="placements"
            value={selectedPlatforms ? 'manual' : 'automatic'}
            onChange={(_, value) => handleModeChange(value as PublisherPlatformsMode)}
          >
            <FormControlLabel
              value="automatic"
              control={<Radio color="primary" />}
              label="Automatically Assign Platforms (Recommended)"
            />
            <p className="ml-2">
              Maximize your budget and help show your ads to more people, our system will allocate
              your ad set's budget across multiple platforms based on where they're likely to
              perform best.
            </p>
            <FormControlLabel
              value="manual"
              control={<Radio color="primary" />}
              label="Manually Assign Platforms"
            />
            <p className="ml-2 ">
              Manually choose the platforms to show your ad. The more platforms you select, the more
              oppurtunities you'll have to reach your target audience and achieve your business
              goals
            </p>
          </RadioGroup>
        </FormControl>
      </div>
      {selectedPlatforms && (
        <Container hidden={!selectedPlatforms} className={classes.possiblePlatforms}>
          <h5>Choose Platforms</h5>
          <div className={classes.platformCheckboxes}>
            {publisherPlatforms.map(p => {
              const prettyName = capitalCase(p)
              return (
                <FormControlLabel
                  key={p}
                  htmlFor={p}
                  label={prettyName}
                  role="checkbox"
                  control={
                    <Checkbox
                      value={p}
                      checked={selectedPlatforms.includes(p)}
                      inputProps={{ 'aria-checked': selectedPlatforms.includes(p) }}
                      onChange={_handlePlatformSelection}
                    />
                  }
                />
              )
            })}
          </div>
        </Container>
      )}
    </div>
  )
}
