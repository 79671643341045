import { toast } from 'react-toastify'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import SaveIcon from '@material-ui/icons/Save'
import React, { useContext, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import { leadCardContext } from '../context/LeadCardContext'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { LeadCardCreateDTO } from '@marketing-milk/interfaces'
import DialogContentText from '@material-ui/core/DialogContentText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ServerError } from 'components/server-request/server-error/ServerError'

export interface SaveChangesProps {
  onSave?(leadCardDTO: LeadCardCreateDTO): Promise<void>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      '&:hover': {
        backgroundColor: '#5da99c',
        borderColor: '#5da99c',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#5da99c',
        borderColor: '#5da99c',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
  })
)

export function SaveChanges({ onSave }: SaveChangesProps) {
  const classes = useStyles()
  const [error, setError] = useState<Error>()
  const leadCard = useContext(leadCardContext)
  const [isOpen, setModal] = useState(false)
  const [isLoading, setLoading] = useState(false)

  function toggle() {
    if (!onSave) return
    setModal(_ => !_)
  }

  async function onSubmit() {
    if (!onSave) return
    try {
      setLoading(true)
      await onSave(leadCard)
      toast.success('Your changes have been saved')
    } catch (e: any) {
      setError(e)
      setModal(false)
    } finally {
      setLoading(false)
    }
  }

  if (!onSave) {
    return null
  }

  const [buttonIcon, buttonText] = isLoading
    ? [<i className="fa fa-spinner fa-spin mr-2" />, 'Saving Lead Card']
    : [<></>, 'Save Lead Card']

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        className={classes.button}
        color="secondary"
        type="button"
        disabled={!leadCard.canSaveLeadCard()}
        onClick={toggle}
      >
        <SaveIcon fontSize="small" className="mr-2" /> Save Lead Card
      </Button>
      <Dialog data-testid="save-modal" open={isOpen} onClose={toggle}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you save your lead card, you won't be able to make changes to it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="save-modal-close-button" onClick={toggle}>
            Cancel
          </Button>
          <Button
            data-testid="save-modal-save-button"
            className={classes.button}
            disabled={isLoading}
            type="button"
            onClick={onSubmit}
          >
            {buttonIcon}
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
      {error && <ServerError error={error} onClose={() => setError(undefined)} />}
    </>
  )
}
