import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Paper } from '@material-ui/core'

export interface MultiChoiceQuestionProps {
  label: string
  options?: Array<{ key: string; value: string }>
}

export function MultiChoiceQuestion({ label, options = [] }: MultiChoiceQuestionProps) {
  const [selectedOption, setSelectedOption] = useState<number>()

  return (
    <Paper className="p-3 mb-3">
      <div className="">
        <Typography variant="h6" className="text-bold mb-2">
          {label}
        </Typography>
        {options.map((option, i) => (
          <Button
            className={`btn btn-block font-weight-bold py-3 ${
              selectedOption === i ? 'btn-dark text-white' : 'btn-default text-dark'
            }`}
            style={{ borderRadius: 50 }}
            key={`multiple_choice_${i}`}
            onClick={() => setSelectedOption(i)}
          >
            {option.value}
          </Button>
        ))}
      </div>
    </Paper>
  )
}
