import { useQuery } from 'react-query'
import { campaignService } from 'app/services/campaign-builder/campaign'
import { halfHourInMs } from '../../constants'

// Disable all refetching behavior. Always pull from cache, since this data
// changes so rarely. Will only get fresh data on a page refresh.
const queryOpts = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: halfHourInMs,
}

export function useCustomConversions(businessID: number) {
  return useQuery(
    ['customConversions', businessID],
    () => campaignService.getCustomConversions(businessID),
    queryOpts
  )
}
