import React from 'react'
import { SizeEstimate } from './SizeEstimate'
import Typography from '@mui/material/Typography'
import { FacebookTargetingSpec } from '@marketing-milk/interfaces'

export interface StepHeaderProps {
  title: string
  description?: string
  imgSrc?: string
  targetingSpec: FacebookTargetingSpec
}

export function StepHeader({ title, imgSrc, description, targetingSpec }: StepHeaderProps) {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-6">
        <div className="col-span-1 lg:col-span-2">
          <div className="d-flex w-100">
            {imgSrc && (
              <img
                className="img-fluid d-block pr-3"
                src={imgSrc}
                alt="step logo"
                style={{ maxWidth: '75px' }}
              />
            )}
            <div className="center-vertical">
              <Typography variant="h5" className="mb-2">
                {title}
              </Typography>
              <Typography variant="body1" className="">
                {description}
              </Typography>
            </div>
          </div>
        </div>
        <div>
          <div className="w-100">
            <SizeEstimate targetingSpec={targetingSpec} />
          </div>
        </div>
      </div>
      <hr className="my-4" />
    </>
  )
}
