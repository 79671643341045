import Burger from 'components/layout/nav/MobileHeader/Burger'
import React from 'react'
import { Breadcrumbs } from './Breadcrumbs'

type BreadCrumb = {
  title: string
  route?: string
}

interface Props {
  breadcrumbs: BreadCrumb[]
  sidebarOpen?: boolean
  setSidebarOpen?: SetState<boolean>
  className?: string
  children?: React.ReactNode
}

export const FullPageHeader = React.memo(
  ({ children, breadcrumbs, className, ...props }: Props) => {
    return (
      <header
        className={`w-full pl-3 flex flex-grow-0 sm:py-3 bg-white space-x-2 items-center border-b ${className}`}
        style={{ height: 60 }}
      >
        {props.setSidebarOpen && (
          <Burger
            dark
            isOpen={props.sidebarOpen || false}
            setIsOpen={props.setSidebarOpen ? props.setSidebarOpen : () => {}}
          />
        )}
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {children}
      </header>
    )
  }
)
