import React from 'react'
import { FormLayout } from './FormLayout'
import { FormBullets } from './FormBullets'
import { FormHeadline } from './FormHeadline'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import { FormParagraph } from './FormParagraph'
import { DeleteGreeting } from './DeleteGreeting'
import Typography from '@material-ui/core/Typography'
import { FacebookLeadgenBodyStyle, LeadCardIntro } from '@marketing-milk/interfaces'

export interface FormGreetingProps {
  editable: boolean
  greeting: LeadCardIntro['greeting']
  onAddGreeting(): void
  onDelete(): void
  onChangeHeadline(headline: string): void
  onChangeBodyStyle(bodyStyle: FacebookLeadgenBodyStyle): void
  onChangeParagraph(paragraph: string): void
  onChangeBullet(index: number, bullet: string): void
}

export function FormGreeting({
  editable,
  greeting,
  onChangeBodyStyle,
  onChangeParagraph,
  onChangeBullet,
  onChangeHeadline,
  onDelete,
  onAddGreeting,
}: FormGreetingProps) {
  return (
    <div data-testid="form-greeting-step">
      <Typography variant="h5" className="mb-2">
        Greeting
      </Typography>
      <Typography variant="body1" className="mb-3">
        Let people know why they should complete your form and any relevant details.
      </Typography>
      <hr className="my-3" />
      {greeting ? (
        <>
          <FormHeadline
            editable={editable}
            greeting={greeting}
            onChangeHeadline={onChangeHeadline}
          />
          <FormLayout
            editable={editable}
            bodyStyle={greeting.bodyStyle}
            onChangeBodyStyle={onChangeBodyStyle}
          />
          {greeting?.bodyStyle === 'PARAGRAPH_STYLE' ? (
            <FormParagraph
              editable={editable}
              greeting={greeting}
              onChangeParagraph={onChangeParagraph}
            />
          ) : (
            <FormBullets editable={editable} greeting={greeting} onChangeBullet={onChangeBullet} />
          )}
          {editable && <DeleteGreeting editable={editable} onDelete={onDelete} />}
        </>
      ) : (
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          disabled={!editable}
          type="button"
          onClick={onAddGreeting}
        >
          <AddIcon className="mr-2" />
          Add Greeting
        </Button>
      )}
    </div>
  )
}
