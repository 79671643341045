import React, { useState } from 'react'
import { SavedAudience, SavedAudienceType } from '@marketing-milk/interfaces'
import { ToolsDropdownProps, ToolMenu } from '@marketing-milk/frontend'
import { AddCustomerDataModal } from '../../audience-builder/hooks/steps/customer-data/AddCustomerDataModal'
import { toast } from 'react-toastify'

export interface AudienceToolsProps extends Pick<ToolsDropdownProps, 'dotPattern'> {
  audience: SavedAudience
  onSetDefault?: (audience: SavedAudience, isDefault: boolean) => void
  onDelete?: (audience: SavedAudience) => void
  onView?: (audience: SavedAudience) => void
  onEdit?: (audience: SavedAudience) => void
}

export function AudienceTools(props: AudienceToolsProps) {
  const { audience, dotPattern, onSetDefault, onDelete, onView, onEdit } = props

  const [customerDataModalOpen, setCustomerDataModalOpen] = useState(false)

  const tools: ToolsDropdownProps['tools'] = [
    {
      text: <span className={!onView ? 'text-inactive' : ''}>View</span>,
      icon: `eye ${!onView && 'text-inactive'}`,
      action: () => (onView ? onView(audience) : {}),
      type: 'link',
    },
    {
      text: (
        <span className={`${(!audience.createdWithMM || !onEdit) && 'text-inactive'}`}>Edit</span>
      ),
      icon: `pencil ${(!audience.createdWithMM || !onEdit) && 'text-inactive'}`,
      action: () => (audience.createdWithMM && onEdit ? onEdit(audience) : {}),
      type: 'link',
    },
    {
      text: (
        <span className={!onSetDefault ? 'text-inactive' : ''}>
          {audience.isDefault ? 'Remove Default' : 'Set Default'}
        </span>
      ),
      icon: `star ${audience.isDefault ? 'text-danger' : 'text-brand'} ${
        !onSetDefault && 'text-inactive'
      }`,
      action: () => (onSetDefault ? onSetDefault(audience, !audience.isDefault) : {}),
      type: 'link',
    },
    {
      text: <span className={!onDelete ? 'text-inactive' : ''}>Delete</span>,
      icon: `trash-alt ${!onDelete && 'text-inactive'}`,
      action: () => (onDelete ? onDelete(audience) : {}),
      type: 'link',
    },
  ]

  if (audience.type === SavedAudienceType.CustomerFile) {
    tools.push({
      text: <span>Add Customer Data</span>,
      icon: 'eye',
      action: () => setCustomerDataModalOpen(true),
      type: 'link',
    })
  }

  if (!onView && !onEdit && !onDelete && !onSetDefault) {
    return null
  }

  return (
    <>
      <ToolMenu dotPattern={dotPattern} tools={tools} />
      <AddCustomerDataModal
        businessID={audience.businessID}
        audienceID={audience.id}
        isOpen={customerDataModalOpen}
        targetSpec={audience.targeting}
        onClose={toastData => {
          setCustomerDataModalOpen(false)
          if (toastData) {
            toast[toastData.type](toastData.msg)
          }
        }}
      />
    </>
  )
}
