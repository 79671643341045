import {
  AdCreative,
  AdCreativeType,
  CampaignGoalType,
  PublishCampaignDTO,
  SprintDTO,
} from '@marketing-milk/interfaces'
import {
  addDays,
  differenceInDays,
  format,
  formatDistance,
  fromUnixTime,
  getUnixTime,
  isAfter,
  isBefore,
} from 'date-fns'
import { add } from 'lodash'
import { any, equals, flattenDeep, toPairs } from 'lodash/fp'
import { rejectNull, rejectUndefined } from '../../../app/util'
import { isValidCreative } from '../campaigns/helpers/campaign.helpers'
import { getAdPairs } from '../campaigns/helpers/campaignForm.helpers'
import { SprintFormData } from './components/create-sprint-form/CreateSprintForm'
import { zonedTimeToUtc } from 'date-fns-tz'

type CreativeScaffold = {
  name: string
  type?: AdCreativeType
}

export const makeSprintCampaignConfig = (
  campaignIndex: number,
  goal: CampaignGoalType | undefined,
  sprintDto: SprintDTO,
  campaignAmount: number
) => ({
  name: `${sprintDto.name} | Campaign ${campaignIndex + 1}`,
  budget: Number((sprintDto.budget / campaignAmount).toFixed(2)),
  startTime: sprintDto.startTime,
  endTime: sprintDto.endTime,
  frequency: 3,
  // This is bad typing, but there isn't a way around it without having to add
  // a lot of un neccessary negative conditionals and useEffects
  goal: goal || ('' as CampaignGoalType),
})

export const getSprintCampaignDefaults = (
  sprintFormData: SprintFormData,
  goal: CampaignGoalType,
  index: number
) => ({
  name: `${index}. ${sprintFormData.name}`,
  budget: 50,
  startTime: sprintFormData.startTime,
  endTime: sprintFormData.endTime,
  frequency: 3,
  goal,
})

export const creativeNameValidator = ({ name, type }: CreativeScaffold, allNames: string[]) => {
  if (!creativeIsUnique(name, allNames)) return true
  if (!creativeNameIsDetailed(name)) return true
  if (!creativeHasType(type)) return true
  if (equals(name, '')) return true
  return false
}

export const creativeHasType = (creativeType?: AdCreativeType) => {
  if (!creativeType) return false
  return true
}

export const creativeNameErrorMessage = ({ name, type }: CreativeScaffold, allNames: string[]) => {
  if (!creativeIsUnique(name, allNames)) return 'Creative Names must be unique'
  if (!creativeNameIsDetailed(name)) return 'Creative names must contain more detail'
  if (!creativeHasType(type)) return 'Creative must have a type'
  if (equals(name, '')) return 'Creative must have a name'
  return false
}

export const creativeIsUnique = (creativeName: string, allNames: string[]) =>
  !allNames.includes(creativeName)

export const creativeNameIsDetailed = (creativeName: string) =>
  !creativeName.match(/^\s*Creative \d\s*$/i) || equals(creativeName.toLowerCase(), 'creative')

export const getUniqueIndex = (names: string[]) => {
  const numbers = names
    .map(name =>
      name
        .split(' ')
        .map(str => Number(str))
        .find(n => !Number.isNaN(n))
    )
    .filter(i => typeof i !== 'undefined') as number[]

  if (!numbers.includes(names.length + 1)) return names.length + 1
  const highestIndex = Math.max(...numbers)
  return highestIndex + 1
}

export const campaignConfigsVaidator = (
  sprintDto: SprintDTO,
  campaigns: PublishCampaignDTO['campaign'][]
) => {
  if (budgetExceedsSprint(sprintDto.budget, campaigns)) return true
  if (hasUnusedBudget(sprintDto.budget, campaigns)) return true
  return false
}

export const campaignConfigsErrorMessage = (
  sprintDto: SprintDTO,
  campaigns: PublishCampaignDTO['campaign'][]
) => {
  if (budgetExceedsSprint(sprintDto.budget, campaigns))
    return `Total campaign budgets exceeds the given sprint budget by $${Math.abs(
      getUnusedBudget(sprintDto.budget, campaigns)
    )}`

  if (getUnusedBudget(sprintDto.budget, campaigns))
    return `This sprint has unused budget!  There are $${getUnusedBudget(
      sprintDto.budget,
      campaigns
    )} unallocated.`
  return false
}

const budgetExceedsSprint = (sprintBudget: number, campaigns: PublishCampaignDTO['campaign'][]) => {
  const totalCampaignBudget = campaigns.map(({ budget }) => budget).reduce(add)
  return sprintBudget < totalCampaignBudget
}

const hasUnusedBudget = (sprintBudget: number, campaigns: PublishCampaignDTO['campaign'][]) => {
  const unusedBudget = getUnusedBudget(sprintBudget, campaigns)
  // if our decimal precision value is off by less than a dollar or no budget is unused, return false
  if (Math.abs(unusedBudget) < 1 || unusedBudget === 0) return false
  return true
}

const getUnusedBudget = (sprintBudget: number, campaigns: PublishCampaignDTO['campaign'][]) => {
  const totalCampaignBudget = campaigns.map(({ budget }) => budget).reduce(add)
  return Number((sprintBudget - totalCampaignBudget).toFixed(2))
}

export const getMonthlyBudgetPerCampaign = (sprintDTO: SprintDTO, amountOfCampaigns: number) => {
  const numOfMonths =
    differenceInDays(fromUnixTime(sprintDTO.endTime), fromUnixTime(sprintDTO.startTime)) / 30
  return amountOfCampaigns && numOfMonths >= 1
    ? (sprintDTO.budget / amountOfCampaigns / numOfMonths).toFixed(2)
    : 0
}

export const creativeScaffoldErrorMessage = (creatives: CreativeScaffold[]) => {
  if (onlyOneDynamicCreative(creatives))
    return "Campaigns with dynamic creatives can't have multiple creatives assigned to them. Please remove the non dynamic creative."
  if (hasTwoCreatives(creatives)) return 'must have an A and a B creative'
  return null
}

export const creativeScaffoldValidator = (creatives: CreativeScaffold[]) => {
  if (!onlyOneDynamicCreative(creatives)) return false
  if (!hasTwoCreatives(creatives)) return false
  return true
}

const hasTwoCreatives = (creatives: CreativeScaffold[]) => creatives.length === 2

const onlyOneDynamicCreative = (creatives: CreativeScaffold[]) => {
  const hasDynamic = creatives.map(({ type }) => type).includes(AdCreativeType.dynamic)
  return hasDynamic && creatives.length > 1
}

export const indexedEntries = <T>(dictionary: Record<number, T>): [number, T][] =>
  toPairs(dictionary).map(([index, item]) => [Number(index), item])

export const getDefaultSprintDto = (timezone = 'America/Chicago'): SprintDTO => {
  return {
    budget: 1000,
    startTime: getUnixTime(zonedTimeToUtc(new Date().setHours(12, 0, 0, 0), timezone)),
    endTime: getUnixTime(addDays(zonedTimeToUtc(new Date().setHours(12, 0, 0, 0), timezone), 90)),
    name: '',
  }
}

export const getStartEndTimeText = <T extends { startTime: number; endTime: number }>(
  sprint: T
) => {
  const startDate = fromUnixTime(sprint.startTime)
  const endDate = fromUnixTime(sprint.endTime)
  if (isBefore(new Date(), startDate)) return `Starts in ${formatDistance(new Date(), startDate)}`
  if (isAfter(new Date(), endDate)) return `Ended ${formatDistance(new Date(), endDate)}`
  if (isBefore(new Date(), endDate) && isAfter(new Date(), startDate))
    `Started ${formatDistance(new Date(), startDate)}`
  return `Starts ${format(fromUnixTime(sprint.startTime), 'PPPP')}`
}

export const isMissingAudiences = (dto: PublishCampaignDTO) => dto.audienceIDs.length === 0
export const hasIncompleteCreatives = (dto: PublishCampaignDTO, creatives?: AdCreative[]) => {
  if (!creatives) return true
  if (!any(isValidCreative, flattenDeep(getAdPairs(dto.creativeGroups, creatives)))) return true
  return false
}
export const hasNoCreatives = (dto: PublishCampaignDTO) => {
  const [firstGroup] = dto.creativeGroups
  if (!firstGroup) return true
  if (rejectNull(firstGroup).length === 0) return true
  return false
}
