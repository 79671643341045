import { useGetBusiness } from '@marketing-milk/frontend'
import { PublishCampaignDTO, SprintDTO } from '@marketing-milk/interfaces'
import { Button, TextField } from '@material-ui/core'
import { Add, Close } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { removeAtIndex } from 'app/util'
import { fromUnixTime, getUnixTime } from 'date-fns'
import { useCampaignGoals } from 'hooks/campaigns/use-campaign-goals-by-business'
import { set } from 'lodash/fp'
import {
  campaignConfigsErrorMessage,
  campaignConfigsVaidator,
  makeSprintCampaignConfig,
} from 'pages/business/sprints/sprint.helpers'
import { CondensedCampaignBudgetField } from '../../sprint-campaigns-tab/Fields/CondensedCampaignBudgetField'
import { CondensedCampaignDurationField } from '../../sprint-campaigns-tab/Fields/CondensedCampaignDurationField'
import { SelectCampaignGoalField } from '../../sprint-campaigns-tab/Fields/SelectCampaignGoalField'
import { utcToZonedTime } from 'date-fns-tz'
import { combineTime } from '../../../../campaigns/helpers/campaignForm.helpers'

interface Props {
  sprintDto: SprintDTO
  campaignConfigs: PublishCampaignDTO['campaign'][]
  setCampaignConfigs: SetState<PublishCampaignDTO['campaign'][]>
}
export const SprintSetupCampaignsStep = ({
  setCampaignConfigs,
  sprintDto,
  campaignConfigs,
}: Props) => {
  const business = useGetBusiness()
  const { enabledGoals } = useCampaignGoals(Number(business.id))

  const addCampaign = () => {
    setCampaignConfigs(prev => {
      const newConfig = makeSprintCampaignConfig(
        prev.length + 1,
        enabledGoals[0],
        sprintDto,
        prev.length + 1
      )
      return [...prev, newConfig].map(setNewBudget(prev.length + 1, sprintDto.budget))
    })
  }

  const removeCampaign = (index: number) => {
    setCampaignConfigs(configs =>
      removeAtIndex(index)(configs).map(setNewBudget(configs.length - 1, sprintDto.budget))
    )
  }

  const setCampaignValue =
    <T extends keyof PublishCampaignDTO['campaign']>(index: number, property: T) =>
    (value: PublishCampaignDTO['campaign'][T]) =>
      setCampaignConfigs(set([index, property], value))

  return (
    <div className="w-full flex flex-col max-w-7xl mx-auto p-4 space-y-4">
      <h1 className="py-2 font-lg font-bold mx-auto">Setup Campaigns</h1>
      <p className="font-medium mx-auto max-w-2xl font-md text-gray-600">
        Scaffold out the campaigns that will be added to this Sprint. You will be able to add
        additional information after this form has been completed.
      </p>

      <div className="max-w-fit mx-auto mb-8">
        <Alert severity="info" variant="standard" className="flex items-center">
          After creating the sprint, you will be able assign your creatives and audiences
        </Alert>
      </div>

      {campaignConfigsVaidator(sprintDto, campaignConfigs) && (
        <div className="w-full px-4 mx-auto">
          <Alert severity="error" variant="standard" className="flex items-center">
            {campaignConfigsErrorMessage(sprintDto, campaignConfigs)}
          </Alert>
        </div>
      )}

      <div className="flex flex-col space-y-4 px-4 w-full pb-1">
        {campaignConfigs.map((campaign, i) => (
          <div data-testid={`campaign-${i}`}>
            <div className="flex py-2" key={campaign.name + i}>
              <div className="flex-grow">
                <TextField
                  fullWidth
                  variant="outlined"
                  name={`campaign-${i + 1}-name`}
                  defaultValue={campaign.name}
                  autoFocus
                  label={`Campaign ${i + 1} Name`}
                  onChange={({ target }) => {
                    setCampaignValue(i, 'name')(target.value)
                  }}
                  required={true}
                />
              </div>

              <CondensedCampaignBudgetField
                budget={campaign.budget}
                setBudget={setCampaignValue(i, 'budget')}
                campaignIndex={i}
              />

              <CondensedCampaignDurationField
                duration={[
                  utcToZonedTime(fromUnixTime(campaign.startTime), business.timezone),
                  utcToZonedTime(fromUnixTime(campaign.endTime), business.timezone),
                ]}
                setStartDate={start => {
                  if (!start) return
                  setCampaignValue(
                    i,
                    'startTime'
                  )(getUnixTime(combineTime(start, start, business.timezone)))
                }}
                setEndDate={end => {
                  if (!end) return
                  setCampaignValue(
                    i,
                    'endTime'
                  )(getUnixTime(combineTime(end, end, business.timezone)))
                }}
              />

              <div className="min-w-mix">
                <SelectCampaignGoalField
                  campaignIndex={i}
                  goal={campaign.goal}
                  setGoal={goal => setCampaignConfigs(set([i, 'goal'], goal))}
                />
              </div>

              <div className="ml-2 flex items-center justify-center">
                <button
                  className="border border-red-900 rounded-full p-2 text-red-700 hover:bg-red-100"
                  onClick={() => removeCampaign(i)}
                >
                  <Close />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="w-full px-4 pb-4 border-b">
        <Button variant="outlined" className="w-full p-3" onClick={addCampaign}>
          <Add />
          <p className="ml-2">Add Campaign To Sprint</p>
        </Button>
      </div>
    </div>
  )
}

const setNewBudget =
  (amountOfCampaigns: number, sprintBudget: number) => (config: PublishCampaignDTO['campaign']) =>
    set(['budget'], Math.floor(sprintBudget / amountOfCampaigns), config)
