import LogoFull from 'public/img/logos/mm-droplet-text.png'
import { Burger } from './Burger'

interface MobileHeaderProps {
  setNavOpen: SetState<boolean>
  navOpen: boolean
}

export const MobileHeader = (props: MobileHeaderProps) => {
  return (
    <div style={{ height: 60, maxHeight: 60 }}>
      <div
        style={{
          color: '#a044ff',
          background: ' #1a1a27',
        }}
        className="h-20 sm:hidden flex justify-between"
      >
        <a href="/home" className="h-full">
          <img alt="Logo" src={LogoFull} className="p-2" style={{ height: 'inherit' }} />
        </a>

        <button className="flex justify-center items-center pr-2">
          <Burger isOpen={props.navOpen} setIsOpen={props.setNavOpen} className="" />
        </button>
      </div>
    </div>
  )
}
