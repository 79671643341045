import { useGetBusinessID } from '@marketing-milk/frontend'
import { AdCreative, Campaign, DraftCampaign, Sprint } from '@marketing-milk/interfaces'
import { Add } from '@material-ui/icons'
import { Upload } from '@mui/icons-material'
import { Button } from '@mui/material'
import { MMAlert } from 'components/Alert'
import { BlankPageLayout } from 'components/BlankPageLayout'
import { ConfirmationModal } from 'components/ConfirmationModal'
import { addMonths, endOfDay, getUnixTime } from 'date-fns'
import { useDraftCampaign } from 'hooks/api/useDraftCampaign'
import { useSprint } from 'hooks/api/useSprint'
import { useFetchAllAudiences } from 'hooks/audiences/useFetchAllAudiences'
import { useCampaignGoals } from 'hooks/campaigns/use-campaign-goals-by-business'
import { any } from 'lodash/fp'
import { ErrorToolTip } from 'pages/business/campaigns/Campaigns'
import {
  getCampaignErrorMessage,
  isValidCreative,
} from 'pages/business/campaigns/helpers/campaign.helpers'
import {
  getDefaultDTO,
  isValidCampaignDto,
} from 'pages/business/campaigns/helpers/campaignForm.helpers'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { DraftCampaignsTable } from './DraftCampaignsTable'
import { EditDraftCampaignModal } from './EditDraftCampaignModal'
import { PublishDraftCampaignsModal } from './PublishDraftCampaigns'

interface SprintsCampaignsTabProps {
  sprint: Sprint
  campaigns?: Campaign[]
  creatives?: AdCreative[]
}

export const SprintDraftCampaigns = (props: SprintsCampaignsTabProps) => {
  const businessID = useGetBusinessID()

  const [addCampaignOpen, setAddCampaignOpen] = useState(false)

  const { createDraftCampaign, updateDraftCampaign } = useDraftCampaign()
  const { draftCampaigns, sprintCreatives, refetchDraftCampagins, refetchPublishedCampaigns } =
    useSprint(props.sprint.id)
  const [publishModalOpen, setPublishModalOpen] = useState(false)
  const [draftCampaignToEdit, setDraftCampaignToEdit] = useState<DraftCampaign>()
  const [editType, setEditType] = useState<'audiences' | 'creatives' | 'settings'>()

  const {
    audiences = [],
    defaultAudiences,
    isLoading: audiencesLoading,
  } = useFetchAllAudiences(businessID)

  const { data: goals, enabledGoals } = useCampaignGoals(Number(businessID))

  const campaignUploadErrorMessages = getCampaignErrorMessage(audiences, goals)

  const getValidDraftCampaigns = () => {
    if (!sprintCreatives) return []
    if (!audiences) return []
    if (!goals) return []
    if (!draftCampaigns) return []
    const campaigns =
      draftCampaigns.filter(({ config }) => isValidCampaignDto(config, sprintCreatives)) || []
    return campaigns
  }

  const canUpload = () => getValidDraftCampaigns().length > 0

  return (
    <BlankPageLayout title="Draft Campaigns">
      <div className="space-y-2 py-2 mx-auto max-w-7xl">
        {sprintCreatives && sprintCreatives.length > 0 && !any(isValidCreative, sprintCreatives) && (
          <MMAlert type="warning">
            <p className="w-full">
              Your sprint has {sprintCreatives.filter(c => !isValidCreative(c)).length} incomplete
              creatives.
            </p>
          </MMAlert>
        )}
        {sprintCreatives && sprintCreatives.length === 0 && (
          <MMAlert type="warning">
            <p className="w-full">This sprint does not have any creatives available to use</p>
          </MMAlert>
        )}
        {!audiencesLoading && audiences.length === 0 && (
          <MMAlert type="warning">
            <p className="w-full">Your business does not have any audiences created</p>
          </MMAlert>
        )}
        {enabledGoals.length === 0 && (
          <MMAlert type="warning">
            <p className="w-full">Your business does not have a goal configured</p>
          </MMAlert>
        )}
      </div>
      <span className="min-w-max space-x-4 flex w-full">
        <ConfirmationModal
          openState={[addCampaignOpen, setAddCampaignOpen]}
          warningText="This could put the sprint over budget"
          confirmButtonText="Add Draft Campaign To Sprint"
          onConfirm={async () => {
            await createDraftCampaign({
              sprintID: props.sprint.id,
              config: getDefaultDTO({
                creativeGroups: [[]],
                audienceIDs: defaultAudiences.map(({ id }) => id),
                campaign: {
                  budget: 150,
                  startTime: getUnixTime(endOfDay(new Date())),
                  endTime: getUnixTime(endOfDay(addMonths(new Date(), 3))),
                  frequency: 3,
                  goal: enabledGoals[0] || '',
                  name: `${props.sprint.name} | Campaign ${(draftCampaigns?.length || 0) + 1}`,
                },
              }),
            })
            refetchDraftCampagins()
            toast.success('Draft Uploaded! This may take a few seconds to reflect in the U.I.')
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={async () => {
            setAddCampaignOpen(true)
          }}
        >
          <Add />
          <p className="ml-2">Add Campaign To Sprint</p>
        </Button>

        <PublishDraftCampaignsModal
          sprintId={props.sprint.id}
          creatives={sprintCreatives}
          audiences={audiences}
          draftCampaigns={getValidDraftCampaigns()}
          isOpen={publishModalOpen}
          onClose={() => setPublishModalOpen(false)}
        />

        <ErrorToolTip
          title={!canUpload() ? 'You must have at least one campaign without errors' : ''}
        >
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setPublishModalOpen(true)}
              disabled={!canUpload()}
            >
              <Upload />
              <p className="ml-2">Select Campaigns To Publish</p>
            </Button>
          </div>
        </ErrorToolTip>
      </span>

      {draftCampaignToEdit && (
        <EditDraftCampaignModal
          audiences={audiences}
          creatives={sprintCreatives}
          isOpen={!!draftCampaignToEdit}
          onClose={() => {
            setDraftCampaignToEdit(undefined)
          }}
          type={editType}
          initialDto={draftCampaignToEdit.config}
          updateDto={async dto => {
            if (!draftCampaignToEdit) return
            await updateDraftCampaign(draftCampaignToEdit.id, {
              sprintID: props.sprint.id,
              config: dto,
            })
            refetchDraftCampagins()
            toast.success('Draft Updated')
          }}
        />
      )}

      <DraftCampaignsTable
        draftCampaigns={draftCampaigns}
        openModal={(type, draft) => {
          setEditType(type)
          setDraftCampaignToEdit(draft)
        }}
        audiences={audiences}
        sprintCreatives={sprintCreatives}
      />
    </BlankPageLayout>
  )
}
