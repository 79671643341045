import './LeadCardPreview.scss'
import React, { useContext } from 'react'
import { BackgroundPreview } from './BackgroundPreview'
import { LeadCardPreviewProps } from './LeadCardPreview.types'
import { SelectSectionPreview, leadCardSections } from './SelectSectionPreview'
import { LeadCardPreviewContextProvider, leadCardPreviewContext } from './context'

function LeadCardPreviewComponent({
  mobilePreview = true,
  selectionPreview = true,
  hideBackground = false,
}: LeadCardPreviewProps) {
  const { section } = useContext(leadCardPreviewContext)
  const leadPreview = (
    <div
      data-testid="lead-card-preview"
      className="lead-card-preview"
      style={{
        width: 322,
        overflow: 'auto',
        background: '#EBEDF0',
        position: 'relative',
        height: 600,
      }}
    >
      {!hideBackground && <BackgroundPreview />}
      <div style={{ width: 280, margin: '0 auto' }}>{leadCardSections[section].view}</div>
    </div>
  )

  return (
    <div style={{ width: 322 }}>
      {mobilePreview && (
        <div data-testid="mobile-lead-card-preview" className="ad-preview">
          <div className="phone-container mx-auto">
            <div className="phone" style={{ background: '#EBEDF0' }}>
              <div className="phone-children">
                {leadPreview}
                {selectionPreview && <SelectSectionPreview />}
              </div>
            </div>
          </div>
        </div>
      )}
      {!mobilePreview && leadPreview}
    </div>
  )
}

export function LeadCardPreview(props: LeadCardPreviewProps) {
  return (
    <LeadCardPreviewContextProvider {...props}>
      <LeadCardPreviewComponent {...props} />
    </LeadCardPreviewContextProvider>
  )
}
