import { Button, Dialog } from '@mui/material'
import React from 'react'
import { toast } from 'react-toastify'
import { BlankPageLayout } from './BlankPageLayout'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

interface ConfimationModalProps {
  openState: [boolean, SetState<boolean>]
  warningText?: React.ReactNode // Normally just a string
  onConfirm: () => void
  confirmButtonText?: React.ReactNode // Normally just a string
  children?: React.ReactNode
  confirmButtonType?: 'delete' | 'other'
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  })
)
export const ConfirmationModal = ({
  openState,
  warningText,
  onConfirm,
  confirmButtonText,
  children,
  confirmButtonType = 'other',
}: ConfimationModalProps) => {
  const [isOpen, setIsOpen] = openState
  const classes = useStyles()

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <div>
        <BlankPageLayout title="Are you sure?" subtitle={warningText}>
          {children}
          <div className="w-full flex justify-between pt-8">
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="inherit"
              className={confirmButtonType === 'delete' ? classes.deleteButton : 'bg-red-800'}
              onClick={() => {
                onConfirm()
                toast.warn('This may take a moment to reflect in the U.I.')
                setIsOpen(false)
              }}
            >
              {confirmButtonText || 'Confirm'}
            </Button>
          </div>
        </BlankPageLayout>
      </div>
    </Dialog>
  )
}
