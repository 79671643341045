import { TabPanel } from 'components'
import React, { useContext } from 'react'
import { TabPanelSection } from 'components'
import { FormPrivacy } from './form-privacy/FormPrivacy'
import { FormGreeting } from './form-greeting/FormGreeting'
import { FormSettings } from './form-settings/FormSettings'
import { leadCardContext } from '../context/LeadCardContext'
import { FormQuestions } from './form-questions/FormQuestions'
import { FormCompletion } from './form-completion/FormCompletion'

export function FormSlider() {
  const leadCard = useContext(leadCardContext)

  const formSections: TabPanelSection[] = [
    {
      title: 'Intro',
      section: (
        <FormGreeting
          editable={leadCard.editable}
          greeting={leadCard.intro.greeting}
          onAddGreeting={leadCard.addGreeting}
          onDelete={leadCard.deleteGreeting}
          onChangeBullet={leadCard.updateGreetingBullet}
          onChangeParagraph={leadCard.updateGreetingParagraph}
          onChangeHeadline={leadCard.updateGreetingHeadline}
          onChangeBodyStyle={leadCard.updateGreetingBodyStyle}
        />
      ),
      onSelectSection: () => leadCard.setPreviewSection(0),
    },
    {
      title: 'Questions *',
      section: (
        <FormQuestions
          editable={leadCard.editable}
          updateCustomQuestion={leadCard.updateCustomQuestion}
          updateCustomQuestionKey={leadCard.updateCustomQuestionKey}
          deleteQuestion={leadCard.deleteQuestion}
          updatePrefillQuestionType={leadCard.updatePrefillQuestionType}
          deleteMultiChoiceOption={leadCard.deleteMultiChoiceOption}
          updateMultiChoiceOption={leadCard.updateMultiChoiceOption}
          addMultiChoiceOption={leadCard.addMultiChoiceOption}
          updateMultiChoiceOptionKey={leadCard.updateMultiChoiceOptionKey}
          addPrefillQuestion={leadCard.addPrefillQuestion}
          addShortAnswerQuestion={leadCard.addShortAnswerQuestion}
          addMultiChoiceQuestion={leadCard.addMultiChoiceQuestion}
          getPrefillQuestions={leadCard.getPrefillQuestions}
          getPrefillQuestionIndex={leadCard.getPrefillQuestionIndex}
          getCustomQuestionIndex={leadCard.getCustomQuestionIndex}
          getCustomQuestions={leadCard.getCustomQuestions}
          sortCustomQuestions={leadCard.sortCustomQuestions}
          sortPrefillQuestions={leadCard.sortPrefillQuestions}
          questionDescription={leadCard.questionsDesc}
          updateQuestionDescription={leadCard.updateQuestionDescription}
          getAvailablePrefillQuestions={leadCard.getAvailablePrefillQuestions}
        />
      ),
      onSelectSection: () => leadCard.setPreviewSection(1),
    },
    {
      title: 'Privacy *',
      section: (
        <FormPrivacy
          editable={leadCard.editable}
          privacy={leadCard.privacy}
          onChangeLink={leadCard.updatePrivacyLink}
          onChangeText={leadCard.updatePrivacyText}
          onAddDisclaimer={leadCard.addCustomDisclaimer}
          onDeleteDisclaimer={leadCard.deleteCustomDisclaimer}
          onChangeTitle={leadCard.updateDisclaimerTitle}
          onChangeBody={leadCard.updateDisclaimerBody}
          onAddConsentBlock={leadCard.addConsentBlock}
          onDeleteConsentBlock={leadCard.deleteConsentBlock}
          onChangeConsent={leadCard.updateConsentBlock}
        />
      ),
      onSelectSection: () => leadCard.setPreviewSection(2),
    },
    {
      title: 'Completion *',
      section: (
        <FormCompletion
          editable={leadCard.editable}
          thankYouPage={leadCard.thankYouPage}
          onChangeHeadline={leadCard.updateCompletionHeadline}
          onChangeDescription={leadCard.updateCompletionDescription}
          onChangeCTA={leadCard.updateCompletionCTA}
          onChangeButtonText={leadCard.updateCompletionButtonText}
          onChangeLink={leadCard.updateCompletionLink}
          onChangeBusinessPhone={leadCard.updateBusinessPhone}
          isValidPhoneNumber={leadCard.isValidPhone}
        />
      ),
      onSelectSection: () => leadCard.setPreviewSection(3),
    },
    {
      title: 'Settings',
      section: (
        <FormSettings
          editable={leadCard.editable}
          settings={leadCard.settings}
          onSaveParam={leadCard.upsertTrackingParam}
          onDeleteParam={leadCard.deleteTrackingParam}
          onChangeSharing={leadCard.updateSharing}
          onChangeLocale={leadCard.updateLanguage}
        />
      ),
      onSelectSection: () => leadCard.setPreviewSection(3),
    },
  ]

  return <TabPanel sections={formSections} activeSection={leadCard.previewSection} />
}
