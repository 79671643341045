import { NestAPI, useGetBusinessID } from '@marketing-milk/frontend'
import { useState } from 'react'
import { toast } from 'react-toastify'

export function useFetchCreativePreview(creativeID?: number) {
  const businessID = useGetBusinessID()
  const [loadingPreview, setIsLoadingPreview] = useState(false)
  const [adCreativePreview, setAdCreativePreview] = useState<string | undefined>()

  const retrievePreview = async (creativeIDOverride?: number) => {
    if (creativeID || creativeIDOverride) {
      try {
        setIsLoadingPreview(true)
        const response = await NestAPI.get(
          `businesses/${businessID}/ad-creatives/${creativeIDOverride ?? creativeID}/preview`
        )
        setAdCreativePreview(response.data)
      } catch (e) {
        toast.error('Failed to generate a preview from Facebook for your ad')
        console.error(e)
      }
      setIsLoadingPreview(false)
    }
  }

  return {
    loadingPreview,
    setIsLoadingPreview,
    retrievePreview,
    adCreativePreview,
  }
}
