import React from 'react'
import Select from 'react-select'
import { StepHeader } from '../StepHeader'
import { Slider } from '@marketing-milk/frontend'
import { FacebookTargetingSpec, FBPageName } from '@marketing-milk/interfaces'
import FacebookLogo from 'public/img/logos/facebook-logo.jpg'

export declare type FbMenuSelection =
  | 'page_engaged'
  | 'page_visited'
  | 'page_post_interaction'
  | 'page_cta_clicked'
  | 'page_messaged'
  | 'page_or_post_save'

export interface FacebookPageProps {
  pageRetention: number
  onRetentionUpdate: (retention: number) => void
  selectedCategory: FbMenuSelection
  onSelectCategory: (category: FbMenuSelection) => void
  facebookPageData: FBPageName | undefined
  targetSpec: FacebookTargetingSpec
}

export const pageCategories: Record<FbMenuSelection, string> = {
  page_engaged: 'People who engaged with any post or ad',
  page_visited: 'Anyone who visited your Facebook Page',
  page_post_interaction: 'People who interacted with any post or ad',
  page_cta_clicked: 'People who clicked any call-to-action button',
  page_messaged: 'People who sent a message to your Facebook Page',
  page_or_post_save: 'People who saved your Facebook Page or any post',
}

export function FacebookPage(props: FacebookPageProps) {
  const {
    pageRetention,
    selectedCategory,
    onSelectCategory,
    onRetentionUpdate,
    facebookPageData,
    targetSpec,
  } = props

  const categoryOptions = Object.keys(pageCategories).map(x => ({
    //@ts-ignore
    label: pageCategories[x],
    value: x,
  }))

  const value: any = selectedCategory
    ? categoryOptions.filter((x: any) => x.value === selectedCategory)
    : null

  function selectCategory(option: { label: string; value: FbMenuSelection }) {
    onSelectCategory(option.value)
  }

  function generatePageName() {
    return (
      <div
        className={`${
          facebookPageData ? 'bg-drive text-drive' : 'bg-danger text-danger'
        } text-center faded fit-content p-3 border-radius mx-auto`}
      >
        {facebookPageData
          ? `${facebookPageData.name} - ${facebookPageData.id}`
          : "We couldn't find a page for your business"}
      </div>
    )
  }

  return (
    <>
      <div className="container">
        <StepHeader
          title="Choose your Facebook Page Categories"
          description="Choose the Facebook page categories you would like to use for this audience."
          imgSrc={FacebookLogo}
          targetingSpec={targetSpec}
        />
        <div className="row mt-5">
          <div className="col-12">{generatePageName()}</div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div
              className="card box-shadow border border-radius p-4 d-block mx-auto"
              style={{ maxWidth: '600px' }}
            >
              <p className="text-bold text-center">Use the following category...</p>
              <Select
                placeholder="Choose a group of people"
                options={categoryOptions}
                value={value}
                onChange={selectCategory as any}
              />
              <p className="text-bold text-center mt-4">In the past...</p>
              <Slider
                defaultValue={pageRetention}
                min={1}
                max={365}
                onChangeValue={onRetentionUpdate}
                minLabel="Day"
                maxLabel="Days"
              />
              <h4 className="text-center">{pageRetention} Days</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
