// numberToUSD(5) --> "$5.00"
export const { format: numberToUSD } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

// https://github.com/microsoft/TypeScript/issues/20707
export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export async function promiseAllSeq<T = any>(promises: Array<Promise<T>>) {
  const results = []
  for (const p of promises) results.push(await p)
  return results
}

export type Dictionary<T> = {
  [key: string]: T
}

export function notNullOrUndefined<T>(x: T | null | undefined): x is T {
  // eslint-disable-next-line eqeqeq
  return x != null
}

const keepDecimals = (digits: number) => (num: number) => Number(num.toFixed(2))
export const keepTwoDecimals = keepDecimals(2)
export const keepThreeDecimals = keepDecimals(3)

export const addOrRemove = <T,>(array: T[], item: T) => {
  const exists = array.includes(item)

  if (exists) {
    return array.filter(c => {
      return c !== item
    })
  } else {
    const copy = [...array]
    copy.push(item)
    return copy
  }
}

// Necessary b/c I believe that our CI environment is still on
// an old version of Node, so we can't use Object.fromEntries
export function fromEntries<T = any>(
  entries: Iterable<readonly [PropertyKey, T]>
): { [k: string]: T } {
  // @ts-ignore - Something about --downlevelIteration. Not too worried about it.
  return [...entries].reduce((obj, [key, val]) => {
    obj[key] = val
    return obj
  }, {})
}

export const zip = <T, U>(a: T[], b: U[]): [T, U][] => a.map((e, i) => [e, b[i]])

/*
 * Removes an entry from a numerically indexed object/dictionary
 * and normalizes the object keys so that there are no holes.
 *
 * dict - numerically indexed object, i.e. { 1: 'a', 2: 'b', 3: 'c' }
 * key - the key to remove from the object, i.e. 2
 *
 * Example:
 * shiftDict({ 1: 'a', 2: 'b', 3: 'c' }, 2) === { 1: 'a', 2: 'c' }
 */
export const shiftDict = <T,>(dict: { [i: number]: T }, key: number | string) => {
  const keyStr = key.toString()
  const keyNum = Number(key)

  const keys = Object.keys(dict)
  const values = Object.values(dict)

  const idx = keys.indexOf(keyStr)
  const keysWithHole = [...keys.slice(0, idx), ...keys.slice(idx + 1)]
  const newValues = [...values.slice(0, idx), ...values.slice(idx + 1)]
  const shiftedKeys = keysWithHole.map(key => (Number(key) > keyNum ? `${Number(key) - 1}` : key))
  const zipped = zip(shiftedKeys, newValues)

  return fromEntries(zipped)
}
