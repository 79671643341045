import React, { useContext } from 'react'
import { Paper } from '@material-ui/core'
import { capitalCase } from 'change-case'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import { leadCardPreviewContext } from '../../context'
import Typography from '@material-ui/core/Typography'
import { FacebookLeadgenQuestionType, LeadCardQuestionPredefined } from '@marketing-milk/interfaces'

export function LeadCardPrivacyPolicy() {
  const { business, leadCard } = useContext(leadCardPreviewContext)
  const prefillQuestions = leadCard.questions.filter(
    _ => _.type !== FacebookLeadgenQuestionType.CUSTOM
  ) as LeadCardQuestionPredefined[]
  return (
    <>
      {leadCard.formType === 'higher_intent' && (
        <Paper className="p-3 mb-3">
          <Typography variant="h6" className="text-bold mb-2">
            Review Your Info
          </Typography>
          <Typography variant="body1" className="mb-3">
            Personal Info
          </Typography>
          {prefillQuestions.map(question => (
            <Typography variant="body2" className="mb-2">
              {capitalCase(question.type)}
            </Typography>
          ))}
        </Paper>
      )}
      <Paper className="p-3 mb-3">
        {leadCard.privacy.customDisclaimer && (
          <>
            <Typography variant="h6">{leadCard.privacy.customDisclaimer?.title}</Typography>
            <Typography variant="body1">{leadCard.privacy.customDisclaimer?.body}</Typography>
            {/*TODO: Investigate this checkbox, there arent any UI controls for this.. maybe something Chris forgot to do when building?*/}
            {leadCard.privacy.customDisclaimer?.checkboxes?.map((checkbox, i) => (
              <div className="form-group" key={`checkbox_${i}`}>
                <Checkbox checked={false} inputProps={{ 'aria-label': 'primary checkbox' }} />
                <label>{checkbox.text}</label>
                {!checkbox.isRequired && <span className="text-grey ml-2">(optional)</span>}
              </div>
            ))}
            <hr className="my-2" />
          </>
        )}
        {!leadCard.privacy.customDisclaimer && (
          <Typography variant="h6" className="text-bold mb-2">
            Privacy Policy
          </Typography>
        )}
        <Typography variant="body1">
          By clicking Submit, you agree to send your info to Drive Social Media who agrees to use it
          according to their privacy policy. Facebook will also use it subject to our Data Policy,
          including to auto-fill forms for ads.
          <Link
            color="primary"
            variant="body1"
            href="https://www.facebook.com/about/privacy"
            target="_blank"
            rel="noreferrer"
          >
            View Facebook Data Policy.
          </Link>{' '}
          <Link
            color="primary"
            variant="body1"
            href={leadCard.privacy.link}
            target="_blank"
            rel="noreferrer"
          >
            {leadCard.privacy.text ?? `View ${business.businessName}'s Facebook Page`}
          </Link>
        </Typography>
      </Paper>
      <Button color="secondary" variant="contained" style={{ width: '100%' }}>
        Submit
      </Button>
    </>
  )
}
