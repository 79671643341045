import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { CreativeStatus } from 'components/creative-status/CreativeStatus'
import { FormPageContent, FormPagePreview } from 'components/layout/PreviewForm'
import { adCreativeFormatWire } from 'pages/business/creatives/creative-wizard/util'
import { useContext } from 'react'
import { AdPreview } from './ad-preview/AdPreview'
import { BasicAdPreview } from './ad-preview/AdPreview.type'
import { creativeContext } from './context/CreativeContext'
import { CreativeContextProvider } from './context/CreativeContextProvider'
import './creative-wizard.scss'
import { CreativeWizardProps } from './CreativeWizard.types'
import { getCreativeFormat } from './format-views/get-format'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormLabel-root': {
        fontWeight: 'bold',
      },
    },
    paperContainer: {
      backgroundImage:
        "url('https://www.marketingmilk.com/wp-content/uploads/2021/07/empty-table-bg.jpg')",
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
  })
)

export const creativePreviews: Array<{ format: BasicAdPreview; icon: string }> = [
  { format: 'facebook', icon: 'fab fa-facebook-f' },
  { format: 'messenger', icon: 'fab fa-facebook-messenger' },
  { format: 'marketplace', icon: 'fas fa-store' },
  { format: 'instagram', icon: 'fab fa-instagram' },
]

export function CreativeWizardComponent() {
  const classes = useStyles()
  const { creative, format, preview, setPreview } = useContext(creativeContext)

  return (
    <>
      <div className="sm:space-x-0 sm:flex flex-grow sm:p-4 space-x-4">
        <FormPageContent>
          <div className="p-3">
            <p className="my-2 text-xl font-semibold">General Settings</p>
            {getCreativeFormat(format, creative)}
          </div>
        </FormPageContent>

        <FormPagePreview>
          <div className="flex justify-between">
            <div className="flex items-center">
              <Typography variant="h5" className="d-inline-block">
                {adCreativeFormatWire(format)}
              </Typography>
              {creative && (
                <div className="d-inline-block ml-3">
                  <CreativeStatus creative={creative} />
                </div>
              )}
            </div>
            <ButtonGroup variant="contained" aria-label="creative preview format options">
              {creativePreviews.map((x, i) => (
                <Button
                  color={preview === x.format ? 'secondary' : 'default'}
                  size="large"
                  onClick={() => setPreview(x.format)}
                >
                  <i className={`${x.icon}`} />
                </Button>
              ))}
            </ButtonGroup>
          </div>

          <div className="relative h-full">
            <AdPreview />
          </div>
        </FormPagePreview>
      </div>
    </>
  )
}

export function CreativeWizard({ defaultCreative }: CreativeWizardProps) {
  return (
    <CreativeContextProvider defaultCreative={defaultCreative}>
      <CreativeWizardComponent />
    </CreativeContextProvider>
  )
}
