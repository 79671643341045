import React from 'react'
import { AccountInfo } from './account-info/AccountInfo'
import Select from 'react-select'
import { StepHeader } from '../StepHeader'
import { Slider } from '@marketing-milk/frontend'
import InstagramLogo from 'public/img/logos/instagram-logo.png'
import { FacebookTargetingSpec } from '@marketing-milk/interfaces'

export declare type IgMenuSelection =
  | 'ig_business_profile_all'
  | 'ig_business_profile_visit'
  | 'ig_business_profile_engaged'
  | 'ig_business_profile_user_messaged'
  | 'ig_business_profile_ad_saved'

export interface InstagramAccountProps {
  retention: number
  accountID: string
  onUpdateRetention: (retention: number) => void
  selectedCategory: IgMenuSelection
  onAddCategory: (category: IgMenuSelection) => void
  onUpdateAccountID: (accountID: string) => void
  targetSpec: FacebookTargetingSpec
}

export const accountCategories: Record<IgMenuSelection, string> = {
  ig_business_profile_all: 'Everyone who engaged with your professional account',
  ig_business_profile_visit: 'Anyone who visited your professional accounts profile',
  ig_business_profile_engaged: 'People who engaged with any post or ad',
  ig_business_profile_user_messaged: 'People who sent a message to your professional account',
  ig_business_profile_ad_saved: 'People who saved any post or ad',
}

export function InstagramAccount(props: InstagramAccountProps) {
  const {
    selectedCategory,
    retention,
    onAddCategory,
    onUpdateRetention,
    onUpdateAccountID,
    accountID,
    targetSpec,
  } = props

  const categoryOptions = Object.keys(accountCategories).map(x => ({
    //@ts-ignore
    label: accountCategories[x],
    value: x,
  }))

  const categoryValue: any = !selectedCategory
    ? null
    : categoryOptions.filter(x => x.value === selectedCategory)

  function setCategory(option: { label: string; value: IgMenuSelection }) {
    onAddCategory(option.value)
  }

  return (
    <>
      <div className="container">
        <StepHeader
          title="Choose your Instagram Account"
          description="Choose the Instagram account you would like to use for this audience."
          imgSrc={InstagramLogo}
          targetingSpec={targetSpec}
        />
        <div className="row mt-5">
          <div className="col-12">
            <AccountInfo onSelectAccount={onUpdateAccountID} accountID={accountID} />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div
              className="card box-shadow border border-radius p-4 d-block mx-auto"
              style={{ maxWidth: '600px' }}
            >
              <p className="text-bold text-center">Use the following category...</p>
              <Select
                placeholder="Choose an account category"
                options={categoryOptions}
                value={categoryValue}
                onChange={setCategory as any}
              />
              <p className="text-bold text-center mt-4">In the past...</p>
              <Slider
                defaultValue={retention}
                min={1}
                max={365}
                onChangeValue={onUpdateRetention}
                minLabel="Day"
                maxLabel="Days"
              />
              <h4 className="text-center">{retention} Days</h4>
            </div>
            <p className="mt-5 text-subtext text-center">
              *You will have the option to add your Facebook Page next.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
