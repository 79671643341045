import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { CustomerDataUploader } from './CustomerDataUploader'
import CircularProgress from '@material-ui/core/CircularProgress'
import { audienceBuilderService } from '@marketing-milk/frontend'
import { CustomerFileCSVData, FacebookTargetingSpec } from '@marketing-milk/interfaces'

type AddCustomerDataModalProps = {
  businessID: string | number
  audienceID: string | number
  isOpen: boolean
  onClose: (toastData?: { msg: string; type: 'success' | 'error' }) => void
  targetSpec: FacebookTargetingSpec
}

export const AddCustomerDataModal = ({
  businessID,
  audienceID,
  isOpen,
  onClose,
  targetSpec,
}: AddCustomerDataModalProps) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [customerData, setCustomerData] = useState<CustomerFileCSVData>()
  const [isSubmittingCustomerData, setIsSubmittingCustomerData] = useState<boolean>(false)

  const submitCustomerData = async () => {
    setIsSubmittingCustomerData(true)

    try {
      await audienceBuilderService.addCustomerData(businessID, audienceID, customerData!)
      onClose({ msg: 'Success!', type: 'success' })
    } catch (err: any) {
      onClose({
        msg: 'There was an issue uploading your customer data. Please ensure it is in the correct format.',
        type: 'error',
      })
    }

    setIsSubmittingCustomerData(false)
  }

  const validateCSVData = (data: CustomerFileCSVData) =>
    audienceBuilderService.validateCustomerData(businessID, data)

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      scroll="paper"
      fullWidth={true}
      fullScreen={fullScreen}
    >
      <DialogTitle id="add-customer-data-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Add Customer Data</Typography>
          <IconButton onClick={() => onClose()} aria-label="close">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <CustomerDataUploader
          handleValidData={setCustomerData}
          validateData={validateCSVData}
          targetSpec={targetSpec}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={submitCustomerData}
          disabled={isSubmittingCustomerData || !customerData}
        >
          {isSubmittingCustomerData ? (
            <CircularProgress data-testid="submit-spinner" size={12} />
          ) : (
            'Submit'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
