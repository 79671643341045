import React from 'react'
import { ChangeKeyModal } from './ChangeKeyModal'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'

export interface CustomQuestionProps {
  id: string
  value: string
  onChange: (event: string) => void
  onDelete: () => void
  onChangeKeyValue: (newKey: string) => void
  keyValue?: string
  placeholder?: string
  editable?: boolean
  canDelete?: boolean
}

export function CustomQuestion(props: CustomQuestionProps) {
  const {
    id,
    value,
    onChange,
    onDelete,
    onChangeKeyValue,
    keyValue,
    placeholder,
    editable = true,
    canDelete = true,
  } = props

  return (
    <div className="d-flex w-100 my-1">
      <div className="w-100 mr-3 center-vertical">
        <TextField
          error={!value}
          helperText={!value && '* Field cannot be empty'}
          className="ml-2"
          id={id}
          fullWidth
          disabled={!editable}
          value={value}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          inputProps={{
            'aria-label': 'lead card custom question',
            'data-testid': 'custom-question-input',
          }}
        />
      </div>
      <div>
        <ChangeKeyModal value={keyValue} onChange={onChangeKeyValue} editable={editable} />
      </div>
      <div>
        <IconButton data-testid="delete-icon" disabled={!editable || !canDelete} onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  )
}
