import './Login.scss'
import React, { useContext, useEffect } from 'react'
import { GuestLayout } from 'components/layout/GuestLayout'
import MMLogo from 'public/img/logos/mm-droplet-text.png'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { sessionContext, userService } from '@marketing-milk/frontend'
import { AUTH0_AUDIENCE } from '../../../constants'
import { routerContext } from '../../../components/router/context/RouterContext'
import jwt_decode from 'jwt-decode'

export function Login() {
  const routes = useContext(routerContext)
  const session = useContext(sessionContext)
  const { loginWithRedirect, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    if (!isLoading) {
      const startSession = async () => {
        const token = await getAccessTokenSilently({
          audience: AUTH0_AUDIENCE,
          scope: 'read:current_user',
        })
        try {
          session.loginWithToken(token)
          await userService.syncAuth0Access()

          const { permissions } = jwt_decode(token) as { permissions: string[] }

          // check if permissions have been set,
          // if not, this may be a new provider login
          // the backend will have updated the ROLES for this user at this point
          // and we will need to attempt to get the permissions again
          if (permissions.length === 0) {
            const newToken = await getAccessTokenSilently({
              audience: AUTH0_AUDIENCE,
              scope: 'read:current_user',
              ignoreCache: true,
            })

            session.loginWithToken(newToken)
          }
        } catch (e: any) {
          history.push('/invalid_user')
        }
      }

      const auth0Login = async () => {
        loginWithRedirect({
          audience: AUTH0_AUDIENCE,
          scope: 'read:current_user',
          redirectUri: window.location.origin + '/builder/login',
        })
      }

      const e2eLogin = async () => {
        const e2eAuthToken = JSON.parse(localStorage.getItem('auth0Cypress')!)
        session.loginWithToken(e2eAuthToken.body.access_token)
        await userService.syncAuth0Access()
      }

      if (session.user) {
        history.push(routes[0])
        // @ts-expect-error - window.Cypress is inited when we run our Cypress tests
      } else if (window.Cypress) {
        e2eLogin()
      } else if (isAuthenticated) {
        startSession()
      } else {
        auth0Login()
      }
    }
  }, [
    getAccessTokenSilently,
    history,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    routes,
    session,
  ])

  return (
    <GuestLayout>
      <div className="d-flex flex-column flex-root h-100">
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
          <div
            className="login-aside flex-column flex-row-auto"
            style={{ background: '#2A2F4C', color: 'white', width: '30%' }}
          >
            <div className="d-flex flex-column-auto flex-column p-15">
              <a href="#" className="text-center mb-10">
                <img alt="mm logo" src={MMLogo} />
              </a>
              <h3 className="font-weight-bolder text-center font-size-h4 font-size-h1-lg">
                Measure KPI's That Matter
              </h3>
            </div>
          </div>
          <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
            <div className="d-flex flex-column-fluid flex-center">
              <div className="login-form login-signin">
                <form className="form fv-plugins-bootstrap fv-plugins-framework" noValidate>
                  <div className="pb-13 pt-lg-0 pt-5">
                    {isLoading ? 'Logging in' : 'Redirecting to Login'}&nbsp;&nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
              <div className="text-dark-50 font-size-lg font-weight-bolder mr-10">
                <span className="mr-1">&copy; 2020</span>
                <a href="/" target="_blank" className="text-dark-75 text-hover-primary">
                  Marketing Milk
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}
