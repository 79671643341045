import React from 'react'
import Auth0ProviderWithHistory from '../../Auth0ProviderWithHistory'
import { BrowserRouter, Switch, Route, Redirect, RouteProps } from 'react-router-dom'
import { RouterContextProvider } from 'components/router/context/RouterContextProvider'
import { Campaigns } from 'pages/business/campaigns/Campaigns'
import { Creatives } from 'pages/business/creatives/Creatives'
import { Login } from 'pages/auth/login'
import { ListLeadCards } from 'pages/business/lead-cards/ListLeadCards'
import { EditLeadCard } from 'pages/business/lead-cards/EditLeadCard'
import { EditAdCreative } from 'pages/business/creatives/EditAdCreative'
import { ListAudiences } from 'pages/business/audiences/list-audiences'
import { CreateLeadCard } from 'pages/business/lead-cards/CreateLeadCard'
import { DuplicateLeadCard } from 'pages/business/lead-cards/DuplicateLeadCard'
import { InvalidUser } from 'pages/auth/login/InvalidUser'
import { BusinessList } from 'pages/business/business-list/BusinessList'
import { Sprints } from 'pages/business/sprints/Sprints'
import { LocalCompetition } from 'pages/business/local-competition/LocalCompetition'
import { useGetBusinessIfExists } from '@marketing-milk/frontend'
import { toast } from 'react-toastify'

type BusinessPageRequiredProps = {
  children: React.ReactElement
}

// only guards by if business has a FB page as of now
function BusinessPageRequired({ children }: BusinessPageRequiredProps) {
  const business = useGetBusinessIfExists()
  if (business?.fbPageID) {
    return children
  } else {
    if (business) {
      toast.error(
        `You cannot use Ad Builder without a Facebook Page configured for ${business.businessName}`
      )
    }
    return <Redirect to="/" />
  }
}

type GuardedRouteProps = {
  children: React.ReactElement
} & RouteProps

// only guards by if business has a FB page as of now
function GuardedRoute({ children, ...routeProps }: GuardedRouteProps) {
  return (
    <Route {...routeProps}>
      <BusinessPageRequired>{children}</BusinessPageRequired>
    </Route>
  )
}

export function Router() {
  return (
    <BrowserRouter basename="/builder">
      <RouterContextProvider>
        <Auth0ProviderWithHistory>
          <Switch>
            {/* Campaigns */}
            <GuardedRoute path="/:businessID/campaigns">
              <Campaigns />
            </GuardedRoute>

            {/* Audiences */}
            <GuardedRoute path="/:businessID/audiences">
              <ListAudiences />
            </GuardedRoute>

            {/* Creatives */}
            <GuardedRoute path="/:businessID/creatives/:creativeID">
              <EditAdCreative />
            </GuardedRoute>
            <GuardedRoute path="/:businessID/sprints/:creativeID">
              <EditAdCreative />
            </GuardedRoute>
            <GuardedRoute path="/:businessID/creatives">
              <Creatives />
            </GuardedRoute>

            {/* Sprints */}
            <GuardedRoute path="/:businessID/sprints">
              <Sprints />
            </GuardedRoute>

            {/* Local Competition */}
            <GuardedRoute path="/:businessID/competition">
              <LocalCompetition />
            </GuardedRoute>

            {/* Lead Cards */}
            <GuardedRoute exact path="/:businessID/lead-cards/duplicate/:leadCardID">
              <DuplicateLeadCard />
            </GuardedRoute>
            <GuardedRoute exact path="/:businessID/lead-cards/create">
              <CreateLeadCard />
            </GuardedRoute>
            <GuardedRoute exact path="/:businessID/lead-cards/:leadCardID">
              <EditLeadCard />
            </GuardedRoute>
            <GuardedRoute exact path="/:businessID/lead-cards">
              <ListLeadCards />
            </GuardedRoute>

            {/* Business List */}
            <Route path="/dashboard">
              <BusinessList />
            </Route>

            {/* Auth */}
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/invalid_user">
              <InvalidUser />
            </Route>
            <Route>
              <Redirect to="/login" />
            </Route>
          </Switch>
        </Auth0ProviderWithHistory>
      </RouterContextProvider>
    </BrowserRouter>
  )
}
