import React from 'react'
import './card-ribbon.scss'
import { StateColorClasses, CardRibbonProps } from './index'

export function CardRibbon(props: CardRibbonProps) {
  const { title, color } = props

  return (
    <div className={`ribbon-target float-right ${StateColorClasses[color ?? 'success'].className}`}>
      {title}
    </div>
  )
}
