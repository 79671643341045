import { getEnvOrFail } from '@marketing-milk/frontend'
import { Environment } from '@marketing-milk/interfaces'

export const DEFAULT_FB_CPM = 15

const MM_ENV = getEnvOrFail('REACT_APP_MM_ENV')

export const AUTH0_DOMAIN =
  MM_ENV === Environment.Development
    ? 'marketing-milk-dev.us.auth0.com'
    : MM_ENV === Environment.Staging
    ? 'marketing-milk-staging.us.auth0.com'
    : MM_ENV === Environment.Beta
    ? 'marketing-milk-beta.us.auth0.com'
    : MM_ENV === Environment.Production
    ? 'marketing-milk.us.auth0.com'
    : 'Error'

export const AUTH0_CLIENT_ID =
  MM_ENV === Environment.Development
    ? 'dpY2BiudkVk6nsX33Ujg66RFTETdnzSu'
    : MM_ENV === Environment.Staging
    ? 'jqtQxMU3etAB7lEfDyZma0Rpv0m8xI9Z'
    : MM_ENV === Environment.Beta
    ? 'AeK81GhwgmAHmSH1pQZODtvMgzWcwcd7'
    : MM_ENV === Environment.Production
    ? 'qhKT8DdIaKTNoh2t3ZjZgYf0AQR7upr9'
    : 'Error'

export const AUTH0_AUDIENCE =
  MM_ENV === Environment.Development
    ? 'https://marketing-milk-dev.com'
    : MM_ENV === Environment.Staging
    ? 'https://marketing-milk-staging.com'
    : MM_ENV === Environment.Beta
    ? 'https://marketing-milk-beta.com'
    : MM_ENV === Environment.Production
    ? 'https://marketing-milk-prod.com'
    : 'Error'

// If specified, forces reauthentication via auth0 and will force reauth in future when sessions expires, if time elapsed since last authentication > max age (seconds)
// https://auth0.com/docs/login/max-age-reauthentication#max_age-authentication-request-parameter
export const AUTH0_MAX_AGE = process.env['REACT_APP_AUTH0_MAX_AGE']

export const twentyFourHoursInMs = 1000 * 60 * 60 * 24
export const halfHourInMs = 1000 * 60 * 60 * 0.5
