import { getPreview } from '../views/getPreview'
import { basicAdPreviewWire } from '../AdPreview.type'
import React, { useContext, useEffect, useState } from 'react'
import { creativeContext } from '../../context/CreativeContext'
import { adCreativeService, useGetBusinessID } from '@marketing-milk/frontend'
import { ServerError } from 'components/server-request/server-error/ServerError'

export function DynamicPreview() {
  const businessID = useGetBusinessID()
  const [error, setError] = useState<Error>()
  const { creative, preview, previewsToGenerate } = useContext(creativeContext)
  const [previews, setPreviews] = useState<string[]>([])

  const basicPreviewToCoolPreviews = basicAdPreviewWire(preview)

  useEffect(() => {
    async function generatePreviews() {
      try {
        setPreviews([])
        const newPreviews = await adCreativeService.getDynamicAdPreviews(
          businessID,
          creative!.id,
          basicPreviewToCoolPreviews[0]
        )
        setPreviews(newPreviews)
      } catch (e: any) {
        setError(error)
      }
    }

    generatePreviews()
  }, [creative, preview, previewsToGenerate])

  return (
    <>
      <div className="d-flex overflowX-scroll py-3 px-4">
        {previews.map((_, index) => (
          <div className="mr-4" key={`preview_${index}`}>
            {getPreview(basicPreviewToCoolPreviews[0], _)}
          </div>
        ))}
      </div>
      {error && <ServerError error={error} onClose={() => setError(undefined)} />}
    </>
  )
}
