import { makeStyles } from '@material-ui/core'

export const useTableStyles = makeStyles({
  exitButton: {
    display: 'flex',
    marginLeft: '5%',
    width: '90%',
  },
  table: {
    width: '100%',
    padding: 2,
  },
  tableBody: {
    minHeight: '50vh',
    width: '100%',
    justifyContent: 'center',
    margin: '0 auto',
    alignItems: 'center',
  },
  input: {
    fontSize: '18px',
    margin: '10px',
    paddingBottom: '0px',
    marginTop: '30px',
    width: '30vw',
  },
})
