import { CampaignUploadStatus } from '@marketing-milk/interfaces'

export const campaignUploadStatusStyles: Record<
  CampaignUploadStatus,
  { text: string; textClass: string; backgroundClass: string }
> = {
  ['pending']: {
    text: 'Processing',
    textClass: 'text-drive',
    backgroundClass: 'bg-drive faded',
  },
  ['completed']: {
    text: 'Completed',
    textClass: 'text-success',
    backgroundClass: 'bg-success faded',
  },
  ['errors']: {
    text: 'Errors',
    textClass: 'text-danger',
    backgroundClass: 'bg-danger faded',
  },
  ['warnings']: {
    text: 'Warnings',
    textClass: 'text-warning',
    backgroundClass: 'bg-warning faded',
  },
}

export interface CampaignUploadStatusIndicatorProps {
  status: CampaignUploadStatus
  className?: string
}
