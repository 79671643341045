import React from 'react'
import {
  LocationActionDropdown,
  LocationActionType,
  facebookMapCodes,
} from '@marketing-milk/frontend'

export interface CountryListItemProps {
  index: number
  onRemoveLocation: (location: number, type: LocationActionType) => void
  onUpdateActionType: (location: number, newType: LocationActionType) => void
  country: string
  actionType: LocationActionType
}

export function CountryListItem(props: CountryListItemProps) {
  const { country, onRemoveLocation, onUpdateActionType, actionType } = props
  const countryStr = facebookMapCodes.countries.filter(_ => _.key === country)[0].name

  function removeLocation() {
    onRemoveLocation(props.index, actionType)
  }

  function updateActionType(type: LocationActionType) {
    onUpdateActionType(props.index, type)
  }

  return (
    <>
      <div className="location-list-item py-2">
        <LocationActionDropdown
          actionType={actionType}
          onlyIcon={true}
          onSelectAction={updateActionType}
        />
        <small className="text-bold" style={{ fontSize: '12px' }}>
          {countryStr}
        </small>
        <i
          className="remove-location fas fa-times text-inherit text-danger float-right cursor-pointer mt-2"
          onClick={removeLocation}
        />
      </div>
    </>
  )
}
