import { InputLabel, TextField } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { CreativeModal } from '../../../fields/creative-modal/CreativeModal'
import { CarouselCreativeFormSlide } from '../CarouselCreative'

interface CarouselSlideProps {
  slide?: CarouselCreativeFormSlide
  slideNumber: number
  readOnly?: boolean
}

export const CarouselSlide = ({ slide, slideNumber, readOnly }: CarouselSlideProps) => {
  const { control, register, setValue } = useFormContext()

  return (
    <div className="space-y-4 mb-4 mt-2">
      <Controller
        name={`slides.${slideNumber}.headline`}
        control={control}
        render={({ field }) => (
          <TextField
            id="slide-headline"
            label="Headline"
            fullWidth
            disabled={readOnly}
            {...field}
          />
        )}
      />

      <Controller
        name={`slides.${slideNumber}.description`}
        control={control}
        render={({ field }) => (
          <TextField
            id="slide-description"
            label="Description"
            fullWidth
            disabled={readOnly}
            {...field}
          />
        )}
      />

      <div className="form-group">
        <InputLabel shrink>Media *</InputLabel>
        <CreativeModal
          mediaID={slide?.media?.mediaID}
          mediaType={slide?.media?.mediaType}
          {...register(`slides.${slideNumber}.media`, { required: true })}
          onChange={(mediaID, mediaType) => {
            if (!mediaID || !mediaType)
              setValue(`slides.${slideNumber}.media`, undefined, { shouldValidate: true })
            else
              setValue(
                `slides.${slideNumber}.media`,
                { mediaID: mediaID, mediaType: mediaType },
                { shouldValidate: true }
              )
          }}
          readOnly={readOnly ?? true}
        />
      </div>
    </div>
  )
}
