import React, { useState } from 'react'
import { adCreativeService, useGetBusinessID } from '@marketing-milk/frontend'
import { DuplicateCreativeModalProps } from './DuplicateCreativeModal.types'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import { useCreatives } from 'pages/business/creatives/useCreatives'

export function DuplicateCreativeModal({
  creative,
  isVisible,
  onToggle,
}: DuplicateCreativeModalProps) {
  const businessID = useGetBusinessID()
  const [newCreativeName, setNewCreativeName] = useState(creative.name)
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)

  const { duplicateCreative, refetch } = useCreatives(businessID)

  if (!isVisible) {
    return null
  }

  async function onDuplicate() {
    setLoading(true)
    try {
      await duplicateCreative(creative.id, {
        name: newCreativeName,
      })
      refetch()
      toast.success('Creative has been duplicated')
      onToggle()
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  function onCancel() {
    setError(undefined)
    onToggle()
  }

  return (
    <Dialog open={isVisible} onClose={onToggle} maxWidth="md" fullWidth={true}>
      <DialogTitle id="duplicate-modal-title">Duplicate Ad Creative</DialogTitle>
      <DialogContent dividers>
        {error ? (
          <DialogContentText>{error.message}</DialogContentText>
        ) : loading ? (
          <CircularProgress />
        ) : (
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Ad Name"
            fullWidth
            defaultValue={newCreativeName}
            onChange={e => setNewCreativeName(e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onDuplicate} color="primary">
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  )
}
