import React from 'react'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { CustomConsentBlock } from './CustomConsentBlock'
import { LeadCardPrivacySettings } from '@marketing-milk/interfaces'

export interface ConsentQuestionsProps {
  editable: boolean
  customDisclaimer: LeadCardPrivacySettings['customDisclaimer']
  onAddConsentBlock(): void
  onDeleteConsentBlock(index: number): void
  onChangeConsent(index: number, key: 'isRequired' | 'text', value: any): void
}

export function ConsentQuestions({
  editable,
  customDisclaimer,
  onAddConsentBlock,
  onDeleteConsentBlock,
  onChangeConsent,
}: ConsentQuestionsProps) {
  return (
    <>
      <div className="d-flex">
        <div className="center-vertical w-75">
          <Typography className="m-0" variant="h5">
            Consent
          </Typography>
        </div>
        <div className="center-vertical w-25" style={{ flex: 'none' }}>
          <div className="d-flex justify-content-end">
            <Button
              disabled={!editable}
              data-testid="add-question-button"
              onClick={onAddConsentBlock}
              color="primary"
            >
              Add Consent
            </Button>
          </div>
        </div>
      </div>
      <hr className="my-3" />
      {customDisclaimer?.checkboxes?.length === 0 && (
        <Alert variant="standard" severity="info" className="mb-2">
          You haven't included any consent checkboxes to this lead card yet.
        </Alert>
      )}
      {customDisclaimer?.checkboxes?.map((_, i) => (
        <CustomConsentBlock
          key={i}
          index={i}
          editable={editable}
          customDisclaimer={customDisclaimer}
          onChangeConsent={onChangeConsent}
          onDeleteConsentBlock={onDeleteConsentBlock}
        />
      ))}
    </>
  )
}
