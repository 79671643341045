import { InputAdornment, TextField } from '@material-ui/core'
import { DescriptiveFieldLayout } from 'components/FormFields/DescriptiveFieldLayout'
import { AttachMoney } from '@material-ui/icons'
import { SprintDTO } from '@marketing-milk/interfaces'
import { getMonthlyBudgetPerCampaign } from '../../../sprint.helpers'

type Props = {
  sprintDTO: SprintDTO
  amountOfCampaigns: number
}

export const MonthlyBudgetField = ({ sprintDTO, amountOfCampaigns }: Props) => {
  const monthlyBudget = getMonthlyBudgetPerCampaign(sprintDTO, amountOfCampaigns)
  return monthlyBudget > 0 ? (
    <DescriptiveFieldLayout
      label="Monthly Budget"
      description="For each Campaign, this is the budget allocated per Month"
    >
      <TextField
        type="number"
        disabled
        label="Monthly Budget Per Campaign"
        value={monthlyBudget}
        variant="standard"
        helperText="= Sprint Budget / Amount of Campaigns / Number of Months"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoney />
            </InputAdornment>
          ),
        }}
      />
    </DescriptiveFieldLayout>
  ) : (
    <></>
  )
}
