import { useGetBusiness } from '@marketing-milk/frontend'
import {
  AdCreative,
  CampaignSchedule,
  PublishCampaignDTO,
  SavedAudience,
} from '@marketing-milk/interfaces'
import { Drawer } from '@mui/material'
import {
  CampaignAdsetsStep,
  CampaignAdStep,
  CampaignSettingsStep,
} from 'pages/business/campaigns/forms/steps'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { campaignService } from '../../../../../app/services'

interface Props {
  isOpen: boolean
  onClose: () => void
  type?: 'audiences' | 'settings' | 'creatives'
  updateDto: (dto: PublishCampaignDTO) => void
  audiences?: SavedAudience[]
  creatives?: AdCreative[]
  initialDto: PublishCampaignDTO
}

export const EditDraftCampaignModal = ({ creatives, audiences, ...props }: Props) => {
  const [campaignDto, setCampaignDto] = useState<PublishCampaignDTO | undefined>(props.initialDto)
  const [schedule, setCampaignSchedule] = useState<CampaignSchedule>()
  const business = useGetBusiness()

  const onUpdate = () => {
    if (!campaignDto) return
    props.updateDto(campaignDto)
    toast.warn('This may take a few moments to reflect in the U.I.')
    props.onClose()
  }

  const fetchCampaignSchedule = async (numOfCreativeGroups: number) => {
    if (campaignDto && numOfCreativeGroups > 1) {
      const generatedSchedule = await campaignService.getCampaignSchedule(business.id, {
        startTime: campaignDto.campaign.startTime,
        endTime: campaignDto.campaign.endTime,
        numOfAdGroups: numOfCreativeGroups,
      })
      setCampaignSchedule(generatedSchedule)
    }
  }

  useEffect(() => {
    if (!campaignDto) return
    fetchCampaignSchedule(campaignDto.creativeGroups.length)
  }, [campaignDto?.campaign, campaignDto?.creativeGroups])

  return (
    <Drawer open={props.isOpen} onClose={props.onClose}>
      {campaignDto && (
        <div style={{ width: '80vw' }} className="flex flex-col max-w-full overflow-hidden">
          <div className="overflow-y-auto flex-grow overflow-x-hidden">
            {props.type === 'audiences' && (
              <CampaignAdsetsStep
                title={`Set ${campaignDto.campaign.name} Audiences`}
                audiences={audiences}
                campaignDto={campaignDto}
                setCampaignDto={setCampaignDto}
              />
            )}

            {props.type === 'creatives' && (
              <CampaignAdStep
                schedule={schedule}
                updateSchedule={fetchCampaignSchedule}
                creatives={creatives}
                campaignDto={campaignDto}
                setCampaignDto={setCampaignDto}
              />
            )}

            {props.type === 'settings' && (
              <CampaignSettingsStep
                isEdit={true}
                campaignDto={campaignDto}
                setCampaignDto={setCampaignDto}
              />
            )}
          </div>

          <button
            className="p-8 m-8 bg-purple-200 w-1/3 border border-dashed border-gray-300 hover:bg-gray-100 flex items-center justify-center font-bold text-lg mx-auto"
            onClick={onUpdate}
          >
            Update Information
          </button>
        </div>
      )}
    </Drawer>
  )
}
