import { toast } from 'react-toastify'
import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import LoopIcon from '@material-ui/icons/Loop'
import { SaveButtonProps } from './SaveButton.types'
import { ServerError } from 'components/server-request/server-error/ServerError'

export function SaveButton({ disabled, onSave }: SaveButtonProps) {
  const [error, setError] = useState<Error>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [errorCode, setErrorCode] = useState<number | undefined>()
  const [isLoading, setLoading] = useState(false)

  async function onClick() {
    setLoading(true)
    try {
      await onSave()
      toast.success('Your changes have been saved')
    } catch (e: any) {
      setError(e)
      setErrorMessage(e.response?.data?.message)
      setErrorCode(e.response?.data?.statusCode)
    } finally {
      setLoading(false)
    }
  }
  const [btnIcon, btnText] = isLoading
    ? [<LoopIcon fontSize="small" className="mr-2 fa fa-spin" />, 'Saving Changes']
    : [<SaveIcon fontSize="small" className="mr-2" />, 'Save Changes']

  return (
    <>
      <Button
        fullWidth
        color="secondary"
        variant="contained"
        disabled={disabled || isLoading}
        onClick={onClick}
        type="button"
        data-testid="save-button"
      >
        {btnIcon} {btnText}
      </Button>
      {error && <ServerError error={error} message={errorMessage} code={errorCode} />}
    </>
  )
}
