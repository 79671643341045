import { ReactNode } from 'react'
import { useLeadCard } from './useLeadCard'
import { emptyLeadCard } from './LeadCardContext.const'
import { LeadCard, LeadCardCreateDTO } from '@marketing-milk/interfaces'
import { OnlyMethods } from 'app/util'

export interface LeadCardContextState extends LeadCardCreateDTO {
  editable: boolean
  previewSection: number
  isValidPhone: boolean
}

export interface LeadCardContextProps {
  children: ReactNode
  defaultLeadCard?: LeadCard
  editable: boolean
}

export const defaultLeadCardContextState: LeadCardContextState = {
  ...emptyLeadCard,
  editable: false,
  previewSection: 0,
  isValidPhone: true,
}

export type LeadCardContextFunctionality = OnlyMethods<ReturnType<typeof useLeadCard>>

export type LeadCardContext = LeadCardContextState & LeadCardContextFunctionality

export const defaultLeadCardContextFunctionality: LeadCardContextFunctionality = {
  setPreviewSection: _ => _,
  onChange(changes: Partial<LeadCardContextState>) {},
  upsertTrackingParam(oldID: string, id: string, value: string) {},
  deleteTrackingParam(id: string) {},
  canSaveLeadCard: () => false,
  updateQuestionDescription: () => {},
  getAvailablePrefillQuestions: () => [],
  deleteQuestion: () => {},
  addShortAnswerQuestion: () => {},
  updateCustomQuestion: () => {},
  updateCustomQuestionKey: () => {},
  addMultiChoiceQuestion: () => {},
  addMultiChoiceOption: () => {},
  updateMultiChoiceOption: () => {},
  deleteMultiChoiceOption: () => {},
  updateMultiChoiceOptionKey: () => {},
  addPrefillQuestion: () => {},
  updatePrefillQuestionType: () => {},
  sortCustomQuestions: () => {},
  sortPrefillQuestions: () => {},
  getPrefillQuestions: () => [],
  getCustomQuestions: () => [],
  getCustomQuestionIndex: () => 0,
  getPrefillQuestionIndex: () => 0,
  updateLanguage: () => {},
  updateSharing: () => {},
  updateCompletionHeadline: () => {},
  updateCompletionDescription: () => {},
  updateCompletionCTA: () => {},
  updateCompletionButtonText: () => {},
  updateCompletionLink: () => {},
  updateCountryCode: () => {},
  updatePhoneNumber: () => {},
  updateBusinessPhone: () => {},
  addGreeting: () => {},
  updateGreetingBodyStyle: () => {},
  updateGreetingHeadline: () => {},
  updateGreetingParagraph: () => {},
  updateGreetingBullet: () => {},
  deleteGreeting: () => {},
  updatePrivacyLink: () => {},
  updatePrivacyText: () => {},
  addCustomDisclaimer: () => {},
  deleteCustomDisclaimer: () => {},
  updateDisclaimerTitle: () => {},
  updateDisclaimerBody: () => {},
  addConsentBlock: () => {},
  deleteConsentBlock: () => {},
  updateConsentBlock: () => {},
  updateFormName: () => {},
  updateFormType: () => {},
  updateMediaType: () => {},
  updateMedia: () => {},
}

export const defaultLeadCardContext: LeadCardContext = {
  ...defaultLeadCardContextState,
  ...defaultLeadCardContextFunctionality,
}
