import React from 'react'
import {
  LocationActionDropdown,
  LocationActionType,
  LocationRegion,
} from '@marketing-milk/frontend'

export interface RegionListItemProps {
  index: number
  onRemoveLocation: (location: number, type: LocationActionType) => void
  onUpdateActionType: (location: number, newType: LocationActionType) => void
  region: LocationRegion
  actionType: LocationActionType
}

export function RegionListItem(props: RegionListItemProps) {
  const { region, onRemoveLocation, onUpdateActionType, actionType } = props

  function removeLocation() {
    onRemoveLocation(props.index, actionType)
  }

  function updateActionType(type: LocationActionType) {
    onUpdateActionType(props.index, type)
  }

  return (
    <>
      <div className="location-list-item py-2">
        <LocationActionDropdown
          actionType={actionType}
          onlyIcon={true}
          onSelectAction={updateActionType}
        />
        <small className="text-bold" style={{ fontSize: '12px' }}>
          {region.name}
        </small>
        <i
          className="remove-location fas fa-times text-inherit text-danger float-right cursor-pointer mt-2"
          onClick={removeLocation}
        />
      </div>
    </>
  )
}
