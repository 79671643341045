import React, { useEffect, useState } from 'react'
import { Loading } from 'components'
import { useGetBusinessID } from '@marketing-milk/frontend'
import { getAllUserDemographics } from 'app/services/audience-builder'
import { IncludedDemographics, ExcludedDemographics } from './'
import { FacebookTargetingSpec } from '@marketing-milk/interfaces'
import { range } from 'lodash'
import { DemographicMultiOptionItem, TargetingSpec } from './Demographics.types'
import { Button } from '@material-ui/core'
import { StepHeader } from '../StepHeader'

type SetState<T> = React.Dispatch<React.SetStateAction<T>>
type Props = {
  setTargetSpec: SetState<FacebookTargetingSpec>
  targetSpec: FacebookTargetingSpec
}

export function Demographics(props: Props) {
  const businessID = useGetBusinessID()
  const { targetSpec, setTargetSpec } = props
  const [options, setOptions] = useState<DemographicMultiOptionItem>()

  useEffect(() => {
    setTargetSpec(targetSpec =>
      targetSpec.flexible_spec ? targetSpec : { ...targetSpec, flexible_spec: [{}] }
    )
  }, [])

  useEffect(() => {
    getAllUserDemographics(businessID).then(setOptions)
  }, [])

  if (!options) return <Loading />
  return (
    <div className="container pb-5 mb-5" style={{ minHeight: '700px' }}>
      <StepHeader
        title="Add User Demographics"
        description="Choose the user demographics, interests, or behaviors below you would like to include OR exclude from your audience."
        targetingSpec={targetSpec}
      />
      <div className="row">
        <h4>I want to INCLUDE people with. . .</h4>
        {range(0, targetSpec?.flexible_spec?.length || 1).map(index => (
          <>
            <div className="col-12">
              <IncludedDemographics
                targetSpec={targetSpec as TargetingSpec}
                setTargetSpec={setTargetSpec as SetState<TargetingSpec>}
                demographicSpecIndex={index}
                businessID={businessID}
                options={options}
              />
            </div>
          </>
        ))}
        <div className="col-12">
          <Button
            variant="outlined"
            className="mt-5"
            onClick={() => {
              setTargetSpec(targetSpec => ({
                ...targetSpec,
                flexible_spec: [...(targetSpec?.flexible_spec || []), {}],
              }))
            }}
          >
            Add another group of traits
          </Button>
          <hr className="my-5" />
        </div>

        <h4>I want to EXCLUDE people with. . .</h4>
        <div className="col-12">
          <div>
            <ExcludedDemographics
              targetSpec={targetSpec as TargetingSpec}
              setTargetSpec={setTargetSpec as SetState<TargetingSpec>}
              businessID={businessID}
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
