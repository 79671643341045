import { ReactNode } from 'react'
import { BasicAdPreview } from '../ad-preview/AdPreview.type'
import {
  AdCreative,
  AdCreativeType,
  AdImage,
  AdVideo,
  exampleAdCreative,
} from '@marketing-milk/interfaces'

export interface CreativeContextProps {
  children: ReactNode
  defaultCreative: AdCreative
}

export interface CreativeContextState {
  name: string
  format: AdCreativeType
  creative: AdCreative
  preview: BasicAdPreview
  previewsToGenerate: number
}

export const defaultCreativeContextState: CreativeContextState = {
  name: '',
  format: AdCreativeType.single_image,
  creative: exampleAdCreative,
  preview: 'facebook',
  previewsToGenerate: 1,
}

export interface CreativeContextFunctionality {
  images: AdImage[]
  videos: AdVideo[]
  setName(name: string): void
  setFormat(format: AdCreativeType): void
  setCreative(creative: AdCreative): void
  setPreview(preview: BasicAdPreview): void
  setPreviewsToGenerate(previews: number): void
  addImage(image: AdImage): void
  addVideo(video: AdVideo): void
}

export const defaultCreativeContextFunctionality: CreativeContextFunctionality = {
  images: [],
  videos: [],
  setName(name: string) {},
  setFormat(format: AdCreativeType) {},
  setCreative(creative: AdCreative) {},
  setPreview(preview: BasicAdPreview) {},
  setPreviewsToGenerate(previews: number) {},
  addImage(image: AdImage): void {},
  addVideo(video: AdVideo): void {},
}

export type CreativeContext = CreativeContextState & CreativeContextFunctionality

export const defaultCreativeContext: CreativeContext = {
  ...defaultCreativeContextState,
  ...defaultCreativeContextFunctionality,
}
