import { Typography } from '@material-ui/core'
import { leadCardPreviewContext } from './context'
import React, { useContext, ReactNode } from 'react'
import { formTypeWire } from './LeadCardPreview.types'
import IconButton from '@material-ui/core/IconButton'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { MessageForLeads } from './sections/message-for-leads/MessageForLeads'
import { LeadCardPrivacyPolicy } from './sections/privacy-review/LeadCardPrivacyReview'
import { LeadCardPrefillQuestions } from './sections/prefil-questions/LeadCardPrefillQuestions'
import { LeadCardCustomQuestions } from './sections/custom-questions/LeadCardCustomQuestions'

export const leadCardSections: Array<{ label: string; view: ReactNode }> = [
  {
    label: 'Custom Questions',
    view: <LeadCardCustomQuestions />,
  },
  {
    label: 'Prefill Information',
    view: <LeadCardPrefillQuestions />,
  },
  {
    label: 'Privacy Review',
    view: <LeadCardPrivacyPolicy />,
  },
  {
    label: 'Message for Leads',
    view: <MessageForLeads />,
  },
]

export function SelectSectionPreview() {
  const { section, setSection, leadCard } = useContext(leadCardPreviewContext)
  const activeSection = leadCardSections[section]
  const canGoForward = section !== leadCardSections.length - 1
  const canGoBackward = section !== 0

  return (
    <>
      <div
        data-testid="select-section-preview"
        className="position-absolute w-100"
        style={{ zIndex: 100, backgroundColor: 'rgba(0,0,0,0.7)', top: 0 }}
      >
        <div className="d-flex w-100 p-1" style={{ justifyContent: 'space-between' }}>
          <IconButton
            data-testid="previous-section"
            disabled={!canGoBackward}
            aria-label="page back"
            onClick={() => setSection(section - 1)}
          >
            <ArrowLeftIcon className="text-white" />
          </IconButton>
          <div>
            <Typography variant="h6" className="text-bold text-white text-center mb-n1">
              {activeSection.label}
            </Typography>
            <Typography variant="subtitle1" className="text-white text-center">
              {formTypeWire[leadCard.formType]} Preview
            </Typography>
          </div>
          <IconButton
            data-testid="next-section"
            disabled={!canGoForward}
            aria-label="page forward"
            onClick={() => setSection(section + 1)}
          >
            <ArrowRightIcon className="text-white" />
          </IconButton>
        </div>
      </div>
    </>
  )
}
