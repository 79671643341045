import {
  AdCreative,
  AdCreativeStatus,
  CampaignGoalType,
  PublishCampaignDTO,
} from '@marketing-milk/interfaces'
import { equals } from 'lodash/fp'
import { useEffect, useState } from 'react'
import {
  creativeHasValidLeadCard,
  hasImproperCTA,
  isValidCreative,
} from '../../../campaigns/helpers/campaign.helpers'
import { AdCreativeList } from './'

type Props = {
  creatives?: AdCreative[]
  creativeA?: AdCreative
  creativeB?: AdCreative
  campaignDto: PublishCampaignDTO
  onChangeCreatives: (creativeA?: AdCreative, creativeB?: AdCreative) => void
}

export function AdCreativeStep({
  campaignDto,
  creativeA,
  creativeB,
  onChangeCreatives,
  ...props
}: Props) {
  const [creatives, setCreatives] = useState(props.creatives)
  const [currentSelection, setCurrentSelection] = useState<'A' | 'B'>('A')

  useEffect(() => {
    if (!props.creatives) return
    setCreatives(filterCreatives(props.creatives))
  }, [props.creatives])

  const filterCreatives = (creatives: AdCreative[]) =>
    creatives
      .filter(creative => creative.status !== AdCreativeStatus.DELETED) // not deleted
      .filter(creative => creative.status !== AdCreativeStatus.WITH_ISSUES) // has no issues
      .filter(creative => !hasImproperCTA(creative, campaignDto.campaign.goal)) // doesn't have config issues with campaign optimization
      .filter(creative =>
        campaignDto.campaign.goal === CampaignGoalType.LeadGeneration
          ? creativeHasValidLeadCard(creative)
          : true
      )
      .filter(creative => isValidCreative(creative))

  const selectCreative = (newCreative: AdCreative) => {
    if (equals(newCreative, creativeA)) {
      onChangeCreatives(creativeB, undefined)
      setCurrentSelection('B')
      return
    }

    if (equals(newCreative, creativeB)) {
      onChangeCreatives(creativeA, undefined)
      setCurrentSelection('B')
      return
    }

    if (currentSelection === 'A') {
      onChangeCreatives(newCreative, creativeB)
      setCurrentSelection('B')
    }
    if (currentSelection === 'B') {
      onChangeCreatives(creativeA, newCreative)
      setCurrentSelection('A')
    }
  }

  return (
    <AdCreativeList
      creatives={creatives}
      creativeA={creativeA}
      creativeB={creativeB}
      selectCreative={selectCreative}
    />
  )
}
