import React from 'react'
import TextField from '@material-ui/core/TextField'
import { LeadCardPrivacySettings } from '@marketing-milk/interfaces'

export interface CustomDisclaimerTextProps {
  editable: boolean
  customDisclaimer: LeadCardPrivacySettings['customDisclaimer']
  onChangeBody(body: string): void
}

export function CustomDisclaimerText({
  editable,
  customDisclaimer,
  onChangeBody,
}: CustomDisclaimerTextProps) {
  const bodyInvalid = !!customDisclaimer && !customDisclaimer.body
  return (
    <div className="form-group">
      <TextField
        error={bodyInvalid}
        helperText={bodyInvalid && 'This field is required when using a custom disclaimer'}
        label="Description *"
        fullWidth
        multiline
        rows={3}
        disabled={!editable}
        value={customDisclaimer?.body}
        onChange={event => onChangeBody(event.target.value as string)}
        placeholder="Explain to users where they can read more about you legal policies"
        inputProps={{ 'data-testid': 'custom-disclaimer-body-input' }}
        InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
      />
    </div>
  )
}
