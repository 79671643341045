import React from 'react'
import { toast } from 'react-toastify'
import { AudienceWizardProps } from './AudienceWizard.types'
import { getBaseWizardSteps } from '../BaseWizardSteps'
import { useWebsiteAudience } from '../use-website-audience'
import { AudienceSummary, fbPixelCategories, Pixel } from '../steps'
import { audienceBuilderService, useGetBusinessID, Line } from '@marketing-milk/frontend'
import { SavedAudienceStandardTargetingSpec, WebsiteAudienceDTO } from '@marketing-milk/interfaces'

import { handleSubmitAudienceError } from './audience-error-handling'
import { AudienceWizard } from '../../audience-wizard'
import { ReceiptSection } from '../../../../campaign-builder/steps/receipt/section'

export function WebsiteAudienceWizard({ onPublishWizard, audience }: AudienceWizardProps) {
  const businessID = useGetBusinessID()
  const websiteAudience = useWebsiteAudience(businessID, audience)

  async function submitAudience() {
    const dto: WebsiteAudienceDTO = {
      name: websiteAudience.audienceName,
      description: undefined,
      isDefault: websiteAudience.isDefault,
      targetingSpec: websiteAudience.targetSpec as SavedAudienceStandardTargetingSpec,
      includes: websiteAudience.audienceData.inclusion,
      // if user has exclusions rule block open.. publish exclusions
      excludes: websiteAudience.addExclusions ? websiteAudience.audienceData.exclusion : undefined,
    }

    try {
      await audienceBuilderService.publishWebsiteTrafficAudience(businessID, dto)
      toast.success("You're audience has been created and published!")
      onPublishWizard(true)
    } catch (err) {
      handleSubmitAudienceError(err)
    }
  }

  return (
    <AudienceWizard
      steps={[
        {
          title: 'Pixel Settings',
          // needs at least one inclusion
          completed: !!websiteAudience.audienceData.inclusion,
          canContinue: !!websiteAudience.audienceData.inclusion,
          children: (
            <Pixel
              pixelData={websiteAudience.pixelData}
              addExclusions={websiteAudience.addExclusions}
              onAddExclusions={websiteAudience.setAddExclusions}
              inclusionSettings={websiteAudience.audienceData.inclusion}
              exclusionSettings={websiteAudience.audienceData.exclusion}
              updateExclusion={websiteAudience.onUpdateExclusion}
              updateInclusion={websiteAudience.onUpdateInclusion}
              targetSpec={websiteAudience.targetSpec}
            />
          ),
        },
        ...getBaseWizardSteps(websiteAudience),
        {
          title: 'Summary',
          completed: !!websiteAudience.audienceName,
          canContinue: !!websiteAudience.audienceName,
          onNext: submitAudience,
          children: (
            <AudienceSummary
              includedUserTraits={websiteAudience.getSelectedUserTraits()}
              excludedUserTraits={websiteAudience.getExcludedUserTraits()}
              targetingSpec={websiteAudience.targetSpec}
              name={websiteAudience.audienceName}
              description={websiteAudience.audienceDescription}
              onUpdateName={websiteAudience.setAudienceName}
              onUpdateDescription={websiteAudience.setAudienceDescription}
              onSetDefault={websiteAudience.setIsDefault}
              isDefault={websiteAudience.isDefault}
              customReceiptSection={
                <>
                  <ReceiptSection
                    title="Pixel Settings"
                    children={
                      <>
                        <Line
                          left="Facebook Pixel:"
                          right={`${websiteAudience.pixelData?.name} - ${websiteAudience.pixelData?.id}`}
                        />
                        <Line
                          left="Retention:"
                          right={`${websiteAudience.audienceData.inclusion.pixelRetention} Days`}
                        />
                        <Line
                          left="Category:"
                          right={
                            //@ts-ignore TODO: Fix typing issue
                            fbPixelCategories[websiteAudience.audienceData.inclusion.menuSelection]
                          }
                        />
                        {websiteAudience.audienceData.inclusion.visitorsByURLOptions && (
                          <>
                            <Line
                              left="URL Rule:"
                              right={
                                websiteAudience.audienceData.inclusion.visitorsByURLOptions
                                  ?.menuSelection
                              }
                            />
                            <Line
                              left="URL:"
                              right={
                                websiteAudience.audienceData.inclusion.visitorsByURLOptions?.url
                              }
                            />
                          </>
                        )}
                        {websiteAudience.audienceData.exclusion && (
                          <>
                            <Line
                              left="Retention:"
                              right={`${websiteAudience.audienceData.exclusion?.pixelRetention} Days`}
                            />
                            <Line
                              left="Category:"
                              right={
                                //@ts-ignore TODO: Fix typing issue
                                fbPixelCategories[
                                  websiteAudience.audienceData.exclusion?.menuSelection
                                ]
                              }
                            />
                            {websiteAudience.audienceData.exclusion?.visitorsByURLOptions && (
                              <>
                                <Line
                                  left="URL Rule:"
                                  right={
                                    websiteAudience.audienceData.exclusion?.visitorsByURLOptions
                                      ?.menuSelection
                                  }
                                />
                                <Line
                                  left="URL:"
                                  right={
                                    websiteAudience.audienceData.exclusion?.visitorsByURLOptions
                                      ?.url
                                  }
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                </>
              }
            />
          ),
        },
      ]}
    />
  )
}
