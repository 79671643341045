import React from 'react'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export interface DeleteGreetingProps {
  editable: boolean
  onDelete(): void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
  })
)

export function DeleteGreeting({ editable, onDelete }: DeleteGreetingProps) {
  const classes = useStyles()
  return (
    <Button
      data-testid="delete-greeting-button"
      variant="contained"
      className={classes.deleteButton + ' mt-3'}
      fullWidth
      disabled={!editable}
      type="button"
      onClick={onDelete}
    >
      <DeleteIcon className="mr-2" />
      Remove Greeting
    </Button>
  )
}
