import { AdCreativeType } from '@marketing-milk/interfaces'

export function adCreativeFormatWire(format: AdCreativeType) {
  switch (format) {
    case AdCreativeType.carousel:
      return 'Carousel'
    case AdCreativeType.dynamic:
      return 'Dynamic'
    case AdCreativeType.single_video:
      return 'Single Video'
    default:
      return 'Single Image'
  }
}
