import { useState } from 'react'
import { SavedAudience } from '@marketing-milk/interfaces'
import { useFetchAllCustomAudiencesForBusiness } from '@marketing-milk/frontend'

export function useAudienceMeta(businessID: string, audience?: SavedAudience) {
  const customAudiences = useFetchAllCustomAudiencesForBusiness(businessID)

  // State
  const [loading, setLoading] = useState(false)
  const [audienceName, setAudienceName] = useState(audience?.name ?? '')
  const [isDefault, setIsDefault] = useState(audience?.isDefault ?? false)
  const [audienceDescription, setAudienceDescription] = useState<string | undefined>(
    audience?.description ?? undefined
  )

  function setLoadingStatus(loading: boolean) {
    setLoading(loading)
  }

  function setIsDefaultFlag(isDefault: boolean) {
    setIsDefault(isDefault)
  }

  function onSetAudienceName(name: string) {
    setAudienceName(name)
  }

  function onSetAudienceDescription(description: string) {
    setAudienceDescription(description)
  }

  return {
    loading,
    isDefault,
    audienceName,
    customAudiences,
    audienceDescription,
    isEditing: !!audience,
    setLoading: setLoadingStatus,
    setIsDefault: setIsDefaultFlag,
    setAudienceName: onSetAudienceName,
    setAudienceDescription: onSetAudienceDescription,
  }
}
