import React from 'react'
import './action-card.scss'
import { ActionCardProps } from './index'
import { Link } from 'react-router-dom'

export function ActionCard(props: ActionCardProps) {
  const { children, classes, onClick, link, active, hideSelectionCheck = false } = props

  const card = (
    <div
      onClick={onClick}
      className={`base-card ${classes ?? ''} p-3 ${active ? 'selected' : ''}`}
      style={props.style}
    >
      {children}
      {active !== undefined && !hideSelectionCheck && (
        <>
          {active ? (
            <i
              className="fad fa-check-circle text-success selection pr-3"
              data-testid="active-icon"
            />
          ) : (
            <i className="fad fa-circle selection pr-3" />
          )}
        </>
      )}
    </div>
  )

  if (link) return <Link to={link}>{card}</Link>
  return card
}
