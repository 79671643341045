import { useAudienceMeta } from './use-audience-meta'
import { useFacebookTargetingSpec } from './use-facebook-targeting-spec'
import { SavedAudience } from '@marketing-milk/interfaces'

export function useBaseAudience(businessID: string, audience?: SavedAudience) {
  const audienceMeta = useAudienceMeta(businessID, audience)
  const targetingSpec = useFacebookTargetingSpec(audience?.targeting)

  return {
    ...audienceMeta,
    ...targetingSpec,
  }
}
