import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useGetBusiness } from '@marketing-milk/frontend'
import InputAdornment from '@material-ui/core/InputAdornment'
import { LeadCardPrivacySettings } from '@marketing-milk/interfaces'

export interface CustomDisclaimerTitleProps {
  editable: boolean
  customDisclaimer: LeadCardPrivacySettings['customDisclaimer']
  onChangeTitle(title: string): void
}

export const disclaimerTitleMaxLength = 60

export function CustomDisclaimerTitle({
  editable,
  customDisclaimer,
  onChangeTitle,
}: CustomDisclaimerTitleProps) {
  const { businessName } = useGetBusiness()
  const titleInvalid = !!customDisclaimer && !customDisclaimer.title
  return (
    <div className="form-group">
      <TextField
        error={titleInvalid}
        helperText={titleInvalid && 'This field is required when using a custom disclaimer'}
        label="Title *"
        fullWidth
        disabled={!editable}
        value={customDisclaimer?.title}
        onChange={event => onChangeTitle(event.target.value as string)}
        inputProps={{
          maxLength: disclaimerTitleMaxLength,
          'data-testid': 'custom-disclaimer-title-input',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="subtitle1">
                {customDisclaimer?.title?.length ?? 0}/{disclaimerTitleMaxLength}
              </Typography>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
        placeholder={`Terms & Conditions for ${businessName}`}
      />
    </div>
  )
}
