import React, { useState } from 'react'
import { SavedAudience } from '@marketing-milk/interfaces'
import { audienceBuilderService } from '@marketing-milk/frontend'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ServerError } from 'components/server-request/server-error/ServerError'

export interface AudienceRecalculationProps {
  audience: SavedAudience
  showModal: boolean
  onHideModal: () => void
  onCalculateAudience: () => void
}

export function AudienceRecalculation({
  audience,
  onHideModal,
  showModal,
  onCalculateAudience,
}: AudienceRecalculationProps) {
  const [error, setError] = useState<Error>()
  const [isLoading, setLoading] = useState(false)

  if (!audience) {
    return null
  }

  async function calculateAudience() {
    try {
      setLoading(true)
      await audienceBuilderService.updateAudience(audience.businessID, audience.id, {
        isPotentialApproximation: true,
      })
      onCalculateAudience()
      onHideModal()
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Modal isOpen={showModal} toggle={onHideModal}>
        <ModalHeader toggle={onHideModal}>Audience Size Calculation</ModalHeader>
        <ModalBody>
          <div className="text-center">
            <i className="fa fa-exclamation-triangle fa-4x text-warning text-center" />
            <p className="text-bold mt-3">
              Do you want to remove exclusions and calculate the total size of your audience?
            </p>
            <p>
              In order to populate an estimated size, we would need to remove custom audience users
              from the list. This could affect your end total, but will not change who you actually
              target.
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-block w-100">
            <button className="btn btn-default mr-2 float-left cancel-recalc" onClick={onHideModal}>
              Cancel
            </button>
            <button
              className="btn btn-brand float-right submit-recalc"
              disabled={isLoading}
              onClick={calculateAudience}
            >
              {isLoading && (
                <>
                  <i className="fa fa-spinner fa-spin mr-2" />
                  Running Calculations...
                </>
              )}
              {!isLoading && 'Calculate Audience'}
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {error && <ServerError error={error} onClose={() => setError(undefined)} />}
    </>
  )
}
