import Paper from '@mui/material/Paper'
import React, { ReactNode } from 'react'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@material-ui/core/Typography'

export interface DetailSectionProps {
  title: string
  children: ReactNode
  headerRight?: ReactNode
  className?: string
}

export function DetailSection({ title, children, headerRight, className }: DetailSectionProps) {
  return (
    <Paper elevation={0} data-testid="detail-section" className={className + ' border'}>
      <CardHeader title={<Typography variant="h5">{title}</Typography>} action={headerRight} />
      <hr />
      <div className="w-100 p-4">{children}</div>
    </Paper>
  )
}
