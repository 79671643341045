import { ReactNode } from 'react'
import { CreativeMediaType, FBCallToAction } from '@marketing-milk/interfaces'

export interface SingleContextProps {
  children: ReactNode
}

export interface SingleContextState {
  headline: string
  description: string
  graphicText: string
  primaryText: string
  websiteURL: string
  displayURL: string
  mediaID?: number
  mediaType?: CreativeMediaType
  callToAction: FBCallToAction
}

export const defaultSingleContextState: SingleContextState = {
  headline: '',
  description: '',
  graphicText: '',
  primaryText: '',
  websiteURL: '',
  displayURL: '',
  callToAction: FBCallToAction.ADD_TO_CART,
  mediaID: undefined,
  mediaType: undefined,
}

export interface SingleContextFunctionality {
  updateSingleContext(changes: Partial<SingleContextState>): void
}

export const defaultSingleContextFunctionality = {
  updateSingleContext(changes: Partial<SingleContextState>) {},
}

export type SingleContext = SingleContextState & SingleContextFunctionality

export const defaultSingleContext: SingleContext = {
  ...defaultSingleContextState,
  ...defaultSingleContextFunctionality,
}
