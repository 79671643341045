import React from 'react'
import { ToolMenu, Tool } from '@marketing-milk/frontend'
import { Button } from '@mui/material'
import { Campaign } from '@marketing-milk/interfaces'
import { ActionType } from '../campaign-page/CampaignContent'
import { rejectUndefined } from 'app/util'
import { campaignHasError } from '../../helpers/campaign.helpers'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

type Props = {
  campaign: Campaign
  action: (type: ActionType) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  })
)

export const CampaignTools = ({ campaign, action }: Props) => {
  const classes = useStyles()
  const canDelete = !!campaign.config
  const deleteTool: Tool | undefined = canDelete
    ? {
        text: 'Delete Campaign',
        action: () => {
          action('delete')
        },
        type: 'link',
        icon: '',
      }
    : undefined

  // An active campaign can always be duplicated
  // An inactive campaign can only be duplicated if it's roa is above 10
  const canDuplicate = (campaign.roas || 0) >= 10 || campaign.status === 'ACTIVE'
  const duplicateTool: Tool | undefined =
    canDuplicate && campaign.config
      ? {
          text: 'Duplicate Campaign',
          action: () => action('duplicate'),
          type: 'link',
          icon: '',
        }
      : undefined

  const editTool: Tool | undefined = campaign.config
    ? {
        text: 'Edit Campaign',
        action: () => action('edit'),
        type: 'link',
        icon: '',
      }
    : undefined

  if (campaignHasError(campaign) && campaign.config)
    return (
      <>
        <Button variant="contained" color="primary" onClick={() => action('retry')}>
          Retry
        </Button>
        {deleteTool && (
          <Button
            className={`${classes.deleteButton} ml-2`}
            variant="contained"
            color="inherit"
            onClick={() => action('delete')}
          >
            Delete
          </Button>
        )}
      </>
    )

  const availableActions = rejectUndefined([duplicateTool, editTool, deleteTool])

  return availableActions.length ? <ToolMenu dotPattern={'h'} tools={availableActions} /> : <></>
}
