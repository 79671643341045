import React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { AdCreative } from '@marketing-milk/interfaces'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import { CreativeMedia } from '../../pages/business/creatives/CreativeCard/CreativeMedia'
import { adCreativeFormatWire } from '../../pages/business/creatives/creative-wizard/util'
import { formTypeWire } from '../../pages/business/lead-cards/lead-card-preview/LeadCardPreview.types'

export interface AdCardProps {
  title: string
  creative?: AdCreative
}

export function AdCard({ title, creative }: AdCardProps) {
  return (
    <Card
      data-testid="ad-card"
      elevation={0}
      className="border center-vertical d-flex flex-column justify-content-start h-100"
    >
      <CardHeader title={title} />
      <hr />
      <div className="d-flex flex-column">
        {creative && <CreativeMedia creative={creative} />}
        <CardContent className="pb-3" sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {creative?.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {creative?.format && adCreativeFormatWire(creative?.format)}
          </Typography>
          <hr className="my-3" />
          <div className="d-flex">
            <div className="center-vertical pr-3">
              <AssignmentIndIcon fontSize="large" style={{ fontSize: '35px' }} />
            </div>
            <div>
              {creative?.leadCardUsed ? (
                <>
                  <Typography variant="body1" component="div">
                    {creative?.leadCardUsed?.formName}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    {creative?.leadCardUsed?.formType &&
                      formTypeWire[creative?.leadCardUsed?.formType]}
                  </Typography>
                </>
              ) : (
                <Typography variant="body1" component="div">
                  No Lead Card
                </Typography>
              )}
            </div>
          </div>
        </CardContent>
      </div>
    </Card>
  )
}
