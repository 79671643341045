import React from 'react'
import { DetailSection } from 'components'
import { OrderSummary, CampaignDetails } from './'
import { CampaignSchedule, PublishCampaignDTO, SavedAudience } from '@marketing-milk/interfaces'
// TODO:  remove this type when the correct type is inside of mono repo
import { AdPair } from 'pages/business/campaigns/forms/CampaignForm'

export interface CampaignSummaryProps {
  campaignDTO: PublishCampaignDTO
  schedule?: CampaignSchedule
  onPublish: () => void
  selectedAudiences?: SavedAudience[]
  selectedCreatives?: AdPair[]
}

export function CampaignSummary({
  campaignDTO,
  onPublish,
  selectedAudiences,
  selectedCreatives,
  schedule,
}: CampaignSummaryProps) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-6 mt-4">
      <div className="col-span-1 lg:col-span-2">
        <CampaignDetails
          campaignDto={campaignDTO}
          selectedCreatives={selectedCreatives}
          selectedAudiences={selectedAudiences}
          schedule={schedule}
        />
      </div>
      <div>
        <DetailSection title="Order Summary">
          <OrderSummary campaignDTOs={[campaignDTO]} onPublish={onPublish} />
        </DetailSection>
      </div>
    </div>
  )
}
