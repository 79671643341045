import React from 'react'
import TextField from '@material-ui/core/TextField'

export interface CompletionDescriptionProps {
  editable: boolean
  description?: string
  onChangeDescription(description: string): void
}

export function CompletionDescription({
  editable,
  description,
  onChangeDescription,
}: CompletionDescriptionProps) {
  return (
    <div className="form-group">
      <TextField
        error={!description && editable}
        helperText={!description && editable && 'Thank you page description is a required field'}
        label="Description *"
        fullWidth
        multiline
        disabled={!editable}
        value={description}
        onChange={event => onChangeDescription(event.target.value as string)}
        rows={3}
        inputProps={{ 'data-testid': 'completion-description-input' }}
        InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
      />
    </div>
  )
}
