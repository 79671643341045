import { ApproximateAudienceSizes, FacebookTargetingSpec } from '@marketing-milk/interfaces'
import { FetchResponse, useCreateFetchHookAdvanced } from '../create-fetch-hook-advanced.base'
import { approximateAudienceSizeService } from '@marketing-milk/frontend'

export function useFetchApproximateAudienceSize(
  businessID: string,
  refresh = 0,
  fbTargetSpec: FacebookTargetingSpec
): FetchResponse<ApproximateAudienceSizes> {
  return useCreateFetchHookAdvanced<ApproximateAudienceSizes>(
    () =>
      approximateAudienceSizeService.getApproximateCountsForTargetingSpec(businessID, fbTargetSpec),
    refresh
  )
}
