import Select from 'react-select'
import React, { useEffect } from 'react'
import { useInstagramAccounts } from '@marketing-milk/frontend'

export interface AccountInfoProps {
  accountID: string
  onSelectAccount: (accountID: string) => void
}

export function AccountInfo(props: AccountInfoProps) {
  const { accountID, onSelectAccount } = props
  const ig = useInstagramAccounts()
  const accounts = ig?.accounts

  useEffect(() => {
    // if only 1 account, set account ID
    if (ig && ig.selected) {
      onSelectAccount(ig.selected)
    }
  }, [ig?.accounts])

  // if multiple accounts, map through them for options in dropdown
  const accountOptions =
    accounts &&
    accounts?.map(a => ({
      label: '@' + a.username,
      value: a.id,
    }))

  // display value of dropdown
  const accountValue: any =
    accountID === '' ? null : accountOptions?.filter(x => x.value === accountID)

  // dropdown set id for selected account
  function setAccount(option: { label: string; value: string }) {
    onSelectAccount(option.value)
  }

  function generateIgAccounts() {
    if (!accounts || accounts.length === 0) {
      return (
        <div className="bg-danger text-danger text-center faded fit-content p-3 border-radius mx-auto">
          We couldn't find an Instagram account for your business
        </div>
      )
    } else if (accounts && accounts.length === 1) {
      return (
        <div
          className={'bg-drive text-drive text-center faded fit-content p-3 border-radius mx-auto'}
        >
          @{accounts[0].username}
        </div>
      )
    } else {
      return (
        <Select
          placeholder="Select an Instagram account"
          options={accountOptions}
          value={accountValue}
          onChange={setAccount as any}
        />
      )
    }
  }

  return <>{generateIgAccounts()}</>
}
