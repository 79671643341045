import React from 'react'
import { audiencePaths } from './'
import { ActionCard } from 'components/cards/action-card'
import { SavedAudienceType } from '@marketing-milk/interfaces'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export interface PathCardsProps {
  onSelectedPath: (path: SavedAudienceType) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pathCard: {
      maxWidth: '500px',
      minWidth: '400px',
      minHeight: '110px',
    },
  })
)

export function PathCards(props: PathCardsProps) {
  const classes = useStyles()
  const { onSelectedPath } = props

  return (
    <div className="container-fluid p-4 center-vertical">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="text-center">
            <h1 className="mb-2">What type of audience would you like to create?</h1>
            <p className="px-2 px-md-4">
              Select an option below to start building your Facebook Audience.
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-3" style={{ justifyContent: 'center' }}>
        {audiencePaths.map((path, i) => (
          <ActionCard
            key={i}
            classes={'path-card flex-half d-flex align-items-center mb-2 ml-2 ' + classes.pathCard}
            onClick={() => onSelectedPath(path.type)}
          >
            <div className="d-inline-block">
              <img
                className="img-fluid"
                src={path.icon}
                alt={path.title + ' icon'}
                style={{ maxWidth: 75 }}
              />
            </div>
            <div className="pl-3 d-inline-block">
              <h6 className="path-title mb-2 text-bold">{path.title}</h6>
              <div className="path-description text-wrap-break">{path.description}</div>
            </div>
          </ActionCard>
        ))}
      </div>
    </div>
  )
}
