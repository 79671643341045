import Select from '@material-ui/core/Select'
import React, { ReactNode, useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { useGetLeadCardsForBusiness } from '../../hooks'
import FormControl from '@material-ui/core/FormControl'
import { useGetBusinessID } from '@marketing-milk/frontend'
import { ServerError } from '../server-request/server-error/ServerError'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const EmptyLeadCardId = -1

export interface SelectLeadCardProps {
  readOnly: boolean
  label?: ReactNode
  className?: string
  selectedLeadCardID?: number
  onSelectLeadCard?: (leadCardID: number) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

export function SelectLeadCard(props: SelectLeadCardProps) {
  const { readOnly, onSelectLeadCard, selectedLeadCardID, label, className } = props
  const classes = useStyles()
  const businessID = useGetBusinessID()
  const [error, setError] = useState<Error>()
  const leadCards = useGetLeadCardsForBusiness(Number(businessID))

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onSelectLeadCard && onSelectLeadCard(Number(event.target.value) as number)
  }

  return (
    <FormControl fullWidth>
      {error && <ServerError error={error} onClose={() => setError(undefined)} />}
      <div>
        {label ?? (
          <InputLabel shrink id="lead-card-select-label">
            Lead Card
          </InputLabel>
        )}
        <Select
          labelId="lead-card-select-label"
          id="lead-card-select"
          displayEmpty
          fullWidth
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'lead card select', 'data-testid': 'lead-card-select' }}
          value={selectedLeadCardID ?? EmptyLeadCardId}
          onChange={handleChange}
          disabled={readOnly}
        >
          <MenuItem key="none" value={EmptyLeadCardId}>
            None
          </MenuItem>
          {leadCards?.map((x, i) => (
            <MenuItem key={i} value={x.id}>
              {x.formName}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FormControl>
  )
}
