import { InputAdornment, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { CircularProgress } from '@mui/material'
import { useDebounce } from 'hooks/useDebounce'
import React, { useEffect, useRef, useState } from 'react'

interface Props {
  onSearch: (term: string) => void
  placeholder?: string
  className?: string
}

export const SearchInput = ({ placeholder, onSearch, className }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const { isDebouncing, debouncedValue } = useDebounce(searchTerm)

  useEffect(() => {
    onSearch(debouncedValue)
  }, [debouncedValue])

  return (
    <TextField
      data-testid="business-search"
      fullWidth
      size="small"
      className="center-vertical mr-3 bg-white"
      variant="outlined"
      placeholder={placeholder || 'Search'}
      style={{ maxWidth: 500 }}
      onChange={e => setSearchTerm(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isDebouncing ? <CircularProgress size={25} /> : <SearchIcon />}
          </InputAdornment>
        ),
      }}
    />
  )
}
