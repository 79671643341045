import { UserRoleScopes } from '@marketing-milk/interfaces'
import { isEqual, any, reject, isNil, isEmpty, isNull, pipe, uniq, set, update } from 'lodash/fp'
import { isWebUri } from 'valid-url'
// TODO:  remove this type when the correct type is inside of mono repo
import { AdPair } from '../../pages/business/campaigns/forms/CampaignForm'

// We take the keys of P and if T[P] is a Function we type P as P (the string literal type for the key), otherwise we type it as never.
// Then we index by keyof T, never will be removed from the union of types, leaving just the property keys that were not typed as never
type MethodKeys<T> = { [P in keyof T]: T[P] extends Function ? P : never }[keyof T]

export type OnlyMethods<T> = Pick<T, MethodKeys<T>> // all function properties in an obj
type NoMethods<T> = Omit<T, MethodKeys<T>> // all non function properties in an obj

type EvolverProperties<T extends { [key: string]: (p: any) => any }> = T extends {
  [P in keyof T]?: (v: any) => infer R
}
  ? { [P in keyof T]: (v: Parameters<T[P]>[0]) => R }
  : never
type TransformationObj<T extends Record<string, any>> = {
  [key in keyof T]?: (value: T[key]) => T[key]
}
type ObjFromTransformationObj<T extends TransformationObj<object>> = {
  [P in keyof T]?: Evolved<T[P]>
}
type Evolved<A> = A extends (value: infer V) => any ? V : never

/**
 * Takes two objects, one that you wish to transform and a matching object that specifies which transformation functions to run on which keys
 * @param transformations object with values of unary functions
 * @param object curried param of an object to be transformed
 * @returns tranformed object
 *
 * @example
 * // basic usage
 * const person = {  name: 'cameron', age: 28  }
 * evolve({ age: (n) => n + 1})(person) // returns person with the age incremented
 * // pointfree example
 * const people = [person, person, person]
 * people.map(evolve({ age: n => n + 1 })) // return array with age properties incremented
 */
export const evolve =
  <E extends EvolverProperties<Record<string, (p: any) => any>>>(transformations: E) =>
  <V extends ObjFromTransformationObj<E>>(obj: V) => ({
    ...obj,
    ...Object.entries(transformations).reduce(
      (acc, [key, fn]) => ({
        ...acc,
        [`${key}`]: fn(obj[key]),
      }),
      {} as { [Key in keyof E]: Evolved<E[Key]> }
    ),
  })

export const add = (n1: number) => (n2: number) => n1 + n2
export const subtract = (n1: number) => (n2: number) => n2 - n1

/**  increment a number */
export const inc = add(1)

/**  decrement a number */
export const dec = subtract(1)

/**
 * returns a list without the specified element
 * @param toRemove item to remove
 * @param list list to remove item from
 * @returns
 */
export const without = <T>(toRemove: T, list: T[]) =>
  list.filter(value => !isEqual(toRemove, value))

export const addOrRemove = <T>(element: T, list: T[]) => {
  if (any(isEqual(element), list)) return without(element, list)
  return [...list, element]
}

type NotUndefined<T> = T extends undefined ? never : T
type NotNull<T> = T extends null ? never : T
export const rejectEmpty = <T>(list: T[]) => reject(isEmpty, list)
export const rejectNull = <T>(list: T[]): NotNull<T>[] => reject(isNull, list) as NotNull<T>[]
export const rejectUndefined = <T>(list: T[]): NotUndefined<T>[] =>
  reject(item => typeof item === 'undefined', list) as NotUndefined<T>[]
export const rejectNil = <T>(list: T[]) => reject(isNil, list) as NonNullable<T>[]
export const onlyPopulatedValues = <T>(list: T[]) =>
  pipe(rejectNil, rejectEmpty)(list) as NonNullable<T>[]
export const exists = <T>(item: T) => !isNil(item) && !isEmpty(item)

// https://github.com/microsoft/TypeScript/issues/20707
export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined
}
export function checkValidUrl(url: string) {
  // common url patterns
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  // checks common patterns and https/https presence
  return pattern.test(url) && !!isWebUri(url)
}

type ArrayInfer<T> = T extends (infer U)[] ? U : never
export const appendToArray =
  <T extends any[]>(value: ArrayInfer<T>) =>
  (array?: T) =>
    [...(array || []), value]

// TODO: Assess if this function is really worth it or make it more generalized
export function updateObjectInArray<T extends object, key extends keyof T>(
  array: T[],
  index: number,
  updateProperty: key,
  value: T[key]
) {
  return update(index, set([updateProperty], value), array || [])
}

export const hasDupes = (array: any[]) => array.length !== uniq(array).length

export const getCreativeFromPair = (type: 'A' | 'B', index: number, adPairs?: AdPair[]) => {
  if (!adPairs) return undefined
  const [creativeA, creativeB] = adPairs[index] || [undefined, undefined]
  return type === 'A' ? creativeA : creativeB
}

export const removeAtIndex =
  (index: number) =>
  <T>(items: T[]) =>
    items.filter((_, i) => i !== index)
