import React from 'react'
import { Alert } from 'reactstrap'
import { CustomAudienceTermsAndConditions } from '@marketing-milk/interfaces'

export interface TermsAlertProps {
  termsData?: CustomAudienceTermsAndConditions
}

export function TermsAlert(props: TermsAlertProps) {
  const { termsData } = props

  if (termsData && !termsData.accepted) {
    return (
      <a href={termsData?.endpoint} target="_blank" style={{ color: 'white' }}>
        <Alert color="danger">
          <span className="text-bold">
            This business's Ad Authorizer must accept Facebook's Custom Audience terms and
            conditions before you can use Audience Builder. Click this alert to accept terms and
            conditions. <i className="fas fa-external-link text-inherit text-white ml-2" />
          </span>
        </Alert>
      </a>
    )
  } else return null
}
