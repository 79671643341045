import { Button, Tab, Tabs } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { CarouselSlide } from '../carousel-slide/CarouselSlide'
import { CarouselCreativeFormSlide } from '../CarouselCreative'
import { CarouselSlidesProps } from './CarouselSlides.types'

export function CarouselSlides({ handleSlideChange, readOnly }: CarouselSlidesProps) {
  const [activeSlide, setActiveSlide] = useState(0)
  const { control, getValues } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'slides',
  })

  const handleRemove = () => {
    remove(activeSlide)

    // Prevents negative index on delete
    setActiveSlide(prev => Math.max(prev - 1, 0))
    handleSlideChange()
  }

  const handleAppend = () => {
    const newSlideData: Partial<CarouselCreativeFormSlide> = {
      headline: '',
      description: '',
      media: undefined,
    }

    append(newSlideData)
    setActiveSlide(fields.length)
    handleSlideChange()
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="mt-2 text-xl font-semibold">Create Carousel Slides</p>
        {fields.length < 10 && !readOnly && (
          <Button className="" variant="contained" color="secondary" onClick={handleAppend}>
            <AddIcon className="mr-2" /> Add Slide
          </Button>
        )}
      </div>
      {fields.length > 0 && (
        <Tabs
          variant="scrollable"
          scrollButtons="on"
          value={activeSlide}
          onChange={(e, i) => setActiveSlide(i)}
        >
          {fields.map((_, i) => (
            <Tab key={`slide_${i}`} label={`Slide ${i + 1}`} />
          ))}
        </Tabs>
      )}

      {fields.map(
        (field, index) =>
          index === activeSlide && (
            <div className="p-2">
              <div className="flex items-center justify-between border-b">
                <p className="text-lg font-medium">{`Slide ${index + 1}`}</p>
                {!readOnly && (
                  <button
                    type="button"
                    onClick={handleRemove}
                    className="bg-red-500 text-white p-2 my-2 rounded-md text-sm"
                    disabled={readOnly}
                  >
                    DELETE
                  </button>
                )}
              </div>
              <CarouselSlide
                key={field.id}
                slide={getValues().slides[index]}
                slideNumber={index}
                readOnly={readOnly}
              />
            </div>
          )
      )}
    </div>
  )
}
