import React, { useState } from 'react'
import { AdCreative } from '@marketing-milk/interfaces'
import {
  isAdImage,
  isAdVideo,
  isCarouselCreative,
  isDynamicCreative,
  isSingleCreative,
} from '../../campaigns/helpers/campaign.helpers'

type Props = {
  creative: AdCreative
  className?: string
}
export const CreativeMedia = ({ creative, className }: Props) => {
  const [brokenLink, setBrokenLink] = useState(false)

  const tailwindHeight = className || 'h-64 w-full bg-gray-200'

  if (brokenLink) {
    if (creative.singleVideo?.thumbnail) {
      return (
        <img
          src={creative.singleVideo.thumbnail}
          alt="creative video thumbnail"
          className={`${tailwindHeight} object-cover rounded-t-lg`}
        />
      )
    }

    if (isCarouselCreative(creative) && creative.carouselSlides[0].mediaType === 'video') {
      return (
        <img
          src={creative.carouselSlides[0].video.thumbnail}
          alt="creative video thumbnail"
          className={`${tailwindHeight} object-cover rounded-t-lg`}
        />
      )
    }

    if (isDynamicCreative(creative)) {
      const creativeContent = [...creative.dynamicImages, ...creative.dynamicVideos]
      const previewData = creativeContent[0]
      if (isAdVideo(previewData)) {
        return (
          <img
            src={previewData.thumbnail}
            alt="creative thumbnail"
            className={`${tailwindHeight} object-cover rounded-t-lg`}
          />
        )
      }
    }

    return (
      <p
        className={`font-bold truncate text-red-500 ${tailwindHeight} w-full flex items-center justify-center truncate`}
      >
        <i className="fa fa-exclamation-triangle mr-2 text-red-400" />{' '}
        <p className="truncate">Link Broken In Marketing Milk</p>
      </p>
    )
  }
  if (isSingleCreative(creative)) {
    if (creative.singleImage)
      return (
        <img
          src={creative.singleImage.url}
          alt={creative.singleImage.name}
          onError={() => setBrokenLink(true)}
          className={`${tailwindHeight} object-cover rounded-t-lg`}
        />
      )

    if (creative.singleVideo)
      return (
        <video
          autoPlay
          preload="auto"
          muted
          loop
          onError={() => setBrokenLink(true)}
          playsInline
          src={creative.singleVideo ? creative.singleVideo?.source : ''}
          className={`${tailwindHeight} rounded-t-lg object-cover`}
        />
      )
  }

  if (isCarouselCreative(creative)) {
    if (creative.carouselSlides && creative.carouselSlides[0].mediaType === 'image') {
      return (
        <img
          src={creative.carouselSlides[0].image.url}
          alt={creative.carouselSlides[0].image.name}
          onError={() => setBrokenLink(true)}
          className={`${tailwindHeight} object-cover rounded-t-lg`}
        />
      )
    }

    if (creative.carouselSlides && creative.carouselSlides[0].mediaType === 'video') {
      return (
        <video
          autoPlay
          preload="auto"
          muted
          loop
          onError={() => setBrokenLink(true)}
          playsInline
          src={creative.carouselSlides[0].video.source ?? ''}
          className={`${tailwindHeight} rounded-t-lg object-cover`}
        />
      )
    }
  }

  if (isDynamicCreative(creative)) {
    const creativeContent = [...creative.dynamicImages, ...creative.dynamicVideos]
    const previewData = creativeContent[0]
    if (previewData) {
      if (isAdImage(previewData)) {
        return (
          <img
            src={previewData.url}
            alt={previewData.name}
            onError={() => setBrokenLink(true)}
            className={`${tailwindHeight} object-cover rounded-t-lg`}
          />
        )
      }
      if (isAdVideo(previewData)) {
        return (
          <video
            autoPlay
            preload="auto"
            muted
            loop
            onError={() => setBrokenLink(true)}
            playsInline
            src={previewData.source ?? ''}
            className={`${tailwindHeight} rounded-t-lg object-cover`}
          />
        )
      }
    }
  }

  return (
    <div
      className={`font-bold text-yellow-500 w-full flex ${tailwindHeight} items-center justify-center `}
    >
      <i className="fa fa-exclamation-triangle mr-2 text-yellow-500" /> No Media Added
    </div>
  )
}
