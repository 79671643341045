import './MobilePreview.scss'
import 'intersection-observer'
import React, { useState } from 'react'
import { PreviewProps } from '../Preview.types'
import IFrameResizer from 'iframe-resizer-react'
import { Loading, RenderWhenVisible } from '@marketing-milk/frontend'

export function MobilePreview({ iframeSource, fluidWidth = false }: PreviewProps) {
  const [isLoaded, setLoaded] = useState(false)

  return (
    <RenderWhenVisible style={{ minHeight: 400 }}>
      <div
        className="border border-radius mx-auto"
        style={{ height: 400, width: fluidWidth ? '100%' : 325, padding: 5 }}
      >
        {!isLoaded && (
          <div style={{ width: '100%' }}>
            <Loading loadingMessage="Generating Preview" />
          </div>
        )}
        <IFrameResizer
          src={iframeSource}
          scrolling
          style={{
            height: 1,
            minHeight: '100%',
            width: 1,
            minWidth: '100%',
            border: 'none',
            visibility: isLoaded ? 'visible' : 'hidden',
          }}
          heightCalculationMethod="lowestElement"
          onLoad={() => setLoaded(true)}
        />
      </div>
    </RenderWhenVisible>
  )
}
