import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { LeadCardIntro } from '@marketing-milk/interfaces'
import InputAdornment from '@material-ui/core/InputAdornment'

export interface FormHeadlineProps {
  editable: boolean
  greeting: LeadCardIntro['greeting']
  onChangeHeadline(headline: string): void
}

export const greetingHeadlineMaxLength = 60

export function FormHeadline({ editable, greeting, onChangeHeadline }: FormHeadlineProps) {
  const headlineInvalid = !!greeting && !greeting?.headline
  return (
    <div className="form-group">
      <TextField
        error={headlineInvalid && editable}
        helperText={headlineInvalid && editable && 'This field is required when using a greeting'}
        id="creative-name"
        label="Headline *"
        fullWidth
        disabled={!editable}
        value={greeting?.headline}
        onChange={event => onChangeHeadline(event.target.value as string)}
        inputProps={{
          maxLength: greetingHeadlineMaxLength,
          'data-testid': 'greeting-headline-input',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="subtitle1">
                {greeting?.headline?.length ?? 0}/{greetingHeadlineMaxLength}
              </Typography>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
        placeholder="e.g. Register for our VIP event!"
      />
    </div>
  )
}
