import React from 'react'
import { RadiusSlider } from '@marketing-milk/frontend'
import LinearProgress from '@mui/material/LinearProgress'
import { CompetitionDetails } from './CompetitionDetails'
import { Place } from '@googlemaps/google-maps-services-js'
import { CompetitionDetailsForm } from './CompetitionDetailsForm'
import { useLocalCompetitionFinder } from './use-local-competition'
import { Switch, Divider, Paper, Typography, CardHeader } from '@material-ui/core'
import { MapMarker, MapCircle, milesToMeters } from '@marketing-milk/frontend'

export function ToolBar(props: ReturnType<typeof useLocalCompetitionFinder>) {
  return (
    <Paper
      className="m-3"
      elevation={1}
      style={{
        position: 'absolute',
        width: '275px',
        textAlign: 'center',
        zIndex: 100,
      }}
    >
      <CardHeader
        title="Competition Finder"
        action={
          props.canToggle ? (
            <Switch
              checked={props.featureActive}
              onChange={() => props.setFeatureActive(!props.featureActive)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          ) : (
            <></>
          )
        }
      />
      {/* If feature is off, hide tools and legend */}
      {props.featureActive && (
        <>
          <Divider />
          <RadiusSlider
            radius={props.searchRadius}
            onSetRadius={radius => {
              props.setSearchRadius(radius)
              props.setShowSearchRadius(true)
            }}
            min={props.MIN_GOOGLE_RADIUS}
            max={props.MAX_GOOGLE_RADIUS}
            onChangeCommitted={() => props.setShowSearchRadius(false)}
          />
          <Divider />
          <div className="p-3">
            <div className="flex mb-2">
              <img
                style={{ width: 22, height: 34 }}
                alt="business icon"
                src={props.getMapIcon('business')}
              />
              <Typography className="ml-3 center-vertical" variant="body1">
                {props.business.businessName}
              </Typography>
            </div>
            <div className="flex mb-2">
              <img
                style={{ width: 22, height: 34 }}
                alt="competitor icon"
                src={props.getMapIcon('competitor')}
              />
              <Typography className="ml-3 center-vertical" variant="body1">
                Competitor
              </Typography>
            </div>
            <div className="flex">
              <img
                style={{ width: 22, height: 34 }}
                alt="closed competitor icon"
                src={props.getMapIcon('closed-competitor')}
              />
              <Typography className="ml-3 center-vertical" variant="body1">
                Closed Competitor
              </Typography>
            </div>
          </div>
        </>
      )}
    </Paper>
  )
}

export function CompetitionFinderMapTools(props: ReturnType<typeof useLocalCompetitionFinder>) {
  return (
    <>
      {props.isLoading && (
        <LinearProgress className="w-full" style={{ position: 'absolute', top: 0 }} />
      )}
      <ToolBar {...props} />
      {props.featureActive && (
        <>
          {props.searchRadius && props.showSearchRadius && (
            <MapCircle
              options={{
                fillOpacity: 0.1,
              }}
              circleHexColor="#a044ff"
              center={props.businessCoordinates}
              radius={milesToMeters(props.searchRadius)}
            />
          )}
          {props.data?.map((place: Place, index: number) => (
            <MapMarker
              icon={props.getMapIcon(
                props.isBusinessOperational(place) ? 'competitor' : 'closed-competitor'
              )}
              key={index}
              draggable={false}
              onClick={() => props.setSelectedCompetitorID(place.place_id)}
              position={{
                lat: place.geometry?.location.lat ?? 0,
                lng: place.geometry?.location.lng ?? 0,
              }}
            />
          ))}
          <MapMarker
            icon={props.getMapIcon('business')}
            draggable={false}
            clickable={false}
            position={props.businessCoordinates}
          />
          {!props.hasBusinessData && <CompetitionDetailsForm />}
          <CompetitionDetails {...props} />
        </>
      )}
    </>
  )
}
