import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { FacebookLeadgenBodyStyle } from '@marketing-milk/interfaces'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'

export interface FormLayoutProps {
  editable: boolean
  bodyStyle?: FacebookLeadgenBodyStyle
  onChangeBodyStyle(bodyStyle: FacebookLeadgenBodyStyle): void
}

export function FormLayout({ editable, bodyStyle, onChangeBodyStyle }: FormLayoutProps) {
  return (
    <div className="form-group">
      <InputLabel disabled={!editable} shrink className="text-bold">
        Description *
      </InputLabel>
      <ToggleButtonGroup
        value={bodyStyle}
        onChange={(event, value) => onChangeBodyStyle(value as FacebookLeadgenBodyStyle)}
        exclusive
      >
        <ToggleButton disabled={!editable} size="small" value="PARAGRAPH_STYLE">
          <ViewHeadlineIcon fontSize="small" className="mr-2" />
          Paragraph
        </ToggleButton>
        <ToggleButton disabled={!editable} size="small" value="LIST_STYLE">
          <FormatListBulletedIcon fontSize="small" className="mr-2" />
          List
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  )
}
