import React, { useState } from 'react'
import PixelLogo from 'public/img/logos/pixel-logo.png'
import { useGetPixelEvents } from '@marketing-milk/frontend'
import { PixelEventWithCount } from '@marketing-milk/interfaces'
import { fbPixelCategories, urlCategories, PixelProps, RuleBlock } from './'
import { StepHeader } from '../StepHeader'

export function Pixel(props: PixelProps) {
  const pixelEvents: PixelEventWithCount[] = useGetPixelEvents()
  const [showExclude, setShowExclude] = useState<boolean>(false)
  const {
    inclusionSettings,
    exclusionSettings,
    updateInclusion,
    updateExclusion,
    addExclusions,
    onAddExclusions,
    pixelData,
    targetSpec,
  } = props

  // base options for pixel dropdown
  const categoryOptions = [
    {
      label: fbPixelCategories['ALL_WEBSITE_VISITORS'],
      value: 'ALL_WEBSITE_VISITORS',
    },
    {
      label: fbPixelCategories['VISITORS_BY_URL'],
      value: 'VISITORS_BY_URL',
    },
  ]

  // add pixel events from backend to list of options for dropdown
  pixelEvents &&
    pixelEvents.map((option: any) =>
      categoryOptions.push({
        label: `${option.event} (${option.count})`,
        value: option.event,
      })
    )

  const urlOptions = Object.keys(urlCategories).map(c => ({
    //@ts-ignore
    label: urlCategories[c],
    value: c,
  }))

  function generatePixelName() {
    return (
      <div
        className={`${
          pixelData ? 'bg-drive text-drive' : 'bg-danger text-danger'
        } text-center faded fit-content p-3 border-radius mx-auto`}
      >
        {pixelData
          ? `${pixelData.name} - ${pixelData.id}`
          : "We couldn't find a pixel for your business"}
      </div>
    )
  }

  return (
    <>
      <div className="container">
        <StepHeader
          title="Choose your Facebook Pixel Categories"
          description="Choose the Facebook Pixel categories you would like to use for this audience."
          imgSrc={PixelLogo}
          targetingSpec={targetSpec}
        />
        <div className="row mt-3">
          <div className="col-12">{generatePixelName()}</div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="mx-auto d-flex align-center">
              <RuleBlock
                type="Include"
                ruleSettings={inclusionSettings}
                updateRuleSettings={updateInclusion}
                //@ts-ignore
                urlMenuOptions={urlOptions}
                //@ts-ignore
                pixelMenuOptions={categoryOptions}
              />
              {showExclude && (
                <RuleBlock
                  type="Exclude"
                  ruleSettings={exclusionSettings}
                  updateRuleSettings={updateExclusion}
                  //@ts-ignore
                  urlMenuOptions={urlOptions}
                  //@ts-ignore
                  pixelMenuOptions={categoryOptions}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div
              className="mx-auto text-brand text-center cursor-pointer exclusion-link bg-brand faded py-2 px-3 rounded fit-content"
              onClick={() => {
                setShowExclude(_ => !_)
                onAddExclusions(!addExclusions)
              }}
            >
              {!showExclude ? (
                <>
                  <i className="text-brand fas fa-plus text-inherit mr-2" /> Exclude Users
                </>
              ) : (
                <>
                  <i className="text-brand fas fa-times text-inherit mr-2" /> Remove Excluded Users
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
