import React, { ReactNode } from 'react'

export interface ItemDetailsCardProps {
  header: ReactNode
  body: ReactNode
  footer: ReactNode
}

export function ItemDetailsCard(props: ItemDetailsCardProps) {
  const { header, body, footer } = props

  return (
    <div className="col-12 col-sm-4 col-lg-3 mr-n1 pl-0">
      <div className="card mx-0">
        <div className="card-header pb-1 bg-white border-bottom-0">{header}</div>
        <div className="card-body pt-2">{body}</div>
        <div className="card-footer bg-white p-0">{footer}</div>
      </div>
    </div>
  )
}
