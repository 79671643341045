import React from 'react'
import Select from 'react-select'
import { SelectItem } from 'components/select-item'
import { FormatService } from '@marketing-milk/frontend'
import { CustomAudience, FacebookTargetingSpec } from '@marketing-milk/interfaces'
import { StepHeader } from '../StepHeader'

export interface ConnectedAudiencesProps {
  customAudiences: CustomAudience[]
  includedAudiences: string[]
  excludedAudiences: string[]
  onAddIncludedAudience: (facebookID: string) => void
  onRemoveIncludedAudience: (facebookID: string) => void
  onAddExcludedAudience: (facebookID: string) => void
  onRemoveExcludedAudience: (facebookID: string) => void
  targetSpec: FacebookTargetingSpec
}

export function ConnectedAudiences(props: ConnectedAudiencesProps) {
  const {
    customAudiences,
    includedAudiences,
    excludedAudiences,
    onAddExcludedAudience,
    onAddIncludedAudience,
    onRemoveExcludedAudience,
    onRemoveIncludedAudience,
    targetSpec,
  } = props

  const audienceOptions = customAudiences
    ? customAudiences.map(x => ({
        label: `${x.name} (${FormatService.getValue(
          x.approximateCountLowerBound
        )} - ${FormatService.getValue(x.approximateCountUpperBound)} Users)`,
        value: x.facebookID,
      }))
    : []

  function onSelectIncludedAudience(option: { label: string; value: string }) {
    onAddIncludedAudience(option.value)
  }

  function onSelectExcludedAudience(option: { label: string; value: string }) {
    onAddExcludedAudience(option.value)
  }

  function generateSelections(selections: string[], onRemove: (facebookID: string) => void) {
    // We return a fragment here when a selected audience doesn't map to an audience available to the business
    // because of some data corruption that happened as a result of https://gitlab.com/marketingmilk/dashboard-laravel/-/issues/3273
    // This issue could potentially go away in the future but I think its fine as a safeguard to not displaying empty boxes anyways.
    return selections.map((selection, index) => {
      const selectedAudience = audienceOptions.find(x => x.value === selection)
      if (selectedAudience) {
        return (
          <SelectItem
            key={index}
            title={selectedAudience?.label ?? ''}
            onClick={() => selectedAudience && onRemove(selectedAudience.value)}
          />
        )
      }
      return <></>
    })
  }

  return (
    <>
      <div className="container pb-5 mb-5" style={{ minHeight: '600px' }}>
        <StepHeader
          title="Include or Exclude Other Audiences"
          description="Already have an audience targeting a specific group of users? Include or exclude them here."
          targetingSpec={targetSpec}
        />
        {/* Included Audiences */}
        <div className="row mt-5">
          <div className="col-12">
            <p className="text-bold">INCLUDE the following audience(s):</p>
            <Select
              placeholder="Select audience(s) to include..."
              value={null}
              options={audienceOptions.filter(
                x => !includedAudiences.includes(x.value) && !excludedAudiences.includes(x.value)
              )}
              onChange={onSelectIncludedAudience as any}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 included-audiences">
            {generateSelections(includedAudiences, onRemoveIncludedAudience)}
          </div>
        </div>
        {/* Excluded Audiences */}
        <div className="row mt-5">
          <div className="col-12">
            <p className="text-bold">EXCLUDE the following audience(s):</p>
            <Select
              placeholder="Select audience(s) to exclude..."
              value={null}
              options={audienceOptions.filter(
                x => !includedAudiences.includes(x.value) && !excludedAudiences.includes(x.value)
              )}
              onChange={onSelectExcludedAudience as any}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 excluded-audiences">
            {generateSelections(excludedAudiences, onRemoveExcludedAudience)}
          </div>
        </div>
      </div>
    </>
  )
}
