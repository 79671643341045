import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

export interface CallToActionTextProps {
  editable: boolean
  buttonText?: string
  onChangeButtonText(buttonText: string): void
}

export const ctaButtonMaxLength = 30 // Facebook's limit is 60 char but its too much for a button

export function CallToActionText({
  editable,
  buttonText,
  onChangeButtonText,
}: CallToActionTextProps) {
  return (
    <div className="form-group">
      <TextField
        error={!buttonText && editable}
        helperText={!buttonText && editable && 'Thank you page call to action text is required'}
        label="Call to Action Text *"
        placeholder="e.g. Visit Our Website"
        fullWidth
        disabled={!editable}
        value={buttonText}
        onChange={event => onChangeButtonText(event.target.value as string)}
        inputProps={{ maxLength: ctaButtonMaxLength, 'data-testid': 'completion-cta-text-input' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="subtitle1">
                {buttonText?.length ?? 0}/{ctaButtonMaxLength}
              </Typography>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true, style: { fontWeight: 'bold' } }}
      />
    </div>
  )
}
